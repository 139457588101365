import { PaymentType, PostingClientFinality, PostingType } from 'types/Posting';

export const PostingTypeDescription = {
	[PostingType.PIX]: 'Pix',
	[PostingType.TED]: 'TED',
	[PostingType.BARCODE]: 'Boleto',
};

export const PaymentTypeDescription = {
	[PaymentType.PIX]: 'Pix',
	[PaymentType.TED]: 'TED',
};

export const PostingClientFinalityDescription = {
	[PostingClientFinality.ACCOUNT_CREDIT_PAYMENT]: 'Crédito em conta',
	[PostingClientFinality.BETWEEN_ACCOUNTS_TRANSFER]:
		'Transferência entre contas de mesma titularidade',
	[PostingClientFinality.TAXES_DUTIES_FEES_PAYMENT]:
		'Pagamento de impostos, tributos e taxas',
	[PostingClientFinality.DIVIDEND_PAYMENT]: 'Pagamentos de dividendos',
	[PostingClientFinality.SALARY_PAYMENT]: 'Pagamento de salários',
	[PostingClientFinality.SUPPLIER_PAYMENT]: 'Pagamento de fornecedores',
	[PostingClientFinality.RENT_CONDFEES_PAYMENT]:
		'Pagamento de aluguéis e taxas de condomínio',
	[PostingClientFinality.SCHOOL_FEES_PAYMENT]:
		'Pagamento de mensalidade escolar',
	[PostingClientFinality.JUDICIAL_PAYMENT]: 'Depósito Judicial',
	[PostingClientFinality.OTHERS]: 'Outros',
};
