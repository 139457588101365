import React, { ReactNode } from 'react';
import { ManagementSvgIcon } from 'components/Icons/ManagementSvgIcon';
import { useTheme } from 'styled-components';
import { BreadcrumbContainer } from 'components/Breadcrumb';

export enum ManagementRoutes {
	BASE = '/management',
	ESCROW_ACCOUNTS = '/management/accounts',
}

export const ManagementIcon: React.FC<{ isActive?: boolean }> = ({
	isActive,
}) => {
	const theme = useTheme();
	if (isActive) return <ManagementSvgIcon />;
	return <ManagementSvgIcon color={theme.text} />;
};

export const titleManagementBreadcrumb: ReactNode = (
	<BreadcrumbContainer>
		<ManagementIcon />
		<span>Gestão</span>
	</BreadcrumbContainer>
);

export const getManagementBreadcrumb = (
	title: string,
	account?: string,
	id?: string,
) => {
	return [
		{
			href: '/management',
			title: (
				<BreadcrumbContainer>
					<ManagementIcon />
					<span>Gestão</span>
				</BreadcrumbContainer>
			),
		},
		{
			title: `Conta ${account ?? ''}`,
			href: `/management/${id}/details`,
		},
		{
			title: title,
		},
	];
};
