import { Dropdown } from 'antd';
import styled from 'styled-components';

export const UserMenuDropdown = styled(Dropdown)`
	width: 2.3rem;
	height: 2.3rem;
	border-radius: 2.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: 500;
	margin-left: 1rem;
	color: ${p => p.theme.white};
	background-color: ${p => p.theme.primary};
`;
