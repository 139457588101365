import React from 'react';

export const ArchiveIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.40833 17.2939C9.5875 17.8956 9.86083 18.4573 10.2125 18.9606H3.3275C3.10865 18.9591 2.89918 18.8715 2.74435 18.7169C2.58951 18.5622 2.50175 18.3528 2.5 18.1339V3.12061C2.5 2.66395 2.8725 2.29395 3.3325 2.29395H13.3333L17.5 6.46061V10.3556C16.9729 10.1051 16.411 9.93569 15.8333 9.85311V7.29395H12.5V3.96061H4.16667V17.2939H9.40833ZM15 19.7939C13.8949 19.7939 12.8351 19.355 12.0537 18.5736C11.2723 17.7922 10.8333 16.7323 10.8333 15.6273C10.8333 14.5222 11.2723 13.4624 12.0537 12.681C12.8351 11.8996 13.8949 11.4606 15 11.4606C16.1051 11.4606 17.1649 11.8996 17.9463 12.681C18.7277 13.4624 19.1667 14.5222 19.1667 15.6273C19.1667 16.7323 18.7277 17.7922 17.9463 18.5736C17.1649 19.355 16.1051 19.7939 15 19.7939ZM13.9225 17.8839C14.3891 18.1072 14.9135 18.1802 15.4234 18.0929C15.9333 18.0056 16.4035 17.7623 16.7693 17.3966C17.1351 17.0308 17.3784 16.5605 17.4657 16.0507C17.5529 15.5408 17.4799 15.0164 17.2567 14.5498L13.9225 17.8831V17.8839ZM12.7433 16.7048L16.0775 13.3714C15.6109 13.1482 15.0865 13.0752 14.5766 13.1625C14.0667 13.2498 13.5965 13.4931 13.2307 13.8588C12.8649 14.2246 12.6216 14.6949 12.5343 15.2047C12.4471 15.7146 12.5201 16.239 12.7433 16.7056V16.7048Z"
			fill={color ?? '#4B4662'}
		/>
	</svg>
);
