import { Drawer, DrawerProps } from 'antd';
import { Heading2 } from 'components/Heading2';
import { SlClose } from 'react-icons/sl';
import { useTheme } from 'styled-components';

export interface CustomDrawerProps extends DrawerProps {
	title: string;
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

const CustomDrawer = ({
	onClose,
	open,
	children,
	title,
	...props
}: CustomDrawerProps) => {
	const theme = useTheme();

	return (
		<Drawer
			title={
				<Heading2 style={{ color: theme.secondary }}>{title}</Heading2>
			}
			placement="right"
			onClose={onClose}
			open={open}
			extra={<SlClose onClick={onClose} style={{ cursor: 'pointer' }} />}
			closable={false}
			{...props}
		>
			{children}
		</Drawer>
	);
};

export default CustomDrawer;
