import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { CompanyIcon } from './constants/routes';
import { PageActions } from 'components/PageActions';
import { Button } from 'antd';
import { CompanyList } from './pages/CompanyList';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { LuPlusCircle } from 'react-icons/lu';
import { SelectPersonTypeModal } from './pages/PersonTypeModal';

const CompanyPage = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { setBreadcrumb } = useBreadcrumb();

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/company',
				title: (
					<BreadcrumbContainer>
						<CompanyIcon />
						<span>Clientes</span>
					</BreadcrumbContainer>
				),
			},
		]);
	}, [setBreadcrumb]);

	return (
		<>
			<PageHeader>
				<Heading1>Clientes</Heading1>
				<PageActions>
					<Button type="primary" onClick={() => setIsOpen(true)}>
						Criar Cliente
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				</PageActions>
			</PageHeader>
			<PageWrapper>
				<CompanyList />
			</PageWrapper>
			<SelectPersonTypeModal
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};

export default CompanyPage;
