import { BlobProvider } from '@react-pdf/renderer';
import {
	CancelButton,
	DateFormIcon,
	ForwardButton,
	PaymentCreationDateAmount,
	ReviewArea,
} from './styles';
import { Col, Row } from 'antd';
import { BarCodeIcon } from 'components/Icons/BarCode';
import { CalendarIcon } from 'components/Icons/Calendar';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { CompanyIcon } from 'components/Icons/Company';
import { PaymentCalendarIcon } from 'components/Icons/PaymentCalendar';
import { PaymentPayerIcon } from 'components/Icons/PaymentPayer';
import { PaymentPersonIcon } from 'components/Icons/PaymentPerson';
import { TextL, TextM, TextS } from 'components/Text';
import dayjs from 'dayjs';
import { formatCurrency, normalizeBoleto } from 'helpers/normalizers';
import { useTheme } from 'styled-components';
import { BarCode } from 'types/Posting';
import PaymentCreationReceipt from './PaymentCreationReceipt/PaymentCreationReceipt';
import { DiscountIcon } from 'components/Icons/Discount';
import { FineIcon } from 'components/Icons/Fine';
import { DownloadIcon } from 'components/Icons/Download';

interface IPostingCreationSuccessProps {
	paymentData: BarCode;
	paymentAmount: number;
	paymentNumber: string;
	paymentDate: string;
	transactionId: string;
	onNew: () => void;
}
const PostingCreationSuccess = ({
	paymentData,
	paymentAmount,
	paymentNumber,
	paymentDate,
	transactionId,
	onNew,
}: IPostingCreationSuccessProps) => {
	const theme = useTheme();

	return (
		<>
			<Row gutter={[16, 16]} justify="center">
				<Col>
					<CheckmarkIcon width={76} height={76} />
				</Col>
			</Row>
			<Row gutter={[16, 16]} justify="center">
				<Col
					style={{
						textAlign: 'center',
					}}
				>
					<TextL weight="normal">
						A solicitação foi realizada com sucesso!
					</TextL>
					<TextS
						style={{
							color: theme.textGray,
							padding: '0 1rem',
						}}
					>
						O seu comprovante será disponibilizado no extrato assim
						que a transação for aprovada/processada.
					</TextS>
				</Col>
			</Row>
			<ReviewArea>
				<Row
					style={{
						marginTop: '1rem',
						maxHeight: 'calc(100vh - 30rem)',
						overflow: 'auto',
					}}
				>
					<Col
						span={24}
						style={{
							backgroundColor: theme.primaryOpacity,
							borderRadius: '10px',
							padding: '16px 0px',
						}}
					>
						<Row align="middle" style={{ position: 'relative' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<CompanyIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Instituição Financeira
								</TextS>
								<TextM color={theme.text}>
									{paymentData.assignor}
								</TextM>
							</Col>
							<PaymentCreationDateAmount>
								{formatCurrency(paymentAmount)}
							</PaymentCreationDateAmount>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<PaymentPersonIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Nome do Beneficiário
								</TextS>
								<TextM>
									{paymentData.register_data.recipient}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<PaymentPayerIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Nome do Pagador
								</TextS>
								<TextM>{paymentData.register_data.payer}</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<BarCodeIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Código de barras
								</TextS>
								<TextM>{normalizeBoleto(paymentNumber)}</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<CalendarIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Vencimento
								</TextS>
								<TextM>
									{dayjs(
										paymentData.due_date ??
											paymentData.register_data
												.due_date_register,
									).format('DD/MM/YYYY')}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<PaymentCalendarIcon
										color={theme.primary}
									/>
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Pagamento
								</TextS>
								<TextM>
									{dayjs(paymentDate, 'YYYY-MM-DD').format(
										'DD/MM/YYYY',
									)}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<DiscountIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Descontos / Abatimentos
								</TextS>
								<TextM>
									{formatCurrency(
										paymentData.register_data
											.total_with_discount,
									)}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<FineIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Juros / Multas
								</TextS>
								<TextM>
									{formatCurrency(
										paymentData.register_data
											.total_with_additional,
									)}
								</TextM>
							</Col>
						</Row>
					</Col>
				</Row>
			</ReviewArea>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<BlobProvider
						document={
							<PaymentCreationReceipt
								paymentAmount={paymentAmount}
								paymentData={paymentData}
								paymentDate={paymentDate}
								paymentNumber={paymentNumber}
								transactionId={transactionId}
							/>
						}
					>
						{({ url }) => {
							return (
								<ForwardButton
									onClick={() => {
										if (url) {
											const a =
												document.createElement('a');
											a.href = url;
											a.download = `Comprovante_Solicitação_Pagamento_${transactionId}.pdf`;
											a.click();
											a.remove();
										}
									}}
									type="primary"
								>
									<TextM>
										Baixar comprovante de solicitação
									</TextM>
									<DownloadIcon color={theme.white} />
								</ForwardButton>
							);
						}}
					</BlobProvider>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CancelButton type="default" onClick={onNew}>
						<TextM>Realizar outra operação</TextM>
					</CancelButton>
				</Col>
			</Row>
		</>
	);
};

export default PostingCreationSuccess;
