import {
	SideBarHeader,
	SideBarMenu,
	SideBarMenuGroup,
	SideBarMenuList,
	SideBarMenuListItem,
	SideBarMenuListItemLink,
	SideBarMenuListItemIcon,
	SideBarWrapper,
} from './styles';
import { SideBarLogo } from './Logo';
import { useLocation } from 'react-router-dom';
import { UsersIcon, UsersRoutes } from 'modules/users/constants/routes';
import { EscrowIcon, EscrowRoutes } from 'modules/escrow/constants/routes';
import { CompanyIcon, CompanyRoutes } from 'modules/company/constants/routes';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';
import {
	DebitCollectionsIcon,
	DebitCollectionsRoutes,
} from 'modules/debitCollections/constants/routes';
import {
	ManagementIcon,
	ManagementRoutes,
} from 'modules/management/constants/routes';
import { FinanceIcon } from 'components/Icons/Finance';
import theme from 'theme/theme';

interface ISideBarProps {
	isCollapsed: boolean;
	toggleCollapsed: (isCollapsed: boolean) => void;
}
export function SideBar({ isCollapsed, toggleCollapsed }: ISideBarProps) {
	const { type, permissions } = useCurrentProfile();
	const location = useLocation();

	return (
		<SideBarWrapper isCollapsed={isCollapsed}>
			<SideBarHeader>
				<SideBarLogo
					isCollapsed={isCollapsed}
					toggleCollapsed={() => toggleCollapsed(!isCollapsed)}
				/>
			</SideBarHeader>
			<SideBarMenu>
				<SideBarMenuGroup>
					<SideBarMenuList>
						{type === AccessType.FINANCIAL_INSTITUTION && (
							<SideBarMenuListItem
								isActive={location.pathname.startsWith(
									ManagementRoutes.BASE,
								)}
							>
								<SideBarMenuListItemLink
									to={ManagementRoutes.BASE}
								>
									<SideBarMenuListItemIcon>
										<ManagementIcon
											isActive={location.pathname.startsWith(
												ManagementRoutes.BASE,
											)}
										/>
									</SideBarMenuListItemIcon>
									Gestão
								</SideBarMenuListItemLink>
							</SideBarMenuListItem>
						)}
						<SideBarMenuListItem
							isActive={location.pathname.startsWith(
								EscrowRoutes.ESCROW_BASE,
							)}
						>
							<SideBarMenuListItemLink
								to={EscrowRoutes.ESCROW_BASE}
							>
								<SideBarMenuListItemIcon>
									<EscrowIcon
										isActive={location.pathname.startsWith(
											EscrowRoutes.ESCROW_BASE,
										)}
									/>
								</SideBarMenuListItemIcon>
								Conta Escrow
							</SideBarMenuListItemLink>
						</SideBarMenuListItem>
						<SideBarMenuListItem
							isActive={location.pathname.startsWith(
								EscrowRoutes.ACCOUNT_BASE,
							)}
						>
							<SideBarMenuListItemLink
								to={EscrowRoutes.ACCOUNT_BASE}
							>
								<SideBarMenuListItemIcon>
									<FinanceIcon
										color={
											location.pathname.startsWith(
												EscrowRoutes.ACCOUNT_BASE,
											)
												? theme.primary
												: theme.text
										}
									/>
								</SideBarMenuListItemIcon>
								Conta Livre
							</SideBarMenuListItemLink>
						</SideBarMenuListItem>
						{(type === AccessType.FINANCIAL_INSTITUTION ||
							permissions.includes(
								AccessPermission.VIEW_WALLET,
							)) && (
							<SideBarMenuListItem
								isActive={location.pathname.startsWith(
									DebitCollectionsRoutes.BASE,
								)}
							>
								<SideBarMenuListItemLink
									to={DebitCollectionsRoutes.BASE}
								>
									<SideBarMenuListItemIcon>
										<DebitCollectionsIcon
											isActive={location.pathname.startsWith(
												DebitCollectionsRoutes.BASE,
											)}
										/>
									</SideBarMenuListItemIcon>
									Cobranças
								</SideBarMenuListItemLink>
							</SideBarMenuListItem>
						)}
						{type === AccessType.FINANCIAL_INSTITUTION && (
							<SideBarMenuListItem
								isActive={location.pathname.startsWith(
									CompanyRoutes.BASE,
								)}
							>
								<SideBarMenuListItemLink
									to={CompanyRoutes.BASE}
								>
									<SideBarMenuListItemIcon>
										<CompanyIcon
											isActive={location.pathname.startsWith(
												CompanyRoutes.BASE,
											)}
										/>
									</SideBarMenuListItemIcon>
									Clientes
								</SideBarMenuListItemLink>
							</SideBarMenuListItem>
						)}
						{(type === AccessType.FINANCIAL_INSTITUTION ||
							permissions.includes(
								AccessPermission.MANAGE_USER,
							)) && (
							<SideBarMenuListItem
								isActive={location.pathname.startsWith(
									UsersRoutes.BASE,
								)}
							>
								<SideBarMenuListItemLink to={UsersRoutes.BASE}>
									<SideBarMenuListItemIcon>
										<UsersIcon
											isActive={location.pathname.startsWith(
												UsersRoutes.BASE,
											)}
										/>
									</SideBarMenuListItemIcon>
									Acessos
								</SideBarMenuListItemLink>
							</SideBarMenuListItem>
						)}
					</SideBarMenuList>
				</SideBarMenuGroup>
			</SideBarMenu>
		</SideBarWrapper>
	);
}
