import React, { useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
import { Destination, DestinationType } from 'types/Destination';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { cpfCnpjRule } from 'helpers/rules';
import { BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { useTheme } from 'styled-components';
import { TextS } from 'components/Text';
import { BiSearch } from 'react-icons/bi';
import { ForwardButton } from './components/ForwardButton';
import { ApiError } from 'types/ApiError';
import { Account } from 'types/Account';
import { useDestinations } from 'modules/escrow/hooks';
import { queryClient } from 'network/query';
import { StepsWrapper } from './styles';

export interface DestinationCreationProps {
	isLoading?: boolean;
	account: Account;
	onCreate: (newDestinationId: string) => void;
	onBack: () => void;
}

const DestinationCreation = ({
	account,
	isLoading,
	onCreate,
	onBack,
}: DestinationCreationProps) => {
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);

	const { createOrUpdateDestination, loading } = useDestinations(account.id, {
		onCreateSuccess: async ({ id }) => {
			api.success({
				message: 'Conta beneficiária cadastrada com sucesso',
			});

			//HK: faz o refetch da query de accountData para atualizar a lista de contas beneficiárias após a criação de uma nova
			// colocar dados da conta em contexto
			await queryClient.refetchQueries({
				queryKey: ['accountData'],
			});

			onCreate(id);
		},
		onError: (error: ApiError) => {
			api.error({
				message: error.data.message,
			});
			form.resetFields();
		},
	});

	return (
		<>
			{contextHolder}
			<StepsWrapper>
				<Form<Destination>
					form={form}
					style={{ width: '100%' }}
					layout="vertical"
					onFieldsChange={(f, allFields) => {
						const errors = allFields.filter(
							field =>
								!field.touched ||
								(field.errors && field.errors.length > 0),
						);
						setFormValid(errors.length === 0);
					}}
					onFinish={values => {
						createOrUpdateDestination({
							...values,
							taxpayer_id: serializeOnlyNumbers(
								values.taxpayer_id,
							),
							account: serializeOnlyNumbers(values.account),
						});
					}}
				>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="taxpayer_id"
								label={
									<TextS>
										CPF / CNPJ da conta de destino
									</TextS>
								}
								normalize={value => normalizeCpfCnpj(value)}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
									cpfCnpjRule,
								]}
							>
								<Input placeholder="Digite aqui" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="name"
								label={<TextS>Nome do beneficiário</TextS>}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Digite aqui" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="bank"
								label={<TextS>Banco</TextS>}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									suffixIcon={<BiSearch size={18} />}
									showSearch={true}
									filterOption={(input, option) =>
										!!option?.label
											?.toLowerCase()
											?.includes(input.toLowerCase())
									}
									placeholder="Selecione"
									options={BankListOptions}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Form.Item
								name="account"
								label={<TextS>Número da conta</TextS>}
								normalize={value => normalizeBankAccount(value)}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="000000-0" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="branch"
								label={<TextS>Agência</TextS>}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="0000" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="type"
								label={<TextS>Tipo de conta</TextS>}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									placeholder="Selecione"
									options={Object.keys(
										DestinationTypeDescription,
									).map(key => ({
										label: DestinationTypeDescription[
											key as DestinationType
										],
										value: key,
									}))}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<div>
					<Row
						style={{
							width: '100%',
							marginTop: '1rem',
						}}
					>
						<ForwardButton
							isLoading={loading}
							disabled={!formValid || loading}
							onClick={form.submit}
							text="Avançar"
						/>
					</Row>
					<Row
						style={{
							marginTop: '1rem',
						}}
					>
						<Button
							type="default"
							loading={isLoading}
							onClick={() => {
								onBack();
							}}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								border: 'none',
								boxShadow: 'none',
								color: theme.primary,
								width: '100%',
							}}
						>
							<TextS
								style={{
									color: theme.primary,
								}}
							>
								Voltar
							</TextS>
						</Button>
					</Row>
				</div>
			</StepsWrapper>
		</>
	);
};

export default DestinationCreation;
