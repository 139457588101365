import { Button } from 'antd';
import { Table } from 'components/Table';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	border: 2px solid #f7f7f7;
	height: 100vh;
`;

export const PageInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-right: 1rem;
`;

export const ForwardButton = styled(Button)`
	display: flex;
	align-items: center;
	gap: 0.3rem;

	p {
		color: white;
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const CancelButton = styled(Button)`
	display: flex;
	align-items: center;
	gap: 0.3rem;

	p {
		color: ${p => p.theme.primary};
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const DestinationTable = styled(Table)`
	th::before {
		display: none;
	}

	& .main-account {
		background-color: ${({ theme }) => theme.primaryOpacity};
	}
`;
