import { AccountType } from 'modules/escrow/constants/routes';
import { Address } from './Address';
import {
	CompanyDocuments,
	BusinessType,
	PersonType,
	ETenantType,
} from './Company';
import { Creator } from './Creator';
import { Destination } from './Destination';
import { Phone } from './Phone';
import { PaymentType } from './Posting';
import { Representative } from './Representative';
import { ExportStatementFileTypeEnum } from 'modules/escrow/pages/EscrowStatement/types';

export enum AccountPermission {
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	CREATE_BENEFICIARY = 'CREATE_BENEFICIARY',
	VIEW_ACCOUNT = 'VIEW_ACCOUNT',
	POSTING_CREATE = 'POSTING_CREATE',
	POSTING_REVIEW = 'POSTING_REVIEW',
	POSTING_VIEW = 'POSTING_VIEW',
	CREATE_WALLET = 'CREATE_WALLET',
	VIEW_WALLET = 'VIEW_WALLET',
	UPDATE_WALLET = 'UPDATE_WALLET',
	ARCHIVE_WALLET = 'ARCHIVE_WALLET',
	CREATE_CHARGE = 'CREATE_CHARGE',
	CANCEL_CHARGE = 'CANCEL_CHARGE',
	VIEW_CHARGE = 'VIEW_CHARGE',
	UPDATE_CHARGE = 'UPDATE_CHARGE',
	CREATE_BALANCE_RETENTION = 'CREATE_RETENTION',
	REMOVE_BALANCE_RETENTION = 'REMOVE_RETENTION',
	VIEW_BALANCE_RETENTION = 'VIEW_RETENTION',
}

export enum AccountStatus {
	ACTIVE = 'ACTIVE',
	BLOCKED = 'BLOCKED',
	BLOCKING = 'BLOCKING',
	CANCELED = 'CANCELED',
	CANCELING = 'CANCELING',
	CREATING = 'CREATING',
	ERROR_BLOCKING = 'ERROR_BLOCKING',
	ERROR_CANCELING = 'ERROR_CANCELING',
	ERROR_CREATING = 'ERROR_CREATING',
	ERROR_UNBLOCKING = 'ERROR_UNBLOCKING',
	PENDING_ACTIVATION = 'PENDING_ACTIVATION',
	PENDING_AGREEMENT_SIGNING = 'PENDING_AGREEMENT_SIGNING',
	UNBLOCKING = 'UNBLOCKING',
	WAITING_ANALYSIS = 'WAITING_ANALYSIS',
	IN_ANALYSIS = 'IN_ANALYSIS',
	WAITING_UPDATES = 'WAITING_UPDATES',
	REFUSED = 'REFUSED',
}

export type AccountRelatedPart = {
	person: {
		id: string;
		businessType: BusinessType;
	};
	sign_contract: boolean;
	permissions: AccountPermission[];
};

export type BaseAccount = {
	person_id: string;
};

export type AccountDto = BaseAccount & {
	parties: AccountRelatedPart[];
	tenant_type: ETenantType;
	account_type: AccountType;
};

export type AccountRelatedPartDetailed = {
	id?: string;
	person: {
		id: string;
		business_type: BusinessType;
		corporate_name: string;
		name: string;
		taxpayer_id: string;
		representatives: Array<{
			full_name: string;
			sign_contract: boolean;
			taxpayer_id: string;
			representative?: {
				full_name: string;
				sign_contract: boolean;
				taxpayer_id: string;
			};
		}>;
	};
	sign_contract: boolean;
	permissions: AccountPermission[];
};

export type Account = AccountOpeningDocuments & {
	person_id: string;
	id: string;
	status: AccountStatus;
	branch?: string;
	account?: string;
	error?: string;
	destinations: Destination[];
	parties: AccountRelatedPartDetailed[];
	tenant_type: ETenantType;
	person: {
		taxpayer_id: string;
		corporate_name: string;
		name: string;
		foundation_date: string;
		birth_date: string;
		person_type: PersonType;
		cnae: string;
		email_address: string;
		phone: Phone;
		address: Address;
		documents: CompanyDocuments;
		representatives: Representative[];
	};
	created_at: string;
	activated_at?: string;
	reason?: string;
};

export type AccountActivationProps = {
	accountId: string;
	agreementKey: string;
	fees: AccountFee;
};

export type AccountUpdateDto = {
	fees: AccountFee;
};

export enum AccountFeeMode {
	DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export enum AccountFeeType {
	NONE = 'NONE',
	FIXED = 'FIXED',
	PERCENT = 'PERCENT',
}

export interface ISelectFeesStateControl {
	tedDebitType: AccountFeeType;
	pixDebitType: AccountFeeType;
	depositType: AccountFeeType;
}

export type AccountFee = {
	mode: AccountFeeMode;
	default_payment_type: PaymentType;
	// TARIFA DA CONTA:
	maintenance?: {
		day?: number;
		amount?: number;
		commission_percent_amount?: number;
	};
	// PIX ENTRADA:
	deposit?: {
		amount_type?: AccountFeeType;
		amount?: number;
	};
	// PIX SAIDA:
	withdrawal?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
	// TED SAIDA:
	ted_transfer?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};
	// TARIFA DE BOLETO:
	charge_settlement?: {
		amount_type?: AccountFeeType;
		amount?: number;
		commission_percent_amount?: number;
	};

	// COMMISSION ACCOUNT DESTINATION
	commission_account_destination_id?: string;
	commission_account_destination?: {
		id?: string;
		account: string;
		bank: string;
		branch: string;
		name: string;
		taxpayer_id: string;
		type: string;
	};
};

export type AccountOpeningDocumentsDto = {
	fees?: AccountFee;
	documents: {
		agreement: string;
	};
};

export type AccountOpeningDocuments = {
	fees?: AccountFee;
	documents: {
		opening_agreement: string;
		opening_agreement_url: string;
	};
};

export type AccountFilters = {
	page?: number;
	size?: number;
	order?: 'ASC' | 'DESC';
	sort?: string;
	search?: string;
	status?: AccountStatus;
	taxpayer_id?: string;
	account?: string;
	person_id?: string;
	creator_name?: string;
	requested_by_user?: boolean;
	account_type?: AccountType;
};

export type AccountContent = {
	id: string;
	person: {
		id: string;
		name: string;
		taxpayer_id: string;
		person_type: PersonType;
	};
	person_id: string;
	account?: string;
	branch?: string;
	error?: string;
	created_at: string;
	creator: Creator;
	status: AccountStatus;
	tenant_type: ETenantType;
	activated_at?: string;
	reason?: string;
	account_type?: AccountType;
};

export enum AccountStatementMovementType {
	ENTRYCREDIT = 'ENTRYCREDIT',
	ENTRYDEBIT = 'ENTRYDEBIT',
	TEDTRANSFEROUT = 'TEDTRANSFEROUT',
	TEDTRANSFERIN = 'TEDTRANSFERIN',
	PIXREVERSALIN = 'PIXREVERSALIN',
	PIXREVERSALOUT = 'PIXREVERSALOUT',
	PIXPAYMENTOUT = 'PIXPAYMENTOUT',
	PIXPAYMENTIN = 'PIXPAYMENTIN',
	BILLPAYMENT = 'BILLPAYMENT',
	TEFTRANSFEROUT = 'TEFTRANSFEROUT',
	TEFTRANSFERIN = 'TEFTRANSFERIN',
}

export interface AccountStatementMovement {
	id: string;
	client_code: string;
	description: string;
	create_date: string;
	last_update_date: string;
	amount: number | null;
	status: string;
	balance_type: string;
	movement_type: AccountStatementMovementType;
	balance: number;
	additional_information:
		| {
				name_credit: string;
				name_debit: string;
		  }
		| undefined;
}

export type AccountStatementFilters = {
	date_from: string;
	date_to: string;
	page?: number;
	size?: number;
};

export type AccountBalance = { amount: number };

export interface StatementStatusResponse {
	id: string;
	amount: number;
	client_code: string;
	transaction_identification: null;
	end_to_end_id: string;
	initiation_type: string;
	payment_type: string;
	urgency: string;
	transaction_type: string;
	debit_party: {
		account: string;
		branch: string;
		tax_id: string;
		name: string;
		account_type: string;
	};
	credit_party: {
		bank: string;
		key: string | null;
		account: string;
		branch: string;
		tax_id: string;
		name: string;
		account_type: string;
	};
	remittance_information: null;
	//Boleto
	transaction_id_authorize?: number;
	has_ocurrence?: boolean;
	bar_code_info?: {
		digitable: string;
	} | null;
	payment_date?: string;
}

export interface IStatementExportResponse {
	email: string;
}

export interface IStatementExportPayload {
	exportedDates: string[];
	format: ExportStatementFileTypeEnum;
}

export interface IUpdateAccountStatusPayload {
	status: AccountStatus;
	reason?: string;
}

export interface IIUpdateAccountStatus extends IUpdateAccountStatusPayload {
	id: string;
}
