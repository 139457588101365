import { Col, Descriptions, Row } from 'antd';
import { TextM } from 'components/Text';
import { useTheme } from 'styled-components';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { Account } from 'types/Account';
import { DescriptionItem, DescriptionsWrapper } from '../styles';
import { PageWrapper } from 'components/PageWrapper';

export interface EscrowAccountInfoProps {
	account: Account;
}

const EscrowAccountInfo = ({ account }: EscrowAccountInfoProps) => {
	const theme = useTheme();

	const descriptionItems = [
		{
			key: '1',
			label: 'Titular',
			children: <DescriptionItem>{account.person.name}</DescriptionItem>,
		},
		{
			key: '2',
			label: 'Instituição',
			children: (
				<DescriptionItem>
					509 - Celcoin Instituição de Pagamento S.A
				</DescriptionItem>
			),
		},
		{
			key: '3',
			label: 'Documento',
			children: (
				<DescriptionItem>
					{normalizeCpfCnpj(account.person.taxpayer_id)}
				</DescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Agência',
			children: (
				<DescriptionItem>{account.branch ?? '-'}</DescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Conta',
			children: (
				<DescriptionItem>
					{account.account
						? normalizeBankAccount(account.account)
						: '-'}
				</DescriptionItem>
			),
		},
	];

	return (
		<PageWrapper>
			<TextM style={{ color: theme.secondary }}>
				Informações da conta:
			</TextM>
			<Row gutter={[16, 16]} style={{ margin: '1rem 0' }}>
				<Col>
					<DescriptionsWrapper>
						<Descriptions
							layout="vertical"
							column={5}
							items={descriptionItems}
							contentStyle={{
								margin: '0 0.5rem 0 0',
							}}
						/>
					</DescriptionsWrapper>
				</Col>
			</Row>
		</PageWrapper>
	);
};

export default EscrowAccountInfo;
