import {
	DropdownWrapper,
	EscrowApprovalsFiltersWrapper,
	Select,
} from './styles';
import { TextS } from 'components/Text';
import { useCallback } from 'react';
import { PostingStatus } from 'types/Posting';

export const postingsStatuses: { value: PostingStatus; label: string }[] = [
	{ value: PostingStatus.PENDING_APPROVAL, label: 'PENDENTES' }, //must be the first value is used as state
	{ value: PostingStatus.SCHEDULED, label: 'APROVADAS' },
	{ value: PostingStatus.REJECTED, label: 'REJEITADAS' },
	{ value: PostingStatus.ERROR, label: 'ERROS' },
];

interface IEscrowApprovalsFiltersProps {
	onSelect: (value: PostingStatus) => void;
	value?: string;
}
const EscrowApprovalsFilters = ({
	onSelect,
	value,
}: IEscrowApprovalsFiltersProps) => {
	const handleSelect = useCallback(
		(value: PostingStatus) => {
			onSelect(value);
		},
		[onSelect],
	);

	return (
		<EscrowApprovalsFiltersWrapper>
			<TextS>Status</TextS>
			<Select
				value={value}
				defaultValue={value}
				optionRender={option => {
					return <div>{option.label}</div>;
				}}
				dropdownRender={menu => {
					return <DropdownWrapper>{menu}</DropdownWrapper>;
				}}
				options={postingsStatuses}
				onSelect={value => handleSelect(value as PostingStatus)}
			/>
		</EscrowApprovalsFiltersWrapper>
	);
};

export default EscrowApprovalsFilters;
