import {
	CompanyOutlinedIcon,
	CompanyIcon as CompanyIconSvg,
} from 'components/Icons/Company';
import { useTheme } from 'styled-components';

export enum CompanyRoutes {
	BASE = '/company',
	LIST = '/',
	NEW = '/company/new',
	NEW_NATURAL_PERSON = '/company/natural/new',
}

export const CompanyIcon: React.FC<{ isActive?: boolean }> = ({ isActive }) => {
	const theme = useTheme();
	if (isActive) return <CompanyIconSvg color={theme.primary} />;
	return <CompanyOutlinedIcon color={theme.text} />;
};
