import React from 'react';
import {
	SideBarCollapseButton,
	SideBarLogoImage,
	SideBarLogoWrapper,
} from './styles';
import { TbArrowBarToLeft, TbArrowBarToRight } from 'react-icons/tb';
import CelcoinLogo from 'components/Icons/CelcoinLogo';

interface ISideBarLogoProps {
	isCollapsed: boolean;
	toggleCollapsed: () => void;
}
export function SideBarLogo({
	isCollapsed,
	toggleCollapsed,
}: ISideBarLogoProps) {
	return (
		<SideBarLogoWrapper>
			<SideBarLogoImage isCollapsed={isCollapsed}>
				<CelcoinLogo />
			</SideBarLogoImage>
			<SideBarCollapseButton onClick={toggleCollapsed}>
				{!isCollapsed ? <TbArrowBarToLeft /> : <TbArrowBarToRight />}
			</SideBarCollapseButton>
		</SideBarLogoWrapper>
	);
}
