import { Button } from 'antd';
import { AccountDestinationPerson } from 'components/AccountDestinationPerson';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';
import { GrCheckmark } from 'react-icons/gr';

interface IEscrowCreationDestinationProps {
	accountId: string;
	onFinish: () => void;
}
const EscrowCreationDestination = ({
	accountId,
	onFinish,
}: IEscrowCreationDestinationProps) => {
	const isEscrowAccount = useIsEscrowAccountRoute();

	return (
		<AccountDestinationPerson
			accountId={accountId}
			renderFooter={destinations => (
				<Button
					type="primary"
					disabled={isEscrowAccount && destinations.length === 0}
					onClick={() => onFinish()}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '0.5rem',
					}}
				>
					Concluir cadastro
					<GrCheckmark />
				</Button>
			)}
		/>
	);
};

export default EscrowCreationDestination;
