import React, { useContext, useMemo, useState } from 'react';
import { EscrowApprovals } from './pages/EscrowApprovals';
import { EscrowAccounts } from './pages/EscrowAccounts';
import { LuPlusCircle } from 'react-icons/lu';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { EscrowRoutes } from './constants/routes';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { PageActions } from 'components/PageActions';
import { Button } from 'antd';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { TabElement } from 'components/Tabs/Tabs';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { SelectPersonTypeModal } from './components/SelectEscrowAccountTypeModal';
import { EscrowLeads } from './pages/EscrowLeads/EscrowLeads';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
} from './utils/adminRoute';

const EscrowPage = () => {
	const { type } = useCurrentProfile();
	const ability = useContext(AbilityContext);
	const [isAccountTypeModalOpen, updateIsAccountTypeModalOpen] =
		useState(false);

	const breadcrumb = [
		{
			href: EscrowRoutes.ESCROW_BASE,
			title: (
				<BreadcrumbContainer>
					{getAccountRouteTypeIcon()}
					<span>{getAccountRouteTypeName()}</span>
				</BreadcrumbContainer>
			),
		},
	];

	const tabElements = useMemo<TabElement[]>(() => {
		const tabs = [
			{
				title: 'Contas',
				content: <EscrowAccounts />,
				path: 'accounts',
				breadcrumb,
			},
		];

		if (
			ability.can('REVIEW', 'POSTING') ||
			ability.can('VIEW', 'POSTING') ||
			type === AccessType.FINANCIAL_INSTITUTION
		) {
			tabs.push({
				title: 'Aprovações',
				content: <EscrowApprovals />,
				path: 'approvals',
				breadcrumb,
			});
		}

		if (
			ability.can('CREATE', 'ACCOUNT') ||
			type === AccessType.FINANCIAL_INSTITUTION
		) {
			tabs.push({
				title: 'Solicitações de abertura',
				content: <EscrowLeads />,
				path: 'leads',
				breadcrumb,
			});
		}
		return tabs;
	}, [ability, type]);

	return (
		<>
			<PageHeader isFluid={true}>
				<Heading1>{getAccountRouteTypeName()}</Heading1>
				{(ability.can('CREATE', 'ACCOUNT') ||
					type === AccessType.FINANCIAL_INSTITUTION) && (
					<PageActions>
						<Button
							type="primary"
							onClick={() => {
								updateIsAccountTypeModalOpen(true);
							}}
						>
							Criar Conta
							<LuPlusCircle
								style={{ marginLeft: '0.6rem' }}
								size={18}
							/>
						</Button>
					</PageActions>
				)}
			</PageHeader>
			<PageWrapper isFluid={true}>
				<Tabs elements={tabElements} />
			</PageWrapper>
			<SelectPersonTypeModal
				isOpen={isAccountTypeModalOpen}
				onClose={() => updateIsAccountTypeModalOpen(false)}
			/>
		</>
	);
};

export default EscrowPage;
