import { RuleRender } from 'antd/es/form';
import { validateCnpj, validateCpf } from './validators';
import dayjs, { Dayjs } from 'dayjs';
import { formatCurrency, formatToCurrencyFloat } from './normalizers';
import { WalletDiscountModality } from 'types/Wallet';
import { getHolidays } from './dates';

export const cnpjRule: RuleRender = ({ isFieldTouched }) => ({
	validator(_, value) {
		if (!isFieldTouched('taxpayer_id') || !value || validateCnpj(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('CNPJ inválido'));
	},
});

export const cpfRule: RuleRender = ({ isFieldTouched }) => ({
	validator(_, value) {
		if (!isFieldTouched('taxpayer_id') || !value || validateCpf(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('CPF inválido'));
	},
});

export const fullNameRule =
	(fieldName: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, value) {
			if (
				!isFieldTouched(fieldName) ||
				!value ||
				String(value).split(' ').length > 1
			) {
				if (
					String(value)
						.split(' ')
						.every(name => name.length >= 1)
				) {
					return Promise.resolve();
				}
				return Promise.reject(
					new Error('Existem caracteres inválidos'),
				);
			}

			return Promise.reject(new Error('Nome completo é obrigatório'));
		},
	});

export const minDateRule: RuleRender = ({ isFieldTouched }) => ({
	validator(_, value) {
		if (
			!isFieldTouched('birth_date') ||
			!value ||
			dayjs().diff(value as Dayjs, 'year') > 18
		) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('Idade mínima de 18 anos'));
	},
});

export const minTodayDateRule =
	(fieldName: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, value) {
			if (
				!isFieldTouched(fieldName) ||
				!value ||
				dayjs()
					.startOf('day')
					.diff((value as Dayjs).startOf('day'), 'day') <= 0
			) {
				return Promise.resolve();
			}
			return Promise.reject(new Error('Data no passado não é permitida'));
		},
	});

export const discountValueRule =
	(
		fieldName: string,
		amountFieldName: string,
		modality: WalletDiscountModality,
	): RuleRender =>
	({ isFieldTouched, getFieldValue }) => ({
		validator(_, v) {
			if (!isFieldTouched(fieldName) || !v) {
				return Promise.resolve();
			}

			const value =
				typeof v === 'string'
					? parseFloat(v.replaceAll('.', '').replace(',', '.'))
					: v;
			const amountValueString = getFieldValue(amountFieldName);
			const amountValue =
				typeof amountValueString === 'string'
					? parseFloat(
							amountValueString
								.replaceAll('.', '')
								.replace(',', '.'),
						)
					: amountValueString;

			if (modality === WalletDiscountModality.PERCENT) {
				const amountAfterDiscount =
					amountValue - (amountValue * value) / 100;
				if (amountAfterDiscount < 5) {
					return Promise.reject(
						new Error(
							`O valor final do boleto após o desconto não pode ser menor que R$ 5,00 (Atual: ${formatCurrency(
								amountAfterDiscount,
							)})`,
						),
					);
				}
			} else if (amountValue - value < 5) {
				return Promise.reject(
					new Error(
						`O valor final do boleto após o desconto não pode ser menor que R$ 5,00 (Atual: ${formatCurrency(
							amountValue - value,
						)})`,
					),
				);
			}

			return Promise.resolve();
		},
	});

export const discountDateRule =
	(dueDateFieldName: string): RuleRender =>
	({ getFieldValue }) => ({
		validator(_, value) {
			if (!value) {
				return Promise.resolve();
			}

			if (
				value &&
				dayjs(value).isAfter(getFieldValue(dueDateFieldName))
			) {
				return Promise.reject(
					new Error(
						'Data de desconto precisa ser menor ou igual a data de vencimento',
					),
				);
			}

			if (
				value &&
				dayjs(getFieldValue(dueDateFieldName)).diff(value, 'day') > 20
			) {
				return Promise.reject(
					new Error('Data de desconto não deve ultrapassar 20 dias'),
				);
			}

			return Promise.resolve();
		},
	});

export const maxTodayDateRule =
	(fieldName: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, value) {
			if (
				!isFieldTouched(fieldName) ||
				!value ||
				dayjs()
					.startOf('day')
					.diff((value as Dayjs).startOf('day'), 'day') >= 0
			) {
				return Promise.resolve();
			}
			return Promise.reject(new Error('Data no futuro não é permitida'));
		},
	});

export const cpfCnpjRule: RuleRender = ({ isFieldTouched }) => ({
	validator(_, value) {
		if (!isFieldTouched('taxpayer_id') || !value) {
			return Promise.resolve();
		}
		const onlyNums = value.replace(/[^\d]/g, '');
		if (onlyNums.length <= 11) {
			if (validateCpf(onlyNums)) {
				return Promise.resolve();
			}
			return Promise.reject(new Error('CPF inválido'));
		}

		if (validateCnpj(onlyNums)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('CNPJ inválido'));
	},
});

export const validateMinDateBetween =
	(fieldName: string): RuleRender =>
	({ getFieldValue }) => ({
		validator(_, value) {
			if (!value) {
				return Promise.resolve();
			}

			const minDate = dayjs(getFieldValue(fieldName)).add(1, 'day');

			if (value && dayjs(value).isBefore(minDate)) {
				return Promise.reject(
					new Error('Data final deve ser maior que a data inicial'),
				);
			}

			return Promise.resolve();
		},
	});

export const betweenRangeDates =
	(
		fieldName: string,
		minDaysDifference: number,
		maxDaysDifference: number,
	): RuleRender =>
	({ getFieldValue }) => ({
		validator(_, value) {
			if (!value) {
				return Promise.resolve();
			}

			if (value && dayjs(value).isBefore(getFieldValue(fieldName))) {
				return Promise.reject(
					new Error('Data final deve ser maior que a data inicial'),
				);
			}

			if (
				value &&
				dayjs(value).diff(getFieldValue(fieldName), 'day') >
					maxDaysDifference
			) {
				return Promise.reject(
					new Error(`Intervalo máximo de ${maxDaysDifference} dias`),
				);
			}

			if (
				value &&
				dayjs(value).diff(getFieldValue(fieldName), 'day') <
					minDaysDifference
			) {
				return Promise.reject(
					new Error(
						minDaysDifference > 1
							? `Intervalo mínimo de ${minDaysDifference} dias`
							: `Intervalo mínimo de ${minDaysDifference} dia`,
					),
				);
			}

			return Promise.resolve();
		},
	});

export const minValue =
	(fieldName: string, min: number, message?: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			const value =
				typeof v === 'string'
					? parseFloat(v.replaceAll('.', '').replace(',', '.'))
					: v;
			if (
				!isFieldTouched(fieldName) ||
				typeof value !== 'number' ||
				value >= min
			) {
				return Promise.resolve();
			}
			return Promise.reject(
				new Error(message ?? `Valor mínimo de ${formatCurrency(min)}`),
			);
		},
	});

export const maxDiscountValue =
	(fieldName: string, max: number): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			const value =
				typeof v === 'string'
					? parseFloat(v.replaceAll('.', '').replace(',', '.'))
					: v;
			if (
				!isFieldTouched(fieldName) ||
				typeof value !== 'number' ||
				value <= max
			) {
				if (max - value < 5) {
					return Promise.reject(
						new Error(
							`O valor final do boleto após o desconto não pode ser menor que R$ 5,00 (Atual: ${formatCurrency(
								max - value,
							)})`,
						),
					);
				}
				return Promise.resolve();
			}
			return Promise.reject(
				new Error('Desconto deve ser menor que o valor total'),
			);
		},
	});

export const betweenRangeValue =
	(fieldName: string, min: number, max: number): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			const value =
				typeof v === 'string'
					? parseFloat(v.replaceAll('.', '').replace(',', '.'))
					: v;
			if (
				!isFieldTouched(fieldName) ||
				!value ||
				(value >= min && value <= max)
			) {
				return Promise.resolve();
			}
			return Promise.reject(
				new Error(
					`Valor mínimo de ${formatCurrency(min)} e máximo de ${formatCurrency(
						max,
					)}`,
				),
			);
		},
	});

export const betweenRangeNumberValue =
	(fieldName: string, min: number, max: number): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			if (v === 0 || v === undefined) {
				return Promise.reject(
					new Error(
						'Insira um valor de cota percentual para prosseguir',
					),
				);
			}

			if (v.includes(',')) {
				v = v.replace(',', '.');
			}

			const value = typeof v === 'string' ? Number(v) : v;
			if (
				!isFieldTouched(fieldName) ||
				!value ||
				(value >= min && value <= max)
			) {
				return Promise.resolve();
			}
			return Promise.reject(
				new Error(`Valor mínimo de ${min} e máximo de ${max}`),
			);
		},
	});

export const validateZeroPercentage =
	(fieldName: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			const value = typeof v === 'string' ? Number(v) : v;
			if (isFieldTouched(fieldName) && value === 0) {
				return Promise.reject(
					new Error('Insira um valor percentual para prosseguir'),
				);
			}

			return Promise.resolve();
		},
	});

export const validateHasValue =
	(fieldName: string, error: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			const value = typeof v === 'string' ? Number(v) : v;
			if (isFieldTouched(fieldName) && value === 0) {
				return Promise.reject(new Error(error));
			}

			return Promise.resolve();
		},
	});

export const greaterThanZeroMonthlyRevenueRule: RuleRender = ({
	isFieldTouched,
}) => ({
	validator(_, value) {
		if (!isFieldTouched('monthly_revenue') || !value) {
			return Promise.resolve();
		}

		if (Number(formatToCurrencyFloat(value)) > 0) {
			return Promise.resolve();
		}

		return Promise.reject(
			new Error('Faturamento mensal deve ser maior que zero'),
		);
	},
});

export const statementDateFromRule: RuleRender = () => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}

		if (value && !dayjs().isAfter(value as Dayjs)) {
			return Promise.reject(new Error('Data no futuro não é permitida'));
		}

		return Promise.resolve();
	},
});

export const weekendRule: RuleRender = () => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}

		if (value && [0, 6].includes((value as Dayjs).day())) {
			return Promise.reject(
				new Error('Finais de semana não são permitidos'),
			);
		}

		return Promise.resolve();
	},
});

export const holidaysRule: RuleRender = () => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}

		if (value) {
			const holidays = getHolidays((value as Dayjs).year());
			if (
				holidays
					.map(holiday => holiday.d)
					.includes((value as Dayjs).format('DD/MM/YYYY'))
			) {
				return Promise.reject(new Error('Feriados não são permitidos'));
			}
		}

		return Promise.resolve();
	},
});

export const statementDateToRule: RuleRender = ({ getFieldValue }) => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}

		if (value && !dayjs().isAfter(value as Dayjs)) {
			return Promise.reject(new Error('Data no futuro não é permitida'));
		}

		if (value && dayjs(value).isBefore(getFieldValue('dateFrom'))) {
			return Promise.reject(
				new Error('Data final deve ser maior que a data inicial'),
			);
		}

		if (value && dayjs(value).diff(getFieldValue('dateFrom'), 'day') > 30) {
			return Promise.reject(new Error('Intervalo máximo de 30 dias'));
		}

		return Promise.resolve();
	},
});

export const scheduledPostingsDateFromRule: RuleRender = () => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}

		if (
			value &&
			!dayjs()
				.startOf('day')
				.isBefore((value as Dayjs).endOf('day'))
		) {
			return Promise.reject(new Error('Data no passado não é permitida'));
		}

		return Promise.resolve();
	},
});

export const scheduledPostingsDateToRule: RuleRender = ({ getFieldValue }) => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve();
		}

		if (
			value &&
			!dayjs()
				.startOf('day')
				.isBefore((value as Dayjs).endOf('day'))
		) {
			return Promise.reject(new Error('Data no passado não é permitida'));
		}

		if (value && dayjs(value).isBefore(getFieldValue('dateFrom'))) {
			return Promise.reject(
				new Error('Data final deve ser maior que a data inicial'),
			);
		}

		return Promise.resolve();
	},
});

export const accountTypeOptions = [
	{
		label: 'Normal',
		value: 'default',
	},
	{
		label: 'Prime',
		value: 'prime',
	},
];

export const hasInvalidDate = (date: string) => {
	if (date.toString() === 'Invalid Date') {
		return true;
	}

	return false;
};

export const fieldGreaterThanZeroRule: RuleRender = () => ({
	validator(_, value) {
		if (typeof value === 'number' && value <= 0) {
			return Promise.reject('Valor deve ser maior que 0');
		}
		return Promise.resolve();
	},
});

export const greaterThanZeroRule =
	(fieldName: string): RuleRender =>
	({ isFieldTouched }) => ({
		validator(_, v) {
			const value = typeof v === 'string' ? Number(v) : v;
			if (isFieldTouched(fieldName) && value > 0) {
				return Promise.resolve();
			}

			return Promise.reject(new Error('Insira um valor maior que zero'));
		},
	});
