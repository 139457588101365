import { Button, Form } from 'antd';
import styled from 'styled-components';
import { UserSettingsPasswordType } from './types';

export const UserSettingsPasswordForm = styled(Form<UserSettingsPasswordType>)`
	padding: 0;
	margin: 0;
	min-height: 413px;
	display: flex;
	flex-direction: column;
`;

export const UserSettingsPasswordConfirmButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;
	margin-top: 2rem;

	p {
		font-size: 18px;
		color: white;
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const UserSettingsPasswordCancelButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;
	margin-top: 1rem;
	border: none;

	p {
		font-size: 18px;
		color: ${p => p.theme.primary};
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const UserSettingsPasswordFormItem = styled(Form.Item)`
	label {
		font-weight: 400;
		font-size: 1rem;
		color: ${p => p.theme.text};
	}
`;
