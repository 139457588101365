import React, { useCallback, useEffect, useState } from 'react';
import { TwoFactorContainer } from './styles';
import TwoFactorCodeForm from './TwoFactorCodeForm';
import TwoFactorSelector from './TwoFactorSelector';
import { ChallengeName, MFAOptions } from 'modules/core/types/Cognito';
import TwoFactorSetupTotpForm from './TwoFactorSetupTotpForm';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { useAuthLogo } from 'modules/core/context/AppContext';
import TwoFactorSetupSmsForm from './TwoFactorSetupSmsForm';
import { Heading2 } from 'components/Heading2';

enum TwoFactorStep {
	SELECTOR,
	SETUP,
	FORM,
}

const TwoFactorPage = () => {
	const { generateSecretMfa, cognitoUser } = useAuthContext();
	const [step, updateStep] = useState<TwoFactorStep>();
	const [totpSecret, updateTotpSecret] = useState<string>();
	const { hideAuthLogo } = useAuthLogo();

	useEffect(() => {
		if (
			cognitoUser &&
			cognitoUser.challengeName === ChallengeName.SOFTWARE_TOKEN_MFA
		) {
			updateStep(() => TwoFactorStep.FORM);
		} else {
			updateStep(TwoFactorStep.SELECTOR);
			hideAuthLogo();
		}
	}, []);

	const onSelectedMfaOption = useCallback(
		(option: MFAOptions) => {
			if (option === MFAOptions.SMS_MFA) {
				updateStep(TwoFactorStep.SETUP);
				return;
			}
			generateSecretMfa().then(u => {
				updateTotpSecret(u);
				updateStep(TwoFactorStep.SETUP);
			});
		},
		[generateSecretMfa],
	);

	return (
		<TwoFactorContainer>
			<Heading2>Autenticação de dois fatores</Heading2>
			{step === TwoFactorStep.SELECTOR && (
				<TwoFactorSelector onSelectMFAOption={onSelectedMfaOption} />
			)}
			{step === TwoFactorStep.SETUP && (
				<>
					{typeof totpSecret === 'string' ? (
						<TwoFactorSetupTotpForm totpSecret={totpSecret} />
					) : (
						<TwoFactorSetupSmsForm />
					)}
				</>
			)}
			{step === TwoFactorStep.FORM && <TwoFactorCodeForm />}
		</TwoFactorContainer>
	);
};

export default TwoFactorPage;
