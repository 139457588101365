export enum IdDocumentType {
	RG = 'RG',
	CNH = 'CNH',
	PASSPORT = 'PASSPORT',
	RNE = 'RNE',
	CLASS_ID = 'CLASS_ID',
	MILITARY_ID = 'MILITARY_ID',
}

export type IdDocument = {
	type: IdDocumentType;
	number: string;
	issuer: string;
	issue_date: string;
};
