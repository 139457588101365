import { Button } from 'antd';
import React from 'react';
import { LuCalendar } from 'react-icons/lu';
import { RangePickerButtonContainer } from './styles';
import { useTheme } from 'styled-components';
import { TextS } from 'components/Text';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';

export interface IRangePickerButtonProps {
	onClick?: () => void;
	initialDate?: string;
	finalDate?: string;
}

const RangePickerButton = ({
	initialDate,
	finalDate,
	onClick,
}: IRangePickerButtonProps) => {
	const theme = useTheme();
	return (
		<RangePickerButtonContainer onClick={onClick}>
			<LuCalendar color={theme.textGray} />
			<TextS color={theme.textGray}>
				de {initialDate ?? ''} a {finalDate ?? ''}
			</TextS>
			<BiChevronRight color={theme.textGray} />
		</RangePickerButtonContainer>
	);
};

export default RangePickerButton;
