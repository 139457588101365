import styled from 'styled-components';

export const TextArea = styled.textarea`
	width: 100%;
	height: 100px;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid ${({ theme }) => theme.inputBorder};
	resize: none;
	font-family: inherit;
`;
