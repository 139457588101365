import { Col, Row, notification } from 'antd';
import { Account, AccountUpdateDto } from 'types/Account';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { Documents } from 'components/Documents';
import { AccountDocumentsList } from 'constants/account';
import { AccountDestination } from 'components/AccountDestination';
import { useState } from 'react';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AccountsService } from 'modules/escrow/services';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { queryClient } from 'network/query';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import dayjs from 'dayjs';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';
import { FeeDescriptions } from 'components/FeeDescriptions';

interface IEscrowDetailsConfigurationProps {
	account: Account;
}
const EscrowDetailsConfiguration: React.FC<
	IEscrowDetailsConfigurationProps
> = ({ account }) => {
	const { type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const [isFeeSettingsModalOpen, toggleIsFeeSettingsModalOpen] =
		useState(false);
	const [isUpdateAccountInfoModalOpen, toggleUpdateAccountInfoModalOpen] =
		useState(false);

	const { mutate, isPending } = useMutation<void, ApiError, AccountUpdateDto>(
		{
			mutationFn: fees => {
				return AccountsService.updateAccount(account.id, fees);
			},
			onSuccess: () => {
				toggleIsFeeSettingsModalOpen(false);
				toggleUpdateAccountInfoModalOpen(true);
				queryClient.refetchQueries({
					queryKey: ['accountDetails', account.id],
				});
			},
			onError: e => {
				toggleIsFeeSettingsModalOpen(false);
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
		},
	);

	return (
		<>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<FeeDescriptions
						title="Tarifas"
						fees={account.fees!}
						handleOnEdit={
							type === AccessType.FINANCIAL_INSTITUTION
								? () => toggleIsFeeSettingsModalOpen(true)
								: undefined
						}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						title="Contratos"
						isDisabled={true}
						documentsObject={account.documents}
						documentsList={AccountDocumentsList}
					/>
				</Col>
			</Row>
			<Row
				gutter={[16, 16]}
				style={{
					marginTop: '2rem',
				}}
			>
				<Col span={24}>
					<DescriptionsVertical
						title="Informações"
						columns={1}
						items={[
							{
								key: '1',
								label: 'Titular',
								children: account.person.name,
							},
							{
								key: '2',
								label: 'Instituição',
								children:
									'509 - Celcoin Instituição de Pagamentos S.A',
							},
							{
								key: '3',
								label: 'Documento',
								children: account.person.taxpayer_id
									? normalizeCpfCnpj(
											account.person.taxpayer_id,
										)
									: '-',
							},
							{
								key: '4',
								label: 'Agência',
								children: account.branch ?? '-',
							},
							{
								key: '5',
								label: 'Conta',
								children: account.account
									? normalizeBankAccount(account.account)
									: '-',
							},
							{
								key: '6',
								label: 'Data de criação',
								children: account.fees?.maintenance?.amount
									? `${dayjs(account.created_at).format(
											'DD/MM/YYYY',
										)} às ${dayjs(account.created_at).format('HH:mm:ss')}`
									: '-',
							},
							{
								key: '7',
								label: 'Data de ativação',
								children: account.activated_at
									? `${dayjs(account.activated_at).format(
											'DD/MM/YYYY',
										)} às ${dayjs(account.activated_at).format('HH:mm:ss')}`
									: '-',
							},
						]}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<AccountDestination
						accountId={account.id}
						destinations={account.destinations}
					/>
				</Col>
			</Row>
			<FeeSettingsModalComission
				accountId={account.id}
				isOpen={isFeeSettingsModalOpen}
				onClose={() => toggleIsFeeSettingsModalOpen(false)}
				feesSettings={account.fees}
				loading={isPending}
				handleConfirm={values => {
					mutate({
						fees: values,
					});
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUpdateAccountInfoModalOpen}
				message="As tarifas foram atualizadas com sucesso!"
				title="Tarifas"
				icon={<CheckmarkIcon />}
				onClose={() => toggleUpdateAccountInfoModalOpen(false)}
			/>
		</>
	);
};

export default EscrowDetailsConfiguration;
