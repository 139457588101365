import { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { DatePicker } from 'components/DatePicker';
import { StepContainer, StepFooter, StepHeader } from '../styles';
import { Heading2 } from 'components/Heading2';
import dayjs from 'dayjs';
import { Representative } from 'types/Representative';
import { IdDocumentDescription } from 'constants/representatives';
import { IdDocumentType } from 'types/IdDocument';
import { maxTodayDateRule } from 'helpers/rules';
import { useForm } from 'antd/es/form/Form';
import { useTheme } from 'styled-components';

interface IIdentificationStepProps {
	representative?: Representative;
	isLegalRepresentative?: boolean;
	onNext: (representative: Representative) => void;
	onBack: (representative: Representative) => void;
}
const IdentificationStep = ({
	representative,
	isLegalRepresentative,
	onNext,
	onBack,
}: IIdentificationStepProps) => {
	const [form] = useForm();
	const theme = useTheme();
	const hasRepresentative =
		typeof representative !== 'undefined' &&
		typeof representative.id_document !== 'undefined' &&
		Object.keys(representative.id_document).length > 0;
	const [formValid, setFormValid] = useState(hasRepresentative);

	return (
		<StepContainer>
			<StepHeader>
				<Heading2
					style={{
						color: theme.primary,
					}}
				>
					Identificação do Procurador
				</Heading2>
			</StepHeader>
			<Form
				style={{ width: '100%' }}
				form={form}
				initialValues={{
					type: representative?.id_document?.type,
					number: representative?.id_document?.number,
					issue_date:
						representative?.id_document?.issue_date &&
						dayjs(
							representative.id_document.issue_date,
							'YYYY-MM-DD',
						),
					issuer: representative?.id_document?.issuer,
				}}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched && !hasRepresentative) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
				}}
				onFinish={values => {
					onNext({
						id_document: {
							type: values.type,
							number: values.number,
							issue_date: values.issue_date.format('YYYY-MM-DD'),
							issuer: values.issuer,
						},
					} as Representative);
				}}
			>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="type"
							label="Tipo de documento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={Object.keys(IdDocumentDescription).map(
									key => ({
										label: IdDocumentDescription[
											key as unknown as IdDocumentType
										],
										value: key,
									}),
								)}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="number"
							label="Número do documento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Form.Item
							name="issue_date"
							label="Data de emissão"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('issue_date'),
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="issuer"
							label="Emissor"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Digite aqui" />
						</Form.Item>
					</Col>
				</Row>
				<StepFooter>
					<Button
						type="link"
						onClick={() =>
							onBack({
								id_document: form.getFieldsValue(),
							} as Representative)
						}
					>
						Voltar
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						Avançar
					</Button>
				</StepFooter>
			</Form>
		</StepContainer>
	);
};

export default IdentificationStep;
