import React, { useEffect } from 'react';
import {
	NewPasswordFlow,
	useAuthContext,
} from 'modules/core/context/AuthContext';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SplashScreen } from 'components/SplashScreen';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AuthService } from 'modules/core/services/AuthService';
import { useAuthLogo } from 'modules/core/context/AppContext';

const ResetPasswordPage: React.FC = () => {
	const [api, contextHolder] = notification.useNotification();
	const { hideAuthLogo } = useAuthLogo();
	const { startNewPasswordFlow, newPasswordFlow } = useAuthContext();
	const { search } = useLocation();
	const navigate = useNavigate();

	const { mutate, isPending } = useMutation<
		{ temporary_password: string },
		ApiError,
		{ username: string; token: string }
	>({
		mutationFn: ({ username, token }) => {
			return AuthService.confirmResetPassword(username, token);
		},
		onSuccess: ({ temporary_password }, { username }) =>
			startNewPasswordFlow(
				username,
				temporary_password,
				NewPasswordFlow.RESET_PASSWORD,
			),
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao solicitar o reset de senha.',
			});
			setTimeout(() => navigate('/login'), 1300);
		},
	});

	useEffect(() => {
		hideAuthLogo();
		const params = new URLSearchParams(search);
		const tokenUrl = params.get('token');
		const emailUrl = params.get('email');

		if (newPasswordFlow === null && !isPending) {
			if (typeof tokenUrl === 'string' && typeof emailUrl === 'string') {
				const token = tokenUrl.replace(/-/g, '+').replace(/_/g, '/');
				const email = emailUrl.replace(/-/g, '+').replace(/_/g, '/');
				const decodedToken = atob(token);
				const decodedEmail = atob(email);
				mutate({
					token: decodedToken,
					username: decodedEmail,
				});
			} else {
				navigate('/login');
			}
		}
	}, []);

	if (newPasswordFlow === NewPasswordFlow.RESET_PASSWORD) {
		return <Navigate to="/new-password" />;
	}

	return (
		<>
			<SplashScreen />
			{contextHolder}
		</>
	);
};

export default ResetPasswordPage;
