import { useEffect, useMemo, useRef } from 'react';
import { InfinityScrollContent, InfinityScrollLoader } from './styles';
import { Spin } from 'antd';

export interface IInfinityScrollProps {
	onRequest: () => void;
	isLoading: boolean;
}

const InfinityScroll = ({ onRequest, isLoading }: IInfinityScrollProps) => {
	const loaderRef = useRef<HTMLDivElement>(null);

	const createObserver = useMemo(() => {
		const options = {
			root: null,
			rootMargin: '20px',
			threshold: 1,
		};

		const observer = new IntersectionObserver(entities => {
			const target = entities[0];

			if (target.isIntersecting) {
				onRequest();
			}
		}, options);

		return observer;
	}, [onRequest]);

	useEffect(() => {
		const observer = createObserver;
		const currentLoader = loaderRef.current;

		if (currentLoader) {
			observer.observe(currentLoader);
		}

		return () => {
			if (currentLoader) {
				observer.disconnect();
				observer.unobserve(currentLoader);
			}
		};
	}, [createObserver]);

	return (
		<>
			<InfinityScrollLoader ref={loaderRef} />
			{isLoading && (
				<InfinityScrollContent>
					<Spin />
					<div>Carregando...</div>
				</InfinityScrollContent>
			)}
		</>
	);
};

export default InfinityScroll;
