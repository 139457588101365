import { notification } from 'antd';
import { Company, CompanyDto } from 'types/Company';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { queryClient } from 'network/query';
import { PersonRepresentatives } from 'components/PersonRepresentatives';

interface IPersonDetailsRepresentativesProps {
	person: Company;
}
const NaturalPersonDetailsRepresentatives: React.FC<
	IPersonDetailsRepresentativesProps
> = ({ person }) => {
	const [api, contextHolder] = notification.useNotification();

	const { mutate } = useMutation<void, ApiError, Partial<CompanyDto>>({
		mutationFn: companyDto => {
			return PersonsService.patchCompany(person.id, companyDto);
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['companyDetails', person.id],
			});
			api.success({
				message: 'Pessoa atualizada com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao atualizar a pessoa.',
			});
		},
	});

	return (
		<>
			{contextHolder}
			<PersonRepresentatives
				isCompanyEdition={true}
				person={person}
				onBack={() => {}}
				onNext={newCompany => {
					mutate(newCompany);
				}}
			/>
		</>
	);
};

export default NaturalPersonDetailsRepresentatives;
