import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import CelcoinLogo from './CelcoinLogo.png';
import dayjs from 'dayjs';
import { formatCurrency, normalizeBoleto } from 'helpers/normalizers';
import { Posting } from 'types/Posting';
import { StatementStatusResponse } from 'types/Account';

export interface StatementBoletoReceiptPdfProps {
	movementData: StatementStatusResponse;
	postingDetails?: Posting;
}

Font.register({
	family: 'Urbanist',
	fonts: [
		{
			src: `/Urbanist.ttf`,
		},
		{
			src: `/Urbanist-SemiBold.ttf`,
			fontWeight: 'semibold',
		},
	],
});

const styles = StyleSheet.create({
	hero: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#806DFB',
		padding: 10,
	},
	title: {
		fontSize: 16,
		textAlign: 'center',
		width: '100%',
		color: '#1E1360',
		marginTop: 20,
		fontWeight: 'bold',
	},
	subtitle: {
		fontSize: 13,
		textAlign: 'center',
		width: '100%',
		color: '#6F6B82',
	},
	amount: {
		marginTop: 3,
		fontSize: 13,
		textAlign: 'center',
		width: '100%',
		color: '#806DFB',
	},
	sectionWrapper: {
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		border: 2,
		borderStyle: 'solid',
		borderColor: '#F7F7F7',
		padding: 20,
		borderRadius: 5,
	},
	sectionTitle: {
		fontSize: 14,
		width: '100%',
		fontWeight: 'semibold',
	},
	sectionRow: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		marginTop: 12,
	},
	rowTitle: {
		fontSize: 10,
		fontWeight: 'semibold',
	},
	rowValue: {
		fontSize: 10,
		color: '#6F6B82',
	},
	footer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#F7F7F7',
		padding: 20,
	},
	footerColumn: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
	},
});

const StatementBoletoReceiptPdf = ({
	movementData,
	postingDetails,
}: StatementBoletoReceiptPdfProps) => {
	return (
		<Document>
			<Page size="A4">
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>
					<View>
						<View style={styles.hero}>
							<View
								style={{
									height: '80px',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Image
									src={CelcoinLogo}
									style={{ width: '182px' }}
								/>
							</View>
						</View>
						<View>
							<Text style={styles.title}>
								Comprovante de Pagamento
							</Text>
						</View>

						<View>
							<Text style={{ ...styles.subtitle, marginTop: 16 }}>
								Valor
							</Text>
							<Text style={styles.amount}>
								{formatCurrency(movementData.amount)}
							</Text>
						</View>

						<View style={styles.sectionWrapper}>
							<View style={{ ...styles.section, marginTop: 10 }}>
								<Text style={styles.sectionTitle}>
									Dados da cobrança:
								</Text>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Pagador
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{postingDetails?.billpayment_payer
												? postingDetails?.billpayment_payer.includes(
														'undefined',
													)
													? '-'
													: postingDetails?.billpayment_payer
												: '-'}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Beneficiário
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{postingDetails?.billpayment_beneficiary
												? postingDetails?.billpayment_beneficiary.includes(
														'undefined',
													)
													? '-'
													: postingDetails?.billpayment_beneficiary
												: '-'}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Código de barras
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{normalizeBoleto(
												postingDetails?.bar_code,
											)}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Identificador
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												postingDetails?.billpayment_txn_id_auth
											}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Data de pagamento
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{dayjs(
												movementData.payment_date,
												'YYYY-MM-DD',
											).format('DD/MM/YYYY')}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Valor do documento
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{formatCurrency(
												movementData.amount,
											)}
										</Text>
									</View>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.footer}>
						<View style={styles.footerColumn}>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								Gerado em
							</Text>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								{dayjs().format('DD/MM/YYYY')} às{' '}
								{dayjs().format('HH:mm')}
							</Text>
							<Text
								style={{
									fontSize: 10,
									color: '#6F6B82',
									marginTop: 10,
								}}
							>
								Código de Identificação
							</Text>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								{postingDetails?.id || '-'}
							</Text>
						</View>
						<View style={styles.footerColumn}>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								Celcoin Instituição de Pagamento S.A
							</Text>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								13.935.893/0001-09
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default StatementBoletoReceiptPdf;
