import styled from 'styled-components';

export const TableWrapper = styled.div`
	margin-top: 24px;

	.ant-table-wrapper {
		.ant-spin-nested-loading {
			.ant-spin-container {
				.ant-table {
					.ant-table-container {
						.ant-table-content {
							table {
								tbody {
									.ant-table-expanded-row {
										.ant-table-cell {
											background-color: white;
											background: white !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.custom-row {
		background-color: ${({ theme }) => theme.primaryOpacity} !important;
	}
`;

export const StyledDatePicker = styled.div`
	.modified {
		width: 100;
		border: 1px solid green;
		animation: changeBorderColor 5s forwards;
	}

	@keyframes changeBorderColor {
		0% {
			border-color: green;
		}
		100% {
			border-color: #d9d9d9;
		}
	}
`;
