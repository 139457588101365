import React from 'react';

export const ManagementSvgIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
		<path
			fill={color ?? '#7664FA'}
			d="M3.783 3.064 12 1.238l8.217 1.826A1 1 0 0 1 21 4.04v9.987a6 6 0 0 1-2.672 4.993L12 23.238 5.672 19.02A6 6 0 0 1 3 14.027V4.04a1 1 0 0 1 .783-.976ZM5 4.843v9.184a4 4 0 0 0 1.781 3.328L12 20.835l5.219-3.48A4 4 0 0 0 19 14.027V4.843l-7-1.556-7 1.556Zm7 6.395a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm-4.473 5a4.5 4.5 0 0 1 8.945 0H7.527Z"
		/>
	</svg>
);
