import { Button, Col, Divider, Row, Popover } from 'antd';
import { PopoverProps } from 'antd/lib';
import { TextM } from 'components/Text';
import { useState } from 'react';
import { RiThumbDownLine, RiThumbUpLine } from 'react-icons/ri';
import { LuEye } from 'react-icons/lu';
import { useTheme } from 'styled-components';
import { Posting, PostingStatus } from 'types/Posting';
import { PopoverButton } from './styles';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { useNavigate } from 'react-router-dom';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';
import { contaLivre } from 'modules/escrow/constants/routes';

export interface TablePopoverProps extends PopoverProps {
	posting: Posting;
	status: PostingStatus;
	handleClick: (type: string, posting: Posting) => void;
}

const TablePopover = ({
	posting,
	status,
	onOpenChange,
	handleClick,
}: TablePopoverProps) => {
	const [visible, setVisible] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();
	const { person: company } = useCurrentProfile();
	const accountRoute = !useIsEscrowAccountRoute() ? contaLivre : 'escrow';

	const handleChange = (open: boolean) => {
		setVisible(open);
		onOpenChange?.(open);
	};

	const handleSelect = (type: string) => {
		setVisible(false);
		handleClick(type, posting);
	};

	const handleNavigate = () => {
		navigate(`/${accountRoute}/approvals/${posting?.id}/info`);
	};

	return (
		<Popover
			placement="bottomRight"
			onOpenChange={handleChange}
			open={visible}
			trigger="click"
			content={() => (
				<Col>
					{status === PostingStatus.PENDING_APPROVAL ? (
						<>
							<Row>
								<PopoverButton
									onClick={() => handleSelect('approve')}
									disabled={!company}
								>
									<RiThumbUpLine
										size={'1rem'}
										color={theme.textSecondary}
									/>
									<TextM style={{ marginLeft: '0.5rem' }}>
										Aprovar
									</TextM>
								</PopoverButton>
							</Row>
							<Divider style={{ margin: '1rem 0' }} />
							<Row>
								<PopoverButton
									onClick={() => handleSelect('reject')}
									disabled={!company}
								>
									<RiThumbDownLine
										size={'1rem'}
										color={theme.textSecondary}
									/>
									<TextM style={{ marginLeft: '0.5rem' }}>
										Rejeitar
									</TextM>
								</PopoverButton>
							</Row>
						</>
					) : (
						<Row>
							<PopoverButton onClick={handleNavigate}>
								<LuEye
									size={'1rem'}
									color={theme.textSecondary}
								/>
								<TextM style={{ marginLeft: '0.5rem' }}>
									Visualizar
								</TextM>
							</PopoverButton>
						</Row>
					)}
				</Col>
			)}
		>
			<Button
				style={{
					backgroundColor: 'transparent',
					border: 'none',
					boxShadow: 'none',
				}}
			>
				<TextM weight="bold">...</TextM>
			</Button>
		</Popover>
	);
};

export default TablePopover;
