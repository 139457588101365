import { Form } from 'antd';
import styled from 'styled-components';
import { CollectionsFiltersType } from './types';

export const FiltersTitle = styled.div`
	display: flex;
	p {
		padding-left: 0.3rem;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
	align-items: flex-end;
`;

export const FiltersForm = styled(Form<CollectionsFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const FiltersFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}
`;

export const DropdownIcon = styled.div<{ size?: string }>`
	display: flex;
	align-items: center;
	min-width: 18px !important;
	svg {
		width: ${({ size }) => size ?? '15px'};
		height: ${({ size }) => size ?? '15px'};
	}
`;
