import styled, { CSSProperties } from 'styled-components';

interface ITextProps {
	weight?: CSSProperties['fontWeight'];
	color?: string;
}

// Font size: 12px
export const TextSS = styled.p<ITextProps>`
	color: ${p => p.color ?? p.theme.text};
	font-size: 0.75rem;
	font-weight: ${p => p.weight ?? 'normal'};
	margin: 0;
`;

// Font size: 14.4px
export const TextS = styled.p<ITextProps>`
	color: ${p => p.color ?? p.theme.text};
	font-size: 0.9rem;
	font-weight: ${p => p.weight ?? 'normal'};
	margin: 0;
`;

// Font size: 16px
export const TextM = styled.p<ITextProps>`
	color: ${p => p.color ?? p.theme.text};
	font-size: 1rem;
	font-weight: ${p => p.weight ?? 'normal'};
	margin: 0;
`;

// Font size: 19.2px
export const TextL = styled.p<ITextProps>`
	color: ${p => p.color ?? p.theme.text};
	font-size: 1.2rem;
	font-weight: ${p => p.weight ?? 'normal'};
	margin: 0;
`;
