import React, { useContext } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form, Input, notification } from 'antd';
import {
	ForgotPasswordBack,
	ForgotPasswordFormContainer,
	ForgotPasswordFormSubmit,
} from './styles';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AppContext } from 'modules/core/context/AppContext';
import { AuthService } from 'modules/core/services/AuthService';

interface IForgotPasswordFormProps {
	onRequestSuccess: (username: string) => void;
}
const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = ({
	onRequestSuccess,
}) => {
	const { financialInstitutionId } = useContext(AppContext);
	const [api, contextHolder] = notification.useNotification();

	const { mutate, isPending } = useMutation<
		void,
		ApiError,
		{ username: string }
	>({
		mutationKey: [financialInstitutionId],
		mutationFn: ({ username }) => {
			return AuthService.requestResetPassword(
				username,
				financialInstitutionId,
			);
		},
		onSuccess: (r, { username }) => onRequestSuccess(username),
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao solicitar o reset de senha.',
			});
		},
	});

	return (
		<ForgotPasswordFormContainer
			style={{ width: '90%' }}
			layout="vertical"
			onFinish={values => {
				mutate({
					username: values.username,
				});
			}}
		>
			<Form.Item
				name="username"
				label="Usuário:"
				rules={[{ required: true, message: 'Campo obrigatório' }]}
			>
				<Input prefix={<UserOutlined />} placeholder="Usuário" />
			</Form.Item>
			<ForgotPasswordFormSubmit
				loading={isPending}
				type="primary"
				htmlType="submit"
			>
				Redefinir
			</ForgotPasswordFormSubmit>
			<ForgotPasswordBack to="/login">Voltar</ForgotPasswordBack>
			{contextHolder}
		</ForgotPasswordFormContainer>
	);
};

export default ForgotPasswordForm;
