import { Button } from 'antd';
import styled from 'styled-components';

export const MenuButtonContainer = styled(Button)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: ${p => p.theme.primaryOpacity};
	padding: 0.5rem;
	border: 1px solid ${p => p.theme.border};
	outline: none;
	box-shadow: none;

	&:hover {
		background-color: ${p => p.theme.primaryOpacity}!important;
	}
`;
