import { Button, Form } from 'antd';
import styled from 'styled-components';
import { ConfirmationForm, PaymentInputForm, PaymentDateForm } from './types';
import { Collapse } from 'components/Collapse';

export const PaymentCreationInputForm = styled(Form<PaymentInputForm>)`
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
`;

export const PaymentCreationDateForm = styled(Form<PaymentDateForm>)`
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
`;

export const PaymentConfirmationForm = styled(Form<ConfirmationForm>)`
	padding: 0;
	margin: 0;
	min-height: 413px;
	display: flex;
	flex-direction: column;
`;

export const DateFormIcon = styled.div`
	svg {
		width: 1.6rem;
		height: 1.6rem;
	}
`;

export const PaymentCreationDateCollapse = styled(Collapse)`
	.ant-collapse-header {
		height: 42px !important;
	}
	.ant-collapse-content-box {
		padding: 8px 12px !important;
	}
`;

export const PaymentCreationDateAmount = styled.div`
	position: absolute;
	right: 16px;
	top: 10px;
	color: ${p => p.theme.danger};
	font-weight: bold;
	font-size: 1rem;
`;

export const PaymentCreationDateInfo = styled.div`
	display: flex;
	p {
		margin-top: 2px;
		font-size: 12px;
	}
`;

export const ForwardButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;

	p {
		font-size: 18px;
		color: white;
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const CancelButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;
	margin-top: 1rem;

	p {
		font-size: 18px;
		color: ${p => p.theme.primary};
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const DescriptionsContainer = styled.div`
	.ant-descriptions-view {
		border: none !important;
		padding: 0px 8px !important;
	}

	.ant-descriptions-item-label {
		background-color: transparent !important;
		border-inline-end: none !important;
		padding: 3px 16px !important;
		font-weight: bold;
		font-size: 12px;
		color: ${p => p.theme.textSecondary} !important;
	}

	.ant-descriptions-item-content {
		padding: 3px 16px !important;
		text-transform: uppercase;
		font-size: 12px;
		color: ${p => p.theme.textGray} !important;
	}

	.ant-descriptions-row {
		border-bottom: none !important;
	}
`;

export const PlaceholderDarkInput = styled(Form.Item)`
	input {
		&::placeholder {
			color: ${p => p.theme.textSecondary};
			opacity: 1;
		}
	}
`;

export const ReceiptFooter = styled.div`
	height: 100px;
	color: ${p => p.theme.textGray};
	background-color: ${p => p.theme.backgroundGray};
	display: flex;
	justify-content: space-between;
	padding: 16px 40px;
`;

export const ReceiptContent = styled.div`
	padding: 18px 24px 18px;
`;

export const ReviewArea = styled.div`
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: ${({ theme }) => theme.primary};
	}
`;

export const ModalWrapper = styled.div`
	::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: transparent;
	}

	::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: ${({ theme }) => theme.primary};
	}
`;
