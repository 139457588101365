import { Company } from './Company';
import { Creator } from './Creator';
import { User } from './User';

export enum AccessType {
	FINANCIAL_INSTITUTION = 'FINANCIAL_INSTITUTION',
	PERSON = 'PERSON',
}

export enum AccessPermission {
	VIEW_ACCOUNT = 'VIEW_ACCOUNT',
	MANAGE_USER = 'MANAGE_USER',

	/* Posting */
	POSTING_CREATE = 'POSTING_CREATE',
	POSTING_REVIEW = 'POSTING_REVIEW',
	POSTING_VIEW = 'POSTING_VIEW',

	/* Wallet */
	CREATE_WALLET = 'CREATE_WALLET',
	VIEW_WALLET = 'VIEW_WALLET',
	UPDATE_WALLET = 'UPDATE_WALLET',
	ARCHIVE_WALLET = 'ARCHIVE_WALLET',

	/* Charges */
	CREATE_CHARGE = 'CREATE_CHARGE',
	CANCEL_CHARGE = 'CANCEL_CHARGE',
	VIEW_CHARGE = 'VIEW_CHARGE',
	UPDATE_CHARGE = 'UPDATE_CHARGE',

	/* Retention */
	CREATE_BALANCE_RETENTION = 'CREATE_RETENTION',
	REMOVE_BALANCE_RETENTION = 'REMOVE_RETENTION',
	VIEW_BALANCE_RETENTION = 'VIEW_RETENTION',

	/* Account */
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	CREATE_BENEFICIARY = 'CREATE_BENEFICIARY',
}

export type AccessDto = {
	type: AccessType;
	personId?: string;
	permissions: AccessPermission[];
};

export type AccessContent = {
	id: string;
	financial_institution: { id: string; name: string };
	type: AccessType;
	permissions: AccessPermission[];
	person?: {
		id: string;
		name: string;
		corporate_name: string;
		taxpayer_id: string;
	};
};

export type AccessListContent = {
	id: string;
	financial_institution: { id: string };
	type: AccessType;
	permissions: AccessPermission[];
	creator: Creator;
	user: User;
	created_at: string;
	person?: {
		id: string;
		name: string;
	};
};

export type AccessListDetail = {
	id: string;
	financial_institution: { id: string; name: string };
	type: AccessType;
	permissions: AccessPermission[];
	person?: Company;
};

export type Ability = {
	rules: {
		action: string | string[];
		subject?: string | string[];
		/** an array of fields to which user has (or not) access */
		fields?: string[];
		/** an object of conditions which restricts the rule scope */
		conditions?: any;
		/** indicates whether rule allows or forbids something */
		inverted?: boolean;
		/** message which explains why rule is forbidden */
		reason?: string;
	}[];
};

export type AccessFilters = {
	page?: number;
	size?: number;
	order?: 'ASC' | 'DESC';
	sort?: string;
	search?: string;
	person_id?: string;
	taxpayer_id?: string;
	email_address?: string;
};
