import React from 'react';

export const BarCodeIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="22"
		height="23"
		viewBox="0 0 22 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.83301 4.16602H3.66634V18.8327H1.83301V4.16602ZM5.49967 4.16602H6.41634V18.8327H5.49967V4.16602ZM7.33301 4.16602H9.16634V18.8327H7.33301V4.16602ZM10.083 4.16602H11.9163V18.8327H10.083V4.16602ZM12.833 4.16602H14.6663V18.8327H12.833V4.16602ZM15.583 4.16602H16.4997V18.8327H15.583V4.16602ZM17.4163 4.16602H20.1663V18.8327H17.4163V4.16602Z"
			fill={color ?? '#F4F4FF'}
		/>
	</svg>
);
