import { useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Col,
	Flex,
	Form,
	Input,
	InputRef,
	Row,
	Select,
	notification,
} from 'antd';
import {
	normalizeCep,
	normalizeCnpj,
	normalizePhone,
} from 'helpers/normalizers';
import { DatePicker } from 'components/DatePicker';
import { StyledSelect, Footer, Spinner } from './styles';
import {
	CompanyDocuments,
	CompanyDto,
	BusinessType,
	ETenantType,
	Company,
} from 'types/Company';
import {
	CompanyDocumentsList,
	CompanyTypeDescription,
} from 'constants/company';
import { CompanyForm } from './types';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import { formatPhoneFromObject } from 'helpers/formaters';
import { statesList } from 'constants/states';
import { PiSpinnerBold } from 'react-icons/pi';
import { accountTypeOptions, cnpjRule, maxTodayDateRule } from 'helpers/rules';
import { useCompaniesList, useCompany } from 'modules/company/hooks';
import { validateCnpj } from 'helpers/validators';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { companyRevenueOptions } from 'modules/company/utils/options';
import { parsedCnaeData } from 'modules/company/utils/cnae';
import { StepsId } from 'modules/escrow/pages/EscrowCreation/types';
import { SaveIcon } from 'components/Icons/Save';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';

interface ICompanyInformationProps {
	company?: Company | CompanyDto;
	isAccountCreation?: boolean;
	onResetRepresentatives?: () => void;
	onNext: (company: Company | CompanyDto) => void;
	handleStepStatus?: (stepsId: StepsId, status: boolean) => void;
	tenantType?: ETenantType;
	setTenantType: (value?: ETenantType) => void;
	cnpj?: string;
	isPersonCreating?: boolean;
	setIsPersonCreating?: (value: boolean) => void;
}
const CompanyInformation = ({
	company,
	isAccountCreation,
	onNext,
	onResetRepresentatives,
	handleStepStatus,
	setTenantType,
	tenantType,
	cnpj,
	isPersonCreating,
	setIsPersonCreating,
}: ICompanyInformationProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const { type } = useCurrentProfile();
	const hasCompany = company && Object.keys(company).length > 0;
	const [formValid, setFormValid] = useState(hasCompany);
	const legalNameRef = useRef<InputRef>(null);
	const taxpayerIdRef = useRef<InputRef>(null);
	const [api, contextHolder] = notification.useNotification();
	const [isFormDisabled, toggleFormDisabled] = useState<boolean>(
		!company || typeof company.id === 'string',
	);
	const [isDocumentsInvalid, toggleDocumentsInvalid] =
		useState<boolean>(false);
	const [uploadDocuments, updateUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	const { companies: managerCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.MANAGER,
			size: 100,
		},
	});
	const { companies: custodianCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.CUSTODIAN,
			size: 100,
		},
	});
	const { companies: adminCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.ADMINISTRATOR,
			size: 100,
		},
	});

	useEffect(() => {
		setFormValid(hasCompany);
	}, [hasCompany]);

	useEffect(() => {
		if (type === AccessType.PERSON && isPersonCreating) {
			const mockResponse = '*****';
			form.setFieldValue('business_type', mockResponse);
			form.setFieldValue(
				'foundation_date',
				dayjs(company?.foundation_date || new Date(), 'YYYY-MM-DD'),
			);
			form.setFieldValue('cnae', mockResponse);
			form.setFieldValue('email_address', mockResponse);
			form.setFieldValue('phone', mockResponse);
			form.setFieldValue('average_monthly_revenue', mockResponse);
			form.setFieldValue('postal_code', mockResponse);
			form.setFieldValue('state_code', mockResponse);
			form.setFieldValue('city', mockResponse);
			form.setFieldValue('district', mockResponse);
			form.setFieldValue('street_name', mockResponse);
			form.setFieldValue('street_number', mockResponse);
			form.setFieldValue('extra_info', mockResponse);
			form.setFieldValue('fund_manager_id', mockResponse);
			form.setFieldValue('fund_securitizer_id', mockResponse);
			form.setFieldValue('fund_administrator_id', mockResponse);

			updateUploadDocuments({
				incorporation_certificate: mockResponse,
				proof_of_address: mockResponse,
				bylaws: mockResponse,
				income_statement: mockResponse,
				last_contract_amendment: mockResponse,
				power_of_attorney: mockResponse,
				incorporation_certificate_url: mockResponse,
				proof_of_address_url: mockResponse,
				bylaws_url: mockResponse,
				income_statement_url: mockResponse,
				last_contract_amendment_url: mockResponse,
				power_of_attorney_url: mockResponse,
				document_identification: mockResponse,
				identification_url: mockResponse,
			});
		}
	}, [company, isPersonCreating]);

	const {
		getCompanyByTaxpayerId,
		company: loadedCompany,
		loading,
	} = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message:
					'Ocorreu um problema ao buscar informações da empresa.',
			});
		},
		onSuccess: (newCompany, hasPrevCompany, accounts) => {
			if (!newCompany || (isAccountCreation && accounts.length >= 2)) {
				if (isAccountCreation) {
					if (hasPrevCompany || typeof company !== 'undefined') {
						form.setFieldValue('business_type', undefined);
						form.setFieldValue('corporate_name', undefined);
						form.setFieldValue('name', undefined);
						form.setFieldValue('foundation_date', undefined);
						form.setFieldValue('cnae', undefined);
						form.setFieldValue('email_address', undefined);
						form.setFieldValue('phone', undefined);
						form.setFieldValue(
							'average_monthly_revenue',
							undefined,
						);
						form.setFieldValue('postal_code', undefined);
						form.setFieldValue('state_code', undefined);
						form.setFieldValue('city', undefined);
						form.setFieldValue('district', undefined);
						form.setFieldValue('street_name', undefined);
						form.setFieldValue('street_number', undefined);
						form.setFieldValue('extra_info', undefined);
						form.setFieldValue('fund_manager_id', undefined);
						form.setFieldValue('fund_securitizer_id', undefined);
						form.setFieldValue('fund_administrator_id', undefined);
						updateUploadDocuments(() => ({}) as CompanyDocuments);

						if (typeof onResetRepresentatives === 'function') {
							onResetRepresentatives();
						}
					}

					toggleFormDisabled(false);
					setFormValid(false);

					if (accounts.length >= 2) {
						setTimeout(() => taxpayerIdRef.current?.focus(), 100);
						form.setFieldValue('taxpayer_id', '');
						api.error({
							message: 'Limite de contas atingido',
							description:
								'Você atingiu o limite de contas criadas para esta empresa.',
						});
					} else {
						setTimeout(() => legalNameRef.current?.focus(), 100);
					}
				}
				return;
			}

			if (!isAccountCreation && !cnpj) {
				api.error({
					message: 'Empresa já cadastrada',
					description: `${newCompany.name} já está cadastrada no sistema.`,
				});
				form.setFieldValue('taxpayer_id', '');
				setTimeout(() => taxpayerIdRef.current?.focus(), 100);
				return;
			}

			if (cnpj) {
				form.setFieldValue('taxpayer_id', cnpj);
			}

			form.setFieldValue('corporate_name', newCompany.corporate_name);
			form.setFieldValue('name', newCompany.name);

			if (type === AccessType.FINANCIAL_INSTITUTION) {
				form.setFieldValue('business_type', newCompany.business_type);
				form.setFieldValue(
					'foundation_date',
					dayjs(newCompany.foundation_date, 'YYYY-MM-DD'),
				);
				form.setFieldValue('cnae', newCompany.cnae);
				form.setFieldValue('email_address', newCompany.email_address);
				form.setFieldValue(
					'phone',
					formatPhoneFromObject(newCompany.phone),
				);
				form.setFieldValue(
					'average_monthly_revenue',
					newCompany.average_monthly_revenue,
				);
				form.setFieldValue(
					'postal_code',
					newCompany.address?.postal_code,
				);
				form.setFieldValue(
					'state_code',
					newCompany.address?.state_code,
				);
				form.setFieldValue('city', newCompany.address?.city);
				form.setFieldValue('district', newCompany.address?.district);
				form.setFieldValue(
					'street_name',
					newCompany.address?.street_name,
				);
				form.setFieldValue(
					'street_number',
					newCompany.address?.street_number,
				);
				form.setFieldValue(
					'extra_info',
					newCompany.address?.extra_info,
				);
				form.setFieldValue(
					'fund_manager_id',
					newCompany.fund_manager_id,
				);
				form.setFieldValue(
					'fund_securitizer_id',
					newCompany.fund_securitizer_id,
				);
				form.setFieldValue(
					'fund_administrator_id',
					newCompany.fund_administrator_id,
				);

				updateUploadDocuments(newCompany.documents);
			} else {
				const mockResponse = '*****';
				form.setFieldValue('business_type', mockResponse);
				form.setFieldValue(
					'foundation_date',
					dayjs(newCompany.foundation_date, 'YYYY-MM-DD'),
				);
				form.setFieldValue('cnae', mockResponse);
				form.setFieldValue('email_address', mockResponse);
				form.setFieldValue('phone', mockResponse);
				form.setFieldValue('average_monthly_revenue', mockResponse);
				form.setFieldValue('postal_code', mockResponse);
				form.setFieldValue('state_code', mockResponse);
				form.setFieldValue('city', mockResponse);
				form.setFieldValue('district', mockResponse);
				form.setFieldValue('street_name', mockResponse);
				form.setFieldValue('street_number', mockResponse);
				form.setFieldValue('extra_info', mockResponse);
				form.setFieldValue('fund_manager_id', mockResponse);
				form.setFieldValue('fund_securitizer_id', mockResponse);
				form.setFieldValue('fund_administrator_id', mockResponse);
				setIsPersonCreating && setIsPersonCreating(true);
			}

			setFormValid(true);
			toggleFormDisabled(true);
		},
	});

	useEffect(() => {
		if (company && company.documents) {
			updateUploadDocuments(company.documents);
		}
	}, [company]);

	const isDocumentsValid = useMemo(() => {
		return (
			(typeof uploadDocuments['income_statement'] === 'string' &&
				typeof uploadDocuments['incorporation_certificate'] ===
					'string' &&
				typeof uploadDocuments['last_contract_amendment'] ===
					'string' &&
				typeof uploadDocuments['proof_of_address'] === 'string') ||
			(typeof uploadDocuments['income_statement_url'] === 'string' &&
				typeof uploadDocuments['incorporation_certificate_url'] ===
					'string' &&
				typeof uploadDocuments['last_contract_amendment_url'] ===
					'string' &&
				typeof uploadDocuments['proof_of_address_url'] === 'string')
		);
	}, [uploadDocuments]);

	return (
		<>
			{contextHolder}
			{isAccountCreation && (
				<>
					<Heading2
						style={{
							marginBottom: '1rem',
						}}
					>
						Tipo de conta
					</Heading2>
					<Row>
						<Select
							style={{
								width: '10rem',
								marginBottom: '1.5rem',
							}}
							placeholder="Selecione"
							value={tenantType}
							showSearch={true}
							options={accountTypeOptions}
							onChange={(value, option) => setTenantType(value)}
							filterOption={(input, option) =>
								option?.label
									?.toLowerCase()
									?.startsWith(input.toLowerCase()) ||
								String(option?.value)
									?.toLowerCase()
									?.startsWith(input.toLowerCase())
							}
						/>
					</Row>
				</>
			)}

			<Form<CompanyForm>
				form={form}
				disabled={isAccountCreation && isFormDisabled}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched &&
								!hasCompany &&
								!field.name?.includes('extra_info')) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
				}}
				initialValues={{
					business_type: company?.business_type,
					taxpayer_id: cnpj
						? cnpj
						: normalizeCnpj(company?.taxpayer_id || ''),
					corporate_name: company?.corporate_name,
					name: company?.name,
					foundation_date:
						company?.foundation_date &&
						dayjs(company.foundation_date, 'YYYY-MM-DD'),
					cnae: company?.cnae,
					email_address: company?.email_address,
					phone:
						company?.phone && formatPhoneFromObject(company.phone),
					average_monthly_revenue: company?.average_monthly_revenue,
					fund_manager_id: company?.fund_manager_id,
					fund_securitizer_id: company?.fund_securitizer_id,
					fund_administrator_id: company?.fund_administrator_id,
					postal_code: company?.address?.postal_code,
					state_code: company?.address?.state_code,
					city: company?.address?.city,
					district: company?.address?.district,
					street_name: company?.address?.street_name,
					street_number: company?.address?.street_number,
					extra_info: company?.address?.extra_info,
				}}
				onFinish={values => {
					const isFund = values.business_type === BusinessType.FUND;
					if (loadedCompany !== null && isAccountCreation) {
						handleStepStatus?.(StepsId.PERSONAL_INFORMATION, true);
						onNext(loadedCompany);
						return;
					}
					if (isDocumentsValid) {
						handleStepStatus?.(StepsId.PERSONAL_INFORMATION, true);
						onNext({
							business_type: isAccountCreation
								? BusinessType.OTHERS
								: values.business_type,
							taxpayer_id: serializeOnlyNumbers(
								values.taxpayer_id,
							),
							corporate_name: values.corporate_name,
							name: values.name,
							foundation_date:
								values.foundation_date.format('YYYY-MM-DD'),
							cnae: values.cnae,
							email_address: values.email_address,
							phone: serializePhoneObject(values.phone),
							average_monthly_revenue:
								values.average_monthly_revenue,
							address: {
								postal_code: serializeOnlyNumbers(
									values.postal_code,
								),
								state_code: values.state_code,
								city: values.city,
								district: values.district,
								street_name: values.street_name,
								street_number: values.street_number,
								extra_info: values.extra_info,
								country_code: 'BRZ',
							},
							documents: uploadDocuments,
							fund_manager_id: isFund
								? values.fund_manager_id
								: undefined,
							fund_administrator_id: isFund
								? values.fund_administrator_id
								: undefined,
							fund_securitizer_id: isFund
								? values.fund_securitizer_id
								: undefined,
						} as Company);
					} else {
						toggleDocumentsInvalid(true);
					}
				}}
			>
				<Heading2 style={{ marginBottom: '1rem' }}>
					{isAccountCreation ? 'Dados do titular' : 'Dados básicos'}
				</Heading2>
				{!isAccountCreation && (
					<Row gutter={[16, 16]}>
						<Col span={5}>
							<Form.Item
								name="business_type"
								label="Tipo"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									placeholder="Selecione"
									options={Object.keys(BusinessType).map(
										key => ({
											label: CompanyTypeDescription[
												key as BusinessType
											],
											value: key,
										}),
									)}
								/>
							</Form.Item>
						</Col>
						<Col span={19}>
							<FormItem
								shouldUpdate={(prevValues, currentValues) =>
									prevValues.business_type !==
									currentValues.business_type
								}
							>
								{({ getFieldValue }) => (
									<Row gutter={[16, 16]}>
										{getFieldValue('business_type') ===
											BusinessType.FUND && (
											<>
												<Col span={8}>
													<Form.Item
														name="fund_manager_id"
														label="Empresa Gestora"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															filterOption={(
																input,
																option,
															) =>
																option?.label
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	) ||
																String(
																	option?.value,
																)
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	)
															}
															options={managerCompanies.map(
																company => ({
																	label: company.name,
																	value: company.id,
																}),
															)}
															showSearch
														/>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item
														name="fund_securitizer_id"
														label="Empresa Custodiante"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															filterOption={(
																input,
																option,
															) =>
																option?.label
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	) ||
																String(
																	option?.value,
																)
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	)
															}
															options={custodianCompanies.map(
																company => ({
																	label: company.name,
																	value: company.id,
																}),
															)}
															showSearch
														/>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item
														name="fund_administrator_id"
														label="Empresa Administradora"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															filterOption={(
																input,
																option,
															) =>
																option?.label
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	) ||
																String(
																	option?.value,
																)
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	)
															}
															options={adminCompanies.map(
																company => ({
																	label: company.name,
																	value: company.id,
																}),
															)}
															showSearch
														/>
													</Form.Item>
												</Col>
											</>
										)}
									</Row>
								)}
							</FormItem>
						</Col>
					</Row>
				)}
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<FormItem
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.taxpayer_id !==
								currentValues.taxpayer_id
							}
						>
							{({ getFieldValue }) => {
								return (
									<Form.Item
										name="taxpayer_id"
										label="CNPJ"
										normalize={e => normalizeCnpj(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cnpjRule,
										]}
									>
										<Input
											disabled={loading}
											placeholder="00.000.000/0000-00"
											ref={taxpayerIdRef}
											onBlur={() => {
												const taxpayerId =
													getFieldValue(
														'taxpayer_id',
													);
												if (
													validateCnpj(taxpayerId) &&
													!loading
												) {
													getCompanyByTaxpayerId(
														serializeOnlyNumbers(
															taxpayerId,
														),
													);
												}
											}}
											suffix={
												loading ? (
													<Spinner>
														<PiSpinnerBold
															style={{
																fontSize: 24,
															}}
														/>
													</Spinner>
												) : null
											}
										/>
									</Form.Item>
								);
							}}
						</FormItem>
					</Col>
					<Col span={9}>
						<Form.Item
							name="corporate_name"
							label="Razão Social"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input
								ref={legalNameRef}
								placeholder="Ex.: Empresa S.A."
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="name"
							label="Nome Fantasia"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Empresa XPTO" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<Form.Item
							name="foundation_date"
							label="Data de Constituição"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('foundation_date'),
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</Form.Item>
					</Col>
					<Col span={19}>
						<StyledSelect>
							<Form.Item
								name="cnae"
								label="CNAE"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									style={{ width: '100%' }}
									showSearch
									filterOption={(input, option) =>
										option?.label
											?.toLowerCase()
											?.includes(input.toLowerCase()) ||
										String(option?.value)
											?.toLowerCase()
											?.includes(input.toLowerCase())
									}
									placeholder="Selecione o CNAE"
									options={parsedCnaeData}
								/>
							</Form.Item>
						</StyledSelect>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={10}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="average_monthly_revenue"
							label="Faixa de Faturamento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione a faixa de faturamento"
								options={companyRevenueOptions.map(option => ({
									label: option.label,
									value: option.value,
								}))}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Heading2 style={{ margin: '1rem 0' }}>Endereço</Heading2>
				<Row gutter={[16, 16]}>
					<Col span={4}>
						<Form.Item
							name="postal_code"
							label="CEP"
							normalize={e => normalizeCep(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="00000-000" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="state_code"
							label="Estado"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								showSearch={true}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.startsWith(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.startsWith(input.toLowerCase())
								}
								placeholder="Selecione o estado"
								options={statesList}
							/>
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="city"
							label="Cidade"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: São Paulo" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="district"
							label="Bairro"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Planalto do Sol" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={14}>
						<Form.Item
							name="street_name"
							label="Rua/Avenida"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Av Presidente Vargas" />
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							name="street_number"
							label="Número"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: 300" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item name="extra_info" label="Complemento">
							<Input placeholder="Ex.: Próximo a escola..." />
						</Form.Item>
					</Col>
				</Row>

				<Row
					gutter={[16, 16]}
					justify="space-between"
					style={{ alignItems: 'center', padding: '0 0.7rem' }}
				>
					<Heading2 style={{ margin: '1rem 0' }}>Documentos</Heading2>
					<TextS>* Obrigatórios</TextS>
				</Row>
				<Documents
					hideTitle={true}
					onChangeDocumentsObject={documents => {
						updateUploadDocuments({
							...uploadDocuments,
							...documents,
						});
					}}
					documentsObject={uploadDocuments}
					documentsList={CompanyDocumentsList}
				/>
				{isDocumentsInvalid && (
					<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
						* Adicione todos os documentos obrigatórios
					</TextS>
				)}
				<Footer>
					{cnpj ? (
						<Button type="primary" htmlType="submit">
							<Flex>
								<TextS
									color={theme.white}
									style={{
										marginRight: '0.7rem',
									}}
								>
									Salvar alterações
								</TextS>
								<SaveIcon color={theme.white} size={21} />
							</Flex>
						</Button>
					) : (
						<Button
							type="primary"
							htmlType="submit"
							disabled={
								!formValid ||
								!isDocumentsValid ||
								(isAccountCreation && !tenantType)
							}
						>
							Avançar
						</Button>
					)}
				</Footer>
			</Form>
		</>
	);
};

export default CompanyInformation;
