import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DownloadIcon } from 'components/Icons/Download';
import { WalletsService } from 'modules/debitCollections/services';
import { IImportedCnab, WalletCnabImportStatusLabel } from './types';
import { PaginationInfo } from 'types/List';
import { formatCurrency } from 'helpers/normalizers';
import { TextS } from 'components/Text';
import theme from 'theme/theme';
import { saveAs } from 'file-saver';

export interface IImportedCnabsListProps {
	walletId: string;
}

export default function ImportedCnabsList({
	walletId,
}: IImportedCnabsListProps) {
	const [api, contextHolder] = notification.useNotification();

	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});

	const getList = async () => {
		try {
			const response = await WalletsService.getCnabImportedList(walletId);

			return response;
		} catch (err) {
			console.error('Get Cnab Imported List', err);
			api.error({
				description: '',
				message: 'Ocorreu um problema ao buscar as cobranças.',
			});
		}
	};
	const { data, isLoading } = useQuery({
		queryKey: ['cnabList', paginationInfo],
		queryFn: () => getList(),
	});

	const downloadCnab = useCallback(
		async (cnabId: string): Promise<null> => {
			try {
				const response =
					await WalletsService.getCnabImportedDownloadUrl(
						walletId,
						cnabId,
					);

				saveAs(response.url);
				return null;
			} catch (e) {
				console.error('Ocorreu um problema ao fazer o download.', e);
				return null;
			}
		},
		[walletId],
	);

	const columns: ColumnsType<IImportedCnab> = [
		{
			key: 'date',
			render: (item: IImportedCnab) => (
				<TextS color={theme.textGray}>
					{item.created_at
						? `${dayjs(item.created_at).format(
								'DD/MM/YYYY',
							)} às ${dayjs(item.created_at).format('HH:mm:ss')}`
						: '-'}
				</TextS>
			),
			title: 'Data de importação',
			width: '25%',
		},
		{
			key: 'amount',
			render: (item: IImportedCnab) => (
				<TextS color={theme.textGray}>
					{formatCurrency(item?.amount || 0)}
				</TextS>
			),
			title: 'Valor total',
			width: '25%',
		},
		{
			key: 'status',
			render: (item: IImportedCnab) => (
				<TextS color={theme.textGray}>
					{WalletCnabImportStatusLabel[item.status]}
				</TextS>
			),
			title: 'Status',
			width: '25%',
		},
		{
			key: 'name',
			render: (item: IImportedCnab) => (
				<TextS color={theme.textGray}>{item?.creator || '-'}</TextS>
			),
			title: 'Upload',
			width: '25%',
		},
		{
			align: 'center',
			render: (item: IImportedCnab) => (
				<div onClick={() => downloadCnab(item.id)}>
					<DownloadIcon />
				</div>
			),
			title: 'Ações',
		},
	];

	return (
		<>
			{contextHolder}

			<Table
				columns={columns}
				rowKey={item => item.id}
				dataSource={data?.content}
				loading={isLoading}
				pagination={{
					current: paginationInfo.currentPage,
					pageSize: paginationInfo.pageSize,
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
		</>
	);
}
