import React from 'react';
import theme from 'theme/theme';

export const CheckIcon: React.FC<{
	color?: string;
	size?: string | number;
	isDisabled?: boolean;
}> = ({ color, size, isDisabled }) => (
	<svg
		width={size ? size : '24'}
		height={size ? size : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z"
			fill={color ?? theme.primary}
			style={{
				opacity: isDisabled ? '0.5' : undefined,
			}}
		/>
	</svg>
);
