import React, { useEffect, useMemo, useState } from 'react';
import { LuPlusCircle } from 'react-icons/lu';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { PageActions } from 'components/PageActions';
import { Button, Flex } from 'antd';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';
import { TabElement } from 'components/Tabs/Tabs';
import ChargesBarcodes from './ChargesBarcodes';
import {
	DebitCollectionsIcon,
	DebitCollectionsRoutes,
} from 'modules/debitCollections/constants/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useWallets } from 'modules/debitCollections/hooks/useWallets';
import { WalletContent } from 'types/Wallet';
import { SplashScreen } from 'components/SplashScreen';
import ImportedCnabsList from '../ImportedCnabsList/ImportedCnabsList';
import { BsArrowBarUp } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import { CnabImportModal } from './CnabImport';

const ChargesPage = () => {
	const theme = useTheme();
	const { id } = useParams();
	const navigate = useNavigate();
	const { type, permissions } = useCurrentProfile();
	const [wallet, updateWallet] = useState<WalletContent>({} as WalletContent);
	const [isCnabImportModalOpen, setIsCnabImportModalOpen] = useState(false);

	const { getWallet, isWalletLoading } = useWallets({
		onError: () => {
			navigate(`/debit-collections/collections`);
		},
		onWalletSuccess: w => {
			updateWallet(w);
		},
	});

	useEffect(() => {
		if (id) {
			getWallet(id);
			return;
		}
		navigate(`/debit-collections/collections`);
	}, [id]);

	const tabElements = useMemo<TabElement[]>(() => {
		const tabs: TabElement[] = [
			{
				title: 'Boletos',
				content: <ChargesBarcodes id={id as string} />,
				path: 'barcodes',
				breadcrumb: [
					{
						href: DebitCollectionsRoutes.BASE,
						title: (
							<BreadcrumbContainer>
								<DebitCollectionsIcon />
								<span>Cobranças</span>
							</BreadcrumbContainer>
						),
					},
					{
						title: wallet?.name ?? '',
					},
				],
			},
			{
				title: 'Importações',
				content: <ImportedCnabsList walletId={wallet.id} />,
				path: 'cnabs',
				breadcrumb: [
					{
						href: DebitCollectionsRoutes.BASE,
						title: (
							<BreadcrumbContainer>
								<DebitCollectionsIcon />
								<span>Cobranças</span>
							</BreadcrumbContainer>
						),
					},
					{
						title: wallet?.name ?? '',
					},
				],
			},
		];

		return tabs;
	}, [id, wallet?.name]);

	if (isWalletLoading) {
		return <SplashScreen />;
	}

	return (
		<>
			<PageHeader isFluid={true}>
				<Heading1>{wallet.name}</Heading1>
				{type !== AccessType.FINANCIAL_INSTITUTION &&
					permissions.includes(AccessPermission.CREATE_CHARGE) && (
						<PageActions>
							<Button
								style={{
									color: theme.primary,
								}}
								type="default"
								onClick={() => setIsCnabImportModalOpen(true)}
							>
								Upload de CNAB
								<BsArrowBarUp
									style={{ marginLeft: '0.6rem' }}
									size={18}
								/>
							</Button>

							<Button
								type="primary"
								onClick={() =>
									navigate(
										`/debit-collections/wallet/${id}/new`,
									)
								}
							>
								Criar nova cobrança
								<LuPlusCircle
									style={{ marginLeft: '0.6rem' }}
									size={18}
								/>
							</Button>
						</PageActions>
					)}
			</PageHeader>
			<PageWrapper isFluid={true}>
				<Tabs prePath={`/wallet/${id}`} elements={tabElements} />
			</PageWrapper>
			<CnabImportModal
				isOpen={isCnabImportModalOpen}
				onClose={() => setIsCnabImportModalOpen(false)}
				wallet={wallet}
			/>
		</>
	);
};

export default ChargesPage;
