import { Navigate, Route, Routes } from 'react-router-dom';

/* Components */
import { AuthTemplate } from 'templates/Auth';
import { FirstAccessPage } from './core/pages/FirstAccess';
import { ForgotPasswordPage } from './core/pages/ForgotPassword';
import { LoginPage } from './core/pages/Login';
import { NewPassword } from './core/pages/NewPassword';
import { NotFoundPage } from './core/pages/NotFound';
import { ProfilePage } from './core/pages/Profile';
import { ResetPasswordPage } from './core/pages/ResetPassword';
import { TermsPage } from './core/pages/Terms';
import { TwoFactorPage } from './core/pages/TwoFactor';
import { UserSettings } from './core/pages/UserSettings';
import CompanyModule from './company';
import DebitCollectionsModule from './debitCollections';
import EscrowModule from './escrow';
import ManagementModule from './management';
import ReadTermsPage from './core/pages/ReadTerms/ReadTerms';
import UsersModule from './users';

/* Styles */
import { DefaultTemplate } from 'templates/Default';
import { GlobalStyle } from 'theme/GlobalStyle';
import { contaLivre } from './escrow/constants/routes';

const App = () => {
	return (
		<>
			<Routes>
				<Route element={<DefaultTemplate />}>
					<Route path="management/*" element={<ManagementModule />} />
					<Route path="escrow/*" element={<EscrowModule />} />
					<Route
						path={`${contaLivre}/*`}
						element={<EscrowModule />}
					/>
					<Route path="company/*" element={<CompanyModule />} />
					<Route path="users/*" element={<UsersModule />} />
					<Route
						path="debit-collections/*"
						element={<DebitCollectionsModule />}
					/>
					<Route path="my-profile/*" element={<UserSettings />} />
					<Route path="read-terms" element={<ReadTermsPage />} />
					<Route path="/" element={<Navigate to="/escrow" />} />
				</Route>
				<Route element={<AuthTemplate />}>
					<Route path="login" element={<LoginPage />} />
					<Route path="new-password" element={<NewPassword />} />
					<Route path="two-factor" element={<TwoFactorPage />} />
					<Route path="forgot" element={<ForgotPasswordPage />} />
					<Route path="first-access" element={<FirstAccessPage />} />
					<Route path="profile" element={<ProfilePage />} />
					<Route
						path="reset-password"
						element={<ResetPasswordPage />}
					/>
					<Route path="terms" element={<TermsPage />} />
				</Route>
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<GlobalStyle />
		</>
	);
};

export default App;
