import { TextL } from 'components/Text';
import { AccountTypeButtonContainer, IconArea } from './styles';
import { useTheme } from 'styled-components';

export interface AccountTypeButtonProps {
	selected: boolean;
	icon: React.ReactNode;
	buttonText: string;
	onClick?: () => void;
}

const AccountTypeButton = ({
	selected,
	icon,
	buttonText,
	onClick,
}: AccountTypeButtonProps) => {
	const theme = useTheme();

	return (
		<AccountTypeButtonContainer selected={selected} onClick={onClick}>
			<IconArea>{icon}</IconArea>
			<TextL style={{ color: selected ? theme.white : theme.textGray }}>
				{buttonText}
			</TextL>
		</AccountTypeButtonContainer>
	);
};

export default AccountTypeButton;
