import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoResponse } from '../types/Cognito';
import { isSandbox } from 'helpers/environment';

export type User = CognitoUser & {
	attributes: Record<string, string>;
};

export class CognitoService {
	static init(userPoolId: string, userPoolWebClientId: string) {
		Amplify.configure({
			Auth: {
				region: 'sa-east-1',
				identityPoolRegion: 'sa-east-1',
				userPoolId: userPoolId,
				userPoolWebClientId: userPoolWebClientId,
			},
		});
	}

	async currentAuthenticatedUser(): Promise<User | undefined> {
		try {
			const user = await Auth.currentAuthenticatedUser();
			return user;
		} catch (e) {
			return undefined;
		}
	}

	async validateUser(code: string): Promise<boolean> {
		try {
			if (isSandbox()) {
				return true;
			}
			const user = await this.currentAuthenticatedUser();
			const response = await this.verifyCode(user as any, code);
			if (response !== undefined) {
				return Promise.resolve(true);
			}
			return Promise.reject(false);
		} catch (error) {
			console.error(error);
			return Promise.reject(false);
		}
	}

	async verifyCode(
		user: CognitoResponse,
		code: string,
	): Promise<CognitoUserSession | undefined> {
		try {
			const cognitoUserSession: CognitoUserSession =
				await Auth.verifyTotpToken(user, code);
			await Auth.setPreferredMFA(user, 'TOTP');
			return cognitoUserSession;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	async confirmSignIn(
		user: CognitoResponse,
		code: string,
	): Promise<CognitoUserSession | undefined> {
		try {
			const cognitoUserSession: CognitoUserSession =
				await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
			return cognitoUserSession;
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	signIn(username: string, password: string): Promise<CognitoResponse> {
		return Auth.signIn(username, password);
	}

	setupMFA(user: CognitoResponse) {
		return Auth.setupTOTP(user);
	}

	async setupSMS(user: CognitoResponse, phoneNumber: string) {}

	signOut() {
		return Auth.signOut();
	}

	getPreferredMFA(user: CognitoUser): Promise<string> {
		return Auth.getPreferredMFA(user);
	}

	async completeNewPassword(
		username: string,
		password: string,
		newPassword: string,
	): Promise<CognitoResponse | undefined> {
		try {
			const user = await this.signIn(username, password);
			return Auth.completeNewPassword(user, newPassword);
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	async updateUserAttributes(attributes: Record<string, unknown>) {
		return await Auth.updateUserAttributes(
			await Auth.currentAuthenticatedUser(),
			attributes,
		);
	}

	async changePassword(oldPassword: string, newPassword: string) {
		return await Auth.changePassword(
			await Auth.currentAuthenticatedUser(),
			oldPassword,
			newPassword,
		);
	}
}

const cognito = new CognitoService();

export default cognito;
