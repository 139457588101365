import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { EmptyFiltersResult } from 'components/EmptyFiltersResult';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { PageWrapper } from 'components/PageWrapper';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { AccountsService } from 'modules/escrow/services';
import { getManagementBreadcrumb } from 'modules/management/constants/routes';
import DepositManagementList from './DepositManagementList/DepositManagementList';
import DepositManagementModal from './DepositManagementModal/DepositManagementModal';
import { TextM } from 'components/Text';
import { IDepositManagementForm, IDepositRetentionPayload } from './types';
import dayjs from 'dayjs';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { ManagementService } from 'modules/management/services/management';
import { ApiError } from 'types/ApiError';
import { notification } from 'antd';
import { queryClient } from 'network/query';
import { SplashScreen } from 'components/SplashScreen';

const DepositManagementPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const { id } = useParams();
	const { setBreadcrumb } = useBreadcrumb();
	const dateFormat = 'YYYY-MM-DD';

	const [duplicating, setDuplicating] = useState<boolean>(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
	const [isOpenSuccessModal, setIsOpenSuccessModal] =
		useState<boolean>(false);
	const [isOpenManagementModal, setIsOpenManagementModal] =
		useState<boolean>(false);
	const [selectedConfig, setSelectedConfig] =
		useState<IDepositManagementForm>();

	const { data } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: depositData, isLoading: retentionLoading } = useQuery({
		queryKey: ['retentionList'],
		enabled: !!data,
		queryFn: () =>
			ManagementService.getDepositRetention(data?.id ?? '', {
				page: 1,
			}),
	});

	const { mutate: createNewDepositRetention, isPending: isPendingCreate } =
		useMutation<
			void,
			ApiError,
			{
				retention: IDepositRetentionPayload;
			}
		>({
			mutationFn: ({ retention }) => {
				return ManagementService.createDepositRetention(
					data?.id ?? '',
					retention,
				);
			},
			onSuccess: () => {
				closeModalHandler();
				setIsOpenSuccessModal(true);

				queryClient.invalidateQueries({
					queryKey: ['retentionList'],
				});
			},
			onError: e => {
				api.error({
					description: e.data.message,
					message: 'Ocorreu um erro ao tentar criar a retenção',
				});
			},
		});

	const { mutate: updateDepositRetention, isPending: isPendingUpdate } =
		useMutation<
			void,
			ApiError,
			{
				retention: IDepositRetentionPayload;
				retentionId: string;
			}
		>({
			mutationFn: ({ retention, retentionId }) => {
				return ManagementService.editDepositRetention(
					data?.id ?? '',
					retentionId,
					retention,
				);
			},
			onSuccess: () => {
				closeModalHandler();
				api.success({
					message: 'Retenção editada com sucesso',
				});

				queryClient.invalidateQueries({
					queryKey: ['retentionList'],
				});
			},
			onError: e => {
				api.error({
					description: e.data.message,
					message: 'Ocorreu um erro ao tentar criar a retenção',
				});
			},
		});

	const { mutate: deleteDepositRetention, isPending: isPendingDelete } =
		useMutation<
			void,
			ApiError,
			{
				retentionId: string;
			}
		>({
			mutationFn: ({ retentionId }) => {
				return ManagementService.deleteDepositRetention(
					data?.id ?? '',
					retentionId,
				);
			},
			onSuccess: () => {
				api.success({
					message: 'Retenção removida com sucesso',
				});

				queryClient.invalidateQueries({
					queryKey: ['retentionList'],
				});
			},
			onError: e => {
				api.error({
					description: e.data.message,
					message: 'Ocorreu um erro ao tentar deletar a retenção',
				});
			},
		});

	const isLoading =
		retentionLoading ||
		isPendingCreate ||
		isPendingDelete ||
		isPendingUpdate;

	const createDepositRetentionHandler = useCallback(
		(values: IDepositManagementForm) => {
			createNewDepositRetention({
				retention: {
					account_destination: duplicating
						? values?.beneficiaryAccount?.value
						: values?.beneficiaryAccount,
					amount_percentage: Number(values?.percent) || 0,
					end_date: values.endDate?.replaceAll('/', '-') || '',
					name: values?.name || '',
					origins: values?.origins || [],
					should_retain_slc: values?.slcRetention || false,
					start_date: values.startDate?.replaceAll('/', '-') || '',
					description: values?.description || undefined,
					max_amount: values?.totalAmount || undefined,
				},
			});
		},
		[duplicating],
	);

	const editDepositRetention = (values: IDepositManagementForm) => {
		updateDepositRetention({
			retention: {
				account_destination: values?.beneficiaryAccount?.value,
				amount_percentage: Number(values?.percent) || 0,
				end_date: values.endDate?.replaceAll('/', '-') || '',
				name: values?.name || '',
				origins: values?.origins || [],
				should_retain_slc: values?.slcRetention || false,
				start_date: values.startDate?.replaceAll('/', '-') || '',
				description: values?.description || undefined,
				max_amount:
					values?.totalAmount && values?.totalAmount > 0
						? values?.totalAmount
						: null,
			},
			retentionId: values.id || '',
		});
	};

	const deleteDepositRetentionHandler = useCallback(() => {
		deleteDepositRetention({ retentionId: selectedConfig?.id || '' });

		closeModalHandler();
	}, [selectedConfig]);

	const closeModalHandler = () => {
		setIsOpenManagementModal(false);
		setDuplicating(false);
		setSelectedConfig({} as IDepositManagementForm);
		setIsOpenSuccessModal(false);
		setIsOpenDeleteModal(false);
	};

	const openModalHandler = () => {
		setIsOpenManagementModal(true);
	};

	useEffect(() => {
		setBreadcrumb(
			getManagementBreadcrumb(
				'Configurações de retenção',
				data?.account,
				data?.id,
			),
		);
	}, [setBreadcrumb, data?.account]);

	return (
		<>
			{contextHolder}
			<PageHeaderWithButton
				title="Configurações de retenção"
				hasDivider
				textButton={
					depositData?.content && depositData?.content?.length > 0
						? 'Nova retenção'
						: undefined
				}
				onClickButton={() => {
					setSelectedConfig({} as IDepositManagementForm);
					openModalHandler();
				}}
			/>
			<PageWrapper>
				{depositData?.total_pages &&
				depositData?.content?.length > 0 ? (
					<DepositManagementList
						isLoading={isLoading}
						depositData={depositData.content}
						deleteRetention={retention => {
							setSelectedConfig(retention);
							setIsOpenDeleteModal(true);
						}}
						duplicateRetention={item => {
							setDuplicating(true);
							setSelectedConfig({
								id: item?.id ?? undefined,
								beneficiaryAccount: {
									label: item?.destination?.account || '',
									value: item?.destination?.id || '',
								},
								description: item?.description,
								endDate: dayjs(item?.end_date)
									.add(1, 'month')
									.format(dateFormat),
								name: item?.name,
								origins: item?.origins,
								originsRetention:
									item?.origins && item?.origins?.length > 0
										? true
										: false,
								percent: item?.amount_percentage,
								slcRetention: item?.should_retain_slc,
								startDate: dayjs(item?.start_date)
									.add(1, 'month')
									.format(dateFormat),
								totalAmount: item?.max_amount,
							});
							openModalHandler();
						}}
						editRetention={item => {
							setSelectedConfig({
								id: item?.id ?? undefined,
								beneficiaryAccount: {
									label: item?.destination?.account || '',
									value: item?.destination?.id || '',
								},
								description: item?.description,
								endDate: item?.end_date,
								name: item?.name,
								origins: item?.origins,
								originsRetention:
									item?.origins && item?.origins?.length > 0
										? true
										: false,
								percent: item?.amount_percentage,
								slcRetention: item?.should_retain_slc,
								startDate: item?.start_date,
								totalAmount: item?.max_amount,
							});
							openModalHandler();
						}}
					/>
				) : (
					<EmptyFiltersResult
						description="Ops, esta conta ainda não possui nenhuma retenção."
						buttonText="Adicionar retenção"
						onClickButton={() => setIsOpenManagementModal(true)}
					/>
				)}
			</PageWrapper>

			{/* Modais  */}
			<DepositManagementModal
				isOpen={!!isOpenManagementModal}
				closeModal={closeModalHandler}
				initialValues={selectedConfig}
				createRetention={createDepositRetentionHandler}
				editRetention={editDepositRetention}
				duplicating={duplicating}
				setDuplicating={setDuplicating}
				destinations={data?.destinations}
			/>

			<ConfirmationModal
				isOpen={!!isOpenDeleteModal}
				danger={true}
				title="Remover regra de transferência"
				confirmText="Remover"
				cancelText="Cancelar"
				onConfirm={deleteDepositRetentionHandler}
				onCancel={() => setIsOpenDeleteModal(false)}
				onClose={() => setIsOpenDeleteModal(false)}
			>
				<TextM>
					Você tem certeza que deseja remover essa configuração?
				</TextM>
			</ConfirmationModal>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={!!isOpenSuccessModal}
				title="Retenção criada com sucesso!"
				icon={<CheckmarkIcon />}
				message="A sua retenção foi criada com sucesso!"
				onClose={closeModalHandler}
			/>
		</>
	);
};

export default DepositManagementPage;
