import styled from 'styled-components';
import { EscrowLeadsFiltersType } from './types';
import { Form, Select as AntSelect } from 'antd';

export const FiltersForm = styled(Form<EscrowLeadsFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const FiltersFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}
`;

export const StyledSelect = styled(AntSelect)`
	width: 256px;
	margin-top: 10px;
	max-height: 32px;
`;

export const IconWrapper = styled.div`
	margin-right: 1rem;
`;
