import { AccountStatus } from 'types/Account';
import {
	ForwardButton,
	WalletCreationFormContainer,
	WalletCreationFormItem,
} from './styles';
import { useEffect, useState } from 'react';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { TextM } from 'components/Text';
import { Wallet, WalletDiscountModality, WalletDto } from 'types/Wallet';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import { FaCheck } from 'react-icons/fa6';
import { WalletDiscountModalityDescription } from 'constants/wallet';
import FormItem from 'antd/es/form/FormItem';
import { CurrencyInput } from 'components/CurrencyInput';
import { minValue } from 'helpers/rules';

interface IWalletCreationFormProps {
	wallet?: Wallet;
	isLoading: boolean;
	onCreate: (wallet: WalletDto) => void;
}
const WalletCreationForm = ({ wallet, onCreate }: IWalletCreationFormProps) => {
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);

	const { data: accountsList, isLoading: isAccountsLoading } = useQuery({
		queryKey: ['accountsList'],
		queryFn: () =>
			AccountsService.getAccounts({
				size: 100,
				status: AccountStatus.ACTIVE,
			}),
	});

	useEffect(() => {
		if (typeof wallet !== 'undefined') {
			form.setFieldValue('name', wallet.name);
			form.setFieldValue('account_id', wallet.account_id);
			form.setFieldValue('fine', wallet.fine);
			form.setFieldValue(
				'days_to_expire_after_payment',
				wallet.days_to_expire_after_payment,
			);
			form.setFieldValue('discount_modality', wallet.discount_modality);
			form.setFieldValue('discount_value', wallet.discount_value);
			form.setFieldValue('interest', wallet.interest);
		} else {
			form.setFieldValue('name', undefined);
			form.setFieldValue('account_id', undefined);
			form.setFieldValue('fine', undefined);
			form.setFieldValue('days_to_expire_after_payment', undefined);
			form.setFieldValue('discount_modality', undefined);
			form.setFieldValue('discount_value', undefined);
			form.setFieldValue('interest', undefined);
		}
	}, [wallet]);

	return (
		<WalletCreationFormContainer
			form={form}
			style={{ width: '100%' }}
			layout="vertical"
			requiredMark={false}
			initialValues={{
				name: wallet?.name,
				account_id: wallet?.account_id,
				fine: wallet?.fine,
				days_to_expire_after_payment:
					wallet?.days_to_expire_after_payment,
				discount_modality: wallet?.discount_modality,
				discount_value: wallet?.discount_value,
				interest: wallet?.interest,
			}}
			onFieldsChange={(f, allFields) => {
				const discountModality = allFields.find(
					f => f.name[0] === 'discount_modality',
				);
				const errors = allFields.filter(
					field =>
						(!field.touched &&
							!wallet &&
							!field.name?.includes('fine') &&
							!field.name?.includes('interest') &&
							!(
								field.name[0] === 'discount_value' &&
								discountModality?.value ===
									WalletDiscountModality.NONE
							)) ||
						(field.errors &&
							field.errors.length > 0 &&
							!(
								field.name[0] === 'discount_value' &&
								discountModality?.value ===
									WalletDiscountModality.NONE
							)),
				);
				setFormValid(errors.length === 0);
			}}
			onValuesChange={changedValues => {
				if (typeof changedValues.discount_modality === 'string') {
					form.setFieldValue('discount_value', undefined);
					setTimeout(() =>
						form.validateFields({
							validateOnly: true,
						}),
					);
				}
			}}
			onFinish={values => {
				onCreate({
					...values,
					discount_modality:
						values.discount_modality === WalletDiscountModality.NONE
							? WalletDiscountModality.FIXED
							: values.discount_modality,
					interest: values.interest ?? 0,
					fine: values.fine ?? 0,
					discount_value: values.discount_value ?? 0,
				});
			}}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<WalletCreationFormItem
						name="name"
						label="Nome da carteira"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="Digite aqui" />
					</WalletCreationFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<WalletCreationFormItem
						name="account_id"
						label="Conta de destino"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Select
							placeholder="Selecione"
							loading={isAccountsLoading}
							options={accountsList?.content?.map(account => ({
								label: account.person.name,
								value: account.id,
							}))}
						/>
					</WalletCreationFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<WalletCreationFormItem
						name="discount_modality"
						label="Tipo de desconto"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Select
							placeholder="Selecione"
							options={Object.keys(
								WalletDiscountModalityDescription,
							).map(walletDiscountModality => ({
								label: WalletDiscountModalityDescription[
									walletDiscountModality as WalletDiscountModality
								],
								value: walletDiscountModality,
							}))}
						/>
					</WalletCreationFormItem>
				</Col>
			</Row>
			<FormItem
				style={{ margin: 0 }}
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.discount_modality !==
					currentValues.discount_modality
				}
			>
				{({ getFieldValue }) => {
					const discountModality = getFieldValue('discount_modality');
					if (
						discountModality === WalletDiscountModality.FIXED ||
						discountModality === WalletDiscountModality.NONE
					) {
						return (
							<Row>
								<Col span={24}>
									<Form.Item
										name="discount_value"
										label="Valor do desconto"
										rules={
											discountModality !==
											WalletDiscountModality.NONE
												? [
														{
															required: true,
															message:
																'Campo obrigatório',
														},
														minValue(
															'discount_value',
															0.01,
															'Desconto deve ser maior que R$0,00',
														),
													]
												: []
										}
									>
										<CurrencyInput
											placeholder="0,00"
											disabled={
												discountModality ===
												WalletDiscountModality.NONE
											}
										/>
									</Form.Item>
								</Col>
							</Row>
						);
					}

					return (
						<Row>
							<Col span={24}>
								<Form.Item
									name="discount_value"
									label="Porcentagem do desconto"
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
										minValue(
											'discount_value',
											0.01,
											'Desconto deve ser maior que 0.00%',
										),
									]}
								>
									<InputNumber
										min={0}
										max={100}
										changeOnWheel={false}
										decimalSeparator=","
										style={{ width: '100%' }}
										prefix={'%'}
										placeholder="0"
									/>
								</Form.Item>
							</Col>
						</Row>
					);
				}}
			</FormItem>
			<Row style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<WalletCreationFormItem
						name="days_to_expire_after_payment"
						label="Dias para expirar após o vencimento"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
							minValue(
								'days_to_expire_after_payment',
								1,
								'Esse campo precisa ser maior que 0',
							),
						]}
						tooltip="Quantidade de dias para expirar a cobrança após o vencimento"
					>
						<InputNumber
							style={{ width: '100%' }}
							placeholder="Digite aqui"
							changeOnWheel={false}
						/>
					</WalletCreationFormItem>
				</Col>
			</Row>
			<Row style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<WalletCreationFormItem name="interest" label="Juros">
						<InputNumber
							min={0}
							max={100}
							style={{ width: '100%' }}
							prefix={'%'}
							changeOnWheel={false}
							decimalSeparator=","
							placeholder="0"
						/>
					</WalletCreationFormItem>
				</Col>
			</Row>
			<Row style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<WalletCreationFormItem name="fine" label="Multa">
						<InputNumber
							min={0}
							max={100}
							changeOnWheel={false}
							style={{ width: '100%' }}
							prefix={'%'}
							decimalSeparator=","
							placeholder="0"
						/>
					</WalletCreationFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<ForwardButton
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						<TextM>
							{!wallet
								? 'Cadastrar nova carteira de cobrança'
								: 'Salvar alterações'}
						</TextM>
						<FaCheck size={18} />
					</ForwardButton>
				</Col>
			</Row>
		</WalletCreationFormContainer>
	);
};

export default WalletCreationForm;
