import React from 'react';

export const UnblockIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.6662 10.3948C16.6389 8.66472 15.9398 7.01137 14.7144 5.78595C13.4641 4.53571 11.7684 3.83333 10.0003 3.83333C8.56322 3.83333 7.17395 4.29735 6.03283 5.14241L8.0734 7.18298C8.15928 7.17217 8.24618 7.16667 8.33366 7.16667H9.16699V5.5H10.8337V7.16667H12.917V8.83333H9.72375L10.5571 9.66667H11.667C12.2195 9.66667 12.7494 9.88616 13.1401 10.2769C13.5308 10.6676 13.7503 11.1975 13.7503 11.75C13.7503 12.0659 13.6786 12.3744 13.5441 12.6537L15.2669 14.3765C16.1248 13.2733 16.6427 11.8945 16.6662 10.3948ZM10.7001 12.1667H7.08366V13.8333H9.16699V15.5H10.8337V13.8333H11.667C11.8752 13.8333 12.0802 13.8022 12.2758 13.7424L14.0884 15.555C12.9592 16.4331 11.5412 16.9551 10.0003 16.9551C6.35393 16.9551 3.39128 14.0276 3.33449 10.3947C3.35817 8.8937 3.88757 7.45041 4.82766 6.29426L6.61131 8.0779C6.37777 8.42107 6.25033 8.82897 6.25033 9.25C6.25033 9.80253 6.46982 10.3324 6.86052 10.7231C7.25122 11.1138 7.78113 11.3333 8.33366 11.3333H9.86674L10.7001 12.1667ZM8.16395 9.63054L7.95312 9.41971C7.97368 9.46582 8.00264 9.50824 8.03903 9.54463C8.07542 9.58102 8.11784 9.60998 8.16395 9.63054ZM10.0003 1.95508C7.69937 1.95508 5.61489 2.88873 4.10777 4.39585C2.60064 5.90298 1.66699 7.98745 1.66699 10.2884C1.66699 10.3237 1.66721 10.359 1.66765 10.3942C1.66721 10.4294 1.66699 10.4647 1.66699 10.5C1.66699 15.1025 5.39783 18.8333 10.0003 18.8333C14.6028 18.8333 18.3337 15.1025 18.3337 10.5C18.3337 10.4647 18.3334 10.4294 18.333 10.3942C18.3334 10.359 18.3337 10.3237 18.3337 10.2884C18.3337 5.68604 14.6027 1.95508 10.0003 1.95508Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
