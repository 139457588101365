import styled from 'styled-components';

export const TransferRulesTableWrapper = styled.div`
	margin-top: 24px;

	.ant-table {
		& th::before {
			display: none;
		}
	}
	.custom-row {
		background-color: ${({ theme }) => theme.primaryOpacity};
	}
`;
