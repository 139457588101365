import React from 'react';
import {
	TwoFactorSelectorContainer,
	TwoFactorSelectorOption,
	TwoFactorSelectorOptionButton,
	TwoFactorSelectorOptions,
	TwoFactorText,
} from './styles';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { MFAOptions } from 'modules/core/types/Cognito';
import { Navigate } from 'react-router-dom';

interface ITwoFactorSelectorProps {
	onSelectMFAOption: (option: MFAOptions) => void;
}
const TwoFactorSelector = ({ onSelectMFAOption }: ITwoFactorSelectorProps) => {
	const { mfaOptions } = useAuthContext();

	if (!mfaOptions || mfaOptions.length === 0) return <Navigate to="/login" />;

	return (
		<TwoFactorSelectorContainer>
			<TwoFactorText>
				Para garantir a segurança da sua conta, exigimos que todos os
				usuários ativem a autenticação de dois fatores (2FA).
			</TwoFactorText>
			<TwoFactorText weight="bold">
				Você pode escolher entre as seguintes opções:
			</TwoFactorText>
			<TwoFactorSelectorOptions>
				{mfaOptions?.includes(MFAOptions.SOFTWARE_TOKEN_MFA) && (
					<TwoFactorSelectorOption>
						<TwoFactorSelectorOptionButton
							type="link"
							onClick={() =>
								onSelectMFAOption(MFAOptions.SOFTWARE_TOKEN_MFA)
							}
						>
							Aplicativo de autenticação
						</TwoFactorSelectorOptionButton>
						<TwoFactorText>
							Use um aplicativo de autenticação, como{' '}
							<i>Google Authenticator</i>, <i>Authy </i>
							ou <i>Microsoft Authenticator</i>, para gerar
							códigos temporários.
						</TwoFactorText>
					</TwoFactorSelectorOption>
				)}
				{/* {mfaOptions?.includes(MFAOptions.SMS_MFA) && (
          <TwoFactorSelectorOption>
            <TwoFactorSelectorOptionButton
              type="link"
              onClick={() => onSelectMFAOption(MFAOptions.SMS_MFA)}
            >
              Receber código por SMS
            </TwoFactorSelectorOptionButton>
            <TwoFactorText>
              Receba um código único por mensagem de texto SMS no seu número de
              telefone celular registrado.
            </TwoFactorText>
          </TwoFactorSelectorOption>
        )} */}
			</TwoFactorSelectorOptions>
			<TwoFactorText>
				Se precisar de ajuda ou tiver alguma dúvida, nossa equipe de
				suporte está aqui para ajudá-lo em cada etapa do caminho.
			</TwoFactorText>
		</TwoFactorSelectorContainer>
	);
};

export default TwoFactorSelector;
