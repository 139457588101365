import { Button, Col, Input, Row } from 'antd';
import { ChargesFiltersType } from './types';
import { useCallback, useEffect } from 'react';
import { ChargesFiltersForm, FiltersFormItem } from './styles';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import { LuSearch } from 'react-icons/lu';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { cpfCnpjRule } from 'helpers/rules';
import dayjs from 'dayjs';
import { WalletChargeFilters } from 'types/Wallet';
import { DatePicker } from 'components/DatePicker';

interface IChargesFiltersProps {
	isOpen: boolean;
	filters?: WalletChargeFilters;
	onClose: () => void;
	onApply: (filters: WalletChargeFilters) => void;
}
const ChargesFilters = ({
	isOpen,
	filters,
	onApply,
	onClose,
}: IChargesFiltersProps) => {
	const [form] = ChargesFiltersForm.useForm();

	const onFinish = useCallback(
		(values: ChargesFiltersType) => {
			onApply({
				taxpayer_id:
					values.taxpayer_id && values.taxpayer_id.length > 0
						? serializeOnlyNumbers(values.taxpayer_id)
						: undefined,
				charge_code: values.charge_code ?? undefined,
				debtor_name:
					values.debtor_name && values.debtor_name.length > 0
						? values.debtor_name
						: undefined,
				max_created_at: values.max_created_at
					? values.max_created_at.toISOString()
					: undefined,
				min_created_at: values.min_created_at
					? values.min_created_at.toISOString()
					: undefined,
				max_due_date: values.max_due_date
					? values.max_due_date.toISOString()
					: undefined,
				min_due_date: values.min_due_date
					? values.min_due_date.toISOString()
					: undefined,
			});
		},
		[onApply],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id &&
					normalizeCpfCnpj(filters?.taxpayer_id),
				debtor_name: filters?.debtor_name,
				charge_code: filters?.charge_code,
				max_created_at: filters?.max_created_at
					? dayjs(filters.max_created_at)
					: undefined,
				min_created_at: filters?.min_created_at
					? dayjs(filters.min_created_at)
					: undefined,
				max_due_date: filters?.max_due_date
					? dayjs(filters.max_due_date)
					: undefined,
				min_due_date: filters?.min_due_date
					? dayjs(filters.min_due_date)
					: undefined,
			});
		}
	}, [isOpen, form, filters]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<ChargesFiltersForm
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCpfCnpj(filters?.taxpayer_id),
					debtor_name: filters?.debtor_name,
					id: filters?.id,
					max_created_at: filters?.max_created_at
						? dayjs(filters.max_created_at)
						: undefined,
					min_created_at: filters?.min_created_at
						? dayjs(filters.min_created_at)
						: undefined,
					max_due_date: filters?.max_due_date
						? dayjs(filters.max_due_date)
						: undefined,
					min_due_date: filters?.min_due_date
						? dayjs(filters.min_due_date)
						: undefined,
				}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="taxpayer_id"
							label="CPF / CNPJ"
							normalize={value => normalizeCpfCnpj(value)}
							rules={[cpfCnpjRule]}
						>
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="debtor_name"
							label="Nome do sacado"
						>
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							label="Cód. do boleto"
							name="charge_code"
						>
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[8, 8]} style={{ width: '100%' }} align="middle">
					<Col span={12}>
						<FiltersFormItem
							name="min_created_at"
							label="Data de Criação"
						>
							<DatePicker placeholder="dd/mm/aaaa" />
						</FiltersFormItem>
					</Col>
					<Col span={12}>
						<FiltersFormItem name="max_created_at" label=" ">
							<DatePicker placeholder="dd/mm/aaaa" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[8, 8]} style={{ width: '100%' }} align="middle">
					<Col span={12}>
						<FiltersFormItem
							name="min_due_date"
							label="Data de Vencimento"
						>
							<DatePicker placeholder="dd/mm/aaaa" />
						</FiltersFormItem>
					</Col>
					<Col span={12}>
						<FiltersFormItem name="max_due_date" label=" ">
							<DatePicker placeholder="dd/mm/aaaa" />
						</FiltersFormItem>
					</Col>
				</Row>
			</ChargesFiltersForm>
		</CustomDrawer>
	);
};

export default ChargesFilters;
