import { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { CurrencyInput } from 'components/CurrencyInput';
import { DatePicker } from 'components/DatePicker';
import { Heading2 } from 'components/Heading2';
import { TextS } from 'components/Text';
import { IoMdArrowForward } from 'react-icons/io';
import { CancelButton, ForwardButton } from '../styles';
import { WalletDiscountModalityDescription } from 'constants/wallet';
import { WalletDiscountModality } from 'types/Wallet';
import { InfoType } from '../types';
import {
	betweenRangeDates,
	discountDateRule,
	discountValueRule,
	minTodayDateRule,
	minValue,
} from 'helpers/rules';

interface IChargesNewInfoProps {
	info?: InfoType;
	onBack: (info: InfoType) => void;
	onNext: (info: InfoType) => void;
}
const ChargesNewInfo = ({ info, onBack, onNext }: IChargesNewInfoProps) => {
	const [form] = useForm();
	const [isDiscountDateRequired, updateDiscountDateRequired] =
		useState<boolean>(true);

	useEffect(() => {
		if (info?.discount_modality === WalletDiscountModality.NONE) {
			form.setFieldValue('discount_date', undefined);
			form.setFieldValue('discount_value', undefined);
			updateDiscountDateRequired(false);
		}
	}, [info]);

	return (
		<Row style={{ maxWidth: 1000 }}>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Heading2 style={{ margin: '30px 0px' }}>
							Dados da Cobrança
						</Heading2>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form<InfoType>
							form={form}
							style={{ width: '100%' }}
							initialValues={{
								amount: info?.amount,
								due_date: info?.due_date,
								due_date_limit: info?.due_date_limit,
								discount_modality: info?.discount_modality,
								discount_value: info?.discount_value,
								discount_date: info?.discount_date,
								fine: info?.fine,
								interest: info?.interest,
							}}
							layout="vertical"
							validateTrigger="onBlur"
							onValuesChange={changedValues => {
								if (
									typeof changedValues.discount_modality ===
									'string'
								) {
									updateDiscountDateRequired(
										changedValues.discount_modality !==
											WalletDiscountModality.NONE,
									);
									form.setFieldValue(
										'discount_date',
										undefined,
									);
									form.setFieldValue(
										'discount_value',
										undefined,
									);
								}
							}}
							onFinish={values =>
								onNext({
									amount: values.amount,
									due_date: values.due_date,
									due_date_limit:
										values.due_date_limit ??
										values?.due_date?.add(1, 'day'),
									discount_modality: values.discount_modality,
									discount_value: values.discount_value ?? 0,
									discount_date:
										values.discount_date ?? values.due_date,
									fine: values.fine ?? 0,
									interest: values.interest ?? 0,
								})
							}
						>
							<Row gutter={[16, 16]}>
								<Col span={8}>
									<Form.Item
										name="amount"
										label="Valor"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											minValue('amount', 5),
										]}
									>
										<CurrencyInput placeholder="0,00" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name="due_date"
										label="Data de vencimento"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											minTodayDateRule('due_date'),
										]}
									>
										<DatePicker
											placeholder="dd/mm/aaaa"
											disablePastDays={true}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name="due_date_limit"
										label="Data limite para pagamento"
										rules={[
											betweenRangeDates(
												'due_date',
												1,
												59,
											),
										]}
									>
										<DatePicker
											placeholder="dd/mm/aaaa"
											disablePastDays={true}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={8}>
									<Form.Item
										name="discount_modality"
										label="Tipo de desconto"
									>
										<Select
											placeholder="Selecione"
											options={Object.keys(
												WalletDiscountModalityDescription,
											).map(walletDiscountModality => ({
												label: WalletDiscountModalityDescription[
													walletDiscountModality as WalletDiscountModality
												],
												value: walletDiscountModality,
											}))}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<FormItem
										style={{ margin: 0 }}
										shouldUpdate={(
											prevValues,
											currentValues,
										) =>
											prevValues.discount_modality !==
											currentValues.discount_modality
										}
									>
										{({ getFieldValue }) => {
											const discountModality =
												getFieldValue(
													'discount_modality',
												);
											if (
												discountModality ===
													WalletDiscountModality.FIXED ||
												discountModality ===
													WalletDiscountModality.NONE
											) {
												return (
													<Row>
														<Col span={24}>
															<Form.Item
																name="discount_value"
																label="Valor do desconto"
																rules={
																	discountModality !==
																	WalletDiscountModality.NONE
																		? [
																				{
																					required:
																						true,
																					message:
																						'Campo obrigatório',
																				},
																				minValue(
																					'discount_value',
																					0.01,
																					'Desconto deve ser maior que R$0,00',
																				),
																				discountValueRule(
																					'discount_value',
																					'amount',
																					WalletDiscountModality.FIXED,
																				),
																			]
																		: []
																}
															>
																<CurrencyInput
																	placeholder="0,00"
																	disabled={
																		discountModality ===
																		WalletDiscountModality.NONE
																	}
																/>
															</Form.Item>
														</Col>
													</Row>
												);
											}

											return (
												<Row>
													<Col span={24}>
														<Form.Item
															name="discount_value"
															label="Porcentagem do desconto"
															rules={[
																{
																	required:
																		true,
																	message:
																		'Campo obrigatório',
																},
																minValue(
																	'discount_value',
																	0.01,
																	'Desconto deve ser maior que 0.00%',
																),
																discountValueRule(
																	'discount_value',
																	'amount',
																	WalletDiscountModality.PERCENT,
																),
															]}
														>
															<InputNumber
																min={0}
																max={100}
																changeOnWheel={
																	false
																}
																decimalSeparator=","
																style={{
																	width: '100%',
																}}
																prefix={'%'}
																placeholder="0"
															/>
														</Form.Item>
													</Col>
												</Row>
											);
										}}
									</FormItem>
								</Col>
								<Col span={8}>
									<Form.Item
										name="discount_date"
										label="Data limite para desconto"
										rules={
											isDiscountDateRequired
												? [
														{
															required: true,
															message:
																'Campo obrigatório',
														},
														minTodayDateRule(
															'discount_date',
														),
														discountDateRule(
															'due_date',
														),
													]
												: []
										}
									>
										<DatePicker
											placeholder="dd/mm/aaaa"
											disablePastDays={true}
											disabled={!isDiscountDateRequired}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={8}>
									<Form.Item name="interest" label="Juros">
										<InputNumber
											min={0}
											max={100}
											changeOnWheel={false}
											decimalSeparator=","
											style={{ width: '100%' }}
											prefix={'%'}
											placeholder="0"
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="fine" label="Multa">
										<InputNumber
											min={0}
											max={100}
											changeOnWheel={false}
											decimalSeparator=","
											style={{ width: '100%' }}
											prefix={'%'}
											placeholder="0"
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row
								gutter={[16, 16]}
								style={{ margin: '3rem 0 1rem' }}
								justify="end"
							>
								<Col>
									<CancelButton
										onClick={() =>
											onBack(form.getFieldsValue())
										}
									>
										<TextS>Voltar</TextS>
									</CancelButton>
								</Col>
								<Col>
									<ForwardButton
										type="primary"
										htmlType="submit"
									>
										<TextS>Avançar</TextS>
										<IoMdArrowForward size={16} />
									</ForwardButton>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ChargesNewInfo;
