import React from 'react';

export const PaymentCalendarIcon: React.FC<{ color?: string }> = ({
	color,
}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.00065 3.33301V4.66634H12.0007V3.33301H13.334V4.66634H16.0007C16.1775 4.66634 16.347 4.73658 16.4721 4.8616C16.5971 4.98663 16.6673 5.1562 16.6673 5.33301V15.9997C16.6673 16.1765 16.5971 16.3461 16.4721 16.4711C16.347 16.5961 16.1775 16.6663 16.0007 16.6663H4.00065C3.82384 16.6663 3.65427 16.5961 3.52925 16.4711C3.40422 16.3461 3.33398 16.1765 3.33398 15.9997V5.33301C3.33398 5.1562 3.40422 4.98663 3.52925 4.8616C3.65427 4.73658 3.82384 4.66634 4.00065 4.66634H6.66732V3.33301H8.00065ZM15.334 9.33301H4.66732V15.333H15.334V9.33301ZM12.0247 10.0903L12.9673 11.033L9.66732 14.333L7.30998 11.9757L8.25398 11.033L9.66798 12.4477L12.0247 10.0903ZM6.66732 5.99967H4.66732V7.99967H15.334V5.99967H13.334V6.66634H12.0007V5.99967H8.00065V6.66634H6.66732V5.99967Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
