import styled from 'styled-components';

export const FeetSettingsModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: calc(100vh - 25rem);
	overflow: auto;
	padding: 0rem 1rem;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: transparent;
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: ${({ theme }) => theme.primary};
	}
`;

export const CargeSelectWrapper = styled.div`
	border-top: 1rem;
	display: flex;
	width: 100%;
	.ant-select-selector {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		min-width: 2rem;
	}
`;

export const FeeTypeSelectWrapper = styled.div`
	margin-top: 1rem;
`;
