import { Col, DescriptionsProps, Row, notification } from 'antd';
import dayjs from 'dayjs';
import { normalizeCep, normalizeCnpj } from 'helpers/normalizers';
import { Company, CompanyDto, CompanyFund } from 'types/Company';
import {
	CompanyDocumentsList,
	CompanyTypeDescription,
} from 'constants/company';
import { StatesListDescription } from 'constants/states';
import { formatPhoneFromObject } from 'helpers/formaters';
import CompanyBasicDataEdit from './CompanyEdit/CompanyBasicDataEdit';
import { useMemo, useState } from 'react';
import CompanyAddressEdit from './CompanyEdit/CompanyAddressEdit';
import { Documents } from 'components/Documents';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { queryClient } from 'network/query';
import { getCompanyRevenueOption } from 'modules/company/utils/options';
import { DescriptionsVertical } from 'components/DescriptionsVertical';

interface ICompanyDetailsInformationProps {
	company: Company;
	companyFund?: CompanyFund;
}
const CompanyDetailsInformation: React.FC<ICompanyDetailsInformationProps> = ({
	company,
	companyFund,
}) => {
	const [api, contextHolder] = notification.useNotification();

	const [isBasicDataEditOpen, toggleBasicDataEditOpen] = useState(false);
	const [isAddressEditOpen, toggleAddressEditOpen] = useState(false);

	const { mutate } = useMutation<void, ApiError, Partial<CompanyDto>>({
		mutationFn: companyDto => {
			return PersonsService.patchCompany(company.id, companyDto);
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['companyDetails', company.id],
			});
			api.success({
				message: 'Empresa atualizada com sucesso.',
			});
			if (isBasicDataEditOpen) toggleBasicDataEditOpen(false);
			if (isAddressEditOpen) toggleAddressEditOpen(false);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao atualizar a empresa.',
			});
		},
	});

	const basicData = useMemo(() => {
		const items: DescriptionsProps['items'] = [
			{
				key: '1',
				label: 'Tipo de Empresa',
				children: CompanyTypeDescription[company.business_type],
			},
			{
				key: '2',
				label: 'CNPJ',
				children: normalizeCnpj(company.taxpayer_id),
			},
			{
				key: '3',
				label: 'Razão Social',
				children: company.corporate_name,
			},
			{
				key: '4',
				label: 'Nome Fantasia',
				children: company.name,
			},
			{
				key: '5',
				label: 'Data de Constituição',
				children: dayjs(company.foundation_date, 'YYYY-MM-DD').format(
					'DD/MM/YYYY',
				),
			},
			{
				key: '6',
				label: 'CNAE',
				children: company.cnae,
			},
			{
				key: '7',
				label: 'E-mail',
				children: company.email_address,
			},
			{
				key: '8',
				label: 'Telefone',
				children: formatPhoneFromObject(company.phone),
			},
			{
				key: '9',
				label: 'Faixa de Faturamento',
				children: getCompanyRevenueOption(
					company.average_monthly_revenue,
				).label,
			},
		];

		if (companyFund && companyFund.manager) {
			items.push({
				key: '9',
				label: 'Empresa Gestora',
				children: companyFund.manager.name,
			});
		}

		if (companyFund && companyFund.securitizer) {
			items.push({
				key: '10',
				label: 'Empresa Custodiante',
				children: companyFund.securitizer.name,
			});
		}

		if (companyFund && companyFund.administrator) {
			items.push({
				key: '10',
				label: 'Empresa Administradora',
				children: companyFund.administrator.name,
			});
		}
		return items;
	}, [company, companyFund]);

	return (
		<>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<DescriptionsVertical
						title="Dados básicos"
						items={basicData}
						onEdit={() => toggleBasicDataEditOpen(true)}
					/>
				</Col>
				<Col span={12}>
					<DescriptionsVertical
						title="Endereço"
						items={[
							{
								key: '1',
								label: 'CEP',
								children: normalizeCep(
									company.address.postal_code,
								),
							},
							{
								key: '2',
								label: 'Logradouro',
								children: company.address.street_name,
							},
							{
								key: '3',
								label: 'Número',
								children: company.address.street_number,
							},
							{
								key: '4',
								label: 'Bairro',
								children: company.address.district,
							},
							{
								key: '5',
								label: 'Cidade',
								children: company.address.city,
							},
							{
								key: '6',
								label: 'Estado',
								children:
									StatesListDescription[
										company.address.state_code
									],
							},
							{
								key: '7',
								label: 'Complemento',
								children: company.address.extra_info,
							},
						]}
						onEdit={() => toggleAddressEditOpen(true)}
					/>
				</Col>
				<CompanyBasicDataEdit
					isOpen={isBasicDataEditOpen}
					company={company}
					onClose={() => toggleBasicDataEditOpen(false)}
					onSave={company => mutate(company)}
				/>
				<CompanyAddressEdit
					isOpen={isAddressEditOpen}
					company={company}
					onClose={() => toggleAddressEditOpen(false)}
					onSave={company => mutate(company)}
				/>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						onChangeDocumentsObject={documents => {
							mutate({
								documents: Object.keys(documents).reduce(
									(acc, key) => {
										if (!key.includes('url')) {
											return {
												...acc,
												[key]: documents[key],
											};
										}
										return acc;
									},
									{},
								),
							});
						}}
						documentsObject={company.documents}
						documentsList={CompanyDocumentsList}
					/>
				</Col>
			</Row>
		</>
	);
};

export default CompanyDetailsInformation;
