import React from 'react';

export const CompanyOutlinedIcon: React.FC<{ color?: string }> = ({
	color,
}) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 19.7402H23V21.7402H1V19.7402H3V4.74023C3 4.47502 3.10536 4.22066 3.29289 4.03313C3.48043 3.84559 3.73478 3.74023 4 3.74023H14C14.2652 3.74023 14.5196 3.84559 14.7071 4.03313C14.8946 4.22066 15 4.47502 15 4.74023V19.7402H19V11.7402H17V9.74023H20C20.2652 9.74023 20.5196 9.84559 20.7071 10.0331C20.8946 10.2207 21 10.475 21 10.7402V19.7402ZM5 5.74023V19.7402H13V5.74023H5ZM7 11.7402H11V13.7402H7V11.7402ZM7 7.74023H11V9.74023H7V7.74023Z"
			fill={color ?? '#302D3F'}
		/>
	</svg>
);

export const CompanyIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 19.7402H23V21.7402H1V19.7402H3V4.74023C3 4.47502 3.10536 4.22066 3.29289 4.03313C3.48043 3.84559 3.73478 3.74023 4 3.74023H14C14.2652 3.74023 14.5196 3.84559 14.7071 4.03313C14.8946 4.22066 15 4.47502 15 4.74023V19.7402H19V11.7402H17V9.74023H20C20.2652 9.74023 20.5196 9.84559 20.7071 10.0331C20.8946 10.2207 21 10.475 21 10.7402V19.7402ZM5 5.74023V19.7402H13V5.74023H5ZM7 11.7402H11V13.7402H7V11.7402ZM7 7.74023H11V9.74023H7V7.74023Z"
			fill={color ?? '#302D3F'}
		/>
	</svg>
);
