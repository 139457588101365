import { Flex } from 'antd';
import { MenuButtonContainer } from './styles';
import { BiChevronRight } from 'react-icons/bi';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';

export interface MenuButtonButtonProps {
	icon: React.ReactNode;
	title: string;
	onClick?: () => void;
}

const MenuButton = ({ icon, title, onClick }: MenuButtonButtonProps) => {
	const theme = useTheme();
	return (
		<MenuButtonContainer type="primary" onClick={onClick}>
			<Flex gap={10} style={{ alignItems: 'center' }}>
				{icon}
				<TextS
					style={{
						color: theme.text,
					}}
				>
					{title}
				</TextS>
			</Flex>

			<BiChevronRight style={{ color: theme.primary }} />
		</MenuButtonContainer>
	);
};

export default MenuButton;
