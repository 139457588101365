import { ConfigProvider } from 'antd';
import { ThemeProvider as OriginalThemeProvider } from 'styled-components';
import locale from 'antd/lib/locale/pt_BR';
import { FC, ReactNode } from 'react';
import theme from './theme';

interface IThemeProviderProps {
	children: ReactNode;
}
export const ThemeProvider: FC<IThemeProviderProps> = ({ children }) => {
	return (
		<ConfigProvider
			locale={locale}
			theme={{
				token: {
					colorPrimary: theme.primary,
				},
				components: {
					Table: {
						headerBg: 'transparent',
					},
					Pagination: {
						itemActiveBg: theme.primaryOpacity,
						colorBorder: theme.primaryOpacity,
					},
					Button: {
						colorLink: theme.primary,
					},
					Tabs: {
						fontSize: 18,
						horizontalItemPadding: '16px',
					},
					Form: {
						itemMarginBottom: 8,
					},
					Input: {
						controlHeight: 40,
						fontSize: 16,
						colorTextPlaceholder: theme.textDisabled,
					},
					Select: {
						controlHeight: 40,
						fontSize: 16,
						colorTextPlaceholder: theme.placeholderSelect,
					},
					DatePicker: {
						controlHeight: 40,
						fontSize: 16,
						colorTextPlaceholder: theme.textDisabled,
					},
					InputNumber: {
						controlHeight: 40,
						fontSize: 16,
						colorTextPlaceholder: theme.textDisabled,
					},
					Steps: {
						colorSplit: theme.border,
					},
					Descriptions: {
						itemPaddingBottom: 0,
					},
				},
			}}
		>
			<OriginalThemeProvider theme={theme}>
				{children}
			</OriginalThemeProvider>
		</ConfigProvider>
	);
};
