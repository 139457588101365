import { Phone } from './Phone';

export enum WalletDiscountModality {
	FIXED = 'FIXED',
	PERCENT = 'PERCENT',
	NONE = 'NONE',
}

export enum WalletStatus {
	ARCHIVED = 'ARCHIVED',
	ACTIVE = 'ACTIVE',
}

export type WalletDto = {
	name: string;
	account_id: string;
	fine: number;
	days_to_expire_after_payment: number;
	discount_modality: WalletDiscountModality;
	discount_value: number;
	interest: number;
};

export type Wallet = {
	id: string;
} & WalletDto;

export type WalletAccountContent = {
	id: string;
	branch: string;
	account: string;
	person: {
		name: string;
		taxpayer_id: string;
	} | null;
};

export type WalletContent = {
	id: string;
	wallet_code: number;
	name: string;
	fine: number;
	status: WalletStatus;
	days_to_expire_after_payment: number;
	discount_modality: WalletDiscountModality;
	discount_value: number;
	interest: number;
	created_at: string;
	account: WalletAccountContent | null;
};

export type WalletFilesContent = {
	id: string;
	name: string;
	processed_at: number;
	size: number;
	download_at: number;
	institution_name: string;
};

export type WalletFilters = {
	page?: number;
	size?: number;
	order?: 'ASC' | 'DESC';
	sort?: string;
	search?: string;
	name?: string;
	status?: WalletStatus;
	id?: string;
	beneficiary_account?: string;
	beneficiary_document?: string;
	wallet_code?: number;
};

export type WalletChargeFilters = {
	page?: number;
	size?: number;
	order?: 'ASC' | 'DESC';
	sort?: string;
	search?: string;
	id?: string;
	charge_code?: number;
	status?: WalletChargeStatus;
	debtor_name?: string;
	taxpayer_id?: string;
	max_created_at?: string;
	min_created_at?: string;
	max_due_date?: string;
	min_due_date?: string;
};

export enum WalletChargeStatus {
	ERROR = 'ERROR',
	PENDING = 'PENDING',
	PROCESSING = 'PROCESSING',
	CONFIRMED = 'CONFIRMED',
	PENDING_CREATION = 'PENDING_CREATION',
	PENDING_CANCELLATION = 'PENDING_CANCELLATION',
	CANCELED = 'CANCELED',
}

export interface ChargeReceiver {
	split_percentage: number;
	account_destination_id: string;
}

export type WalletChargeDto = {
	due_date: string;
	amount: number;
	fine?: number;
	discount_limit_date: string;
	days_to_expire_after_payment?: number;
	discount_modality?: WalletDiscountModality;
	discount_value?: number;
	interest?: number;
	charge_debtor: {
		number: string;
		neighborhood: string;
		name: string;
		document: string;
		city: string;
		public_area: string;
		state: string;
		postal_code: string;
		phone: Phone;
		email: string;
	};
	charge_receivers: ChargeReceiver[];
};

export type WalletChargeUpdateDto = {
	due_date: string;
	amount: number;
	fine?: number;
	discount_limit_date: string;
	days_to_expire_after_payment?: number;
	discount_modality?: WalletDiscountModality;
	discount_value?: number;
	interest?: number;
	charge_debtor: Partial<{
		number: string;
		neighborhood: string;
		name: string;
		document: string;
		city: string;
		public_area: string;
		state: string;
		postal_code: string;
		phone: Phone;
		email: string;
	}>;
};

export type WalletCharge = {
	id: string;
	charge_code: number;
	fine: number;
	status: WalletChargeStatus;
	discount_limit_date: string;
	days_to_expire_after_payment: number;
	discount_modality: string;
	discount_value: number;
	due_date: string;
	interest: number;
	amount: number;
	error: string | null;
	created_at: string;
	receiver?: {
		account: string;
		document: string;
	} | null;
	debtor: {
		number: string;
		neighborhood: string;
		name: string;
		document: string;
		city: string;
		public_area: string;
		state: string;
		postal_code: string;
	};
};
