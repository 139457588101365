import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CompanyDetails, NaturalPersonDetails } from './pages/CompanyDetails';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { UsersDetails } from 'components/UsersDetails';
import CompanyCreationPage from './pages/CompanyCreation/LegalPerson/CompanyCreation';
import CompanyPage from './Company';
import NaturalPersonCreationPage from './pages/CompanyCreation/NaturalPerson/NaturalPersonCreation';
import { AccessType } from 'types/Access';

const CompanyModule = () => {
	const { type } = useCurrentProfile();

	if (type !== AccessType.FINANCIAL_INSTITUTION) {
		return <Navigate to="/" replace />;
	}

	return (
		<Routes>
			<Route path="new" element={<CompanyCreationPage />} />
			<Route path="natural/new" element={<NaturalPersonCreationPage />} />
			<Route path="list" element={<CompanyPage />} />
			<Route
				path=":id/access/:userId"
				element={<UsersDetails isCompany={true} />}
			/>
			<Route path=":id/:tab" element={<CompanyDetails />} />
			<Route
				path="natural-person/:id/:tab"
				element={<NaturalPersonDetails />}
			/>
			<Route index element={<Navigate to="list" replace />} />
		</Routes>
	);
};

export default CompanyModule;
