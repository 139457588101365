import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Steps } from 'antd';
import { Modal } from 'components/Modal';
import { VscAccount, VscMap } from 'react-icons/vsc';
import { IoDocumentsOutline } from 'react-icons/io5';
import { PiIdentificationCardLight } from 'react-icons/pi';
import { FormThemeProvider } from 'theme/FormThemeProvider';
import InformationsStep from './steps/InformationsStep';
import AddressStep from './steps/AddressStep';
import DocumentsStep from './steps/DocumentsStep';
import { StepsContainer } from './styles';
import { BaseRepresentative, Representative } from 'types/Representative';
import IdentificationStep from './steps/IdentificationStep';
import { ConfirmationModal } from 'components/ConfirmationModal';
import Confirmation from 'components/Icons/Confirmation';
import { TextS } from 'components/Text';
interface IRepresentativesCreationProps {
	isOpen: boolean;
	representative?: BaseRepresentative;
	isLegalRepresentative?: boolean;
	onClose: () => void;
	onCreate: (representative: BaseRepresentative) => void;
}
const RepresentativesCreation = ({
	representative: editRepresentative,
	isOpen,
	isLegalRepresentative,
	onClose,
	onCreate,
}: IRepresentativesCreationProps) => {
	const [confirmModalOpen, toggleConfirmModal] = useState<boolean>(false);
	const [currentStep, updateCurrentStep] = useState(0);
	const [representative, updateRepresentative] = useState<Representative>(
		{} as Representative,
	);

	useEffect(() => {
		if (isOpen) {
			updateCurrentStep(0);
			if (editRepresentative) {
				updateRepresentative(editRepresentative);
				return;
			}
			updateRepresentative({} as Representative);
		}
	}, [isOpen, editRepresentative]);

	const onNext = useCallback(
		(values: Representative) => {
			updateRepresentative({
				...((representative ?? {}) as Representative),
				...values,
			});
			updateCurrentStep(currentStep + 1);
		},
		[currentStep, representative],
	);

	const onBack = useCallback(
		(values: Representative) => {
			updateRepresentative({
				...((representative ?? {}) as Representative),
				...values,
			});
			updateCurrentStep(currentStep - 1);
		},
		[currentStep, representative],
	);

	const onFinish = useCallback(
		(values: Representative) => {
			onCreate({
				...((representative ?? {}) as Representative),
				...values,
			});
			toggleConfirmModal(true);
		},
		[onCreate, representative],
	);

	return (
		<>
			<Modal
				title={
					isLegalRepresentative
						? 'Procurador'
						: 'Adicionar Representante'
				}
				isOpen={isOpen}
				onClose={onClose}
				width={1000}
			>
				<StepsContainer>
					<Steps
						current={currentStep}
						className="steps"
						direction="vertical"
						items={[
							{
								title: 'Informações',
								icon: <VscAccount />,
							},
							{
								title: 'Identificação',
								icon: <PiIdentificationCardLight />,
							},
							{
								title: 'Endereço',
								icon: <VscMap />,
							},
							{
								title: 'Documentos',
								icon: <IoDocumentsOutline />,
							},
						]}
					/>
					<FormThemeProvider>
						{currentStep === 0 && (
							<InformationsStep
								isLegalRepresentative={isLegalRepresentative}
								representative={representative}
								onNext={onNext}
							/>
						)}
						{currentStep === 1 && (
							<IdentificationStep
								isLegalRepresentative={isLegalRepresentative}
								representative={representative}
								onNext={onNext}
								onBack={onBack}
							/>
						)}
						{currentStep === 2 && (
							<AddressStep
								isLegalRepresentative={isLegalRepresentative}
								representative={representative}
								onNext={onNext}
								onBack={onBack}
							/>
						)}
						{currentStep === 3 && (
							<DocumentsStep
								isLegalRepresentative={isLegalRepresentative}
								representative={representative}
								onNext={onFinish}
								onBack={onBack}
							/>
						)}
					</FormThemeProvider>
				</StepsContainer>
			</Modal>
			<ConfirmationModal
				title="Representante adicionado"
				isOpen={confirmModalOpen}
				onClose={() => toggleConfirmModal(false)}
				confirmText="Ok, obrigado"
				onConfirm={() => toggleConfirmModal(false)}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Col span={3}>
						<Confirmation />
					</Col>
					<Col span={21}>
						<TextS>Representante foi adicionado com sucesso.</TextS>
					</Col>
				</Row>
			</ConfirmationModal>
		</>
	);
};

export default RepresentativesCreation;
