import { CancelButton, ForwardButton } from './styles';
import { Col, Row } from 'antd';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { TextL, TextM, TextS } from 'components/Text';
import { getAccountRouteTypeName } from 'modules/escrow/utils/adminRoute';
import theme from 'theme/theme';

interface IPostingCreationSuccessProps {
	onClose: () => void;
	onReceipt: () => void;
}
const PostingCreationSuccess = ({
	onClose,
	onReceipt,
}: IPostingCreationSuccessProps) => {
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col
					span={3}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<CheckmarkIcon />
				</Col>
				<Col span={21}>
					<TextL>A transação foi solicitada com sucesso!</TextL>
					<TextM weight="normal" color={theme.textSecondary}>
						{`Você pode acompanhar suas solicitações na aba
							"Aprovações" em ${getAccountRouteTypeName()}.`}
					</TextM>
				</Col>
			</Row>

			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<TextS
						style={{
							color: theme.textGray,
						}}
					>
						O seu comprovante será disponibilizado no extrato assim
						que a transação for aprovada/processada.
					</TextS>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<ForwardButton onClick={onClose} type="primary">
						<TextM>Ok, obrigado</TextM>
					</ForwardButton>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CancelButton type="default" onClick={onReceipt}>
						<TextM>Visualizar comprovante de solicitação</TextM>
					</CancelButton>
				</Col>
			</Row>
		</>
	);
};

export default PostingCreationSuccess;
