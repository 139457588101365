import { Button, Col, Input, Row, Select } from 'antd';
import { CollectionsFiltersType } from './types';
import { useCallback, useEffect } from 'react';
import { FiltersForm, FiltersFormItem } from './styles';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import { LuSearch } from 'react-icons/lu';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { AccountStatus } from 'types/Account';
import { AccountsService } from 'modules/escrow/services';
import { useQuery } from '@tanstack/react-query';

interface ICollectionsFiltersProps {
	isOpen: boolean;
	filters?: CollectionsFiltersType;
	onClose: () => void;
	onApply: (filters: CollectionsFiltersType) => void;
}
const CollectionsFilters = ({
	isOpen,
	filters,
	onApply,
	onClose,
}: ICollectionsFiltersProps) => {
	const [form] = FiltersForm.useForm();

	const { data: accountsList, isLoading: isAccountsLoading } = useQuery({
		queryKey: ['accountsList'],
		queryFn: () =>
			AccountsService.getAccounts({
				size: 100,
				status: AccountStatus.ACTIVE,
			}),
		enabled: isOpen,
	});

	const onFinish = useCallback(
		(values: CollectionsFiltersType) => {
			onApply({
				taxpayer_id:
					values.taxpayer_id && values.taxpayer_id.length > 0
						? serializeOnlyNumbers(values.taxpayer_id)
						: undefined,
				beneficiary_account:
					values.beneficiary_account &&
					values.beneficiary_account.length > 0
						? values.beneficiary_account
						: undefined,
				name:
					values.name && values.name.length > 0
						? values.name
						: undefined,
				wallet_code: values.wallet_code ?? undefined,
			} as CollectionsFiltersType);
		},
		[onApply],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id &&
					normalizeCpfCnpj(filters?.taxpayer_id),
				beneficiary_account: filters?.beneficiary_account,
				name: filters?.name,
				wallet_code: filters?.wallet_code,
			});
		}
	}, [isOpen, form, filters]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCpfCnpj(filters?.taxpayer_id),
					beneficiary_account: filters?.beneficiary_account,
					name: filters?.name,
					wallet_code: filters?.wallet_code,
				}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="name" label="Nome da carteira">
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							label="Código da carteira"
							name="wallet_code"
						>
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="account_id"
							label="Conta de destino"
						>
							<Select
								style={{ minWidth: '10rem' }}
								placeholder="Selecionar"
								loading={isAccountsLoading}
								allowClear={true}
								options={accountsList?.content?.map(
									account => ({
										label: account.person.name,
										value: account.account,
									}),
								)}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
};

export default CollectionsFilters;
