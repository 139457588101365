import { Button, Form } from 'antd';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { TwoFactorCodeFormType, TwoFactorSmsFormType } from './types';
import { TextS } from 'components/Text';

export const TwoFactorContainer = styled.div`
	width: 100%;
	max-width: 25rem;
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem 1rem;
	align-items: center;
	box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 0.4rem;
	border: 1px solid ${p => p.theme.border};
	background-color: ${p => p.theme.white};
	margin-bottom: -3rem;
`;

export const TwoFactorCodeFormQrCode = styled.div`
	display: flex;
	justify-content: center;
	border: 1px solid ${p => p.theme.border};
	padding: 1rem;
	width: fit-content;
	margin: 1rem 4rem 1rem;
`;

export const TwoFactorResend = styled(Button)`
	display: block;
	padding: 0;
	border: none;
	margin: 0 auto 1rem;
	color: ${p => p.theme.textSecondary};
`;

export const TwoFactorBack = styled(RouterLink)`
	margin-top: 0.5rem;
	display: block;
	text-align: center;
	color: ${p => p.theme.primary};
`;

export const TwoFactorCodeFormSubmit = styled(Button)`
	margin: 0 auto;
	display: block;
	margin-top: 1.5rem;
`;

export const TwoFactorCodeFormInputList = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`;

export const TwoFactorCodeFormItem = styled(Form.Item)`
	width: 2rem;
	display: inline-block;
	margin: 0 0.4rem 0 0;
	margin-bottom: 0;
`;

export const TwoFactorCodeFormError = styled.div`
	margin: 0.4rem 0 0;
	text-align: center;
	color: ${p => p.theme.error};
`;

export const TwoFactorText = styled(TextS)`
	margin: 0.6rem 0 0.4rem;
	text-align: center;
	color: ${p => p.theme.textSecondary};
`;

export const TwoFactorCodeFormContainer = styled(Form<TwoFactorCodeFormType>)`
	padding: 0 0 1rem;
	width: 100%;
	position: relative;
`;

export const TwoFactorSetupFormContainer = styled.div`
	width: 100%;
	position: relative;

	${TwoFactorText} {
		text-align: left;
		padding: 0 1.2rem;
	}
`;

export const TwoFactorSetupFormSteps = styled.ol`
	list-style: none;
	counter-reset: my-awesome-counter;
`;

export const TwoFactorSetupFormStepItem = styled.li`
	counter-increment: my-awesome-counter;
	position: relative;
	${TwoFactorText} {
		text-align: left;
		padding: 0;
	}
	&::before {
		content: counter(my-awesome-counter) '.';
		font-size: 1rem;
		font-weight: bold;
		color: ${p => p.theme.textSecondary};
		position: absolute;
		top: 0;
		left: -1.5rem;
	}
`;

export const TwoFactorSelectorContainer = styled.div`
	width: 100%;
	position: relative;

	${TwoFactorText} {
		text-align: left;
		padding: 0 1.2rem;
	}
`;

export const TwoFactorSelectorOptions = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	padding: 0 0 0.6rem;
`;

export const TwoFactorSelectorOptionButton = styled(Button)`
	margin: 0;
	padding: 0;
	height: unset;
	margin-bottom: 0.3rem;
`;

export const TwoFactorSelectorOption = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem 0 0;
	border: 1px solid ${p => p.theme.border};
	border-radius: 10px;
	padding: 0.7rem;

	${TwoFactorText} {
		text-align: center;
		padding: 0;
		margin: 0;
		max-width: 90%;
	}
`;

export const TwoFactorSmsFormContainer = styled(Form<TwoFactorSmsFormType>)`
	padding: 0 0 1rem;
	width: 100%;
	position: relative;
`;
