//subclasses from ibge cnae api v2
export const cnaeSubclassesRawData = [
	{
		id: '4929902',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL',
	},
	{
		id: '1610204',
		description:
			'SERRARIAS SEM DESDOBRAMENTO DE MADEIRA EM BRUTO - RESSERRAGEM',
	},
	{
		id: '1610203',
		description: 'SERRARIAS COM DESDOBRAMENTO DE MADEIRA EM BRUTO',
	},
	{ id: '4623105', description: 'COMÉRCIO ATACADISTA DE CACAU' },
	{
		id: '3314705',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS DE TRANSMISSÃO PARA FINS INDUSTRIAIS',
	},
	{
		id: '4623106',
		description:
			'COMÉRCIO ATACADISTA DE SEMENTES, FLORES, PLANTAS E GRAMAS',
	},
	{ id: '4623107', description: 'COMÉRCIO ATACADISTA DE SISAL' },
	{
		id: '5620102',
		description: 'SERVIÇOS DE ALIMENTAÇÃO PARA EVENTOS E RECEPÇÕES - BUFÊ',
	},
	{ id: '6611802', description: 'BOLSA DE MERCADORIAS' },
	{ id: '6611803', description: 'BOLSA DE MERCADORIAS E FUTUROS' },
	{
		id: '6611804',
		description: 'ADMINISTRAÇÃO DE MERCADOS DE BALCÃO ORGANIZADOS',
	},
	{ id: '0111301', description: 'CULTIVO DE ARROZ' },
	{ id: '0111302', description: 'CULTIVO DE MILHO' },
	{ id: '0111303', description: 'CULTIVO DE TRIGO' },
	{
		id: '7729201',
		description: 'ALUGUEL DE APARELHOS DE JOGOS ELETRÔNICOS',
	},
	{
		id: '0111399',
		description:
			'CULTIVO DE OUTROS CEREAIS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '0112101', description: 'CULTIVO DE ALGODÃO HERBÁCEO' },
	{ id: '0112102', description: 'CULTIVO DE JUTA' },
	{
		id: '0112199',
		description:
			'CULTIVO DE OUTRAS FIBRAS DE LAVOURA TEMPORÁRIA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '0113000', description: 'CULTIVO DE CANA DE AÇÚCAR' },
	{ id: '0114800', description: 'CULTIVO DE FUMO' },
	{ id: '0115600', description: 'CULTIVO DE SOJA' },
	{ id: '0116401', description: 'CULTIVO DE AMENDOIM' },
	{ id: '0116402', description: 'CULTIVO DE GIRASSOL' },
	{ id: '0116403', description: 'CULTIVO DE MAMONA' },
	{ id: '0133406', description: 'CULTIVO DE GUARANÁ' },
	{ id: '0721901', description: 'EXTRAÇÃO DE MINÉRIO DE ALUMÍNIO' },
	{
		id: '0116499',
		description:
			'CULTIVO DE OUTRAS OLEAGINOSAS DE LAVOURA TEMPORÁRIA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '0119901', description: 'CULTIVO DE ABACAXI' },
	{ id: '0119902', description: 'CULTIVO DE ALHO' },
	{ id: '0119903', description: 'CULTIVO DE BATATA INGLESA' },
	{ id: '0119904', description: 'CULTIVO DE CEBOLA' },
	{ id: '0119905', description: 'CULTIVO DE FEIJÃO' },
	{ id: '0119906', description: 'CULTIVO DE MANDIOCA' },
	{ id: '0119907', description: 'CULTIVO DE MELÃO' },
	{ id: '0119908', description: 'CULTIVO DE MELANCIA' },
	{ id: '0119909', description: 'CULTIVO DE TOMATE RASTEIRO' },
	{ id: '0133403', description: 'CULTIVO DE CAJU' },
	{
		id: '0151203',
		description: 'CRIAÇÃO DE BOVINOS, EXCETO PARA CORTE E LEITE',
	},
	{ id: '0133404', description: 'CULTIVO DE CÍTRICOS, EXCETO LARANJA' },
	{ id: '0133405', description: 'CULTIVO DE COCO DA BAÍA' },
	{
		id: '0119999',
		description:
			'CULTIVO DE OUTRAS PLANTAS DE LAVOURA TEMPORÁRIA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '0121101', description: 'HORTICULTURA, EXCETO MORANGO' },
	{ id: '0121102', description: 'CULTIVO DE MORANGO' },
	{ id: '0122900', description: 'CULTIVO DE FLORES E PLANTAS ORNAMENTAIS' },
	{ id: '0131800', description: 'CULTIVO DE LARANJA' },
	{ id: '0132600', description: 'CULTIVO DE UVA' },
	{ id: '0133401', description: 'CULTIVO DE AÇAÍ' },
	{ id: '0133402', description: 'CULTIVO DE BANANA' },
	{ id: '0139301', description: 'CULTIVO DE CHÁ DA ÍNDIA' },
	{ id: '0133407', description: 'CULTIVO DE MAÇÃ' },
	{ id: '0133408', description: 'CULTIVO DE MAMÃO' },
	{ id: '0133409', description: 'CULTIVO DE MARACUJÁ' },
	{ id: '0133410', description: 'CULTIVO DE MANGA' },
	{ id: '0133411', description: 'CULTIVO DE PÊSSEGO' },
	{
		id: '0133499',
		description:
			'CULTIVO DE FRUTAS DE LAVOURA PERMANENTE NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '0134200', description: 'CULTIVO DE CAFÉ' },
	{ id: '0135100', description: 'CULTIVO DE CACAU' },
	{ id: '0139302', description: 'CULTIVO DE ERVA MATE' },
	{ id: '0139303', description: 'CULTIVO DE PIMENTA DO REINO' },
	{ id: '0152103', description: 'CRIAÇÃO DE ASININOS E MUARES' },
	{ id: '0721902', description: 'BENEFICIAMENTO DE MINÉRIO DE ALUMÍNIO' },
	{
		id: '0139304',
		description:
			'CULTIVO DE PLANTAS PARA CONDIMENTO, EXCETO PIMENTA DO REINO',
	},
	{ id: '0139305', description: 'CULTIVO DE DENDÊ' },
	{ id: '0139306', description: 'CULTIVO DE SERINGUEIRA' },
	{
		id: '0139399',
		description:
			'CULTIVO DE OUTRAS PLANTAS DE LAVOURA PERMANENTE NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '0141501',
		description:
			'PRODUÇÃO DE SEMENTES CERTIFICADAS, EXCETO DE FORRAGEIRAS PARA PASTO',
	},
	{
		id: '0141502',
		description:
			'PRODUÇÃO DE SEMENTES CERTIFICADAS DE FORRAGEIRAS PARA FORMAÇÃO DE PASTO',
	},
	{
		id: '0142300',
		description:
			'PRODUÇÃO DE MUDAS E OUTRAS FORMAS DE PROPAGAÇÃO VEGETAL, CERTIFICADAS',
	},
	{ id: '0151201', description: 'CRIAÇÃO DE BOVINOS PARA CORTE' },
	{ id: '0151202', description: 'CRIAÇÃO DE BOVINOS PARA LEITE' },
	{
		id: '0161002',
		description: 'SERVIÇO DE PODA DE ÁRVORES PARA LAVOURAS',
	},
	{ id: '0152101', description: 'CRIAÇÃO DE BUFALINOS' },
	{ id: '0152102', description: 'CRIAÇÃO DE EQÜINOS' },
	{ id: '0153901', description: 'CRIAÇÃO DE CAPRINOS' },
	{
		id: '0153902',
		description: 'CRIAÇÃO DE OVINOS, INCLUSIVE PARA PRODUÇÃO DE LÃ',
	},
	{ id: '0154700', description: 'CRIAÇÃO DE SUÍNOS' },
	{ id: '0155501', description: 'CRIAÇÃO DE FRANGOS PARA CORTE' },
	{ id: '0155502', description: 'PRODUÇÃO DE PINTOS DE UM DIA' },
	{
		id: '0155503',
		description: 'CRIAÇÃO DE OUTROS GALINÁCEOS, EXCETO PARA CORTE',
	},
	{ id: '0155504', description: 'CRIAÇÃO DE AVES, EXCETO GALINÁCEOS' },
	{ id: '0155505', description: 'PRODUÇÃO DE OVOS' },
	{ id: '0159801', description: 'APICULTURA' },
	{ id: '0159802', description: 'CRIAÇÃO DE ANIMAIS DE ESTIMAÇÃO' },
	{ id: '0159803', description: 'CRIAÇÃO DE ESCARGÔ' },
	{ id: '0159804', description: 'CRIAÇÃO DE BICHO DA SEDA' },
	{
		id: '0159899',
		description:
			'CRIAÇÃO DE OUTROS ANIMAIS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '0161001',
		description: 'SERVIÇO DE PULVERIZAÇÃO E CONTROLE DE PRAGAS AGRÍCOLAS',
	},
	{ id: '0210106', description: 'CULTIVO DE MUDAS EM VIVEIROS FLORESTAIS' },
	{
		id: '0161003',
		description: 'SERVIÇO DE PREPARAÇÃO DE TERRENO, CULTIVO E COLHEITA',
	},
	{
		id: '0210107',
		description: 'EXTRAÇÃO DE MADEIRA EM FLORESTAS PLANTADAS',
	},
	{
		id: '0210109',
		description: 'PRODUÇÃO DE CASCA DE ACÁCIA NEGRA - FLORESTAS PLANTADAS',
	},
	{
		id: '0161099',
		description:
			'ATIVIDADES DE APOIO À AGRICULTURA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '0162801',
		description: 'SERVIÇO DE INSEMINAÇÃO ARTIFICIAL DE ANIMAIS',
	},
	{ id: '0162802', description: 'SERVIÇO DE TOSQUIAMENTO DE OVINOS' },
	{ id: '0162803', description: 'SERVIÇO DE MANEJO DE ANIMAIS' },
	{
		id: '0162899',
		description:
			'ATIVIDADES DE APOIO À PECUÁRIA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '0163600', description: 'ATIVIDADES DE PÓS COLHEITA' },
	{ id: '0170900', description: 'CAÇA E SERVIÇOS RELACIONADOS' },
	{ id: '0210101', description: 'CULTIVO DE EUCALIPTO' },
	{ id: '0210102', description: 'CULTIVO DE ACÁCIA NEGRA' },
	{ id: '0210103', description: 'CULTIVO DE PINUS' },
	{ id: '0210104', description: 'CULTIVO DE TECA' },
	{
		id: '0210105',
		description:
			'CULTIVO DE ESPÉCIES MADEIREIRAS, EXCETO EUCALIPTO, ACÁCIA NEGRA, PINUS E TECA',
	},
	{
		id: '0210108',
		description: 'PRODUÇÃO DE CARVÃO VEGETAL - FLORESTAS PLANTADAS',
	},
	{
		id: '0210199',
		description:
			'PRODUÇÃO DE PRODUTOS NÃO MADEIREIROS NÃO ESPECIFICADOS ANTERIORMENTE EM FLORESTAS PLANTADAS',
	},
	{
		id: '0220901',
		description: 'EXTRAÇÃO DE MADEIRA EM FLORESTAS NATIVAS',
	},
	{
		id: '0220902',
		description: 'PRODUÇÃO DE CARVÃO VEGETAL - FLORESTAS NATIVAS',
	},
	{
		id: '0220903',
		description: 'COLETA DE CASTANHA DO PARÁ EM FLORESTAS NATIVAS',
	},
	{ id: '0220904', description: 'COLETA DE LÁTEX EM FLORESTAS NATIVAS' },
	{ id: '0220905', description: 'COLETA DE PALMITO EM FLORESTAS NATIVAS' },
	{ id: '0220906', description: 'CONSERVAÇÃO DE FLORESTAS NATIVAS' },
	{
		id: '0220999',
		description:
			'COLETA DE PRODUTOS NÃO MADEIREIROS NÃO ESPECIFICADOS ANTERIORMENTE EM FLORESTAS NATIVAS',
	},
	{
		id: '0230600',
		description: 'ATIVIDADES DE APOIO À PRODUÇÃO FLORESTAL',
	},
	{ id: '0311601', description: 'PESCA DE PEIXES EM ÁGUA SALGADA' },
	{
		id: '0311602',
		description: 'PESCA DE CRUSTÁCEOS E MOLUSCOS EM ÁGUA SALGADA',
	},
	{ id: '0311603', description: 'COLETA DE OUTROS PRODUTOS MARINHOS' },
	{
		id: '0311604',
		description: 'ATIVIDADES DE APOIO À PESCA EM ÁGUA SALGADA',
	},
	{ id: '0312401', description: 'PESCA DE PEIXES EM ÁGUA DOCE' },
	{
		id: '0312402',
		description: 'PESCA DE CRUSTÁCEOS E MOLUSCOS EM ÁGUA DOCE',
	},
	{
		id: '4633803',
		description:
			'COMÉRCIO ATACADISTA DE COELHOS E OUTROS PEQUENOS ANIMAIS VIVOS PARA ALIMENTAÇÃO',
	},
	{
		id: '0312403',
		description: 'COLETA DE OUTROS PRODUTOS AQUÁTICOS DE ÁGUA DOCE',
	},
	{
		id: '0312404',
		description: 'ATIVIDADES DE APOIO À PESCA EM ÁGUA DOCE',
	},
	{ id: '0710301', description: 'EXTRAÇÃO DE MINÉRIO DE FERRO' },
	{
		id: '0710302',
		description:
			'PELOTIZAÇÃO, SINTERIZAÇÃO E OUTROS BENEFICIAMENTOS DE MINÉRIO DE FERRO',
	},
	{
		id: '0321301',
		description: 'CRIAÇÃO DE PEIXES EM ÁGUA SALGADA E SALOBRA',
	},
	{
		id: '0321302',
		description: 'CRIAÇÃO DE CAMARÕES EM ÁGUA SALGADA E SALOBRA',
	},
	{
		id: '0321303',
		description: 'CRIAÇÃO DE OSTRAS E MEXILHÕES EM ÁGUA SALGADA E SALOBRA',
	},
	{
		id: '0321304',
		description: 'CRIAÇÃO DE PEIXES ORNAMENTAIS EM ÁGUA SALGADA E SALOBRA',
	},
	{
		id: '0321305',
		description:
			'ATIVIDADES DE APOIO À AQÜICULTURA EM ÁGUA SALGADA E SALOBRA',
	},
	{
		id: '0321399',
		description:
			'CULTIVOS E SEMICULTIVOS DA AQÜICULTURA EM ÁGUA SALGADA E SALOBRA NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '0322101', description: 'CRIAÇÃO DE PEIXES EM ÁGUA DOCE' },
	{ id: '0322102', description: 'CRIAÇÃO DE CAMARÕES EM ÁGUA DOCE' },
	{
		id: '0322103',
		description: 'CRIAÇÃO DE OSTRAS E MEXILHÕES EM ÁGUA DOCE',
	},
	{
		id: '0322104',
		description: 'CRIAÇÃO DE PEIXES ORNAMENTAIS EM ÁGUA DOCE',
	},
	{ id: '0322105', description: 'RANICULTURA' },
	{ id: '0322106', description: 'CRIAÇÃO DE JACARÉ' },
	{
		id: '0322107',
		description: 'ATIVIDADES DE APOIO À AQÜICULTURA EM ÁGUA DOCE',
	},
	{
		id: '0322199',
		description:
			'CULTIVOS E SEMICULTIVOS DA AQÜICULTURA EM ÁGUA DOCE NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '0500301', description: 'EXTRAÇÃO DE CARVÃO MINERAL' },
	{ id: '0500302', description: 'BENEFICIAMENTO DE CARVÃO MINERAL' },
	{ id: '0600001', description: 'EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL' },
	{ id: '0600002', description: 'EXTRAÇÃO E BENEFICIAMENTO DE XISTO' },
	{
		id: '0600003',
		description: 'EXTRAÇÃO E BENEFICIAMENTO DE AREIAS BETUMINOSAS',
	},
	{ id: '0722701', description: 'EXTRAÇÃO DE MINÉRIO DE ESTANHO' },
	{ id: '0722702', description: 'BENEFICIAMENTO DE MINÉRIO DE ESTANHO' },
	{ id: '0723501', description: 'EXTRAÇÃO DE MINÉRIO DE MANGANÊS' },
	{ id: '0723502', description: 'BENEFICIAMENTO DE MINÉRIO DE MANGANÊS' },
	{ id: '0724301', description: 'EXTRAÇÃO DE MINÉRIO DE METAIS PRECIOSOS' },
	{
		id: '0724302',
		description: 'BENEFICIAMENTO DE MINÉRIO DE METAIS PRECIOSOS',
	},
	{ id: '0725100', description: 'EXTRAÇÃO DE MINERAIS RADIOATIVOS' },
	{
		id: '0729401',
		description: 'EXTRAÇÃO DE MINÉRIOS DE NIÓBIO E TITÂNIO',
	},
	{ id: '0729402', description: 'EXTRAÇÃO DE MINÉRIO DE TUNGSTÊNIO' },
	{ id: '0729403', description: 'EXTRAÇÃO DE MINÉRIO DE NÍQUEL' },
	{
		id: '0729404',
		description:
			'EXTRAÇÃO DE MINÉRIOS DE COBRE, CHUMBO, ZINCO E OUTROS MINERAIS METÁLICOS NÃO FERROSOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '0729405',
		description:
			'BENEFICIAMENTO DE MINÉRIOS DE COBRE, CHUMBO, ZINCO E OUTROS MINERAIS METÁLICOS NÃO FERROSOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '0810001',
		description: 'EXTRAÇÃO DE ARDÓSIA E BENEFICIAMENTO ASSOCIADO',
	},
	{
		id: '0810002',
		description: 'EXTRAÇÃO DE GRANITO E BENEFICIAMENTO ASSOCIADO',
	},
	{
		id: '0810003',
		description: 'EXTRAÇÃO DE MÁRMORE E BENEFICIAMENTO ASSOCIADO',
	},
	{ id: '1012102', description: 'ABATE DE PEQUENOS ANIMAIS' },
	{ id: '6621502', description: 'AUDITORIA E CONSULTORIA ATUARIAL' },
	{
		id: '0810004',
		description:
			'EXTRAÇÃO DE CALCÁRIO E DOLOMITA E BENEFICIAMENTO ASSOCIADO',
	},
	{ id: '0810005', description: 'EXTRAÇÃO DE GESSO E CAULIM' },
	{
		id: '0810006',
		description:
			'EXTRAÇÃO DE AREIA, CASCALHO OU PEDREGULHO E BENEFICIAMENTO ASSOCIADO',
	},
	{ id: '9603302', description: 'SERVIÇOS DE CREMAÇÃO' },
	{
		id: '0810007',
		description: 'EXTRAÇÃO DE ARGILA E BENEFICIAMENTO ASSOCIADO',
	},
	{
		id: '0810008',
		description: 'EXTRAÇÃO DE SAIBRO E BENEFICIAMENTO ASSOCIADO',
	},
	{
		id: '0810009',
		description: 'EXTRAÇÃO DE BASALTO E BENEFICIAMENTO ASSOCIADO',
	},
	{
		id: '0810010',
		description: 'BENEFICIAMENTO DE GESSO E CAULIM ASSOCIADO À EXTRAÇÃO',
	},
	{
		id: '0810099',
		description:
			'EXTRAÇÃO E BRITAMENTO DE PEDRAS E OUTROS MATERIAIS PARA CONSTRUÇÃO E BENEFICIAMENTO ASSOCIADO',
	},
	{
		id: '0891600',
		description:
			'EXTRAÇÃO DE MINERAIS PARA FABRICAÇÃO DE ADUBOS, FERTILIZANTES E OUTROS PRODUTOS QUÍMICOS',
	},
	{ id: '0892401', description: 'EXTRAÇÃO DE SAL MARINHO' },
	{ id: '0892402', description: 'EXTRAÇÃO DE SAL GEMA' },
	{ id: '0892403', description: 'REFINO E OUTROS TRATAMENTOS DO SAL' },
	{
		id: '0893200',
		description: 'EXTRAÇÃO DE GEMAS (PEDRAS PRECIOSAS E SEMIPRECIOSAS)',
	},
	{ id: '0899101', description: 'EXTRAÇÃO DE GRAFITA' },
	{ id: '0899102', description: 'EXTRAÇÃO DE QUARTZO' },
	{ id: '0899103', description: 'EXTRAÇÃO DE AMIANTO' },
	{
		id: '0899199',
		description:
			'EXTRAÇÃO DE OUTROS MINERAIS NÃO METÁLICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '1043100',
		description:
			'FABRICAÇÃO DE MARGARINA E OUTRAS GORDURAS VEGETAIS E DE ÓLEOS NÃO COMESTÍVEIS DE ANIMAIS',
	},
	{ id: '1051100', description: 'PREPARAÇÃO DO LEITE' },
	{
		id: '1351100',
		description: 'FABRICAÇÃO DE ARTEFATOS TÊXTEIS PARA USO DOMÉSTICO',
	},
	{
		id: '0910600',
		description: 'ATIVIDADES DE APOIO À EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL',
	},
	{
		id: '0990401',
		description: 'ATIVIDADES DE APOIO À EXTRAÇÃO DE MINÉRIO DE FERRO',
	},
	{
		id: '0990402',
		description:
			'ATIVIDADES DE APOIO À EXTRAÇÃO DE MINERAIS METÁLICOS NÃO FERROSOS',
	},
	{
		id: '0990403',
		description: 'ATIVIDADES DE APOIO À EXTRAÇÃO DE MINERAIS NÃO METÁLICOS',
	},
	{ id: '1011201', description: 'FRIGORÍFICO - ABATE DE BOVINOS' },
	{ id: '1011202', description: 'FRIGORÍFICO - ABATE DE EQÜINOS' },
	{
		id: '1011203',
		description: 'FRIGORÍFICO - ABATE DE OVINOS E CAPRINOS',
	},
	{ id: '1011204', description: 'FRIGORÍFICO - ABATE DE BUFALINOS' },
	{
		id: '1011205',
		description:
			'MATADOURO - ABATE DE RESES SOB CONTRATO - EXCETO ABATE DE SUÍNOS',
	},
	{ id: '1012101', description: 'ABATE DE AVES' },
	{ id: '1012103', description: 'FRIGORÍFICO - ABATE DE SUÍNOS' },
	{
		id: '1012104',
		description: 'MATADOURO - ABATE DE SUÍNOS SOB CONTRATO',
	},
	{ id: '1013901', description: 'FABRICAÇÃO DE PRODUTOS DE CARNE' },
	{ id: '1013902', description: 'PREPARAÇÃO DE SUBPRODUTOS DO ABATE' },
	{
		id: '1020101',
		description: 'PRESERVAÇÃO DE PEIXES, CRUSTÁCEOS E MOLUSCOS',
	},
	{
		id: '1020102',
		description: 'FABRICAÇÃO DE CONSERVAS DE PEIXES, CRUSTÁCEOS E MOLUSCOS',
	},
	{ id: '1031700', description: 'FABRICAÇÃO DE CONSERVAS DE FRUTAS' },
	{ id: '1032501', description: 'FABRICAÇÃO DE CONSERVAS DE PALMITO' },
	{
		id: '1032599',
		description:
			'FABRICAÇÃO DE CONSERVAS DE LEGUMES E OUTROS VEGETAIS, EXCETO PALMITO',
	},
	{
		id: '1033301',
		description:
			'FABRICAÇÃO DE SUCOS CONCENTRADOS DE FRUTAS, HORTALIÇAS E LEGUMES',
	},
	{
		id: '1033302',
		description:
			'FABRICAÇÃO DE SUCOS DE FRUTAS, HORTALIÇAS E LEGUMES, EXCETO CONCENTRADOS',
	},
	{
		id: '1041400',
		description:
			'FABRICAÇÃO DE ÓLEOS VEGETAIS EM BRUTO, EXCETO ÓLEO DE MILHO',
	},
	{
		id: '1042200',
		description:
			'FABRICAÇÃO DE ÓLEOS VEGETAIS REFINADOS, EXCETO ÓLEO DE MILHO',
	},
	{ id: '1052000', description: 'FABRICAÇÃO DE LATICÍNIOS' },
	{
		id: '1053800',
		description: 'FABRICAÇÃO DE SORVETES E OUTROS GELADOS COMESTÍVEIS',
	},
	{ id: '1061901', description: 'BENEFICIAMENTO DE ARROZ' },
	{ id: '1061902', description: 'FABRICAÇÃO DE PRODUTOS DO ARROZ' },
	{
		id: '1062700',
		description: 'MOAGEM DE TRIGO E FABRICAÇÃO DE DERIVADOS',
	},
	{
		id: '1063500',
		description: 'FABRICAÇÃO DE FARINHA DE MANDIOCA E DERIVADOS',
	},
	{
		id: '1064300',
		description:
			'FABRICAÇÃO DE FARINHA DE MILHO E DERIVADOS, EXCETO ÓLEOS DE MILHO',
	},
	{
		id: '1065101',
		description: 'FABRICAÇÃO DE AMIDOS E FÉCULAS DE VEGETAIS',
	},
	{ id: '1065102', description: 'FABRICAÇÃO DE ÓLEO DE MILHO EM BRUTO' },
	{ id: '1065103', description: 'FABRICAÇÃO DE ÓLEO DE MILHO REFINADO' },
	{ id: '1066000', description: 'FABRICAÇÃO DE ALIMENTOS PARA ANIMAIS' },
	{
		id: '1069400',
		description:
			'MOAGEM E FABRICAÇÃO DE PRODUTOS DE ORIGEM VEGETAL NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '1071600', description: 'FABRICAÇÃO DE AÇÚCAR EM BRUTO' },
	{ id: '1072401', description: 'FABRICAÇÃO DE AÇÚCAR DE CANA REFINADO' },
	{
		id: '1072402',
		description:
			'FABRICAÇÃO DE AÇÚCAR DE CEREAIS (DEXTROSE) E DE BETERRABA',
	},
	{ id: '1081301', description: 'BENEFICIAMENTO DE CAFÉ' },
	{ id: '1421500', description: 'FABRICAÇÃO DE MEIAS' },
	{
		id: '4763603',
		description:
			'COMÉRCIO VAREJISTA DE BICICLETAS E TRICICLOS; PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2062200',
		description: 'FABRICAÇÃO DE PRODUTOS DE LIMPEZA E POLIMENTO',
	},
	{ id: '1081302', description: 'TORREFAÇÃO E MOAGEM DE CAFÉ' },
	{ id: '1082100', description: 'FABRICAÇÃO DE PRODUTOS À BASE DE CAFÉ' },
	{
		id: '1091101',
		description: 'FABRICAÇÃO DE PRODUTOS DE PANIFICAÇÃO INDUSTRIAL',
	},
	{
		id: '1091102',
		description:
			'FABRICAÇÃO DE PRODUTOS DE PADARIA E CONFEITARIA COM PREDOMINÂNCIA  DE PRODUÇÃO PRÓPRIA',
	},
	{ id: '1092900', description: 'FABRICAÇÃO DE BISCOITOS E BOLACHAS' },
	{
		id: '1093701',
		description:
			'FABRICAÇÃO DE PRODUTOS DERIVADOS DO CACAU E DE CHOCOLATES',
	},
	{
		id: '1093702',
		description: 'FABRICAÇÃO DE FRUTAS CRISTALIZADAS, BALAS E SEMELHANTES',
	},
	{ id: '1094500', description: 'FABRICAÇÃO DE MASSAS ALIMENTÍCIAS' },
	{
		id: '1095300',
		description:
			'FABRICAÇÃO DE ESPECIARIAS, MOLHOS, TEMPEROS E CONDIMENTOS',
	},
	{ id: '1099601', description: 'FABRICAÇÃO DE VINAGRES' },
	{ id: '1099602', description: 'FABRICAÇÃO DE PÓS ALIMENTÍCIOS' },
	{ id: '1099603', description: 'FABRICAÇÃO DE FERMENTOS E LEVEDURAS' },
	{ id: '1099604', description: 'FABRICAÇÃO DE GELO COMUM' },
	{
		id: '1099605',
		description: 'FABRICAÇÃO DE PRODUTOS PARA INFUSÃO (CHÁ, MATE, ETC.)',
	},
	{
		id: '1099606',
		description: 'FABRICAÇÃO DE ADOÇANTES NATURAIS E ARTIFICIAIS',
	},
	{
		id: '1099607',
		description:
			'FABRICAÇÃO DE ALIMENTOS DIETÉTICOS E COMPLEMENTOS ALIMENTARES',
	},
	{
		id: '1311100',
		description: 'PREPARAÇÃO E FIAÇÃO DE FIBRAS DE ALGODÃO',
	},
	{
		id: '1312000',
		description:
			'PREPARAÇÃO E FIAÇÃO DE FIBRAS TÊXTEIS NATURAIS, EXCETO ALGODÃO',
	},
	{
		id: '1099699',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS ALIMENTÍCIOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '1111901',
		description: 'FABRICAÇÃO DE AGUARDENTE DE CANA DE AÇÚCAR',
	},
	{
		id: '1111902',
		description: 'FABRICAÇÃO DE OUTRAS AGUARDENTES E BEBIDAS DESTILADAS',
	},
	{ id: '1112700', description: 'FABRICAÇÃO DE VINHO' },
	{
		id: '1113501',
		description: 'FABRICAÇÃO DE MALTE, INCLUSIVE MALTE UÍSQUE',
	},
	{ id: '1113502', description: 'FABRICAÇÃO DE CERVEJAS E CHOPES' },
	{ id: '1121600', description: 'FABRICAÇÃO DE ÁGUAS ENVASADAS' },
	{ id: '1122401', description: 'FABRICAÇÃO DE REFRIGERANTES' },
	{
		id: '1122402',
		description:
			'FABRICAÇÃO DE CHÁ MATE E OUTROS CHÁS PRONTOS PARA CONSUMO',
	},
	{
		id: '1122403',
		description:
			'FABRICAÇÃO DE REFRESCOS, XAROPES E PÓS PARA REFRESCOS, EXCETO REFRESCOS DE FRUTAS',
	},
	{ id: '1122404', description: 'FABRICAÇÃO DE BEBIDAS ISOTÔNICAS' },
	{
		id: '1122499',
		description:
			'FABRICAÇÃO DE OUTRAS BEBIDAS NÃO ALCOÓLICAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '1210700', description: 'PROCESSAMENTO INDUSTRIAL DO FUMO' },
	{ id: '1220401', description: 'FABRICAÇÃO DE CIGARROS' },
	{ id: '1220402', description: 'FABRICAÇÃO DE CIGARRILHAS E CHARUTOS' },
	{ id: '1220403', description: 'FABRICAÇÃO DE FILTROS PARA CIGARROS' },
	{
		id: '1220499',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS DO FUMO, EXCETO CIGARROS, CIGARRILHAS E CHARUTOS',
	},
	{
		id: '1313800',
		description: 'FIAÇÃO DE FIBRAS ARTIFICIAIS E SINTÉTICAS',
	},
	{
		id: '1314600',
		description: 'FABRICAÇÃO DE LINHAS PARA COSTURAR E BORDAR',
	},
	{ id: '1321900', description: 'TECELAGEM DE FIOS DE ALGODÃO' },
	{
		id: '1322700',
		description:
			'TECELAGEM DE FIOS DE FIBRAS TÊXTEIS NATURAIS, EXCETO ALGODÃO',
	},
	{
		id: '1323500',
		description: 'TECELAGEM DE FIOS DE FIBRAS ARTIFICIAIS E SINTÉTICAS',
	},
	{ id: '1330800', description: 'FABRICAÇÃO DE TECIDOS DE MALHA' },
	{
		id: '1340501',
		description:
			'ESTAMPARIA E TEXTURIZAÇÃO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO',
	},
	{
		id: '1340502',
		description:
			'ALVEJAMENTO, TINGIMENTO E TORÇÃO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO',
	},
	{
		id: '1932200',
		description: 'FABRICAÇÃO DE BIOCOMBUSTÍVEIS, EXCETO ÁLCOOL',
	},
	{
		id: '1340599',
		description:
			'OUTROS SERVIÇOS DE ACABAMENTO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO',
	},
	{
		id: '7729202',
		description:
			'ALUGUEL DE MÓVEIS, UTENSÍLIOS E APARELHOS DE USO DOMÉSTICO E PESSOAL; INSTRUMENTOS MUSICAIS',
	},
	{ id: '1352900', description: 'FABRICAÇÃO DE ARTEFATOS DE TAPEÇARIA' },
	{ id: '1353700', description: 'FABRICAÇÃO DE ARTEFATOS DE CORDOARIA' },
	{
		id: '1354500',
		description: 'FABRICAÇÃO DE TECIDOS ESPECIAIS, INCLUSIVE ARTEFATOS',
	},
	{
		id: '1359600',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS TÊXTEIS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '1411801', description: 'CONFECÇÃO DE ROUPAS ÍNTIMAS' },
	{ id: '1411802', description: 'FACÇÃO DE ROUPAS ÍNTIMAS' },
	{
		id: '1412601',
		description:
			'CONFECÇÃO DE PEÇAS DE VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS E AS CONFECCIONADAS SOB MEDIDA',
	},
	{
		id: '1412602',
		description:
			'CONFECÇÃO, SOB MEDIDA, DE PEÇAS DO VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS',
	},
	{
		id: '1412603',
		description: 'FACÇÃO DE PEÇAS DO VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS',
	},
	{
		id: '1413401',
		description: 'CONFECÇÃO DE ROUPAS PROFISSIONAIS, EXCETO SOB MEDIDA',
	},
	{
		id: '1413402',
		description: 'CONFECÇÃO, SOB MEDIDA, DE ROUPAS PROFISSIONAIS',
	},
	{ id: '1413403', description: 'FACÇÃO DE ROUPAS PROFISSIONAIS' },
	{
		id: '1622699',
		description:
			'FABRICAÇÃO DE OUTROS ARTIGOS DE CARPINTARIA PARA CONSTRUÇÃO',
	},
	{
		id: '4763604',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE CAÇA, PESCA E CAMPING',
	},
	{
		id: '1414200',
		description:
			'FABRICAÇÃO DE ACESSÓRIOS DO VESTUÁRIO, EXCETO PARA SEGURANÇA E PROTEÇÃO',
	},
	{ id: '7729203', description: 'ALUGUEL DE MATERIAL MÉDICO' },
	{
		id: '1422300',
		description:
			'FABRICAÇÃO DE ARTIGOS DO VESTUÁRIO, PRODUZIDOS EM MALHARIAS E TRICOTAGENS, EXCETO MEIAS',
	},
	{
		id: '1510600',
		description: 'CURTIMENTO E OUTRAS PREPARAÇÕES DE COURO',
	},
	{
		id: '1521100',
		description:
			'FABRICAÇÃO DE ARTIGOS PARA VIAGEM, BOLSAS E SEMELHANTES DE QUALQUER MATERIAL',
	},
	{
		id: '1529700',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE COURO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '1531901', description: 'FABRICAÇÃO DE CALÇADOS DE COURO' },
	{
		id: '1531902',
		description: 'ACABAMENTO DE CALÇADOS DE COURO SOB CONTRATO',
	},
	{
		id: '1532700',
		description: 'FABRICAÇÃO DE TÊNIS DE QUALQUER MATERIAL',
	},
	{
		id: '1533500',
		description: 'FABRICAÇÃO DE CALÇADOS DE MATERIAL SINTÉTICO',
	},
	{
		id: '1539400',
		description:
			'FABRICAÇÃO DE CALÇADOS DE MATERIAIS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '1540800',
		description: 'FABRICAÇÃO DE PARTES PARA CALÇADOS, DE QUALQUER MATERIAL',
	},
	{
		id: '1621800',
		description:
			'FABRICAÇÃO DE MADEIRA LAMINADA E DE CHAPAS DE MADEIRA COMPENSADA, PRENSADA E AGLOMERADA',
	},
	{
		id: '1622601',
		description: 'FABRICAÇÃO DE CASAS DE MADEIRA PRÉ FABRICADAS',
	},
	{
		id: '1622602',
		description:
			'FABRICAÇÃO DE ESQUADRIAS DE MADEIRA E DE PEÇAS DE MADEIRA PARA INSTALAÇÕES INDUSTRIAIS E COMERCIAIS',
	},
	{ id: '4924800', description: 'TRANSPORTE ESCOLAR' },
	{
		id: '1623400',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE TANOARIA E DE EMBALAGENS DE MADEIRA',
	},
	{ id: '4643501', description: 'COMÉRCIO ATACADISTA DE CALÇADOS' },
	{ id: '8599601', description: 'FORMAÇÃO DE CONDUTORES' },
	{
		id: '1629301',
		description:
			'FABRICAÇÃO DE ARTEFATOS DIVERSOS DE MADEIRA, EXCETO MÓVEIS',
	},
	{
		id: '1629302',
		description:
			'FABRICAÇÃO DE ARTEFATOS DIVERSOS DE CORTIÇA, BAMBU, PALHA, VIME E OUTROS MATERIAIS TRANÇADOS, EXCETO MÓVEIS',
	},
	{
		id: '1710900',
		description:
			'FABRICAÇÃO DE CELULOSE E OUTRAS PASTAS PARA A FABRICAÇÃO DE PAPEL',
	},
	{ id: '1721400', description: 'FABRICAÇÃO DE PAPEL' },
	{ id: '1722200', description: 'FABRICAÇÃO DE CARTOLINA E PAPEL CARTÃO' },
	{ id: '1731100', description: 'FABRICAÇÃO DE EMBALAGENS DE PAPEL' },
	{
		id: '1732000',
		description: 'FABRICAÇÃO DE EMBALAGENS DE CARTOLINA E PAPEL CARTÃO',
	},
	{
		id: '1733800',
		description: 'FABRICAÇÃO DE CHAPAS E DE EMBALAGENS DE PAPELÃO ONDULADO',
	},
	{ id: '1741901', description: 'FABRICAÇÃO DE FORMULÁRIOS CONTÍNUOS' },
	{
		id: '1741902',
		description:
			'FABRICAÇÃO DE PRODUTOS DE PAPEL, CARTOLINA, PAPEL CARTÃO E PAPELÃO ONDULADO PARA USO COMERCIAL E DE ESCRITÓRIO',
	},
	{ id: '1742701', description: 'FABRICAÇÃO DE FRALDAS DESCARTÁVEIS' },
	{ id: '1742702', description: 'FABRICAÇÃO DE ABSORVENTES HIGIÊNICOS' },
	{
		id: '1742799',
		description:
			'FABRICAÇÃO DE PRODUTOS DE PAPEL PARA USO DOMÉSTICO E HIGIÊNICO SANITÁRIO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '1749400',
		description:
			'FABRICAÇÃO DE PRODUTOS DE PASTAS CELULÓSICAS, PAPEL, CARTOLINA, PAPEL CARTÃO E PAPELÃO ONDULADO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '1922599',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS DERIVADOS DO PETRÓLEO, EXCETO PRODUTOS DO REFINO',
	},
	{ id: '1811301', description: 'IMPRESSÃO DE JORNAIS' },
	{ id: '2011800', description: 'FABRICAÇÃO DE CLORO E ÁLCALIS' },
	{
		id: '1811302',
		description:
			'IMPRESSÃO DE LIVROS, REVISTAS E OUTRAS PUBLICAÇÕES PERIÓDICAS',
	},
	{ id: '1812100', description: 'IMPRESSÃO DE MATERIAL DE SEGURANÇA' },
	{
		id: '1813001',
		description: 'IMPRESSÃO DE MATERIAL PARA USO PUBLICITÁRIO',
	},
	{ id: '1813099', description: 'IMPRESSÃO DE MATERIAL PARA OUTROS USOS' },
	{ id: '1821100', description: 'SERVIÇOS DE PRÉ IMPRESSÃO' },
	{
		id: '1822901',
		description: 'SERVIÇOS DE ENCADERNAÇÃO E PLASTIFICAÇÃO',
	},
	{
		id: '1822999',
		description:
			'SERVIÇOS DE ACABAMENTOS GRÁFICOS, EXCETO ENCADERNAÇÃO E PLASTIFICAÇÃO',
	},
	{ id: '1830001', description: 'REPRODUÇÃO DE SOM EM QUALQUER SUPORTE' },
	{ id: '1830002', description: 'REPRODUÇÃO DE VÍDEO EM QUALQUER SUPORTE' },
	{
		id: '1830003',
		description: 'REPRODUÇÃO DE SOFTWARE EM QUALQUER SUPORTE',
	},
	{ id: '1910100', description: 'COQUERIAS' },
	{ id: '1922501', description: 'FORMULAÇÃO DE COMBUSTÍVEIS' },
	{ id: '1922502', description: 'RERREFINO DE ÓLEOS LUBRIFICANTES' },
	{
		id: '2013401',
		description: 'FABRICAÇÃO DE ADUBOS E FERTILIZANTES ORGANOMINERAIS',
	},
	{
		id: '2012600',
		description: 'FABRICAÇÃO DE INTERMEDIÁRIOS PARA FERTILIZANTES',
	},
	{
		id: '2013402',
		description:
			'FABRICAÇÃO DE ADUBOS E FERTILIZANTES, EXCETO ORGANOMINERAIS',
	},
	{ id: '2019301', description: 'ELABORAÇÃO DE COMBUSTÍVEIS NUCLEARES' },
	{
		id: '2122000',
		description: 'FABRICAÇÃO DE MEDICAMENTOS PARA USO VETERINÁRIO',
	},
	{
		id: '2019399',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS QUÍMICOS INORGÂNICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '2021500',
		description: 'FABRICAÇÃO DE PRODUTOS PETROQUÍMICOS BÁSICOS',
	},
	{
		id: '2022300',
		description:
			'FABRICAÇÃO DE INTERMEDIÁRIOS PARA PLASTIFICANTES, RESINAS E FIBRAS',
	},
	{
		id: '2029100',
		description:
			'FABRICAÇÃO DE PRODUTOS QUÍMICOS ORGÂNICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '2031200', description: 'FABRICAÇÃO DE RESINAS TERMOPLÁSTICAS' },
	{ id: '2032100', description: 'FABRICAÇÃO DE RESINAS TERMOFIXAS' },
	{ id: '2033900', description: 'FABRICAÇÃO DE ELASTÔMEROS' },
	{
		id: '4643502',
		description: 'COMÉRCIO ATACADISTA DE BOLSAS, MALAS E ARTIGOS DE VIAGEM',
	},
	{
		id: '2040100',
		description: 'FABRICAÇÃO DE FIBRAS ARTIFICIAIS E SINTÉTICAS',
	},
	{ id: '2051700', description: 'FABRICAÇÃO DE DEFENSIVOS AGRÍCOLAS' },
	{
		id: '2052500',
		description: 'FABRICAÇÃO DE DESINFESTANTES DOMISSANITÁRIOS',
	},
	{
		id: '2061400',
		description: 'FABRICAÇÃO DE SABÕES E DETERGENTES SINTÉTICOS',
	},
	{
		id: '2063100',
		description:
			'FABRICAÇÃO DE COSMÉTICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL',
	},
	{
		id: '2071100',
		description: 'FABRICAÇÃO DE TINTAS, VERNIZES, ESMALTES E LACAS',
	},
	{ id: '2072000', description: 'FABRICAÇÃO DE TINTAS DE IMPRESSÃO' },
	{
		id: '2092401',
		description: 'FABRICAÇÃO DE PÓLVORAS, EXPLOSIVOS E DETONANTES',
	},
	{ id: '2092402', description: 'FABRICAÇÃO DE ARTIGOS PIROTÉCNICOS' },
	{ id: '2092403', description: 'FABRICAÇÃO DE FÓSFOROS DE SEGURANÇA' },
	{
		id: '2093200',
		description: 'FABRICAÇÃO DE ADITIVOS DE USO INDUSTRIAL',
	},
	{ id: '2094100', description: 'FABRICAÇÃO DE CATALISADORES' },
	{
		id: '2099101',
		description:
			'FABRICAÇÃO DE CHAPAS, FILMES, PAPÉIS E OUTROS MATERIAIS E PRODUTOS QUÍMICOS PARA FOTOGRAFIA',
	},
	{
		id: '2099199',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS QUÍMICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '2091600', description: 'FABRICAÇÃO DE ADESIVOS E SELANTES' },
	{ id: '2110600', description: 'FABRICAÇÃO DE PRODUTOS FARMOQUÍMICOS' },
	{
		id: '2121101',
		description: 'FABRICAÇÃO DE MEDICAMENTOS ALOPÁTICOS PARA USO HUMANO',
	},
	{
		id: '2121102',
		description: 'FABRICAÇÃO DE MEDICAMENTOS HOMEOPÁTICOS PARA USO HUMANO',
	},
	{
		id: '2121103',
		description: 'FABRICAÇÃO DE MEDICAMENTOS FITOTERÁPICOS PARA USO HUMANO',
	},
	{ id: '2342701', description: 'FABRICAÇÃO DE AZULEJOS E PISOS' },
	{
		id: '2211100',
		description: 'FABRICAÇÃO DE PNEUMÁTICOS E DE CÂMARAS DE AR',
	},
	{ id: '2212900', description: 'REFORMA DE PNEUMÁTICOS USADOS' },
	{
		id: '2391501',
		description: 'BRITAMENTO DE PEDRAS, EXCETO ASSOCIADO À EXTRAÇÃO',
	},
	{
		id: '2219600',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE BORRACHA NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '2442300', description: 'METALURGIA DOS METAIS PRECIOSOS' },
	{
		id: '3299004',
		description: 'FABRICAÇÃO DE PAINÉIS E LETREIROS LUMINOSOS',
	},
	{
		id: '2221800',
		description:
			'FABRICAÇÃO DE LAMINADOS PLANOS E TUBULARES DE MATERIAL PLÁSTICO',
	},
	{
		id: '2222600',
		description: 'FABRICAÇÃO DE EMBALAGENS DE MATERIAL PLÁSTICO',
	},
	{
		id: '2223400',
		description:
			'FABRICAÇÃO DE TUBOS E ACESSÓRIOS DE MATERIAL PLÁSTICO PARA USO NA CONSTRUÇÃO',
	},
	{
		id: '2229301',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE MATERIAL PLÁSTICO PARA USO PESSOAL E DOMÉSTICO',
	},
	{
		id: '2229302',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE MATERIAL PLÁSTICO PARA USOS INDUSTRIAIS',
	},
	{
		id: '2229303',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE MATERIAL PLÁSTICO PARA USO NA CONSTRUÇÃO, EXCETO TUBOS E ACESSÓRIOS',
	},
	{ id: '3600602', description: 'DISTRIBUIÇÃO DE ÁGUA POR CAMINHÕES' },
	{
		id: '2229399',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE MATERIAL PLÁSTICO PARA OUTROS USOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '2311700',
		description: 'FABRICAÇÃO DE VIDRO PLANO E DE SEGURANÇA',
	},
	{ id: '2312500', description: 'FABRICAÇÃO DE EMBALAGENS DE VIDRO' },
	{
		id: '2424502',
		description:
			'PRODUÇÃO DE RELAMINADOS, TREFILADOS E PERFILADOS DE AÇO, EXCETO ARAMES',
	},
	{ id: '2319200', description: 'FABRICAÇÃO DE ARTIGOS DE VIDRO' },
	{ id: '2320600', description: 'FABRICAÇÃO DE CIMENTO' },
	{
		id: '2330301',
		description:
			'FABRICAÇÃO DE ESTRUTURAS PRÉ MOLDADAS DE CONCRETO ARMADO, EM SÉRIE E SOB ENCOMENDA',
	},
	{ id: '2449101', description: 'PRODUÇÃO DE ZINCO EM FORMAS PRIMÁRIAS' },
	{
		id: '2330302',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE CIMENTO PARA USO NA CONSTRUÇÃO',
	},
	{
		id: '2330303',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE FIBROCIMENTO PARA USO NA CONSTRUÇÃO',
	},
	{
		id: '2330304',
		description: 'FABRICAÇÃO DE CASAS PRÉ MOLDADAS DE CONCRETO',
	},
	{
		id: '2330305',
		description:
			'PREPARAÇÃO DE MASSA DE CONCRETO E ARGAMASSA PARA CONSTRUÇÃO',
	},
	{
		id: '2330399',
		description:
			'FABRICAÇÃO DE OUTROS ARTEFATOS E PRODUTOS DE CONCRETO, CIMENTO, FIBROCIMENTO, GESSO E MATERIAIS SEMELHANTES',
	},
	{
		id: '2341900',
		description: 'FABRICAÇÃO DE PRODUTOS CERÂMICOS REFRATÁRIOS',
	},
	{
		id: '2342702',
		description:
			'FABRICAÇÃO DE ARTEFATOS DE CERÂMICA E BARRO COZIDO PARA USO NA CONSTRUÇÃO, EXCETO AZULEJOS E PISOS',
	},
	{
		id: '2349401',
		description: 'FABRICAÇÃO DE MATERIAL SANITÁRIO DE CERÂMICA',
	},
	{
		id: '2349499',
		description:
			'FABRICAÇÃO DE PRODUTOS CERÂMICOS NÃO REFRATÁRIOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3314706',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALAÇÕES TÉRMICAS',
	},
	{ id: '2392300', description: 'FABRICAÇÃO DE CAL E GESSO' },
	{
		id: '2399101',
		description:
			'DECORAÇÃO, LAPIDAÇÃO, GRAVAÇÃO, VITRIFICAÇÃO E OUTROS TRABALHOS EM CERÂMICA, LOUÇA, VIDRO E CRISTAL',
	},
	{ id: '2399102', description: 'FABRICAÇÃO DE ABRASIVOS' },
	{ id: '2443100', description: 'METALURGIA DO COBRE' },
	{
		id: '2599301',
		description:
			'SERVIÇOS DE CONFECÇÃO DE ARMAÇÕES METÁLICAS PARA A CONSTRUÇÃO',
	},
	{
		id: '2399199',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS DE MINERAIS NÃO METÁLICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '2411300', description: 'PRODUÇÃO DE FERRO GUSA' },
	{ id: '2412100', description: 'PRODUÇÃO DE FERROLIGAS' },
	{
		id: '2422901',
		description:
			'PRODUÇÃO DE LAMINADOS PLANOS DE AÇO AO CARBONO, REVESTIDOS OU NÃO',
	},
	{
		id: '2422902',
		description: 'PRODUÇÃO DE LAMINADOS PLANOS DE AÇOS ESPECIAIS',
	},
	{ id: '9603303', description: 'SERVIÇOS DE SEPULTAMENTO' },
	{ id: '2423701', description: 'PRODUÇÃO DE TUBOS DE AÇO SEM COSTURA' },
	{
		id: '2423702',
		description: 'PRODUÇÃO DE LAMINADOS LONGOS DE AÇO, EXCETO TUBOS',
	},
	{ id: '2424501', description: 'PRODUÇÃO DE ARAMES DE AÇO' },
	{ id: '2431800', description: 'PRODUÇÃO DE TUBOS DE AÇO COM COSTURA' },
	{ id: '2439300', description: 'PRODUÇÃO DE OUTROS TUBOS DE FERRO E AÇO' },
	{
		id: '2441501',
		description: 'PRODUÇÃO DE ALUMÍNIO E SUAS LIGAS EM FORMAS PRIMÁRIAS',
	},
	{ id: '2441502', description: 'PRODUÇÃO DE LAMINADOS DE ALUMÍNIO' },
	{ id: '2449102', description: 'PRODUÇÃO DE LAMINADOS DE ZINCO' },
	{
		id: '2449103',
		description: 'FABRICAÇÃO DE ÂNODOS PARA GALVANOPLASTIA',
	},
	{
		id: '2449199',
		description:
			'METALURGIA DE OUTROS METAIS NÃO FERROSOS E SUAS LIGAS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '2451200', description: 'FUNDIÇÃO DE FERRO E AÇO' },
	{
		id: '2452100',
		description: 'FUNDIÇÃO DE METAIS NÃO FERROSOS E SUAS LIGAS',
	},
	{ id: '2511000', description: 'FABRICAÇÃO DE ESTRUTURAS METÁLICAS' },
	{ id: '2512800', description: 'FABRICAÇÃO DE ESQUADRIAS DE METAL' },
	{
		id: '2542000',
		description: 'FABRICAÇÃO DE ARTIGOS DE SERRALHERIA, EXCETO ESQUADRIAS',
	},
	{
		id: '4679699',
		description: 'COMÉRCIO ATACADISTA DE MATERIAIS DE CONSTRUÇÃO EM GERAL',
	},
	{
		id: '2513600',
		description: 'FABRICAÇÃO DE OBRAS DE CALDEIRARIA PESADA',
	},
	{
		id: '2550101',
		description:
			'FABRICAÇÃO DE EQUIPAMENTO BÉLICO PESADO, EXCETO VEÍCULOS MILITARES DE COMBATE',
	},
	{
		id: '2521700',
		description:
			'FABRICAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS PARA AQUECIMENTO CENTRAL',
	},
	{
		id: '2522500',
		description:
			'FABRICAÇÃO DE CALDEIRAS GERADORAS DE VAPOR, EXCETO PARA AQUECIMENTO CENTRAL E PARA VEÍCULOS',
	},
	{ id: '2531401', description: 'PRODUÇÃO DE FORJADOS DE AÇO' },
	{
		id: '2531402',
		description: 'PRODUÇÃO DE FORJADOS DE METAIS NÃO FERROSOS E SUAS LIGAS',
	},
	{ id: '2599302', description: 'SERVIÇO DE CORTE E DOBRA DE METAIS' },
	{
		id: '2532201',
		description: 'PRODUÇÃO DE ARTEFATOS ESTAMPADOS DE METAL',
	},
	{ id: '2532202', description: 'METALURGIA DO PÓ' },
	{ id: '2539001', description: 'SERVIÇOS DE USINAGEM, TORNEARIA E SOLDA' },
	{
		id: '2539002',
		description: 'SERVIÇOS DE TRATAMENTO E REVESTIMENTO EM METAIS',
	},
	{ id: '2541100', description: 'FABRICAÇÃO DE ARTIGOS DE CUTELARIA' },
	{ id: '2543800', description: 'FABRICAÇÃO DE FERRAMENTAS' },
	{
		id: '2550102',
		description: 'FABRICAÇÃO DE ARMAS DE FOGO, OUTRAS ARMAS  E MUNIÇÕES',
	},
	{ id: '2591800', description: 'FABRICAÇÃO DE EMBALAGENS METÁLICAS' },
	{
		id: '2592601',
		description:
			'FABRICAÇÃO DE PRODUTOS DE TREFILADOS DE METAL PADRONIZADOS',
	},
	{
		id: '2592602',
		description:
			'FABRICAÇÃO DE PRODUTOS DE TREFILADOS DE METAL, EXCETO PADRONIZADOS',
	},
	{
		id: '2593400',
		description:
			'FABRICAÇÃO DE ARTIGOS DE METAL PARA USO DOMÉSTICO E PESSOAL',
	},
	{
		id: '2599399',
		description:
			'FABRICAÇÃO DE OUTROS PRODUTOS DE METAL NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '2610800', description: 'FABRICAÇÃO DE COMPONENTES ELETRÔNICOS' },
	{
		id: '2621300',
		description: 'FABRICAÇÃO DE EQUIPAMENTOS DE INFORMÁTICA',
	},
	{
		id: '2670102',
		description:
			'FABRICAÇÃO DE APARELHOS FOTOGRÁFICOS E CINEMATOGRÁFICOS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2622100',
		description:
			'FABRICAÇÃO DE PERIFÉRICOS PARA EQUIPAMENTOS DE INFORMÁTICA',
	},
	{
		id: '2722802',
		description:
			'RECONDICIONAMENTO DE BATERIAS E ACUMULADORES PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '3250704',
		description:
			'FABRICAÇÃO DE APARELHOS E UTENSÍLIOS PARA CORREÇÃO DE DEFEITOS FÍSICOS E APARELHOS ORTOPÉDICOS EM GERAL, EXCETO SOB ENCOMENDA',
	},
	{
		id: '2631100',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS TRANSMISSORES DE COMUNICAÇÃO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2632900',
		description:
			'FABRICAÇÃO DE APARELHOS TELEFÔNICOS E DE OUTROS EQUIPAMENTOS DE COMUNICAÇÃO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2680900',
		description: 'FABRICAÇÃO DE MÍDIAS VIRGENS, MAGNÉTICAS E ÓPTICAS',
	},
	{ id: '3299005', description: 'FABRICAÇÃO DE AVIAMENTOS PARA COSTURA' },
	{
		id: '2640000',
		description:
			'FABRICAÇÃO DE APARELHOS DE RECEPÇÃO, REPRODUÇÃO, GRAVAÇÃO E AMPLIFICAÇÃO DE ÁUDIO E VÍDEO',
	},
	{
		id: '2710401',
		description:
			'FABRICAÇÃO DE GERADORES DE CORRENTE CONTÍNUA E ALTERNADA, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2815101',
		description: 'FABRICAÇÃO DE ROLAMENTOS PARA FINS INDUSTRIAIS',
	},
	{
		id: '2651500',
		description:
			'FABRICAÇÃO DE APARELHOS E EQUIPAMENTOS DE MEDIDA, TESTE E CONTROLE',
	},
	{ id: '2652300', description: 'FABRICAÇÃO DE CRONÔMETROS E RELÓGIOS' },
	{
		id: '2731700',
		description:
			'FABRICAÇÃO DE APARELHOS E EQUIPAMENTOS PARA DISTRIBUIÇÃO E CONTROLE DE ENERGIA ELÉTRICA',
	},
	{
		id: '3314703',
		description: 'MANUTENÇÃO E REPARAÇÃO DE VÁLVULAS INDUSTRIAIS',
	},
	{
		id: '2660400',
		description:
			'FABRICAÇÃO DE APARELHOS ELETROMÉDICOS E ELETROTERAPÊUTICOS E EQUIPAMENTOS DE IRRADIAÇÃO',
	},
	{
		id: '2670101',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS E INSTRUMENTOS ÓPTICOS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2814301',
		description:
			'FABRICAÇÃO DE COMPRESSORES PARA USO INDUSTRIAL, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2710402',
		description:
			'FABRICAÇÃO DE TRANSFORMADORES, INDUTORES, CONVERSORES, SINCRONIZADORES E SEMELHANTES, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2710403',
		description: 'FABRICAÇÃO DE MOTORES ELÉTRICOS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2721000',
		description:
			'FABRICAÇÃO DE PILHAS, BATERIAS E ACUMULADORES ELÉTRICOS, EXCETO PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2722801',
		description:
			'FABRICAÇÃO DE BATERIAS E ACUMULADORES PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2732500',
		description:
			'FABRICAÇÃO DE MATERIAL ELÉTRICO PARA INSTALAÇÕES EM CIRCUITO DE CONSUMO',
	},
	{
		id: '2733300',
		description:
			'FABRICAÇÃO DE FIOS, CABOS E CONDUTORES ELÉTRICOS ISOLADOS',
	},
	{ id: '2740601', description: 'FABRICAÇÃO DE LÂMPADAS' },
	{
		id: '2740602',
		description:
			'FABRICAÇÃO DE LUMINÁRIAS E OUTROS EQUIPAMENTOS DE ILUMINAÇÃO',
	},
	{
		id: '3299006',
		description: 'FABRICAÇÃO DE VELAS, INCLUSIVE DECORATIVAS',
	},
	{
		id: '2751100',
		description:
			'FABRICAÇÃO DE FOGÕES, REFRIGERADORES E MÁQUINAS DE LAVAR E SECAR PARA USO DOMÉSTICO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2759701',
		description:
			'FABRICAÇÃO DE APARELHOS ELÉTRICOS DE USO PESSOAL, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2814302',
		description:
			'FABRICAÇÃO DE COMPRESSORES PARA USO NÃO INDUSTRIAL, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2759799',
		description:
			'FABRICAÇÃO DE OUTROS APARELHOS ELETRODOMÉSTICOS NÃO ESPECIFICADOS ANTERIORMENTE, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2790201',
		description:
			'FABRICAÇÃO DE ELETRODOS, CONTATOS E OUTROS ARTIGOS DE CARVÃO E GRAFITA PARA USO ELÉTRICO, ELETROÍMÃS E ISOLADORES',
	},
	{
		id: '2790202',
		description: 'FABRICAÇÃO DE EQUIPAMENTOS PARA SINALIZAÇÃO E ALARME',
	},
	{
		id: '2790299',
		description:
			'FABRICAÇÃO DE OUTROS EQUIPAMENTOS E APARELHOS ELÉTRICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '2811900',
		description:
			'FABRICAÇÃO DE MOTORES E TURBINAS, PEÇAS E ACESSÓRIOS, EXCETO PARA AVIÕES E VEÍCULOS RODOVIÁRIOS',
	},
	{
		id: '2812700',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS HIDRÁULICOS E PNEUMÁTICOS, PEÇAS E ACESSÓRIOS, EXCETO VÁLVULAS',
	},
	{
		id: '2832100',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS PARA IRRIGAÇÃO AGRÍCOLA, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2813500',
		description:
			'FABRICAÇÃO DE VÁLVULAS, REGISTROS E DISPOSITIVOS SEMELHANTES, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2815102',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS DE TRANSMISSÃO PARA FINS INDUSTRIAIS, EXCETO ROLAMENTOS',
	},
	{
		id: '2824102',
		description:
			'FABRICAÇÃO DE APARELHOS E EQUIPAMENTOS DE AR CONDICIONADO PARA USO NÃO INDUSTRIAL',
	},
	{
		id: '3102100',
		description: 'FABRICAÇÃO DE MÓVEIS COM PREDOMINÂNCIA DE METAL',
	},
	{
		id: '2821601',
		description:
			'FABRICAÇÃO DE FORNOS INDUSTRIAIS, APARELHOS E EQUIPAMENTOS NÃO ELÉTRICOS PARA INSTALAÇÕES TÉRMICAS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2821602',
		description:
			'FABRICAÇÃO DE ESTUFAS E FORNOS ELÉTRICOS PARA FINS INDUSTRIAIS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2822401',
		description:
			'FABRICAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE PESSOAS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2941700',
		description:
			'FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA O SISTEMA MOTOR DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2942500',
		description:
			'FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA OS SISTEMAS DE MARCHA E TRANSMISSÃO DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2822402',
		description:
			'FABRICAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '3317102',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES PARA ESPORTE E LAZER',
	},
	{ id: '4732600', description: 'COMÉRCIO VAREJISTA DE LUBRIFICANTES' },
	{
		id: '2823200',
		description:
			'FABRICAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2824101',
		description:
			'FABRICAÇÃO DE APARELHOS E EQUIPAMENTOS DE AR CONDICIONADO PARA USO INDUSTRIAL',
	},
	{
		id: '2950600',
		description:
			'RECONDICIONAMENTO E RECUPERAÇÃO DE MOTORES PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2825900',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA SANEAMENTO BÁSICO E AMBIENTAL, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2829101',
		description:
			'FABRICAÇÃO DE MÁQUINAS DE ESCREVER, CALCULAR E OUTROS EQUIPAMENTOS NÃO ELETRÔNICOS PARA ESCRITÓRIO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2829199',
		description:
			'FABRICAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO GERAL NÃO ESPECIFICADOS ANTERIORMENTE, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2831300',
		description: 'FABRICAÇÃO DE TRATORES AGRÍCOLAS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2943300',
		description:
			'FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA O SISTEMA DE FREIOS DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2854200',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA TERRAPLENAGEM, PAVIMENTAÇÃO E CONSTRUÇÃO, PEÇAS E ACESSÓRIOS, EXCETO TRATORES',
	},
	{
		id: '4724500',
		description: 'COMÉRCIO VAREJISTA DE HORTIFRUTIGRANJEIROS',
	},
	{
		id: '2833000',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A AGRICULTURA E PECUÁRIA, PEÇAS E ACESSÓRIOS, EXCETO PARA IRRIGAÇÃO',
	},
	{
		id: '2840200',
		description: 'FABRICAÇÃO DE MÁQUINAS FERRAMENTA, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '3250702',
		description:
			'FABRICAÇÃO DE MOBILIÁRIO PARA USO MÉDICO, CIRÚRGICO, ODONTOLÓGICO E DE LABORATÓRIO',
	},
	{
		id: '2851800',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A PROSPECÇÃO E EXTRAÇÃO DE PETRÓLEO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2852600',
		description:
			'FABRICAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS PARA USO NA EXTRAÇÃO MINERAL, PEÇAS E ACESSÓRIOS, EXCETO NA EXTRAÇÃO DE PETRÓLEO',
	},
	{
		id: '2853400',
		description:
			'FABRICAÇÃO DE TRATORES, PEÇAS E ACESSÓRIOS, EXCETO AGRÍCOLAS',
	},
	{
		id: '2944100',
		description:
			'FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA O SISTEMA DE DIREÇÃO E SUSPENSÃO DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2861500',
		description:
			'FABRICAÇÃO DE MÁQUINAS PARA A INDÚSTRIA METALÚRGICA, PEÇAS E ACESSÓRIOS, EXCETO MÁQUINAS FERRAMENTA',
	},
	{
		id: '3101200',
		description: 'FABRICAÇÃO DE MÓVEIS COM PREDOMINÂNCIA DE MADEIRA',
	},
	{ id: '3314704', description: 'MANUTENÇÃO E REPARAÇÃO DE COMPRESSORES' },
	{
		id: '2862300',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DE ALIMENTOS, BEBIDAS E FUMO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2863100',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA TÊXTIL, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2864000',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DO VESTUÁRIO, DO COURO E DE CALÇADOS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '3042300',
		description:
			'FABRICAÇÃO DE TURBINAS, MOTORES E OUTROS COMPONENTES E PEÇAS PARA AERONAVES',
	},
	{
		id: '3314715',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO NA EXTRAÇÃO MINERAL, EXCETO NA EXTRAÇÃO DE PETRÓLEO',
	},
	{
		id: '2865800',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DE CELULOSE, PAPEL E PAPELÃO E ARTEFATOS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2866600',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA DO PLÁSTICO, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '3250703',
		description:
			'FABRICAÇÃO DE APARELHOS E UTENSÍLIOS PARA CORREÇÃO DE DEFEITOS FÍSICOS E APARELHOS ORTOPÉDICOS EM GERAL SOB ENCOMENDA',
	},
	{
		id: '2869100',
		description:
			'FABRICAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO INDUSTRIAL ESPECÍFICO NÃO ESPECIFICADOS ANTERIORMENTE, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '2910701',
		description: 'FABRICAÇÃO DE AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS',
	},
	{
		id: '2910702',
		description:
			'FABRICAÇÃO DE CHASSIS COM MOTOR PARA AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS',
	},
	{
		id: '2910703',
		description:
			'FABRICAÇÃO DE MOTORES PARA AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS',
	},
	{ id: '2920401', description: 'FABRICAÇÃO DE CAMINHÕES E ÔNIBUS' },
	{
		id: '2920402',
		description: 'FABRICAÇÃO DE MOTORES PARA CAMINHÕES E ÔNIBUS',
	},
	{
		id: '2930101',
		description:
			'FABRICAÇÃO DE CABINES, CARROCERIAS E REBOQUES PARA CAMINHÕES',
	},
	{ id: '2930102', description: 'FABRICAÇÃO DE CARROCERIAS PARA ÔNIBUS' },
	{
		id: '2930103',
		description:
			'FABRICAÇÃO DE CABINES, CARROCERIAS E REBOQUES PARA OUTROS VEÍCULOS AUTOMOTORES, EXCETO CAMINHÕES E ÔNIBUS',
	},
	{ id: '3299001', description: 'FABRICAÇÃO DE GUARDA CHUVAS E SIMILARES' },
	{
		id: '2945000',
		description:
			'FABRICAÇÃO DE MATERIAL ELÉTRICO E ELETRÔNICO PARA VEÍCULOS AUTOMOTORES, EXCETO BATERIAS',
	},
	{
		id: '2949201',
		description:
			'FABRICAÇÃO DE BANCOS E ESTOFADOS PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '2949299',
		description:
			'FABRICAÇÃO DE OUTRAS PEÇAS E ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '3011301',
		description: 'CONSTRUÇÃO DE EMBARCAÇÕES DE GRANDE PORTE',
	},
	{
		id: '3011302',
		description:
			'CONSTRUÇÃO DE EMBARCAÇÕES PARA USO COMERCIAL E PARA USOS ESPECIAIS, EXCETO DE GRANDE PORTE',
	},
	{
		id: '3012100',
		description: 'CONSTRUÇÃO DE EMBARCAÇÕES PARA ESPORTE E LAZER',
	},
	{
		id: '3031800',
		description:
			'FABRICAÇÃO DE LOCOMOTIVAS, VAGÕES E OUTROS MATERIAIS RODANTES',
	},
	{
		id: '3032600',
		description:
			'FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA VEÍCULOS FERROVIÁRIOS',
	},
	{
		id: '3314716',
		description: 'MANUTENÇÃO E REPARAÇÃO DE TRATORES, EXCETO AGRÍCOLAS',
	},
	{ id: '4729601', description: 'TABACARIA' },
	{ id: '3041500', description: 'FABRICAÇÃO DE AERONAVES' },
	{
		id: '6622300',
		description:
			'CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDÊNCIA COMPLEMENTAR E DE SAÚDE',
	},
	{
		id: '3050400',
		description: 'FABRICAÇÃO DE VEÍCULOS MILITARES DE COMBATE',
	},
	{ id: '3091101', description: 'FABRICAÇÃO DE MOTOCICLETAS' },
	{
		id: '3091102',
		description: 'FABRICAÇÃO DE PEÇAS E ACESSÓRIOS PARA MOTOCICLETAS',
	},
	{
		id: '3092000',
		description:
			'FABRICAÇÃO DE BICICLETAS E TRICICLOS NÃO MOTORIZADOS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '3099700',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS DE TRANSPORTE NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3103900',
		description:
			'FABRICAÇÃO DE MÓVEIS DE OUTROS MATERIAIS, EXCETO MADEIRA E METAL',
	},
	{ id: '3211601', description: 'LAPIDAÇÃO DE GEMAS' },
	{
		id: '3211602',
		description: 'FABRICAÇÃO DE ARTEFATOS DE JOALHERIA E OURIVESARIA',
	},
	{ id: '3211603', description: 'CUNHAGEM DE MOEDAS E MEDALHAS' },
	{
		id: '3212400',
		description: 'FABRICAÇÃO DE BIJUTERIAS E ARTEFATOS SEMELHANTES',
	},
	{
		id: '3220500',
		description: 'FABRICAÇÃO DE INSTRUMENTOS MUSICAIS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '3299002',
		description:
			'FABRICAÇÃO DE CANETAS, LÁPIS E OUTROS ARTIGOS PARA ESCRITÓRIO',
	},
	{
		id: '3299003',
		description:
			'FABRICAÇÃO DE LETRAS, LETREIROS E PLACAS DE QUALQUER MATERIAL, EXCETO LUMINOSOS',
	},
	{
		id: '3530100',
		description:
			'PRODUÇÃO E DISTRIBUIÇÃO DE VAPOR, ÁGUA QUENTE E AR CONDICIONADO',
	},
	{
		id: '3230200',
		description: 'FABRICAÇÃO DE ARTEFATOS PARA PESCA E ESPORTE',
	},
	{ id: '3240001', description: 'FABRICAÇÃO DE JOGOS ELETRÔNICOS' },
	{
		id: '3240002',
		description:
			'FABRICAÇÃO DE MESAS DE BILHAR, DE SINUCA E ACESSÓRIOS NÃO ASSOCIADA À LOCAÇÃO',
	},
	{
		id: '3240003',
		description:
			'FABRICAÇÃO DE MESAS DE BILHAR, DE SINUCA E ACESSÓRIOS ASSOCIADA À LOCAÇÃO',
	},
	{
		id: '3240099',
		description:
			'FABRICAÇÃO DE OUTROS BRINQUEDOS E JOGOS RECREATIVOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3250701',
		description:
			'FABRICAÇÃO DE INSTRUMENTOS NÃO ELETRÔNICOS E UTENSÍLIOS PARA USO MÉDICO, CIRÚRGICO, ODONTOLÓGICO E DE LABORATÓRIO',
	},
	{
		id: '3250705',
		description: 'FABRICAÇÃO DE MATERIAIS PARA MEDICINA E ODONTOLOGIA',
	},
	{ id: '3250706', description: 'SERVIÇOS DE PRÓTESE DENTÁRIA' },
	{ id: '3250707', description: 'FABRICAÇÃO DE ARTIGOS ÓPTICOS' },
	{ id: '3250709', description: 'SERVIÇO DE LABORATÓRIO ÓPTICO' },
	{
		id: '3291400',
		description: 'FABRICAÇÃO DE ESCOVAS, PINCÉIS E VASSOURAS',
	},
	{
		id: '3292201',
		description:
			'FABRICAÇÃO DE ROUPAS DE PROTEÇÃO E SEGURANÇA E RESISTENTES A FOGO',
	},
	{
		id: '3292202',
		description:
			'FABRICAÇÃO DE EQUIPAMENTOS E ACESSÓRIOS PARA SEGURANÇA PESSOAL E PROFISSIONAL',
	},
	{
		id: '3299099',
		description:
			'FABRICAÇÃO DE PRODUTOS DIVERSOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3311200',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS, EXCETO PARA VEÍCULOS',
	},
	{
		id: '3312102',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE',
	},
	{
		id: '3312103',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE APARELHOS ELETROMÉDICOS E ELETROTERAPÊUTICOS E EQUIPAMENTOS DE IRRADIAÇÃO',
	},
	{
		id: '3312104',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS E INSTRUMENTOS ÓPTICOS',
	},
	{
		id: '3313901',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE GERADORES, TRANSFORMADORES E MOTORES ELÉTRICOS',
	},
	{
		id: '3313902',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE BATERIAS E ACUMULADORES ELÉTRICOS, EXCETO PARA VEÍCULOS',
	},
	{
		id: '3313999',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E MATERIAIS ELÉTRICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3314701',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS MOTRIZES NÃO ELÉTRICAS',
	},
	{
		id: '3314702',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS HIDRÁULICOS E PNEUMÁTICOS, EXCETO VÁLVULAS',
	},
	{
		id: '3314707',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL',
	},
	{
		id: '3314708',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS',
	},
	{
		id: '3314709',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS DE ESCREVER, CALCULAR E DE OUTROS EQUIPAMENTOS NÃO ELETRÔNICOS PARA ESCRITÓRIO',
	},
	{
		id: '3314710',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO GERAL NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3314711',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AGRICULTURA E PECUÁRIA',
	},
	{
		id: '3314712',
		description: 'MANUTENÇÃO E REPARAÇÃO DE TRATORES AGRÍCOLAS',
	},
	{
		id: '3314713',
		description: 'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS FERRAMENTA',
	},
	{
		id: '3314714',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A PROSPECÇÃO E EXTRAÇÃO DE PETRÓLEO',
	},
	{
		id: '3314717',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM, PAVIMENTAÇÃO E CONSTRUÇÃO, EXCETO TRATORES',
	},
	{
		id: '3314718',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS PARA A INDÚSTRIA METALÚRGICA, EXCETO MÁQUINAS FERRAMENTA',
	},
	{
		id: '3314719',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DE ALIMENTOS, BEBIDAS E FUMO',
	},
	{
		id: '3314720',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA TÊXTIL, DO VESTUÁRIO, DO COURO E CALÇADOS',
	},
	{
		id: '3314721',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS PARA A INDÚSTRIA DE CELULOSE, PAPEL E PAPELÃO E ARTEFATOS',
	},
	{
		id: '3314722',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS PARA A INDÚSTRIA DO PLÁSTICO',
	},
	{
		id: '4221905',
		description: 'MANUTENÇÃO DE ESTAÇÕES E REDES DE TELECOMUNICAÇÕES',
	},
	{
		id: '3314799',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS PARA USOS INDUSTRIAIS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3315500',
		description: 'MANUTENÇÃO E REPARAÇÃO DE VEÍCULOS FERROVIÁRIOS',
	},
	{
		id: '3316301',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE AERONAVES, EXCETO A MANUTENÇÃO NA PISTA',
	},
	{ id: '3316302', description: 'MANUTENÇÃO DE AERONAVES NA PISTA' },
	{
		id: '3317101',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES E ESTRUTURAS FLUTUANTES',
	},
	{
		id: '3319800',
		description:
			'MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS E PRODUTOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3321000',
		description: 'INSTALAÇÃO DE MÁQUINAS E EQUIPAMENTOS INDUSTRIAIS',
	},
	{
		id: '3329501',
		description: 'SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL',
	},
	{ id: '3511501', description: 'GERAÇÃO DE ENERGIA ELÉTRICA' },
	{
		id: '3511502',
		description:
			'ATIVIDADES DE COORDENAÇÃO E CONTROLE DA OPERAÇÃO DA GERAÇÃO E TRANSMISSÃO DE ENERGIA ELÉTRICA',
	},
	{ id: '3512300', description: 'TRANSMISSÃO DE ENERGIA ELÉTRICA' },
	{ id: '3513100', description: 'COMÉRCIO ATACADISTA DE ENERGIA ELÉTRICA' },
	{ id: '3514000', description: 'DISTRIBUIÇÃO DE ENERGIA ELÉTRICA' },
	{ id: '4212000', description: 'CONSTRUÇÃO DE OBRAS DE ARTE ESPECIAIS' },
	{
		id: '3520401',
		description: 'PRODUÇÃO DE GÁS; PROCESSAMENTO DE GÁS NATURAL',
	},
	{
		id: '3520402',
		description: 'DISTRIBUIÇÃO DE COMBUSTÍVEIS GASOSOS POR REDES URBANAS',
	},
	{ id: '3701100', description: 'GESTÃO DE REDES DE ESGOTO' },
	{
		id: '3702900',
		description:
			'ATIVIDADES RELACIONADAS A ESGOTO, EXCETO A GESTÃO DE REDES',
	},
	{ id: '3811400', description: 'COLETA DE RESÍDUOS NÃO PERIGOSOS' },
	{
		id: '4634601',
		description:
			'COMÉRCIO ATACADISTA DE CARNES BOVINAS E SUÍNAS E DERIVADOS',
	},
	{ id: '3812200', description: 'COLETA DE RESÍDUOS PERIGOSOS' },
	{
		id: '3821100',
		description: 'TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS NÃO PERIGOSOS',
	},
	{
		id: '3822000',
		description: 'TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS PERIGOSOS',
	},
	{ id: '3831901', description: 'RECUPERAÇÃO DE SUCATAS DE ALUMÍNIO' },
	{ id: '4330403', description: 'OBRAS DE ACABAMENTO EM GESSO E ESTUQUE' },
	{
		id: '3831999',
		description: 'RECUPERAÇÃO DE MATERIAIS METÁLICOS, EXCETO ALUMÍNIO',
	},
	{ id: '3832700', description: 'RECUPERAÇÃO DE MATERIAIS PLÁSTICOS' },
	{ id: '3839401', description: 'USINAS DE COMPOSTAGEM' },
	{
		id: '4311801',
		description: 'DEMOLIÇÃO DE EDIFÍCIOS E OUTRAS ESTRUTURAS',
	},
	{
		id: '3839499',
		description: 'RECUPERAÇÃO DE MATERIAIS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '3900500',
		description: 'DESCONTAMINAÇÃO E OUTROS SERVIÇOS DE GESTÃO DE RESÍDUOS',
	},
	{
		id: '4110700',
		description: 'INCORPORAÇÃO DE EMPREENDIMENTOS IMOBILIÁRIOS',
	},
	{
		id: '4330404',
		description: 'SERVIÇOS DE PINTURA DE EDIFÍCIOS EM GERAL',
	},
	{
		id: '4623102',
		description:
			'COMÉRCIO ATACADISTA DE COUROS, LÃS, PELES E OUTROS SUBPRODUTOS NÃO COMESTÍVEIS DE ORIGEM ANIMAL',
	},
	{ id: '4120400', description: 'CONSTRUÇÃO DE EDIFÍCIOS' },
	{ id: '4211101', description: 'CONSTRUÇÃO DE RODOVIAS E FERROVIAS' },
	{
		id: '4211102',
		description:
			'PINTURA PARA SINALIZAÇÃO EM PISTAS RODOVIÁRIAS E AEROPORTOS',
	},
	{
		id: '4311802',
		description: 'PREPARAÇÃO DE CANTEIRO E LIMPEZA DE TERRENO',
	},
	{
		id: '4634602',
		description: 'COMÉRCIO ATACADISTA DE AVES ABATIDAS E DERIVADOS',
	},
	{
		id: '4213800',
		description: 'OBRAS DE URBANIZAÇÃO - RUAS, PRAÇAS E CALÇADAS',
	},
	{
		id: '4221901',
		description:
			'CONSTRUÇÃO DE BARRAGENS E REPRESAS PARA GERAÇÃO DE ENERGIA ELÉTRICA',
	},
	{
		id: '4221902',
		description:
			'CONSTRUÇÃO DE ESTAÇÕES E REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA',
	},
	{
		id: '4221903',
		description: 'MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA',
	},
	{
		id: '4221904',
		description: 'CONSTRUÇÃO DE ESTAÇÕES E REDES DE TELECOMUNICAÇÕES',
	},
	{ id: '4623103', description: 'COMÉRCIO ATACADISTA DE ALGODÃO' },
	{
		id: '4222701',
		description:
			'CONSTRUÇÃO DE REDES DE ABASTECIMENTO DE ÁGUA, COLETA DE ESGOTO E CONSTRUÇÕES CORRELATAS, EXCETO OBRAS DE IRRIGAÇÃO',
	},
	{ id: '4222702', description: 'OBRAS DE IRRIGAÇÃO' },
	{
		id: '4223500',
		description:
			'CONSTRUÇÃO DE REDES DE TRANSPORTES POR DUTOS, EXCETO PARA ÁGUA E ESGOTO',
	},
	{ id: '4291000', description: 'OBRAS PORTUÁRIAS, MARÍTIMAS E FLUVIAIS' },
	{ id: '4292801', description: 'MONTAGEM DE ESTRUTURAS METÁLICAS' },
	{ id: '4292802', description: 'OBRAS DE MONTAGEM INDUSTRIAL' },
	{
		id: '4299501',
		description: 'CONSTRUÇÃO DE INSTALAÇÕES ESPORTIVAS E RECREATIVAS',
	},
	{
		id: '4299599',
		description:
			'OUTRAS OBRAS DE ENGENHARIA CIVIL NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '4634603',
		description: 'COMÉRCIO ATACADISTA DE PESCADOS E FRUTOS DO MAR',
	},
	{ id: '4312600', description: 'PERFURAÇÕES E SONDAGENS' },
	{ id: '4313400', description: 'OBRAS DE TERRAPLENAGEM' },
	{
		id: '4319300',
		description:
			'SERVIÇOS DE PREPARAÇÃO DO TERRENO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4330405',
		description:
			'APLICAÇÃO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES',
	},
	{ id: '4321500', description: 'INSTALAÇÃO E MANUTENÇÃO ELÉTRICA' },
	{
		id: '4322301',
		description: 'INSTALAÇÕES HIDRÁULICAS, SANITÁRIAS E DE GÁS',
	},
	{
		id: '4322302',
		description:
			'INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO',
	},
	{
		id: '4322303',
		description: 'INSTALAÇÕES DE SISTEMA DE PREVENÇÃO CONTRA INCÊNDIO',
	},
	{ id: '4329101', description: 'INSTALAÇÃO DE PAINÉIS PUBLICITÁRIOS' },
	{
		id: '4329102',
		description:
			'INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA FLUVIAL E LACUSTRE',
	},
	{
		id: '4329103',
		description:
			'INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES',
	},
	{
		id: '4520006',
		description: 'SERVIÇOS DE BORRACHARIA PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4329104',
		description:
			'MONTAGEM E INSTALAÇÃO DE SISTEMAS E EQUIPAMENTOS DE ILUMINAÇÃO E SINALIZAÇÃO EM VIAS PÚBLICAS, PORTOS E AEROPORTOS',
	},
	{
		id: '4329105',
		description: 'TRATAMENTOS TÉRMICOS, ACÚSTICOS OU DE VIBRAÇÃO',
	},
	{
		id: '4329199',
		description:
			'OUTRAS OBRAS DE INSTALAÇÕES EM CONSTRUÇÕES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '4330401',
		description: 'IMPERMEABILIZAÇÃO EM OBRAS DE ENGENHARIA CIVIL',
	},
	{
		id: '4330402',
		description:
			'INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL',
	},
	{
		id: '4330499',
		description: 'OUTRAS OBRAS DE ACABAMENTO DA CONSTRUÇÃO',
	},
	{ id: '4391600', description: 'OBRAS DE FUNDAÇÕES' },
	{ id: '4399101', description: 'ADMINISTRAÇÃO DE OBRAS' },
	{
		id: '4399102',
		description:
			'MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORÁRIAS',
	},
	{ id: '4399103', description: 'OBRAS DE ALVENARIA' },
	{
		id: '4399104',
		description:
			'SERVIÇOS DE OPERAÇÃO E FORNECIMENTO DE EQUIPAMENTOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS E PESSOAS PARA USO EM OBRAS',
	},
	{
		id: '4399105',
		description: 'PERFURAÇÃO E CONSTRUÇÃO DE POÇOS DE ÁGUA',
	},
	{
		id: '4399199',
		description:
			'SERVIÇOS ESPECIALIZADOS PARA CONSTRUÇÃO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4511101',
		description:
			'COMÉRCIO A VAREJO DE AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS NOVOS',
	},
	{
		id: '4511102',
		description:
			'COMÉRCIO A VAREJO DE AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS USADOS',
	},
	{
		id: '4511103',
		description:
			'COMÉRCIO POR ATACADO DE AUTOMÓVEIS, CAMIONETAS E UTILITÁRIOS NOVOS E USADOS',
	},
	{
		id: '4511104',
		description: 'COMÉRCIO POR ATACADO DE CAMINHÕES NOVOS E USADOS',
	},
	{
		id: '4511105',
		description:
			'COMÉRCIO POR ATACADO DE REBOQUES E SEMI REBOQUES NOVOS E USADOS',
	},
	{
		id: '5611204',
		description:
			'BARES E OUTROS ESTABELECIMENTOS ESPECIALIZADOS EM SERVIR BEBIDAS, SEM ENTRETENIMENTO',
	},
	{
		id: '4511106',
		description:
			'COMÉRCIO POR ATACADO DE ÔNIBUS E MICROÔNIBUS NOVOS E USADOS',
	},
	{
		id: '4512901',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4512902',
		description: 'COMÉRCIO SOB CONSIGNAÇÃO DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4520001',
		description:
			'SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO MECÂNICA DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4520002',
		description:
			'SERVIÇOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4520003',
		description:
			'SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO ELÉTRICA DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4520004',
		description:
			'SERVIÇOS DE ALINHAMENTO E BALANCEAMENTO DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4520005',
		description:
			'SERVIÇOS DE LAVAGEM, LUBRIFICAÇÃO E POLIMENTO DE VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4520007',
		description:
			'SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES',
	},
	{ id: '4520008', description: 'SERVIÇOS DE CAPOTARIA' },
	{
		id: '4530701',
		description:
			'COMÉRCIO POR ATACADO DE PEÇAS E ACESSÓRIOS NOVOS PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4530702',
		description: 'COMÉRCIO POR ATACADO DE PNEUMÁTICOS E CÂMARAS DE AR',
	},
	{
		id: '4530703',
		description:
			'COMÉRCIO A VAREJO DE PEÇAS E ACESSÓRIOS NOVOS PARA VEÍCULOS AUTOMOTORES',
	},
	{ id: '4623101', description: 'COMÉRCIO ATACADISTA DE ANIMAIS VIVOS' },
	{
		id: '4530704',
		description:
			'COMÉRCIO A VAREJO DE PEÇAS E ACESSÓRIOS USADOS PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4530705',
		description: 'COMÉRCIO A VAREJO DE PNEUMÁTICOS E CÂMARAS DE AR',
	},
	{
		id: '4530706',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PEÇAS E ACESSÓRIOS NOVOS E USADOS PARA VEÍCULOS AUTOMOTORES',
	},
	{
		id: '4541201',
		description: 'COMÉRCIO POR ATACADO DE MOTOCICLETAS E MOTONETAS',
	},
	{
		id: '4541202',
		description:
			'COMÉRCIO POR ATACADO DE PEÇAS E ACESSÓRIOS PARA MOTOCICLETAS E MOTONETAS',
	},
	{
		id: '4541203',
		description: 'COMÉRCIO A VAREJO DE MOTOCICLETAS E MOTONETAS NOVAS',
	},
	{
		id: '4541204',
		description: 'COMÉRCIO A VAREJO DE MOTOCICLETAS E MOTONETAS USADAS',
	},
	{
		id: '4618401',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MEDICAMENTOS, COSMÉTICOS E PRODUTOS DE PERFUMARIA',
	},
	{
		id: '4542101',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MOTOCICLETAS E MOTONETAS, PEÇAS E ACESSÓRIOS',
	},
	{
		id: '4542102',
		description: 'COMÉRCIO SOB CONSIGNAÇÃO DE MOTOCICLETAS E MOTONETAS',
	},
	{
		id: '4543900',
		description: 'MANUTENÇÃO E REPARAÇÃO DE MOTOCICLETAS E MOTONETAS',
	},
	{
		id: '4611700',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MATÉRIAS PRIMAS AGRÍCOLAS E ANIMAIS VIVOS',
	},
	{
		id: '4612500',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE COMBUSTÍVEIS, MINERAIS, PRODUTOS SIDERÚRGICOS E QUÍMICOS',
	},
	{
		id: '4613300',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MADEIRA, MATERIAL DE CONSTRUÇÃO E FERRAGENS',
	},
	{
		id: '4614100',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MÁQUINAS, EQUIPAMENTOS, EMBARCAÇÕES E AERONAVES',
	},
	{
		id: '4615000',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE ELETRODOMÉSTICOS, MÓVEIS E ARTIGOS DE USO DOMÉSTICO',
	},
	{
		id: '4616800',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE TÊXTEIS, VESTUÁRIO, CALÇADOS E ARTIGOS DE VIAGEM',
	},
	{
		id: '4617600',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PRODUTOS ALIMENTÍCIOS, BEBIDAS E FUMO',
	},
	{
		id: '4618402',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE INSTRUMENTOS E MATERIAIS ODONTO MÉDICO HOSPITALARES',
	},
	{
		id: '4618403',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE JORNAIS, REVISTAS E OUTRAS PUBLICAÇÕES',
	},
	{
		id: '4618499',
		description:
			'OUTROS REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO ESPECIALIZADO EM PRODUTOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4619200',
		description:
			'REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MERCADORIAS EM GERAL NÃO ESPECIALIZADO',
	},
	{ id: '4621400', description: 'COMÉRCIO ATACADISTA DE CAFÉ EM GRÃO' },
	{ id: '4622200', description: 'COMÉRCIO ATACADISTA DE SOJA' },
	{
		id: '4623104',
		description: 'COMÉRCIO ATACADISTA DE FUMO EM FOLHA NÃO BENEFICIADO',
	},
	{
		id: '4623108',
		description:
			'COMÉRCIO ATACADISTA DE MATÉRIAS PRIMAS AGRÍCOLAS COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADA',
	},
	{
		id: '4623109',
		description: 'COMÉRCIO ATACADISTA DE ALIMENTOS PARA ANIMAIS',
	},
	{
		id: '4623199',
		description:
			'COMÉRCIO ATACADISTA DE MATÉRIAS PRIMAS AGRÍCOLAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '4631100',
		description: 'COMÉRCIO ATACADISTA DE LEITE E LATICÍNIOS',
	},
	{
		id: '4632001',
		description:
			'COMÉRCIO ATACADISTA DE CEREAIS E LEGUMINOSAS BENEFICIADOS',
	},
	{
		id: '4632002',
		description: 'COMÉRCIO ATACADISTA DE FARINHAS, AMIDOS E FÉCULAS',
	},
	{
		id: '4632003',
		description:
			'COMÉRCIO ATACADISTA DE CEREAIS E LEGUMINOSAS BENEFICIADOS, FARINHAS, AMIDOS E FÉCULAS, COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADA',
	},
	{
		id: '4633801',
		description:
			'COMÉRCIO ATACADISTA DE FRUTAS, VERDURAS, RAÍZES, TUBÉRCULOS, HORTALIÇAS E LEGUMES FRESCOS',
	},
	{
		id: '4633802',
		description: 'COMÉRCIO ATACADISTA DE AVES VIVAS E OVOS',
	},
	{
		id: '4634699',
		description:
			'COMÉRCIO ATACADISTA DE CARNES E DERIVADOS DE OUTROS ANIMAIS',
	},
	{ id: '4635401', description: 'COMÉRCIO ATACADISTA DE ÁGUA MINERAL' },
	{
		id: '4635402',
		description: 'COMÉRCIO ATACADISTA DE CERVEJA, CHOPE E REFRIGERANTE',
	},
	{
		id: '4635403',
		description:
			'COMÉRCIO ATACADISTA DE BEBIDAS COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADA',
	},
	{
		id: '4635499',
		description:
			'COMÉRCIO ATACADISTA DE BEBIDAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '4636201', description: 'COMÉRCIO ATACADISTA DE FUMO BENEFICIADO' },
	{
		id: '4636202',
		description: 'COMÉRCIO ATACADISTA DE CIGARROS, CIGARRILHAS E CHARUTOS',
	},
	{
		id: '4637101',
		description: 'COMÉRCIO ATACADISTA DE CAFÉ TORRADO, MOÍDO E SOLÚVEL',
	},
	{ id: '4637102', description: 'COMÉRCIO ATACADISTA DE AÇÚCAR' },
	{ id: '4637103', description: 'COMÉRCIO ATACADISTA DE ÓLEOS E GORDURAS' },
	{
		id: '4637104',
		description:
			'COMÉRCIO ATACADISTA DE PÃES, BOLOS, BISCOITOS E SIMILARES',
	},
	{
		id: '4637105',
		description: 'COMÉRCIO ATACADISTA DE MASSAS ALIMENTÍCIAS',
	},
	{ id: '4637106', description: 'COMÉRCIO ATACADISTA DE SORVETES' },
	{
		id: '4637107',
		description:
			'COMÉRCIO ATACADISTA DE CHOCOLATES, CONFEITOS, BALAS, BOMBONS E SEMELHANTES',
	},
	{
		id: '4637199',
		description:
			'COMÉRCIO ATACADISTA ESPECIALIZADO EM OUTROS PRODUTOS ALIMENTÍCIOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4639701',
		description: 'COMÉRCIO ATACADISTA DE PRODUTOS ALIMENTÍCIOS EM GERAL',
	},
	{
		id: '4639702',
		description:
			'COMÉRCIO ATACADISTA DE PRODUTOS ALIMENTÍCIOS EM GERAL, COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADA',
	},
	{
		id: '4641902',
		description: 'COMÉRCIO ATACADISTA DE ARTIGOS DE CAMA, MESA E BANHO',
	},
	{
		id: '4641903',
		description: 'COMÉRCIO ATACADISTA DE ARTIGOS DE ARMARINHO',
	},
	{
		id: '4642701',
		description:
			'COMÉRCIO ATACADISTA DE ARTIGOS DO VESTUÁRIO E ACESSÓRIOS, EXCETO PROFISSIONAIS E DE SEGURANÇA',
	},
	{
		id: '4642702',
		description:
			'COMÉRCIO ATACADISTA DE ROUPAS E ACESSÓRIOS PARA USO PROFISSIONAL E DE SEGURANÇA DO TRABALHO',
	},
	{
		id: '4644301',
		description:
			'COMÉRCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO HUMANO',
	},
	{
		id: '4644302',
		description:
			'COMÉRCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO VETERINÁRIO',
	},
	{
		id: '4645101',
		description:
			'COMÉRCIO ATACADISTA DE INSTRUMENTOS E MATERIAIS PARA USO MÉDICO, CIRÚRGICO, HOSPITALAR E DE LABORATÓRIOS',
	},
	{
		id: '4645102',
		description: 'COMÉRCIO ATACADISTA DE PRÓTESES E ARTIGOS DE ORTOPEDIA',
	},
	{
		id: '4645103',
		description: 'COMÉRCIO ATACADISTA DE PRODUTOS ODONTOLÓGICOS',
	},
	{
		id: '4646001',
		description:
			'COMÉRCIO ATACADISTA DE COSMÉTICOS E PRODUTOS DE PERFUMARIA',
	},
	{
		id: '4646002',
		description: 'COMÉRCIO ATACADISTA DE PRODUTOS DE HIGIENE PESSOAL',
	},
	{
		id: '4647801',
		description:
			'COMÉRCIO ATACADISTA DE ARTIGOS DE ESCRITÓRIO E DE PAPELARIA',
	},
	{
		id: '4647802',
		description:
			'COMÉRCIO ATACADISTA DE LIVROS, JORNAIS E OUTRAS PUBLICAÇÕES',
	},
	{
		id: '4679601',
		description: 'COMÉRCIO ATACADISTA DE TINTAS, VERNIZES E SIMILARES',
	},
	{
		id: '4649401',
		description:
			'COMÉRCIO ATACADISTA DE EQUIPAMENTOS ELÉTRICOS DE USO PESSOAL E DOMÉSTICO',
	},
	{
		id: '4649402',
		description:
			'COMÉRCIO ATACADISTA DE APARELHOS ELETRÔNICOS DE USO PESSOAL E DOMÉSTICO',
	},
	{
		id: '4649403',
		description:
			'COMÉRCIO ATACADISTA DE BICICLETAS, TRICICLOS E OUTROS VEÍCULOS RECREATIVOS',
	},
	{
		id: '4649404',
		description: 'COMÉRCIO ATACADISTA DE MÓVEIS E ARTIGOS DE COLCHOARIA',
	},
	{
		id: '4649405',
		description:
			'COMÉRCIO ATACADISTA DE ARTIGOS DE TAPEÇARIA; PERSIANAS E CORTINAS',
	},
	{
		id: '4649406',
		description: 'COMÉRCIO ATACADISTA DE LUSTRES, LUMINÁRIAS E ABAJURES',
	},
	{
		id: '4649407',
		description: 'COMÉRCIO ATACADISTA DE FILMES, CDS, DVDS, FITAS E DISCOS',
	},
	{
		id: '4649408',
		description:
			'COMÉRCIO ATACADISTA DE PRODUTOS DE HIGIENE, LIMPEZA E CONSERVAÇÃO DOMICILIAR',
	},
	{
		id: '4649409',
		description:
			'COMÉRCIO ATACADISTA DE PRODUTOS DE HIGIENE, LIMPEZA E CONSERVAÇÃO DOMICILIAR, COM ATIVIDADE DE FRACIONAMENTO E ACONDICIONAMENTO ASSOCIADA',
	},
	{
		id: '4649410',
		description:
			'COMÉRCIO ATACADISTA DE JÓIAS, RELÓGIOS E BIJUTERIAS, INCLUSIVE PEDRAS PRECIOSAS E SEMIPRECIOSAS LAPIDADAS',
	},
	{
		id: '4649499',
		description:
			'COMÉRCIO ATACADISTA DE OUTROS EQUIPAMENTOS E ARTIGOS DE USO PESSOAL E DOMÉSTICO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4651601',
		description: 'COMÉRCIO ATACADISTA DE EQUIPAMENTOS DE INFORMÁTICA',
	},
	{
		id: '4651602',
		description: 'COMÉRCIO ATACADISTA DE SUPRIMENTOS PARA INFORMÁTICA',
	},
	{
		id: '4652400',
		description:
			'COMÉRCIO ATACADISTA DE COMPONENTES ELETRÔNICOS E EQUIPAMENTOS DE TELEFONIA E COMUNICAÇÃO',
	},
	{
		id: '4661300',
		description:
			'COMÉRCIO ATACADISTA DE MÁQUINAS, APARELHOS E EQUIPAMENTOS PARA USO AGROPECUÁRIO; PARTES E PEÇAS',
	},
	{
		id: '4679602',
		description: 'COMÉRCIO ATACADISTA DE MÁRMORES E GRANITOS',
	},
	{
		id: '4662100',
		description:
			'COMÉRCIO ATACADISTA DE MÁQUINAS, EQUIPAMENTOS PARA TERRAPLENAGEM, MINERAÇÃO E CONSTRUÇÃO; PARTES E PEÇAS',
	},
	{
		id: '4663000',
		description:
			'COMÉRCIO ATACADISTA DE MÁQUINAS E EQUIPAMENTOS PARA USO INDUSTRIAL; PARTES E PEÇAS',
	},
	{
		id: '4664800',
		description:
			'COMÉRCIO ATACADISTA DE MÁQUINAS, APARELHOS E EQUIPAMENTOS PARA USO ODONTO MÉDICO HOSPITALAR; PARTES E PEÇAS',
	},
	{
		id: '4665600',
		description:
			'COMÉRCIO ATACADISTA DE MÁQUINAS E EQUIPAMENTOS PARA USO COMERCIAL; PARTES E PEÇAS',
	},
	{
		id: '4669901',
		description:
			'COMÉRCIO ATACADISTA DE BOMBAS E COMPRESSORES; PARTES E PEÇAS',
	},
	{
		id: '4741500',
		description: 'COMÉRCIO VAREJISTA DE TINTAS E MATERIAIS PARA PINTURA',
	},
	{
		id: '4669999',
		description:
			'COMÉRCIO ATACADISTA DE OUTRAS MÁQUINAS E EQUIPAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE; PARTES E PEÇAS',
	},
	{
		id: '4671100',
		description: 'COMÉRCIO ATACADISTA DE MADEIRA E PRODUTOS DERIVADOS',
	},
	{
		id: '4672900',
		description: 'COMÉRCIO ATACADISTA DE FERRAGENS E FERRAMENTAS',
	},
	{
		id: '4673700',
		description: 'COMÉRCIO ATACADISTA DE MATERIAL ELÉTRICO',
	},
	{ id: '4674500', description: 'COMÉRCIO ATACADISTA DE CIMENTO' },
	{
		id: '4679603',
		description: 'COMÉRCIO ATACADISTA DE VIDROS, ESPELHOS E VITRAIS',
	},
	{
		id: '4679604',
		description:
			'COMÉRCIO ATACADISTA ESPECIALIZADO DE MATERIAIS DE CONSTRUÇÃO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4681801',
		description:
			'COMÉRCIO ATACADISTA DE ÁLCOOL CARBURANTE, BIODIESEL, GASOLINA E DEMAIS DERIVADOS DE PETRÓLEO, EXCETO LUBRIFICANTES, NÃO REALIZADO POR TRANSPORTADOR RETALHISTA (T.R.R.)',
	},
	{
		id: '4681802',
		description:
			'COMÉRCIO ATACADISTA DE COMBUSTÍVEIS REALIZADO POR TRANSPORTADOR RETALHISTA (T.R.R.)',
	},
	{
		id: '4681803',
		description:
			'COMÉRCIO ATACADISTA DE COMBUSTÍVEIS DE ORIGEM VEGETAL, EXCETO ÁLCOOL CARBURANTE',
	},
	{
		id: '4681804',
		description:
			'COMÉRCIO ATACADISTA DE COMBUSTÍVEIS DE ORIGEM MINERAL EM BRUTO',
	},
	{ id: '4681805', description: 'COMÉRCIO ATACADISTA DE LUBRIFICANTES' },
	{
		id: '4682600',
		description: 'COMÉRCIO ATACADISTA DE GÁS LIQÜEFEITO DE PETRÓLEO (GLP)',
	},
	{
		id: '4683400',
		description:
			'COMÉRCIO ATACADISTA DE DEFENSIVOS AGRÍCOLAS, ADUBOS, FERTILIZANTES E CORRETIVOS DO SOLO',
	},
	{
		id: '4684201',
		description: 'COMÉRCIO ATACADISTA DE RESINAS E ELASTÔMEROS',
	},
	{ id: '4684202', description: 'COMÉRCIO ATACADISTA DE SOLVENTES' },
	{
		id: '4684299',
		description:
			'COMÉRCIO ATACADISTA DE OUTROS PRODUTOS QUÍMICOS E PETROQUÍMICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4685100',
		description:
			'COMÉRCIO ATACADISTA DE PRODUTOS SIDERÚRGICOS E METALÚRGICOS, EXCETO PARA CONSTRUÇÃO',
	},
	{
		id: '4686901',
		description: 'COMÉRCIO ATACADISTA DE PAPEL E PAPELÃO EM BRUTO',
	},
	{ id: '4686902', description: 'COMÉRCIO ATACADISTA DE EMBALAGENS' },
	{
		id: '4687701',
		description: 'COMÉRCIO ATACADISTA DE RESÍDUOS DE PAPEL E PAPELÃO',
	},
	{
		id: '4721103',
		description: 'COMÉRCIO VAREJISTA DE LATICÍNIOS E FRIOS',
	},
	{
		id: '4687702',
		description:
			'COMÉRCIO ATACADISTA DE RESÍDUOS E SUCATAS NÃO METÁLICOS, EXCETO DE PAPEL E PAPELÃO',
	},
	{
		id: '4687703',
		description: 'COMÉRCIO ATACADISTA DE RESÍDUOS E SUCATAS METÁLICOS',
	},
	{
		id: '4689301',
		description:
			'COMÉRCIO ATACADISTA DE PRODUTOS DA EXTRAÇÃO MINERAL, EXCETO COMBUSTÍVEIS',
	},
	{
		id: '4689302',
		description: 'COMÉRCIO ATACADISTA DE FIOS E FIBRAS BENEFICIADOS',
	},
	{
		id: '4689399',
		description:
			'COMÉRCIO ATACADISTA ESPECIALIZADO EM OUTROS PRODUTOS INTERMEDIÁRIOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4691500',
		description:
			'COMÉRCIO ATACADISTA DE MERCADORIAS EM GERAL, COM PREDOMINÂNCIA DE PRODUTOS ALIMENTÍCIOS',
	},
	{
		id: '4692300',
		description:
			'COMÉRCIO ATACADISTA DE MERCADORIAS EM GERAL, COM PREDOMINÂNCIA DE INSUMOS AGROPECUÁRIOS',
	},
	{
		id: '4693100',
		description:
			'COMÉRCIO ATACADISTA DE MERCADORIAS EM GERAL, SEM PREDOMINÂNCIA DE ALIMENTOS OU DE INSUMOS AGROPECUÁRIOS',
	},
	{ id: '4774100', description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE ÓPTICA' },
	{
		id: '4711301',
		description:
			'COMÉRCIO VAREJISTA DE MERCADORIAS EM GERAL, COM PREDOMINÂNCIA DE PRODUTOS ALIMENTÍCIOS   HIPERMERCADOS',
	},
	{
		id: '4711302',
		description:
			'COMÉRCIO VAREJISTA DE MERCADORIAS EM GERAL, COM PREDOMINÂNCIA DE PRODUTOS ALIMENTÍCIOS - SUPERMERCADOS',
	},
	{
		id: '4712100',
		description:
			'COMÉRCIO VAREJISTA DE MERCADORIAS EM GERAL, COM PREDOMINÂNCIA DE PRODUTOS ALIMENTÍCIOS - MINIMERCADOS, MERCEARIAS E ARMAZÉNS',
	},
	{
		id: '4713002',
		description:
			'LOJAS DE VARIEDADES, EXCETO LOJAS DE DEPARTAMENTOS OU MAGAZINES',
	},
	{
		id: '4721102',
		description: 'PADARIA E CONFEITARIA COM PREDOMINÂNCIA DE REVENDA',
	},
	{
		id: '4721104',
		description:
			'COMÉRCIO VAREJISTA DE DOCES, BALAS, BOMBONS E SEMELHANTES',
	},
	{ id: '4722901', description: 'COMÉRCIO VAREJISTA DE CARNES - AÇOUGUES' },
	{ id: '4722902', description: 'PEIXARIA' },
	{ id: '4723700', description: 'COMÉRCIO VAREJISTA DE BEBIDAS' },
	{
		id: '4729602',
		description:
			'COMÉRCIO VAREJISTA DE MERCADORIAS EM LOJAS DE CONVENIÊNCIA',
	},
	{
		id: '4729699',
		description:
			'COMÉRCIO VAREJISTA DE PRODUTOS ALIMENTÍCIOS EM GERAL OU ESPECIALIZADO EM PRODUTOS ALIMENTÍCIOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4731800',
		description:
			'COMÉRCIO VAREJISTA DE COMBUSTÍVEIS PARA VEÍCULOS AUTOMOTORES',
	},
	{ id: '4742300', description: 'COMÉRCIO VAREJISTA DE MATERIAL ELÉTRICO' },
	{ id: '4743100', description: 'COMÉRCIO VAREJISTA DE VIDROS' },
	{
		id: '4744001',
		description: 'COMÉRCIO VAREJISTA DE FERRAGENS E FERRAMENTAS',
	},
	{
		id: '4744002',
		description: 'COMÉRCIO VAREJISTA DE MADEIRA E ARTEFATOS',
	},
	{
		id: '4744003',
		description: 'COMÉRCIO VAREJISTA DE MATERIAIS HIDRÁULICOS',
	},
	{
		id: '4744004',
		description:
			'COMÉRCIO VAREJISTA DE CAL, AREIA, PEDRA BRITADA, TIJOLOS E TELHAS',
	},
	{
		id: '4744005',
		description:
			'COMÉRCIO VAREJISTA DE MATERIAIS DE CONSTRUÇÃO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4744006',
		description: 'COMÉRCIO VAREJISTA DE PEDRAS PARA REVESTIMENTO',
	},
	{
		id: '4744099',
		description: 'COMÉRCIO VAREJISTA DE MATERIAIS DE CONSTRUÇÃO EM GERAL',
	},
	{
		id: '4751201',
		description:
			'COMÉRCIO VAREJISTA ESPECIALIZADO DE EQUIPAMENTOS E SUPRIMENTOS DE INFORMÁTICA',
	},
	{
		id: '4751202',
		description: 'RECARGA DE CARTUCHOS PARA EQUIPAMENTOS DE INFORMÁTICA',
	},
	{
		id: '4752100',
		description:
			'COMÉRCIO VAREJISTA ESPECIALIZADO DE EQUIPAMENTOS DE TELEFONIA E COMUNICAÇÃO',
	},
	{
		id: '4753900',
		description:
			'COMÉRCIO VAREJISTA ESPECIALIZADO DE ELETRODOMÉSTICOS E EQUIPAMENTOS DE ÁUDIO E VÍDEO',
	},
	{ id: '4754701', description: 'COMÉRCIO VAREJISTA DE MÓVEIS' },
	{
		id: '4754702',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE COLCHOARIA',
	},
	{
		id: '4754703',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE ILUMINAÇÃO',
	},
	{ id: '4755501', description: 'COMÉRCIO VAREJISTA DE TECIDOS' },
	{
		id: '4755502',
		description: 'COMERCIO VAREJISTA DE ARTIGOS DE ARMARINHO',
	},
	{
		id: '4755503',
		description: 'COMERCIO VAREJISTA DE ARTIGOS DE CAMA, MESA E BANHO',
	},
	{
		id: '4756300',
		description:
			'COMÉRCIO VAREJISTA ESPECIALIZADO DE INSTRUMENTOS MUSICAIS E ACESSÓRIOS',
	},
	{
		id: '5320201',
		description: 'SERVIÇOS DE MALOTE NÃO REALIZADOS PELO CORREIO NACIONAL',
	},
	{
		id: '4757100',
		description:
			'COMÉRCIO VAREJISTA ESPECIALIZADO DE PEÇAS E ACESSÓRIOS PARA APARELHOS ELETROELETRÔNICOS PARA USO DOMÉSTICO, EXCETO INFORMÁTICA E COMUNICAÇÃO',
	},
	{
		id: '4759801',
		description:
			'COMÉRCIO VAREJISTA DE ARTIGOS DE TAPEÇARIA, CORTINAS E PERSIANAS',
	},
	{
		id: '4759899',
		description:
			'COMÉRCIO VAREJISTA DE OUTROS ARTIGOS DE USO PESSOAL E DOMÉSTICO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '4761001', description: 'COMÉRCIO VAREJISTA DE LIVROS' },
	{
		id: '4761002',
		description: 'COMÉRCIO VAREJISTA DE JORNAIS E REVISTAS',
	},
	{
		id: '4761003',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE PAPELARIA',
	},
	{
		id: '4762800',
		description: 'COMÉRCIO VAREJISTA DE DISCOS, CDS, DVDS E FITAS',
	},
	{
		id: '4763601',
		description: 'COMÉRCIO VAREJISTA DE BRINQUEDOS E ARTIGOS RECREATIVOS',
	},
	{
		id: '4763602',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS ESPORTIVOS',
	},
	{
		id: '4763605',
		description:
			'COMÉRCIO VAREJISTA DE EMBARCAÇÕES E OUTROS VEÍCULOS RECREATIVOS; PEÇAS E ACESSÓRIOS',
	},
	{
		id: '4771701',
		description:
			'COMÉRCIO VAREJISTA DE PRODUTOS FARMACÊUTICOS, SEM MANIPULAÇÃO DE FÓRMULAS',
	},
	{
		id: '4771702',
		description:
			'COMÉRCIO VAREJISTA DE PRODUTOS FARMACÊUTICOS, COM MANIPULAÇÃO DE FÓRMULAS',
	},
	{
		id: '4771703',
		description:
			'COMÉRCIO VAREJISTA DE PRODUTOS FARMACÊUTICOS HOMEOPÁTICOS',
	},
	{
		id: '4771704',
		description: 'COMÉRCIO VAREJISTA DE MEDICAMENTOS VETERINÁRIOS',
	},
	{
		id: '4772500',
		description:
			'COMÉRCIO VAREJISTA DE COSMÉTICOS, PRODUTOS DE PERFUMARIA E DE HIGIENE PESSOAL',
	},
	{
		id: '4773300',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS MÉDICOS E ORTOPÉDICOS',
	},
	{
		id: '4781400',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DO VESTUÁRIO E ACESSÓRIOS',
	},
	{ id: '4782201', description: 'COMÉRCIO VAREJISTA DE CALÇADOS' },
	{ id: '4782202', description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE VIAGEM' },
	{
		id: '4783101',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE JOALHERIA',
	},
	{
		id: '4783102',
		description: 'COMÉRCIO VAREJISTA DE ARTIGOS DE RELOJOARIA',
	},
	{
		id: '4784900',
		description: 'COMÉRCIO VAREJISTA DE GÁS LIQÜEFEITO DE PETRÓLEO (GLP)',
	},
	{ id: '4785701', description: 'COMÉRCIO VAREJISTA DE ANTIGÜIDADES' },
	{
		id: '4785799',
		description: 'COMÉRCIO VAREJISTA DE OUTROS ARTIGOS USADOS',
	},
	{
		id: '4789001',
		description:
			'COMÉRCIO VAREJISTA DE SUVENIRES, BIJUTERIAS E ARTESANATOS',
	},
	{
		id: '4789002',
		description: 'COMÉRCIO VAREJISTA DE PLANTAS E FLORES NATURAIS',
	},
	{ id: '4789003', description: 'COMÉRCIO VAREJISTA DE OBJETOS DE ARTE' },
	{
		id: '4789004',
		description:
			'COMÉRCIO VAREJISTA DE ANIMAIS VIVOS E DE ARTIGOS E ALIMENTOS PARA ANIMAIS DE ESTIMAÇÃO',
	},
	{
		id: '4789005',
		description: 'COMÉRCIO VAREJISTA DE PRODUTOS SANEANTES DOMISSANITÁRIOS',
	},
	{
		id: '4789006',
		description:
			'COMÉRCIO VAREJISTA DE FOGOS DE ARTIFÍCIO E ARTIGOS PIROTÉCNICOS',
	},
	{
		id: '4789007',
		description: 'COMÉRCIO VAREJISTA DE EQUIPAMENTOS PARA ESCRITÓRIO',
	},
	{
		id: '4789008',
		description:
			'COMÉRCIO VAREJISTA DE ARTIGOS FOTOGRÁFICOS E PARA FILMAGEM',
	},
	{ id: '4789009', description: 'COMÉRCIO VAREJISTA DE ARMAS E MUNIÇÕES' },
	{
		id: '4789099',
		description:
			'COMÉRCIO VAREJISTA DE OUTROS PRODUTOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '4911600', description: 'TRANSPORTE FERROVIÁRIO DE CARGA' },
	{
		id: '4912401',
		description:
			'TRANSPORTE FERROVIÁRIO DE PASSAGEIROS INTERMUNICIPAL E INTERESTADUAL',
	},
	{
		id: '4912402',
		description:
			'TRANSPORTE FERROVIÁRIO DE PASSAGEIROS MUNICIPAL E EM REGIÃO METROPOLITANA',
	},
	{ id: '4912403', description: 'TRANSPORTE METROVIÁRIO' },
	{
		id: '4921301',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, MUNICIPAL',
	},
	{
		id: '4921302',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, INTERMUNICIPAL EM REGIÃO METROPOLITANA',
	},
	{
		id: '4922101',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, INTERMUNICIPAL, EXCETO EM REGIÃO METROPOLITANA',
	},
	{
		id: '4922102',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, INTERESTADUAL',
	},
	{
		id: '4922103',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, INTERNACIONAL',
	},
	{ id: '4923001', description: 'SERVIÇO DE TÁXI' },
	{
		id: '4923002',
		description:
			'SERVIÇO DE TRANSPORTE DE PASSAGEIROS - LOCAÇÃO DE AUTOMÓVEIS COM MOTORISTA',
	},
	{
		id: '4929901',
		description:
			'TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, MUNICIPAL',
	},
	{
		id: '4929903',
		description:
			'ORGANIZAÇÃO DE EXCURSÕES EM VEÍCULOS RODOVIÁRIOS PRÓPRIOS, MUNICIPAL',
	},
	{
		id: '4929904',
		description:
			'ORGANIZAÇÃO DE EXCURSÕES EM VEÍCULOS RODOVIÁRIOS PRÓPRIOS, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL',
	},
	{
		id: '4929999',
		description:
			'OUTROS TRANSPORTES RODOVIÁRIOS DE PASSAGEIROS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '4930201',
		description:
			'TRANSPORTE RODOVIÁRIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANÇAS, MUNICIPAL',
	},
	{
		id: '4930202',
		description:
			'TRANSPORTE RODOVIÁRIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANÇAS, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL',
	},
	{
		id: '4930203',
		description: 'TRANSPORTE RODOVIÁRIO DE PRODUTOS PERIGOSOS',
	},
	{ id: '4930204', description: 'TRANSPORTE RODOVIÁRIO DE MUDANÇAS' },
	{ id: '4940000', description: 'TRANSPORTE DUTOVIÁRIO' },
	{
		id: '4950700',
		description: 'TRENS TURÍSTICOS, TELEFÉRICOS E SIMILARES',
	},
	{
		id: '5011401',
		description: 'TRANSPORTE MARÍTIMO DE CABOTAGEM - CARGA',
	},
	{
		id: '5011402',
		description: 'TRANSPORTE MARÍTIMO DE CABOTAGEM - PASSAGEIROS',
	},
	{
		id: '5012201',
		description: 'TRANSPORTE MARÍTIMO DE LONGO CURSO - CARGA',
	},
	{
		id: '5012202',
		description: 'TRANSPORTE MARÍTIMO DE LONGO CURSO - PASSAGEIROS',
	},
	{
		id: '6612602',
		description: 'DISTRIBUIDORAS DE TÍTULOS E VALORES MOBILIÁRIOS',
	},
	{
		id: '5021101',
		description:
			'TRANSPORTE POR NAVEGAÇÃO INTERIOR DE CARGA, MUNICIPAL, EXCETO TRAVESSIA',
	},
	{
		id: '5021102',
		description:
			'TRANSPORTE POR NAVEGAÇÃO INTERIOR DE CARGA, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL, EXCETO TRAVESSIA',
	},
	{
		id: '5022001',
		description:
			'TRANSPORTE POR NAVEGAÇÃO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, MUNICIPAL, EXCETO TRAVESSIA',
	},
	{
		id: '5022002',
		description:
			'TRANSPORTE POR NAVEGAÇÃO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL, EXCETO TRAVESSIA',
	},
	{ id: '5030101', description: 'NAVEGAÇÃO DE APOIO MARÍTIMO' },
	{ id: '5030102', description: 'NAVEGAÇÃO DE APOIO PORTUÁRIO' },
	{ id: '5030103', description: 'SERVIÇO DE REBOCADORES E EMPURRADORES' },
	{
		id: '5091201',
		description: 'TRANSPORTE POR NAVEGAÇÃO DE TRAVESSIA, MUNICIPAL',
	},
	{
		id: '5091202',
		description:
			'TRANSPORTE POR NAVEGAÇÃO DE TRAVESSIA INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL',
	},
	{
		id: '5099801',
		description: 'TRANSPORTE AQUAVIÁRIO PARA PASSEIOS TURÍSTICOS',
	},
	{
		id: '5099899',
		description:
			'OUTROS TRANSPORTES AQUAVIÁRIOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '5111100', description: 'TRANSPORTE AÉREO DE PASSAGEIROS REGULAR' },
	{
		id: '5112901',
		description:
			'SERVIÇO DE TÁXI AÉREO E LOCAÇÃO DE AERONAVES COM TRIPULAÇÃO',
	},
	{
		id: '5112999',
		description:
			'OUTROS SERVIÇOS DE TRANSPORTE AÉREO DE PASSAGEIROS NÃO REGULAR',
	},
	{ id: '5120000', description: 'TRANSPORTE AÉREO DE CARGA' },
	{ id: '5130700', description: 'TRANSPORTE ESPACIAL' },
	{ id: '5211701', description: 'ARMAZÉNS GERAIS - EMISSÃO DE WARRANT' },
	{ id: '5211702', description: 'GUARDA MÓVEIS' },
	{ id: '6612603', description: 'CORRETORAS DE CÂMBIO' },
	{
		id: '5211799',
		description:
			'DEPÓSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZÉNS GERAIS E GUARDA MÓVEIS',
	},
	{ id: '5212500', description: 'CARGA E DESCARGA' },
	{
		id: '5221400',
		description:
			'CONCESSIONÁRIAS DE RODOVIAS, PONTES, TÚNEIS E SERVIÇOS RELACIONADOS',
	},
	{ id: '5222200', description: 'TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS' },
	{ id: '5223100', description: 'ESTACIONAMENTO DE VEÍCULOS' },
	{
		id: '5229001',
		description:
			'SERVIÇOS DE APOIO AO TRANSPORTE POR TÁXI, INCLUSIVE CENTRAIS DE CHAMADA',
	},
	{ id: '5229002', description: 'SERVIÇOS DE REBOQUE DE VEÍCULOS' },
	{ id: '8650006', description: 'ATIVIDADES DE FONOAUDIOLOGIA' },
	{
		id: '5229099',
		description:
			'OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '5231101',
		description: 'ADMINISTRAÇÃO DA INFRAESTRUTURA PORTUÁRIA',
	},
	{ id: '5231102', description: 'ATIVIDADES DO OPERADOR PORTUÁRIO' },
	{ id: '5231103', description: 'GESTÃO DE TERMINAIS AQUAVIÁRIOS' },
	{ id: '5232000', description: 'ATIVIDADES DE AGENCIAMENTO MARÍTIMO' },
	{ id: '5239701', description: 'SERVIÇOS DE PRATICAGEM' },
	{
		id: '5239799',
		description:
			'ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '5240101',
		description: 'OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM',
	},
	{
		id: '5620101',
		description:
			'FORNECIMENTO DE ALIMENTOS PREPARADOS PREPONDERANTEMENTE PARA EMPRESAS',
	},
	{
		id: '5620103',
		description: 'CANTINAS - SERVIÇOS DE ALIMENTAÇÃO PRIVATIVOS',
	},
	{
		id: '5620104',
		description:
			'FORNECIMENTO DE ALIMENTOS PREPARADOS PREPONDERANTEMENTE PARA CONSUMO DOMICILIAR',
	},
	{
		id: '5240199',
		description:
			'ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM',
	},
	{ id: '5250801', description: 'COMISSARIA DE DESPACHOS' },
	{ id: '5250802', description: 'ATIVIDADES DE DESPACHANTES ADUANEIROS' },
	{
		id: '5250803',
		description:
			'AGENCIAMENTO DE CARGAS, EXCETO PARA O TRANSPORTE MARÍTIMO',
	},
	{
		id: '5250804',
		description: 'ORGANIZAÇÃO LOGÍSTICA DO TRANSPORTE DE CARGA',
	},
	{ id: '5250805', description: 'OPERADOR DE TRANSPORTE MULTIMODAL - OTM' },
	{ id: '5310501', description: 'ATIVIDADES DO CORREIO NACIONAL' },
	{
		id: '5310502',
		description: 'ATIVIDADES DE FRANQUEADAS DO CORREIO NACIONAL',
	},
	{ id: '5320202', description: 'SERVIÇOS DE ENTREGA RÁPIDA' },
	{ id: '5510801', description: 'HOTÉIS' },
	{ id: '5510802', description: 'APART HOTÉIS' },
	{ id: '5510803', description: 'MOTÉIS' },
	{ id: '5590601', description: 'ALBERGUES, EXCETO ASSISTENCIAIS' },
	{ id: '5590602', description: 'CAMPINGS' },
	{ id: '5590603', description: 'PENSÕES(ALOJAMENTO)' },
	{
		id: '5590699',
		description: 'OUTROS ALOJAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '5611201', description: 'RESTAURANTES E SIMILARES' },
	{
		id: '5611203',
		description: 'LANCHONETES, CASAS DE CHÁ, DE SUCOS E SIMILARES',
	},
	{ id: '5612100', description: 'SERVIÇOS AMBULANTES DE ALIMENTAÇÃO' },
	{ id: '5811500', description: 'EDIÇÃO DE LIVROS' },
	{ id: '5812301', description: 'EDIÇÃO DE JORNAIS DIÁRIOS' },
	{ id: '5812302', description: 'EDIÇÃO DE JORNAIS NÃO DIÁRIOS' },
	{ id: '5813100', description: 'EDIÇÃO DE REVISTAS' },
	{ id: '6201502', description: 'WEB DESIGN' },
	{
		id: '5819100',
		description:
			'EDIÇÃO DE CADASTROS, LISTAS E DE OUTROS PRODUTOS GRÁFICOS',
	},
	{ id: '5821200', description: 'EDIÇÃO INTEGRADA À IMPRESSÃO DE LIVROS' },
	{
		id: '5822101',
		description: 'EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS DIÁRIOS',
	},
	{
		id: '5822102',
		description: 'EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS NÃO DIÁRIOS',
	},
	{
		id: '5823900',
		description: 'EDIÇÃO INTEGRADA À IMPRESSÃO DE REVISTAS',
	},
	{
		id: '5829800',
		description:
			'EDIÇÃO INTEGRADA À IMPRESSÃO DE CADASTROS, LISTAS E DE OUTROS PRODUTOS GRÁFICOS',
	},
	{
		id: '6110803',
		description: 'SERVIÇOS DE COMUNICAÇÃO MULTIMÍDIA - SCM',
	},
	{ id: '6120501', description: 'TELEFONIA MÓVEL CELULAR' },
	{ id: '5911101', description: 'ESTÚDIOS CINEMATOGRÁFICOS' },
	{ id: '5911102', description: 'PRODUÇÃO DE FILMES PARA PUBLICIDADE' },
	{
		id: '5911199',
		description:
			'ATIVIDADES DE PRODUÇÃO CINEMATOGRÁFICA, DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '5912001', description: 'SERVIÇOS DE DUBLAGEM' },
	{
		id: '5912002',
		description: 'SERVIÇOS DE MIXAGEM SONORA EM PRODUÇÃO AUDIOVISUAL',
	},
	{
		id: '7220700',
		description:
			'PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS SOCIAIS E HUMANAS',
	},
	{
		id: '5912099',
		description:
			'ATIVIDADES DE PÓS PRODUÇÃO CINEMATOGRÁFICA, DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '5913800',
		description:
			'DISTRIBUIÇÃO CINEMATOGRÁFICA, DE VÍDEO E DE PROGRAMAS DE TELEVISÃO',
	},
	{ id: '5914600', description: 'ATIVIDADES DE EXIBIÇÃO CINEMATOGRÁFICA' },
	{
		id: '6110899',
		description:
			'SERVIÇOS DE TELECOMUNICAÇÕES POR FIO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '6550200', description: 'PLANOS DE SAÚDE' },
	{
		id: '5920100',
		description: 'ATIVIDADES DE GRAVAÇÃO DE SOM E DE EDIÇÃO DE MÚSICA',
	},
	{ id: '6010100', description: 'ATIVIDADES DE RÁDIO' },
	{ id: '6021700', description: 'ATIVIDADES DE TELEVISÃO ABERTA' },
	{ id: '6022501', description: 'PROGRAMADORAS' },
	{
		id: '6022502',
		description:
			'ATIVIDADES RELACIONADAS À TELEVISÃO POR ASSINATURA, EXCETO PROGRAMADORAS',
	},
	{
		id: '6462000',
		description: 'HOLDINGS DE INSTITUIÇÕES NÃO FINANCEIRAS',
	},
	{
		id: '6110801',
		description: 'SERVIÇOS DE TELEFONIA FIXA COMUTADA - STFC',
	},
	{
		id: '6110802',
		description:
			'SERVIÇOS DE REDES DE TRANSPORTES DE TELECOMUNICAÇÕES - SRTT',
	},
	{ id: '6612604', description: 'CORRETORAS DE CONTRATOS DE MERCADORIAS' },
	{ id: '6120502', description: 'SERVIÇO MÓVEL ESPECIALIZADO - SME' },
	{
		id: '6120599',
		description:
			'SERVIÇOS DE TELECOMUNICAÇÕES SEM FIO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '6201501',
		description: 'DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA',
	},
	{ id: '6130200', description: 'TELECOMUNICAÇÕES POR SATÉLITE' },
	{
		id: '6141800',
		description: 'OPERADORAS DE TELEVISÃO POR ASSINATURA POR CABO',
	},
	{ id: '6470102', description: 'FUNDOS DE INVESTIMENTO PREVIDENCIÁRIOS' },
	{
		id: '6142600',
		description: 'OPERADORAS DE TELEVISÃO POR ASSINATURA POR MICROONDAS',
	},
	{
		id: '6143400',
		description: 'OPERADORAS DE TELEVISÃO POR ASSINATURA POR SATÉLITE',
	},
	{
		id: '6190601',
		description: 'PROVEDORES DE ACESSO ÀS REDES DE COMUNICAÇÕES',
	},
	{
		id: '6190602',
		description: 'PROVEDORES DE VOZ SOBRE PROTOCOLO INTERNET - VOIP',
	},
	{
		id: '6190699',
		description:
			'OUTRAS ATIVIDADES DE TELECOMUNICAÇÕES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '6619304', description: 'CAIXAS ELETRÔNICOS' },
	{ id: '6619305', description: 'OPERADORAS DE CARTÕES DE DÉBITO' },
	{
		id: '6202300',
		description:
			'DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZÁVEIS',
	},
	{
		id: '6203100',
		description:
			'DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR NÃO CUSTOMIZÁVEIS',
	},
	{ id: '6470103', description: 'FUNDOS DE INVESTIMENTO IMOBILIÁRIOS' },
	{
		id: '6422100',
		description: 'BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL',
	},
	{ id: '6204000', description: 'CONSULTORIA EM TECNOLOGIA DA INFORMAÇÃO' },
	{
		id: '6209100',
		description:
			'SUPORTE TÉCNICO, MANUTENÇÃO E OUTROS SERVIÇOS EM TECNOLOGIA DA INFORMAÇÃO',
	},
	{
		id: '6311900',
		description:
			'TRATAMENTO DE DADOS, PROVEDORES DE SERVIÇOS DE APLICAÇÃO E SERVIÇOS DE HOSPEDAGEM NA INTERNET',
	},
	{ id: '6391700', description: 'AGÊNCIAS DE NOTÍCIAS' },
	{
		id: '6399200',
		description:
			'OUTRAS ATIVIDADES DE PRESTAÇÃO DE SERVIÇOS DE INFORMAÇÃO NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '6410700', description: 'BANCO CENTRAL' },
	{ id: '6421200', description: 'BANCOS COMERCIAIS' },
	{ id: '6611801', description: 'BOLSA DE VALORES' },
	{ id: '6423900', description: 'CAIXAS ECONÔMICAS' },
	{ id: '6424701', description: 'BANCOS COOPERATIVOS' },
	{ id: '6424702', description: 'COOPERATIVAS CENTRAIS DE CRÉDITO' },
	{ id: '6424703', description: 'COOPERATIVAS DE CRÉDITO MÚTUO' },
	{ id: '6424704', description: 'COOPERATIVAS DE CRÉDITO RURAL' },
	{
		id: '6431000',
		description: 'BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL',
	},
	{ id: '6432800', description: 'BANCOS DE INVESTIMENTO' },
	{
		id: '6619399',
		description:
			'OUTRAS ATIVIDADES AUXILIARES DOS SERVIÇOS FINANCEIROS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '6433600', description: 'BANCOS DE DESENVOLVIMENTO' },
	{
		id: '6491300',
		description: 'SOCIEDADES DE FOMENTO MERCANTIL - FACTORING',
	},
	{ id: '6434400', description: 'AGÊNCIAS DE FOMENTO' },
	{ id: '6435201', description: 'SOCIEDADES DE CRÉDITO IMOBILIÁRIO' },
	{ id: '6435202', description: 'ASSOCIAÇÕES DE POUPANÇA E EMPRÉSTIMO' },
	{ id: '6435203', description: 'COMPANHIAS HIPOTECÁRIAS' },
	{
		id: '6436100',
		description:
			'SOCIEDADES DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS',
	},
	{
		id: '6437900',
		description: 'SOCIEDADES DE CRÉDITO AO MICROEMPREENDEDOR',
	},
	{ id: '6438701', description: 'BANCOS DE CÂMBIO' },
	{
		id: '6438799',
		description: 'OUTRAS INSTITUIÇÕES DE INTERMEDIAÇÃO NÃO MONETÁRIA',
	},
	{ id: '6440900', description: 'ARRENDAMENTO MERCANTIL' },
	{ id: '6450600', description: 'SOCIEDADES DE CAPITALIZAÇÃO' },
	{ id: '6461100', description: 'HOLDINGS DE INSTITUIÇÕES FINANCEIRAS' },
	{
		id: '6463800',
		description: 'OUTRAS SOCIEDADES DE PARTICIPAÇÃO, EXCETO HOLDINGS',
	},
	{
		id: '6470101',
		description:
			'FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIÁRIOS E IMOBILIÁRIOS',
	},
	{
		id: '6612601',
		description: 'CORRETORAS DE TÍTULOS E VALORES MOBILIÁRIOS',
	},
	{ id: '6492100', description: 'SECURITIZAÇÃO DE CRÉDITOS' },
	{
		id: '6493000',
		description:
			'ADMINISTRAÇÃO DE CONSÓRCIOS PARA AQUISIÇÃO DE BENS E DIREITOS',
	},
	{ id: '6499901', description: 'CLUBES DE INVESTIMENTO' },
	{ id: '6499902', description: 'SOCIEDADES DE INVESTIMENTO' },
	{ id: '6499903', description: 'FUNDO GARANTIDOR DE CRÉDITO' },
	{ id: '6499904', description: 'CAIXAS DE FINANCIAMENTO DE CORPORAÇÕES' },
	{ id: '6499905', description: 'CONCESSÃO DE CRÉDITO PELAS OSCIP' },
	{
		id: '6499999',
		description:
			'OUTRAS ATIVIDADES DE SERVIÇOS FINANCEIROS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '6511101', description: 'SOCIEDADE SEGURADORA DE SEGUROS VIDA' },
	{ id: '6621501', description: 'PERITOS E AVALIADORES DE SEGUROS' },
	{
		id: '6512000',
		description: 'SOCIEDADE SEGURADORA DE SEGUROS NÃO VIDA',
	},
	{ id: '6520100', description: 'SOCIEDADE SEGURADORA DE SEGUROS SAÚDE' },
	{ id: '6530800', description: 'RESSEGUROS' },
	{ id: '6541300', description: 'PREVIDÊNCIA COMPLEMENTAR FECHADA' },
	{ id: '6542100', description: 'PREVIDÊNCIA COMPLEMENTAR ABERTA' },
	{
		id: '6612605',
		description: 'AGENTES DE INVESTIMENTOS EM APLICAÇÕES FINANCEIRAS',
	},
	{ id: '6613400', description: 'ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO' },
	{ id: '6619301', description: 'SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA' },
	{
		id: '6619302',
		description: 'CORRESPONDENTES DE INSTITUIÇÕES FINANCEIRAS',
	},
	{ id: '6619303', description: 'REPRESENTAÇÕES DE BANCOS ESTRANGEIROS' },
	{
		id: '6629100',
		description:
			'ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '6630400',
		description:
			'ATIVIDADES DE ADMINISTRAÇÃO DE FUNDOS POR CONTRATO OU COMISSÃO',
	},
	{ id: '6810201', description: 'COMPRA E VENDA DE IMÓVEIS PRÓPRIOS' },
	{ id: '6810202', description: 'ALUGUEL DE IMÓVEIS PRÓPRIOS' },
	{ id: '6810203', description: 'LOTEAMENTO DE IMÓVEIS PRÓPRIOS' },
	{
		id: '6821801',
		description: 'CORRETAGEM NA COMPRA E VENDA E AVALIAÇÃO DE IMÓVEIS',
	},
	{ id: '6821802', description: 'CORRETAGEM NO ALUGUEL DE IMÓVEIS' },
	{
		id: '6822600',
		description: 'GESTÃO E ADMINISTRAÇÃO DA PROPRIEDADE IMOBILIARIA',
	},
	{ id: '6911701', description: 'SERVIÇOS ADVOCATÍCIOS' },
	{
		id: '7722500',
		description: 'ALUGUEL DE FITAS DE VÍDEO, DVDS E SIMILARES',
	},
	{ id: '6911703', description: 'AGENTE DE PROPRIEDADE INDUSTRIAL' },
	{ id: '6912500', description: 'CARTÓRIOS' },
	{ id: '6920601', description: 'ATIVIDADES DE CONTABILIDADE' },
	{ id: '7490102', description: 'ESCAFANDRIA E MERGULHO' },
	{
		id: '7020400',
		description:
			'ATIVIDADES DE CONSULTORIA EM GESTÃO EMPRESARIAL, EXCETO CONSULTORIA TÉCNICA ESPECÍFICA',
	},
	{ id: '7111100', description: 'SERVIÇOS DE ARQUITETURA' },
	{ id: '7112000', description: 'SERVIÇOS DE ENGENHARIA' },
	{
		id: '7119701',
		description: 'SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA',
	},
	{ id: '7119702', description: 'ATIVIDADES DE ESTUDOS GEOLÓGICOS' },
	{
		id: '7119703',
		description:
			'SERVIÇOS DE DESENHO TÉCNICO RELACIONADOS À ARQUITETURA E ENGENHARIA',
	},
	{
		id: '7119704',
		description:
			'SERVIÇOS DE PERÍCIA TÉCNICA RELACIONADOS À SEGURANÇA DO TRABALHO',
	},
	{
		id: '7723300',
		description: 'ALUGUEL DE OBJETOS DO VESTUÁRIO, JÓIAS E ACESSÓRIOS',
	},
	{
		id: '7119799',
		description:
			'ATIVIDADES TÉCNICAS RELACIONADAS À ENGENHARIA E ARQUITETURA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '7120100', description: 'TESTES E ANÁLISES TÉCNICAS' },
	{
		id: '7210000',
		description:
			'PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS',
	},
	{ id: '7311400', description: 'AGÊNCIAS DE PUBLICIDADE' },
	{
		id: '7312200',
		description:
			'AGENCIAMENTO DE ESPAÇOS PARA PUBLICIDADE, EXCETO EM VEÍCULOS DE COMUNICAÇÃO',
	},
	{
		id: '7319001',
		description: 'CRIAÇÃO ESTANDES PARA FEIRAS E EXPOSIÇÕES',
	},
	{ id: '7319002', description: 'PROMOÇÃO DE VENDAS' },
	{ id: '7319003', description: 'MARKETING DIRETO' },
	{ id: '7319004', description: 'CONSULTORIA EM PUBLICIDADE' },
	{
		id: '7319099',
		description:
			'OUTRAS ATIVIDADES DE PUBLICIDADE NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '7320300',
		description: 'PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA',
	},
	{ id: '7410202', description: 'DESIGN DE INTERIORES' },
	{ id: '7410203', description: 'DESIGN DE PRODUTO' },
	{
		id: '7410299',
		description: 'ATIVIDADES DE DESIGN NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '7420001',
		description:
			'ATIVIDADES DE PRODUÇÃO DE FOTOGRAFIAS, EXCETO AÉREA E SUBMARINA',
	},
	{
		id: '7420002',
		description:
			'ATIVIDADES DE PRODUÇÃO DE FOTOGRAFIAS AÉREAS E SUBMARINAS',
	},
	{ id: '7420003', description: 'LABORATÓRIOS FOTOGRÁFICOS' },
	{ id: '7420004', description: 'FILMAGEM DE FESTAS E EVENTOS' },
	{ id: '7420005', description: 'SERVIÇOS DE MICROFILMAGEM' },
	{
		id: '7490101',
		description: 'SERVIÇOS DE TRADUÇÃO, INTERPRETAÇÃO E SIMILARES',
	},
	{ id: '8230002', description: 'CASAS DE FESTAS E EVENTOS' },
	{
		id: '7490104',
		description:
			'ATIVIDADES DE INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS E NEGÓCIOS EM GERAL, EXCETO IMOBILIÁRIOS',
	},
	{
		id: '7490105',
		description:
			'AGENCIAMENTO DE PROFISSIONAIS PARA ATIVIDADES ESPORTIVAS, CULTURAIS E ARTÍSTICAS',
	},
	{
		id: '7739001',
		description:
			'ALUGUEL DE MÁQUINAS E EQUIPAMENTOS PARA EXTRAÇÃO DE MINÉRIOS E PETRÓLEO, SEM OPERADOR',
	},
	{ id: '7711000', description: 'LOCAÇÃO DE AUTOMÓVEIS SEM CONDUTOR' },
	{
		id: '7719501',
		description:
			'LOCAÇÃO DE EMBARCAÇÕES SEM TRIPULAÇÃO, EXCETO PARA FINS RECREATIVOS',
	},
	{ id: '7719502', description: 'LOCAÇÃO DE AERONAVES SEM TRIPULAÇÃO' },
	{
		id: '7719599',
		description:
			'LOCAÇÃO DE OUTROS MEIOS DE TRANSPORTE NÃO ESPECIFICADOS ANTERIORMENTE, SEM CONDUTOR',
	},
	{
		id: '7721700',
		description: 'ALUGUEL DE EQUIPAMENTOS RECREATIVOS E ESPORTIVOS',
	},
	{
		id: '7729299',
		description:
			'ALUGUEL DE OUTROS OBJETOS PESSOAIS E DOMÉSTICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '7731400',
		description:
			'ALUGUEL DE MÁQUINAS E EQUIPAMENTOS AGRÍCOLAS SEM OPERADOR',
	},
	{
		id: '7732201',
		description:
			'ALUGUEL DE MÁQUINAS E EQUIPAMENTOS PARA CONSTRUÇÃO SEM OPERADOR, EXCETO ANDAIMES',
	},
	{ id: '7732202', description: 'ALUGUEL DE ANDAIMES' },
	{
		id: '7733100',
		description: 'ALUGUEL DE MÁQUINAS E EQUIPAMENTOS PARA ESCRITÓRIOS',
	},
	{
		id: '7739002',
		description:
			'ALUGUEL DE EQUIPAMENTOS CIENTÍFICOS, MÉDICOS E HOSPITALARES, SEM OPERADOR',
	},
	{
		id: '7739003',
		description:
			'ALUGUEL DE PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO, EXCETO ANDAIMES',
	},
	{
		id: '7739099',
		description:
			'ALUGUEL DE OUTRAS MÁQUINAS E EQUIPAMENTOS COMERCIAIS E INDUSTRIAIS NÃO ESPECIFICADOS ANTERIORMENTE, SEM OPERADOR',
	},
	{
		id: '7740300',
		description: 'GESTÃO DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS',
	},
	{ id: '7810800', description: 'SELEÇÃO E AGENCIAMENTO DE MÃO DE OBRA' },
	{ id: '7820500', description: 'LOCAÇÃO DE MÃO DE OBRA TEMPORÁRIA' },
	{ id: '8599602', description: 'CURSOS DE PILOTAGEM' },
	{ id: '8591100', description: 'ENSINO DE ESPORTES' },
	{
		id: '7830200',
		description: 'FORNECIMENTO E GESTÃO DE RECURSOS HUMANOS PARA TERCEIROS',
	},
	{ id: '7911200', description: 'AGÊNCIAS DE VIAGENS' },
	{ id: '7912100', description: 'OPERADORES TURÍSTICOS' },
	{
		id: '7990200',
		description:
			'SERVIÇOS DE RESERVAS E OUTROS SERVIÇOS DE TURISMO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '8011101',
		description: 'ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA',
	},
	{
		id: '8011102',
		description: 'SERVIÇOS DE ADESTRAMENTO DE CÃES DE GUARDA',
	},
	{ id: '8012900', description: 'ATIVIDADES DE TRANSPORTE DE VALORES' },
	{
		id: '8020001',
		description:
			'ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA ELETRÔNICO',
	},
	{
		id: '8020002',
		description: 'OUTRAS ATIVIDADES DE SERVIÇOS DE SEGURANÇA',
	},
	{ id: '8030700', description: 'ATIVIDADES DE INVESTIGAÇÃO PARTICULAR' },
	{ id: '8220200', description: 'ATIVIDADES DE TELEATENDIMENTO' },
	{ id: '8592901', description: 'ENSINO DE DANÇA' },
	{
		id: '8111700',
		description:
			'SERVIÇOS COMBINADOS PARA APOIO A EDIFÍCIOS, EXCETO CONDOMÍNIOS PREDIAIS',
	},
	{ id: '8112500', description: 'CONDOMÍNIOS PREDIAIS' },
	{ id: '8121400', description: 'LIMPEZA EM PRÉDIOS E EM DOMICÍLIOS' },
	{ id: '8122200', description: 'IMUNIZAÇÃO E CONTROLE DE PRAGAS URBANAS' },
	{
		id: '8129000',
		description: 'ATIVIDADES DE LIMPEZA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '8130300', description: 'ATIVIDADES PAISAGÍSTICAS' },
	{
		id: '8211300',
		description: 'SERVIÇOS COMBINADOS DE ESCRITÓRIO E APOIO ADMINISTRATIVO',
	},
	{ id: '8219901', description: 'FOTOCÓPIAS' },
	{ id: '8512100', description: 'EDUCAÇÃO INFANTIL - PRÉESCOLA' },
	{
		id: '8230001',
		description:
			'SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, CONGRESSOS, EXPOSIÇÕES E FESTAS',
	},
	{
		id: '8291100',
		description: 'ATIVIDADES DE COBRANÇAS E INFORMAÇÕES CADASTRAIS',
	},
	{
		id: '8292000',
		description: 'ENVASAMENTO E EMPACOTAMENTO SOB CONTRATO',
	},
	{
		id: '8299701',
		description: 'MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA',
	},
	{
		id: '8299702',
		description:
			'EMISSÃO DE VALES ALIMENTAÇÃO, VALES TRANSPORTE E SIMILARES',
	},
	{
		id: '8299703',
		description: 'SERVIÇOS DE GRAVAÇÃO DE CARIMBOS, EXCETO CONFECÇÃO',
	},
	{ id: '8299704', description: 'LEILOEIROS INDEPENDENTES' },
	{
		id: '8299705',
		description: 'SERVIÇOS DE LEVANTAMENTO DE FUNDOS SOB CONTRATO',
	},
	{ id: '8299706', description: 'CASAS LOTÉRICAS' },
	{ id: '8299707', description: 'SALAS DE ACESSO À INTERNET' },
	{
		id: '8299799',
		description:
			'OUTRAS ATIVIDADES DE SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '9603304', description: 'SERVIÇOS DE FUNERÁRIAS' },
	{
		id: '8412400',
		description:
			'REGULAÇÃO DAS ATIVIDADES DE SAÚDE, EDUCAÇÃO, SERVIÇOS CULTURAIS E OUTROS SERVIÇOS SOCIAIS',
	},
	{ id: '8413200', description: 'REGULAÇÃO DAS ATIVIDADES ECONÔMICAS' },
	{ id: '8421300', description: 'RELAÇÕES EXTERIORES' },
	{ id: '8422100', description: 'DEFESA' },
	{
		id: '8630599',
		description:
			'ATIVIDADES DE ATENÇÃO AMBULATORIAL NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '8423000', description: 'JUSTIÇA' },
	{ id: '8424800', description: 'SEGURANÇA E ORDEM PÚBLICA' },
	{ id: '8425600', description: 'DEFESA CIVIL' },
	{ id: '8430200', description: 'SEGURIDADE SOCIAL OBRIGATÓRIA' },
	{ id: '8511200', description: 'EDUCAÇÃO INFANTIL - CRECHE' },
	{ id: '9603305', description: 'SERVIÇOS DE SOMATOCONSERVAÇÃO' },
	{ id: '8513900', description: 'ENSINO FUNDAMENTAL' },
	{ id: '8599603', description: 'TREINAMENTO EM INFORMÁTICA' },
	{
		id: '8599604',
		description: 'TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL',
	},
	{ id: '8599605', description: 'CURSOS PREPARATÓRIOS PARA CONCURSOS' },
	{ id: '8520100', description: 'ENSINO MÉDIO' },
	{ id: '8531700', description: 'EDUCAÇÃO SUPERIOR - GRADUAÇÃO' },
	{
		id: '8532500',
		description: 'EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS GRADUAÇÃO',
	},
	{
		id: '8640201',
		description: 'LABORATÓRIOS DE ANATOMIA PATOLÓGICA E CITOLÓGICA',
	},
	{
		id: '8533300',
		description: 'EDUCAÇÃO SUPERIOR - PÓS GRADUAÇÃO E EXTENSÃO',
	},
	{ id: '8541400', description: 'EDUCAÇÃO PROFISSIONAL DE NÍVEL TÉCNICO' },
	{
		id: '8542200',
		description: 'EDUCAÇÃO PROFISSIONAL DE NÍVEL TECNOLÓGICO',
	},
	{ id: '8550301', description: 'ADMINISTRAÇÃO DE CAIXAS ESCOLARES' },
	{
		id: '8550302',
		description: 'ATIVIDADES DE APOIO À EDUCAÇÃO, EXCETO CAIXAS ESCOLARES',
	},
	{
		id: '9603399',
		description:
			'ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '8592902', description: 'ENSINO DE ARTES CÊNICAS, EXCETO DANÇA' },
	{ id: '8592903', description: 'ENSINO DE MÚSICA' },
	{
		id: '8592999',
		description: 'ENSINO DE ARTE E CULTURA NÃO ESPECIFICADO ANTERIORMENTE',
	},
	{ id: '8593700', description: 'ENSINO DE IDIOMAS' },
	{
		id: '8599699',
		description:
			'OUTRAS ATIVIDADES DE ENSINO NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '8640202', description: 'LABORATÓRIOS CLÍNICOS' },
	{ id: '8640203', description: 'SERVIÇOS DE DIÁLISE E NEFROLOGIA' },
	{ id: '8640204', description: 'SERVIÇOS DE TOMOGRAFIA' },
	{
		id: '8610101',
		description:
			'ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS',
	},
	{
		id: '8610102',
		description:
			'ATIVIDADES DE ATENDIMENTO EM PRONTO SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGÊNCIAS',
	},
	{ id: '8621601', description: 'UTI MÓVEL' },
	{
		id: '8621602',
		description:
			'SERVIÇOS MÓVEIS DE ATENDIMENTO A URGÊNCIAS, EXCETO POR UTI MÓVEL',
	},
	{
		id: '8622400',
		description:
			'SERVIÇOS DE REMOÇÃO DE PACIENTES, EXCETO OS SERVIÇOS MÓVEIS DE ATENDIMENTO A URGÊNCIAS',
	},
	{ id: '9609202', description: 'AGÊNCIAS MATRIMONIAIS' },
	{
		id: '8630501',
		description:
			'ATIVIDADE MÉDICA AMBULATORIAL COM RECURSOS PARA REALIZAÇÃO DE PROCEDIMENTOS CIRÚRGICOS',
	},
	{
		id: '8630502',
		description:
			'ATIVIDADE MÉDICA AMBULATORIAL COM RECURSOS PARA REALIZAÇÃO DE EXAMES COMPLEMENTARES',
	},
	{
		id: '8630503',
		description: 'ATIVIDADE MÉDICA AMBULATORIAL RESTRITA A CONSULTAS',
	},
	{ id: '8650004', description: 'ATIVIDADES DE FISIOTERAPIA' },
	{ id: '8630504', description: 'ATIVIDADE ODONTOLÓGICA' },
	{
		id: '8630506',
		description: 'SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA',
	},
	{
		id: '8630507',
		description: 'ATIVIDADES DE REPRODUÇÃO HUMANA ASSISTIDA',
	},
	{
		id: '8640205',
		description:
			'SERVIÇOS DE DIAGNÓSTICO POR IMAGEM COM USO DE RADIAÇÃO IONIZANTE, EXCETO TOMOGRAFIA',
	},
	{ id: '8640206', description: 'SERVIÇOS DE RESSONÂNCIA MAGNÉTICA' },
	{
		id: '8640207',
		description:
			'SERVIÇOS DE DIAGNÓSTICO POR IMAGEM SEM USO DE RADIAÇÃO IONIZANTE, EXCETO RESSONÂNCIA MAGNÉTICA',
	},
	{
		id: '8640208',
		description:
			'SERVIÇOS DE DIAGNÓSTICO POR REGISTRO GRÁFICO - ECG, EEG E OUTROS EXAMES ANÁLOGOS',
	},
	{
		id: '8640209',
		description:
			'SERVIÇOS DE DIAGNÓSTICO POR MÉTODOS ÓPTICOS - ENDOSCOPIA E OUTROS EXAMES ANÁLOGOS',
	},
	{ id: '8640210', description: 'SERVIÇOS DE QUIMIOTERAPIA' },
	{ id: '8640211', description: 'SERVIÇOS DE RADIOTERAPIA' },
	{ id: '8640212', description: 'SERVIÇOS DE HEMOTERAPIA' },
	{ id: '8640213', description: 'SERVIÇOS DE LITOTRIPCIA' },
	{
		id: '8640214',
		description: 'SERVIÇOS DE BANCOS DE CÉLULAS E TECIDOS HUMANOS',
	},
	{
		id: '8712300',
		description:
			'ATIVIDADES DE FORNECIMENTO DE INFRAESTRUTURA DE APOIO E ASSISTÊNCIA A PACIENTE NO DOMICÍLIO',
	},
	{
		id: '8640299',
		description:
			'ATIVIDADES DE SERVIÇOS DE COMPLEMENTAÇÃO DIAGNÓSTICA E TERAPÊUTICA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '8650001', description: 'ATIVIDADES DE ENFERMAGEM' },
	{ id: '8650002', description: 'ATIVIDADES DE PROFISSIONAIS DA NUTRIÇÃO' },
	{ id: '8650003', description: 'ATIVIDADES DE PSICOLOGIA E PSICANÁLISE' },
	{
		id: '8650007',
		description: 'ATIVIDADES DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL',
	},
	{
		id: '8650099',
		description:
			'ATIVIDADES DE PROFISSIONAIS DA ÁREA DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '8660700', description: 'ATIVIDADES DE APOIO À GESTÃO DE SAÚDE' },
	{
		id: '8690901',
		description:
			'ATIVIDADES DE PRÁTICAS INTEGRATIVAS E COMPLEMENTARES EM SAÚDE HUMANA',
	},
	{ id: '8690902', description: 'ATIVIDADES DE BANCO DE LEITE HUMANO' },
	{ id: '8690903', description: 'ATIVIDADES DE ACUPUNTURA' },
	{ id: '8690904', description: 'ATIVIDADES DE PODOLOGIA' },
	{ id: '9529103', description: 'REPARAÇÃO DE RELÓGIOS' },
	{ id: '8711501', description: 'CLÍNICAS E RESIDÊNCIAS GERIÁTRICAS' },
	{
		id: '8711502',
		description: 'INSTITUIÇÕES DE LONGA PERMANÊNCIA PARA IDOSOS',
	},
	{
		id: '8711503',
		description:
			'ATIVIDADES DE ASSISTÊNCIA A DEFICIENTES FÍSICOS, IMUNODEPRIMIDOS E CONVALESCENTES',
	},
	{
		id: '8711504',
		description: 'CENTROS DE APOIO A PACIENTES COM CÂNCER E COM AIDS',
	},
	{ id: '8711505', description: 'CONDOMÍNIOS RESIDENCIAIS PARA IDOSOS' },
	{ id: '9002702', description: 'RESTAURAÇÃO DE OBRAS DE ARTE' },
	{
		id: '8720401',
		description: 'ATIVIDADES DE CENTROS DE ASSISTÊNCIA PSICOSSOCIAL',
	},
	{ id: '8730101', description: 'ORFANATOS' },
	{ id: '8730102', description: 'ALBERGUES ASSISTENCIAIS' },
	{
		id: '8730199',
		description:
			'ATIVIDADES DE ASSISTÊNCIA SOCIAL PRESTADAS EM RESIDÊNCIAS COLETIVAS E PARTICULARES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '9001901', description: 'PRODUÇÃO TEATRAL' },
	{ id: '9001902', description: 'PRODUÇÃO MUSICAL' },
	{ id: '9001903', description: 'PRODUÇÃO DE ESPETÁCULOS DE DANÇA' },
	{
		id: '9001904',
		description:
			'PRODUÇÃO DE ESPETÁCULOS CIRCENSES, DE MARIONETES E SIMILARES',
	},
	{
		id: '9001905',
		description:
			'PRODUÇÃO DE ESPETÁCULOS DE RODEIOS, VAQUEJADAS E SIMILARES',
	},
	{
		id: '9001906',
		description: 'ATIVIDADES DE SONORIZAÇÃO E DE ILUMINAÇÃO',
	},
	{
		id: '9001999',
		description:
			'ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '9002701',
		description:
			'ATIVIDADES DE ARTISTAS PLÁSTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES',
	},
	{
		id: '9003500',
		description:
			'GESTÃO DE ESPAÇOS PARA ARTES CÊNICAS, ESPETÁCULOS E OUTRAS ATIVIDADES ARTÍSTICAS',
	},
	{ id: '9101500', description: 'ATIVIDADES DE BIBLIOTECAS E ARQUIVOS' },
	{
		id: '9102301',
		description:
			'ATIVIDADES DE MUSEUS E DE EXPLORAÇÃO DE LUGARES E PRÉDIOS HISTÓRICOS E ATRAÇÕES SIMILARES',
	},
	{
		id: '9102302',
		description:
			'RESTAURAÇÃO E CONSERVAÇÃO DE LUGARES E PRÉDIOS HISTÓRICOS',
	},
	{
		id: '9103100',
		description:
			'ATIVIDADES DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS, RESERVAS ECOLÓGICAS E ÁREAS DE PROTEÇÃO AMBIENTAL',
	},
	{ id: '9200301', description: 'CASAS DE BINGO' },
	{
		id: '9200302',
		description: 'EXPLORAÇÃO DE APOSTAS EM CORRIDAS DE CAVALOS',
	},
	{
		id: '9200399',
		description:
			'EXPLORAÇÃO DE JOGOS DE AZAR E APOSTAS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '9311500', description: 'GESTÃO DE INSTALAÇÕES DE ESPORTES' },
	{ id: '9312300', description: 'CLUBES SOCIAIS, ESPORTIVOS E SIMILARES' },
	{ id: '9313100', description: 'ATIVIDADES DE CONDICIONAMENTO FÍSICO' },
	{
		id: '9319101',
		description: 'PRODUÇÃO E PROMOÇÃO DE EVENTOS ESPORTIVOS',
	},
	{
		id: '9319199',
		description:
			'OUTRAS ATIVIDADES ESPORTIVAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '9321200', description: 'PARQUES DE DIVERSÃO E PARQUES TEMÁTICOS' },
	{
		id: '9329801',
		description: 'DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES',
	},
	{ id: '9329802', description: 'EXPLORAÇÃO DE BOLICHES' },
	{
		id: '9329803',
		description: 'EXPLORAÇÃO DE JOGOS DE SINUCA, BILHAR E SIMILARES',
	},
	{
		id: '9329804',
		description: 'EXPLORAÇÃO DE JOGOS ELETRÔNICOS RECREATIVOS',
	},
	{
		id: '9609204',
		description:
			'EXPLORAÇÃO DE MÁQUINAS DE SERVIÇOS PESSOAIS ACIONADAS POR MOEDA',
	},
	{
		id: '9329899',
		description:
			'OUTRAS ATIVIDADES DE RECREAÇÃO E LAZER NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '9411100',
		description:
			'ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS PATRONAIS E EMPRESARIAIS',
	},
	{ id: '9412001', description: 'ATIVIDADES DE FISCALIZAÇÃO PROFISSIONAL' },
	{
		id: '9412099',
		description: 'OUTRAS ATIVIDADES ASSOCIATIVAS PROFISSIONAIS',
	},
	{ id: '9420100', description: 'ATIVIDADES DE ORGANIZAÇÕES SINDICAIS' },
	{
		id: '9430800',
		description: 'ATIVIDADES DE ASSOCIAÇÕES DE DEFESA DE DIREITOS SOCIAIS',
	},
	{
		id: '9491000',
		description: 'ATIVIDADES DE ORGANIZAÇÕES RELIGIOSAS OU FILOSÓFICAS',
	},
	{ id: '9492800', description: 'ATIVIDADES DE ORGANIZAÇÕES POLÍTICAS' },
	{
		id: '9602502',
		description:
			'ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA',
	},
	{
		id: '9493600',
		description:
			'ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS LIGADAS À CULTURA E À ARTE',
	},
	{ id: '9609205', description: 'ATIVIDADES DE SAUNA E BANHOS' },
	{
		id: '9609206',
		description: 'SERVIÇOS DE TATUAGEM E COLOCAÇÃO DE PIERCING',
	},
	{
		id: '9511800',
		description:
			'REPARAÇÃO E MANUTENÇÃO DE COMPUTADORES E DE EQUIPAMENTOS PERIFÉRICOS',
	},
	{
		id: '9512600',
		description: 'REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE COMUNICAÇÃO',
	},
	{
		id: '9521500',
		description:
			'REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS ELETROELETRÔNICOS DE USO PESSOAL E DOMÉSTICO',
	},
	{
		id: '9529101',
		description: 'REPARAÇÃO DE CALÇADOS, DE BOLSAS E ARTIGOS DE VIAGEM',
	},
	{ id: '9529102', description: 'CHAVEIROS' },
	{
		id: '9529104',
		description:
			'REPARAÇÃO DE BICICLETAS, TRICICLOS E OUTROS VEÍCULOS NÃO MOTORIZADOS',
	},
	{ id: '9529105', description: 'REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO' },
	{ id: '9529106', description: 'REPARAÇÃO DE JÓIAS' },
	{
		id: '9529199',
		description:
			'REPARAÇÃO E MANUTENÇÃO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMÉSTICOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '9601701', description: 'LAVANDERIAS' },
	{ id: '9601702', description: 'TINTURARIAS' },
	{ id: '9601703', description: 'TOALHEIROS' },
	{ id: '9602501', description: 'CABELEIREIROS, MANICURE E PEDICURE' },
	{ id: '9603301', description: 'GESTÃO E MANUTENÇÃO DE CEMITÉRIOS' },
	{ id: '9609207', description: 'ALOJAMENTO DE ANIMAIS DOMÉSTICOS' },
	{
		id: '9609208',
		description: 'HIGIENE E EMBELEZAMENTO DE ANIMAIS DOMÉSTICOS',
	},
	{
		id: '9609299',
		description:
			'OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{ id: '9700500', description: 'SERVIÇOS DOMÉSTICOS' },
	{
		id: '9900800',
		description:
			'ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS',
	},
	{ id: '3104700', description: 'FABRICAÇÃO DE COLCHÕES' },
	{ id: '4641901', description: 'COMÉRCIO ATACADISTA DE TECIDOS' },
	{
		id: '7490199',
		description:
			'OUTRAS ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '1921700',
		description: 'FABRICAÇÃO DE PRODUTOS DO REFINO DE PETRÓLEO',
	},
	{ id: '1931400', description: 'FABRICAÇÃO DE ÁLCOOL' },
	{ id: '2014200', description: 'FABRICAÇÃO DE GASES INDUSTRIAIS' },
	{
		id: '2073800',
		description:
			'FABRICAÇÃO DE IMPERMEABILIZANTES, SOLVENTES E PRODUTOS AFINS',
	},
	{ id: '2123800', description: 'FABRICAÇÃO DE PREPARAÇÕES FARMACÊUTICAS' },
	{ id: '2421100', description: 'PRODUÇÃO DE SEMI ACABADOS DE AÇO' },
	{
		id: '3600601',
		description: 'CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA',
	},
	{
		id: '1096100',
		description: 'FABRICAÇÃO DE ALIMENTOS E PRATOS PRONTOS',
	},
	{
		id: '3329599',
		description:
			'INSTALAÇÃO DE OUTROS EQUIPAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{ id: '7500100', description: 'ATIVIDADES VETERINÁRIAS' },
	{ id: '8650005', description: 'ATIVIDADES DE TERAPIA OCUPACIONAL' },
	{
		id: '8720499',
		description:
			'ATIVIDADES DE ASSISTÊNCIA PSICOSSOCIAL E À SAÚDE A PORTADORES DE DISTÚRBIOS PSÍQUICOS, DEFICIÊNCIA MENTAL E DEPENDÊNCIA QUÍMICA E GRUPOS SIMILARES NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '1610205',
		description: 'SERVIÇO DE TRATAMENTO DE MADEIRA REALIZADO SOB CONTRATO',
	},
	{
		id: '5611205',
		description:
			'BARES E OUTROS ESTABELECIMENTOS ESPECIALIZADOS EM SERVIR BEBIDAS, COM ENTRETENIMENTO ',
	},
	{
		id: '7490103',
		description:
			'SERVIÇOS DE AGRONOMIA E DE CONSULTORIA ÀS ATIVIDADES AGRÍCOLAS E PECUÁRIAS',
	},
	{
		id: '8219999',
		description:
			'PREPARAÇÃO DE DOCUMENTOS E SERVIÇOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NÃO ESPECIFICADOS ANTERIORMENTE',
	},
	{
		id: '6319400',
		description:
			'PORTAIS, PROVEDORES DE CONTEÚDO E OUTROS SERVIÇOS DE INFORMAÇÃO NA INTERNET',
	},
	{
		id: '9499500',
		description: 'ATIVIDADES ASSOCIATIVAS NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '4713005',
		description:
			'LOJAS FRANCAS (DUTY FREE) DE AEROPORTOS, PORTOS E EM FRONTEIRAS TERRESTRES',
	},
	{ id: '8411600', description: 'ADMINISTRAÇÃO PÚBLICA EM GERAL' },
	{
		id: '8690999',
		description:
			'OUTRAS ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA NÃO ESPECIFICADAS ANTERIORMENTE',
	},
	{
		id: '2391502',
		description:
			'APARELHAMENTO DE PEDRAS PARA CONSTRUÇÃO, EXCETO ASSOCIADO À EXTRAÇÃO',
	},
	{
		id: '2391503',
		description:
			'APARELHAMENTO DE PLACAS E EXECUÇÃO DE TRABALHOS EM MÁRMORE, GRANITO, ARDÓSIA E OUTRAS PEDRAS',
	},
	{ id: '6511102', description: 'PLANOS DE AUXÍLIO FUNERAL' },
	{
		id: '4541206',
		description:
			'COMÉRCIO A VAREJO DE PEÇAS E ACESSÓRIOS NOVOS PARA MOTOCICLETAS E MOTONETAS',
	},
	{
		id: '4541207',
		description:
			'COMÉRCIO A VAREJO DE PEÇAS E ACESSÓRIOS USADOS PARA MOTOCICLETAS E MOTONETAS',
	},
	{ id: '6911702', description: 'ATIVIDADES AUXILIARES DA JUSTIÇA' },
	{
		id: '6920602',
		description:
			'ATIVIDADES DE CONSULTORIA E AUDITORIA CONTÁBIL E TRIBUTÁRIA',
	},
	{
		id: '8800600',
		description: 'SERVIÇOS DE ASSISTÊNCIA SOCIAL SEM ALOJAMENTO',
	},
	{
		id: '4713004',
		description:
			'LOJAS DE DEPARTAMENTOS OU MAGAZINES, EXCETO LOJAS FRANCAS (DUTY FREE)',
	},
];

export const parsedCnaeData = cnaeSubclassesRawData.map(subclass => {
	return {
		label: `${subclass.id} - ${subclass.description}`,
		value: subclass.id,
	};
});

export const CnaeListDescription: { [key: string]: string } =
	cnaeSubclassesRawData.reduce((obj, cnae) => {
		return {
			...obj,
			[cnae.id]: `${cnae.id} - ${cnae.description}`,
		};
	}, {});
