import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { Steps, StepsWrapper } from './styles';
import { PageActions } from 'components/PageActions';
import {
	BusinessType,
	Company,
	CompanyDto,
	ETenantType,
	PersonType,
} from 'types/Company';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { notification } from 'antd';
import { AccountPermission, AccountRelatedPart } from 'types/Account';
import { AccountsService } from 'modules/escrow/services';
import { useNavigate } from 'react-router-dom';
import { AccountType, EscrowRoutes } from 'modules/escrow/constants/routes';
import { EscrowSteps } from '../../../../../components/EscrowSteps';
import { NaturalPersonInformation } from 'components/NaturalPersonInformation';
import { PersonRepresentatives } from 'components/PersonRepresentatives';
import EscrowNaturalPersonCreationSigners from '../../EscrowCreation/natural/EscrowNaturalPersonCreationSigners';
import {
	FullAccountRelatedPart,
	StepsId,
	StepsStatus,
} from '../../EscrowCreation/types';
import EscrowCreationDestination from '../../EscrowCreation/EscrowCreationDestination';
import { paymentAccountPermissions } from '../types';
import { AccountOwnerPermissions } from 'constants/account';
import { RelatedPartType } from 'modules/escrow/components/RelatedPartsCreation/RelatedPartsCreation';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';

const enum EPaymentSteps {
	INFORMATION = 'INFORMATION',
	REPRESENTATIVES = 'REPRESENTATIVES',
	SIGNERS = 'SIGNERS',
	BENEFICIARY = 'BENEFICIARY',
}

const enum EPaymentStepsNumber {
	INFORMATION = 1,
	REPRESENTATIVES = 2,
	SIGNERS = 3,
	BENEFICIARY = 4,
}

const PaymentAccountPersonCreationPage = () => {
	const navigate = useNavigate();
	const { person: personInContext, type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const [currentStepNumber, setCurrentStepNumber] =
		useState<EPaymentStepsNumber>(EPaymentStepsNumber.INFORMATION);
	const [currentStep, setCurrentStep] = useState<EPaymentSteps>(
		EPaymentSteps.INFORMATION,
	);
	const [accountId, updateAccountId] = useState<string>();
	const [company, updateCompany] = useState<CompanyDto>({} as CompanyDto);
	const [isPersonCreating, setIsPersonCreating] = useState<boolean>(false);
	const [tenantType, setTenantType] = useState<ETenantType>();
	const [accountRelatedParts, updateAccountRelatedParts] = useState<
		FullAccountRelatedPart[]
	>([]);
	const [stepsStatus, updateStepsStatus] = useState<StepsStatus>({
		personal_information: {
			completed: false,
		},
		representatives: {
			completed: false,
		},
		signers: {
			completed: false,
		},
		destination: {
			completed: false,
		},
	});

	const handleStepStatus = useCallback((stepId: StepsId, status: boolean) => {
		updateStepsStatus(prevState => ({
			...prevState,
			[stepId]: {
				completed: status,
			},
			related_parts: {
				completed: true,
			},
		}));
	}, []);

	const { mutate, isPending } = useMutation<
		{ id: string },
		ApiError,
		{
			company: CompanyDto;
			relatedParts: AccountRelatedPart[];
		}
	>({
		mutationFn: ({ company: newCompany, relatedParts }) => {
			if (newCompany.id) {
				return AccountsService.createAccount({
					person_id: newCompany.id,
					tenant_type: tenantType || ETenantType.DEFAULT,
					account_type: AccountType.PAYMENT,
					parties: relatedParts.map(relatedPart => {
						if (relatedPart.person.id === 'company_owner_id') {
							return {
								...relatedPart,
								permissions: paymentAccountPermissions,
								person: {
									businessType: BusinessType.OTHERS,
									id: newCompany.id!,
								},
								sign_contract: true,
							};
						}
						return relatedPart;
					}),
				});
			}
			return PersonsService.createCompany({
				...newCompany,
				documents: {
					bylaws: newCompany.documents.bylaws,
					income_statement: newCompany.documents.income_statement,
					incorporation_certificate:
						newCompany.documents.incorporation_certificate,
					last_contract_amendment:
						newCompany.documents.last_contract_amendment,
					proof_of_address: newCompany.documents.proof_of_address,
					power_of_attorney: newCompany.documents.power_of_attorney,
					document_identification:
						newCompany.documents.document_identification,
				},
				representatives: newCompany.representatives.map(
					representative => ({
						...representative,
						documents: {
							identification:
								representative.documents.identification,
							proof_of_address:
								representative.documents.proof_of_address,
						},
						representative: representative.representative && {
							...representative.representative,
							documents: {
								identification:
									representative.representative.documents
										.identification,
								proof_of_address:
									representative.representative.documents
										.proof_of_address,
							},
						},
					}),
				),
			})
				.then(({ id: person_id }) => {
					return AccountsService.createAccount({
						person_id: person_id,
						tenant_type: tenantType || ETenantType.DEFAULT,
						account_type: AccountType.PAYMENT,
						parties: relatedParts.map(relatedPart => {
							if (relatedPart.person.id === 'company_owner_id') {
								return {
									...relatedPart,
									permissions: paymentAccountPermissions,
									businessType: BusinessType.OTHERS,
									person: {
										...relatedPart.person,
										id: person_id,
									},
									sign_contract: true,
								};
							}
							return relatedPart;
						}),
					});
				})
				.catch(e => Promise.reject(e));
		},
		onSuccess: ({ id }) => {
			updateAccountId(id);
			setIsPersonCreating(false);
			setTimeout(() => {
				setCurrentStep(EPaymentSteps.BENEFICIARY);
				setCurrentStepNumber(EPaymentStepsNumber.BENEFICIARY);
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a conta.',
			});
		},
	});

	const updateRelatedPartsIfNecessary = useCallback(
		(editAccount: CompanyDto) => {
			updateAccountRelatedParts(
				state =>
					state.map(relatedPart => {
						if (relatedPart.person.id === 'company_owner_id') {
							return {
								...relatedPart,
								companyDetails: {
									...relatedPart.companyDetails,
									representatives:
										editAccount.representatives,
								},
							};
						}
						return relatedPart;
					}) as FullAccountRelatedPart[],
			);
		},
		[accountRelatedParts],
	);

	const handleAccountUpdate = useCallback(
		(editAccount: CompanyDto | FullAccountRelatedPart[] | void) => {
			if (Array.isArray(editAccount)) {
				updateAccountRelatedParts(editAccount);
			} else if (editAccount) {
				updateRelatedPartsIfNecessary(editAccount);
				updateCompany({
					...company,
					...editAccount,
				});
			}
		},
		[company, updateRelatedPartsIfNecessary, accountRelatedParts],
	);

	const onNext = useCallback(() => {
		mutate({
			company: {
				...company,
				person_type: PersonType.NATURAL,
			},
			relatedParts: (
				accountRelatedParts as FullAccountRelatedPart[]
			).map<AccountRelatedPart>(relatedPart => ({
				person: relatedPart.person,
				sign_contract: relatedPart.sign_contract,
				permissions: relatedPart.permissions,
			})),
		});
	}, [accountRelatedParts, company]);

	const onResetRepresentatives = useCallback(() => {
		updateCompany({
			...company,
			id: undefined,
			representatives: [],
			documents: {},
		});
	}, [company]);

	const updateRelatedPartsSigners = useCallback(
		(selectedRelatedPart: FullAccountRelatedPart) => {
			if (!selectedRelatedPart.person.id) return;
			const updatedRelatedParts = accountRelatedParts.map(relatedPart => {
				if (relatedPart.person.id === selectedRelatedPart.person.id) {
					return {
						...relatedPart,
						sign_contract: !relatedPart.sign_contract,
					};
				}
				return relatedPart;
			});
			updateAccountRelatedParts(updatedRelatedParts);
		},
		[accountRelatedParts],
	);

	const handleRelatedPartCreation = useCallback(
		(relatedParts: RelatedPartType[]) => {
			return relatedParts
				.map<FullAccountRelatedPart>(relatedPart => ({
					person: {
						id: relatedPart.person_id,
						businessType: relatedPart.relationship,
					},
					companyDetails: relatedPart.person,
					sign_contract: relatedPart.signer ?? false,
					permissions: relatedPart.permission,
				}))
				.filter(
					relatedPart =>
						relatedPart.person.id !== 'celcoin_company_id',
				);
		},
		[accountRelatedParts],
	);

	useEffect(() => {
		if (!personInContext) return;
		if (accountRelatedParts.length > 0) return;

		const celcoinRelatedPart: RelatedPartType = {
			person_id: 'celcoin_company_id',
			permission: [AccountPermission.POSTING_REVIEW],
			relationship: BusinessType.OTHERS,
			person: {} as Company,
			signer: false,
		};

		const companyRelatedPart: RelatedPartType = {
			person_id: company.id ?? 'company_owner_id',
			permission: AccountOwnerPermissions,
			relationship: BusinessType.OTHERS,
			person: company as Company,
			signer: true,
		};

		const currentPersonProfile: RelatedPartType = {
			person_id: personInContext?.id ?? '',
			permission: [
				AccountPermission.VIEW_ACCOUNT,
				AccountPermission.CREATE_ACCOUNT,
			],
			relationship: BusinessType.OTHERS,
			signer: true,
		};

		const relatedParts = accountRelatedParts
			.filter(
				relatedPart =>
					relatedPart.person.id !== company.id &&
					relatedPart.person.id !== 'company_owner_id',
			)
			.filter(
				relatedPart => relatedPart.person.id !== personInContext?.id,
			)
			.map(relatedParts => ({
				person_id: relatedParts.person.id,
				person: relatedParts.companyDetails,
				signer: relatedParts.sign_contract,
				permission: relatedParts.permissions,
				relationship: relatedParts.person.businessType,
			}));

		const mustShowInRelatedPartList =
			type === AccessType.FINANCIAL_INSTITUTION
				? [companyRelatedPart]
				: [companyRelatedPart, currentPersonProfile];

		const parsedRelatedParts = handleRelatedPartCreation(
			[
				celcoinRelatedPart,
				...(company.name && company.taxpayer_id
					? mustShowInRelatedPartList
					: []),
			].concat(relatedParts) as RelatedPartType[],
		);

		updateAccountRelatedParts(parsedRelatedParts);
	}, [company, personInContext, type]);

	const { data: personInTheContextData, error } = useQuery({
		queryKey: ['personData', personInContext],
		queryFn: () => PersonsService.getCompany(personInContext?.id ?? ''),
		enabled:
			!!personInContext?.id && type !== AccessType.FINANCIAL_INSTITUTION,
	});

	useEffect(() => {
		if (error && currentStep == EPaymentSteps.SIGNERS) {
			api.error({
				description: error.message,
				message: 'Ocorreu um erro ao buscar os dados do contexto.',
			});

			setCurrentStep(EPaymentSteps.REPRESENTATIVES);
			setCurrentStepNumber(EPaymentStepsNumber.REPRESENTATIVES);
		}
	}, [error]);

	useEffect(() => {
		if (personInTheContextData && accountRelatedParts.length > 0) {
			const foundPersonFromContextData = accountRelatedParts.find(
				relatedPart =>
					relatedPart.person.id === personInTheContextData.id,
			);
			if (
				foundPersonFromContextData &&
				foundPersonFromContextData.companyDetails
			) {
				return;
			}

			updateAccountRelatedParts(relatedParts => {
				const updatedRelatedParts = relatedParts.map(relatedPart => {
					if (relatedPart.person.id === personInTheContextData.id) {
						return {
							...relatedPart,
							companyDetails: personInTheContextData,
						};
					}
					return relatedPart;
				});
				return updatedRelatedParts;
			});
		}
	}, [personInTheContextData, accountRelatedParts]);

	const steps = {
		[EPaymentSteps.INFORMATION]: (
			<NaturalPersonInformation
				isAccountCreation={true}
				person={company}
				onResetRepresentatives={onResetRepresentatives}
				handleStepStatus={handleStepStatus}
				setTenantType={setTenantType}
				tenantType={tenantType}
				isPersonCreating={isPersonCreating}
				setIsPersonCreating={setIsPersonCreating}
				onNext={company => {
					handleAccountUpdate(company);
					setCurrentStep(EPaymentSteps.REPRESENTATIVES);
					setCurrentStepNumber(EPaymentStepsNumber.REPRESENTATIVES);
				}}
			/>
		),
		[EPaymentSteps.REPRESENTATIVES]: (
			<PersonRepresentatives
				isAccountCreation={true}
				person={company}
				isPersonCreating={isPersonCreating}
				onBack={() => {
					setCurrentStep(EPaymentSteps.INFORMATION);
					setCurrentStepNumber(EPaymentStepsNumber.INFORMATION);
				}}
				onNext={company => {
					handleAccountUpdate(company);
					handleStepStatus(StepsId.REPRESENTATIVES, true);
					setCurrentStep(EPaymentSteps.SIGNERS);
					setCurrentStepNumber(EPaymentStepsNumber.SIGNERS);
				}}
			/>
		),
		[EPaymentSteps.SIGNERS]: (
			<EscrowNaturalPersonCreationSigners
				relatedParts={accountRelatedParts}
				isLoading={isPending}
				onBack={() => {
					setCurrentStep(EPaymentSteps.REPRESENTATIVES);
					handleStepStatus(StepsId.SIGNERS, false);
					setCurrentStepNumber(EPaymentStepsNumber.REPRESENTATIVES);
				}}
				onNext={onNext}
				handleStepStatus={handleStepStatus}
				updateRelatedPartsSigners={updateRelatedPartsSigners}
			/>
		),
		[EPaymentSteps.BENEFICIARY]: (
			<EscrowCreationDestination
				accountId={accountId || ''}
				onFinish={() => navigate(EscrowRoutes.ACCOUNT_BASE)}
			/>
		),
	};

	return (
		<>
			<PageHeader>
				<Heading1>{`Criar Conta Livre PF`}</Heading1>
				<PageActions>
					<Steps>{`Etapa ${currentStepNumber}/4`}</Steps>
				</PageActions>
			</PageHeader>
			{contextHolder}
			<StepsWrapper>
				<div
					style={{
						minHeight: 'calc(100vh - 200px)',
					}}
				>
					<EscrowSteps
						selectedStep={currentStepNumber}
						steps={[
							{
								title: 'Dados do titular',
								completed:
									stepsStatus[StepsId.PERSONAL_INFORMATION]
										.completed,
							},
							{
								title: 'Procurador',
								completed:
									stepsStatus[StepsId.REPRESENTATIVES]
										.completed,
							},
							{
								title: 'Assinantes',
								completed:
									stepsStatus[StepsId.SIGNERS].completed,
							},
							{
								title: 'Contas beneficiárias',
								completed:
									stepsStatus[StepsId.DESTINATION].completed,
							},
						]}
					/>
				</div>
				<PageWrapper>{steps[currentStep]}</PageWrapper>
			</StepsWrapper>
		</>
	);
};

export default PaymentAccountPersonCreationPage;
