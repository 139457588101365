import { createContext, useContext, useState } from 'react';

interface IEscrowAccountsContext {
	isEditingAccount?: boolean;
	setIsEditingAccount: (isEditingAccount: boolean) => void;
}

const EscrowAccountsContext = createContext<IEscrowAccountsContext>(
	{} as IEscrowAccountsContext,
);

const EscrowProvider = ({ children }: IChildrenProps) => {
	const [isEditingAccount, setIsEditingAccount] = useState(false);

	return (
		<EscrowAccountsContext.Provider
			value={{
				isEditingAccount,
				setIsEditingAccount,
			}}
		>
			{children}
		</EscrowAccountsContext.Provider>
	);
};

const useEscrowAccountsContext = () => {
	const context = useContext(EscrowAccountsContext);
	return context;
};

export { useEscrowAccountsContext, EscrowProvider as default };
