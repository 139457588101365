import { CollapseProps } from 'antd';
import { Collapse as CollapseAnt, CollapseExpander } from './styles';
import { ExpanderIcon } from 'components/Icons/Expander';

interface ICollapseProps extends CollapseProps {
	noPadding?: boolean;
}
const Collapse = (props: ICollapseProps) => {
	return (
		<CollapseAnt
			expandIconPosition="end"
			expandIcon={({ isActive }) => (
				<CollapseExpander isActive={isActive}>
					<ExpanderIcon />
				</CollapseExpander>
			)}
			{...props}
		/>
	);
};

export default Collapse;
