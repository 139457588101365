import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'network/query';

import { CurrencyInput } from 'components/CurrencyInput';
import { LockIcon } from 'components/Icons/Lock';
import { PageWrapper } from 'components/PageWrapper';
import { SplashScreen } from 'components/SplashScreen';
import { TextL } from 'components/Text';
import BalanceTable from './BalanceTable/BalanceTable';
import ManagementModal from './ManagementModal/ManagementModal';
import SuccessModal from 'components/SuccessModal/SuccessModal';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';

import { BalanceForm } from './styles';
import { Button, Col, Divider, Flex, Row, notification } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import theme from 'theme/theme';

import { AccountsService } from 'modules/escrow/services';
import { getManagementBreadcrumb } from 'modules/management/constants/routes';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { formatCurrency } from 'helpers/normalizers';

import { AccessPermission, AccessType } from 'types/Access';
import { ApiError } from 'types/ApiError';
import { ESortingPage, ISortingPage } from 'types/List';
import { PaginationInfo } from 'types/List';
import {
	IBalanceManagementHistory,
	IBalanceBlockPayload,
	TBalanceInfoModal,
	IBalanceUnblockPayload,
} from './types';

const BalanceManagementPage = () => {
	const { id } = useParams();
	const { type, permissions } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const { setBreadcrumb } = useBreadcrumb();

	const [amountInput, setAmountInput] = useState<number>(0);
	const [isOpenManagementModal, setIsOpenManagementModal] =
		useState<boolean>(false);
	const [isOpenSuccessModal, setIsOpenSuccessModal] =
		useState<boolean>(false);
	const [balanceManagementValues, setBalanceManagementValues] =
		useState<TBalanceInfoModal>();
	const [sorting, setSorting] = useState<ISortingPage>({
		order: ESortingPage.DESC,
		sort: 'created_at',
	});
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});

	const closeModalHandler = () => {
		setIsOpenManagementModal(false);
		setBalanceManagementValues({} as TBalanceInfoModal);
		setIsOpenSuccessModal(false);
	};

	const unblockHandler = (balance: IBalanceManagementHistory) => {
		setBalanceManagementValues({
			amount: balance.blocked_amount,
			id: balance.id,
		});

		setIsOpenManagementModal(true);
	};

	const blockHandler = useCallback(() => {
		setBalanceManagementValues({
			amount: amountInput,
			id: undefined,
		});

		setIsOpenManagementModal(true);
	}, [amountInput]);

	const updateBalanceHandler = (
		reason: string,
		balance: TBalanceInfoModal,
	) => {
		setIsOpenManagementModal(false);

		if (balance?.id) {
			return deleteBlockedBalance({
				remove_reason: reason,
			});
		}

		return blockBalance({
			blocked_amount: balance.amount,
			block_reason: reason,
		});
	};

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: balanceData, isLoading: isLoadingBalanceList } = useQuery({
		queryKey: ['balanceRetention', id, paginationInfo, sorting],
		queryFn: () =>
			AccountsService.getBalanceRetention(id || '', {
				order: sorting.order,
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				sort: sorting.sort,
			}),
	});

	const { mutate: blockBalance, isPending: isLoadingBlockBalance } =
		useMutation<void, ApiError, IBalanceBlockPayload>({
			mutationFn: payload => {
				return AccountsService.createBalanceRetention(
					id || '',
					payload,
				);
			},
			onSuccess: () => {
				setIsOpenSuccessModal(true);

				queryClient.refetchQueries({
					queryKey: ['balanceRetention', id],
				});
			},
			onError: e => {
				closeModalHandler();
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
		});

	const { mutate: deleteBlockedBalance, isPending: isLoadingUnblockBalance } =
		useMutation<void, ApiError, IBalanceUnblockPayload>({
			mutationFn: payload => {
				return AccountsService.deleteBalanceRetention(
					id || '',
					balanceManagementValues?.id || '',
					payload,
				);
			},
			onSuccess: () => {
				setIsOpenSuccessModal(true);

				queryClient.refetchQueries({
					queryKey: ['balanceRetention', id],
				});
			},
			onError: e => {
				closeModalHandler();
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
		});

	useEffect(() => {
		setBreadcrumb(
			getManagementBreadcrumb('Gestão de saldo', data?.account, data?.id),
		);
	}, [setBreadcrumb, data?.account]);

	if (isLoading) {
		return <SplashScreen />;
	}

	return (
		<>
			{contextHolder}
			<PageHeaderWithButton title="Gestão de saldo" hasDivider />

			<PageWrapper isFluid={true}>
				<Flex
					align="center"
					justify="space-between"
					style={{ width: '100%', marginTop: '1rem' }}
				>
					<Flex>
						<TextL style={{ fontWeight: 'bold' }}>
							Saldo Bloqueado:{' '}
						</TextL>
						<TextL
							color={theme.dangerText}
							style={{ paddingLeft: '0.5rem' }}
						>
							{formatCurrency(balanceData?.amount_blocked)}
						</TextL>
					</Flex>
					<Flex>
						<TextL style={{ fontWeight: 'bold' }}>
							Saldo disponível para movimentação:{' '}
						</TextL>
						<TextL
							color={theme.success}
							style={{ paddingLeft: '0.5rem' }}
						>
							{formatCurrency(
								balanceData?.amount_available &&
									balanceData?.amount_available > 0
									? balanceData?.amount_available
									: 0,
							)}
						</TextL>
					</Flex>
				</Flex>
				<BalanceForm
					initialValues={{ amount: 0 }}
					layout="vertical"
					onFieldsChange={f => setAmountInput(f[0].value)}
					style={{ marginTop: '1.5rem' }}
				>
					{(type === AccessType.FINANCIAL_INSTITUTION ||
						permissions.includes(
							AccessPermission.CREATE_BALANCE_RETENTION,
						)) && (
						<Row gutter={24}>
							<Col span={8}>
								<FormItem name="amount" label="Bloquear saldo">
									<CurrencyInput placeholder="0,00" />
								</FormItem>
							</Col>
							<Col span={6}>
								<Button
									danger
									onClick={blockHandler}
									size="large"
									type="primary"
									style={{
										marginTop: '1.9rem',
									}}
								>
									Bloquear
									<LockIcon color={theme.white} />
								</Button>
							</Col>
						</Row>
					)}
					<Divider />
				</BalanceForm>

				<BalanceTable
					balanceData={balanceData?.paginated}
					paginationInfo={paginationInfo}
					unblock={unblockHandler}
					updatePaginationInfo={setPaginationInfo}
					updateSorting={setSorting}
					isLoading={
						isLoadingBalanceList ||
						isLoadingBlockBalance ||
						isLoadingUnblockBalance
					}
				/>

				{isOpenManagementModal && balanceManagementValues && (
					<ManagementModal
						accountId={data?.account}
						balanceManagementInfo={balanceManagementValues}
						closeModal={closeModalHandler}
						handleUpdateBalance={updateBalanceHandler}
					/>
				)}

				{isOpenSuccessModal && balanceManagementValues && (
					<SuccessModal
						closeModal={closeModalHandler}
						message={
							!!balanceManagementValues?.id
								? `Bloqueio no valor de ${formatCurrency(balanceManagementValues?.amount)} removido com sucesso!`
								: `${formatCurrency(amountInput)} foram bloqueados com sucesso!`
						}
						title={
							!!balanceManagementValues?.id
								? 'Bloqueio removido com sucesso'
								: 'Bloqueio de saldo efetuado com sucesso'
						}
					/>
				)}
			</PageWrapper>
		</>
	);
};

export default BalanceManagementPage;
