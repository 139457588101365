import { AxiosProgressEvent } from 'axios';
import { NetworkApi } from 'network';

export type UploadUrl = {
	key: string;
	download_url: string;
	upload_url: string;
};

export type UploadRequest = {
	file_name: string;
};

export class UploadService extends NetworkApi {
	public static getUploadUrl(uploadRequest: UploadRequest) {
		return this.post<UploadUrl, UploadRequest>(
			'/documents/upload',
			uploadRequest,
		);
	}

	public static async uploadFile(
		file: File,
		onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
	): Promise<UploadUrl> {
		const fileName = file.name.replace(/[^a-z0-9.]/gi, '_').toLowerCase();

		const uploadConfig = await this.getUploadUrl({
			file_name: fileName,
		});

		const formData = new FormData();
		formData.append('file', file);
		formData.append('type', file.type);

		await this.upload(
			uploadConfig.upload_url,
			formData,
			{
				'Content-Disposition': `attachment; filename="${fileName}"`,
			},
			onUploadProgress,
		);

		return uploadConfig;
	}
}
