import { AccountPermission, AccountStatus } from 'types/Account';
import { BusinessType } from 'types/Company';

export const AccountPermissionRestricted = [] as AccountPermission[];

export const AccountPermissionDescription = {
	[AccountPermission.CREATE_ACCOUNT]: 'Criar conta',
	[AccountPermission.CREATE_BENEFICIARY]: 'Criar beneficiário',
	[AccountPermission.VIEW_ACCOUNT]: 'Visualizar contas',
	[AccountPermission.POSTING_REVIEW]: 'Aprovar/reprovar lançamentos',
	[AccountPermission.POSTING_CREATE]: 'Realizar lançamentos',
	[AccountPermission.POSTING_VIEW]: 'Visualizar lançamentos',
	[AccountPermission.VIEW_WALLET]: 'Visualizar carteiras',
	[AccountPermission.CREATE_WALLET]: 'Criar carteiras',
	[AccountPermission.UPDATE_WALLET]: 'Alterar carteiras',
	[AccountPermission.ARCHIVE_WALLET]: 'Arquivar carteiras',
	[AccountPermission.CREATE_CHARGE]: 'Criar cobranças',
	[AccountPermission.CANCEL_CHARGE]: 'Cancelar cobranças',
	[AccountPermission.VIEW_CHARGE]: 'Visualizar cobranças',
	[AccountPermission.UPDATE_CHARGE]: 'Alterar cobranças',
	[AccountPermission.CREATE_BALANCE_RETENTION]: 'Criar bloqueios de saldo',
	[AccountPermission.REMOVE_BALANCE_RETENTION]: 'Remover bloqueios de saldo',
	[AccountPermission.VIEW_BALANCE_RETENTION]: 'Visualizar bloqueios de saldo',
};

export const AccountStatusDescription = {
	[AccountStatus.CREATING]: 'Criando',
	[AccountStatus.ERROR_CREATING]: 'Erro na criação',
	[AccountStatus.PENDING_AGREEMENT_SIGNING]: 'Pendente de assinatura',
	[AccountStatus.ACTIVE]: 'Ativa',
	[AccountStatus.BLOCKING]: 'Bloqueando',
	[AccountStatus.ERROR_BLOCKING]: 'Bloqueada por erro',
	[AccountStatus.UNBLOCKING]: 'Desbloqueando',
	[AccountStatus.ERROR_UNBLOCKING]: 'Erro ao desbloquear',
	[AccountStatus.BLOCKED]: 'Bloqueada',
	[AccountStatus.CANCELING]: 'Encerrando',
	[AccountStatus.ERROR_CANCELING]: 'Erro ao encerrar',
	[AccountStatus.CANCELED]: 'Encerrada',
	[AccountStatus.PENDING_ACTIVATION]: 'Pendente de ativação',
	[AccountStatus.WAITING_ANALYSIS]: 'Pendente de análise',
	[AccountStatus.IN_ANALYSIS]: 'Em análise',
	[AccountStatus.WAITING_UPDATES]: 'Aguardando informações',
	[AccountStatus.REFUSED]: 'Recusada',
};

export const AccountOwnerPermissions = [
	AccountPermission.VIEW_ACCOUNT,
	AccountPermission.POSTING_VIEW,
	AccountPermission.VIEW_WALLET,
];

export const AccountPermissionFromFundKey = {
	manager: [AccountPermission.VIEW_ACCOUNT, AccountPermission.POSTING_CREATE],
	securitizer: [
		AccountPermission.VIEW_ACCOUNT,
		AccountPermission.POSTING_REVIEW,
	],
	administrator: [AccountPermission.VIEW_ACCOUNT],
};

export const AccountRelationshipFromFundKey = {
	manager: BusinessType.MANAGER,
	securitizer: BusinessType.CUSTODIAN,
	administrator: BusinessType.ADMINISTRATOR,
};

export const AccountDocumentsList = [
	{
		label: 'Contrato de abertura',
		download_key: 'opening_agreement_url',
		upload_key: 'opening_agreement',
	},
	{
		label: 'Comprovante de encerramento',
		download_key: 'canceling_agreement_url',
		upload_key: 'canceling_agreement',
	},
];
