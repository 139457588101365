import { Button, Flex, Tooltip, notification } from 'antd';
import { useQuery } from '@tanstack/react-query';
import EscrowAccountsFilters from './EscrowAccountsFilters';
import { LuSearch } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { AccountContent, AccountStatus } from 'types/Account';
import { AccountsService } from 'modules/escrow/services';
import dayjs from 'dayjs';
import { AccountStatusDescription } from 'constants/account';
import { useCallback, useEffect, useState } from 'react';
import { Table } from 'components/Table';
import { EscrowBalance, FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { EscrowAccountsFiltersType } from './types';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { ESortingPage, PaginationInfo } from 'types/List';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useAccount } from 'modules/escrow/hooks/useAccount';
import { ETenantType, PersonType } from 'types/Company';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { EmptyFiltersResult } from 'components/EmptyFiltersResult';
import { TextM, TextS } from 'components/Text';
import { StarIcon } from 'components/Icons/Star';
import { sortTableHandler } from 'helpers/tables';
import {
	formatCurrency,
	normalizeBankAccount,
	normalizeCpfCnpj,
} from 'helpers/normalizers';

const EscrowAccounts = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [paginationInfo, updatePaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [api, contextHolder] = notification.useNotification();
	const { setBreadcrumb } = useBreadcrumb();
	const [visibleBalances, updateVisibleBalances] = useState<{
		[key: string]: number;
	}>({});
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [isFiltersOpen, toggleFiltersOpen] = useState<boolean>(false);
	const [filters, updateFilters] = useState<EscrowAccountsFiltersType>(
		{} as EscrowAccountsFiltersType,
	);

	const handleClearFilters = useCallback(() => {
		updateFilters({} as EscrowAccountsFiltersType);
	}, []);

	const { isBalanceLoading, getAccountBalance } = useAccount({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBalanceSuccess: (account, amount) => {
			updateVisibleBalances(prev => ({
				...prev,
				[account.id]: amount,
			}));
		},
	});

	const { data, isLoading } = useQuery({
		queryKey: ['accountsList', paginationInfo, filters, sorting],
		queryFn: () =>
			AccountsService.getAccounts({
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
				status: filters.status,
				search: filters.search,
				taxpayer_id: filters.taxpayer_id,
				account: filters.account,
				creator_name: filters.creator_name,
			}),
	});

	useEffect(() => {
		//cleaning breadcrumb
		setBreadcrumb(undefined);
	}, [setBreadcrumb]);

	const columns: ColumnsType<AccountContent> = [
		{
			title: 'Titular',
			sorter: true,
			key: 'company.name',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="bold">{`${account.person.name}`}</TextM>
					<TextS weight="normal" color={theme.textSecondary}>
						{normalizeCpfCnpj(account.person.taxpayer_id)}
					</TextS>
				</div>
			),
			width: '20%',
		},
		{
			title: 'Pessoa',
			sorter: true,
			key: 'company.person_type',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">{`${
						account.person.person_type === PersonType.LEGAL
							? 'PJ'
							: 'PF'
					}`}</TextM>
				</div>
			),
			width: '5%',
		},
		{
			title: 'Data de abertura',
			width: '15%',
			sorter: true,
			key: 'created_at',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="bold">{`${dayjs(account.created_at).format(
						'DD/MM/YYYY',
					)}`}</TextM>
					<TextS color={theme.textSecondary} weight="normal">
						{`às ${dayjs(account.created_at).format('HH:mm:ss')}`}
					</TextS>
				</div>
			),
		},
		{
			title: 'Criador da Conta',
			width: '15%',
			sorter: true,
			key: 'creator.fullName',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">{`${account.creator.full_name}`}</TextM>
				</div>
			),
		},
		{
			title: 'Nº da conta',
			width: '13%',
			sorter: true,
			key: 'account',
			render: (account: AccountContent) => (
				<Flex align="center">
					<div style={{ marginRight: '5px', width: '1.3rem' }}>
						{account.tenant_type === ETenantType.PRIME && (
							<StarIcon size={20} />
						)}
					</div>
					<TextM weight="normal">
						{account.account
							? normalizeBankAccount(account.account)
							: '-'}
					</TextM>
				</Flex>
			),
		},
		{
			title: 'Status',
			width: '14%',
			render: (account: AccountContent) => {
				if (
					typeof account.error === 'string' &&
					[
						AccountStatus.ERROR_CREATING,
						AccountStatus.ERROR_BLOCKING,
						AccountStatus.ERROR_CANCELING,
						AccountStatus.ERROR_UNBLOCKING,
					].includes(account.status)
				)
					return (
						<Tooltip title={account.error}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px',
								}}
							>
								<TextM weight="normal">
									{AccountStatusDescription[account.status]}
								</TextM>
								<IoMdInformationCircleOutline size={16} />
							</div>
						</Tooltip>
					);
				return (
					<TextM weight="normal">
						{AccountStatusDescription[account.status]}
					</TextM>
				);
			},
		},
		{
			title: 'Saldo',
			width: '14%',
			render: (account: AccountContent) => {
				if (
					[AccountStatus.BLOCKED, AccountStatus.ACTIVE].includes(
						account.status,
					)
				) {
					const isVisible =
						typeof visibleBalances[account.id] === 'number';
					return (
						<EscrowBalance isVisible={isVisible}>
							<TextM weight="normal">
								{isVisible
									? formatCurrency(
											visibleBalances[account.id],
										)
									: '*******'}
							</TextM>
							<Button
								type="link"
								size="large"
								icon={isVisible ? <BsEyeSlash /> : <BsEye />}
								loading={isBalanceLoading === account.id}
								onClick={() => {
									if (!isVisible) {
										getAccountBalance(account);
										return;
									}
									updateVisibleBalances(prev => {
										delete prev[account.id];
										return { ...prev };
									});
								}}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							/>
						</EscrowBalance>
					);
				}
				return '-';
			},
		},
		{
			key: 'action',
			width: '15%',
			align: 'center',
			render: (account: AccountContent) => {
				return (
					<div
						style={{
							fontSize: '1.3rem',
						}}
					>
						<HiOutlineCog6Tooth
							onClick={() => {
								if (
									account.status ===
									AccountStatus.PENDING_ACTIVATION
								) {
									api.error({
										description:
											'A conta está pendente de ativação.',
										message:
											'A conta precisa ter sido ativada para ser gerenciada.',
									});

									return;
								}

								navigate(`/management/${account.id}/details`);
							}}
							style={{
								color: theme.primary,
							}}
						/>
					</div>
				);
			},
		},
	];

	return (
		<>
			{contextHolder}
			<FiltersContainer>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => toggleFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={!Object.keys(filters).length}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</FiltersContainer>
			{data?.content?.length === 0 &&
			Object.values(filters).some(item => item !== undefined) ? (
				<EmptyFiltersResult
					title="Ops, sua busca não obteve nenhum resultado!"
					description={`Preencha os filtros avançados novamente ou limpe os filtros${'\n'}para encontrar o que você precisa.`}
				/>
			) : (
				<Table
					columns={columns}
					rowKey={record => record.id}
					dataSource={data?.content}
					loading={isLoading}
					onChange={(_, __, sorter) =>
						sortTableHandler(sorter, setSorting)
					}
					pagination={{
						total: data?.total_elements || 0,
						showTotal(total) {
							const currentSize =
								paginationInfo.currentPage *
								paginationInfo.pageSize;
							return `${currentSize > total ? total : currentSize} de ${total}`;
						},
						pageSizeOptions: ['10', '30', '60', '90'],
						showSizeChanger: true,
						pageSize: paginationInfo.pageSize,
						current: paginationInfo.currentPage,
						onChange(page, pageSize) {
							updatePaginationInfo({
								currentPage: page,
								pageSize,
							});
						},
					}}
				/>
			)}

			<EscrowAccountsFilters
				isOpen={isFiltersOpen}
				onApply={filters => {
					updateFilters(filters);
					toggleFiltersOpen(false);
				}}
				onClose={() => toggleFiltersOpen(false)}
				filters={filters}
			/>
		</>
	);
};

export default EscrowAccounts;
