import styled from 'styled-components';
import { Descriptions as DescriptionsAnt, Flex } from 'antd';

export const DescriptionsHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 20px 8px;
`;

export const DescriptionsContainer = styled.div<{
	borderless?: boolean;
}>`
	border: ${p => (p.borderless ? 'none' : `1px solid ${p.theme.border}`)};
	border-radius: 6px;
	padding-bottom: 8px;
	margin: 0 auto;

	${DescriptionsHeader} {
		padding: ${p => (p.borderless ? '4px 20px 8px ' : '16px 20px 8px')};
	}
`;

export const DescriptionsActions = styled.div`
	display: flex;
	gap: 2px;
`;

export const Descriptions = styled(DescriptionsAnt)`
	.ant-descriptions-view {
		border: none !important;
		padding: 0px 8px !important;
	}

	.ant-descriptions-item-label {
		background-color: transparent !important;
		border-inline-end: none !important;
		padding: 12px 16px;
	}

	.ant-descriptions-item-content {
		padding: 8px 16px !important;
		border-inline-end: none !important;
		color: ${p => p.theme.text} !important;
	}

	.ant-descriptions-row {
		border-bottom: none !important;
	}
`;

export const DescriptionsRowItem = styled(Flex)`
	width: 100%;
	justify-content: space-between;
	align-items: center;

	.ant-descriptions {
		width: 100%;
	}

	.ant-descriptions-view {
		padding: 0 !important;
	}
`;

export const GridContent = styled.div<{ contentLength?: number }>`
	width: 100%;
	display: grid;
	grid-template-columns:
		repeat(${p => (p.contentLength && p.contentLength - 1) ?? 2}, 1fr)
		0.1fr;
	grid-gap: 8px;
`;
