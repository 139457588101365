import styled from 'styled-components';

export const StepsContainer = styled.div`
	display: flex;
	padding: 0 1rem;

	.steps {
		max-width: 200px;
		margin-top: 0.5rem;
		padding: 2rem 0;
	}
`;

export const StepContainer = styled.div`
	width: 100%;
	padding-bottom: 2rem;
	min-height: 450px;
	position: relative;
`;

export const StepHeader = styled.div`
	padding-bottom: 1rem;
`;

export const StepFooter = styled.div`
	padding-top: 0.6rem;
	justify-content: flex-end;
	display: flex;
	gap: 0.6rem;
	position: absolute;
	right: 0;
	bottom: 0;
`;
