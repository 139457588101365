import { useNavigate } from 'react-router-dom';
import { Row } from 'antd';
import { MenuButton } from './components/MenuButton';
import { CoinsIcon } from 'components/Icons/Coins';
import { ExchangeIcon } from 'components/Icons/Exchange';
import { SafeIcon } from 'components/Icons/Safe';
import { Account } from 'types/Account';
import { ListConfigIcon } from 'components/Icons/ListConfig';

interface IEscrowDetailsInformationProps {
	account: Account;
}
const EscrowDetailsInformation: React.FC<IEscrowDetailsInformationProps> = ({
	account,
}) => {
	const navigate = useNavigate();

	return (
		<>
			<Row gutter={[16, 16]} style={{ margin: '1rem 1rem 0 0' }}>
				<MenuButton
					icon={<ListConfigIcon />}
					title="Configurações da conta"
					onClick={() =>
						navigate(
							`/management/${account.id}/details/account-config`,
						)
					}
				/>
			</Row>
			<Row gutter={[16, 16]} style={{ margin: '1rem 1rem 0 0' }}>
				<MenuButton
					icon={<SafeIcon />}
					title="Configurações de retenção"
					onClick={() =>
						navigate(
							`/management/${account.id}/details/deposit-management`,
						)
					}
				/>
			</Row>
			<Row gutter={[16, 16]} style={{ margin: '1rem 1rem 0 0' }}>
				<MenuButton
					icon={<ExchangeIcon />}
					title="Regras de transferências"
					onClick={() =>
						navigate(
							`/management/${account.id}/details/transfer-rule`,
						)
					}
				/>
			</Row>
			<Row gutter={[16, 16]} style={{ margin: '1rem 1rem 0 0' }}>
				<MenuButton
					icon={<CoinsIcon />}
					title="Gestão de saldo"
					onClick={() =>
						navigate(
							`/management/${account.id}/details/balance-management`,
						)
					}
				/>
			</Row>
		</>
	);
};

export default EscrowDetailsInformation;
