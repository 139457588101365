import React from 'react';
import {
	ForgotPasswordBack,
	ForgotPasswordSuccessContainer,
	ForgotPasswordSuccessText,
} from './styles';

interface IForgotPasswordSuccessProps {
	username: string;
}
const ForgotPasswordSuccess: React.FC<IForgotPasswordSuccessProps> = ({
	username,
}) => {
	return (
		<ForgotPasswordSuccessContainer>
			<ForgotPasswordSuccessText>
				Foi enviado para o e-mail <strong>{username}</strong> um link
				para redefinir a senha.
			</ForgotPasswordSuccessText>
			<ForgotPasswordBack to="/login">
				Voltar para o login
			</ForgotPasswordBack>
		</ForgotPasswordSuccessContainer>
	);
};

export default ForgotPasswordSuccess;
