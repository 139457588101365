import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { DatePicker } from 'components/DatePicker';
import { StepContainer, StepFooter, StepHeader } from '../styles';
import { Heading2 } from 'components/Heading2';
import { TextS } from 'components/Text';
import { normalizeCpf, normalizePhone } from 'helpers/normalizers';
import dayjs from 'dayjs';
import { Representative, RepresentativeRole } from 'types/Representative';
import { RepresentativeRoleDescription } from 'constants/representatives';
import { formatPhoneFromObject } from 'helpers/formaters';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import { cpfRule, fullNameRule, minDateRule } from 'helpers/rules';

interface IInformationsStepProps {
	representative?: Representative;
	isLegalRepresentative?: boolean;
	onNext: (representative: Representative) => void;
}
const InformationsStep = ({
	representative,
	isLegalRepresentative,
	onNext,
}: IInformationsStepProps) => {
	const hasRepresentative =
		typeof representative !== 'undefined' &&
		Object.keys(representative).length > 0;
	const [formValid, setFormValid] = useState(hasRepresentative);

	return (
		<StepContainer>
			<StepHeader>
				<Heading2>Informações</Heading2>
				<TextS weight="normal">{`Informações sobre o ${
					isLegalRepresentative ? 'procurador' : 'representante'
				}`}</TextS>
			</StepHeader>
			<Form
				style={{ width: '100%' }}
				initialValues={{
					birth_date:
						representative?.birth_date &&
						dayjs(representative.birth_date, 'YYYY-MM-DD'),
					email_address: representative?.email_address,
					full_name: representative?.full_name,
					mother_name: representative?.mother_name,
					pep: representative?.pep,
					phone:
						representative?.phone &&
						formatPhoneFromObject(representative.phone),
					role: representative?.role,
					sign_contract: representative?.sign_contract,
					taxpayer_id:
						representative?.taxpayer_id &&
						normalizeCpf(representative.taxpayer_id),
				}}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched && !hasRepresentative) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
				}}
				onFinish={values => {
					onNext({
						role: values.role,
						birth_date: values.birth_date.format('YYYY-MM-DD'),
						email_address: values.email_address,
						full_name: values.full_name,
						mother_name: values.mother_name,
						pep: values.pep,
						phone: serializePhoneObject(values.phone),
						sign_contract: values.sign_contract,
						taxpayer_id: serializeOnlyNumbers(values.taxpayer_id),
					} as Representative);
				}}
			>
				<Row gutter={[16, 16]}>
					<Col span={7}>
						<Form.Item
							name="taxpayer_id"
							label="CPF"
							normalize={e => normalizeCpf(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								cpfRule,
							]}
						>
							<Input placeholder="000.000.000-00" />
						</Form.Item>
					</Col>
					<Col span={17}>
						<Form.Item
							name="full_name"
							label="Nome completo"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								fullNameRule('full_name'),
							]}
						>
							<Input placeholder="Ex.: Maria da Silva" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={7}>
						<Form.Item
							name="birth_date"
							label="Data de nascimento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								minDateRule,
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</Form.Item>
					</Col>
					<Col span={17}>
						<Form.Item
							name="mother_name"
							label="Nome da mãe"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								fullNameRule('mother_name'),
							]}
						>
							<Input placeholder="Ex.: Maria da Silva" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={7}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="role"
							label="Cargo"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={Object.keys(
									RepresentativeRoleDescription,
								).map(key => ({
									label: RepresentativeRoleDescription[
										key as unknown as RepresentativeRole
									],
									value: key,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="sign_contract"
							label="Assina contrato?"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={[
									{ label: 'Sim', value: true },
									{ label: 'Não', value: false },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={10}>
						<Form.Item
							name="pep"
							label="Pessoa Politicamente Exposta (PEP)"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={[
									{ label: 'Sim', value: true },
									{ label: 'Não', value: false },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
				<StepFooter>
					<Button
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						Avançar
					</Button>
				</StepFooter>
			</Form>
		</StepContainer>
	);
};

export default InformationsStep;
