import React from 'react';

export const KeyIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.96507 10.3563L15.5059 3.81543L16.6842 4.99376L15.5059 6.17293L17.5676 8.2346L16.3892 9.41376L14.3267 7.35126L13.1484 8.5296L14.9159 10.2971L13.7376 11.4763L11.9701 9.70793L10.1434 11.5346C10.7104 12.3963 10.9379 13.4376 10.7817 14.4572C10.6255 15.4768 10.0968 16.4023 9.2978 17.0547C8.49882 17.7071 7.48631 18.0401 6.45605 17.9893C5.4258 17.9385 4.45097 17.5075 3.72007 16.7796C2.9876 16.0499 2.5526 15.0739 2.49962 14.0413C2.44664 13.0087 2.77947 11.9933 3.43342 11.1924C4.08736 10.3916 5.01571 9.86246 6.03804 9.70793C7.06036 9.55339 8.10363 9.78447 8.96507 10.3563ZM8.43424 15.6004C8.677 15.3708 8.87127 15.0948 9.00556 14.7889C9.13984 14.4829 9.21143 14.1531 9.21607 13.819C9.22072 13.4848 9.15834 13.1532 9.03261 12.8436C8.90688 12.534 8.72036 12.2527 8.48408 12.0164C8.2478 11.7801 7.96654 11.5936 7.65694 11.4679C7.34734 11.3422 7.01567 11.2798 6.68155 11.2844C6.34743 11.2891 6.01763 11.3607 5.71164 11.4949C5.40565 11.6292 5.12969 11.8235 4.90007 12.0663C4.44468 12.5378 4.19269 13.1693 4.19839 13.8248C4.20408 14.4803 4.46701 15.1073 4.93053 15.5708C5.39405 16.0343 6.02108 16.2973 6.67657 16.3029C7.33206 16.3086 7.96357 16.0567 8.43507 15.6013L8.43424 15.6004Z"
			fill={color ?? 'white'}
		/>
	</svg>
);
