import { TextM } from 'components/Text';
import { CollapseBody, CollapseHeader, CollapseWrapper } from './styles';
import { CustomSwitch } from 'components/CustomSwitch';
import { SwitchSize, Variant } from 'components/CustomSwitch/CustomSwitch';

interface ICollapseProps {
	isOpen: boolean;
	children?: React.ReactNode;
	title?: string;
	onToggle?: () => void;
}

const Collapse = ({ isOpen, title, onToggle, children }: ICollapseProps) => {
	return (
		<CollapseWrapper>
			<CollapseHeader>
				<TextM>{title}</TextM>
				<div onClick={onToggle}>
					<CustomSwitch
						checked={isOpen}
						handleChange={() => {}}
						variant={Variant.SECONDARY}
						size={SwitchSize.SMALL}
					/>
				</div>
			</CollapseHeader>
			<CollapseBody isOpen={isOpen}>
				{isOpen ? children : null}
			</CollapseBody>
		</CollapseWrapper>
	);
};

export default Collapse;
