import { BackgroundModalData, ModalTextArea, StepsWrapper } from './styles';
import { useMemo, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { TextM, TextS } from 'components/Text';
import { PaymentType } from 'types/Posting';
import { useTheme } from 'styled-components';
import { Account } from 'types/Account';
import {
	formatCurrency,
	normalizeBankAccount,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import {
	PaymentTypeDescription,
	PostingClientFinalityDescription,
} from 'constants/posting';
import dayjs from 'dayjs';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission } from 'types/Access';
import { PostingDtoForm } from './PostingCreation';
import { ForwardButton } from './components/ForwardButton';

interface IPostingCreationConfirmationProps {
	isLoading: boolean;
	posting: PostingDtoForm;
	account: Account;
	onClose: () => void;
	onConfirm: (description?: string, isApproved?: boolean) => void;
}
const PostingCreationConfirmation = ({
	isLoading,
	posting,
	account,
	onClose,
	onConfirm,
}: IPostingCreationConfirmationProps) => {
	const theme = useTheme();
	const [comment, updateComment] = useState('');
	const [isApproved, updateIsApproved] = useState(false);
	const { permissions } = useCurrentProfile();

	const canApprove = useMemo(() => {
		const postingCreatingPermission = permissions?.includes(
			AccessPermission.POSTING_CREATE,
		);

		const postingReviewPermission = permissions?.includes(
			AccessPermission.POSTING_REVIEW,
		);

		const isPix = posting.type === PaymentType.PIX;

		return postingCreatingPermission && postingReviewPermission && isPix;
	}, [permissions]);

	const accountDestination = useMemo(() => {
		return account.destinations.find(
			destination => destination.id === posting.account_destination_id,
		);
	}, [account, posting]);

	return (
		<StepsWrapper>
			<div>
				<TextM
					style={{
						fontSize: '1.2rem',
					}}
				>
					Você tem certeza que deseja realizar esta solicitação?
				</TextM>

				<BackgroundModalData
					style={{
						marginTop: '1rem',
					}}
				>
					<Row
						style={{ margin: '1rem 0.6rem' }}
						justify="space-between"
					>
						<Col span={11}>
							<TextM
								weight="bold"
								style={{ marginBottom: '2px' }}
							>
								Origem:
							</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{account.person.name}
							</TextS>
							<TextS color={theme.textSecondary} weight={400}>
								{`CNPJ:
                ${normalizeCpfCnpj(account?.person?.taxpayer_id ?? '')}`}
							</TextS>
							<TextS color={theme.textSecondary} weight={400}>
								{`Conta: ${
									account.account
										? normalizeBankAccount(account.account)
										: '-'
								}`}
							</TextS>
						</Col>
						<Col span={11}>
							<TextM
								weight="bold"
								style={{ marginBottom: '2px' }}
							>
								Destino:
							</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{accountDestination?.name}
							</TextS>
							<TextS color={theme.textSecondary} weight={400}>
								{`CNPJ:
                ${normalizeCpfCnpj(accountDestination?.taxpayer_id ?? '')}`}
							</TextS>
							<TextS color={theme.textSecondary} weight={400}>
								{`Conta: ${
									accountDestination?.account
										? normalizeBankAccount(
												accountDestination?.account,
											)
										: '-'
								}`}
							</TextS>
						</Col>
					</Row>
					<Row
						style={{ margin: '1rem 0.6rem' }}
						justify="space-between"
					>
						<Col span={11}>
							<TextM
								weight="bold"
								style={{ marginBottom: '2px' }}
							>
								Tipo de transferência:
							</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{
									PaymentTypeDescription[
										posting.type as PaymentType
									]
								}
							</TextS>
						</Col>
						{posting.type === PaymentType.PIX ? (
							<Col span={11}>
								<TextM
									weight="bold"
									style={{ marginBottom: '2px' }}
								>
									Data da transferência:
								</TextM>
								<TextS color={theme.textSecondary} weight={400}>
									{dayjs(
										posting.scheduled_date,
										'YYYY-MM-DD',
									).format('DD/MM/YYYY')}
								</TextS>
							</Col>
						) : (
							<Col span={11}>
								<TextM
									weight="bold"
									style={{ marginBottom: '2px' }}
								>
									Finalidade:
								</TextM>
								<TextS color={theme.textSecondary} weight={400}>
									{posting.clientFinality
										? PostingClientFinalityDescription[
												posting.clientFinality
											]
										: '-'}
								</TextS>
							</Col>
						)}
					</Row>
					<Row
						style={{ margin: '1rem 0.6rem' }}
						justify="space-between"
					>
						{posting.type === PaymentType.TED && (
							<Col span={11}>
								<TextM
									weight="bold"
									style={{ marginBottom: '2px' }}
								>
									Data da transferência:
								</TextM>
								<TextS color={theme.textSecondary} weight={400}>
									{dayjs(
										posting.scheduled_date,
										'YYYY-MM-DD',
									).format('DD/MM/YYYY')}
								</TextS>
							</Col>
						)}
						<Col span={11}>
							<TextM
								weight="bold"
								style={{ marginBottom: '2px' }}
							>
								Valor:
							</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{formatCurrency(posting.amount)}
							</TextS>
						</Col>
					</Row>
				</BackgroundModalData>

				<Row style={{ margin: '1.4rem 0 1rem' }}>
					<TextM>Comentário (Opcional)</TextM>
				</Row>
				<ModalTextArea
					rows={3}
					placeholder="Digite aqui"
					maxLength={220}
					value={comment}
					onChange={e => updateComment(e.target.value)}
				/>
			</div>
			<div>
				<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
					<Col span={24}>
						<ForwardButton
							text="Realizar solicitação"
							onClick={() =>
								onConfirm(comment, isApproved || canApprove)
							}
						/>
					</Col>
				</Row>
				<Row
					style={{
						marginTop: '1rem',
					}}
				>
					<Button
						type="default"
						onClick={() => {
							onClose();
						}}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							border: 'none',
							boxShadow: 'none',
							color: theme.primary,
							width: '100%',
						}}
					>
						<TextS
							style={{
								color: theme.primary,
							}}
						>
							Cancelar
						</TextS>
					</Button>
				</Row>
			</div>
		</StepsWrapper>
	);
};

export default PostingCreationConfirmation;
