import styled from 'styled-components';

export const UploaderAreaContainer = styled.div<{
	isInvalid: boolean;
	isDisabled?: boolean;
}>`
	width: 100%;
	margin: 1rem 0 0.6rem;
	border: 1px dashed
		${({ isInvalid, isDisabled, theme }) =>
			isInvalid ? theme.error : isDisabled ? 'gray' : theme.primary};
	background-color: ${({ theme }) => theme.backgroundVariant};
	border-radius: 5px;
	opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

export const UploaderAreaContent = styled.div`
	width: 100%;
	min-height: 200px;
	padding: 1rem;
	display: flex;
	justify-content: center;
	cursor: pointer;

	input {
		display: none;
	}
`;

export const UploaderAreaModalContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 0 1rem;
`;

export const Spinner = styled.span`
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	animation: spin 1s linear infinite;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
`;
