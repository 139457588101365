import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

export interface ForwardButtonContainerProps extends ButtonProps {}

export const ForwardButtonContainer = styled(
	Button,
)<ForwardButtonContainerProps>`
	width: 100%;
	padding: 1rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	min-height: 45px;

	p {
		width: 100%;
		text-align: center;
		color: ${({ theme, type }) =>
			type === 'primary' ? theme.white : theme.primary};
	}

	svg {
		color: ${({ theme }) => theme.white};
	}

	&:disabled {
		background-color: ${({ theme }) => theme.primaryOpacity};
		border-color: ${({ theme }) => theme.primaryOpacity};
		p {
			color: ${({ theme }) => theme.primary};
			filter: opacity(0.4);
		}

		svg {
			color: ${({ theme }) => theme.primary};
			filter: opacity(0.4);
		}
	}
`;
