import React, { useEffect } from 'react';
import { ProfileContainer } from './styles';
import ProfileSelector from './ProfileSelector';
import { useAuthLogo } from 'modules/core/context/AppContext';
import { Heading2 } from 'components/Heading2';

const ProfilePage = () => {
	const { showAuthLogo } = useAuthLogo();

	useEffect(() => showAuthLogo(), []);

	return (
		<ProfileContainer>
			<Heading2>Selecione seu acesso</Heading2>
			<ProfileSelector />
		</ProfileContainer>
	);
};

export default ProfilePage;
