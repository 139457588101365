import { Form } from 'antd';
import styled from 'styled-components';
import { EscrowAccountsFiltersType } from './types';

export const FiltersTitle = styled.div`
	display: flex;
	p {
		padding-left: 0.3rem;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
`;

export const FiltersForm = styled(Form<EscrowAccountsFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const FiltersFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}
`;

export const DropdownIcon = styled.div`
	display: flex;
	align-items: center;
	svg {
		width: 18px;
		height: 18px;
	}
`;

export const EscrowBalance = styled.div<{ isVisible?: boolean }>`
	display: flex;
	align-items: center;
	gap: 0.1rem;

	p {
		letter-spacing: ${p => (p.isVisible ? 'none' : '0.1rem')};
		padding-top: ${p => (p.isVisible ? 'none' : '0.3rem')};
	}
`;
