export const BreadcrumbSeparatorIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_10302_1902)">
			<path
				d="M8.70723 8.07374L5.40723 4.77374L6.34989 3.83107L10.5926 8.07374L6.34989 12.3164L5.40723 11.3737L8.70723 8.07374Z"
				fill="#9390A3"
			/>
		</g>
		<defs>
			<clipPath id="clip0_10302_1902">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
