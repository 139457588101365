import { Button, Col, Input, Row } from 'antd';
import { EscrowAccountsFiltersType } from './types';
import { useCallback, useEffect } from 'react';
import { FiltersForm, FiltersFormItem } from './styles';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import { LuSearch } from 'react-icons/lu';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { cpfCnpjRule } from 'helpers/rules';

interface IEscrowAccountsProps {
	isOpen: boolean;
	filters?: EscrowAccountsFiltersType;
	onClose: () => void;
	onApply: (filters: EscrowAccountsFiltersType) => void;
}
const EscrowAccounts = ({
	isOpen,
	filters,
	onApply,
	onClose,
}: IEscrowAccountsProps) => {
	const [form] = FiltersForm.useForm();

	const onFinish = useCallback(
		(values: EscrowAccountsFiltersType) => {
			onApply({
				...values,
				taxpayer_id:
					values.taxpayer_id && values.taxpayer_id.length > 0
						? serializeOnlyNumbers(values.taxpayer_id)
						: undefined,
				account:
					values.account && values.account.length > 0
						? serializeOnlyNumbers(values.account)
						: undefined,
				search:
					values.search && values.search.length > 0
						? values.search
						: undefined,
				creator_name:
					values.creator_name && values.creator_name.length > 0
						? values.creator_name
						: undefined,
			} as EscrowAccountsFiltersType);
		},
		[onApply],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id &&
					normalizeCpfCnpj(filters?.taxpayer_id),
				account: filters?.account,
				status: filters?.status,
				search: filters?.search,
				creator_name: filters?.creator_name,
			});
		}
	}, [isOpen, form, filters]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCpfCnpj(filters?.taxpayer_id),
					account: filters?.account,
					status: filters?.status,
					search: filters?.search,
				}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="taxpayer_id"
							label="CPF / CNPJ"
							normalize={value => normalizeCpfCnpj(value)}
							rules={[cpfCnpjRule]}
						>
							<Input
								placeholder="Digite aqui"
								prefix={<BsPerson />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="search" label="Titular">
							<Input
								placeholder="Digite aqui"
								prefix={<BsPerson />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem label="Conta" name="account">
							<Input
								placeholder="Digite aqui"
								prefix={<MdOutlineAccountBalanceWallet />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
};

export default EscrowAccounts;
