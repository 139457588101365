import React from 'react';

export const CoinsIcon: React.FC<{ color?: string; size?: string }> = ({
	color,
	size,
}) => (
	<svg
		width={size ? size : '21'}
		height={size ? size : '21'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.0049 2.00275C18.4232 2.00275 22.0049 5.58447 22.0049 10.0027C22.0049 13.2474 20.0733 16.0408 17.2973 17.296C16.0422 20.0717 13.249 22.0027 10.0049 22.0027C5.5866 22.0027 2.00488 18.421 2.00488 14.0027C2.00488 10.7586 3.9359 7.96548 6.71122 6.71006C7.96681 3.93431 10.7603 2.00275 14.0049 2.00275ZM10.0049 8.00275C6.69117 8.00275 4.00488 10.689 4.00488 14.0027C4.00488 17.3165 6.69117 20.0027 10.0049 20.0027C13.3186 20.0027 16.0049 17.3165 16.0049 14.0027C16.0049 10.689 13.3186 8.00275 10.0049 8.00275ZM11.0049 9.00275V10.0027H13.0049V12.0027H9.00488C8.72874 12.0027 8.50488 12.2266 8.50488 12.5027C8.50488 12.7482 8.68176 12.9524 8.91501 12.9947L9.00488 13.0027H11.0049C12.3856 13.0027 13.5049 14.122 13.5049 15.5027C13.5049 16.8835 12.3856 18.0027 11.0049 18.0027V19.0027H9.00488V18.0027H7.00488V16.0027H11.0049C11.281 16.0027 11.5049 15.7789 11.5049 15.5027C11.5049 15.2573 11.328 15.0531 11.0948 15.0108L11.0049 15.0027H9.00488C7.62417 15.0027 6.50488 13.8835 6.50488 12.5027C6.50488 11.122 7.62417 10.0027 9.00488 10.0027V9.00275H11.0049ZM14.0049 4.00275C12.2214 4.00275 10.6196 4.78091 9.52064 6.01623C9.68133 6.00758 9.84254 6.00275 10.0049 6.00275C14.4232 6.00275 18.0049 9.58447 18.0049 14.0027C18.0049 14.1654 18 14.327 17.9905 14.4872C19.2265 13.3885 20.0049 11.7865 20.0049 10.0027C20.0049 6.68904 17.3186 4.00275 14.0049 4.00275Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
