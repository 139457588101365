import { Button, Col, Form, Input, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CustomDrawer } from 'components/CustomDrawer';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { cpfCnpjRule } from 'helpers/rules';
import { useEffect, useState } from 'react';
import { GoPerson } from 'react-icons/go';
import { LuSearch } from 'react-icons/lu';
import { RiWallet2Line } from 'react-icons/ri';
import { FiltersFormItem } from './styles';
import { serializeOnlyNumbers } from 'helpers/serializers';

export interface FiltersDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	handleFilter: (filters: SearchFilters) => void;
	filters?: SearchFilters;
}

export interface SearchFilters {
	taxpayer_id: string;
	name: string;
	account: string;
}

const FiltersDrawer = ({
	isOpen,
	filters,
	onClose,
	handleFilter,
}: FiltersDrawerProps) => {
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState<boolean>(true);

	const onFilter = (filters: SearchFilters) => {
		if (!formValid) {
			return;
		}
		handleFilter({
			...filters,
			taxpayer_id:
				filters.taxpayer_id &&
				serializeOnlyNumbers(filters.taxpayer_id),
		});
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id &&
					normalizeCpfCnpj(filters.taxpayer_id),
				name: filters?.name,
				account: filters?.account,
			});
		}
	}, [filters, form, isOpen]);

	return (
		<CustomDrawer
			title="Busca avançada"
			open={isOpen}
			onClose={onClose}
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<Form<SearchFilters>
				form={form}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field => field.errors && field.errors.length > 0,
					);
					setFormValid(errors.length === 0);
				}}
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCpfCnpj(filters.taxpayer_id),
					name: filters?.name,
					account: filters?.account,
				}}
				onFinish={values => {
					onFilter(values);
				}}
			>
				<Row gutter={[5, 5]} style={{ width: '100%' }}>
					<FormItem
						style={{ width: '100%' }}
						shouldUpdate={(prevValues, currentValues) =>
							prevValues.taxpayer_id !== currentValues.taxpayer_id
						}
					>
						<FiltersFormItem
							name="taxpayer_id"
							label="CPF/CNPJ"
							normalize={e => normalizeCpfCnpj(e)}
							rules={[cpfCnpjRule]}
						>
							<Input disabled={false} placeholder="Digite aqui" />
						</FiltersFormItem>
					</FormItem>
					<FormItem
						style={{ width: '100%' }}
						shouldUpdate={(prevValues, currentValues) =>
							prevValues.name !== currentValues.name
						}
					>
						<FiltersFormItem name="name" label="Nome">
							<Input
								prefix={<GoPerson />}
								disabled={false}
								placeholder="Digite aqui"
							/>
						</FiltersFormItem>
					</FormItem>
					<FormItem
						style={{ width: '100%' }}
						shouldUpdate={(prevValues, currentValues) =>
							prevValues.account !== currentValues.account
						}
					>
						<FiltersFormItem name="account" label="Conta">
							<Input
								prefix={<RiWallet2Line />}
								disabled={false}
								placeholder="Digite aqui"
							/>
						</FiltersFormItem>
					</FormItem>
				</Row>
			</Form>
		</CustomDrawer>
	);
};

export default FiltersDrawer;
