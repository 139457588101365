import { Button, Dropdown, type TableProps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Table } from 'components/Table';
import { ESortingPage, PaginationInfo } from 'types/List';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { AccessService } from 'modules/users/services/access';
import { AccessListContent } from 'types/Access';
import { TextM, TextS } from 'components/Text';
import dayjs from 'dayjs';
import { normalizeCpf } from 'helpers/normalizers';
import { FiltersActions, FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { LuSearch } from 'react-icons/lu';
import { AiOutlineStop } from 'react-icons/ai';
import { AccessListFiltersType } from './types';
import UsersListFilters from './AccessListFilters';
import { MenuProps } from 'antd/lib';
import { MdBlock } from 'react-icons/md';
import { BsEye } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import { sortTableHandler } from 'helpers/tables';

interface IAccessListTableProps {
	companyId?: string;
	actions?: ReactNode;
	loading?: string;
	onView?: (user: AccessListContent) => void;
	onDelete?: (user: AccessListContent) => void;
}

const AccessListTable: React.FC<IAccessListTableProps> = ({
	companyId,
	actions,
	loading,
	onView,
	onDelete,
}) => {
	const theme = useTheme();
	const [isFiltersOpen, toggleFiltersOpen] = useState<boolean>(false);
	const [paginationInfo, updatePaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [filters, updateFilters] = useState<AccessListFiltersType>(
		{} as AccessListFiltersType,
	);

	const createActionItems = useCallback(
		(access: AccessListContent): MenuProps['items'] => {
			const defaultItems: MenuProps['items'] = [];

			if (typeof onView === 'function') {
				defaultItems.push({
					label: 'Ver usuário',
					icon: <BsEye size={18} />,
					key: '1',
					onClick: () => onView(access),
				});
			}

			if (typeof onDelete === 'function')
				defaultItems.push({
					label: 'Remover usuário',
					key: '3',
					icon: <MdBlock size={18} />,
					onClick: () => onDelete(access),
				});

			return defaultItems;
		},
		[onView, onDelete],
	);

	const columns: ColumnsType<AccessListContent> = useMemo(() => {
		const items: ColumnsType<AccessListContent> = [
			{
				title: 'Nome',
				key: 'user.fullName',
				sorter: true,
				width: '15%',
				render: (access: AccessListContent) => (
					<TextM weight="bold">{access.user.full_name}</TextM>
				),
			},
			{
				title: 'CPF',
				width: '10%',
				render: (access: AccessListContent) =>
					normalizeCpf(access.user.taxpayer_id),
			},
			{
				title: 'E-mail',
				key: 'user.emailAddress',
				sorter: true,
				width: '20%',
				render: (access: AccessListContent) =>
					access.user.email_address,
			},
			{
				title: 'Data de Criação',
				key: 'createdAt',
				sorter: true,
				width: '15%',
				render: (access: AccessListContent) => (
					<div>
						<TextM weight="normal">{`${dayjs(
							access.created_at,
						).format('DD/MM/YYYY')}`}</TextM>
						<TextS color={theme.textSecondary} weight="normal">
							{`às ${dayjs(access.created_at).format('HH:mm:ss')}`}
						</TextS>
					</div>
				),
			},
			{
				title: 'Criador',
				key: 'creator.fullName',
				sorter: true,
				width: '15%',
				render: (access: AccessListContent) => access.creator.full_name,
			},
			{
				key: 'action',
				width: '10%',
				align: 'center',
				render: (access: AccessListContent) => (
					<Dropdown
						disabled={access.id === loading}
						menu={{ items: createActionItems(access) }}
						trigger={['click']}
					>
						<Button
							type="link"
							style={{
								color: theme.text,
								fontWeight: 'bold',
								fontSize: '1.3rem',
								lineHeight: '1rem',
								letterSpacing: '0.09rem',
							}}
							loading={loading === access.id}
						>
							...
						</Button>
					</Dropdown>
				),
			},
		];

		return items;
	}, [createActionItems, theme.text, theme.textSecondary, loading]);

	const { data, isLoading } = useQuery({
		queryKey: ['accessList', companyId, paginationInfo, sorting, filters],
		queryFn: () =>
			AccessService.getAccessList({
				person_id: companyId,
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
				search: filters.search,
				taxpayer_id: filters.taxpayer_id,
				email_address: filters.email_address,
			}),
	});

	const handleClearFilters = useCallback(() => {
		updateFilters({} as AccessListFiltersType);
	}, []);

	return (
		<>
			<FiltersContainer>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => toggleFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={Object.keys(filters).length === 0}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
				{actions && <FiltersActions>{actions}</FiltersActions>}
			</FiltersContainer>
			<Table
				columns={columns}
				dataSource={data?.content || []}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						updatePaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
			<UsersListFilters
				isOpen={isFiltersOpen}
				onApply={filters => {
					updateFilters(filters);
					toggleFiltersOpen(false);
				}}
				onClose={() => toggleFiltersOpen(false)}
				filters={filters}
			/>
		</>
	);
};

export default AccessListTable;
