import React from 'react';

export const ExpanderIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.0007 10.828L7.05072 15.778L5.63672 14.364L12.0007 8L18.3647 14.364L16.9507 15.778L12.0007 10.828Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
