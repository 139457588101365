import React, { useState } from 'react';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import { SplashScreen } from 'components/SplashScreen';
import EscrowDetailsInformation from './EscrowDetailsInformation';
import { AccountsService } from 'modules/escrow/services';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import EscrowDetailsRelatedParts from './EscrowDetailsRelatedParts';
import EscrowDetailsSigners from './EscrowDetailsSigners';
import EscrowDetailsConfiguration from './EscrowDetailsConfiguration';
import { Divider, notification } from 'antd';
import { PageActions } from 'components/PageActions';
import { ButtonSecondary } from './styles';
import { BlockIcon } from 'components/Icons/Block';
import { CancelIcon } from 'components/Icons/Cancel';
import { TextM } from 'components/Text';
import { AccountStatus, IIUpdateAccountStatus } from 'types/Account';
import { UnblockIcon } from 'components/Icons/Unblock';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { useAccountActions } from 'modules/escrow/hooks';
import { queryClient } from 'network/query';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import EscrowAccountInfo from '../components/EscrowAccountInfo';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { FileWarningIcon } from 'components/Icons/FileWarning';
import { ThumbDownIcon } from 'components/Icons/ThumbDown';
import { ThumbUpIcon } from 'components/Icons/ThumbUp';
import theme from 'theme/theme';
import EventConfirmationModal from '../EventConfirmationModal/EventConfirmationModal';
import { TEventConfirmationModal } from '../types';
import { ApiError } from 'types/ApiError';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';

const EscrowDetailsPage = () => {
	const { id } = useParams();
	const { type } = useCurrentProfile();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const [api, contextHolder] = notification.useNotification();
	const { isEditingAccount } = useEscrowAccountsContext();

	const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
		useState(false);
	const [eventInfoModal, setEventInfoModal] =
		useState<TEventConfirmationModal>();
	const [successMessage, setSuccessMessage] = useState({
		label: '',
		title: '',
	});

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;
	const titleBreadcrumb = isEditingAccount
		? 'Análise de abertura de conta'
		: 'Detalhes da conta';
	const baseTitleBreadcrumb = (
		<BreadcrumbContainer>
			{getAccountRouteTypeIcon()}
			<span>{getAccountRouteTypeName()}</span>
		</BreadcrumbContainer>
	);
	const breadcrumb = [
		{
			href: redirectAccount,
			title: baseTitleBreadcrumb,
		},
		{
			title: titleBreadcrumb,
		},
	];

	const { loading, block, cancel, unblock } = useAccountActions({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onUpdateStatusSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			setSuccessMessage({
				label: 'Status da conta atualizado com sucesso!',
				title: 'Status da conta atualizado',
			});
			setIsOpenSuccessModal(true);
		},
		onBlockSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			setSuccessMessage({
				label: 'A conta foi bloqueada com sucesso!',
				title: 'Conta bloqueada',
			});
			setIsOpenSuccessModal(true);
		},
		onUnblockSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			setSuccessMessage({
				label: 'A conta foi desbloqueada com sucesso!',
				title: 'Conta desbloqueada',
			});
			setIsOpenSuccessModal(true);
		},
		onCancelSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			setSuccessMessage({
				label: 'A conta foi encerrada com sucesso!',
				title: 'Conta encerrada',
			});
			setIsOpenSuccessModal(true);
		},
	});

	const { mutate: updateStatus } = useMutation<
		void,
		ApiError,
		IIUpdateAccountStatus
	>({
		mutationFn: ({ id, status, reason }) => {
			return AccountsService.updateAccountStatus(id, {
				status: status,
				reason: reason,
			});
		},
		onSuccess: (_, data) => {
			if (data.status === AccountStatus.WAITING_UPDATES) {
				setSuccessMessage({
					label: 'A conta foi enviada para revisão de documentação.',
					title: 'Pendência sinalizada com sucesso!',
				});
			}
			if (data.status === AccountStatus.PENDING_ACTIVATION) {
				setSuccessMessage({
					label: 'A conta foi aprovada na análise e está pendente de ativação.',
					title: 'Conta aprovada com sucesso!',
				});
			}
			if (data.status === AccountStatus.REFUSED) {
				setSuccessMessage({
					label: 'A conta foi recusada em análise com sucesso.',
					title: 'Conta recusada com sucesso!',
				});
			}

			if (data.status === AccountStatus.CREATING) {
				setSuccessMessage({
					label: 'A conta foi aprovada na análise e está sendo criada.',
					title: 'Conta aprovada com sucesso!',
				});
			}

			setIsOpenSuccessModal(true);
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
	});

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to={redirectAccount} />;
	}

	const title = isEditingAccount
		? `Análise da conta ${data?.account ?? ''}`
		: `Detalhes da conta ${data?.account ?? ''}`;

	const tabOptions = [
		{
			title: 'Dados do titular',
			content: <EscrowDetailsInformation account={data} />,
			path: 'info',
			breadcrumb,
		},
		{
			title: 'Partes relacionadas',
			content: <EscrowDetailsRelatedParts account={data} />,
			path: 'related-parts',
			breadcrumb,
		},
		{
			title: 'Assinantes',
			content: <EscrowDetailsSigners account={data} />,
			path: 'signers',
			breadcrumb,
		},
	];
	const tabOptionsAccount = [
		...tabOptions,
		{
			title: 'Configurações da conta',
			content: <EscrowDetailsConfiguration account={data} />,
			path: 'configs',
			breadcrumb,
		},
	];

	const updateStatusHandler = (status: AccountStatus, reason?: string) => {
		if (status === AccountStatus.WAITING_UPDATES) {
			updateStatus({
				id: data.id,
				status: AccountStatus.WAITING_UPDATES,
				reason: reason || '',
			});
		}

		if (status === AccountStatus.REFUSED) {
			updateStatus({
				id: data.id,
				status: AccountStatus.REFUSED,
				reason: reason || '',
			});
		}

		if (status === AccountStatus.PENDING_ACTIVATION) {
			updateStatus({
				id: data.id,
				status: AccountStatus.PENDING_ACTIVATION,
				reason: reason || '',
			});
		}

		if (status === AccountStatus.CREATING) {
			updateStatus({
				id: data.id,
				status: AccountStatus.CREATING,
				reason: reason || '',
			});
		}
	};

	return (
		<>
			{contextHolder}
			<PageHeader isFluid={true}>
				<Heading1>{title}</Heading1>
				{type === AccessType.FINANCIAL_INSTITUTION &&
					!isEditingAccount && (
						<PageActions>
							<ButtonSecondary
								type="default"
								onClick={() => {
									setEventInfoModal({
										buttonText: 'Encerrar conta',
										modalTitle: 'Encerrar conta',

										isDanger: true,
										infoPrimary:
											'Você tem certeza que deseja encerrar a conta?',
										infoSecondary:
											'Essa ação não poderá ser desfeita.',
										onClick: () => {
											cancel(data);
											setIsOpenConfirmationModal(false);
										},
									});
									setIsOpenConfirmationModal(true);
								}}
							>
								Encerrar conta
								<CancelIcon />
							</ButtonSecondary>
							{data.status === AccountStatus.BLOCKED ? (
								<ButtonSecondary
									type="default"
									onClick={() => {
										setEventInfoModal({
											buttonText: 'Desbloquear conta',
											modalTitle: 'Desbloquear conta',
											infoPrimary:
												'Você tem certeza que deseja desbloquear a conta?',
											onClick: () => {
												unblock(data);
												setIsOpenConfirmationModal(
													false,
												);
											},
										});
										setIsOpenConfirmationModal(true);
									}}
								>
									Desbloquear conta
									<UnblockIcon />
								</ButtonSecondary>
							) : (
								<ButtonSecondary
									type="default"
									onClick={() => {
										setEventInfoModal({
											buttonText: 'Bloquear conta',
											modalTitle: 'Bloquear conta',
											infoPrimary:
												'Você tem certeza que deseja bloquear a conta?',
											onClick: () => {
												block(data);
												setIsOpenConfirmationModal(
													false,
												);
											},
										});
										setIsOpenConfirmationModal(true);
									}}
								>
									Bloquear conta
									<BlockIcon />
								</ButtonSecondary>
							)}
						</PageActions>
					)}
				{type === AccessType.FINANCIAL_INSTITUTION &&
					isEditingAccount && (
						<PageActions>
							<ButtonSecondary
								type="default"
								onClick={() => {
									setEventInfoModal({
										isDanger: true,
										disableIfEmptyReason: true,
										buttonText:
											'Sinalizar pendência de documentação',
										modalTitle:
											'Sinalizar pendência de documentação',
										infoPrimary:
											'Sinalizar que a abertura da conta possui documentos pendentes?',
										inputTextTitle: 'Documentos pendentes',
										onClick: reason => {
											updateStatusHandler(
												AccountStatus.WAITING_UPDATES,
												reason,
											);
											setIsOpenConfirmationModal(false);
										},
									});
									setIsOpenConfirmationModal(true);
								}}
							>
								Documentos pendentes
								<FileWarningIcon size={19} />
							</ButtonSecondary>
							<ButtonSecondary
								type="default"
								onClick={() => {
									setEventInfoModal({
										buttonText: 'Rejeitar solicitação',
										modalTitle: 'Rejeitar solicitação',
										infoPrimary: `Você tem certeza que deseja rejeitar a abertura da conta de ${data.person.name}?`,
										inputTextTitle: 'Motivo da rejeição',
										isDanger: true,
										disableIfEmptyReason: true,
										onClick: reason => {
											updateStatusHandler(
												AccountStatus.REFUSED,
												reason,
											);
											setIsOpenConfirmationModal(false);
										},
									});
									setIsOpenConfirmationModal(true);
								}}
							>
								Recusar
								<ThumbDownIcon />
							</ButtonSecondary>
							<ButtonSecondary
								type="primary"
								onClick={() => {
									setEventInfoModal({
										buttonText: 'Aprovar solicitação',
										modalTitle: 'Aprovar solicitação',
										infoPrimary: `Você tem certeza que deseja aprovar a abertura da conta de ${data.person.name}?`,
										inputTextTitle: 'Comentário (optional)',
										onClick: reason => {
											updateStatusHandler(
												AccountStatus.CREATING,
												reason,
											);
											setIsOpenConfirmationModal(false);
										},
									});
									setIsOpenConfirmationModal(true);
								}}
							>
								<TextM color={theme.white}>Aprovar</TextM>
								<ThumbUpIcon color={theme.white} />
							</ButtonSecondary>
						</PageActions>
					)}
			</PageHeader>
			<EscrowAccountInfo account={data} />
			<Divider style={{ marginTop: 16 }} />
			<PageWrapper isFluid={true}>
				<Tabs
					prePath={`/${id}/natural-person/details`}
					elements={isEditingAccount ? tabOptions : tabOptionsAccount}
				/>

				<InformationModal
					buttonText="Ok, obrigado"
					isOpen={isOpenSuccessModal}
					message={successMessage.label}
					title={successMessage.title}
					icon={<CheckmarkIcon />}
					onClose={() => setIsOpenSuccessModal(false)}
				/>

				{isOpenConfirmationModal && eventInfoModal && (
					<EventConfirmationModal
						closeModal={() => setIsOpenConfirmationModal(false)}
						info={eventInfoModal}
						hasInputText={!!eventInfoModal?.inputTextTitle}
						isLoading={loading}
						confirmHandler={reason =>
							eventInfoModal?.onClick(reason || '')
						}
					/>
				)}
			</PageWrapper>
		</>
	);
};

export default EscrowDetailsPage;
