import { Col, Row } from 'antd';
import { ConfirmationModal } from 'components/ConfirmationModal';
import Confirmation from 'components/Icons/Confirmation';
import { WarningIcon } from 'components/Icons/Warning';
import { TextS } from 'components/Text';

export enum AutoApprovePostingSucessModalType {
	SUCCESS = 'success',
	ERROR = 'error',
	TIMEOUT = 'timeout',
}

export interface AutoApprovePostingSucessModalProps {
	isOpen: boolean;
	onClose: () => void;
	modalType: AutoApprovePostingSucessModalType;
	textMessage?: string;
}

const MODAL_TITLE = {
	success: 'Transferência concluída com sucesso!',
	error: 'Erro ao concluir transferência',
	timeout: 'Ainda estamos processando sua transferência',
};

const MODAL_ICON = {
	success: <Confirmation />,
	error: <WarningIcon />,
	timeout: <WarningIcon />,
};

export const MODAL_MESSAGE = {
	success:
		'Transferência concluída com sucesso. Verifique seu extrato para mais detalhes',
	error: 'Ocorreu um erro ao realizar a transferência. Por favor, verifique a seção de "Aprovações" para mais detalhes.',
	timeout:
		'A verificação do pagamento está demorando mais do que o esperado. Por favor, veja o status da transferência em seu extrato.',
};

const AutoApprovePostingSucessModal = ({
	isOpen,
	modalType,
	textMessage,
	onClose,
}: AutoApprovePostingSucessModalProps) => {
	const handleClose = () => {
		onClose();
	};

	return (
		<ConfirmationModal
			title={MODAL_TITLE[modalType]}
			isOpen={isOpen}
			onClose={() => {
				handleClose();
			}}
			confirmText="Ok, obrigado"
			onConfirm={() => {
				handleClose();
			}}
		>
			<Row
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Col span={3}>{MODAL_ICON[modalType]}</Col>
				<Col span={21}>
					<TextS>{MODAL_MESSAGE[modalType]}</TextS>
					{textMessage && (
						<Row style={{ gap: '0.4rem', marginTop: '1rem' }}>
							Erro: <TextS>{textMessage}</TextS>
						</Row>
					)}
				</Col>
			</Row>
		</ConfirmationModal>
	);
};

export default AutoApprovePostingSucessModal;
