import { UserListAccess, UserListContent } from 'types/User';
import { TextL } from 'components/Text';
import { useCallback, useEffect, useState } from 'react';
import UserBlockingForm from './UserBlockingForm';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { AccessType } from 'types/Access';
import { useAccess } from 'modules/users/hooks/useAccess';
import { queryClient } from 'network/query';
import { ApiError } from 'types/ApiError';

interface IUserBlockingProps {
	isOpen: boolean;
	user: UserListContent | false;
	onClose: () => void;
	onError: (error: ApiError) => void;
}
const UserBlocking = ({
	isOpen,
	user,
	onClose,
	onError,
}: IUserBlockingProps) => {
	const [isFormOpen, toggleFormOpen] = useState<boolean>(false);
	const [isConfirmationOpen, toggleConfirmationOpen] = useState<
		UserListAccess[] | false
	>(false);
	const [isInfoModalOpen, toggleInfoModalOpen] = useState(false);

	useEffect(() => {
		if (isOpen) {
			toggleFormOpen(true);
		}
	}, [isOpen]);

	const onCloseRequest = useCallback(() => {
		toggleFormOpen(false);
		toggleConfirmationOpen(false);
		toggleInfoModalOpen(false);
		onClose();
	}, [onClose]);

	const { deleteAccess, isLoading } = useAccess({
		onError,
		onSuccess: () => {
			toggleInfoModalOpen(true);
			toggleConfirmationOpen(false);
			queryClient.refetchQueries({
				queryKey: ['userList'],
			});
		},
	});

	return (
		<>
			<UserBlockingForm
				isOpen={isFormOpen}
				user={user}
				onClose={onCloseRequest}
				onConfirm={access => {
					toggleFormOpen(false);
					toggleConfirmationOpen(access);
				}}
			/>
			<ConfirmationModal
				isOpen={isConfirmationOpen !== false}
				danger={true}
				title="Bloquear usuário"
				confirmText="Sim, bloquear acessos"
				cancelText="Cancelar"
				isLoading={isLoading}
				onConfirm={() => {
					if (isConfirmationOpen && user) {
						deleteAccess(
							user.id,
							isConfirmationOpen.map(a => a.id),
						);
					}
				}}
				onCancel={onCloseRequest}
				onClose={onCloseRequest}
			>
				{isConfirmationOpen && user && (
					<>
						{isConfirmationOpen.length > 1 ? (
							<TextL>
								{`Você tem certeza que deseja bloquear o ${user.full_name} nas ${isConfirmationOpen.length} empresas?`}
							</TextL>
						) : (
							<TextL>
								{`Você tem certeza que deseja bloquear o ${user.full_name} na ${
									isConfirmationOpen[0].type ===
									AccessType.PERSON
										? isConfirmationOpen[0].person?.name
										: isConfirmationOpen[0]
												.financial_institution.name
								}?`}
							</TextL>
						)}
					</>
				)}
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isInfoModalOpen}
				message="Usuário foi bloqueado com sucesso!"
				title="Bloquear usuário"
				icon={<CheckmarkIcon />}
				onClose={onCloseRequest}
			/>
		</>
	);
};

export default UserBlocking;
