import { useCallback, useContext, useState } from 'react';
import { AccountBalanceTableWrapper, PageWrapper } from './styles';
import { Button, Col, Dropdown, Row, notification } from 'antd';
import { FilterButton } from 'components/FilterButton';
import { LuCalendar } from 'react-icons/lu';
import { TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { TableParams, DateRange, defaultTableConfig } from './EscrowStatement';
import dayjs from 'dayjs';
import { AiOutlineStop } from 'react-icons/ai';
import { ColumnsType } from 'antd/es/table';
import { AccountStatementMovement } from 'types/Account';
import { formatCurrency } from 'helpers/normalizers';
import { Table } from 'components/Table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PostingsService } from 'modules/escrow/services';
import { Posting, PostingStatus, PostingStatusMap } from 'types/Posting';
import DateFilterDrawer, { DateFilterType } from './DateFilterDrawer';
import { MenuProps } from 'antd/lib';
import { MdBlock } from 'react-icons/md';
import PostingRejectModal from './PostingRejectModal';
import { usePostings } from 'modules/escrow/hooks/usePostings';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { subject } from '@casl/ability';
import {
	getPostingType,
	getScheduledPostingsDescription,
} from 'modules/escrow/utils/scheduledPostings';
import { queryClient } from 'network/query';

export const scheduledPostingsStatuses: {
	value: PostingStatus;
	label: string;
}[] = [
	{
		value: PostingStatus.SCHEDULED,
		label: PostingStatusMap[PostingStatus.SCHEDULED],
	},
];

const INITIAL_DATES_VALUES = {
	dateFrom: dayjs(),
	dateTo: dayjs().add(6, 'days'),
};

const ScheduledPostingsContent = () => {
	const theme = useTheme();
	const ability = useContext(AbilityContext);
	const [api, contextHolder] = notification.useNotification();
	const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
	const [postingToReject, setPostingToReject] = useState<Posting | undefined>(
		undefined,
	);
	const [dateRangeFilter, setDateRangeFilter] =
		useState<DateRange>(INITIAL_DATES_VALUES);
	const [tableParams, setTableParams] =
		useState<TableParams>(defaultTableConfig);
	const [cancelPostingSuccess, setCancelPostingSuccess] = useState(false);

	const handleClearFilters = useCallback(() => {
		setDateRangeFilter(INITIAL_DATES_VALUES);
		setTableParams(defaultTableConfig);
	}, []);

	const handleDateFilter = useCallback((filter: DateRange) => {
		setDateRangeFilter(filter);
	}, []);

	const handleConfirmReject = (posting: Posting) => {
		cancelPosting({
			postingId: posting.id,
		});
	};

	const { cancelPosting } = usePostings({
		onCreateSuccess: () => {
			setCancelPostingSuccess(true);
			setPostingToReject(undefined);
			queryClient.refetchQueries({
				queryKey: ['listScheduledPostings'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Erro ao cancelar lançamento.',
			});
		},
	});

	const handlePageChange = (page: number, pageSize: number) => {
		setTableParams(prevState => {
			return {
				...prevState,
				pagination: {
					...prevState.pagination,
					current: page,
					pageSize: pageSize,
				},
			};
		});
	};

	const { data, isLoading } = useQuery({
		queryKey: [
			'listScheduledPostings',
			dateRangeFilter,
			tableParams.pagination,
		],
		queryFn: () =>
			PostingsService.listPostings({
				status: scheduledPostingsStatuses[0].value,
				page: tableParams.pagination?.current || 1,
				limit: tableParams.pagination?.pageSize || 50,
				date_from: dateRangeFilter.dateFrom.format('YYYY-MM-DD'),
				date_to: dateRangeFilter.dateTo.format('YYYY-MM-DD'),
			}),
		placeholderData: keepPreviousData,
		staleTime: 5000,
	});

	const createActionItems = useCallback(
		(posting: Posting): MenuProps['items'] => {
			const defaultItems: MenuProps['items'] = [];

			defaultItems.push({
				label: 'Cancelar lançamento',
				icon: <MdBlock size={18} />,
				key: '2',
				onClick: () => setPostingToReject(posting),
				disabled: ability.cannot(
					'REVIEW',
					subject('POSTING', { accountId: posting.account.id }),
				),
			});

			return defaultItems;
		},
		[ability],
	);

	const columns: ColumnsType<AccountStatementMovement> = [
		{
			title: 'Agendado para',
			render: (item: Posting) => (
				<TextS color="#6F6B82">
					{dayjs(item.scheduled_date).format('DD/MM/YYYY')}
				</TextS>
			),
			width: '15%',
		},
		{
			title: 'Lançamento',
			render: (item: Posting) => (
				<TextS color="#6F6B82">{getPostingType(item)}</TextS>
			),
			width: '30%',
		},
		{
			title: 'Descrição',
			render: (item: Posting) => {
				return (
					<>
						<TextS color="#6F6B82">
							{getScheduledPostingsDescription(item)}
						</TextS>
					</>
				);
			},
			width: '30%',
		},
		{
			title: 'Valor',
			render: (item: Posting) => {
				return (
					<TextM
						style={{
							fontFamily: "'Inter', 'sans-serif'",
							color: '#302D3F',
						}}
					>
						{formatCurrency(item.amount!)}
					</TextM>
				);
			},
			width: '15%',
		},
		{
			title: 'Ações',
			render: (item: Posting) => {
				return (
					<Dropdown
						disabled={false}
						menu={{ items: createActionItems(item) }}
						trigger={['click']}
					>
						<Button
							type="link"
							style={{ color: theme.text, fontWeight: 'bold' }}
							loading={false}
						>
							. . .
						</Button>
					</Dropdown>
				);
			},
			width: '10%',
		},
	];

	return (
		<>
			{contextHolder}
			<PageWrapper>
				<Row
					gutter={[8, 8]}
					style={{ justifyContent: 'space-between' }}
				>
					<Col style={{ display: 'flex' }}>
						<Col>
							<FilterButton
								icon={<LuCalendar color={theme.white} />}
								onClick={() => setIsDateFilterOpen(true)}
							>
								<TextS
									style={{ color: theme.white }}
								>{`${dateRangeFilter.dateFrom.format(
									'DD/MM/YYYY',
								)} - ${dateRangeFilter.dateTo.format('DD/MM/YYYY')}`}</TextS>
							</FilterButton>
						</Col>
					</Col>
					<Col>
						<FilterButton
							icon={
								<AiOutlineStop color={theme.white} size={18} />
							}
							variation="secondary"
							onClick={handleClearFilters}
						>
							<TextS style={{ color: theme.white }}>
								Limpar filtros
							</TextS>
						</FilterButton>
					</Col>
				</Row>

				<AccountBalanceTableWrapper>
					<Table
						style={{ marginTop: '1rem' }}
						columns={columns}
						rowKey={record => record.id}
						dataSource={data?.content || []}
						loading={isLoading}
						pagination={{
							total: data?.total_elements || 0,
							showTotal(total) {
								const currentSize =
									(tableParams.pagination?.current || 1) *
									(tableParams.pagination?.pageSize || 50);
								return `${
									currentSize > total ? total : currentSize
								} de ${total}`;
							},
							pageSizeOptions: ['10', '30', '60', '90'],
							showSizeChanger: true,
							pageSize: tableParams.pagination?.pageSize || 50,
							current: tableParams.pagination?.current || 1,
							onChange(page, pageSize) {
								handlePageChange(page, pageSize);
							},
						}}
						locale={{
							emptyText: 'Nenhum documento adicionado',
						}}
						// onChange={handleTableChange}
						rowClassName={record => {
							return record.id.includes('daily-balance')
								? 'daily-balance'
								: '';
						}}
					/>
				</AccountBalanceTableWrapper>
			</PageWrapper>
			<DateFilterDrawer
				isOpen={isDateFilterOpen}
				onClose={() => setIsDateFilterOpen(false)}
				handleFilter={handleDateFilter}
				filterType={DateFilterType.SCHEDULED_POSTINGS}
			/>
			<PostingRejectModal
				isOpen={!!postingToReject}
				onClose={() => setPostingToReject(undefined)}
				postingData={postingToReject}
				handleConfirm={() => handleConfirmReject(postingToReject!)}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={cancelPostingSuccess}
				message="Lançamento cancelado"
				title="Lançamento cancelado"
				icon={<CheckmarkIcon />}
				onClose={() => setCancelPostingSuccess(false)}
			/>
		</>
	);
};

export default ScheduledPostingsContent;
