import { Card } from 'antd';
import { Table } from 'components/Table';
import styled from 'styled-components';

export const CargeSelectWrapper = styled.div`
	border-top: 1rem;
	display: flex;
	width: 100%;
	.ant-select-selector {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
`;

export const DestinationTable = styled(Table)`
	th::before {
		display: none;
	}
`;

export const WarningCard = styled(Card)`
	background-color: ${({ theme }) => theme.dangerOpacity};
	width: 100%;
	padding: 1rem;

	.ant-card-body {
		padding: 0;
	}
`;
