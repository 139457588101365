import { Form } from 'antd';
import styled from 'styled-components';
import { ICnabFilters } from './types';

export const FiltersContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
	align-items: flex-end;
`;

export const DropdownIcon = styled.div`
	display: flex;
	align-items: center;
	svg {
		width: 18px;
		height: 18px;
	}
`;

export const FiltersForm = styled(Form<ICnabFilters>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;
