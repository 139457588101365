import React, { useContext } from 'react';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { ProfileOption } from './styles';
import { AccessType } from 'types/Access';

const ProfileSelector: React.FC = () => {
	const { userAccess, setSelectedUserAccess } = useContext(ProfileContext);

	return (
		<>
			{userAccess.map(access => {
				return (
					<ProfileOption
						key={access.id}
						onClick={() => setSelectedUserAccess(access)}
					>
						{access.type === AccessType.PERSON
							? access.person?.name
							: access.financial_institution?.name}
					</ProfileOption>
				);
			})}
		</>
	);
};

export default ProfileSelector;
