import React, { useState } from 'react';
import { Form, Input } from 'antd';
import {
	TwoFactorCodeFormContainer,
	TwoFactorCodeFormError,
	TwoFactorCodeFormInputList,
	TwoFactorCodeFormItem,
	TwoFactorCodeFormQrCode,
	TwoFactorCodeFormSubmit,
	TwoFactorSetupFormContainer,
	TwoFactorSetupFormStepItem,
	TwoFactorSetupFormSteps,
	TwoFactorText,
} from './styles';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { TwoFactorCodeFormType } from './types';
import { CognitoErrorCode } from 'modules/core/types/Cognito';
import QRCodeCanvas from 'qrcode.react';

const VALID_INPUT_REGEX = /^[0-9]*$/;

interface TwoFactorSetupTotpFormProps {
	totpSecret: string;
}
const TwoFactorSetupTotpForm: React.FC<TwoFactorSetupTotpFormProps> = ({
	totpSecret,
}) => {
	const { verifyCode, resetError, errorCode, isLoading } = useAuthContext();
	const [error, setError] = useState<string | null>(null);

	const onKeyDown =
		(index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
			const charCode = e.which || e.keyCode;
			const char = String.fromCharCode(charCode);
			if (e.key === 'Backspace' && index > 0) {
				setTimeout(() =>
					document.getElementById(`code-${index - 1}`)?.focus(),
				);
			} else if (VALID_INPUT_REGEX.test(char) && index < 6) {
				setTimeout(() =>
					document.getElementById(`code-${index + 1}`)?.focus(),
				);
			}
		};

	return (
		<TwoFactorSetupFormContainer>
			<TwoFactorSetupFormSteps>
				<TwoFactorSetupFormStepItem>
					<TwoFactorText>
						<strong>Abra Seu Aplicativo de Autenticação:</strong>{' '}
						<br />
						Procure a opção <i>Adicionar Conta</i> ou{' '}
						<i>Escanear QR Code</i> no aplicativo. Use a câmera do
						seu dispositivo para escanear o QR Code abaixo.
					</TwoFactorText>
					<TwoFactorCodeFormQrCode>
						<QRCodeCanvas value={totpSecret} />
					</TwoFactorCodeFormQrCode>
				</TwoFactorSetupFormStepItem>
				<TwoFactorSetupFormStepItem>
					<TwoFactorText>
						<strong>Digite o Código:</strong> <br />
						Após escanear, o aplicativo gerará um código de 6
						dígitos. Digite este código no campo abaixo para
						concluir a configuração.
					</TwoFactorText>
				</TwoFactorSetupFormStepItem>
			</TwoFactorSetupFormSteps>
			<TwoFactorCodeFormContainer
				style={{ width: '100%' }}
				initialValues={{ code: [{}, {}, {}, {}, {}, {}] }}
				layout="vertical"
				onFieldsChange={() => {
					setError(null);
					resetError();
				}}
				onFinish={(values: TwoFactorCodeFormType) => {
					const code = values.code
						.map((item, i) => item[`code${i}`])
						.join('');
					if (code.length < 6) {
						setError('Campo obrigatório!');
						return;
					}
					verifyCode(code);
				}}
			>
				<Form.List name="code">
					{fields => (
						<TwoFactorCodeFormInputList>
							{fields.map(({ key, name, ...restField }) => (
								<TwoFactorCodeFormItem
									key={key}
									name={[name, `code${key}`]}
									validateStatus={
										error !== null ||
										errorCode ===
											CognitoErrorCode.MFA_FAILED
											? 'error'
											: undefined
									}
								>
									<Input
										{...restField}
										id={`code-${key}`}
										autoFocus={key === 0 ? true : false}
										onKeyDown={onKeyDown(key)}
										maxLength={1}
										style={{
											textAlign: 'center',
											padding: '6.4px 0',
										}}
									/>
								</TwoFactorCodeFormItem>
							))}
						</TwoFactorCodeFormInputList>
					)}
				</Form.List>
				{error && (
					<TwoFactorCodeFormError>{error}</TwoFactorCodeFormError>
				)}
				{errorCode === CognitoErrorCode.MFA_FAILED && (
					<TwoFactorCodeFormError>
						Código inválido
					</TwoFactorCodeFormError>
				)}
				<TwoFactorCodeFormSubmit
					loading={isLoading}
					type="primary"
					htmlType="submit"
				>
					Confirmar
				</TwoFactorCodeFormSubmit>
			</TwoFactorCodeFormContainer>
		</TwoFactorSetupFormContainer>
	);
};

export default TwoFactorSetupTotpForm;
