import React from 'react';

export const DebitCollectionsIcon: React.FC<{ color?: string }> = ({
	color,
}) => (
	<svg
		width="20"
		height="19"
		viewBox="0 0 20 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2 2.74023V16.7402H18V2.74023H2ZM1 0.740234H19C19.5523 0.740234 20 1.18795 20 1.74023V17.7402C20 18.2925 19.5523 18.7402 19 18.7402H1C0.44772 18.7402 0 18.2925 0 17.7402V1.74023C0 1.18795 0.44772 0.740234 1 0.740234ZM4 4.74023H7V14.7402H4V4.74023ZM8 4.74023H10V14.7402H8V4.74023ZM11 4.74023H12V14.7402H11V4.74023ZM13 4.74023H16V14.7402H13V4.74023Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
