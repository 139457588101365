import { useEffect, useMemo, useState } from 'react';
import InterestFineForm from './InterestFineForm';
import InterestFineSuccess from './InterestFineSuccess';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { Modal } from 'components/Modal';
import { WalletCharge } from 'types/Wallet';
import { WalletsService } from 'modules/debitCollections/services';
import { queryClient } from 'network/query';
import { InterestFineType } from './types';

enum InterestFineSteps {
	FORM = 'FORM',
	SUCCESS = 'SUCCESS',
}

interface IInterestFineProps {
	isOpen: boolean;
	walletId: string;
	charge?: WalletCharge | WalletCharge[];
	onClose: () => void;
}
const InterestFine = ({
	isOpen,
	walletId,
	charge: editCharge,
	onClose,
}: IInterestFineProps) => {
	const [currentStep, updateCurrentStep] = useState<InterestFineSteps>(
		InterestFineSteps.FORM,
	);
	const [api, contextHolder] = notification.useNotification();
	const isMultiples = Array.isArray(editCharge);

	const { mutate, isPending } = useMutation<
		{ id: string | string[] },
		ApiError,
		InterestFineType
	>({
		mutationFn: ({ fine, interest }) => {
			if (isMultiples) {
				return Promise.all(
					editCharge!.map(charge => {
						return WalletsService.updateCharge(
							walletId,
							charge.id,
							{
								fine,
								interest,
							},
						);
					}),
				).then(() => ({ id: editCharge!.map(charge => charge.id) }));
			}
			return WalletsService.updateCharge(walletId, editCharge!.id, {
				fine,
				interest,
			});
		},
		onSuccess: () => {
			updateCurrentStep(InterestFineSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['chargesList'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao alterar a cobrança.',
			});
		},
	});

	useEffect(() => {
		if (isOpen) {
			updateCurrentStep(InterestFineSteps.FORM);
		}
	}, [isOpen]);

	const title = useMemo(() => {
		if (currentStep === InterestFineSteps.SUCCESS)
			return 'Alterações salvas com sucesso!';
		return 'Alterar multas e juros';
	}, [currentStep]);

	return (
		<Modal title={title} width={480} isOpen={isOpen} onClose={onClose}>
			{contextHolder}
			{currentStep === InterestFineSteps.FORM && isOpen && editCharge && (
				<InterestFineForm
					isLoading={isPending}
					onClose={onClose}
					charge={editCharge}
					onSave={values => {
						mutate(values);
					}}
				/>
			)}
			{currentStep === InterestFineSteps.SUCCESS && isOpen && (
				<InterestFineSuccess onClose={onClose} />
			)}
		</Modal>
	);
};

export default InterestFine;
