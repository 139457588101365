import { Modal } from 'components/Modal';
import { TextM } from 'components/Text';
import { Container } from './styles';
import { useTheme } from 'styled-components';
import { AccountTypeButton } from './AccountTypeButton';
import { CompanyIcon } from 'components/Icons/Company';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import { PersonIcon } from 'components/Icons/Person';
import {
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';

export interface SelectPersonTypeModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export enum PersonType {
	PJ = 'PJ',
	PF = 'PF',
}

const SelectPersonTypeModal = ({
	isOpen,
	onClose,
}: SelectPersonTypeModalProps) => {
	const theme = useTheme();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const [selectedAccountType, updateSelectedAccountType] =
		useState<PersonType | null>(null);
	const navigate = useNavigate();

	const handleClose = () => {
		updateSelectedAccountType(null);
		onClose();
	};

	const handleRegister = () => {
		const registerRouters = {
			[PersonType.PJ]: isEscrowRoute
				? EscrowRoutes.ESCROW_NEW
				: EscrowRoutes.ACCOUNT_NEW,
			[PersonType.PF]: isEscrowRoute
				? EscrowRoutes.ESCROW_NEW_NATURAL_PERSON
				: EscrowRoutes.ACCOUNT_NEW_NATURAL_PERSON,
		};
		navigate(registerRouters[selectedAccountType!]);
	};

	return (
		<Modal
			title={`Selecione o tipo de ${getAccountRouteTypeName()}`}
			isOpen={isOpen}
			onClose={handleClose}
			width={500}
		>
			<Container>
				<TextM style={{ color: theme.text }}>
					{`Selecione o tipo de ${getAccountRouteTypeName()} que você deseja criar`}
				</TextM>

				<AccountTypeButton
					icon={<CompanyIcon color={theme.primary} />}
					selected={selectedAccountType === PersonType.PJ}
					buttonText="Para empresas (PJ)"
					onClick={() => updateSelectedAccountType(PersonType.PJ)}
				/>

				<AccountTypeButton
					icon={<PersonIcon color={theme.primary} />}
					selected={selectedAccountType === PersonType.PF}
					buttonText="Para pessoas (PF)"
					onClick={() => updateSelectedAccountType(PersonType.PF)}
				/>

				<div style={{ marginTop: '20px' }}>
					<ButtonPrimary
						onClick={handleRegister}
						style={{ justifyContent: 'center', padding: '20px 0' }}
						disabled={!selectedAccountType}
					>
						<TextM style={{ color: theme.white }}>Cadastrar</TextM>
					</ButtonPrimary>

					<ButtonPrimary
						variant="secondary"
						onClick={handleClose}
						style={{
							justifyContent: 'center',
							padding: '20px 0',
							outline: 'none',
							border: 'none',
						}}
					>
						<TextM style={{ color: theme.primary }}>Cancelar</TextM>
					</ButtonPrimary>
				</div>
			</Container>
		</Modal>
	);
};

export default SelectPersonTypeModal;
