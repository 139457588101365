import { useCallback } from 'react';

import { Button, Col, DatePicker, Form, Row } from 'antd';
import { LuSearch } from 'react-icons/lu';
import { CustomDrawer } from 'components/CustomDrawer';
import { FiltersForm } from '../styles';

import { ICnabFilters } from '../types';
import { localeDatePicker } from 'helpers/dates';

interface ICnabFilterProps {
	isOpen: boolean;
	onApply: (filters: ICnabFilters) => void;
	onClose: () => void;
}
const CnabFilter = ({ isOpen, onApply, onClose }: ICnabFilterProps) => {
	const [form] = FiltersForm.useForm();

	const onFinish = useCallback(
		(values: ICnabFilters) => {
			onApply(values as ICnabFilters);
		},
		[onApply],
	);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={{}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Form.Item name="created_at" label="Processado em">
							<DatePicker
								style={{
									width: '100%',
								}}
								format={'DD/MM/YYYY'}
								locale={localeDatePicker}
							/>
						</Form.Item>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
};

export default CnabFilter;
