import { CustomDrawer } from 'components/CustomDrawer';
import { FiltersForm, FiltersFormItem } from './styles';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { useCallback, useEffect } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import { normalizeCpf, normalizeCpfCnpj } from 'helpers/normalizers';
import { LuSearch } from 'react-icons/lu';
import { UsersListFiltersType } from './types';
import { cpfRule } from 'helpers/rules';
import { useCompaniesList } from 'modules/company/hooks';

export interface UsersListFiltersProps {
	isOpen: boolean;
	filters?: UsersListFiltersType;
	onClose: () => void;
	onApply: (filters: UsersListFiltersType) => void;
}

const UsersListFilters = ({
	isOpen,
	filters,
	onClose,
	onApply,
}: UsersListFiltersProps) => {
	const [form] = FiltersForm.useForm();
	const { companies, loading, applyFilters } = useCompaniesList({
		skipUntilApplyFilters: isOpen,
	});

	useEffect(() => {
		if (isOpen) applyFilters({});
	}, [isOpen]);

	const onFinish = useCallback(
		(values: UsersListFiltersType) => {
			onApply({
				...values,
				taxpayer_id:
					values.taxpayer_id && values.taxpayer_id.length > 0
						? serializeOnlyNumbers(values.taxpayer_id)
						: undefined,
				email_address:
					values.email_address && values.email_address.length > 0
						? values.email_address
						: undefined,
				full_name:
					values.full_name && values.full_name.length > 0
						? values.full_name
						: undefined,
				person_id:
					values.person_id && values.person_id.length > 0
						? values.person_id
						: undefined,
			} as UsersListFiltersType);
		},
		[onApply],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id &&
					normalizeCpfCnpj(filters?.taxpayer_id),
				email_address: filters?.email_address,
				full_name: filters?.full_name,
				person_id: filters?.person_id,
			});
		}
	}, [isOpen]);

	return (
		<CustomDrawer
			open={isOpen}
			onClose={onClose}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCpfCnpj(filters?.taxpayer_id),
					email_address: filters?.email_address,
					full_name: filters?.full_name,
				}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="full_name" label="Nome">
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="taxpayer_id"
							label="CPF"
							normalize={value => normalizeCpf(value)}
							rules={[cpfRule]}
						>
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="email_address" label="E-mail">
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="person_id" label="Empresa">
							<Select
								placeholder="Selecione"
								options={companies.map(company => ({
									label: company.name,
									value: company.id,
								}))}
								allowClear
								showSearch
								loading={loading}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.startsWith(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.startsWith(input.toLowerCase())
								}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
};

export default UsersListFilters;
