import styled from 'styled-components';

export const PageActions = styled.div`
	display: flex;
	align-items: center;
	padding: 0 1rem;
	gap: 1rem;

	button {
		height: 45px;
		font-size: 18px;
		font-weight: 600;
		line-height: 29px;
		text-align: center;
		display: flex;
		align-items: center;

		svg {
			margin-left: 0.6rem;
		}
	}
`;
