import { useEffect, useMemo, useState } from 'react';
import DiscountForm from './DiscountForm';
import DiscountSuccess from './DiscountSuccess';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { Modal } from 'components/Modal';
import { WalletCharge } from 'types/Wallet';
import { WalletsService } from 'modules/debitCollections/services';
import { queryClient } from 'network/query';

enum DiscountSteps {
	FORM = 'FORM',
	SUCCESS = 'SUCCESS',
}

interface IDiscountProps {
	isOpen: boolean;
	walletId: string;
	charge?: WalletCharge | WalletCharge[];
	onClose: () => void;
}
const Discount = ({
	isOpen,
	walletId,
	charge: editCharge,
	onClose,
}: IDiscountProps) => {
	const [currentStep, updateCurrentStep] = useState<DiscountSteps>(
		DiscountSteps.FORM,
	);
	const [api, contextHolder] = notification.useNotification();
	const [discountValue, updateDiscountValue] = useState<number>(0);
	const isMultiples = Array.isArray(editCharge);

	const { mutate, isPending } = useMutation<
		{ id: string | string[] },
		ApiError,
		{ discountValue: number }
	>({
		mutationFn: ({ discountValue }) => {
			if (isMultiples) {
				return Promise.all(
					editCharge!.map(charge => {
						return WalletsService.updateCharge(
							walletId,
							charge.id,
							{
								amount: charge.amount - discountValue,
							},
						);
					}),
				).then(() => ({ id: editCharge!.map(charge => charge.id) }));
			}
			return WalletsService.updateCharge(walletId, editCharge!.id, {
				amount: editCharge!.amount - discountValue,
			});
		},
		onSuccess: () => {
			updateCurrentStep(DiscountSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['chargesList'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao alterar a cobrança.',
			});
		},
	});

	useEffect(() => {
		if (isOpen) {
			updateCurrentStep(DiscountSteps.FORM);
		}
	}, [isOpen]);

	const title = useMemo(() => {
		if (currentStep === DiscountSteps.SUCCESS)
			return isMultiples
				? 'Descontos adicionados com sucesso!'
				: 'Desconto adicionado com sucesso!';
		return isMultiples
			? 'Adicionar desconto aos boletos'
			: 'Adicionar desconto ao boleto';
	}, [currentStep, isMultiples]);

	return (
		<Modal title={title} width={480} isOpen={isOpen} onClose={onClose}>
			{contextHolder}
			{currentStep === DiscountSteps.FORM && isOpen && editCharge && (
				<DiscountForm
					charge={editCharge}
					isLoading={isPending}
					onClose={onClose}
					onSave={discountValue => {
						updateDiscountValue(discountValue);
						mutate({ discountValue });
					}}
				/>
			)}
			{currentStep === DiscountSteps.SUCCESS && isOpen && (
				<DiscountSuccess
					discountValue={discountValue}
					onClose={onClose}
				/>
			)}
		</Modal>
	);
};

export default Discount;
