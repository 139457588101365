import { useEffect, useMemo, useState } from 'react';
import WalletCreationDataForm from './WalletCreationForm';
import WalletCreationSuccess from './WalletCreationSuccess';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { Modal } from 'components/Modal';
import { Wallet, WalletDto } from 'types/Wallet';
import { WalletsService } from 'modules/debitCollections/services';
import { queryClient } from 'network/query';

enum WalletCreationSteps {
	FORM = 'FORM',
	SUCCESS = 'SUCCESS',
}

interface IWalletCreationProps {
	isOpen: boolean;
	wallet?: Wallet;
	onClose: () => void;
}
const WalletCreation = ({
	isOpen,
	wallet: editWallet,
	onClose,
}: IWalletCreationProps) => {
	const [currentStep, updateCurrentStep] = useState<WalletCreationSteps>(
		WalletCreationSteps.FORM,
	);
	const [api, contextHolder] = notification.useNotification();

	const { mutate, isPending } = useMutation<
		{ id: string },
		ApiError,
		WalletDto
	>({
		mutationFn: walletDto => {
			if (editWallet) {
				return WalletsService.updateWallet(editWallet.id, walletDto);
			}
			return WalletsService.createWallet(walletDto);
		},
		onSuccess: () => {
			updateCurrentStep(WalletCreationSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['walletsList'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar carteira.',
			});
		},
	});

	useEffect(() => {
		if (isOpen) {
			updateCurrentStep(WalletCreationSteps.FORM);
		}
	}, [isOpen]);

	const title = useMemo(() => {
		if (currentStep === WalletCreationSteps.SUCCESS)
			return !editWallet
				? 'Carteira criada com sucesso!'
				: 'Alterações salvas';
		return !editWallet
			? 'Nova carteira de cobrança'
			: 'Editar carteira de cobrança';
	}, [currentStep, editWallet]);

	return (
		<Modal
			title={title}
			width={480}
			isOpen={isOpen}
			onClose={onClose}
			style={{
				maxHeight: '550px',
				overflowY: 'auto',
			}}
		>
			{contextHolder}
			{currentStep === WalletCreationSteps.FORM && isOpen && (
				<WalletCreationDataForm
					wallet={editWallet}
					isLoading={isPending}
					onCreate={wallet => {
						mutate(wallet);
					}}
				/>
			)}
			{currentStep === WalletCreationSteps.SUCCESS && isOpen && (
				<WalletCreationSuccess onClose={onClose} />
			)}
		</Modal>
	);
};

export default WalletCreation;
