const normalizer =
	(
		normalizerFunc: (v: string) => string,
		handleOnChange: (
			e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		) => void,
	) =>
	(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const {
			target: { value },
		} = e;
		e.target.value = normalizerFunc(value);
		return handleOnChange(e);
	};

export const getNumbersFromValue = (value = '') =>
	value.replace(/\D+/g, '') || '';

const addDigitToNumber = (number: string) => {
	const digitsStartPos = number.length - 1;
	const accNumber = number.substring(0, digitsStartPos);
	const digitNumber = number.substring(digitsStartPos);
	return accNumber + '-' + digitNumber;
};

export const formatNumberWithDigit = (value: string) => {
	const digits = getNumbersFromValue(value);
	return addDigitToNumber(digits);
};

export const normalizeBankAccount = (value: string) => {
	if (!value) {
		return value;
	}

	const res = formatNumberWithDigit(value).replace(',', '-');
	if (res === '-') {
		return '';
	}
	return res;
};

export const normalizePhone = (value: string): string => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length < 3) {
		return `(${onlyNums}`;
	}
	if (onlyNums.length <= 7) {
		return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
	}
	return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
		7,
		11,
	)}`;
};

export const normalizeCep = (value: string): string => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length < 6) {
		return `${onlyNums}`;
	}
	return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const normalizeCpfCnpj = (value: string): string => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 11) {
		return normalizeCpf(onlyNums);
	}
	return normalizeCnpj(onlyNums);
};

export const normalizeCpf = (value: string): string => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 3) {
		return `${onlyNums}`;
	}
	if (onlyNums.length <= 6) {
		return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
	}
	if (onlyNums.length <= 9) {
		return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
			6,
			9,
		)}`;
	}
	return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
		6,
		9,
	)}-${onlyNums.slice(9, 11)}`;
};

export const normalizeDate = (value: string): string => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 2) {
		return `${onlyNums}`;
	}
	if (onlyNums.length <= 4) {
		return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
	}

	return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
		4,
		8,
	)}`;
};

export const normalizeCnpj = (value: string): string => {
	if (!value) {
		return value;
	}

	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length < 3) {
		return `${onlyNums}`;
	}
	if (onlyNums.length < 6) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
	}
	if (onlyNums.length < 9) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
			5,
			8,
		)}`;
	}
	if (onlyNums.length < 13) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
			5,
			8,
		)}/${onlyNums.slice(8, 12)}`;
	}
	return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
		5,
		8,
	)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const formatCurrency = (value?: number, showSign = true) =>
	value !== undefined && value !== null && showSign
		? value.toLocaleString('pt-br', {
				style: 'currency',
				currency: 'BRL',
			})
		: value !== undefined && value !== null
			? value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
			: value;

/**
 *Format a number to fixed value without rounding
 * @param {number} num Number to format
 * @param {number} precision Precision to format
 * @returns
 */
export const formatToFixed = (num: number, precision: number) => {
	const decimals = Math.pow(10, precision);
	return Math.floor(num * decimals) / decimals;
};

export const formatToCurrencyFloat = (num: string) => {
	return num.replaceAll('.', '').replace(',', '.');
};

export const normalizeAddDigits = (value: number, digits = 2) => {
	if (!value || !digits) return '0';

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: digits,
	}).format(value);
};

export const capitalize = (str: string): string => {
	const finalSentence = str
		.toLocaleLowerCase()
		.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
	return finalSentence;
};

export const formatNumberToStringWithComma = (value: number) => {
	return value.toLocaleString('pt-BR');
};

const TYPE_BOLETO_TAX = /(\d{12})(\d{12})(\d{12})(\d{12})/;

const TYPE_BOLETO_NORMAL =
	/(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d)(\d{14})/;

export function normalizeBoleto(codigo?: string) {
	if (!codigo) {
		return '';
	}

	const filteredNumbers = codigo.replace(/\D/g, '');

	const formatTypeTax = (value: string) => {
		return value.replace(TYPE_BOLETO_TAX, '$1 $2 $3 $4');
	};

	const formatNormal = (value: string) => {
		return value.replace(TYPE_BOLETO_NORMAL, '$1.$2 $3.$4 $5.$6 $7 $8');
	};

	const isTypeTax = (value: string) => {
		return value.charAt(0) === '8';
	};

	return isTypeTax(filteredNumbers)
		? formatTypeTax(filteredNumbers)
		: formatNormal(filteredNumbers);
}

export default normalizer;
