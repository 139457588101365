import { Button, Modal, Row, notification } from 'antd';
import { Footer } from './styles';
import { DocumentType, UploaderArea } from 'components/UploaderArea';
import { useCallback, useEffect, useState } from 'react';
import { UploadService } from 'modules/core/services/UploadService';
import { ApiError } from 'types/ApiError';

const OpeningDocumentType: DocumentType = {
	label: 'Contrato de Abertura de Conta',
	type: 'agreement',
};

interface IOpeningDocumentsProps {
	isOpen: boolean;
	onClose: () => void;
	handleConfirm: (uploadKey: string) => void;
	isPending?: boolean;
}
const OpeningDocuments = ({
	isOpen,
	isPending,
	onClose,
	handleConfirm,
}: IOpeningDocumentsProps) => {
	const [api, contextHolder] = notification.useNotification();
	const [uploadKey, setUploadKey] = useState<string | null>(null);
	const [isLoading, toggleLoading] = useState<boolean>(false);

	const onConfirmUpload = useCallback(async () => {
		if (!uploadKey) {
			api.error({
				description: 'Por favor, envie o arquivo antes de continuar.',
				message: 'Ocorreu um problema ao enviar o arquivo.',
			});
			return;
		}

		handleConfirm(uploadKey);
	}, [api, uploadKey, handleConfirm]);

	useEffect(() => {
		if (isOpen) {
			setUploadKey(null);
		}
	}, [isOpen]);

	return (
		<Modal
			title="Contrato de Abertura de Conta"
			centered
			open={isOpen}
			footer={null}
			destroyOnClose
			onCancel={onClose}
			width={600}
		>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<UploaderArea
					document={OpeningDocumentType}
					loading={isLoading}
					uploaded={uploadKey !== null}
					onUpload={file => {
						toggleLoading(true);
						UploadService.uploadFile(file)
							.then(upload => {
								setUploadKey(upload.key);
								toggleLoading(false);
							})
							.catch((e: ApiError) => {
								api.error({
									description: e.data.message,
									message:
										'Ocorreu um problema ao enviar o arquivo. ',
								});
								toggleLoading(false);
							});
					}}
				/>
			</Row>
			<Footer>
				<Button
					type="primary"
					loading={isLoading || isPending}
					onClick={onConfirmUpload}
				>
					Enviar
				</Button>
			</Footer>
		</Modal>
	);
};

export default OpeningDocuments;
