import React from 'react';
import { Spinner, UploaderAreaContainer, UploaderAreaContent } from './styles';
import { useDropzone } from 'react-dropzone';
import { DocumentType } from './types';
import { Flex } from 'antd';
import { TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { BsUpload } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { PiSpinnerBold } from 'react-icons/pi';

interface IUploaderAreaProps {
	loading?: boolean;
	uploaded?: boolean;
	document: DocumentType;
	onUpload: (file: File, document: DocumentType) => void;
}
export function UploaderArea({
	loading,
	uploaded,
	document,
	onUpload,
}: IUploaderAreaProps) {
	const theme = useTheme();

	const onDrop = (file: File[]) => {
		onUpload(file[0], document);
	};

	const { getRootProps, getInputProps, isDragAccept, isDragActive } =
		useDropzone({
			onDrop,
			maxFiles: 1,
			multiple: false,
			accept: {
				'image/*': ['.jpeg', '.jpg', '.png'],
				'application/pdf': [],
				'application/msword': [],
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
					[],
			},
		});

	const isInvalidFile = isDragActive && !isDragAccept;

	return (
		<UploaderAreaContainer isInvalid={isInvalidFile} isDisabled={uploaded}>
			<UploaderAreaContent
				{...getRootProps({
					onClick: (event: React.MouseEvent) => {
						if (loading) {
							event.stopPropagation();
						}
					},
				})}
			>
				{uploaded ? (
					<Flex align="center" vertical justify="center">
						<FaCheck size={32} />
						<TextM
							style={{
								color: theme.text,
								marginTop: '1rem',
							}}
						>
							{document.label}
						</TextM>
					</Flex>
				) : (
					<>
						<input disabled={loading} {...getInputProps()} />
						{loading ? (
							<Flex align="center" vertical justify="center">
								<Spinner>
									<PiSpinnerBold style={{ fontSize: 32 }} />
								</Spinner>
								<TextM
									style={{
										color: theme.text,
										marginTop: '1rem',
									}}
								>
									{`${document.label} sendo carregado...`}
								</TextM>
							</Flex>
						) : (
							<Flex justify="center" align="center">
								<TextS
									style={{
										color: isInvalidFile
											? theme.error
											: theme.primary,
									}}
								>
									<Flex
										align="center"
										vertical
										justify="center"
									>
										<BsUpload size={32} />
										<TextM
											style={{
												color: theme.primary,
												marginTop: '1rem',
											}}
										>
											{isInvalidFile
												? 'Tipo de arquivo não suportado'
												: isDragActive
													? 'Solte aqui'
													: document.label}
										</TextM>
									</Flex>
								</TextS>
							</Flex>
						)}
					</>
				)}
			</UploaderAreaContent>
		</UploaderAreaContainer>
	);
}
