import React from 'react';
import { NavBarUserArea, NavBarWrapper } from './styles';
import { UserMenu } from 'components/UserMenu';
import { NavBarCompanyList } from './NavBarCompanyList';

export const NavBar = () => {
	return (
		<NavBarWrapper>
			<NavBarCompanyList />
			<NavBarUserArea>
				<UserMenu />
			</NavBarUserArea>
		</NavBarWrapper>
	);
};
