import { Button, Form } from 'antd';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { LoginFormType } from './types';

export const LoginContainer = styled.div`
	width: 100%;
	max-width: 25rem;
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem 1rem;
	align-items: center;
	box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 0.4rem;
	border: 1px solid ${p => p.theme.border};
	background-color: ${p => p.theme.white};
`;

export const LoginFormContainer = styled(Form<LoginFormType>)`
	padding: 1rem 0;
	width: 100%;
	position: relative;
`;

export const LoginForgotPassword = styled(RouterLink)`
	font-size: 0.875rem;
	margin-top: -0.1rem;
	display: block;
	text-align: right;
	color: ${p => p.theme.primary};
`;

export const LoginFormSubmit = styled(Button)`
	margin: 0 auto;
	width: 100%;
	margin-top: 1rem;
`;
