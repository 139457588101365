import styled from 'styled-components';

export const InformationContent = styled.div`
	display: flex;
	align-items: center;
	.message {
		margin-left: 1rem;
		p {
			color: ${p => p.theme.textSecondary};
		}
	}
`;

export const InformationButtons = styled.div`
	margin-top: 40px;
	button {
		height: 45px !important;
	}
`;
