import { Button, Col, Row } from 'antd';
import { Modal } from 'components/Modal';
import { TextL, TextM, TextS } from 'components/Text';
import React from 'react';
import { ChargeReviewParsed } from '../types';
import { WalletContent } from 'types/Wallet';
import { formatCurrency } from 'helpers/normalizers';
import { BackgroundModalData } from './styles';
import theme from 'theme/theme';

export interface IImportConfirmationModalProps {
	isOpen: boolean;
	totalCharges: ChargeReviewParsed[];
	totalAmount: number;
	wallet: WalletContent;
	onClose: () => void;
	onConfirm: () => void;
}

const ImportConfirmationModal = ({
	isOpen,
	totalAmount,
	totalCharges,
	wallet,
	onClose,
	onConfirm,
}: IImportConfirmationModalProps) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				title="Upload de CNAB"
				onClose={onClose}
				width={500}
			>
				<Row style={{ marginBottom: '1.4rem' }}>
					<TextL weight="bold">
						Você tem certeza que deseja gerar as cobranças?
					</TextL>
				</Row>
				<BackgroundModalData>
					<Row>
						<Col span={12}>
							<TextM weight="bold">Total de boletos:</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{totalCharges.length || 0}
							</TextS>
						</Col>
						<Col span={12}>
							<TextM weight="bold">Valor total:</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{formatCurrency(totalAmount)}
							</TextS>
						</Col>
					</Row>
				</BackgroundModalData>

				<Row
					style={{
						marginTop: '1.4rem',
					}}
				>
					<TextM>
						Ao realizar esta ação, os boletos serão adicionados a
						carteira {wallet.name}
					</TextM>
				</Row>

				<Row
					style={{
						marginTop: '1rem',
						width: '100%',
					}}
				>
					<Button
						type="primary"
						onClick={onConfirm}
						style={{
							width: '100%',
						}}
					>
						<TextS
							style={{
								color: theme.white,
							}}
						>
							Confirmar
						</TextS>
					</Button>

					<Button
						onClick={onClose}
						type="default"
						style={{
							width: '100%',
							border: 0,
							marginTop: '1rem',
						}}
					>
						<TextS
							style={{
								color: theme.primary,
							}}
						>
							Cancelar
						</TextS>
					</Button>
				</Row>
			</Modal>
		</>
	);
};

export default ImportConfirmationModal;
