import { TableProps } from 'antd';
import { Table as TableAnt } from './styles';

interface ITableProps extends TableProps {
	borderless?: boolean;
}
const Table = (props: ITableProps) => {
	return (
		<TableAnt sortDirections={['ascend', 'descend', 'ascend']} {...props} />
	);
};

export default Table;
