import React from 'react';

export const CancelIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.91224 5.23276L15.2669 14.5874C16.145 13.4582 16.667 12.0403 16.667 10.4993C16.667 6.81745 13.6822 3.83268 10.0003 3.83268C8.45941 3.83268 7.04148 4.35472 5.91224 5.23276ZM14.0884 15.7659L4.73373 6.41127C3.85569 7.54051 3.33366 8.95843 3.33366 10.4993C3.33366 14.1813 6.31843 17.166 10.0003 17.166C11.5412 17.166 12.9592 16.644 14.0884 15.7659ZM4.10777 4.60679C5.61489 3.09967 7.69937 2.16602 10.0003 2.16602C14.6027 2.16602 18.3337 5.89697 18.3337 10.4993C18.3337 12.8003 17.4 14.8848 15.8929 16.3919C14.3857 17.899 12.3012 18.8327 10.0003 18.8327C5.39795 18.8327 1.66699 15.1017 1.66699 10.4993C1.66699 8.19839 2.60064 6.11392 4.10777 4.60679Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
