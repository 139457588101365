import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import CelcoinLogo from './CelcoinLogo.png';
import dayjs from 'dayjs';
import {
	formatCurrency,
	normalizeBoleto,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { BarCode } from 'types/Posting';
import InfoIcon from 'components/images/InfoIcon.png';

export interface PaymentCreationReceiptProps {
	transactionId: string;
	paymentNumber: string;
	paymentDate: string;
	paymentData: BarCode;
	paymentAmount: number;
}

Font.register({
	family: 'Urbanist',
	fonts: [
		{
			src: `/Urbanist.ttf`,
		},
		{
			src: `/Urbanist-SemiBold.ttf`,
			fontWeight: 'semibold',
		},
	],
});

const styles = StyleSheet.create({
	hero: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#806DFB',
		padding: 10,
	},
	title: {
		fontSize: 16,
		textAlign: 'center',
		width: '100%',
		color: '#1E1360',
		marginTop: 20,
		fontWeight: 'bold',
	},
	subtitle: {
		fontSize: 13,
		textAlign: 'center',
		width: '100%',
		color: '#6F6B82',
	},
	amount: {
		marginTop: 3,
		fontSize: 13,
		textAlign: 'center',
		width: '100%',
		color: '#806DFB',
	},
	sectionWrapper: {
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		border: 2,
		borderStyle: 'solid',
		borderColor: '#F7F7F7',
		padding: 20,
		borderRadius: 5,
	},
	sectionTitle: {
		fontSize: 14,
		width: '100%',
		fontWeight: 'semibold',
	},
	sectionRow: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		marginTop: 12,
	},
	rowTitle: {
		fontSize: 10,
		fontWeight: 'semibold',
	},
	rowValue: {
		fontSize: 10,
		color: '#6F6B82',
	},
	infoCard: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#FFF7E5',
		padding: 17,
		gap: 9,
		border: 1,
		borderRadius: 10,
		borderColor: '#FDD9B8',
	},
	infoCardText: {
		fontSize: 12,
		fontFamily: 'Urbanist',
		display: 'flex',
		flexWrap: 'wrap',
		lineHeight: 1.5,
	},
	footer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#F7F7F7',
		padding: 20,
	},
	footerColumn: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
	},
});

const PaymentCreationReceipt = ({
	paymentAmount,
	paymentData,
	paymentDate,
	paymentNumber,
	transactionId,
}: PaymentCreationReceiptProps) => {
	return (
		<Document>
			<Page size="A4">
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>
					<View>
						<View style={styles.hero}>
							<View
								style={{
									height: '80px',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Image
									src={CelcoinLogo}
									style={{ width: '182px' }}
								/>
							</View>
						</View>
						<View>
							<Text style={styles.title}>
								Solicitação de Pagamento
							</Text>
							<Text style={{ ...styles.subtitle, marginTop: 6 }}>
								{dayjs().format('DD/MM/YYYY')} às{' '}
								{dayjs().format('HH:mm')}
							</Text>
						</View>

						<View>
							<Text style={{ ...styles.subtitle, marginTop: 16 }}>
								Valor
							</Text>
							<Text style={styles.amount}>
								{formatCurrency(paymentAmount)}
							</Text>
						</View>

						<View style={styles.sectionWrapper}>
							<View style={styles.section}>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Valor do documento
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{formatCurrency(paymentData.value)}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Multa
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{paymentData.register_data
												? formatCurrency(
														paymentData
															.register_data
															.total_with_additional,
													)
												: '-'}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Desconto
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{paymentData.register_data
												? formatCurrency(
														paymentData
															.register_data
															.total_with_discount,
													)
												: '-'}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Vencimento
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{paymentData.register_data
												? dayjs(
														paymentData.due_date ??
															paymentData
																.register_data
																.due_date_register,
													).format('DD/MM/YYYY')
												: '-'}
										</Text>
									</View>
								</View>
							</View>

							<View
								style={{ ...styles.section, marginTop: '1rem' }}
							>
								<Text style={styles.sectionTitle}>
									Dados da cobrança:
								</Text>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Pagador
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{paymentData.register_data ? (
												<>
													{
														paymentData
															.register_data.payer
													}
													{' - '}
													{normalizeCpfCnpj(
														paymentData
															?.register_data
															?.document_payer ??
															'',
													)}
												</>
											) : (
												'-'
											)}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Beneficiário
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{paymentData.register_data ? (
												<>
													{
														paymentData
															.register_data
															.recipient
													}
													{' - '}
													{normalizeCpfCnpj(
														paymentData
															?.register_data
															?.document_recipient ??
															'',
													)}
												</>
											) : (
												'-'
											)}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Código de barras
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{normalizeBoleto(
												paymentData.digitable,
											)}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Identificador
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{paymentData.transaction_id ?? '-'}
										</Text>
									</View>
								</View>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '30%',
										}}
									>
										<Text style={styles.rowTitle}>
											Agendado para
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{dayjs(
												paymentDate,
												'YYYY-MM-DD',
											).format('DD/MM/YYYY')}
										</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={styles.sectionWrapper}>
							<View style={styles.infoCard}>
								<Image
									src={InfoIcon}
									style={{ width: '16px', height: '16px' }}
								/>
								<View>
									<Text style={styles.infoCardText}>
										Este documento não deve ser utilizado
										como comprovante.
									</Text>
									<Text
										style={{
											...styles.infoCardText,
											flexWrap: 'wrap',
										}}
									>
										O seu comprovante será disponibilizado
										no extrato assim que a transação for
										{'\n'}
										aprovada/processada.
									</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.footer}>
						<View style={styles.footerColumn}>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								Código de Identificação
							</Text>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								{transactionId || '-'}
							</Text>
						</View>
						<View style={styles.footerColumn}>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								Celcoin Instituição de Pagamento S.A
							</Text>
							<Text style={{ fontSize: 10, color: '#6F6B82' }}>
								13.935.893/0001-09
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default PaymentCreationReceipt;
