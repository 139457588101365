import React, { useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input, Popover } from 'antd';
import { NewPasswordFormContainer, NewPasswordFormSubmit } from './styles';
import { NewPasswordFormType } from './types';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { PasswordStrength } from 'components/PasswordStrength';
import { CognitoErrorCode } from 'modules/core/types/Cognito';

const NewPasswordForm: React.FC = () => {
	const [password, updatePassword] = useState('');
	const [isInvalid, updateInvalid] = useState(false);
	const [form] = Form.useForm();
	const { confirmNewPassword, resetError, errorCode, isLoading } =
		useAuthContext();
	const onFinish = (values: NewPasswordFormType) => {
		if (values.confirm === values.password) {
			confirmNewPassword(values.password);
		}
	};

	return (
		<NewPasswordFormContainer
			style={{ width: '90%' }}
			initialValues={{ remember: true }}
			form={form}
			layout="vertical"
			validateTrigger="onBlur"
			onValuesChange={value => {
				if (typeof value.password === 'string') {
					updatePassword(value.password);
					resetError();
				}
			}}
			onFinish={onFinish}
		>
			<Popover
				content={
					<PasswordStrength
						password={password}
						onChangePasswordStrength={strength =>
							updateInvalid(strength < 3)
						}
					/>
				}
				placement="right"
				trigger="focus"
			>
				<Form.Item
					name="password"
					label="Senha:"
					rules={[
						{ required: true, message: 'Campo obrigatório' },
						() => ({
							validator() {
								if (!isInvalid) {
									return Promise.resolve();
								}
								return Promise.reject(
									new Error(
										'Senha fraca, tente uma senha mais forte',
									),
								);
							},
						}),
						() => ({
							validator() {
								if (
									errorCode ===
									CognitoErrorCode.NEW_PASSWORD_FAILED
								) {
									return Promise.reject(
										new Error('Erro ao criar nova senha'),
									);
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input
						prefix={<LockOutlined />}
						type="password"
						placeholder="Senha"
					/>
				</Form.Item>
			</Popover>
			<Form.Item
				name="confirm"
				label="Confirmar Senha:"
				rules={[
					{
						required: true,
						message: 'Campo obrigatório',
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(
								new Error(
									'Senhas não conferem, tente novamente',
								),
							);
						},
					}),
					() => ({
						validator() {
							if (
								errorCode ===
								CognitoErrorCode.NEW_PASSWORD_FAILED
							) {
								return Promise.reject(
									new Error('Erro ao criar nova senha'),
								);
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input
					prefix={<LockOutlined />}
					type="password"
					placeholder="Senha"
				/>
			</Form.Item>
			<NewPasswordFormSubmit
				loading={isLoading}
				type="primary"
				htmlType="submit"
			>
				Confirmar
			</NewPasswordFormSubmit>
		</NewPasswordFormContainer>
	);
};

export default NewPasswordForm;
