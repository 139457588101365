import { CollapseProps } from 'antd';
import { Collapse as CollapseAnt, CollapseExpander } from './styles';
import { CustomSwitch } from 'components/CustomSwitch';
import { SwitchSize, Variant } from 'components/CustomSwitch/CustomSwitch';

interface ICardConfigProps extends CollapseProps {
	noPadding?: boolean;
	handleCollapse: (keys: string | string[]) => void;
}
const CardConfig = (props: ICardConfigProps) => {
	return (
		<CollapseAnt
			expandIconPosition="end"
			collapsible="icon"
			onChange={(activeKey: string | string[]) => {
				props.handleCollapse(activeKey);
			}}
			expandIcon={({ isActive }) => {
				return (
					<CollapseExpander isActive={isActive}>
						<CustomSwitch
							variant={Variant.SECONDARY}
							checked={isActive || false}
							handleChange={() => {}}
							size={SwitchSize.MEDIUM}
						/>
					</CollapseExpander>
				);
			}}
			{...props}
		/>
	);
};

export default CardConfig;
