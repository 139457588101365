import { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { PageWrapper } from 'components/PageWrapper';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { UsersIcon } from 'modules/users/constants/routes';
import { UserCreation } from 'components/UserCreation';
import { PageActions } from 'components/PageActions';
import { UsersListTable } from 'components/UsersListTable';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { Navigate, useNavigate } from 'react-router-dom';
import { LuPlusCircle } from 'react-icons/lu';
import { UserListContent } from 'types/User';
import { UserBlocking } from 'components/UserBlocking';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';

const UsersListAdmin = () => {
	const { setBreadcrumb } = useBreadcrumb();
	const { person: company, type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const navigate = useNavigate();
	const [isUserCreationOpen, toggleUserCreationOpen] = useState(false);
	const [isUserCreationSuccessOpen, toggleIsUserCreationSuccessOpen] =
		useState(false);
	const [isUserBlockingOpen, toggleUserBlockingOpen] = useState<
		UserListContent | false
	>(false);

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/company',
				title: (
					<BreadcrumbContainer>
						<UsersIcon />
						<span>Acessos</span>
					</BreadcrumbContainer>
				),
			},
		]);
	}, [setBreadcrumb]);

	if (type !== AccessType.FINANCIAL_INSTITUTION) {
		return <Navigate to="/" />;
	}

	return (
		<>
			{contextHolder}
			<PageHeader>
				<Heading1>Acessos</Heading1>
				<PageActions>
					<Button
						type="primary"
						onClick={() => toggleUserCreationOpen(true)}
					>
						Criar Usuário
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				</PageActions>
			</PageHeader>
			<PageWrapper>
				<UsersListTable
					onView={user => navigate(`/users/${user.id}`)}
					onBlock={user => toggleUserBlockingOpen(user)}
				/>
			</PageWrapper>
			<UserBlocking
				isOpen={!!isUserBlockingOpen}
				user={isUserBlockingOpen}
				onClose={() => {
					toggleUserBlockingOpen(false);
				}}
				onError={() => {
					toggleUserBlockingOpen(false);
					api.error({
						message: 'Erro ao bloquear usuário.',
					});
				}}
			/>
			<UserCreation
				isOpen={isUserCreationOpen}
				companyId={company?.id}
				onClose={() => {
					toggleUserCreationOpen(false);
				}}
				onSuccess={() => {
					toggleUserCreationOpen(false);
					toggleIsUserCreationSuccessOpen(true);
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserCreationSuccessOpen}
				title="Acesso criado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso criado com sucesso!"
				onClose={() => toggleIsUserCreationSuccessOpen(false)}
			/>
		</>
	);
};

export default UsersListAdmin;
