import dayjs from 'dayjs';
import pt from 'antd/es/date-picker/locale/pt_BR';
import ptBR from 'antd/es/locale/pt_BR';

const getEasterDay = (year: number) => {
	const c = Math.floor(year / 100);
	const n = year - 19 * Math.floor(year / 19);
	const k = Math.floor((c - 17) / 25);
	let i = c - Math.floor(c / 4) - Math.floor((c - k) / 3) + 19 * n + 15;
	i = i - 30 * Math.floor(i / 30);
	i =
		i -
		Math.floor(i / 28) *
			(1 -
				Math.floor(i / 28) *
					Math.floor(29 / (i + 1)) *
					Math.floor((21 - n) / 11));
	let j = year + Math.floor(year / 4) + i + 2 - c + Math.floor(c / 4);
	j = j - 7 * Math.floor(j / 7);
	const l = i - j;
	const month = 3 + Math.floor((l + 40) / 44);
	const day = l + 28 - 31 * Math.floor(month / 4);
	return dayjs(
		`${year}-${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}`,
	);
};

export const getHolidays = (year: number) => {
	const anoNovo = dayjs(`01/01/${year}`, 'DD/MM/YYYY');
	const carnaval1 = getEasterDay(year).add(-48, 'day');
	const carnaval2 = getEasterDay(year).add(-47, 'day');
	const paixaoCristo = getEasterDay(year).add(-2, 'day');
	const pascoa = getEasterDay(year);
	const tiradentes = dayjs(`21/04/${year}`, 'DD/MM/YYYY');
	const corpusChristi = getEasterDay(year).add(60, 'day');
	const diaTrabalho = dayjs(`01/05/${year}`, 'DD/MM/YYYY');
	const diaIndependencia = dayjs(`07/09/${year}`, 'DD/MM/YYYY');
	const nossaSenhora = dayjs(`12/10/${year}`, 'DD/MM/YYYY');
	const finados = dayjs(`02/11/${year}`, 'DD/MM/YYYY');
	const proclamaRepublica = dayjs(`15/11/${year}`, 'DD/MM/YYYY');
	const natal = dayjs(`25/12/${year}`, 'DD/MM/YYYY');
	return [
		{ m: anoNovo, dia: 'Ano Novo', d: anoNovo.format('DD/MM/YYYY') },
		{ m: carnaval1, dia: 'Carnaval', d: carnaval1.format('DD/MM/YYYY') },
		{ m: carnaval2, dia: 'Carnaval', d: carnaval2.format('DD/MM/YYYY') },
		{
			m: paixaoCristo,
			dia: 'Paix\u00E3o de Cristo',
			d: paixaoCristo.format('DD/MM/YYYY'),
		},
		{ m: pascoa, dia: 'Páscoa', d: pascoa.format('DD/MM/YYYY') },
		{
			m: tiradentes,
			dia: 'Tiradentes',
			d: tiradentes.format('DD/MM/YYYY'),
		},
		{
			m: corpusChristi,
			dia: 'Corpus Christi',
			d: corpusChristi.format('DD/MM/YYYY'),
		},
		{
			m: diaTrabalho,
			dia: 'Dia do Trabalho',
			d: diaTrabalho.format('DD/MM/YYYY'),
		},
		{
			m: diaIndependencia,
			dia: 'Dia da Independ\u00EAncia do Brasil',
			d: diaIndependencia.format('DD/MM/YYYY'),
		},
		{
			m: nossaSenhora,
			dia: 'Nossa Senhora Aparecida',
			d: nossaSenhora.format('DD/MM/YYYY'),
		},
		{ m: finados, dia: 'Finados', d: finados.format('DD/MM/YYYY') },
		{
			m: proclamaRepublica,
			dia: 'Proclamação da República',
			d: proclamaRepublica.format('DD/MM/YYYY'),
		},
		{ m: natal, dia: 'Natal', d: natal.format('DD/MM/YYYY') },
	];
};

export const dayOfWeek = [
	{
		label: 'DOM',
		value: 'SUNDAY',
	},
	{
		label: 'SEG',
		value: 'MONDAY',
	},
	{
		label: 'TER',
		value: 'TUESDAY',
	},
	{
		label: 'QUA',
		value: 'WEDNESDAY',
	},
	{
		label: 'QUI',
		value: 'THURSDAY',
	},
	{
		label: 'SEX',
		value: 'FRIDAY',
	},
	{
		label: 'SAB',
		value: 'SATURDAY',
	},
];

export const requestTime = [
	{
		label: '30 minutos',
		value: 30,
	},
	{
		label: '1 hora',
		value: 60,
	},
	{
		label: '1 hora e 30 minutos',
		value: 90,
	},
	{
		label: '2 horas',
		value: 120,
	},
	{
		label: '2 horas e 30 minutos',
		value: 150,
	},
	{
		label: '3 horas',
		value: 180,
	},
	{
		label: '3 horas e 30 minutos',
		value: 210,
	},
	{
		label: '4 horas',
		value: 240,
	},
	{
		label: '4 horas e 30 minutos',
		value: 270,
	},
	{
		label: '5 horas',
		value: 300,
	},
	{
		label: '5 horas e 30 minutos',
		value: 330,
	},
	{
		label: '6 horas',
		value: 360,
	},
	{
		label: '6 horas e 30 minutos',
		value: 390,
	},
	{
		label: '7 horas',
		value: 420,
	},
	{
		label: '7 horas e 30 minutos',
		value: 450,
	},
	{
		label: '8 horas',
		value: 480,
	},
	{
		label: '8 horas e 30 minutos',
		value: 510,
	},
	{
		label: '9 horas',
		value: 540,
	},
	{
		label: '9 horas e 30 minutos',
		value: 570,
	},
	{
		label: '10 horas',
		value: 600,
	},
	{
		label: '10 horas e 30 minutos',
		value: 630,
	},
	{
		label: '11 horas',
		value: 660,
	},
	{
		label: '11 horas e 30 minutos',
		value: 690,
	},
	{
		label: '12 horas',
		value: 720,
	},
];

export const convertMinutesToHours = (minutes?: number): string => {
	if (minutes === undefined) {
		return '';
	}

	const horas = Math.floor(minutes / 60);
	const minutosRestantes = minutes % 60;

	if (horas > 0) {
		return minutosRestantes > 0
			? `${horas} horas e ${minutosRestantes} minutos`
			: `${horas} horas`;
	}

	return `${minutosRestantes} minutos`;
};

const ptBrLocale: typeof pt = {
	...pt,
	lang: {
		...pt.lang,
	},
};

const globalPtBrLocale: typeof ptBR = {
	...ptBR,
	DatePicker: {
		...ptBR.DatePicker!,
		lang: ptBrLocale.lang,
	},
	locale: 'pt_BR',
};

export const localeDatePicker = {
	...globalPtBrLocale,
	lang: globalPtBrLocale.DatePicker!.lang,
	timePickerLocale: globalPtBrLocale.DatePicker!.timePickerLocale,
};
