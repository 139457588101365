import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
	color: ${({ theme }) => theme.primary};

	svg {
		fill: ${({ theme }) => theme.primary};
	}
`;
