import React from 'react';
import { SplashScreenContent } from './styles';
import { Spin } from 'antd';

export function SplashScreen() {
	return (
		<SplashScreenContent>
			<Spin
				tip="Carregando"
				size="large"
				style={{
					height: '100px',
					width: '100px',
				}}
			>
				<div className="content" />
			</Spin>
		</SplashScreenContent>
	);
}
