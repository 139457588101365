import { Button, List, notification } from 'antd';
import { useEffect, useMemo } from 'react';
import { ContainerFluid, ContainerHeader, Footer } from './styles';
import { TextM, TextS } from 'components/Text';
import { Representative } from 'types/Representative';
import { FullAccountRelatedPart, StepsId } from './types';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { Heading2 } from 'components/Heading2';
import { useTheme } from 'styled-components';
import { CELCOIN_CNPJ, CELCOIN_NAME } from 'constants/company';
import { CustomSwitch } from 'components/CustomSwitch';

export interface ParsedRepresentative extends Representative {
	representatives: Representative[];
	relatedPart: FullAccountRelatedPart;
}

interface IEscrowCreationSignersProps {
	isLoading: boolean;
	relatedParts: FullAccountRelatedPart[];
	onNext: () => void;
	onBack: () => void;
	handleStepStatus: (stepsId: StepsId, status: boolean) => void;
	updateRelatedPartsSigners?: (relatedPartId: FullAccountRelatedPart) => void;
}
const EscrowCreationSigners = ({
	relatedParts,
	isLoading,
	onNext,
	onBack,
	updateRelatedPartsSigners,
	handleStepStatus,
}: IEscrowCreationSignersProps) => {
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const companies = useMemo(() => {
		return [
			...relatedParts
				.filter(relatedPart => relatedPart.sign_contract)
				.map(relatedPart => relatedPart.companyDetails!),
		];
	}, [relatedParts]);

	useEffect(() => {
		if (
			typeof handleStepStatus === 'function' &&
			companies &&
			companies.length > 0
		) {
			handleStepStatus(StepsId.SIGNERS, true);
			return;
		}

		handleStepStatus && handleStepStatus(StepsId.SIGNERS, false);
	}, [handleStepStatus, companies]);

	const personItems = useMemo(() => {
		// Financial Institution fixed
		const financialInstituition = {
			representatives: [
				{
					full_name: CELCOIN_NAME,
					taxpayer_id: CELCOIN_CNPJ,
					sign_contract: true,
				} as Representative,
			],
			relatedPart: {
				sign_contract: true,
				companyDetails: {
					name: CELCOIN_NAME,
					taxpayer_id: CELCOIN_CNPJ,
				} as FullAccountRelatedPart['companyDetails'],
			} as FullAccountRelatedPart,
		} as ParsedRepresentative;

		const allRepresentatives = relatedParts.map(relatedPart => {
			return {
				representatives:
					relatedPart.companyDetails?.representatives || [],
				relatedPart,
			} as ParsedRepresentative;
		});

		return [financialInstituition, ...allRepresentatives];
	}, [relatedParts]);

	return (
		<>
			{contextHolder}
			<ContainerFluid>
				<ContainerHeader>
					<Heading2 style={{ fontSize: '1.5rem' }}>
						Assinantes
					</Heading2>
				</ContainerHeader>
				<List
					size="large"
					bordered
					dataSource={personItems}
					renderItem={(item, idx) => (
						<List.Item>
							<div>
								<div>
									<TextM>
										{item.relatedPart.companyDetails?.name}
									</TextM>
								</div>
								{item.representatives.map(representative => (
									<div
										style={{
											display: 'flex',
											gap: '0.5rem',
											alignItems: 'center',
										}}
									>
										<TextS
											style={{
												color: theme.textGray,
											}}
										>
											{representative.full_name}
										</TextS>
										<TextS
											style={{
												color: theme.textGray,
											}}
										>
											{representative.taxpayer_id?.includes?.(
												'*',
											)
												? representative.taxpayer_id
												: normalizeCpfCnpj(
														representative.taxpayer_id ||
															'-',
													)}
										</TextS>
									</div>
								))}
							</div>
							<div>
								<CustomSwitch
									checked={
										item.relatedPart.sign_contract || false
									}
									handleChange={() => {
										if (idx === 0 || idx === 1) {
											api.info({
												message:
													'Esse assinante é obrigatório',
											});
											return;
										}
										updateRelatedPartsSigners?.(
											item.relatedPart!,
										);
									}}
								/>
							</div>
						</List.Item>
					)}
				/>

				<Footer>
					<Button
						type="link"
						onClick={() => {
							onBack();
							handleStepStatus(StepsId.SIGNERS, false);
						}}
					>
						Voltar
					</Button>
					<Button
						type="primary"
						onClick={() => onNext()}
						loading={isLoading}
					>
						Avançar
					</Button>
				</Footer>
			</ContainerFluid>
		</>
	);
};

export default EscrowCreationSigners;
