import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/pt_BR';
import React, { FC, ReactNode } from 'react';

interface IFormThemeProviderProps {
	children: ReactNode;
}
export const FormThemeProvider: FC<IFormThemeProviderProps> = ({
	children,
}) => {
	return (
		<ConfigProvider
			locale={locale}
			theme={{
				components: {
					Form: {
						itemMarginBottom: 16,
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	);
};
