import styled from 'styled-components';

export const ConfirmationContent = styled.div`
	p {
		color: ${p => p.theme.textSecondary};
	}
`;

export const ConfirmationButtons = styled.div`
	margin-top: 40px;
	button {
		height: 45px !important;
	}
`;
