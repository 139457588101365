import styled, { css } from 'styled-components';
import { SwitchSize, Variant } from './CustomSwitch';

export const Switch = styled.div<{ size: SwitchSize; variant: Variant }>`
	position: relative;
	width: 46px;
	height: 28px;
	background: #b3b3b3;
	border-radius: 32px;
	padding: 4px;
	transition: 300ms all;
	cursor: pointer;

	&:before {
		transition: 300ms all;
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 35px;
		top: 50%;
		left: 4px;
		background: white;
		transform: translate(0, -50%);
		cursor: pointer !important;
	}

	${p => getSwitchSize(p.size)}
	${({ variant, theme }) =>
		variant === Variant.SECONDARY &&
		css`
			border: 1px solid #7664fa66;
			background: ${theme.white};

			&:before {
				background: ${theme.primary};
			}
		`}
`;

export const Input = styled.input<{ variant: Variant }>`
	display: none;

	&:checked + ${Switch} {
		background: ${({ theme }) => theme.primary};
		cursor: pointer;

		&:before {
			transform: translate(16px, -50%);
			cursor: pointer;

			${({ variant, theme }) =>
				variant === Variant.SECONDARY &&
				css`
					background: ${theme.white};
				`};
		}
	}
`;

const getSwitchSize = (size: SwitchSize) => {
	switch (size) {
		case SwitchSize.SMALL:
			return `
				width: 36px;
				height: 20px;
				&:before {
					width: 16px;
					height: 16px;
					left: 2px;
				}
			`;
		case SwitchSize.MEDIUM:
			return `
				width: 46px;
				height: 28px;
				&:before {
					width: 20px;
					height: 20px;
					left: 4px;
				}
			`;
		case SwitchSize.LARGE:
			return `
				width: 56px;
				height: 36px;
				&:before {
					width: 24px;
					height: 24px;
					left: 6px;
				}
			`;
	}
};
