import dayjs from 'dayjs';
import { Table as ANTDTable, Button, Col, Divider, Flex, Row } from 'antd';
import { TableWrapper } from '../styles';
import { Table } from 'components/Table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import theme from 'theme/theme';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import { TextS } from 'components/Text';
import { DepositManagementItem, EDepositManagementLabel } from '../types';
import { RiPencilLine } from 'react-icons/ri';
import { FileCopyIcon } from 'components/Icons/FileCopy';
import { TrashIcon } from 'components/Icons/Trash';

interface IDepositManagementListProps {
	depositData?: DepositManagementItem[];
	deleteRetention: (item: DepositManagementItem) => void;
	duplicateRetention: (item: DepositManagementItem) => void;
	editRetention: (item: DepositManagementItem) => void;
	isLoading: boolean;
}

const DepositManagementList = ({
	depositData,
	deleteRetention,
	duplicateRetention,
	editRetention,
	isLoading,
}: IDepositManagementListProps) => {
	const columns: ColumnsType<DepositManagementItem> = [
		{
			key: '1',
			title: 'Nome',
			render: (data: DepositManagementItem) => <div>{data?.name}</div>,
			width: '20%',
		},
		{
			key: '2',
			title: 'Período',
			render: (data: DepositManagementItem) => (
				<div>
					{`de ${dayjs(data?.start_date).format('DD/MM/YYYY')} a ${dayjs(data?.end_date).format('DD/MM/YYYY')}`}
				</div>
			),
			width: '20%',
		},
		{
			key: '3',
			title: 'Total retido',
			render: (data: DepositManagementItem) => (
				<div>
					{data?.balance_retention?.blocked_amount
						? formatCurrency(
								data?.balance_retention?.blocked_amount,
							)
						: '-'}
				</div>
			),
			width: '20%',
		},
		{
			key: '4',
			title: 'Máximo retido',
			width: '20%',
			render: (data: DepositManagementItem) => (
				<div>
					{data?.max_amount ? formatCurrency(data?.max_amount) : '-'}
				</div>
			),
		},
		{
			key: '5',
			title: '%',
			width: '5%',
			render: (data: DepositManagementItem) => (
				<div>{data.amount_percentage}%</div>
			),
		},
		{
			key: '6',
			title: 'Origens específicas',
			width: '20%',
			render: (data: DepositManagementItem) => (
				<div>{!!data.origins ? 'Sim' : 'Não'}</div>
			),
		},
		{
			key: '7',
			title: 'Status',
			width: '20%',
			render: (data: DepositManagementItem) => (
				<div>{EDepositManagementLabel[data?.status]}</div>
			),
		},
		ANTDTable.EXPAND_COLUMN,
	];

	const expandedRowRender = (item: DepositManagementItem) => {
		const columns: ColumnsType<DepositManagementItem> = [
			{
				key: '1',
				title: 'CPF / CNPJ',
				render: (item: DepositManagementItem) => {
					return item?.origins?.map((document, key) => (
						<div key={key}>{normalizeCpfCnpj(document) || ''}</div>
					));
				},
				width: '30%',
			},
		];

		return (
			<>
				<Row>
					<Col span={20}>
						<Flex>
							<TextS
								weight="bold"
								style={{
									marginRight: '1rem',
									marginBottom: '1rem',
								}}
							>
								Descrição:
							</TextS>
							<TextS color={theme.textGray}>
								{item?.description || '-'}
							</TextS>
						</Flex>
					</Col>
					<Col span={4}>
						<Flex>
							<TextS
								weight="bold"
								style={{ marginRight: '1rem' }}
							>
								SLC:
							</TextS>
							<TextS color={theme.textGray}>
								{!!item?.should_retain_slc ? 'Sim' : 'Não'}
							</TextS>
						</Flex>
					</Col>
				</Row>
				{item?.destination?.taxpayer_id && (
					<>
						<Divider />
						<Row>
							<Col span={20}>
								<Flex>
									<TextS
										weight="bold"
										style={{
											fontSize: '0.9rem',
											marginBottom: '8px',
											marginRight: '1rem',
										}}
									>
										Beneficiário:
									</TextS>
									<TextS color={theme.text} weight="normal">
										{item.destination.name}
									</TextS>
								</Flex>
							</Col>
							<Col span={4}>
								<Flex>
									<TextS
										weight="bold"
										style={{
											fontSize: '0.9rem',
											marginBottom: '8px',
											marginRight: '1rem',
										}}
									>
										CPF / CNPJ:
									</TextS>
									<TextS color={theme.text} weight="normal">
										{normalizeCpfCnpj(
											item.destination.taxpayer_id,
										)}
									</TextS>
								</Flex>
							</Col>
						</Row>
						<br />
						<Row gutter={16}>
							<Col className="gutter-row" span={2}>
								<TextS color={theme.textGray}>Banco</TextS>
							</Col>
							<Col className="gutter-row" span={3}>
								<TextS>{item.destination.bank}</TextS>
							</Col>
							<Divider
								type="vertical"
								style={{ marginTop: '5px' }}
							/>
							<Col className="gutter-row" span={2}>
								<TextS color={theme.textGray}>Agência</TextS>
							</Col>
							<Col className="gutter-row" span={2}>
								<TextS>{item.destination.branch}</TextS>
							</Col>
							<Divider
								type="vertical"
								style={{ marginTop: '5px' }}
							/>
							<Col className="gutter-row" span={2}>
								<TextS color={theme.textGray}>Conta</TextS>
							</Col>
							<Col className="gutter-row" span={4}>
								<TextS>{item.destination.account}</TextS>
							</Col>
							<Divider
								type="vertical"
								style={{ marginTop: '5px' }}
							/>
							<Col className="gutter-row" span={2}>
								<TextS color={theme.textGray}>Tipo</TextS>
							</Col>
							<Col className="gutter-row" span={3}>
								<TextS>{item.destination.type}</TextS>
							</Col>
						</Row>
						<Divider />
						{item.origins && item.origins?.length > 0 ? (
							<>
								<TextS
									weight="bold"
									style={{ marginBottom: '10px' }}
								>
									Origens retidas:
								</TextS>
								<Table
									pagination={false}
									columns={columns}
									rowKey={record => record.id}
									dataSource={[item]}
								/>
							</>
						) : undefined}
					</>
				)}

				<Col>
					<Flex
						gap={10}
						justify="flex-end"
						style={{ marginTop: '24px', marginBottom: '12px' }}
					>
						<Button
							onClick={() => deleteRetention(item)}
							type="default"
						>
							<TextS style={{ color: theme.danger }}>
								Excluir
							</TextS>
							<TrashIcon color={theme.danger} size={16} />
						</Button>

						<Button
							onClick={() => duplicateRetention(item)}
							type="default"
						>
							<TextS style={{ color: theme.primary }}>
								Duplicar
							</TextS>
							<FileCopyIcon color={theme.primary} size={18} />
						</Button>

						<Button
							type="primary"
							onClick={() => editRetention(item)}
						>
							<TextS style={{ color: theme.white }}>Editar</TextS>
							<RiPencilLine style={{ color: theme.white }} />
						</Button>
					</Flex>
				</Col>
			</>
		);
	};

	return (
		<>
			<TableWrapper>
				<Table
					pagination={false}
					loading={isLoading}
					columns={columns}
					rowKey={item => item.id}
					dataSource={depositData}
					expandable={{
						expandedRowRender: expandedRowRender,
						expandIcon: ({ expanded, onExpand, record }) => {
							return expanded ? (
								<div onClick={e => onExpand(record, e)}>
									<BiChevronUp
										style={{
											color: theme.primary,
											fontSize: '1.3rem',
										}}
									/>
								</div>
							) : (
								<div onClick={e => onExpand(record, e)}>
									<BiChevronDown
										style={{
											color: theme.primary,
											fontSize: '1.3rem',
										}}
									/>
								</div>
							);
						},
					}}
					rowClassName={'custom-row'}
				/>
			</TableWrapper>
		</>
	);
};

export default DepositManagementList;
