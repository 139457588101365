import { Address } from './Address';
import { IdDocument } from './IdDocument';
import { Phone } from './Phone';

export enum RepresentativeRole {
	MANAGING_PARTNER = 'MANAGING_PARTNER',
	MANAGER = 'MANAGER',
	PARTNER = 'PARTNER',
	DIRECTOR = 'DIRECTOR',
}

export type RepresentativeDocuments = {
	identification?: string;
	proof_of_address?: string;
	identification_url?: string;
	proof_of_address_url?: string;
};

export type BaseRepresentative = {
	id?: string;
	role: RepresentativeRole;
	taxpayer_id: string;
	full_name: string;
	mother_name: string;
	email_address: string;
	birth_date: string;
	phone: Phone;
	address: Address;
	id_document: IdDocument;
	documents: RepresentativeDocuments;
	pep: boolean;
	sign_contract: boolean;
};

export type Representative = BaseRepresentative & {
	representative?: BaseRepresentative;
};
