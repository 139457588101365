import { Col, Row } from 'antd';
import { ButtonPrimary } from 'components/ButtonPrimary';
import Confirmation from 'components/Icons/Confirmation';
import { TextM } from 'components/Text';
import { getAccountRouteTypeName } from 'modules/escrow/utils/adminRoute';
import { useTheme } from 'styled-components';
import { Posting } from 'types/Posting';

export interface PostingRejectSuccessProps {
	postingData: Posting[];
	onConfirm: () => void;
}

const PostingRejectSuccess = ({
	postingData,
	onConfirm,
}: PostingRejectSuccessProps) => {
	const theme = useTheme();

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={3}>
					<Confirmation />
				</Col>
				<Col span={21}>
					<TextM weight="bold">
						{postingData && postingData.length > 1
							? `As ${postingData.length} solicitações foram rejeitadas com sucesso!`
							: 'A solicitação foi rejeitada com sucesso!'}
					</TextM>
					<TextM>
						{`Você pode acompanhar suas solicitações na aba
						”Rejeitadas” em ${getAccountRouteTypeName()}.`}
					</TextM>
				</Col>
			</Row>
			<Row style={{ width: '100%', marginTop: '2rem' }}>
				<ButtonPrimary
					onClick={onConfirm}
					style={{ justifyContent: 'center', padding: '1.4rem' }}
				>
					<TextM style={{ color: theme.white }}>
						Ok, obrigado(a)
					</TextM>
				</ButtonPrimary>
			</Row>
		</>
	);
};

export default PostingRejectSuccess;
