import React from 'react';
import { BlobProvider } from '@react-pdf/renderer';

export interface BlobProviderComponentProps {
	documentToBlob: JSX.Element;
	documentTitle: string;
}

const BlobProviderComponent = ({
	documentToBlob,
	documentTitle,
}: BlobProviderComponentProps) => {
	return (
		<BlobProvider document={documentToBlob}>
			{({ url }) => {
				if (url) {
					const a = document.createElement('a');
					a.href = url;
					a.download = documentTitle;
					a.click();
					a.remove();
				}
				return null;
			}}
		</BlobProvider>
	);
};

export default BlobProviderComponent;
