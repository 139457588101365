import {
	Button,
	Divider as DividerAnt,
	Form,
	Tabs,
	Collapse as CollapseAnt,
} from 'antd';
import styled from 'styled-components';
import { EscrowExtractFiltersFiltersType } from './types';

export const PageWrapper = styled.div`
	height: 100%;
	background-color: ${p => p.theme.white};
	padding: 1.5rem;
`;

export const HeaderArea = styled.div`
	background-color: ${({ theme }) => theme.primaryOpacity};
	border-radius: 8px;
	padding: 0 1.5rem;
	border: 1px solid ${({ theme }) => theme.border};
`;

export const DescriptionItem = styled.p<{ color?: string }>`
	padding: 0;
	margin: 0 0;
	font-size: 1.2rem;
	display: inline;
	vertical-align: sub;
	color: ${p => p.color || p.theme.text};
`;

export const Divider = styled(DividerAnt)`
	margin: 0.8rem 0;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-left: auto;
	justify-content: flex-end;
`;

export const ButtonFlex = styled(Button)`
	display: flex;
	align-items: center;
	padding: 0 1.3rem 0 1rem !important;
	svg {
		color: inherit;
		height: 1.2rem;
		width: 1.2rem;
	}
	&:hover {
		svg {
			color: inherit;
		}
	}
`;

export const TabsSelector = styled(Tabs)`
	.ant-tabs-tab {
		padding: 6px 10px !important;
	}
	.ant-tabs-nav {
		padding: 0;
		margin: 0;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0;

	svg {
		color: ${({ theme }) => theme.textSecondary};
	}
`;

export const FiltersForm = styled(Form<EscrowExtractFiltersFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const ReceiptItem = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	.icon {
		font-size: 1.1rem;
		font-weight: normal;
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${({ theme }) => theme.primary};
		background-color: ${({ theme }) => theme.borderLight};
		border: 1px solid ${({ theme }) => theme.border};
		border-radius: 50%;
	}
`;

export const AccountInfoHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.8rem;
	justify-content: space-between;
`;

export const Collapse = styled(CollapseAnt)`
	.ant-collapse-item {
		.ant-collapse-header {
			display: flex;
			align-items: center;
		}
	}
`;

export interface AccountInfoHeaderArrowProps {
	isOpen?: boolean;
}
export const AccountInfoHeaderArrow = styled.div<AccountInfoHeaderArrowProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transform: rotate(${p => (p.isOpen ? '0deg' : '180deg')});
	margin: 0px;
	padding-inline-start: 0px;
	background-color: #f7f7f7 !important;
	padding: 8px !important;
	border-radius: 7px;
`;

export const Balance = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: center;
	gap: 0.5rem;
`;

export const CollapseWrapper = styled.div`
	padding: 0.5rem;
	background-color: #f7f7f7;
`;

export const DescriptionsWrapper = styled.div`
	.ant-descriptions-item-label {
		color: ${({ theme }) => theme.primary} !important;
		font-size: 18px;
		font-weight: 500;
	}

	.ant-descriptions-item-container {
		p {
			color: ${({ theme }) => theme.text} !important;
			font-size: 16px;
		}
	}
`;

export const HeaderButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 0.2rem;
`;

export const AccountBalanceTableWrapper = styled.div`
	.daily-balance {
		background-color: #f4f4ff;
	}

	.ant-table-footer {
		background: transparent !important;
	}
`;

export const BackgroundModalData = styled.div`
	width: 100%;
	background-color: #f4f4ff;
	padding: 0.2rem 1rem;
	border-radius: 1rem;
`;

export const ListWrapper = styled.div`
	max-height: 350px;
	min-height: 3rem;
	overflow-y: auto;
`;

export const ButtonsSpaces = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 2rem;
`;
