import { Input, InputRef } from 'antd';
import { forwardRef } from 'react';
import { formatBRLCurrencyMaskInput } from 'helpers/currency';
import { InputProps } from 'antd/lib';
import theme from 'theme/theme';

const CurrencyInput = forwardRef<InputRef, InputProps>((props, ref) => {
	return (
		<Input
			{...props}
			prefix={
				<span
					style={{
						color:
							props.value !== 0 && !props.value
								? theme.placeholderSelect
								: 'black',
					}}
				>
					R$
				</span>
			}
			ref={ref}
			onChange={e => {
				if (props.onChange)
					props.onChange(
						formatBRLCurrencyMaskInput(
							e.currentTarget.value,
						) as any,
					);
			}}
			onBlur={e => {
				if (props.onBlur)
					props.onBlur(
						parseFloat(
							e.currentTarget.value
								.replaceAll('.', '')
								.replace(',', '.'),
						) as any,
					);
				if (props.onChange)
					props.onChange(
						parseFloat(
							e.currentTarget.value
								.replaceAll('.', '')
								.replace(',', '.'),
						) as any,
					);
			}}
			value={
				typeof props.value === 'number'
					? formatBRLCurrencyMaskInput(
							props.value.toFixed(2).replaceAll('.', ','),
						)
					: props.value
			}
		/>
	);
});

export default CurrencyInput;
