import { Col, Row } from 'antd';
import { WarningIcon } from 'components/Icons/Warning';
import { TextM } from 'components/Text';
import { useTheme } from 'styled-components';
import { ForwardButton } from './components/ForwardButton';

interface IPostingCreationTedConfirmationProps {
	onClose: () => void;
	onContinue: () => void;
	onContinuePix: () => void;
}
const PostingCreationTedConfirmation = ({
	onClose,
	onContinue,
	onContinuePix,
}: IPostingCreationTedConfirmationProps) => {
	const theme = useTheme();

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={3}>
					<WarningIcon />
				</Col>
				<Col span={21}>
					<TextM weight="normal" color={theme.textSecondary}>
						Caso a transação seja aprovada após às 16h, ela será
						agendada para o próximo dia útil
					</TextM>
					<TextM weight="normal" style={{ marginTop: '2rem' }}>
						Deseja prosseguir mesmo assim?
					</TextM>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<ForwardButton
						onClick={onContinue}
						type="primary"
						text="Realizar Ted"
					/>
				</Col>
			</Row>
			<Row style={{ margin: '1rem 0', width: '100%' }}>
				<Col span={24} style={{ width: '100%' }}>
					<ForwardButton
						onClick={onContinue}
						type="default"
						text="Realizar transferência por Pix"
						style={{ border: `2px solid ${theme.border}` }}
					/>
				</Col>
			</Row>
			<Row style={{ margin: '1rem 0', width: '100%' }}>
				<Col span={24} style={{ width: '100%' }}>
					<ForwardButton
						onClick={onClose}
						type="default"
						text="Cancelar"
						style={{ border: 'none' }}
					/>
				</Col>
			</Row>
		</>
	);
};

export default PostingCreationTedConfirmation;
