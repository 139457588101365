import { Button } from 'antd';
import styled from 'styled-components';

export const UserBlockingContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 450px;
`;

export const UserBlockingButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;

	p {
		font-size: 18px;
		color: white;
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		opacity: 1 !important;
		background: ${p => p.theme.dangerOpacity} !important;
		border-color: ${p => p.theme.dangerOpacity} !important;
		p {
			color: ${p => p.theme.dangerDisabled} !important;
			opacity: 0.5 !important;
		}
	}
`;

export const CancelButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;
	margin-top: 1rem;

	p {
		font-size: 18px;
		color: ${p => p.theme.primary};
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;
