import { Button, Flex, Form, Row, Select } from 'antd';
import { CurrencyInput } from 'components/CurrencyInput';
import {
	CustomFeeType,
	CustomFeeTypeLabel,
} from 'components/FeeSettingsModal/types';
import { CheckIcon } from 'components/Icons/Check';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { TextArea } from './styles';
import { greaterThanZeroRule } from 'helpers/rules';

export interface CreateCustomFeeModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: (values: CreateCustomFeeFormValues) => void;
}

export interface CreateCustomFeeFormValues {
	type: CustomFeeType;
	amount: number;
	description?: string;
}
const CUSTOM_FEE_OPTIONS = [
	{ label: CustomFeeTypeLabel.MAINTENANCE, value: CustomFeeType.MAINTENANCE },
	{
		label: CustomFeeTypeLabel.CHARGE_SETTLEMENT,
		value: CustomFeeType.CHARGE_SETTLEMENT,
	},
	{ label: CustomFeeTypeLabel.OTHER, value: CustomFeeType.OTHER },
];

const CreateCustomFeeModal = ({
	isOpen,
	onClose,
	onConfirm,
}: CreateCustomFeeModalProps) => {
	const [form] = Form.useForm();
	const theme = useTheme();

	const handleFinish = (values: CreateCustomFeeFormValues) => {
		onConfirm(values);
		form.resetFields();
	};

	return (
		<Modal
			isOpen={isOpen}
			width={500}
			title="Adicionar tarifa"
			contentStyle={{
				borderBottomLeftRadius: '15px',
				borderBottomRightRadius: '15px',
			}}
			onClose={onClose}
		>
			<Form<CreateCustomFeeFormValues>
				layout="vertical"
				onFinish={handleFinish}
				form={form}
			>
				<Form.Item
					label="Valor"
					name="amount"
					rules={[
						{ required: true, message: 'Campo obrigatório' },
						greaterThanZeroRule('amount'),
					]}
				>
					<CurrencyInput placeholder="0,00" />
				</Form.Item>

				<Form.Item label="Descrição" name="description">
					<TextArea />
				</Form.Item>
			</Form>

			<Row>
				<Flex
					align="center"
					gap={10}
					justify="end"
					style={{
						marginTop: '1rem',
						width: '100%',
					}}
				>
					<Button
						onClick={onClose}
						type="default"
						style={{
							border: 0,
						}}
					>
						<TextS
							style={{
								color: theme.primary,
							}}
						>
							Cancelar
						</TextS>
					</Button>

					<Button type="primary" onClick={form.submit}>
						<Flex align="center" gap={10}>
							<TextS
								style={{
									color: theme.white,
								}}
							>
								Adicionar tarifa
							</TextS>
							<CheckIcon color={theme.white} size={18} />
						</Flex>
					</Button>
				</Flex>
			</Row>
		</Modal>
	);
};

export default CreateCustomFeeModal;
