import styled from 'styled-components';

export const BackgroundModalData = styled.div`
	width: 100%;
	background-color: #f4f4ff;
	padding: 0.2rem 1rem;
	border-radius: 1rem;
`;

export const ModalTextArea = styled.textarea`
	width: 100%;
	min-height: 4rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.border};
	resize: none;
`;

export const Spinner = styled.span`
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	animation: spin 1s linear infinite;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	color: ${p => p.theme.primary};
`;

export const DateFormIcon = styled.div`
	svg {
		width: 1.6rem;
		height: 1.6rem;
	}
`;

export const PaymentCreationDateAmount = styled.div`
	position: absolute;
	right: 16px;
	top: 10px;
	color: ${p => p.theme.danger};
	font-weight: bold;
	font-size: 1rem;
`;
