import React from 'react';

export const SignerIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.5962 1.03714L22.9428 7.38375C23.1381 7.57901 23.1381 7.89559 22.9428 8.09085C22.8679 8.16576 22.7712 8.21494 22.6665 8.2313L21.1924 8.46176L15.5356 2.80491L15.7477 1.31998C15.7868 1.04662 16.04 0.856662 16.3134 0.895714C16.4205 0.911014 16.5197 0.960637 16.5962 1.03714ZM4.59487 20.1484C8.31725 16.8169 12.5899 15.8206 17.2379 14.6279L17.6843 10.6105L13.3869 6.31304L9.36936 6.75943C8.17674 11.4074 7.18038 15.6801 3.84886 19.4024L2.4541 18.0077C5.28253 14.7078 6.34319 11.0545 7.7574 4.92623L14.1214 4.21912L19.7783 9.87602L19.0711 16.2399C12.9429 17.6541 9.28947 18.7148 5.98964 21.5432L4.59487 20.1484ZM9.87872 14.1186C9.09767 13.3375 9.09767 12.0712 9.87872 11.2902C10.6598 10.5091 11.9261 10.5091 12.7071 11.2902C13.4882 12.0712 13.4882 13.3375 12.7071 14.1186C11.9261 14.8996 10.6598 14.8996 9.87872 14.1186Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
