import { NetworkApi } from 'network';
import { List } from 'types/List';
import { User, UserDto, UserFilters, UserListContent } from 'types/User';

export class UsersService extends NetworkApi {
	public static getUsers(filters?: UserFilters) {
		return this.get<List<UserListContent>, UserFilters>('/users', filters);
	}

	public static createUser(user: UserDto) {
		return this.post<{ id: string }, UserDto>('/users', user);
	}

	public static async getUser(id: string) {
		const user = await this.get<User>(`/users/${id}`);
		return {
			...user,
			id,
		};
	}

	public static async acceptPortalTerms() {
		return this.post<void, {}>('/users/accept-portal-terms', {});
	}
}
