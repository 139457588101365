import {
	ForwardButton,
	DiscountFormContainer,
	DiscountFormItem,
	CancelButton,
} from './styles';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { TextM } from 'components/Text';
import { WalletCharge } from 'types/Wallet';
import { FaCheck } from 'react-icons/fa6';
import { useTheme } from 'styled-components';
import { formatCurrency } from 'helpers/normalizers';
import { CurrencyInput } from 'components/CurrencyInput';
import { maxDiscountValue } from 'helpers/rules';

interface IDiscountFormProps {
	charge: WalletCharge | WalletCharge[];
	isLoading: boolean;
	onClose: () => void;
	onSave: (discount_value: number) => void;
}
const DiscountForm = ({ charge, onClose, onSave }: IDiscountFormProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);

	useEffect(() => {
		form.setFieldValue('discount_value', undefined);
	}, []);

	return (
		<DiscountFormContainer
			form={form}
			style={{ width: '100%' }}
			layout="vertical"
			requiredMark={false}
			validateTrigger="onBlur"
			onFieldsChange={(f, allFields) => {
				const errors = allFields.filter(
					field =>
						!field.touched ||
						(field.errors && field.errors.length > 0),
				);
				setFormValid(errors.length === 0);
			}}
			onFinish={values => {
				onSave(values.discount_value);
			}}
		>
			{!Array.isArray(charge) && charge && (
				<Row>
					<Col span={24}>
						<TextM weight="normal" style={{ fontSize: '18px' }}>
							O valor total do boleto é de
							<span style={{ color: theme.primary }}>
								{` ${formatCurrency(charge.amount)}`}
							</span>
						</TextM>
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: '1.5rem' }}>
				<Col span={24}>
					<DiscountFormItem
						name="discount_value"
						label="Valor do desconto"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
							maxDiscountValue(
								'discount_value',
								Array.isArray(charge)
									? charge[0].amount
									: charge.amount,
							),
						]}
					>
						<CurrencyInput placeholder="0,00" />
					</DiscountFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<ForwardButton
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						<TextM>Adicionar desconto ao boleto</TextM>
						<FaCheck size={18} />
					</ForwardButton>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CancelButton type="default" onClick={onClose}>
						<TextM>Cancelar</TextM>
					</CancelButton>
				</Col>
			</Row>
		</DiscountFormContainer>
	);
};

export default DiscountForm;
