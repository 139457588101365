import {
	CancelButton,
	ForwardButton,
	PostingTokenForm,
	StepsWrapper,
} from './styles';
import { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { TextM } from 'components/Text';
import cognito from 'modules/core/services/CognitoService';
import { ApiError } from 'types/ApiError';
import { TokenForm } from './types';
import { useMutation } from '@tanstack/react-query';

interface IPostingCreationTokenProps {
	isLoading: boolean;
	onClose: () => void;
	onConfirm: () => void;
}
const PostingCreationToken = ({
	isLoading,
	onClose,
	onConfirm,
}: IPostingCreationTokenProps) => {
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);
	const [isError, setIsError] = useState(false);

	const { mutate, isPending } = useMutation<boolean, ApiError, TokenForm>({
		mutationFn: ({ token }) => {
			return cognito.validateUser(token);
		},
		onSuccess: () => onConfirm(),
		onError: () => {
			setIsError(true);
			form.setFields([
				{
					name: 'token',
					errors: ['Código incorreto'],
				},
			]);
		},
	});

	return (
		<StepsWrapper>
			<div>
				<PostingTokenForm
					style={{ width: '100%' }}
					layout="vertical"
					requiredMark={false}
					form={form}
					onValuesChange={() => {
						setIsError(false);
					}}
					onFieldsChange={(f, allFields) => {
						const errors = allFields.filter(
							field =>
								!field.touched ||
								(field.errors && field.errors.length > 0),
						);
						setFormValid(errors.length === 0);
					}}
					onFinish={values => mutate(values)}
				>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<TextM>
								Para sua segurança, realize a autenticação do
								seu dispositivo para realizar sua transferência.
							</TextM>
						</Col>
					</Row>
					<Row gutter={[16, 16]} style={{ paddingTop: '40px' }}>
						<Col span={24}>
							<Form.Item
								name="token"
								label="Insira o código do duplo fator de autenticação"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
									{
										min: 6,
										max: 6,
										message: 'Código inválido',
									},
								]}
							>
								<Input
									disabled={isPending || isLoading}
									placeholder="Informe o código do duplo fator de autenticação"
								/>
							</Form.Item>
						</Col>
					</Row>
				</PostingTokenForm>
			</div>
			<div>
				<Row gutter={[16, 16]} style={{ marginTop: 'auto' }}>
					<Col span={24}>
						<ForwardButton
							type="primary"
							htmlType="submit"
							disabled={!formValid || isError}
							loading={isPending || isLoading}
							onClick={form.submit}
						>
							<TextM>Validar</TextM>
						</ForwardButton>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<CancelButton
							type="default"
							onClick={onClose}
							disabled={isPending || isLoading}
						>
							<TextM>Cancelar</TextM>
						</CancelButton>
					</Col>
				</Row>
			</div>
		</StepsWrapper>
	);
};

export default PostingCreationToken;
