import React, { ReactNode } from 'react';
import { Modal } from 'components/Modal';
import { Button, Col, Row } from 'antd';
import { InformationButtons, InformationContent } from './styles';
import { TextM } from 'components/Text';
import { useTheme } from 'styled-components';

interface IInformationModalProps {
	isOpen: boolean;
	message?: string | ReactNode;
	title: string;
	style?: React.CSSProperties;
	buttonText: string;
	icon?: ReactNode;
	children?: ReactNode;
	warning?: boolean;
	onClose: () => void;
}
const InformationModal = ({
	isOpen,
	message,
	icon,
	title,
	style,
	buttonText,
	children,
	warning,
	onClose,
}: IInformationModalProps) => {
	const theme = useTheme();
	return (
		<Modal
			isOpen={isOpen}
			width={500}
			title={title}
			style={style}
			contentStyle={{
				borderBottomLeftRadius: '15px',
				borderBottomRightRadius: '15px',
			}}
			onClose={onClose}
		>
			<InformationContent>
				{icon}
				{message && (
					<div className="message">
						<TextM weight="normal">{message}</TextM>
					</div>
				)}
				{children}
			</InformationContent>
			<InformationButtons>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Button
							type="primary"
							style={{
								width: '100%',
								backgroundColor: warning
									? theme.attention
									: theme.primary,
							}}
							size="large"
							onClick={onClose}
						>
							{buttonText}
						</Button>
					</Col>
				</Row>
			</InformationButtons>
		</Modal>
	);
};

export default InformationModal;
