import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PdfContainer, TermsConfirm, TermsContainer } from './styles';
import { Button, Checkbox, CheckboxProps } from 'antd';
import { TextM } from 'components/Text';
import { useNavigate } from 'react-router';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.mjs',
	import.meta.url,
).toString();

const TermsPage: React.FC = () => {
	const navigate = useNavigate();
	const [numPages, setNumPages] = useState<number>();
	const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

	const onDocumentLoadSuccess = ({
		numPages,
	}: {
		numPages: number;
	}): void => {
		setNumPages(numPages);
	};

	const onChange: CheckboxProps['onChange'] = e => {
		setAcceptedTerms(e.target.checked);
	};

	return (
		<TermsContainer>
			<PdfContainer>
				<Document
					file="/escrow-terms.pdf"
					onLoadSuccess={onDocumentLoadSuccess}
				>
					{Array.from(new Array(numPages), (el, index) => (
						<Page
							key={`page_${index + 1}`}
							width={1024}
							pageNumber={index + 1}
						/>
					))}
				</Document>
			</PdfContainer>
			<TermsConfirm>
				<Checkbox onChange={onChange}>
					<TextM>Aceito os termos de uso da plataforma</TextM>
				</Checkbox>
				<Button
					type="primary"
					disabled={!acceptedTerms}
					onClick={() => navigate('/new-password')}
				>
					Continuar
				</Button>
			</TermsConfirm>
		</TermsContainer>
	);
};

export default TermsPage;
