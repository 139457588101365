import { ESortingPage } from 'types/List';

export const sortTableHandler = (
	sorter: any,
	setSorting: (value: any) => void,
) => {
	if ((sorter as any)?.column) {
		setSorting({
			order:
				(sorter as any).order === 'ascend'
					? ESortingPage.ASC
					: ESortingPage.DESC,
			sort: (sorter as any).column.key,
		});
	}
};
