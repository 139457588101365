import styled from 'styled-components';

export const TermsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const PdfContainer = styled.div`
	max-height: 70vh;
	overflow-x: auto;
`;

export const TermsConfirm = styled.div`
	display: flex;
	margin-top: 1.5rem;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: -3rem;
`;
