import React from 'react';
import theme from 'theme/theme';

export const PaperIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.8337 18.9596H4.16699C2.78628 18.9596 1.66699 17.8404 1.66699 16.4596V3.1263C1.66699 2.66607 2.04009 2.29297 2.50033 2.29297H14.167C14.6272 2.29297 15.0003 2.66607 15.0003 3.1263V13.1263H18.3337V16.4596C18.3337 17.8404 17.2144 18.9596 15.8337 18.9596ZM15.0003 14.793V16.4596C15.0003 16.9199 15.3734 17.293 15.8337 17.293C16.2939 17.293 16.667 16.9199 16.667 16.4596V14.793H15.0003ZM13.3337 17.293V3.95964H3.33366V16.4596C3.33366 16.9199 3.70676 17.293 4.16699 17.293H13.3337ZM5.00033 6.45964H11.667V8.1263H5.00033V6.45964ZM5.00033 9.79297H11.667V11.4596H5.00033V9.79297ZM5.00033 13.1263H9.16699V14.793H5.00033V13.1263Z"
			fill={color ?? theme.primary}
		/>
	</svg>
);
