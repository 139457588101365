import { Form } from 'antd';
import styled from 'styled-components';
import { ChargesFiltersType, CollectionsFiltersType } from './types';
import { StyleConstants } from 'theme/StyleConstants';

export const FiltersTitle = styled.div`
	display: flex;
	p {
		padding-left: 0.3rem;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
	align-items: flex-end;
`;

export const FiltersForm = styled(Form<CollectionsFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const ChargesFiltersForm = styled(Form<ChargesFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const FiltersFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}
`;

export const DropdownIcon = styled.div<{ size?: string }>`
	display: flex;
	align-items: center;
	min-width: 18px !important;
	svg {
		width: ${({ size }) => size ?? '15px'};
		height: ${({ size }) => size ?? '15px'};
	}
`;

export const ActionBar = styled.div`
	position: fixed;
	bottom: 0;
	left: ${StyleConstants.SIDE_BAR_WIDTH};
	width: calc(100% - ${StyleConstants.SIDE_BAR_WIDTH});
	height: 62px;
	background-color: ${p => p.theme.white};
	border-top: 1px solid ${p => p.theme.border};
	border-left: 1px solid ${p => p.theme.border};
	display: flex;
	align-items: center;
	padding: 0 24px;
`;

export const ModalTextArea = styled.textarea`
	width: 100%;
	min-height: 4rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.border};
	resize: none;
`;
