import styled from 'styled-components';

export const DescriptionsVerticalWrapper = styled.div`
	th.ant-descriptions-item-label {
		padding-top: 12px !important;
		padding-bottom: 12px !important;
		padding-left: 0px !important;
	}
	.ant-descriptions-view {
		padding: 0px 20px !important;
	}
`;
