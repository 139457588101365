import React, { useEffect } from 'react';
import { NewPasswordContainer } from './styles';
import NewPasswordForm from './NewPasswordForm';
import { useAuthLogo } from 'modules/core/context/AppContext';
import { Heading2 } from 'components/Heading2';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { Navigate } from 'react-router';

const NewPasswordPage = () => {
	const { showAuthLogo } = useAuthLogo();
	const { newPasswordFlow } = useAuthContext();

	useEffect(() => showAuthLogo(), []);

	if (!newPasswordFlow) {
		return <Navigate to="/login" />;
	}

	return (
		<NewPasswordContainer>
			<Heading2>Primeiro Acesso</Heading2>
			<NewPasswordForm />
		</NewPasswordContainer>
	);
};

export default NewPasswordPage;
