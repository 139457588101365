import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Col,
	Flex,
	Form,
	Input,
	InputRef,
	Row,
	Select,
	notification,
} from 'antd';
import {
	normalizeCep,
	normalizeCpf,
	normalizePhone,
} from 'helpers/normalizers';
import { DatePicker } from 'components/DatePicker';
import { Container, Footer, Spinner, StyledInput } from './styles';
import {
	Company,
	CompanyDocuments,
	CompanyDto,
	ETenantType,
} from 'types/Company';
import { naturalPersonDocumentsList } from 'constants/company';
import { NaturalPersonForm } from './types';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import { formatPhoneFromObject } from 'helpers/formaters';
import { statesList } from 'constants/states';
import { PiSpinnerBold } from 'react-icons/pi';
import {
	accountTypeOptions,
	cpfRule,
	maxTodayDateRule,
	minDateRule,
} from 'helpers/rules';
import { useCompany } from 'modules/company/hooks';
import { validateCpf } from 'helpers/validators';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { StepsId } from 'modules/escrow/pages/EscrowCreation/types';
import { SaveIcon } from 'components/Icons/Save';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';

interface INaturalPersonInformationProps {
	person?: CompanyDto | Company;
	isAccountCreation?: boolean;
	onResetRepresentatives?: () => void;
	onNext: (person: CompanyDto | Company) => void;
	handleStepStatus?: (stepsId: StepsId, status: boolean) => void;
	tenantType?: ETenantType;
	setTenantType: (value?: ETenantType) => void;
	cnpj?: string;
	isPersonCreating?: boolean;
	setIsPersonCreating?: (value: boolean) => void;
}
const NaturalPersonInformation = ({
	person,
	isAccountCreation,
	onNext,
	onResetRepresentatives,
	handleStepStatus,
	setTenantType,
	tenantType,
	cnpj,
	isPersonCreating,
	setIsPersonCreating,
}: INaturalPersonInformationProps) => {
	const theme = useTheme();
	const { type } = useCurrentProfile();
	const [form] = Form.useForm();
	const hasCompany = person && Object.keys(person).length > 0;
	const legalNameRef = useRef<InputRef>(null);
	const taxpayerIdRef = useRef<InputRef>(null);

	const [formValid, setFormValid] = useState(hasCompany);
	const [api, contextHolder] = notification.useNotification();
	const [isFormDisabled, toggleFormDisabled] = useState<boolean>(
		!person || typeof person.id === 'string',
	);
	const [isDocumentsInvalid, setDocumentsInvalid] = useState<boolean>(false);
	const [uploadDocuments, updateUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	useEffect(() => {
		setFormValid(hasCompany);
	}, [hasCompany]);

	useEffect(() => {
		if (type === AccessType.PERSON && isPersonCreating) {
			const mockResponse = '*****';
			form.setFieldValue(
				'taxpayer_id',
				taxpayerIdRef.current?.input?.value ||
					person?.taxpayer_id ||
					mockResponse,
			);
			form.setFieldValue(
				'birth_date',
				dayjs(person?.birth_date || new Date(), 'YYYY-MM-DD'),
			);
			form.setFieldValue('email_address', mockResponse);
			form.setFieldValue('phone', mockResponse);
			form.setFieldValue('mother_name', mockResponse);
			form.setFieldValue('pep', mockResponse);
			form.setFieldValue('postal_code', mockResponse);
			form.setFieldValue('state_code', mockResponse);
			form.setFieldValue('city', mockResponse);
			form.setFieldValue('district', mockResponse);
			form.setFieldValue('street_name', mockResponse);
			form.setFieldValue('street_number', mockResponse);
			form.setFieldValue('extra_info', mockResponse);
			updateUploadDocuments({
				incorporation_certificate: mockResponse,
				proof_of_address: mockResponse,
				bylaws: mockResponse,
				income_statement: mockResponse,
				last_contract_amendment: mockResponse,
				power_of_attorney: mockResponse,
				incorporation_certificate_url: mockResponse,
				proof_of_address_url: mockResponse,
				bylaws_url: mockResponse,
				income_statement_url: mockResponse,
				last_contract_amendment_url: mockResponse,
				power_of_attorney_url: mockResponse,
				document_identification: mockResponse,
				identification_url: mockResponse,
			});
		}
	}, [person, isPersonCreating]);

	const {
		getCompanyByTaxpayerId,
		company: loadedCompany,
		loading,
	} = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message: 'Ocorreu um problema ao buscar informações da pessoa.',
			});
		},
		onSuccess: (newPerson, hasPrevPerson, accounts) => {
			if (!newPerson || (isAccountCreation && accounts.length >= 2)) {
				if (isAccountCreation) {
					if (hasPrevPerson || typeof person !== 'undefined') {
						form.setFieldValue('name', undefined);
						form.setFieldValue('birth_date', undefined);
						form.setFieldValue('email_address', undefined);
						form.setFieldValue('phone', undefined);
						form.setFieldValue('mother_name', undefined);
						form.setFieldValue('pep', undefined);
						form.setFieldValue('postal_code', undefined);
						form.setFieldValue('state_code', undefined);
						form.setFieldValue('city', undefined);
						form.setFieldValue('district', undefined);
						form.setFieldValue('street_name', undefined);
						form.setFieldValue('street_number', undefined);
						form.setFieldValue('extra_info', undefined);
						updateUploadDocuments(() => ({}) as CompanyDocuments);

						if (typeof onResetRepresentatives === 'function') {
							onResetRepresentatives();
						}
					}
					toggleFormDisabled(false);
					setFormValid(false);

					if (accounts.length >= 2) {
						setTimeout(() => taxpayerIdRef.current?.focus(), 100);
						form.setFieldValue('taxpayer_id', '');
						api.error({
							message: 'Limite de contas atingido',
							description:
								'Você atingiu o limite de contas criadas para esta pessoa.',
						});
					} else {
						setTimeout(() => legalNameRef.current?.focus(), 100);
					}
				}
				return;
			}

			if (!isAccountCreation && !cnpj) {
				api.error({
					message: 'Pessoa já cadastrada',
					description: `${newPerson.name} já está cadastrada no sistema.`,
				});
				form.setFieldValue('taxpayer_id', '');
				setTimeout(() => taxpayerIdRef.current?.focus(), 100);
				return;
			}

			if (cnpj) {
				form.setFieldValue('taxpayer_id', cnpj);
			}

			form.setFieldValue('name', newPerson.name);
			if (type === AccessType.FINANCIAL_INSTITUTION) {
				form.setFieldValue(
					'birth_date',
					dayjs(newPerson.birth_date, 'YYYY-MM-DD'),
				);
				form.setFieldValue('email_address', newPerson.email_address);
				form.setFieldValue(
					'phone',
					formatPhoneFromObject(newPerson.phone),
				);
				form.setFieldValue('mother_name', newPerson.mother_name);
				form.setFieldValue('pep', newPerson.pep);
				form.setFieldValue(
					'postal_code',
					newPerson.address?.postal_code,
				);
				form.setFieldValue('state_code', newPerson.address?.state_code);
				form.setFieldValue('city', newPerson.address?.city);
				form.setFieldValue('district', newPerson.address?.district);
				form.setFieldValue(
					'street_name',
					newPerson.address?.street_name,
				);
				form.setFieldValue(
					'street_number',
					newPerson.address?.street_number,
				);
				form.setFieldValue('extra_info', newPerson.address?.extra_info);

				updateUploadDocuments(newPerson.documents);
			} else {
				const mockResponse = '*****';
				form.setFieldValue(
					'birth_date',
					dayjs(newPerson.birth_date, 'YYYY-MM-DD'),
				);
				form.setFieldValue('email_address', mockResponse);
				form.setFieldValue('phone', mockResponse);
				form.setFieldValue('mother_name', mockResponse);
				form.setFieldValue('pep', mockResponse);
				form.setFieldValue('postal_code', mockResponse);
				form.setFieldValue('state_code', mockResponse);
				form.setFieldValue('city', mockResponse);
				form.setFieldValue('district', mockResponse);
				form.setFieldValue('street_name', mockResponse);
				form.setFieldValue('street_number', mockResponse);
				form.setFieldValue('extra_info', mockResponse);
				updateUploadDocuments({
					incorporation_certificate: mockResponse,
					proof_of_address: mockResponse,
					bylaws: mockResponse,
					income_statement: mockResponse,
					last_contract_amendment: mockResponse,
					power_of_attorney: mockResponse,
					incorporation_certificate_url: mockResponse,
					proof_of_address_url: mockResponse,
					bylaws_url: mockResponse,
					income_statement_url: mockResponse,
					last_contract_amendment_url: mockResponse,
					power_of_attorney_url: mockResponse,
					document_identification: mockResponse,
					identification_url: mockResponse,
				});

				setIsPersonCreating && setIsPersonCreating(true);
			}

			toggleFormDisabled(true);
			setFormValid(true);
		},
	});

	useEffect(() => {
		if (person && person.documents) {
			updateUploadDocuments(person.documents);
		}
	}, [person]);

	useEffect(() => {
		if (cnpj) {
			getCompanyByTaxpayerId(cnpj);
			setDocumentsInvalid(false);
		}
	}, [cnpj]);

	const isDocumentsValid = useMemo(() => {
		return (
			(typeof uploadDocuments['proof_of_address'] === 'string' &&
				typeof uploadDocuments['document_identification'] ===
					'string') ||
			(typeof uploadDocuments['proof_of_address_url'] === 'string' &&
				typeof uploadDocuments['identification_url'] === 'string')
		);
	}, [uploadDocuments]);

	const getFormValues = useCallback(
		(values: NaturalPersonForm) => {
			return {
				taxpayer_id: values.taxpayer_id
					? serializeOnlyNumbers(values.taxpayer_id)
					: undefined,
				name: values.name,
				birth_date: values.birth_date
					? values.birth_date.format('YYYY-MM-DD')
					: undefined,
				email_address: values.email_address,
				phone: values.phone
					? serializePhoneObject(values.phone)
					: undefined,
				mother_name: values.mother_name,
				pep: values.pep,
				address: {
					postal_code: values.postal_code
						? serializeOnlyNumbers(values.postal_code)
						: undefined,
					state_code: values.state_code,
					city: values.city,
					district: values.district,
					street_name: values.street_name,
					street_number: values.street_number,
					extra_info: values.extra_info,
					country_code: 'BRZ',
				},
				documents: uploadDocuments,
			};
		},
		[uploadDocuments],
	);

	return (
		<Container>
			{contextHolder}
			{isAccountCreation && (
				<>
					<Heading2
						style={{
							marginBottom: '1rem',
						}}
					>
						Tipo de conta
					</Heading2>
					<Row>
						<Select
							value={tenantType}
							style={{ width: '13rem', marginBottom: '1.5rem' }}
							placeholder="Selecione"
							showSearch={true}
							options={accountTypeOptions}
							onChange={(value, option) => setTenantType(value)}
							filterOption={(input, option) =>
								option?.label
									?.toLowerCase()
									?.startsWith(input.toLowerCase()) ||
								String(option?.value)
									?.toLowerCase()
									?.startsWith(input.toLowerCase())
							}
						/>
					</Row>
				</>
			)}

			<Form<NaturalPersonForm>
				form={form}
				disabled={isAccountCreation && isFormDisabled}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched &&
								!hasCompany &&
								!field.name?.includes('extra_info')) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
				}}
				initialValues={{
					taxpayer_id:
						person?.taxpayer_id && normalizeCpf(person.taxpayer_id),
					name: person?.name,
					birth_date:
						person?.birth_date &&
						dayjs(person.birth_date, 'YYYY-MM-DD'),
					email_address: person?.email_address,
					mother_name: person?.mother_name,
					pep: person?.pep,
					phone: person?.phone && formatPhoneFromObject(person.phone),
					postal_code: person?.address?.postal_code,
					state_code: person?.address?.state_code,
					city: person?.address?.city,
					district: person?.address?.district,
					street_name: person?.address?.street_name,
					street_number: person?.address?.street_number,
					extra_info: person?.address?.extra_info,
				}}
				onFinish={values => {
					if (loadedCompany !== null && isAccountCreation) {
						handleStepStatus?.(StepsId.PERSONAL_INFORMATION, true);
						onNext(loadedCompany);
						return;
					}
					if (isDocumentsValid) {
						onNext(getFormValues(values) as CompanyDto);
						handleStepStatus?.(StepsId.PERSONAL_INFORMATION, true);
					} else {
						if (!cnpj) {
							setDocumentsInvalid(true);
						}
					}
				}}
			>
				<Heading2 style={{ marginTop: '1rem', marginBottom: '21px' }}>
					{isAccountCreation ? 'Dados do titular' : 'Dados básicos'}
				</Heading2>
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<FormItem
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.taxpayer_id !==
								currentValues.taxpayer_id
							}
						>
							{({ getFieldValue }) => {
								return (
									<Form.Item
										name="taxpayer_id"
										label="CPF"
										normalize={e => normalizeCpf(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cpfRule,
										]}
									>
										<Input
											disabled={loading}
											placeholder="000.000.000-00"
											ref={taxpayerIdRef}
											onBlur={() => {
												const taxpayerId =
													getFieldValue(
														'taxpayer_id',
													);
												if (
													validateCpf(taxpayerId) &&
													!loading
												) {
													getCompanyByTaxpayerId(
														serializeOnlyNumbers(
															taxpayerId,
														),
													);
												}
												Object.values(StepsId).forEach(
													step => {
														handleStepStatus?.(
															step as StepsId,
															false,
														);
													},
												);
											}}
											suffix={
												loading ? (
													<Spinner>
														<PiSpinnerBold
															style={{
																fontSize: 24,
															}}
														/>
													</Spinner>
												) : null
											}
										/>
									</Form.Item>
								);
							}}
						</FormItem>
					</Col>
					<Col span={9}>
						<Form.Item
							name="name"
							label="Nome"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input
								ref={legalNameRef}
								placeholder="Ex.: João Silva"
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="pep"
							label="Pessoa Politicamente Exposta (PEP)"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={[
									{ label: 'Sim', value: true },
									{ label: 'Não', value: false },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<Form.Item
							name="birth_date"
							label="Data de Nascimento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('birth_date'),
								minDateRule,
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</Form.Item>
					</Col>
					<Col span={9}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={14}>
						<StyledInput>
							<Form.Item
								name="mother_name"
								label="Nome da mãe"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: Maria Silva" />
							</Form.Item>
						</StyledInput>
					</Col>
				</Row>
				<Heading2 style={{ marginTop: '1.5rem', marginBottom: '21px' }}>
					Endereço
				</Heading2>
				<Row gutter={[16, 16]}>
					<Col span={4}>
						<Form.Item
							name="postal_code"
							label="CEP"
							normalize={e => normalizeCep(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="00000-000" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							name="state_code"
							label="Estado"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								showSearch={true}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.startsWith(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.startsWith(input.toLowerCase())
								}
								placeholder="Selecione o estado"
								options={statesList}
							/>
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="city"
							label="Cidade"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: São Paulo" />
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item
							name="district"
							label="Bairro"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Planalto do Sol" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={14}>
						<StyledInput>
							<Form.Item
								name="street_name"
								label="Rua/Avenida"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: Av Presidente Vargas" />
							</Form.Item>
						</StyledInput>
					</Col>
					<Col span={3}>
						<StyledInput>
							<Form.Item
								name="street_number"
								label="Número"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: 300" />
							</Form.Item>
						</StyledInput>
					</Col>
					<Col span={7}>
						<StyledInput>
							<Form.Item name="extra_info" label="Complemento">
								<Input placeholder="Ex.: Próximo a escola..." />
							</Form.Item>
						</StyledInput>
					</Col>
				</Row>
				<Row
					gutter={[16, 16]}
					justify="space-between"
					style={{ alignItems: 'center', padding: '0 0.7rem' }}
				>
					<Heading2
						style={{ marginTop: '1.5rem', marginBottom: '21px' }}
					>
						Documentos
					</Heading2>
					<TextS>* Obrigatórios</TextS>
				</Row>
				<Documents
					hideTitle={true}
					onChangeDocumentsObject={documents => {
						updateUploadDocuments({
							...uploadDocuments,
							...documents,
						});
					}}
					documentsObject={uploadDocuments}
					documentsList={naturalPersonDocumentsList}
				/>
				{isDocumentsInvalid && (
					<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
						* Adicione todos os documentos obrigatórios
					</TextS>
				)}
				<Footer>
					{cnpj ? (
						<Button type="primary" htmlType="submit">
							<Flex>
								<TextS
									color={theme.white}
									style={{
										marginRight: '0.7rem',
									}}
								>
									Salvar alterações
								</TextS>
								<SaveIcon color={theme.white} size={21} />
							</Flex>
						</Button>
					) : (
						<Button
							type="primary"
							htmlType="submit"
							disabled={
								!formValid ||
								!isDocumentsValid ||
								(isAccountCreation && !tenantType)
							}
						>
							Avançar
						</Button>
					)}
				</Footer>
			</Form>
		</Container>
	);
};

export default NaturalPersonInformation;
