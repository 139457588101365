import { DebitCollectionsIcon as DebitCollectionsSvg } from 'components/Icons/DebitCollections';
import React from 'react';
import { useTheme } from 'styled-components';

export enum DebitCollectionsRoutes {
	BASE = '/debit-collections',
	NEW = '/debit-collections/new',
	ESCROW_APPROVALS = '/debit-collections/approvals',
	ESCROW_ACCOUNTS = '/debit-collections/accounts',
}

export const DebitCollectionsIcon: React.FC<{ isActive?: boolean }> = ({
	isActive,
}) => {
	const theme = useTheme();
	if (isActive) return <DebitCollectionsSvg />;
	return <DebitCollectionsSvg color={theme.text} />;
};
