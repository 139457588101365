export enum ChallengeName {
	MFA_SETUP = 'MFA_SETUP',
	NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
	SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export enum MFAOptions {
	SMS_MFA = 'SMS_MFA',
	SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export enum CognitoErrorCode {
	LOGIN_FAILED = 'LOGIN_FAILED',
	MFA_FAILED = 'MFA_FAILED',
	NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED',
}

export type CognitoResponse = {
	username: string;
	pool: {
		userPoolId: string;
		clientId: string;
		client: {
			endpoint: string;
			fetchOptions: {};
		};
		advancedSecurityDataCollectionFlag: boolean;
		storage: {
			'ZD-settings': string;
			'ZD-suid': string;
			i18nextLng: string;
			'ZD-buid': string;
			'ZD-store': string;
		};
	};
	Session: string;
	client: {
		endpoint: string;
		fetchOptions: {};
	};
	signInUserSession: any;
	authenticationFlowType: string;
	storage: {
		'ZD-settings': string;
		'ZD-suid': string;
		i18nextLng: string;
		'ZD-buid': string;
		'ZD-store': string;
	};
	keyPrefix: string;
	userDataKey: string;
	challengeName: ChallengeName;
	challengeParam: { [key: string]: any };
};
