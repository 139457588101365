import dayjs from 'dayjs';
import { InformationIcon } from 'components/Icons/Information';
import { Table } from 'components/Table';
import { TextS } from 'components/Text';

import theme from 'theme/theme';
import { Button, Tooltip } from 'antd';
import { formatCurrency } from 'helpers/normalizers';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';

import type { ColumnsType } from 'antd/es/table';
import { AccessPermission, AccessType } from 'types/Access';
import { ISortingPage, List, PaginationInfo } from 'types/List';
import {
	EBalanceRetentionTypeLabel,
	IBalanceManagementHistory,
} from '../types';
import { sortTableHandler } from 'helpers/tables';

interface IBalanceTableProps {
	balanceData?: List<IBalanceManagementHistory>;
	isLoading: boolean;
	paginationInfo: PaginationInfo;
	unblock: (balance: IBalanceManagementHistory) => void;
	updatePaginationInfo: (pagination: PaginationInfo) => void;
	updateSorting: (value: React.SetStateAction<ISortingPage>) => void;
}

const BalanceTable = ({
	balanceData,
	isLoading,
	paginationInfo,
	unblock,
	updatePaginationInfo,
	updateSorting,
}: IBalanceTableProps) => {
	const { type, permissions } = useCurrentProfile();
	const hasPermission =
		type === AccessType.FINANCIAL_INSTITUTION ||
		permissions.includes(AccessPermission.REMOVE_BALANCE_RETENTION);

	const renderBlockAction = (balance: IBalanceManagementHistory) => {
		if (!balance?.removed_at?.length) {
			return (
				<Button
					disabled={!hasPermission}
					onClick={() => unblock(balance)}
					style={{ padding: 0 }}
					type="link"
				>
					Remover bloqueio
				</Button>
			);
		}

		return (
			<Tooltip title={balance?.remove_reason || ''} placement="top">
				<div style={{ width: '100%', marginLeft: '4rem' }}>
					<InformationIcon color={theme.placeholderOpacity} />
				</div>
				<TextS
					color={theme.placeholderOpacity}
					style={{ fontSize: '14px' }}
				>
					Motivo do desbloqueio
				</TextS>
			</Tooltip>
		);
	};

	const columns: ColumnsType<IBalanceManagementHistory> = [
		{
			key: 'block_type',
			sorter: true,
			title: 'Operação',
			width: '20%',
			render: (part: IBalanceManagementHistory) => {
				return part.remove_reason
					? EBalanceRetentionTypeLabel.UNBLOCK
					: EBalanceRetentionTypeLabel[part.block_type];
			},
		},
		{
			key: 'created_at',
			sorter: true,
			title: 'Data',
			width: '20%',
			render: (part: IBalanceManagementHistory) => {
				return `${dayjs(part.created_at).format('DD/MM/YYYY')} às ${dayjs(part.created_at).format('HH:mm')}`;
			},
		},
		{
			key: 'block_reason',
			sorter: true,
			title: 'Motivo do bloqueio',
			width: '23%',
			render: (part: IBalanceManagementHistory) => part.block_reason,
		},
		{
			key: 'blocked_amount',
			sorter: true,
			title: 'Valor',
			width: '20%',
			render: (part: IBalanceManagementHistory) =>
				formatCurrency(part.blocked_amount),
		},
		{
			title: 'Ações',
			width: '17%',
			render: (part: IBalanceManagementHistory) =>
				renderBlockAction(part),
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={balanceData?.content || []}
			loading={isLoading}
			rowKey={balanceData => balanceData.id}
			onChange={(_, __, sorter) =>
				sortTableHandler(sorter, updateSorting)
			}
			pagination={{
				current: paginationInfo.currentPage,
				pageSize: paginationInfo.pageSize,
				pageSizeOptions: ['10', '30', '60', '90'],
				showSizeChanger: true,
				total: balanceData?.total_elements || 0,
				showTotal(total) {
					const currentSize =
						paginationInfo.currentPage * paginationInfo.pageSize;
					return `${currentSize > total ? total : currentSize} de ${total}`;
				},
				onChange(page, pageSize) {
					updatePaginationInfo({
						currentPage: page,
						pageSize,
					});
				},
			}}
		/>
	);
};

export default BalanceTable;
