import { Route, Routes } from 'react-router-dom';
import { UsersList } from './pages/UsersList';
import { UsersListAdmin } from './pages/UsersListAdmin';
import { UsersDetails } from 'components/UsersDetails';

const UsersModule = () => {
	return (
		<Routes>
			<Route path="/admin" element={<UsersListAdmin />} />
			<Route path="/:userId" element={<UsersDetails />} />
			<Route path="/" element={<UsersList />} />
		</Routes>
	);
};

export default UsersModule;
