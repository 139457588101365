import { Button, Col, CollapseProps, Empty, Row, notification } from 'antd';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
	CollapseItemsBody,
	ContainerFluid,
	ContainerHeader,
	Footer,
} from './styles';
import { PageTitle } from 'components/PageTitle';
import { PageActions } from 'components/PageActions';

import { Destination, DestinationType } from 'types/Destination';
import { TextL, TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { BankListDescription } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { useDestinations } from 'modules/escrow/hooks';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { ConfirmationModal } from 'components/ConfirmationModal';
import emptyDestinationAccount from 'components/images/emptyListB.png';
import { IoAddCircleOutline } from 'react-icons/io5';
import { DestinationCreationNaturalPerson } from 'modules/escrow/components/DestinationCreationNaturalPerson';
import { Collapse, CollapseHeader } from 'components/Collapse';
import CopyCard from 'components/Icons/CopyCard';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';

interface IAccountDestinationNaturalPersonProps {
	accountId: string;
	destinations?: Destination[];
	renderFooter?: (destinations: Destination[]) => ReactNode;
}

const CustomRow = ({ children }: { children: ReactNode }) => {
	const theme = useTheme();
	return (
		<Row
			style={{
				padding: '1rem 0',
				borderBottom: `1px solid ${theme.border}`,
			}}
		>
			{children}
		</Row>
	);
};

const AccountDestinationPerson = ({
	accountId,
	destinations: editDestinations,
	renderFooter,
}: IAccountDestinationNaturalPersonProps) => {
	const theme = useTheme();
	const isEscrowAccount = useIsEscrowAccountRoute();
	const { type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const [destinations, updateDestinations] = useState<Destination[]>([]);
	const [editDestination, setEditDestination] = useState<Destination>();
	const [destinationToConfirm, updateDestinationToConfirm] =
		useState<Destination>();
	const [isDestinationConfirmOpen, toggleIsDestinationConfirmOpen] =
		useState(false);
	const [isDestinationRemoveOpen, toggleIsDestinationRemoveOpen] =
		useState(false);
	const [isDestinationCreationOpen, toggleIsDestinationCreationOpen] =
		useState(false);

	useEffect(() => {
		if (Array.isArray(editDestinations) && editDestinations.length > 0) {
			updateDestinations(editDestinations);
		}
	}, [editDestinations]);

	const { createOrUpdateDestination, deleteDestination, loading } =
		useDestinations(accountId, {
			onCreateSuccess: destination => {
				updateDestinations(prev => [...prev, destination]);
				toggleIsDestinationConfirmOpen(false);
				updateDestinationToConfirm(undefined);
			},
			onUpdateSuccess: destination => {
				setEditDestination(undefined);
				updateDestinations(prev =>
					prev.map(prevDestination =>
						prevDestination.id === destination.id
							? destination
							: prevDestination,
					),
				);
				toggleIsDestinationConfirmOpen(false);
				updateDestinationToConfirm(undefined);
			},
			onDeleteSuccess: destinationId => {
				updateDestinations(prevDestinations =>
					prevDestinations.filter(
						destination => destination.id !== destinationId,
					),
				);
				toggleIsDestinationRemoveOpen(false);
				updateDestinationToConfirm(undefined);
			},
			onError: error => {
				setEditDestination(undefined);
				api.error({
					description: error.data.message,
					message: 'Ocorreu um problema com conta beneficiária.',
				});
			},
		});

	const onCreateDestination = useCallback(
		(newDestination: Destination) => {
			if (editDestination) {
				createOrUpdateDestination({
					...editDestination,
					...newDestination,
				});
			} else {
				createOrUpdateDestination(newDestination);
			}
		},
		[createOrUpdateDestination, editDestination],
	);

	const onCloseDestinationCreation = useCallback(() => {
		setEditDestination(undefined);
		toggleIsDestinationCreationOpen(false);
	}, []);

	const destinationItems: CollapseProps['items'] = useMemo(() => {
		return destinations.map((account, j) => {
			return {
				key: `${j + 1}`,
				label: (
					<CollapseHeader>
						<CopyCard />
						<TextM>{account.name}</TextM>
					</CollapseHeader>
				),
				children: (
					<CollapseItemsBody>
						<div
							style={{
								marginBottom: '0.5rem',
							}}
						>
							<TextM>Informações da conta</TextM>
						</div>
						<CustomRow>
							<Col span={6}>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									CPF / CNPJ
								</TextS>
							</Col>
							<Col span={15}>
								<TextS>
									{normalizeCpfCnpj(account.taxpayer_id)}
								</TextS>
							</Col>
						</CustomRow>

						<CustomRow>
							<Col span={6}>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									Nome do beneficiário
								</TextS>
							</Col>
							<Col span={15}>
								<TextS>{account.name}</TextS>
							</Col>
						</CustomRow>

						<CustomRow>
							<Col span={6}>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									Banco
								</TextS>
							</Col>
							<Col span={15}>
								<TextS>
									{BankListDescription[account.bank]}
								</TextS>
							</Col>
						</CustomRow>

						<CustomRow>
							<Col span={6}>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									Agência
								</TextS>
							</Col>
							<Col span={15}>
								<TextS>{account.branch}</TextS>
							</Col>
						</CustomRow>

						<CustomRow>
							<Col span={6}>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									Conta
								</TextS>
							</Col>
							<Col span={15}>
								<TextS>
									{normalizeBankAccount(account.account)}
								</TextS>
							</Col>
						</CustomRow>

						<CustomRow>
							<Col span={6}>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									Tipo de conta
								</TextS>
							</Col>
							<Col span={15}>
								<TextS>
									{
										DestinationTypeDescription[
											account.type as DestinationType
										]
									}
								</TextS>
							</Col>
						</CustomRow>
					</CollapseItemsBody>
				),
			};
		});
	}, [destinations, theme]);

	return (
		<ContainerFluid>
			{contextHolder}
			<ContainerHeader>
				<PageTitle>Contas Beneficiárias</PageTitle>
				{(!isEscrowAccount ||
					type === AccessType.FINANCIAL_INSTITUTION) &&
					destinations.length > 0 && (
						<Button
							style={{ marginTop: '0.5rem', height: 'auto' }}
							type="primary"
							onClick={() =>
								toggleIsDestinationCreationOpen(true)
							}
						>
							<div
								style={{
									display: 'flex',
									gap: '10px',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '0.2rem',
								}}
							>
								<TextS style={{ color: theme.white }}>
									Adicionar conta beneficiária
								</TextS>
								<IoAddCircleOutline
									size={'1.4rem'}
									style={{ width: '2rem' }}
								/>
							</div>
						</Button>
					)}
			</ContainerHeader>
			{destinations.length === 0 && (
				<>
					<TextM
						style={{
							textAlign: 'center',
							paddingTop: '1rem',
							color: theme.textSecondary,
						}}
					>
						Você ainda não possui nenhuma conta beneficiária
						cadastrada
					</TextM>
					<Empty
						style={{
							minHeight: '50vh',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						description={
							isEscrowAccount ? (
								<TextS
									style={{
										paddingTop: '1rem',
										color: theme.textGray,
										textAlign: 'center',
									}}
								>
									Para prosseguir, adicione pelo menos uma
									conta beneficiária
								</TextS>
							) : undefined
						}
						image={emptyDestinationAccount}
						imageStyle={{
							height: 240,
						}}
					>
						<Button
							style={{ marginTop: '0.5rem', height: 'auto' }}
							type="primary"
							onClick={() =>
								toggleIsDestinationCreationOpen(true)
							}
						>
							<div
								style={{
									display: 'flex',
									gap: '10px',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '0.2rem',
								}}
							>
								<TextS style={{ color: theme.white }}>
									Adicionar conta beneficiária
								</TextS>
								<IoAddCircleOutline
									size={'1.4rem'}
									style={{ width: '2rem' }}
								/>
							</div>
						</Button>
					</Empty>
				</>
			)}
			{destinations.length > 0 && (
				<>
					<Collapse
						defaultActiveKey={'1'}
						expandIconPosition="end"
						items={destinationItems}
					/>
				</>
			)}
			{typeof renderFooter === 'function' && (
				<Footer>{renderFooter(destinations)}</Footer>
			)}
			<DestinationCreationNaturalPerson
				isOpen={isDestinationCreationOpen}
				destination={editDestination}
				isLoading={loading}
				onClose={onCloseDestinationCreation}
				onCreate={destination => {
					updateDestinationToConfirm(destination);
					toggleIsDestinationCreationOpen(false);
					toggleIsDestinationConfirmOpen(true);
				}}
			/>
			<ConfirmationModal
				isOpen={isDestinationConfirmOpen}
				title={
					editDestination
						? 'Alterar conta beneficiária'
						: 'Adicionar conta beneficiária'
				}
				confirmText={editDestination ? 'Alterar conta' : 'Criar conta'}
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					if (destinationToConfirm) {
						onCreateDestination(destinationToConfirm);
					}
				}}
				onCancel={() => toggleIsDestinationConfirmOpen(false)}
				onClose={() => toggleIsDestinationConfirmOpen(false)}
			>
				{editDestination ? (
					<TextL>
						Você tem certeza que deseja alterar essa conta
						beneficiária?
					</TextL>
				) : (
					<TextL>
						Você tem certeza que deseja criar essa conta
						beneficiária?
					</TextL>
				)}
			</ConfirmationModal>
			<ConfirmationModal
				isOpen={isDestinationRemoveOpen}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					if (destinationToConfirm) {
						deleteDestination(destinationToConfirm.id);
					}
				}}
				onCancel={() => toggleIsDestinationRemoveOpen(false)}
				onClose={() => toggleIsDestinationRemoveOpen(false)}
			>
				<TextL>
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
		</ContainerFluid>
	);
};

export default AccountDestinationPerson;
