import { ButtonProps } from 'antd';
import { IconContainer, ChildContainer, ButtonFlex } from './styles';

export interface FilterButtonProps extends ButtonProps {
	icon: React.ReactNode;
	children?: React.ReactNode;
	onClick?: () => void;
	variation?: 'primary' | 'secondary';
}

const FilterButton = ({
	children,
	icon,
	onClick,
	variation = 'primary',
	...props
}: FilterButtonProps) => {
	return (
		<ButtonFlex onClick={onClick} variation={variation} {...props}>
			<IconContainer variation={variation}>{icon}</IconContainer>
			<ChildContainer variation={variation}>{children}</ChildContainer>
		</ButtonFlex>
	);
};

export default FilterButton;
