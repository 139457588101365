import React, { useState } from 'react';
import { Form, Input } from 'antd';
import {
	TwoFactorCodeFormContainer,
	TwoFactorCodeFormError,
	TwoFactorCodeFormInputList,
	TwoFactorCodeFormItem,
	TwoFactorCodeFormSubmit,
	TwoFactorText,
} from './styles';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { TwoFactorCodeFormType } from './types';
import { CognitoErrorCode } from 'modules/core/types/Cognito';

const VALID_INPUT_REGEX = /^[A-Za-z0-9]*$/;

const TwoFactorCodeForm: React.FC = () => {
	const { confirmCode, resetError, isLoading, errorCode } = useAuthContext();
	const [error, setError] = useState<string | null>(null);

	const onKeyDown =
		(index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
			const charCode = e.which || e.keyCode;
			const char = String.fromCharCode(charCode);
			if (e.key === 'Backspace' && index > 0) {
				setTimeout(() =>
					document.getElementById(`code-${index - 1}`)?.focus(),
				);
			} else if (VALID_INPUT_REGEX.test(char) && index < 6) {
				setTimeout(() =>
					document.getElementById(`code-${index + 1}`)?.focus(),
				);
			}
		};

	return (
		<TwoFactorCodeFormContainer
			style={{ width: '90%' }}
			initialValues={{ code: [{}, {}, {}, {}, {}, {}] }}
			layout="vertical"
			onFieldsChange={() => {
				setError(null);
				resetError();
			}}
			onFinish={(values: TwoFactorCodeFormType) => {
				const code = values.code
					.map((item, i) => item[`code${i}`])
					.join('');
				if (code.length < 6) {
					setError('Campo obrigatório!');
					return;
				}
				confirmCode(code);
			}}
		>
			<TwoFactorText>
				Digite o código gerado pelo aplicativo no campo abaixo para
				concluir a autenticação.
			</TwoFactorText>
			<Form.List name="code">
				{fields => (
					<TwoFactorCodeFormInputList>
						{fields.map(({ key, name, ...restField }) => (
							<TwoFactorCodeFormItem
								key={key}
								name={[name, `code${key}`]}
								validateStatus={
									error !== null ||
									errorCode === CognitoErrorCode.MFA_FAILED
										? 'error'
										: undefined
								}
							>
								<Input
									{...restField}
									id={`code-${key}`}
									autoFocus={key === 0 ? true : false}
									onKeyDown={onKeyDown(key)}
									maxLength={1}
									style={{
										textAlign: 'center',
										padding: '6.4px 0',
									}}
								/>
							</TwoFactorCodeFormItem>
						))}
					</TwoFactorCodeFormInputList>
				)}
			</Form.List>
			{error && <TwoFactorCodeFormError>{error}</TwoFactorCodeFormError>}
			{errorCode === CognitoErrorCode.MFA_FAILED && (
				<TwoFactorCodeFormError>Código inválido</TwoFactorCodeFormError>
			)}
			<TwoFactorCodeFormSubmit
				loading={isLoading}
				type="primary"
				htmlType="submit"
			>
				Confirmar
			</TwoFactorCodeFormSubmit>
		</TwoFactorCodeFormContainer>
	);
};

export default TwoFactorCodeForm;
