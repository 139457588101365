import { AccountPermission, AccountRelatedPart } from 'types/Account';
import { Company } from 'types/Company';

export type FullAccountRelatedPart = AccountRelatedPart & {
	companyDetails?: Company;
};

export enum StepsId {
	PERSONAL_INFORMATION = 'personal_information',
	REPRESENTATIVES = 'representatives',
	RELATED_PARTS = 'related_parts',
	SIGNERS = 'signers',
	DESTINATION = 'destination',
}

export type StepsStatus = {
	[key: string]: {
		completed: boolean;
	};
};

export const paymentAccountPermissions = [
	AccountPermission.ARCHIVE_WALLET,
	AccountPermission.CANCEL_CHARGE,
	AccountPermission.CREATE_CHARGE,
	AccountPermission.CREATE_WALLET,
	AccountPermission.POSTING_CREATE,
	AccountPermission.POSTING_REVIEW,
	AccountPermission.POSTING_VIEW,
	AccountPermission.UPDATE_CHARGE,
	AccountPermission.UPDATE_WALLET,
	AccountPermission.VIEW_ACCOUNT,
	AccountPermission.VIEW_CHARGE,
	AccountPermission.VIEW_WALLET,
];
