import React from 'react';

export const CalendarIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.50033 0.621094V2.28776H12.5003V0.621094H14.167V2.28776H17.5003C17.9606 2.28776 18.3337 2.66086 18.3337 3.12109V16.4544C18.3337 16.9147 17.9606 17.2878 17.5003 17.2878H2.50033C2.04009 17.2878 1.66699 16.9147 1.66699 16.4544V3.12109C1.66699 2.66086 2.04009 2.28776 2.50033 2.28776H5.83366V0.621094H7.50033ZM16.667 8.95443H3.33366V15.6211H16.667V8.95443ZM9.16699 10.6211V13.9544H5.00033V10.6211H9.16699ZM5.83366 3.95443H3.33366V7.28776H16.667V3.95443H14.167V5.62109H12.5003V3.95443H7.50033V5.62109H5.83366V3.95443Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
