import { DescriptionsHeader } from 'components/DescriptionsVertical/styles';
import { Heading2 } from 'components/Heading2';
import styled from 'styled-components';

export const DescriptionsVerticalWrapper = styled.div`
	th.ant-descriptions-item-label {
		padding-top: 12px !important;
		padding-bottom: 12px !important;
		padding-left: 0px !important;
	}
	.ant-descriptions-view {
		padding: 0px 20px !important;
	}
`;

export const DescriptionsVerticalMainWrapper = styled.div`
	th.ant-descriptions-item-label {
		padding-top: 12px !important;
		padding-bottom: 12px !important;
		padding-left: 0px !important;
	}
	.ant-descriptions-view {
		padding: 0px 0px !important;
	}

	${DescriptionsHeader} {
		padding: 4px 4px 8px;

		${Heading2} {
			font-size: 24px;
		}
	}
`;
