import styled from 'styled-components';

export const SplashScreenContent = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
`;
