import { Divider, Flex } from 'antd';
import { TextM, TextS } from 'components/Text';
import { normalizeBoleto } from 'helpers/normalizers';
import React from 'react';
import theme from 'theme/theme';
import { Posting } from 'types/Posting';

export interface BillPaymentDrawerContentProps {
	postingDetail: Posting | undefined;
}

const BillPaymentDrawerContent = ({
	postingDetail,
}: BillPaymentDrawerContentProps) => {
	return (
		<div>
			<Divider
				style={{
					margin: 0,
				}}
			/>
			<TextM
				weight="bold"
				style={{
					marginTop: '1rem',
				}}
			>
				Dados da cobrança
			</TextM>

			<Flex
				style={{
					width: '100%',
					flexDirection: 'column',
				}}
				gap={16}
			>
				<Flex
					justify="space-between"
					gap={16}
					align="center"
					style={{
						marginTop: 16,
					}}
				>
					<TextS
						style={{
							color: theme.textGray,
							minWidth: '30%',
						}}
					>
						Pagador
					</TextS>
					<TextS>
						{postingDetail?.billpayment_payer
							? postingDetail?.billpayment_payer.includes(
									'undefined',
								)
								? '-'
								: postingDetail.billpayment_payer
							: '-'}
					</TextS>
				</Flex>

				<Flex justify="space-between" gap={16} align="center">
					<TextS
						style={{
							color: theme.textGray,
							minWidth: '30%',
						}}
					>
						Beneficiário
					</TextS>
					<TextS>
						{postingDetail?.billpayment_beneficiary
							? postingDetail?.billpayment_beneficiary.includes(
									'undefined',
								)
								? '-'
								: postingDetail?.billpayment_beneficiary
							: '-'}
					</TextS>
				</Flex>

				<Flex justify="space-between" gap={16} align="center">
					<TextS
						style={{
							color: theme.textGray,
							minWidth: '30%',
						}}
					>
						Código de barras
					</TextS>
					<TextS>
						{postingDetail?.bar_code
							? normalizeBoleto(postingDetail?.bar_code)
							: '-'}
					</TextS>
				</Flex>

				<Flex justify="space-between" gap={16} align="center">
					<TextS
						style={{
							color: theme.textGray,
							minWidth: '30%',
						}}
					>
						Identificador
					</TextS>
					<TextS>
						{postingDetail?.billpayment_txn_id_auth ?? '-'}
					</TextS>
				</Flex>
			</Flex>
		</div>
	);
};

export default BillPaymentDrawerContent;
