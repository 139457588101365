import { Heading1 } from 'components/Heading1';
import { AccountInfoHeaderWrapper, Balance, DescriptionItem } from './styles';
import { Heading2 } from 'components/Heading2';
import { formatCurrency } from 'helpers/normalizers';
import { Button, Flex, Spin, Tooltip } from 'antd';
import { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useTheme } from 'styled-components';
import { TextS } from 'components/Text';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export interface AccountInfoHeaderProps {
	balance?: number;
	blockedAmount?: number;
	isLoading?: boolean;
	accountBlocked?: boolean;
}

const AccountInfoHeader = ({
	balance,
	blockedAmount,
	isLoading,
	accountBlocked,
}: AccountInfoHeaderProps) => {
	const [showBalance, setShowBalance] = useState(false);
	const theme = useTheme();

	return (
		<AccountInfoHeaderWrapper id="account-info-balance-header">
			<Heading1 style={{ color: theme.secondary }}>Extrato</Heading1>
			<Balance>
				<Flex vertical>
					<Flex align="center">
						<Heading2
							style={{
								color: theme.secondary,
								fontWeight: 'bold',
								marginRight: '0.5rem',
							}}
						>
							Saldo disponível:
						</Heading2>
						<Heading2
							style={{
								color: theme.textGray,
							}}
						>
							{isLoading ? (
								<Spin />
							) : accountBlocked ? (
								'Conta bloqueada'
							) : showBalance ? (
								typeof balance === 'number' ? (
									formatCurrency(balance)
								) : (
									'-'
								)
							) : (
								<DescriptionItem color={theme.textSecondary}>
									*********
								</DescriptionItem>
							)}
						</Heading2>
						{isLoading || accountBlocked ? null : (
							<Button
								type="link"
								size="large"
								icon={showBalance ? <BsEyeSlash /> : <BsEye />}
								onClick={() => setShowBalance(!showBalance)}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							/>
						)}
					</Flex>
					{blockedAmount && blockedAmount > 0 ? (
						<Flex style={{ marginTop: '-5px' }} align="center">
							<TextS
								style={{
									marginRight: '0.5rem',
								}}
							>
								Saldo bloqueado:
							</TextS>
							<div style={{ marginRight: '0.5rem' }}>
								{showBalance ? (
									<TextS color={theme.danger}>
										{formatCurrency(blockedAmount)}
									</TextS>
								) : (
									<TextS color={theme.textSecondary}>
										*********
									</TextS>
								)}
							</div>
							<Tooltip
								title="O saldo foi bloqueado pela Celcoin. Para mais informações, entre em contato."
								placement="bottom"
								color={theme.textSecondary}
							>
								<IoMdInformationCircleOutline
									size={16}
									color={theme.textDisabled}
								/>
							</Tooltip>
						</Flex>
					) : undefined}
				</Flex>
			</Balance>
		</AccountInfoHeaderWrapper>
	);
};

export default AccountInfoHeader;
