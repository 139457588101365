import { Button, Col, Flex, Row, Spin } from 'antd';
import { TextS } from 'components/Text';
import { IoIosArrowForward } from 'react-icons/io';
import theme from 'theme/theme';

export interface FooterProps {
	defaultButtonText?: string;
	primaryButtonText?: string;
	primaryButtonDisabled?: boolean;
	isLoading?: boolean;
	onDefaultButtonClick?: () => void;
	onPrimaryButtonClick?: () => void;
	primaryButtonIcon?: React.ReactNode;
}

const Footer = ({
	defaultButtonText = 'Voltar',
	primaryButtonText = 'Avançar',
	primaryButtonDisabled = false,
	isLoading,
	onDefaultButtonClick,
	onPrimaryButtonClick,
	primaryButtonIcon,
}: FooterProps) => {
	const isDisabled = primaryButtonDisabled || isLoading;
	const textColor = isDisabled ? theme.primary : theme.white;

	return (
		<Row>
			<Col span={24}>
				<Flex justify="end" gap={10}>
					<Button
						type="default"
						style={{ outline: 'none', border: 0 }}
						onClick={onDefaultButtonClick}
					>
						<TextS
							style={{
								color: theme.primary,
							}}
						>
							{defaultButtonText}
						</TextS>
					</Button>

					{isLoading ? (
						<Spin />
					) : (
						<Button
							type="primary"
							onClick={onPrimaryButtonClick}
							disabled={primaryButtonDisabled || isLoading}
						>
							<TextS
								style={{
									color: textColor,
									opacity: isDisabled ? '0.5' : undefined,
								}}
							>
								<Flex gap={10} align="center">
									{primaryButtonText}
									{primaryButtonIcon ?? <IoIosArrowForward />}
								</Flex>
							</TextS>
						</Button>
					)}
				</Flex>
			</Col>
		</Row>
	);
};

export default Footer;
