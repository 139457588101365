import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './modules/index';
import { ThemeProvider } from './theme/ThemeProvider';
import FontFaceObserver from 'fontfaceobserver';
import reportWebVitals from './reportWebVitals';

import 'sanitize.css/sanitize.css';
import { QueryProvider } from './network';
import { AuthProvider } from './modules/core/context/AuthContext';
import { ProviderComposer } from './components/ProviderComposer';
import { AppProvider } from './modules/core/context/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { ProfileProvider } from './modules/core/context/ProfileContext';

const openSansObserver = new FontFaceObserver('Inter', {});
openSansObserver.load().then(() => {
	document.body.classList.add('fontLoaded');
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<BrowserRouter>
		<QueryProvider>
			<ThemeProvider>
				<ProviderComposer
					contexts={[
						<AppProvider key="app-service" />,
						<AuthProvider key="auth-service" />,
						<ProfileProvider key="profile-service" />,
					]}
				>
					<App />
				</ProviderComposer>
			</ThemeProvider>
		</QueryProvider>
	</BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
