import { Flex } from 'antd';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';

export interface IUploadPercentage {
	loadingPercentage?: number;
}

const UploadPercentage = ({ loadingPercentage }: IUploadPercentage) => {
	const theme = useTheme();
	return (
		<div
			style={{
				width: '100%',
				height: '8px',
				borderRadius: '10px',
				backgroundColor: theme.white,
			}}
		>
			<div
				style={{
					width: `${loadingPercentage}%`,
					height: '100%',
					borderRadius: '10px',
					backgroundColor: theme.primary,
					transition: 'width 0.3s',
				}}
			/>
			<Flex align="center" justify="center">
				<TextS
					style={{
						color: theme.textGray,
						fontSize: '12px',
					}}
				>
					Fazendo upload...
				</TextS>
			</Flex>
		</div>
	);
};

export default UploadPercentage;
