import { Col, Row } from 'antd';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { Modal } from 'components/Modal';
import { TextL, TextM, TextS } from 'components/Text';
import dayjs from 'dayjs';
import {
	formatCurrency,
	normalizeBankAccount,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { useTheme } from 'styled-components';
import { Posting } from 'types/Posting';
import { BackgroundModalData } from './styles';
import { BankListOptions } from 'constants/banksList';

export interface PostingRejectModalProps {
	isOpen: boolean;
	onClose: () => void;
	postingData?: Posting;
	handleConfirm: () => void;
}

const PostingRejectModal = ({
	isOpen,
	onClose,
	postingData,
	handleConfirm,
}: PostingRejectModalProps) => {
	const theme = useTheme();

	const handleConfirmReject = () => {
		handleConfirm();
		onClose();
	};

	const renderPostingData = (postingData: Posting | undefined) => {
		return (
			<BackgroundModalData>
				<Row
					style={{ margin: '2rem 0', gap: '1rem' }}
					justify="space-between"
				>
					<Col span={10}>
						<TextS weight="bold">Data da solicitação:</TextS>
						<TextS color={theme.textSecondary}>
							{dayjs(postingData?.created_at).format(
								'DD/MM/YYYY - HH:mm:ss',
							)}
						</TextS>
					</Col>
					<Col span={10}>
						<TextS weight="bold">Valor:</TextS>
						<TextS color={theme.textSecondary}>
							{formatCurrency(postingData?.amount)}
						</TextS>
					</Col>
				</Row>
				<Row
					style={{ margin: '2rem 0', gap: '1rem' }}
					justify="space-between"
				>
					<Col span={20}>
						<TextS weight="bold">Descrição:</TextS>
						<TextS color={theme.textSecondary}>
							{`Crédito em conta: Banco ${
								BankListOptions.find(
									bank =>
										bank.value ===
										postingData?.account_destination.bank,
								)?.label
							} | Ag: ${
								postingData?.account_destination.branch
							} | Conta ${normalizeBankAccount(
								postingData?.account_destination.account || '',
							)} | ${
								postingData?.account_destination.name
							} | CPF/CNPJ ${normalizeCpfCnpj(
								postingData?.account_destination.taxpayer_id ||
									'',
							)}`}
						</TextS>
					</Col>
				</Row>
			</BackgroundModalData>
		);
	};

	return (
		<Modal isOpen={isOpen} title="Cancelar lançamento" onClose={onClose}>
			<Row style={{ marginBottom: '1.4rem' }}>
				<TextL weight="bold" color={theme.text}>
					Você tem certeza que deseja cancelar este lançamento?
				</TextL>
			</Row>
			{renderPostingData(postingData)}

			<Row style={{ margin: '2rem 0', gap: '1rem' }}>
				<ButtonPrimary
					onClick={handleConfirmReject}
					style={{
						justifyContent: 'center',
						padding: '1.4rem',
						backgroundColor: theme.danger,
						border: 0,
						cursor: 'pointer',
					}}
				>
					<TextM style={{ color: theme.white }}>
						Cancelar lançamento
					</TextM>
				</ButtonPrimary>

				<ButtonPrimary
					variant="secondary"
					onClick={onClose}
					style={{
						justifyContent: 'center',
						padding: '1.4rem',
						border: 0,
						boxShadow: 'none',
					}}
				>
					<TextM style={{ color: theme.primary }}>Cancelar</TextM>
				</ButtonPrimary>
			</Row>
		</Modal>
	);
};

export default PostingRejectModal;
