import { Modal } from 'components/Modal';
import { Posting, PostingAction, PostingType } from 'types/Posting';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PostingApprovalReview from './PostingApprovalReview';
import { usePostings } from 'modules/escrow/hooks/usePostings';
import { ApiError } from 'types/ApiError';
import PostingApprovalConfirmation from './PostingApprovalConfirmation';
import PostingApprovalSuccess from './PostingApprovalSuccess';
import { queryClient } from 'network/query';
import dayjs from 'dayjs';
import {
	AutoApprovePostingModal,
	AutoApprovePostingResultModal,
} from '../AutoApprovePostingModal';
import { AutoApprovePostingSucessModalType } from '../AutoApprovePostingModal/AutoApprovePostingResultModal';

enum PostingApprovalSteps {
	REVIEW = 'REVIEW',
	CONFIRMATION = 'CONFIRMATION',
	SUCCESS = 'SUCCESS',
}

export interface PostingApprovalProps {
	isOpen: boolean;
	postingData?: Posting[];
	onClose: () => void;
	onConfirm: () => void;
	onError: (e: ApiError) => void;
}

const PostingApproval = ({
	isOpen,
	postingData,
	onClose,
	onConfirm,
	onError,
}: PostingApprovalProps) => {
	const [comment, setComment] = useState('');
	const [step, updateStep] = useState<PostingApprovalSteps>(
		PostingApprovalSteps.REVIEW,
	);
	const [autoApproveModalOpen, updateAutoApproveModalOpen] = useState(false);
	const [showSuccessModal, updateShowSuccessModal] = useState(false);
	const [showErrorModal, updateShowErrorModal] = useState<string>('');
	const [showTimeoutModal, updateShowTimeoutModal] = useState(false);

	useEffect(() => {
		if (isOpen) {
			updateStep(PostingApprovalSteps.REVIEW);
		}
	}, [isOpen]);

	const { reviewPosting, isUpdating } = usePostings({
		onReviewSuccess: () => {
			if (
				postingData &&
				postingData.length === 1 &&
				postingData?.[0].scheduled_date ===
					dayjs().format('YYYY-MM-DD') &&
				postingData?.[0]?.type === PostingType.PIX
			) {
				updateAutoApproveModalOpen(true);
				return;
			}

			updateStep(PostingApprovalSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['listPostings'],
			});
		},
		onError: e => {
			onError(e);
			queryClient.refetchQueries({
				queryKey: ['listPostings'],
			});
		},
	});

	const handleConfirm = useCallback(async () => {
		if (!postingData) {
			onClose();
			return;
		}
		if (postingData.length > 1) {
			for (const posting of postingData) {
				reviewPosting({
					action: PostingAction.APPROVE,
					postingId: posting.id,
					reason: comment,
				});
			}
			return;
		}
		reviewPosting({
			action: PostingAction.APPROVE,
			postingId: postingData[0].id,
			reason: comment,
		});
	}, [postingData, comment, reviewPosting, onClose]);

	const title = useMemo(() => {
		if (step === PostingApprovalSteps.SUCCESS)
			return 'Solicitação aprovada!';
		return 'Aprovar solicitação';
	}, [step]);

	const size = useMemo(() => {
		if (step === PostingApprovalSteps.REVIEW) return 600;
		return 480;
	}, [step]);

	return (
		<>
			<Modal isOpen={isOpen} width={size} title={title} onClose={onClose}>
				{step === PostingApprovalSteps.REVIEW && postingData && (
					<PostingApprovalReview
						onClose={onClose}
						postingData={postingData}
						onConfirm={comment => {
							setComment(comment);
							updateStep(PostingApprovalSteps.CONFIRMATION);
						}}
					/>
				)}
				{step === PostingApprovalSteps.CONFIRMATION && (
					<PostingApprovalConfirmation
						isLoading={isUpdating}
						onClose={onClose}
						onConfirm={() => handleConfirm()}
					/>
				)}
				{step === PostingApprovalSteps.SUCCESS && postingData && (
					<PostingApprovalSuccess
						onConfirm={onConfirm}
						postingData={postingData}
					/>
				)}
			</Modal>

			<AutoApprovePostingModal
				isOpen={autoApproveModalOpen}
				onClose={() => {
					updateAutoApproveModalOpen(false);
					queryClient.refetchQueries({
						queryKey: ['listPostings'],
					});
					onClose();
				}}
				postingId={postingData?.[0]?.id ?? ''}
				onFinish={(success, message) => {
					updateAutoApproveModalOpen(false);
					onClose();
					if (success) {
						updateShowSuccessModal(true);
						return;
					}
					if (success === undefined) {
						updateShowTimeoutModal(true);
					}
					if (success === false) {
						updateShowErrorModal(message ?? '');
					}
				}}
			/>
			<AutoApprovePostingResultModal
				isOpen={showSuccessModal}
				onClose={() => updateShowSuccessModal(false)}
				modalType={AutoApprovePostingSucessModalType.SUCCESS}
			/>
			<AutoApprovePostingResultModal
				isOpen={!!showErrorModal}
				onClose={() => updateShowErrorModal('')}
				modalType={AutoApprovePostingSucessModalType.ERROR}
				textMessage={showErrorModal}
			/>
			<AutoApprovePostingResultModal
				isOpen={showTimeoutModal}
				onClose={() => updateShowTimeoutModal(false)}
				modalType={AutoApprovePostingSucessModalType.TIMEOUT}
			/>
		</>
	);
};

export default PostingApproval;
