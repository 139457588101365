import React from 'react';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import NaturalPersonDetailsUsers from './NaturalPersonDetailsUsers';
import { CompanyIcon, CompanyRoutes } from 'modules/company/constants/routes';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import { PersonsService } from 'modules/company/services';
import { SplashScreen } from 'components/SplashScreen';
import NaturalPersonDetailsInformation from './NaturalPersonDetailsInformation';
import NaturalPersonDetailsRepresentatives from './NaturalPersonDetailsRepresentatives';

const NaturalPersonDetailsPage = () => {
	const { id } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: ['companyDetails', id],
		queryFn: () => PersonsService.getCompany(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to={CompanyRoutes.BASE} />;
	}

	return (
		<>
			<PageHeader isFluid={true}>
				<Heading1>{data?.name}</Heading1>
			</PageHeader>
			<PageWrapper isFluid={true}>
				<Tabs
					prePath={`/natural-person/${id}`}
					elements={[
						{
							title: 'Informações',
							content: (
								<NaturalPersonDetailsInformation
									person={data}
								/>
							),
							path: 'info',
							breadcrumb: [
								{
									href: CompanyRoutes.BASE,
									title: (
										<BreadcrumbContainer>
											<CompanyIcon />
											<span>Clientes</span>
										</BreadcrumbContainer>
									),
								},
								{
									title: data?.name,
								},
							],
						},
						{
							title: 'Procuradores',
							content: (
								<NaturalPersonDetailsRepresentatives
									person={data}
								/>
							),
							path: 'representatives',
							breadcrumb: [
								{
									href: CompanyRoutes.BASE,
									title: (
										<BreadcrumbContainer>
											<CompanyIcon />
											<span>Clientes</span>
										</BreadcrumbContainer>
									),
								},
								{
									title: data?.name,
								},
							],
						},
						{
							title: 'Acessos',
							content: (
								<NaturalPersonDetailsUsers company={data} />
							),
							path: 'access',
							breadcrumb: [
								{
									href: CompanyRoutes.BASE,
									title: (
										<BreadcrumbContainer>
											<CompanyIcon />
											<span>Clientes</span>
										</BreadcrumbContainer>
									),
								},
								{
									title: data?.name,
								},
							],
						},
					]}
				/>
			</PageWrapper>
		</>
	);
};

export default NaturalPersonDetailsPage;
