import { ESortingPage, List } from 'types/List';

export enum EDepositManagementStatus {
	CREATED = 'CREATED',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	TRANSFERING = 'TRANSFERING',
	TRANSFERED = 'TRANSFERED',
	CHECKING_POSTING = 'CHECKING_POSTING',
	REMOVED = 'REMOVED',
	ERROR = 'ERROR',
}
export enum EDepositManagementLabel {
	CREATED = 'Ativo',
	ACTIVE = 'Ativo',
	INACTIVE = 'Inativo',
	TRANSFERING = 'Transferindo',
	TRANSFERED = 'Transferido',
	CHECKING_POSTING = 'Conferindo',
	REMOVED = 'Removido',
	ERROR = 'Erro',
}

interface IDepositDestination {
	type: string;
	account: string;
	branch: string;
	bank: string;
	name: string;
	taxpayer_id: string;
	id: string;
}

interface IDepositBalanceRetention {
	id: string;
	amount_to_pay_off?: number;
	blocked_amount: number;
}

export interface DepositManagementItem {
	amount_percentage: number;
	balance_retention?: IDepositBalanceRetention | null;
	created_at: string;
	description?: string;
	destination?: IDepositDestination | null;
	end_date: string;
	id: string;
	max_amount?: number;
	name: string;
	origins?: string[];
	should_retain_slc: boolean;
	start_date: string;
	status: EDepositManagementStatus;
}

export interface IDepositManagementForm {
	beneficiaryAccount?: { label: string; value: string };
	description?: string;
	endDate?: string;
	id?: string;
	name?: string;
	origins?: string[];
	originsRetention?: boolean;
	percent?: number;
	slcRetention?: boolean;
	startDate?: string;
	totalAmount?: number | null;
}

export interface IDepositRetentionPayload {
	account_destination?: { label: string; value: string } | string;
	amount_percentage: number;
	description?: string;
	end_date: string;
	max_amount?: number | null;
	name: string;
	origins: string[];
	should_retain_slc: boolean;
	start_date: string;
}

export interface IDepositRetentionResponse {
	financialInstitutionId: string;
	name: string;
	description: string;
	status: EDepositManagementStatus;
	startDate: string;
	endDate: string;
	amountPercentage: number;
	maxAmount?: string;
	origins: string[];
	shouldRetainSlc: boolean;
	accountDestinationId: string;
	accountId: string;
}

export interface IDepositManagementListParams {
	page?: number;
	size?: number;
	order?: ESortingPage;
	sort?: string;
}
