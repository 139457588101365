import { Phone } from 'types/Phone';
import { normalizePhone } from './normalizers';

export const formatPhoneFromObject = (phoneObject: Phone): string => {
	const phone = `${phoneObject.area_code}${phoneObject.number}`;
	return normalizePhone(phone);
};

export const getDigitsFromValue = (value = '') =>
	value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits: string) => {
	const desiredLength = 7;
	const actualLength = digits.length;

	if (actualLength >= desiredLength) {
		return digits;
	}

	const amountToAdd = desiredLength - actualLength;
	const padding = '0'.repeat(amountToAdd);

	return padding + digits;
};

export const toChargeNumber = (value: string, separator = '.') => {
	const digits = getDigitsFromValue(value);
	const digitsWithPadding = padDigits(digits);
	return digitsWithPadding;
};

export const formatAddZerosMaskInput = (value: string): string => {
	const res = toChargeNumber(value);
	return res;
};

export const truncateString = (str: string, length: number): string => {
	if (str.length <= length) {
		return str;
	}

	return `${str.slice(0, length)}...`;
};
