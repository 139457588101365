import { Modal } from 'components/Modal';
import React, { createContext, useCallback, useState } from 'react';
import DropArea from './DropArea';
import { Flex, notification } from 'antd';
import ChargesReview from './ChargesReview';
import { UploadService } from 'modules/core/services/UploadService';
import { ApiError } from 'types/ApiError';
import { useMutation, useQuery } from '@tanstack/react-query';
import { WalletsService } from 'modules/debitCollections/services';
import { queryClient } from 'network/query';
import { saveAs } from 'file-saver';
import { WalletContent } from 'types/Wallet';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { ImportCNABFile, ImportCNABFileParsed } from '../types';
import dayjs from 'dayjs';

export interface ICnabImportProps {
	isOpen: boolean;
	wallet: WalletContent;
	onClose: () => void;
}

export interface IUploadCnabProviderContext {
	wallet: WalletContent;
	chargeReviewData: ImportCNABFileParsed | null;
	step: UploadCnabSteps;
	fileUploadedKey: string;
	file?: File | null;
	loadingCnabTemplate?: boolean;
	setDataReviewData: (data: ImportCNABFileParsed) => void;
	updateStep: (step: UploadCnabSteps) => void;
	setFile?: (file: File) => void;
	onClose: () => void;
	handleConfirmImports: () => void;
}

export enum UploadCnabSteps {
	UPLOAD = 'UPLOAD',
	REVIEW = 'REVIEW',
}

const ModalMaxSize = {
	[UploadCnabSteps.UPLOAD]: 500,
	[UploadCnabSteps.REVIEW]: 880,
};

export const UploadCnabProviderContext =
	createContext<IUploadCnabProviderContext>({
		wallet: {} as WalletContent,
		chargeReviewData: null,
		step: UploadCnabSteps.UPLOAD,
		fileUploadedKey: '',
		loadingCnabTemplate: false,
		setDataReviewData: () => {
			throw new Error('Method not implemented.');
		},
		updateStep: () => {
			throw new Error('Method not implemented.');
		},
		onClose: () => {
			throw new Error('Method not implemented.');
		},
		handleConfirmImports: () => {
			throw new Error('Method not implemented.');
		},
	});

const CnabImport = ({ isOpen, wallet, onClose }: ICnabImportProps) => {
	const [api, contextHolder] = notification.useNotification();
	const [step, updateStep] = useState(UploadCnabSteps.UPLOAD);
	const [fileUploadedKey, setFileUploadedKey] = useState('');
	const [file, setFile] = useState<File | null>(null);
	const [chargeReviewData, setDataReviewData] =
		useState<ImportCNABFileParsed | null>(null);
	const [loadingPercentage, setLoadingPercentage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isUserCreationSuccessOpen, setIsUserCreationSuccessOpen] =
		useState(false);

	const handleDrop = useCallback((file: File) => {
		setFile(file);
	}, []);

	const { isLoading: loadingCnabTemplate, refetch } = useQuery({
		queryKey: ['getWalletCnabTemplate'],
		enabled: false,
		queryFn: async () => {
			return WalletsService.getCNABImportTemplateUrl(wallet.id!).then(
				data => {
					saveAs(
						data.wallet_cnab_import_template,
						'cnab_template.REM',
					);
				},
			);
		},
	});

	const { mutate: uploadCnabFileKey } = useMutation<
		ImportCNABFile,
		ApiError,
		{
			key: string;
		}
	>({
		mutationFn: async ({ key }) => {
			return WalletsService.importCNABFileKey(wallet.id!, key);
		},
		onSuccess: data => {
			// to handle better data behavior control
			const parsedData = data.charges.map((charge, index) => ({
				...charge,
				row_index: index,
			}));

			setDataReviewData({
				...data,
				charges: parsedData,
			});

			if ((data.file_errors?.length || 0) > 0) {
				return;
			}
			updateStep(UploadCnabSteps.REVIEW);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar os dados de importação.',
			});
		},
	});

	const handleCreateCnabImport = useCallback(
		(uploadKey: string) => {
			uploadCnabFileKey({ key: uploadKey });
		},
		[fileUploadedKey],
	);

	const handleSetUploadLoadingFalse = useCallback(async () => {
		return new Promise(resolve => {
			setTimeout(() => {
				setLoading(false);
				resolve(true);
			}, 1000);
		});
	}, []);

	const handleRequestUploadFile = useCallback(() => {
		setLoading(true);
		UploadService.uploadFile(file!, e => {
			const percentage = Math.round((e.loaded * 100) / (e.total || 1));
			setLoadingPercentage(percentage);
		})
			.then(async upload => {
				setFileUploadedKey(upload.key);
				await handleSetUploadLoadingFalse();
				handleCreateCnabImport(upload.key);
			})
			.catch(async (e: ApiError) => {
				api.error({
					description: e?.data?.message,
					message: 'Ocorreu um problema ao enviar o arquivo.',
				});
				await handleSetUploadLoadingFalse();
			});
	}, [file]);

	const handleClose = useCallback(() => {
		setFile(null);
		updateStep(UploadCnabSteps.UPLOAD);
		setDataReviewData(null);
		setFileUploadedKey('');
		onClose();
	}, []);

	const handleDownloadTemplate = useCallback(() => {
		refetch();
	}, []);

	const handleConfirmImports = useCallback(() => {
		const parsedCharges = chargeReviewData?.charges.map(charge => ({
			...charge,
			amount: Number(charge.amount),
			discount_value: Number(charge.discount_value),
			fine: Number(charge.fine),
			interest: Number(charge.interest),
			days_to_expire_after_payment: Number(
				charge.days_to_expire_after_payment,
			),
			due_date: dayjs(charge.due_date).format('YYYY-MM-DD'),
			discount_limit_date: dayjs(charge.discount_limit_date).format(
				'YYYY-MM-DD',
			),
		}));
		WalletsService.importReviewedChargesBatch(
			wallet.id!,
			fileUploadedKey,
			parsedCharges!,
		)
			.then(() => {
				setIsUserCreationSuccessOpen(true);

				queryClient.refetchQueries({
					queryKey: ['chargesList'],
				});
			})
			.catch(e => {
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema ao importar as cobranças.',
				});
			});
	}, [chargeReviewData]);

	return (
		<>
			{contextHolder}
			<Modal
				title="Upload de CNAB"
				isOpen={isOpen}
				onClose={handleClose}
				width={ModalMaxSize[step]}
			>
				<Flex justify="center" align="center">
					<UploadCnabProviderContext.Provider
						value={{
							wallet,
							chargeReviewData,
							step,
							fileUploadedKey,
							loadingCnabTemplate,
							setDataReviewData,
							updateStep,
							onClose: handleClose,
							handleConfirmImports,
						}}
					>
						{step === UploadCnabSteps.UPLOAD && (
							<DropArea
								onUpload={handleDrop}
								removeFile={() => {
									setFile(null);
									setDataReviewData(null);
								}}
								file={file}
								onNext={handleRequestUploadFile}
								loading={loading}
								loadPercentage={loadingPercentage}
								downloadTemplate={handleDownloadTemplate}
							/>
						)}
						{step === UploadCnabSteps.REVIEW && <ChargesReview />}
					</UploadCnabProviderContext.Provider>
				</Flex>
			</Modal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserCreationSuccessOpen}
				title="CNAB adicionado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Cobranças criadas com sucesso"
				onClose={() => {
					setIsUserCreationSuccessOpen(false);
					handleClose();
				}}
			/>
		</>
	);
};

export default CnabImport;
