import { IoCheckmark } from 'react-icons/io5';
import { Input, Switch } from './styles';
import { useTheme } from 'styled-components';

export enum SwitchSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
}

const iconPosition = {
	[SwitchSize.SMALL]: '3px',
	[SwitchSize.MEDIUM]: '10px',
	[SwitchSize.LARGE]: '15px',
};

export enum Variant {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
}

export interface CustomSwitchProps {
	checked: boolean;
	size?: SwitchSize;
	variant?: Variant;
	handleChange: () => void;
}

const CustomSwitch = ({
	checked,
	size = SwitchSize.MEDIUM,
	variant = Variant.PRIMARY,
	handleChange,
}: CustomSwitchProps) => {
	const theme = useTheme();
	return (
		<div
			onClick={handleChange}
			style={{
				position: 'relative',
				display: 'flex',
				cursor: 'pointer',
			}}
		>
			<Input
				checked={checked}
				type="checkbox"
				onChange={handleChange}
				variant={variant}
			/>
			<Switch size={size} variant={variant} />
			{checked && (
				<div
					style={{
						position: 'relative',
					}}
				>
					<IoCheckmark
						style={{
							position: 'absolute',
							right: iconPosition[size],
							top: '50%',
							transform: 'translate(0, -50%)',
							zIndex: 99,
							color: theme.primary,
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default CustomSwitch;
