import { ESortingPage, List } from 'types/List';

// INTERFACES
export interface IBalanceManagementHistory {
	block_reason: string;
	block_type: EBalanceRetentionType;
	blocked_amount: number;
	created_at: string;
	id: string;
	removed_at?: string;
	remove_reason?: string;
}

export interface IBalanceManagement {
	amount_blocked: number;
	amount_available: number;
	paginated: List<IBalanceManagementHistory>;
}

export interface IBalanceManagementListParams {
	page: number;
	size: number;
	order: ESortingPage;
	sort: string;
}

export interface IBalanceBlockPayload {
	block_reason: string;
	blocked_amount: number;
}

export interface IBalanceUnblockPayload {
	remove_reason: string;
}

// TYPES
export type TBalanceForm = {
	amount: number;
};

export type TBalanceInfoModal = {
	id?: string;
	amount: number;
};

// ENUMS
export enum EBalanceRetentionType {
	BACKOFFICE_BLOCK = 'BACKOFFICE_BLOCK',
	FEE_DEBT = 'FEE_DEBT',
	UNBLOCK = 'UNBLOCK',
}

export enum EBalanceRetentionTypeLabel {
	BACKOFFICE_BLOCK = 'Bloqueio',
	FEE_DEBT = 'Taxa de manutenção',
	UNBLOCK = 'Bloqueio removido',
}
