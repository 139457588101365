import { useState } from 'react';
import { Button, Row } from 'antd';
import type { TableProps } from 'antd';
import { PersonsService } from 'modules/company/services';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Company, BusinessType, PersonType } from 'types/Company';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CompanyTypeDescription } from 'constants/company';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Table';
import { ESortingPage, PaginationInfo } from 'types/List';
import dayjs from 'dayjs';
import { FilterButton } from 'components/FilterButton';
import { LuSearch } from 'react-icons/lu';
import { TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { AiOutlineStop } from 'react-icons/ai';
import CompanyListFilters from './CompanyListFilters';
import { CompanyListFiltersType } from './types';
import { Creator } from 'types/Creator';
import { sortTableHandler } from 'helpers/tables';

const CompanyList = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [paginationInfo, updatePaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [filters, updateFilters] = useState<CompanyListFiltersType>(
		{} as CompanyListFiltersType,
	);
	const [isFiltersOpen, toggleFiltersOpen] = useState<boolean>(false);

	const handleClearFilters = () => {
		updateFilters({} as CompanyListFiltersType);
	};

	const { data, isLoading } = useQuery({
		queryKey: ['companyList', paginationInfo, filters, sorting],
		queryFn: () =>
			PersonsService.getPersons({
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				search: filters?.search,
				taxpayer_id: filters?.taxpayer_id,
				business_type: filters?.business_type,
				person_type: filters?.person_type,
				sort: sorting.sort,
				order: sorting.order,
			}),
		placeholderData: keepPreviousData,
		staleTime: 5000,
	});

	const columns: ColumnsType<Company> = [
		{
			title: 'Cliente',
			sorter: true,
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			render: (value: string) => <TextM weight="bold">{value}</TextM>,
		},
		{
			title: 'CPF/CNPJ',
			dataIndex: 'taxpayer_id',
			render: (taxpayer_id: string) => (
				<TextM weight="normal">{`${normalizeCpfCnpj(taxpayer_id)}`}</TextM>
			),
			width: '20%',
		},
		{
			title: 'Tipo',
			sorter: true,
			key: 'business_type',
			dataIndex: 'business_type',
			render: (business_type: BusinessType) => (
				<TextM weight="normal">
					{CompanyTypeDescription[business_type] || '-'}
				</TextM>
			),
			width: '15%',
		},
		{
			title: 'Data da criação',
			dataIndex: 'created_at',
			key: 'created_at',
			sorter: true,
			render: (created_at: string) => {
				return (
					<div>
						<TextM weight="normal">
							{dayjs(created_at).format('DD/MM/YYYY')}
						</TextM>
						<TextS weight="normal">
							às {dayjs(created_at).format('HH:mm:ss')}
						</TextS>
					</div>
				);
			},
			width: '15%',
		},
		{
			title: 'Criador',
			dataIndex: 'creator',
			sorter: true,
			key: 'creator.fullName',
			width: '15%',
			render: (creator: Creator) => (
				<TextM weight="normal">{creator.full_name}</TextM>
			),
		},
		{
			key: 'action',
			width: '10%',
			align: 'center',
			render: (company: Company) => (
				<Button
					type="link"
					onClick={() =>
						navigate(
							company.person_type === PersonType.LEGAL
								? `/company/${company.id}/info`
								: `/company/natural-person/${company.id}/info`,
						)
					}
				>
					Detalhes
				</Button>
			),
		},
	];

	return (
		<>
			<Row style={{ width: '100%', gap: '1rem', marginBottom: '1rem' }}>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => toggleFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={Object.keys(filters).length === 0}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</Row>
			<Table
				columns={columns}
				dataSource={data?.content || []}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						updatePaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
			<CompanyListFilters
				onApply={filters => {
					updateFilters(filters);
					toggleFiltersOpen(false);
				}}
				isOpen={isFiltersOpen}
				filters={filters}
				onClose={() => toggleFiltersOpen(false)}
			/>
		</>
	);
};

export default CompanyList;
