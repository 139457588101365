import { useCallback, useEffect } from 'react';
import { Col, Empty, Row, Switch, notification } from 'antd';
import { PageWrapper } from 'components/PageWrapper';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { UsersIcon } from 'modules/users/constants/routes';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessListContent, AccessPermission, AccessType } from 'types/Access';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { UsersService } from 'modules/users/services';
import { AccessService } from 'modules/users/services/access';
import { SplashScreen } from 'components/SplashScreen';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { normalizeCpf } from 'helpers/normalizers';
import { useTheme } from 'styled-components';
import { Heading2 } from 'components/Heading2';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { TextM } from 'components/Text';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'components/Table';
import { AccessPermissionDescription } from 'constants/access';
import { useAccess } from 'modules/users/hooks/useAccess';
import { queryClient } from 'network/query';
import {
	BreadcrumbItemType,
	BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';
import { CompanyIcon } from 'modules/company/constants/routes';
import { DescriptionsVerticalWrapper } from './styles';

type AccessPermissionItem = {
	access: AccessListContent;
	enabled: boolean;
	permission: AccessPermission;
};

interface IUsersDetailsProps {
	isCompany?: boolean;
}
const UsersDetails: React.FC<IUsersDetailsProps> = ({ isCompany }) => {
	const theme = useTheme();

	const [api, contextHolder] = notification.useNotification();
	const { setBreadcrumb } = useBreadcrumb();
	const { type, permissions, financial_institution } = useCurrentProfile();
	const { id, userId } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: ['usersDetails', userId],
		queryFn: () => UsersService.getUser(userId!),
		refetchOnWindowFocus: false,
		enabled: typeof userId === 'string',
	});

	const { data: accessList, isLoading: isAccessListLoading } = useQuery({
		queryKey: ['usersAccessDetails', userId],
		refetchOnWindowFocus: false,
		queryFn: () =>
			AccessService.getAccessList({
				taxpayer_id: data!.taxpayer_id,
			}),
		enabled: typeof data === 'object',
	});

	const { updateAccess, isLoading: isUpdating } = useAccess({
		onError: error => {
			api.error({
				message: 'Ocorreu um problema ao atualizar as permissões',
				description: error.data.message,
			});
		},
		onSuccess: () => {
			api.success({
				message: 'Permissões atualizadas com sucesso',
			});
			queryClient.refetchQueries({
				queryKey: ['usersAccessDetails', userId],
			});
		},
	});

	useEffect(() => {
		const items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] =
			[];

		if (isCompany && accessList?.content && accessList.content.length > 0) {
			items.push(
				{
					href: '/company',
					title: (
						<BreadcrumbContainer>
							<CompanyIcon />
							<span>Clientes</span>
						</BreadcrumbContainer>
					),
				},
				{
					href: `/company/${id}/access`,
					title: (
						<BreadcrumbContainer>
							<span>{accessList.content[0].person?.name}</span>
						</BreadcrumbContainer>
					),
				},
			);
		} else {
			items.push({
				href: '/users',
				title: (
					<BreadcrumbContainer>
						<UsersIcon />
						<span>Acessos</span>
					</BreadcrumbContainer>
				),
			});
		}

		items.push({
			title: (
				<BreadcrumbContainer>
					{typeof data?.full_name === 'string' ? data.full_name : ''}
				</BreadcrumbContainer>
			),
		});

		setBreadcrumb(items);
	}, [setBreadcrumb, data, accessList, isCompany, id]);

	const onChangePermission = useCallback(
		(item: AccessPermissionItem) => () => {
			updateAccess({
				userId: data!.id,
				accessDto: {
					permissions: item.enabled
						? item.access.permissions.filter(
								permission => permission !== item.permission,
							)
						: [...item.access.permissions, item.permission],
					type: item.access.type,
					personId: item.access.person?.id,
				},
			});
		},
		[data, updateAccess],
	);

	const columns: ColumnsType<AccessPermissionItem> = [
		{
			title: 'Empresa',
			width: '35%',
			render: (item: AccessPermissionItem) => (
				<TextM
					style={{ color: theme.textGray, padding: '0px 16px' }}
					weight="normal"
				>
					{AccessPermissionDescription[item.permission]}
				</TextM>
			),
		},
		{
			title: 'Tipo da parte',
			width: '30%',
			align: 'end',
			render: (item: AccessPermissionItem) => {
				return (
					<div style={{ padding: '0px 16px' }}>
						<Switch
							checked={item.enabled}
							size="default"
							disabled={isUpdating}
							onChange={onChangePermission(item)}
						/>
					</div>
				);
			},
		},
	];

	if (
		type !== AccessType.FINANCIAL_INSTITUTION &&
		!permissions.includes(AccessPermission.MANAGE_USER)
	) {
		return <Navigate to="/" />;
	}

	if (isLoading || isAccessListLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to="/users" />;
	}

	const userAccessList = accessList?.content.filter(
		({ type }) => type !== AccessType.FINANCIAL_INSTITUTION,
	);

	return (
		<>
			{contextHolder}
			<PageHeader>
				<Heading1>{data.full_name}</Heading1>
			</PageHeader>
			<PageWrapper>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<DescriptionsVerticalWrapper>
							<DescriptionsVertical
								title="Dados básicos"
								items={[
									{
										key: '1',
										label: 'Nome',
										children: data.full_name,
									},
									{
										key: '2',
										label: 'CPF',
										children: normalizeCpf(
											data.taxpayer_id,
										),
									},
									{
										key: '3',
										label: 'E-mail',
										children: data.email_address,
									},
								]}
							/>
						</DescriptionsVerticalWrapper>
					</Col>
				</Row>
				<Row
					style={{
						marginTop: '2rem',
						border: `1px solid ${theme.border}`,
						padding: '16px 20px',
						borderRadius: '6px',
					}}
				>
					<Col span={24}>
						<Heading2>Permissões</Heading2>
						<Row style={{ marginTop: '1rem' }}>
							<Col span={24}>
								{userAccessList && userAccessList.length > 0 ? (
									<Collapse
										noPadding={true}
										items={accessList?.content
											.filter(
												({ type }) =>
													type !==
													AccessType.FINANCIAL_INSTITUTION,
											)
											.map((access, i) => {
												return {
													key: `${i + 1}`,
													label: (
														<CollapseHeader>
															<TextM>
																{access.type ===
																AccessType.PERSON
																	? access
																			.person
																			?.name
																	: financial_institution.name}
															</TextM>
														</CollapseHeader>
													),
													children: (
														<Table
															columns={columns}
															showHeader={false}
															borderless={true}
															dataSource={Object.values(
																AccessPermission,
															).map(
																permission => {
																	return {
																		access,
																		enabled:
																			access.permissions.includes(
																				permission,
																			),
																		permission,
																	};
																},
															)}
															pagination={false}
														/>
													),
												};
											})}
									/>
								) : (
									<Empty
										description="Nenhum acesso com permissões disponível."
										image={null}
										style={{
											paddingBottom: '5rem',
											borderRadius: '10px',
										}}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</PageWrapper>
		</>
	);
};

export default UsersDetails;
