import React from 'react';

export const DownloadIcon: React.FC<{ color?: string; size?: string }> = ({
	color,
	size,
}) => (
	<svg
		width={size ? size : '24'}
		height={size ? size : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3 19.5H21V21.5H3V19.5ZM13 13.672L19.071 7.6L20.485 9.014L12 17.5L3.515 9.015L4.929 7.6L11 13.67V2.5H13V13.672Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
