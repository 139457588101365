import { NetworkApi } from 'network';
import { List } from 'types/List';
import {
	BarCode,
	ListPostingParams,
	Posting,
	PostingAction,
	PostingDto,
} from 'types/Posting';

export class PostingsService extends NetworkApi {
	public static createPosting(posting: PostingDto) {
		return this.post<{ id: string }, PostingDto>(`/postings`, posting);
	}

	public static listPostings(params?: ListPostingParams) {
		return this.get<List<Posting>>('/postings', params);
	}

	public static reviewPosting(
		postingId: string,
		action: PostingAction,
		reason?: string,
	) {
		return this.put<void, { action: PostingAction; reason?: string }>(
			`/postings/${postingId}/review`,
			{ action, reason },
		);
	}

	public static getPostingDetails(postingId: string) {
		return this.get<Posting>(`/postings/${postingId}`);
	}

	public static getBarCodeDetails(barcode: string) {
		return this.get<BarCode>(`/billpayments/${barcode}`);
	}

	public static cancelPosting(postingId: string) {
		return this.put<void, {}>(`/postings/${postingId}/cancel`, {});
	}
}
