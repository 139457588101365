import { useContext, useState } from 'react';
import { Button, notification } from 'antd';
import { UserCreation } from 'components/UserCreation';
import { Company } from 'types/Company';
import { AccessListContent } from 'types/Access';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextL, TextM } from 'components/Text';
import { useAccess } from 'modules/users/hooks/useAccess';
import cognito from 'modules/core/services/CognitoService';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { queryClient } from 'network/query';
import { AccessListTable } from 'components/AccessListTable';
import { LuPlusCircle } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';

interface ICompanyDetailsUsersProps {
	company: Company;
}
const CompanyDetailsUsers: React.FC<ICompanyDetailsUsersProps> = ({
	company,
}) => {
	const navigate = useNavigate();
	const { userProfile, userAccess } = useContext(ProfileContext);
	const [api, contextHolder] = notification.useNotification();
	const [isUserCreationSuccessOpen, toggleIsUserCreationSuccessOpen] =
		useState(false);
	const [isUserCreationOpen, toggleUserCreationOpen] = useState(false);
	const [userAccessToDelete, setUserAccessToDelete] = useState<
		AccessListContent | undefined
	>();

	const { deleteAccess, isLoading } = useAccess({
		onError: error => {
			api.error({
				description: error.data.message,
				message: 'Erro ao excluir acesso.',
			});
		},
		onSuccess: () => {
			api.success({
				message: 'Acesso excluído com sucesso.',
			});

			if (
				userAccessToDelete?.user.email_address ===
				userProfile?.email_address
			) {
				setUserAccessToDelete(undefined);
				if (userAccess && userAccess.length === 1) {
					cognito.signOut();
				}

				window.location.reload();
			}

			queryClient.refetchQueries({
				queryKey: ['accessList', company.id],
			});
		},
	});

	return (
		<>
			{contextHolder}
			<AccessListTable
				companyId={company.id}
				actions={
					<Button
						type="primary"
						onClick={() => toggleUserCreationOpen(true)}
					>
						Criar Usuário
						<LuPlusCircle
							style={{
								marginLeft: '0.4rem',
								marginBottom: '0.1rem',
							}}
							size={14}
						/>
					</Button>
				}
				onView={access =>
					navigate(`/company/${company.id}/access/${access.user.id}`)
				}
				onDelete={access => setUserAccessToDelete(access)}
			/>
			<UserCreation
				isOpen={isUserCreationOpen}
				companyId={company.id}
				onClose={() => {
					toggleUserCreationOpen(false);
				}}
				onSuccess={() => {
					toggleUserCreationOpen(false);
					toggleIsUserCreationSuccessOpen(true);
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserCreationSuccessOpen}
				title="Acesso criado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso criado com sucesso!"
				onClose={() => toggleIsUserCreationSuccessOpen(false)}
			/>
			<ConfirmationModal
				isOpen={!!userAccessToDelete}
				danger={true}
				title="Excluir Acesso"
				confirmText="Excluir Acesso"
				cancelText="Cancelar"
				isLoading={isLoading}
				onConfirm={() => {
					deleteAccess(
						userAccessToDelete!.user.id,
						userAccessToDelete!.id,
					);
					setUserAccessToDelete(undefined);
				}}
				onCancel={() => setUserAccessToDelete(undefined)}
				onClose={() => setUserAccessToDelete(undefined)}
			>
				<TextL>Você tem certeza que quer excluir esse acesso?</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default CompanyDetailsUsers;
