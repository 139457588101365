import React, { useState } from 'react';
import { Form, Input } from 'antd';
import {
	TwoFactorSmsFormContainer,
	TwoFactorCodeFormError,
	TwoFactorCodeFormSubmit,
	TwoFactorText,
} from './styles';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { TwoFactorSmsFormType } from './types';
import { CognitoErrorCode } from 'modules/core/types/Cognito';

interface TwoFactorSetupSmsFormProps {}
const TwoFactorSetupSmsForm: React.FC<TwoFactorSetupSmsFormProps> = () => {
	const { confirmSms, resetError, errorCode, isLoading } = useAuthContext();
	const [error, setError] = useState<string | null>(null);

	return (
		<TwoFactorSmsFormContainer
			style={{ width: '90%' }}
			layout="vertical"
			onFieldsChange={() => {
				setError(null);
				resetError();
			}}
			onFinish={(values: TwoFactorSmsFormType) => {
				confirmSms(values.phone).then(response => {
					if (!response) {
						setError('Erro ao confirmar o número de telefone');
					}
				});
			}}
		>
			<TwoFactorText>
				Digite o seu número de telefone abaixo para receber um código de
				verificação por SMS.
			</TwoFactorText>
			<Form.Item name="phone">
				<Input placeholder="(xx) xxxxx-xxxx" />
			</Form.Item>
			{error && <TwoFactorCodeFormError>{error}</TwoFactorCodeFormError>}
			{errorCode === CognitoErrorCode.MFA_FAILED && (
				<TwoFactorCodeFormError>Código inválido</TwoFactorCodeFormError>
			)}
			<TwoFactorCodeFormSubmit
				loading={isLoading}
				type="primary"
				htmlType="submit"
			>
				Confirmar
			</TwoFactorCodeFormSubmit>
		</TwoFactorSmsFormContainer>
	);
};

export default TwoFactorSetupSmsForm;
