import { Button, Col, Form, Input, Row, Select } from 'antd';
import { StepContainer, StepFooter, StepHeader } from '../styles';
import { Heading2 } from 'components/Heading2';
import { TextS } from 'components/Text';
import { normalizeCep } from 'helpers/normalizers';
import { Address } from 'types/Address';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { statesList } from 'constants/states';
import { Representative } from 'types/Representative';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';

interface IAddressStepProps {
	representative?: Representative;
	isLegalRepresentative?: boolean;
	onNext: (representative: Representative) => void;
	onBack: (representative: Representative) => void;
}
const AddressStep = ({
	representative,
	isLegalRepresentative,
	onNext,
	onBack,
}: IAddressStepProps) => {
	const [form] = useForm();
	const hasRepresentative =
		typeof representative !== 'undefined' &&
		typeof representative.address !== 'undefined' &&
		Object.keys(representative.address).length > 0;
	const [formValid, setFormValid] = useState(hasRepresentative);

	return (
		<StepContainer>
			<StepHeader>
				<Heading2>Endereço</Heading2>
				<TextS weight="normal">
					{`Informações sobre endereço do ${
						isLegalRepresentative ? 'procurador' : 'representante'
					}`}
				</TextS>
			</StepHeader>
			<Form<Address>
				form={form}
				style={{ width: '100%' }}
				initialValues={{
					postal_code: representative?.address?.postal_code,
					street_name: representative?.address?.street_name,
					street_number: representative?.address?.street_number,
					state_code: representative?.address?.state_code,
					city: representative?.address?.city,
					district: representative?.address?.district,
					extra_info: representative?.address?.extra_info,
				}}
				layout="vertical"
				onFieldsChange={(f, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched &&
								!hasRepresentative &&
								!field.name?.includes('extra_info')) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
				}}
				onFinish={values =>
					onNext({
						address: {
							...values,
							postal_code: serializeOnlyNumbers(
								values.postal_code,
							),
							country_code: 'BRZ',
						},
					} as Representative)
				}
			>
				<Row gutter={[16, 16]}>
					<Col span={6}>
						<Form.Item
							name="postal_code"
							label="CEP"
							normalize={e => normalizeCep(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="00000-000" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={20}>
						<Form.Item
							name="street_name"
							label="Rua/Avenida"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Av Presidente Vargas" />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							name="street_number"
							label="Número"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: 300" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Form.Item
							name="state_code"
							label="Estado"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								showSearch={true}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.startsWith(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.startsWith(input.toLowerCase())
								}
								placeholder="Selecione o estado"
								options={statesList}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="city"
							label="Cidade"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: São Paulo" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							name="district"
							label="Bairro"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Planalto do Sol" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={16}>
						<Form.Item name="extra_info" label="Complemento">
							<Input placeholder="Ex.: Próximo a escola..." />
						</Form.Item>
					</Col>
				</Row>
				<StepFooter>
					<Button
						type="link"
						onClick={() =>
							onBack({
								address: form.getFieldsValue(),
							} as Representative)
						}
					>
						Voltar
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						Avançar
					</Button>
				</StepFooter>
			</Form>
		</StepContainer>
	);
};

export default AddressStep;
