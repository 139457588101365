import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AccountsService } from '../services';
import { StatementStatusResponse } from 'types/Account';

export interface fetchAccountStatementDetailsProps {
	accountId: string;
	clientCode: string;
}

export const useAccountStatement = (options?: {
	onError: (error: ApiError) => void;
	onSuccess: (data: StatementStatusResponse) => void;
}) => {
	const {
		mutate: getAccountStatementDetailsMutate,
		isPending: fetchAccountDetailsLoading,
	} = useMutation<
		StatementStatusResponse,
		ApiError,
		fetchAccountStatementDetailsProps
	>({
		mutationFn: variables => {
			return AccountsService.getStatementStatus(
				variables.accountId,
				variables.clientCode,
			);
		},
		onSuccess: data => {
			if (typeof options?.onSuccess === 'function') {
				options.onSuccess(data);
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const fetchAccountStatementDetails = async ({
		accountId,
		clientCode,
	}: fetchAccountStatementDetailsProps) => {
		getAccountStatementDetailsMutate({ accountId, clientCode });
	};

	return {
		fetchAccountStatementDetails,
		fetchAccountDetailsLoading,
	};
};
