import { Modal } from 'components/Modal';
import { UserSettingsMfaContainer } from './styles';
import UserSettingsMfaForm from './UserSettingsMfaForm';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';

interface IUserSettingsMfaProps {
	isOpen: boolean;
	onClose: () => void;
}
const UserSettingsMfa = ({ isOpen, onClose }: IUserSettingsMfaProps) => {
	const { generateSecretMfa } = useAuthContext();
	const [totpSecret, updateTotpSecret] = useState<string | null>(null);

	useEffect(() => {
		if (isOpen) {
			generateSecretMfa().then(u => {
				updateTotpSecret(u);
			});
		}
	}, [isOpen]);

	return (
		<Modal
			title="Autenticação de dois fatores"
			isOpen={isOpen}
			onClose={onClose}
			width={520}
			style={{
				padding: '16px 24px',
			}}
		>
			<UserSettingsMfaContainer>
				{typeof totpSecret === 'string' ? (
					<UserSettingsMfaForm
						totpSecret={totpSecret}
						onClose={onClose}
					/>
				) : (
					<Spin style={{ margin: 'auto 0' }} />
				)}
			</UserSettingsMfaContainer>
		</Modal>
	);
};

export default UserSettingsMfa;
