const UploadFileIcon = ({ ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={152}
		height={136}
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#F4F4FF"
				d="M77.845.297 72.899 1.57 11.965 17.234 7.02 18.505A9.361 9.361 0 0 0 1.303 22.8a9.31 9.31 0 0 0-1.005 7.064l21.446 82.908a9.339 9.339 0 0 0 4.307 5.699 9.384 9.384 0 0 0 7.086 1.002l.013-.003 70.799-18.202.013-.003a9.36 9.36 0 0 0 5.717-4.293 9.314 9.314 0 0 0 1.005-7.065L89.238 6.998A9.339 9.339 0 0 0 84.93 1.3 9.39 9.39 0 0 0 77.845.297Z"
			/>
			<path
				fill="#fff"
				d="m78.516 2.896-5.858 1.506L13.55 19.6l-5.858 1.506a6.666 6.666 0 0 0-4.07 3.057 6.63 6.63 0 0 0-.716 5.03l21.446 82.909a6.648 6.648 0 0 0 3.067 4.058 6.688 6.688 0 0 0 5.046.714l.013-.004 70.799-18.201.013-.004a6.664 6.664 0 0 0 4.071-3.057 6.628 6.628 0 0 0 .716-5.031L86.63 7.668a6.65 6.65 0 0 0-3.067-4.058 6.686 6.686 0 0 0-5.047-.714Z"
			/>
			<path
				fill="#F4F4FF"
				d="m74.093 29.744-35.815 9.208c-.4.102-.825.043-1.18-.167a1.554 1.554 0 0 1-.55-2.126c.21-.355.552-.612.952-.715l35.816-9.208a1.561 1.561 0 0 1 1.895 1.117 1.548 1.548 0 0 1-1.118 1.89ZM81.628 33.219 39.59 44.026a1.562 1.562 0 0 1-1.897-1.116 1.549 1.549 0 0 1 1.12-1.891L80.85 30.21c.4-.102.824-.043 1.18.167a1.554 1.554 0 0 1 .55 2.126c-.21.355-.552.612-.952.715ZM79.976 52.486 44.16 61.694a1.561 1.561 0 0 1-1.897-1.116 1.548 1.548 0 0 1 .572-1.629c.163-.122.35-.211.548-.262l35.815-9.208a1.561 1.561 0 0 1 1.898 1.116 1.548 1.548 0 0 1-.572 1.628c-.164.123-.35.212-.548.263ZM87.51 55.963 45.473 66.77a1.563 1.563 0 0 1-1.634-.57 1.55 1.55 0 0 1 .856-2.437l42.038-10.808a1.562 1.562 0 0 1 1.897 1.116 1.549 1.549 0 0 1-1.12 1.892ZM85.859 75.23l-35.816 9.208a1.562 1.562 0 0 1-1.896-1.116 1.549 1.549 0 0 1 1.118-1.891l35.816-9.208a1.554 1.554 0 0 1 1.73 2.293c-.21.355-.552.612-.952.714ZM93.394 78.705 51.356 89.512a1.563 1.563 0 0 1-1.635-.57 1.55 1.55 0 0 1 .857-2.437l42.038-10.807a1.562 1.562 0 0 1 1.897 1.116 1.55 1.55 0 0 1-1.12 1.891Z"
			/>
			<path
				fill="#E1E0FF"
				d="M32.163 48.393 19.488 51.65a.75.75 0 0 1-.91-.535L15.67 39.87a.744.744 0 0 1 .537-.907l12.675-3.259a.75.75 0 0 1 .91.535L32.7 47.486a.744.744 0 0 1-.537.907ZM38.046 71.135 25.37 74.394a.75.75 0 0 1-.91-.535l-2.91-11.248a.744.744 0 0 1 .538-.907l12.674-3.259a.75.75 0 0 1 .91.535l2.91 11.248a.744.744 0 0 1-.537.907ZM43.93 93.88l-12.675 3.258a.75.75 0 0 1-.91-.535l-2.91-11.248a.744.744 0 0 1 .537-.907l12.675-3.259a.75.75 0 0 1 .91.536l2.91 11.247a.744.744 0 0 1-.537.907ZM127.241 21.313H54.099a9.379 9.379 0 0 0-6.611 2.734 9.321 9.321 0 0 0-2.743 6.59v85.621a9.32 9.32 0 0 0 2.743 6.59 9.377 9.377 0 0 0 6.61 2.735h73.143a9.378 9.378 0 0 0 6.611-2.735 9.323 9.323 0 0 0 2.743-6.59v-85.62a9.325 9.325 0 0 0-2.743-6.591 9.38 9.38 0 0 0-6.611-2.735Z"
			/>
			<path
				fill="#fff"
				d="M127.241 23.996H54.099a6.68 6.68 0 0 0-4.708 1.947 6.638 6.638 0 0 0-1.953 4.694v85.62a6.638 6.638 0 0 0 1.953 4.693 6.679 6.679 0 0 0 4.708 1.947h73.142a6.68 6.68 0 0 0 4.708-1.947 6.641 6.641 0 0 0 1.953-4.693v-85.62a6.64 6.64 0 0 0-1.953-4.694 6.68 6.68 0 0 0-4.708-1.947Z"
			/>
			<path
				fill="#7664FA"
				d="M134.925 136c9.431 0 17.075-7.621 17.075-17.023 0-9.401-7.644-17.022-17.075-17.022-9.43 0-17.074 7.621-17.074 17.022 0 9.402 7.644 17.023 17.074 17.023Z"
			/>
			<path
				fill="#fff"
				d="M143.092 116.758h-5.939v-5.921a2.227 2.227 0 0 0-3.802-1.569 2.215 2.215 0 0 0-.653 1.569v5.921h-5.939a2.227 2.227 0 0 0-2.227 2.221c0 .588.235 1.153.653 1.57.417.416.984.65 1.574.65h5.939v5.921c0 .589.235 1.153.653 1.57a2.23 2.23 0 0 0 3.149 0c.418-.417.653-.981.653-1.57v-5.921h5.939a2.222 2.222 0 0 0 2.227-2.22c0-.589-.235-1.154-.652-1.57a2.23 2.23 0 0 0-1.575-.651Z"
			/>
			<path
				fill="#E1E0FF"
				d="M116.233 71.045H79.246a1.563 1.563 0 0 1-1.44-.958 1.549 1.549 0 0 1 .843-2.03 1.56 1.56 0 0 1 .597-.118h36.987a1.553 1.553 0 1 1 0 3.106ZM122.659 76.285H79.246a1.563 1.563 0 0 1-1.44-.958 1.549 1.549 0 0 1 .843-2.03c.19-.078.392-.117.597-.117h43.413a1.55 1.55 0 1 1 0 3.106ZM116.233 94.531H79.246a1.563 1.563 0 0 1-1.44-.958 1.549 1.549 0 0 1 .843-2.03c.19-.077.392-.117.597-.117h36.987c.414 0 .81.163 1.102.455a1.55 1.55 0 0 1-1.102 2.65ZM122.659 99.774H79.246a1.563 1.563 0 0 1-1.44-.959 1.549 1.549 0 0 1 .843-2.03 1.56 1.56 0 0 1 .597-.117h43.413a1.558 1.558 0 0 1 1.559 1.553 1.538 1.538 0 0 1-.457 1.099 1.563 1.563 0 0 1-1.102.454ZM70.96 78.665H57.87a.749.749 0 0 1-.746-.744V66.305a.744.744 0 0 1 .747-.745h13.09a.75.75 0 0 1 .746.745v11.616a.744.744 0 0 1-.747.744ZM70.96 102.152H57.87a.747.747 0 0 1-.746-.745V89.792a.744.744 0 0 1 .747-.745h13.09a.749.749 0 0 1 .746.745v11.615a.747.747 0 0 1-.747.745ZM116.274 44.899h-27.27a1.56 1.56 0 0 1-1.1-.455 1.55 1.55 0 0 1 1.1-2.651h27.27c.413 0 .81.164 1.102.455a1.55 1.55 0 0 1-1.102 2.65ZM122.7 50.14H89.005a1.563 1.563 0 0 1-1.44-.958 1.55 1.55 0 0 1 1.44-2.147H122.7c.413 0 .809.164 1.101.455a1.55 1.55 0 0 1-1.101 2.65Z"
			/>
			<path
				fill="#7664FA"
				d="M82.97 56.493H57.83a.749.749 0 0 1-.747-.745v-19.56a.744.744 0 0 1 .747-.745h25.14a.749.749 0 0 1 .747.745v19.56a.744.744 0 0 1-.747.745Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h152v136H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default UploadFileIcon;
