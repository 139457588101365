import React from 'react';

export const PaymentPayerIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.8333 11.8763V13.6175C10.312 13.4333 9.75108 13.333 9.16667 13.333C6.40524 13.333 4.16667 15.5716 4.16667 18.333H2.5C2.5 14.6511 5.48477 11.6663 9.16667 11.6663C9.74217 11.6663 10.3007 11.7393 10.8333 11.8763ZM9.16667 10.833C6.40417 10.833 4.16667 8.59551 4.16667 5.83301C4.16667 3.07051 6.40417 0.833008 9.16667 0.833008C11.9292 0.833008 14.1667 3.07051 14.1667 5.83301C14.1667 8.59551 11.9292 10.833 9.16667 10.833ZM9.16667 9.16634C11.0083 9.16634 12.5 7.67467 12.5 5.83301C12.5 3.99134 11.0083 2.49967 9.16667 2.49967C7.325 2.49967 5.83333 3.99134 5.83333 5.83301C5.83333 7.67467 7.325 9.16634 9.16667 9.16634Z"
			fill={color ?? '#7664FA'}
		/>
		<path
			d="M16.713 15.833H13.1481V17.083H14.7685V18.333H16.0648V17.083H16.713C17.6079 17.083 18.3333 16.3834 18.3333 15.5205C18.3333 14.6576 17.6079 13.958 16.713 13.958H14.1204C13.9414 13.958 13.7963 13.8181 13.7963 13.6455C13.7963 13.4729 13.9414 13.333 14.1204 13.333H17.6852V12.083H16.0648V10.833H14.7685V12.083H14.1204C13.2255 12.083 12.5 12.7826 12.5 13.6455C12.5 14.5084 13.2255 15.208 14.1204 15.208H16.713C16.8919 15.208 17.037 15.3479 17.037 15.5205C17.037 15.6931 16.8919 15.833 16.713 15.833Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
