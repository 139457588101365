import { Address } from './Address';
import { Creator } from './Creator';
import { Phone } from './Phone';
import { Representative } from './Representative';

export enum BusinessType {
	FUND = 'FUND',
	MANAGER = 'MANAGER',
	SECURITIZER = 'SECURITIZER',
	CONSULTANT = 'CONSULTANT',
	CUSTODIAN = 'CUSTODIAN',
	ADMINISTRATOR = 'ADMINISTRATOR',
	OTHERS = 'OTHERS',
}

export enum PersonType {
	LEGAL = 'LEGAL',
	NATURAL = 'NATURAL',
}

export const PersonTypeDescription = {
	[PersonType.LEGAL]: 'Pessoa Jurídica',
	[PersonType.NATURAL]: 'Pessoa Física',
};

export type CompanyDocuments = {
	incorporation_certificate?: string;
	proof_of_address?: string;
	bylaws?: string;
	income_statement?: string;
	last_contract_amendment?: string;
	power_of_attorney?: string;
	incorporation_certificate_url?: string;
	proof_of_address_url?: string;
	bylaws_url?: string;
	income_statement_url?: string;
	last_contract_amendment_url?: string;
	power_of_attorney_url?: string;
	document_identification?: string;
	identification_url?: string;
};

export type BaseCompany = {
	address: Address;
	average_monthly_revenue?: number;
	birth_date?: string;
	business_type: BusinessType;
	cnae?: string;
	corporate_name?: string;
	created_at: string;
	documents: CompanyDocuments;
	email_address: string;
	foundation_date?: string;
	fund_administrator_id?: string;
	fund_manager_id?: string;
	fund_securitizer_id?: string;
	mother_name?: string;
	name: string;
	pep?: boolean;
	person_type: PersonType;
	phone: Phone;
	taxpayer_id: string;
	tenant_type: ETenantType;
};

export type CompanyDto = BaseCompany & {
	representatives: Representative[];
	id?: string;
};

export type Company = CompanyDto & {
	id: string;
	creator: Creator;
};

export type CompanyFund = {
	fund: Company | null;
	manager: Company | null;
	securitizer: Company | null;
	administrator: Company | null;
};

export type CompanyFilters = {
	taxpayer_id?: string;
	business_type?: BusinessType;
	person_type?: PersonType;
	page?: number;
	size?: number;
	order?: 'ASC' | 'DESC';
	sort?: string;
	search?: string;
};

export enum ETenantType {
	DEFAULT = 'default',
	PRIME = 'prime',
}
