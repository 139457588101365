import React, { ReactNode } from 'react';
import { Modal } from 'components/Modal';
import { Button, Col, Row } from 'antd';
import { ButtonSecondary } from 'components/ButtonSecondary';
import { TextS } from 'components/Text';
import { ConfirmationButtons, ConfirmationContent } from './styles';
import theme from 'theme/theme';

interface IConfirmationModalProps {
	cancelText?: string;
	children: ReactNode;
	confirmText?: string;
	danger?: boolean;
	disabledButton?: boolean;
	isLoading?: boolean;
	isOpen: boolean;
	onCancel?: () => void;
	onClose?: () => void;
	onConfirm?: () => void;
	style?: React.CSSProperties;
	title: string | ReactNode;
}

const ConfirmationModal = ({
	cancelText,
	children,
	confirmText,
	danger,
	disabledButton,
	isLoading,
	isOpen,
	onCancel,
	onClose,
	onConfirm,
	style,
	title,
}: IConfirmationModalProps) => {
	let textColor = disabledButton ? theme.primary : theme.white;

	if (danger) {
		textColor = disabledButton ? theme.dangerText : theme.white;
	}

	return (
		<Modal
			isOpen={isOpen}
			width={500}
			title={title}
			style={style}
			onClose={isLoading ? undefined : onClose}
			contentStyle={{
				borderBottomLeftRadius: '15px',
				borderBottomRightRadius: '15px',
			}}
		>
			<ConfirmationContent>{children}</ConfirmationContent>
			<ConfirmationButtons>
				{typeof onConfirm === 'function' && (
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Button
								danger={danger}
								disabled={disabledButton}
								loading={isLoading}
								onClick={onConfirm}
								size="large"
								type="primary"
								style={{
									width: '100%',
									backgroundColor:
										danger && disabledButton
											? theme.dangerBg
											: undefined,
								}}
							>
								<TextS
									color={textColor}
									style={{
										opacity: disabledButton
											? '0.5'
											: undefined,
									}}
								>
									{confirmText}
								</TextS>
							</Button>
						</Col>
					</Row>
				)}
				{typeof onCancel === 'function' && (
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<ButtonSecondary
								onClick={onCancel}
								disabled={isLoading}
								size="large"
								style={{
									width: '100%',
									border: 'none',
									marginTop: '1rem',
								}}
							>
								{cancelText}
							</ButtonSecondary>
						</Col>
					</Row>
				)}
			</ConfirmationButtons>
		</Modal>
	);
};

export default ConfirmationModal;
