import { Col, Descriptions, Row, notification } from 'antd';
import { TextM } from 'components/Text';
import { ButtonFlex, DescriptionItem, DescriptionsWrapper } from './styles';
import { useTheme } from 'styled-components';
import { FaRegCopy } from 'react-icons/fa6';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { Account, AccountStatus } from 'types/Account';
import { PostingCreation } from 'modules/escrow/components/PostingCreation';
import { useContext, useState } from 'react';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { subject } from '@casl/ability';
import { Navigate, useNavigate } from 'react-router-dom';
import { BarCodeIcon } from 'components/Icons/BarCode';
import { PostingIcon } from 'components/Icons/Posting';
import { PaymentCreation } from 'modules/escrow/components/PaymentCreation';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { PaperIcon } from 'components/Icons/Paper';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';
import { contaLivre } from 'modules/escrow/constants/routes';

export interface AccountInfoProps {
	accountContent?: Account;
}

const AccountInfo = ({ accountContent }: AccountInfoProps) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const { type } = useCurrentProfile();
	const ability = useContext(AbilityContext);
	const [isPostingCreationOpen, togglePostingCreationOpen] = useState(false);
	const [isPaymentCreationOpen, togglePaymentCreationOpen] = useState(false);

	const accountRoute = !useIsEscrowAccountRoute() ? contaLivre : 'escrow';
	const account = {
		name: accountContent?.person?.corporate_name
			? accountContent?.person?.corporate_name
			: accountContent?.person?.name || '-',
		bank: '509 - Celcoin Instituição de Pagamento S.A',
		taxpayer_id: accountContent?.person?.taxpayer_id
			? normalizeCpfCnpj(accountContent?.person?.taxpayer_id)
			: '-',
		agency: accountContent?.branch ?? '-',
		account: accountContent?.account ?? '-',
	};

	const descriptionItems = [
		//verificar se vem do contexto
		{
			key: '1',
			label: 'Titular',
			children: <DescriptionItem>{account.name}</DescriptionItem>,
		},
		{
			key: '2',
			label: 'Instituição',
			children: <DescriptionItem>{account.bank}</DescriptionItem>,
		},
		{
			key: '3',
			label: 'Documento',
			children: <DescriptionItem>{account.taxpayer_id}</DescriptionItem>,
		},
		{
			key: '4',
			label: 'Agência',
			children: <DescriptionItem>{account.agency}</DescriptionItem>,
		},
		{
			key: '5',
			label: 'Conta',
			children: (
				<DescriptionItem>
					{normalizeBankAccount(account.account)}
				</DescriptionItem>
			),
		},
	];

	const handleCopy = () => {
		const text = `Nome: ${account.name}\nBanco: ${account.bank}\nDocumento: ${account.taxpayer_id}\nAgência: ${account.agency}\nConta: ${account.account}`;
		navigator.clipboard.writeText(text);
		api.info({
			message: 'Dados copiados',
			description:
				'Os dados da conta foram copiados para a área de transferência',
		});
	};

	const handleNavigateAccountDetails = () => {
		navigate(`/${accountRoute}/${accountContent?.id}/details/info`);
	};

	if (
		ability.cannot(
			'VIEW',
			subject('ACCOUNT', { accountId: accountContent?.id }),
		)
	) {
		return <Navigate to={`/${accountRoute}`} />;
	}

	return (
		<div style={{ margin: '1rem 0' }}>
			{contextHolder}
			<Row gutter={[16, 16]} style={{ margin: '1rem 0' }}>
				<Col>
					<DescriptionsWrapper>
						<Descriptions
							layout="vertical"
							column={5}
							items={descriptionItems}
							contentStyle={{
								margin: '0 0.5rem 0 0',
							}}
						/>
					</DescriptionsWrapper>
				</Col>
			</Row>
			<Row
				gutter={[16, 16]}
				style={{ justifyContent: 'flex-end', marginTop: '30px' }}
			>
				<Col>
					<ButtonFlex
						type="text"
						size="large"
						style={{ gap: '0.3rem', minWidth: '3rem' }}
						onClick={handleCopy}
					>
						<FaRegCopy color={theme.primary} />
						<TextM style={{ color: theme.primary }}>
							Copiar Dados
						</TextM>
					</ButtonFlex>
				</Col>
				<Col>
					<ButtonFlex
						type="text"
						size="large"
						style={{ gap: '0.3rem', minWidth: '3rem' }}
						onClick={handleNavigateAccountDetails}
					>
						<PaperIcon color={theme.primary} />
						<TextM style={{ color: theme.primary }}>
							Detalhes da conta
						</TextM>
					</ButtonFlex>
				</Col>
				{type === AccessType.PERSON &&
					ability.can(
						'CREATE',
						subject('POSTING', { accountId: accountContent?.id }),
					) && (
						<>
							<Col>
								<ButtonFlex
									type="primary"
									size="large"
									style={{ gap: '0.5rem', minWidth: '5rem' }}
									onClick={() => {
										if (
											accountContent?.status !==
											AccountStatus.ACTIVE
										) {
											api.info({
												message: 'Conta não está ativa',
												description:
													'A conta precisa estar ativa para realizar transferências',
											});
											return;
										}
										togglePaymentCreationOpen(true);
									}}
								>
									<TextM style={{ color: theme.white }}>
										Pagar boleto
									</TextM>
									<BarCodeIcon color={theme.white} />
								</ButtonFlex>
							</Col>
							<Col>
								<ButtonFlex
									type="primary"
									size="large"
									style={{ gap: '0.5rem', minWidth: '5rem' }}
									onClick={() => {
										if (
											accountContent?.status !==
											AccountStatus.ACTIVE
										) {
											api.info({
												message: 'Conta não está ativa',
												description:
													'A conta precisa estar ativa para realizar transferências',
											});

											return;
										}
										togglePostingCreationOpen(true);
									}}
								>
									<TextM style={{ color: theme.white }}>
										Transferir
									</TextM>
									<PostingIcon color={theme.white} />
								</ButtonFlex>
							</Col>
						</>
					)}
			</Row>
			{accountContent && (
				<PostingCreation
					isOpen={isPostingCreationOpen}
					account={accountContent}
					onClose={() => togglePostingCreationOpen(false)}
				/>
			)}
			{accountContent && (
				<PaymentCreation
					isOpen={isPaymentCreationOpen}
					account={accountContent}
					onClose={() => togglePaymentCreationOpen(false)}
				/>
			)}
		</div>
	);
};

export default AccountInfo;
