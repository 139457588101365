import { UserIcon, UserOutlinedIcon } from 'components/Icons/User';
import { useTheme } from 'styled-components';

export enum UsersRoutes {
	BASE = '/users',
	USERS_LIST = '/users',
	USERS_PERMISSIONS = '/users/permissions',
	USERS_CREATION = '/users/new',
}

export const UsersIcon: React.FC<{ isActive?: boolean }> = ({ isActive }) => {
	const theme = useTheme();
	if (isActive) return <UserIcon color={theme.primary} />;
	return <UserOutlinedIcon color={theme.text} />;
};
