import {
	CancelButton,
	DateFormIcon,
	ForwardButton,
	PaymentCreationDateAmount,
} from './styles';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { TextM, TextS } from 'components/Text';
import { IoMdArrowForward } from 'react-icons/io';
import { CompanyIcon } from 'components/Icons/Company';
import { useTheme } from 'styled-components';
import { PaymentPersonIcon } from 'components/Icons/PaymentPerson';
import { PaymentPayerIcon } from 'components/Icons/PaymentPayer';
import { BarCodeIcon } from 'components/Icons/BarCode';
import { CalendarIcon } from 'components/Icons/Calendar';
import { formatCurrency, normalizeBoleto } from 'helpers/normalizers';
import { PaymentCalendarIcon } from 'components/Icons/PaymentCalendar';
import { Heading1 } from 'components/Heading1';
import { BarCode } from 'types/Posting';
import { DiscountIcon } from 'components/Icons/Discount';
import { FineIcon } from 'components/Icons/Fine';

interface IPaymentCreationReviewProps {
	paymentNumber: string;
	paymentDate: string;
	paymentData: BarCode;
	paymentAmount: number;
	onNext: () => void;
	onClose: () => void;
}
const PaymentCreationReview = ({
	paymentData,
	paymentDate,
	paymentNumber,
	paymentAmount,
	onNext,
	onClose,
}: IPaymentCreationReviewProps) => {
	const theme = useTheme();

	return (
		<Row>
			<Col span={24}>
				<Heading1 style={{ margin: 0, padding: 0, fontSize: '24px' }}>
					Confirmar operação?
				</Heading1>
				<Row
					style={{
						marginTop: '1rem',
						maxHeight: 'calc(100vh - 25rem)',
						overflow: 'auto',
					}}
				>
					<Col
						span={24}
						style={{
							backgroundColor: theme.primaryOpacity,
							borderRadius: '10px',
							padding: '16px 0px',
						}}
					>
						<Row align="middle" style={{ position: 'relative' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<CompanyIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Instituição Financeira
								</TextS>
								<TextM color={theme.text}>
									{paymentData.assignor}
								</TextM>
							</Col>
							<PaymentCreationDateAmount>
								{formatCurrency(paymentAmount)}
							</PaymentCreationDateAmount>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<PaymentPersonIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Nome do Beneficiário
								</TextS>
								<TextM>
									{paymentData.register_data.recipient}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<PaymentPayerIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Nome do Pagador
								</TextS>
								<TextM>{paymentData.register_data.payer}</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<BarCodeIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Código de barras
								</TextS>
								<TextM>{normalizeBoleto(paymentNumber)}</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<CalendarIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Vencimento
								</TextS>
								<TextM>
									{dayjs(
										paymentData.due_date ??
											paymentData.register_data
												.due_date_register,
									).format('DD/MM/YYYY')}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<PaymentCalendarIcon
										color={theme.primary}
									/>
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Pagamento
								</TextS>
								<TextM>
									{dayjs(paymentDate, 'YYYY-MM-DD').format(
										'DD/MM/YYYY',
									)}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<DiscountIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Descontos / Abatimentos
								</TextS>
								<TextM>
									{formatCurrency(
										paymentData.register_data
											.total_with_discount,
									)}
								</TextM>
							</Col>
						</Row>
						<Row align="middle" style={{ marginTop: '1rem' }}>
							<Col span={3} style={{ textAlign: 'center' }}>
								<DateFormIcon>
									<FineIcon color={theme.primary} />
								</DateFormIcon>
							</Col>
							<Col span={21}>
								<TextS color={theme.textGray} weight={400}>
									Juros / Multas
								</TextS>
								<TextM>
									{formatCurrency(
										paymentData.register_data
											.total_with_additional,
									)}
								</TextM>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
					<Col span={24}>
						<ForwardButton type="primary" onClick={onNext}>
							<TextM>Confirmar solicitação de pagamento</TextM>
							<IoMdArrowForward size={22} />
						</ForwardButton>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<CancelButton type="default" onClick={onClose}>
							<TextM>Cancelar</TextM>
						</CancelButton>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default PaymentCreationReview;
