import React, { useEffect } from 'react';
import { LoginContainer } from './styles';
import LoginForm from './LoginForm';
import { useAuthLogo } from 'modules/core/context/AppContext';
import { Heading2 } from 'components/Heading2';

const LoginPage = () => {
	const { showAuthLogo } = useAuthLogo();

	useEffect(() => showAuthLogo(), []);

	return (
		<LoginContainer>
			<Heading2>Acessar sua conta</Heading2>
			<LoginForm />
		</LoginContainer>
	);
};

export default LoginPage;
