import { useState } from 'react';
import { Col, Form, Input, Popover, Row } from 'antd';
import { Modal } from 'components/Modal';
import {
	UserSettingsPasswordCancelButton,
	UserSettingsPasswordConfirmButton,
	UserSettingsPasswordForm,
	UserSettingsPasswordFormItem,
} from './styles';
import { PasswordStrength } from 'components/PasswordStrength';
import { TextM } from 'components/Text';
import { useAuthContext } from 'modules/core/context/AuthContext';

interface IUserSettingsPasswordProps {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
}
const UserSettingsPassword = ({
	isOpen,
	onClose,
	onSuccess,
}: IUserSettingsPasswordProps) => {
	const [form] = Form.useForm();
	const [password, updatePassword] = useState('');
	const [formValid, setFormValid] = useState(false);
	const [isInvalid, updateInvalid] = useState(false);
	const { redefinePassword, isLoading } = useAuthContext();

	return (
		<Modal
			title="Alterar senha"
			width={480}
			isOpen={isOpen}
			onClose={onClose}
		>
			<UserSettingsPasswordForm
				form={form}
				style={{ width: '100%' }}
				layout="vertical"
				requiredMark={false}
				onFieldsChange={(fields, allFields) => {
					const errors = allFields.filter(
						field =>
							!field.touched ||
							(field.errors && field.errors.length > 0),
					);
					if (fields.find(field => field.name[0] === 'password')) {
						form.setFields([
							{
								name: 'password',
								errors: [],
							},
						]);
					}
					setFormValid(errors.length === 0);
				}}
				onValuesChange={value => {
					if (typeof value.newPassword === 'string') {
						updatePassword(value.newPassword);
					}
				}}
				onFinish={values => {
					redefinePassword(values.password, values.newPassword).then(
						r => {
							if (r) {
								form.resetFields();
								onSuccess();
							} else {
								form.setFields([
									{
										name: 'password',
										errors: ['Senha incorreta'],
									},
								]);
							}
						},
					);
				}}
			>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<UserSettingsPasswordFormItem
							name="password"
							label="Senha atual"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input type="password" placeholder="Digite aqui" />
						</UserSettingsPasswordFormItem>
					</Col>
				</Row>

				<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
					<Col span={24}>
						<Popover
							content={
								<PasswordStrength
									password={password}
									onChangePasswordStrength={strength =>
										updateInvalid(strength < 3)
									}
								/>
							}
							placement="right"
							trigger="focus"
						>
							<UserSettingsPasswordFormItem
								name="newPassword"
								label="Nova senha"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
									() => ({
										validator() {
											if (!isInvalid) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error(
													'Senha fraca, tente uma senha mais forte',
												),
											);
										},
									}),
								]}
							>
								<Input
									type="password"
									placeholder="Digite aqui"
								/>
							</UserSettingsPasswordFormItem>
						</Popover>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
					<Col span={24}>
						<UserSettingsPasswordFormItem
							name="confirmNewPassword"
							label="Confirmar senha"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (
											!value ||
											getFieldValue('newPassword') ===
												value
										) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												'Senhas não conferem, tente novamente',
											),
										);
									},
								}),
							]}
						>
							<Input type="password" placeholder="Digite aqui" />
						</UserSettingsPasswordFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ marginTop: 'auto' }}>
					<Col span={24}>
						<UserSettingsPasswordConfirmButton
							type="primary"
							htmlType="submit"
							disabled={!formValid}
							loading={isLoading}
						>
							<TextM>Alterar minha senha</TextM>
						</UserSettingsPasswordConfirmButton>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<UserSettingsPasswordCancelButton
							type="default"
							onClick={onClose}
							disabled={isLoading}
						>
							<TextM>Cancelar</TextM>
						</UserSettingsPasswordCancelButton>
					</Col>
				</Row>
			</UserSettingsPasswordForm>
		</Modal>
	);
};

export default UserSettingsPassword;
