import { TextL } from 'components/Text';
import { ActionBar } from './styles';
import { useTheme } from 'styled-components';
import { Row, Col, Select } from 'antd';
import {
	ChargeAction,
	ChargeActionDescription,
} from 'modules/debitCollections/constants/actions';
import { WalletCharge } from 'types/Wallet';

interface IChargesActionBarProps {
	selectedBarCodes: WalletCharge[];
	onTriggerAction: (action: ChargeAction) => void;
}
const ChargesActionBar = ({ selectedBarCodes }: IChargesActionBarProps) => {
	const theme = useTheme();

	return (
		<ActionBar>
			<Row
				gutter={[16, 16]}
				style={{ width: '100%' }}
				justify="end"
				align="middle"
			>
				<Col span={12} style={{ textAlign: 'right' }}>
					<TextL weight={500}>
						<span
							style={{ fontWeight: 'bold', color: theme.primary }}
						>
							{String(selectedBarCodes.length).padStart(2, '0')}
						</span>{' '}
						Cobranças selecionadas
					</TextL>
				</Col>
				<Col span={12}>
					<Select
						style={{ width: '100%' }}
						placeholder="Selecione uma ação para a cobrança"
						options={Object.keys(ChargeActionDescription).map(
							key => ({
								label: ChargeActionDescription[
									key as ChargeAction
								],
								value: key,
							}),
						)}
					/>
				</Col>
			</Row>
		</ActionBar>
	);
};

export default ChargesActionBar;
