import { Col, Row } from 'antd';
import { Heading2 } from 'components/Heading2';
import { TextM, TextS, TextSS } from 'components/Text';
import { BarCodeIcon } from 'components/Icons/BarCode';
import { useTheme } from 'styled-components';
import { CancelButton, DestinationTable, ForwardButton } from '../styles';
import {
	ChargeReceiver,
	WalletContent,
	WalletDiscountModality,
} from 'types/Wallet';
import { InfoType, PayerType } from '../types';
import {
	formatCurrency,
	formatToFixed,
	normalizeBankAccount,
	normalizeCep,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { ColumnsType } from 'antd/es/table';
import { BankListDescription } from 'constants/banksList';
import { BankListCode } from 'constants/bankListCode';
import { useEffect, useState } from 'react';
import { Destination } from 'types/Destination';
import { Account } from 'types/Account';
import { CELCOIN_BANK_ISPB } from 'constants/company';

interface IChargesNewPreviewProps {
	info: InfoType;
	payer: PayerType;
	wallet: WalletContent;
	isCreating: boolean;
	chargeReceivers: ChargeReceiver[];
	walletAccount: Account | undefined;
	onBack: () => void;
	onNext: () => void;
}

export type ColumnsData = {
	accountDestination: Destination;
	share: number;
};

const ChargesNewPreview = ({
	info,
	payer,
	wallet,
	walletAccount,
	isCreating,
	chargeReceivers,
	onBack,
	onNext,
}: IChargesNewPreviewProps) => {
	const theme = useTheme();

	const [beneficiaries, setBeneficiaries] = useState<ColumnsData[]>([]);

	useEffect(() => {
		if (walletAccount) {
			const formattedBeneficiaries = [];

			// Add wallet account as first beneficiary
			formattedBeneficiaries.push({
				accountDestination: {
					id: walletAccount.id,
					taxpayer_id: walletAccount.person.taxpayer_id,
					name: walletAccount.person.name,
					bank: CELCOIN_BANK_ISPB,
					branch: walletAccount.branch,
					account: walletAccount.account,
					type: 'CACC',
				},
				share:
					chargeReceivers.reduce(
						(acc, receiver) =>
							100 - (acc + receiver.split_percentage),
						0,
					) || 100,
			});

			chargeReceivers.forEach(receiver => {
				const accountDestination = walletAccount.destinations.find(
					destination =>
						destination.id === receiver.account_destination_id,
				);
				if (accountDestination) {
					formattedBeneficiaries.push({
						accountDestination,
						share: receiver.split_percentage,
					});
				}
			});
			setBeneficiaries(formattedBeneficiaries as ColumnsData[]);
		}
	}, [walletAccount, chargeReceivers]);

	const columns: ColumnsType<ColumnsData> = [
		{
			title: 'Conta beneficiária',
			key: 'beneficiaryAccount',
			align: 'left',
			render: (account: ColumnsData) => {
				return (
					<>
						{account.accountDestination.account ===
							walletAccount?.account && (
							<TextS
								style={{
									fontSize: '0.7rem',
									color: theme.primary,
									marginBottom: 5,
								}}
								weight="bold"
							>
								Conta principal
							</TextS>
						)}
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textSecondary,
							}}
							weight="bold"
						>
							{account.accountDestination?.name ?? '-'}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Banco:{' '}
							{
								BankListDescription[
									(account.accountDestination
										?.bank as keyof typeof BankListCode) ??
										''
								]
							}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Ag.: {account.accountDestination?.branch}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Cc.:{' '}
							{normalizeBankAccount(
								account.accountDestination?.account ?? '',
							)}
						</TextS>
					</>
				);
			},
		},
		{
			title: 'CPF/CNPJ',
			align: 'left',
			render: (account: ColumnsData) => (
				<TextS style={{ color: theme.textGray }}>
					{account.accountDestination.account ===
					walletAccount?.account
						? normalizeCpfCnpj(walletAccount.person.taxpayer_id)
						: normalizeCpfCnpj(
								account.accountDestination.taxpayer_id,
							)}
				</TextS>
			),
		},
		{
			title: 'Tipo de transferência',
			key: 'transferType',
			align: 'left',
			render: () => (
				<TextS style={{ color: theme.textGray }}>Percentual</TextS>
			),
		},
		{
			title: 'Valor',
			key: 'share',
			align: 'left',
			render: (account: ColumnsData) => (
				<TextS style={{ color: theme.textGray }}>
					{formatToFixed(account.share, 2)}%
				</TextS>
			),
		},
	];

	return (
		<Row style={{ maxWidth: 1000 }}>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Heading2 style={{ margin: '30px 0px' }}>
							Pré visualização do boleto
						</Heading2>
					</Col>
				</Row>
				<Row>
					<Col
						span={24}
						style={{
							border: `2px solid ${theme.border}`,
							borderRadius: 8,
						}}
					>
						<Row>
							<Col span={8} style={{ padding: '0.8rem 1rem' }}>
								<TextSS weight="normal" color={theme.textGray}>
									Valor
								</TextSS>
								<TextM color={theme.textSecondary}>
									{formatCurrency(info.amount)}
								</TextM>
							</Col>
							<Col
								span={8}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									Desconto
								</TextSS>
								<TextM color={theme.textSecondary}>
									{info.discount_modality ===
									WalletDiscountModality.FIXED
										? formatCurrency(info.discount_value)
										: `${formatCurrency(
												(info.discount_value / 100) *
													(info?.amount
														? info.amount
														: 0),
											)} (${info.discount_value}%)`}
								</TextM>
							</Col>
							<Col
								span={8}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									Data limite do desconto
								</TextSS>
								<TextM color={theme.textSecondary}>
									{info?.discount_date?.format('DD/MM/YYYY')}
								</TextM>
							</Col>
						</Row>
						<Row style={{ borderTop: `2px solid ${theme.border}` }}>
							<Col span={6} style={{ padding: '0.8rem 1rem' }}>
								<TextSS weight="normal" color={theme.textGray}>
									Vencimento
								</TextSS>
								<TextM color={theme.textSecondary}>
									{info?.due_date?.format('DD/MM/YYYY')}
								</TextM>
							</Col>
							<Col
								span={6}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									Data limite de pagamento
								</TextSS>
								<TextM color={theme.textSecondary}>
									{info?.due_date_limit?.format('DD/MM/YYYY')}
								</TextM>
							</Col>
							<Col
								span={6}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									Multa
								</TextSS>
								<TextM color={theme.textSecondary}>
									{`${info.fine}%`}
								</TextM>
							</Col>
							<Col
								span={6}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									Juros ao Mês
								</TextSS>
								<TextM color={theme.textSecondary}>
									{`${info.interest}%`}
								</TextM>
							</Col>
						</Row>
						<Row style={{ borderTop: `2px solid ${theme.border}` }}>
							<Col span={19} style={{ padding: '0.8rem 1rem' }}>
								<TextSS weight="normal" color={theme.textGray}>
									Pagador
								</TextSS>
								<TextM color={theme.textSecondary}>
									{`${payer.name}`}
								</TextM>
							</Col>
							<Col
								span={5}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									CPF/CNPJ
								</TextSS>
								<TextM color={theme.textSecondary}>
									{normalizeCpfCnpj(payer.taxpayer_id)}
								</TextM>
							</Col>
						</Row>
						<Row style={{ borderTop: `2px solid ${theme.border}` }}>
							<Col span={16} style={{ padding: '0.8rem 1rem' }}>
								<TextSS weight="normal" color={theme.textGray}>
									Endereço
								</TextSS>
								<TextM color={theme.textSecondary}>
									{`${payer.street}, ${payer.number}, ${
										payer.district
									}, CEP ${normalizeCep(payer.postal_code)}`}
								</TextM>
							</Col>
							<Col
								span={8}
								style={{
									borderLeft: `2px solid ${theme.border}`,
									padding: '0.8rem 1rem',
								}}
							>
								<TextSS weight="normal" color={theme.textGray}>
									Cidade
								</TextSS>
								<TextM color={theme.textSecondary}>
									{`${payer.city} - ${payer.state}`}
								</TextM>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Heading2 style={{ marginTop: '30px' }}>
							Contas beneficiárias
						</Heading2>
					</Col>
				</Row>
				<Row style={{ margin: '1rem 0' }}>
					<Col span={24}>
						<DestinationTable
							rowKey={record => record.id}
							pagination={false}
							columns={columns}
							dataSource={beneficiaries}
							rowClassName={(_, idx) => {
								if (idx === 0) {
									return 'main-account';
								}
								return '';
							}}
						/>
					</Col>
				</Row>
				<Row
					gutter={[16, 16]}
					style={{ margin: '3rem 0 1rem' }}
					justify="end"
				>
					<Col>
						<CancelButton onClick={() => onBack()}>
							<TextS>Voltar</TextS>
						</CancelButton>
					</Col>
					<Col>
						<ForwardButton
							type="primary"
							onClick={onNext}
							disabled={isCreating}
							loading={isCreating}
						>
							<TextS>Registrar boleto</TextS>
							<BarCodeIcon color={theme.white} />
						</ForwardButton>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ChargesNewPreview;
