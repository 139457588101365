import styled from 'styled-components';

export const AuthTemplateWrapper = styled.div`
	max-width: 100vw;
	width: 100%;
	max-height: 100vh;
	min-height: 100vh;
	display: flex;
	padding-bottom: 5rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${p => p.theme.background};
`;

export const AuthTemplateLogo = styled.div`
	height: 6rem;
	width: 12rem;
	svg {
		height: 4rem;
		width: 12rem;
	}
`;
