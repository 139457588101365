import { useQuery } from '@tanstack/react-query';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useAuthContext } from './AuthContext';
import { AuthService } from '../services/AuthService';
import {
	ACCESS_COMPANY_ID,
	ACCESS_FINANCIAL_INSTITUTION_ID,
	PROFILE_ACCESS_ID,
} from '../constants/session';
import { AbilityContext } from './AbilityContext';
import { createMongoAbility } from '@casl/ability';
import { useNavigate } from 'react-router-dom';
import { AccessContent, AccessType } from 'types/Access';
import { UserContent } from 'types/User';
import { queryClient } from 'network/query';

interface IProfileContextProps {
	userAccess: AccessContent[];
	isProfileLoading: boolean;
	userProfile?: UserContent;
	isLoadingProfile?: boolean;

	selectedUserAccess: AccessContent | null;
	setSelectedUserAccess: (
		selectedUserAccess: AccessContent | string,
		changeRoute?: boolean,
	) => void;

	resetSelectedUserAccess: () => void;
}

export const ProfileContext = createContext<IProfileContextProps>({
	userAccess: [] as AccessContent[],
	isProfileLoading: false,

	selectedUserAccess: null,
	setSelectedUserAccess: () => {
		throw new Error('Método não implementado');
	},

	resetSelectedUserAccess: () => {
		throw new Error('Método não implementado');
	},
});

interface IProfileProviderProps {
	children?: React.ReactNode;
}
export const ProfileProvider = ({ children }: IProfileProviderProps) => {
	const [selectedUserAccess, updateSelectedUserAccess] =
		useState<AccessContent | null>(null);
	const { isLogged } = useAuthContext();
	const navigate = useNavigate();

	const { data: userAccess, isLoading } = useQuery({
		queryKey: ['userAccess'],
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
		queryFn: () => AuthService.getAccess(),
		enabled: isLogged && !selectedUserAccess,
	});

	const {
		data: userAbility,
		isLoading: isLoadingAbility,
		refetch,
	} = useQuery({
		queryKey: ['userAbility'],
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
		queryFn: () => AuthService.getAbility(),
		enabled: isLogged && selectedUserAccess !== null,
	});

	const { data: userProfile, isLoading: isLoadingProfile } = useQuery({
		queryKey: ['userProfile'],
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
		queryFn: () => AuthService.getProfile(),
		enabled: isLogged && !selectedUserAccess,
	});

	const setSelectedUserAccess = useCallback(
		(access: AccessContent | string, changeRoute?: boolean) => {
			const userAccessSelected =
				typeof access === 'string'
					? userAccess?.content.find(item => item.id === access)
					: access;

			if (!userAccessSelected) throw new Error('Access not found');

			localStorage.setItem(PROFILE_ACCESS_ID, userAccessSelected.id);
			if (userAccessSelected.type === AccessType.PERSON) {
				sessionStorage.setItem(
					ACCESS_COMPANY_ID,
					userAccessSelected.person?.id ?? '',
				);
			} else {
				sessionStorage.removeItem(ACCESS_COMPANY_ID);
			}
			sessionStorage.setItem(
				ACCESS_FINANCIAL_INSTITUTION_ID,
				userAccessSelected.financial_institution.id,
			);

			updateSelectedUserAccess(userAccessSelected);

			if (changeRoute) navigate('/');

			setTimeout(() => {
				queryClient.refetchQueries();
				refetch();
			}, 500);
		},
		[userAccess, refetch, navigate],
	);

	const resetSelectedUserAccess = useCallback(() => {
		updateSelectedUserAccess(null);
		localStorage.removeItem(PROFILE_ACCESS_ID);
		sessionStorage.removeItem(ACCESS_COMPANY_ID);
		sessionStorage.removeItem(ACCESS_FINANCIAL_INSTITUTION_ID);
	}, []);

	useEffect(() => {
		if (
			userAccess &&
			userAccess.content.length > 0 &&
			!selectedUserAccess
		) {
			const storageAccessId = localStorage.getItem(PROFILE_ACCESS_ID);
			if (
				typeof storageAccessId === 'string' &&
				storageAccessId.length > 0
			) {
				const userAccessProfile = userAccess.content.find(
					access => access.id === storageAccessId,
				);
				if (userAccessProfile) {
					if (userAccessProfile.type === AccessType.PERSON) {
						sessionStorage.setItem(
							ACCESS_COMPANY_ID,
							userAccessProfile.person?.id ?? '',
						);
					} else {
						sessionStorage.removeItem(ACCESS_COMPANY_ID);
					}
					sessionStorage.setItem(
						ACCESS_FINANCIAL_INSTITUTION_ID,
						userAccessProfile.financial_institution.id,
					);
					updateSelectedUserAccess(userAccessProfile);
					return;
				}
			}

			if (userAccess.content.length === 1) {
				setSelectedUserAccess(userAccess.content[0]);
			} else {
				navigate('/profile');
			}
		}
	}, [userAccess, setSelectedUserAccess]);

	return (
		<ProfileContext.Provider
			value={{
				userAccess: userAccess?.content || ([] as AccessContent[]),
				isProfileLoading: isLoading || isLoadingAbility,
				selectedUserAccess,
				userProfile,
				isLoadingProfile,
				setSelectedUserAccess,
				resetSelectedUserAccess,
			}}
		>
			<AbilityContext.Provider
				value={createMongoAbility(userAbility?.rules)}
			>
				{children}
			</AbilityContext.Provider>
		</ProfileContext.Provider>
	);
};

export const useCurrentProfile = (): AccessContent => {
	const context = useContext(ProfileContext);

	if (context === undefined) {
		throw new Error(
			'useCurrentProfile must be used within a ProfileProvider',
		);
	}

	if (!context.selectedUserAccess) {
		throw new Error('user must be logged to have access');
	}

	return context.selectedUserAccess;
};

export const useCurrentUser = (): UserContent => {
	const context = useContext(ProfileContext);

	if (context === undefined) {
		throw new Error(
			'useCurrentProfile must be used within a ProfileProvider',
		);
	}

	if (!context.userProfile) {
		throw new Error('user must be logged to have access');
	}

	return context.userProfile;
};
