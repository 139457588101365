import { Button, Form } from 'antd';
import styled from 'styled-components';
import { NewPasswordFormType } from './types';

export const NewPasswordContainer = styled.div`
	width: 100%;
	max-width: 25rem;
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem 1rem;
	align-items: center;
	box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 0.4rem;
	border: 1px solid ${p => p.theme.border};
	background-color: ${p => p.theme.white};
`;

export const NewPasswordFormContainer = styled(Form<NewPasswordFormType>)`
	padding: 1rem 0;
	width: 100%;
	position: relative;
`;

export const NewPasswordFormSubmit = styled(Button)`
	margin: 0 auto;
	width: 100%;
	margin-top: 1rem;
`;
