import { IListFilters } from 'types/List';

export interface IImportedCnab {
	id: string;
	walletId: string;
	status: WalletCnabImportStatus;
	errors: string[];
	amount: number;
	created_at: string;
	creator: string;
}

export interface ICnabImportedFilters extends IListFilters {
	search?: string;
}

export enum WalletCnabImportStatus {
	PROCESSING = 'PROCESSING',
	FAILED = 'FAILED',
	SUCCESS = 'SUCCESS',
}

export enum WalletCnabImportStatusLabel {
	PROCESSING = 'Processando',
	FAILED = 'Erro',
	SUCCESS = 'Sucesso',
}

export interface ICnabDownloadResponse {
	url: string;
}
