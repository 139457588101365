import React from 'react';

export const UserOutlinedIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 15.2402H6V20.2402H18V4.24023H6V9.24023H4V3.24023C4 2.68795 4.44772 2.24023 5 2.24023H19C19.5523 2.24023 20 2.68795 20 3.24023V21.2402C20 21.7925 19.5523 22.2402 19 22.2402H5C4.44772 22.2402 4 21.7925 4 21.2402V15.2402ZM10 11.2402V8.24023L15 12.2402L10 16.2402V13.2402H2V11.2402H10Z"
			fill={color ?? '#4B4662'}
		/>
	</svg>
);

export const UserIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 15.2402H6V20.2402H18V4.24023H6V9.24023H4V3.24023C4 2.68795 4.44772 2.24023 5 2.24023H19C19.5523 2.24023 20 2.68795 20 3.24023V21.2402C20 21.7925 19.5523 22.2402 19 22.2402H5C4.44772 22.2402 4 21.7925 4 21.2402V15.2402ZM10 11.2402V8.24023L15 12.2402L10 16.2402V13.2402H2V11.2402H10Z"
			fill={color ?? '#4B4662'}
		/>
	</svg>
);
