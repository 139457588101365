import { Form, Select as AntSelect, Col, Button } from 'antd';
import styled from 'styled-components';
import { EscrowApprovalsFiltersType } from './types';

export const FiltersTitle = styled.div`
	display: flex;
	p {
		padding-left: 0.3rem;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.border};
	padding: 0 0 1rem;

	svg {
		color: ${({ theme }) => theme.textSecondary};
	}
`;

export const FiltersForm = styled(Form<EscrowApprovalsFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const EscrowApprovalsFiltersWrapper = styled.div``;

export const Select = styled(AntSelect)`
	width: 256px;
	margin-top: 10px;
	max-height: 32px;
`;

export const DropdownWrapper = styled.div`
	& .ant-select-item {
		padding: 0;
		border-bottom: 1px solid ${({ theme }) => theme.border};
		padding: 10px;
	}

	& .ant-select-item:last-child {
		border-bottom: none;
	}
`;

export const FilterCol = styled(Col)`
	align-self: self-end;
`;

export const TableWrapper = styled.div`
	& .selected-row {
		background-color: #f4f4ff;
	}
`;

export const PopoverButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: 0;
	box-shadow: none;
`;

export const BackgroundModalData = styled.div`
	width: 100%;
	background-color: #f4f4ff;
	padding: 0.2rem 1rem;
	border-radius: 1rem;
`;

export const ModalTextArea = styled.textarea`
	width: 100%;
	min-height: 4rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.border};
	resize: none;
`;

export const FiltersFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}
`;
