import { DatePicker, Input } from 'antd';
import styled from 'styled-components';

export const DatePickerFull = styled(DatePicker)`
	width: 100%;
	max-height: 40px;
	position: absolute;
	top: 1px;
	right: 1px;
`;

export const DatePickerInput = styled(Input)`
	max-height: 40px;
`;

export const DatePickerContainer = styled.div`
	display: block;
	max-height: 40px;
	position: relative;
`;
