import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextM } from 'components/Text';
import { formatCurrency } from 'helpers/normalizers';
import { TBalanceInfoModal } from '../types';

interface IManagementModalProps {
	accountId?: string;
	balanceManagementInfo: TBalanceInfoModal;
	closeModal: () => void;
	handleUpdateBalance: (reason: string, balance: TBalanceInfoModal) => void;
}

const ManagementModal = ({
	accountId,
	balanceManagementInfo,
	closeModal,
	handleUpdateBalance,
}: IManagementModalProps) => {
	const [reason, setReason] = useState<string>();

	const isBlocked: boolean = !!balanceManagementInfo.id;
	const formattedAmount = formatCurrency(balanceManagementInfo.amount);
	const title: string = isBlocked
		? `Você tem certeza que deseja remover este bloqueio?`
		: `Você tem certeza que deseja bloquear ${formattedAmount} do saldo da conta ${accountId || ''}?`;
	const subtitle: string = isBlocked
		? 'Motivo da remoção de bloqueio'
		: 'Motivo do bloqueio';

	return (
		<ConfirmationModal
			cancelText="Cancelar"
			confirmText={`${!isBlocked ? 'Bloquear' : 'Desbloquear'} ${formattedAmount}`}
			danger={!isBlocked}
			disabledButton={!reason || !balanceManagementInfo.amount}
			isOpen
			onCancel={closeModal}
			onClose={closeModal}
			onConfirm={() =>
				handleUpdateBalance(reason || '', balanceManagementInfo)
			}
			title={isBlocked ? 'Remover bloqueio' : 'Bloquear saldo'}
		>
			<TextM style={{ marginBottom: '2rem' }}>{title}</TextM>
			<span>{subtitle}</span>
			<TextArea
				maxLength={250}
				onChange={e => setReason(e.target.value)}
				placeholder="Digite aqui"
				rows={5}
				style={{ marginTop: '0.5rem' }}
			/>
		</ConfirmationModal>
	);
};

export default ManagementModal;
