import { ApiError } from 'types/ApiError';
import { PostingsService } from '../services';
import { PostingAction } from 'types/Posting';
import { useMutation } from '@tanstack/react-query';

export interface ReviewPostingProps {
	postingId: string;
	action: PostingAction;
	reason?: string;
}

export interface CancelPostingProps {
	postingId: string;
}

export const usePostings = (options?: {
	onError?: (error: ApiError) => void;
	onCreateSuccess?: () => void;
	onReviewSuccess?: () => void;
}) => {
	const { mutate: reviewPostingMutate, isPending: isUpdating } = useMutation<
		void,
		ApiError,
		ReviewPostingProps
	>({
		mutationFn: variables => {
			return PostingsService.reviewPosting(
				variables.postingId,
				variables.action,
				variables.reason,
			);
		},
		onSuccess: () => {
			if (typeof options?.onReviewSuccess === 'function') {
				options.onReviewSuccess();
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: cancelPostingMutate, isPending: isCanceling } = useMutation<
		void,
		ApiError,
		CancelPostingProps
	>({
		mutationFn: variables => {
			return PostingsService.cancelPosting(variables.postingId);
		},
		onSuccess: () => {
			if (typeof options?.onCreateSuccess === 'function') {
				options.onCreateSuccess();
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const reviewPosting = ({
		action,
		postingId,
		reason,
	}: ReviewPostingProps) => {
		reviewPostingMutate({ action, postingId, reason });
	};

	const cancelPosting = ({ postingId }: CancelPostingProps) => {
		cancelPostingMutate({ postingId });
	};

	return {
		cancelPosting,
		reviewPosting,
		isUpdating,
		isCanceling,
	};
};
