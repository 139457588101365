import { Button, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';

export type FilterButtonVariation = 'primary' | 'secondary';

export interface ButtonFlexProps extends ButtonProps {
	variation?: FilterButtonVariation;
}

export const ButtonFlex = styled(Button)<ButtonFlexProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border-radius: 0.5rem;
	border: transparent;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	${({ variation }) =>
		variation === 'secondary' &&
		css`
			border: 1px solid #5e50c8;
		`}

	svg {
		color: inherit;
	}
	&:hover {
		svg {
			color: ${({ theme, variation }) =>
				variation === 'secondary' ? theme.primary : 'inhrit'};
		}
	}
`;

export const IconContainer = styled.div<ButtonFlexProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ variation }) =>
		variation === 'secondary' ? '#EAEAEA' : '#5e50c8'};
	height: 100%;
	padding: 0.5rem;
	border-radius: 0.5rem 0 0 0.5rem;

	svg {
		color: ${({ theme, variation }) =>
			variation === 'secondary' ? theme.primary : theme.white} !important;
	}
`;

export const ChildContainer = styled.div<ButtonFlexProps>`
	height: 100%;
	min-width: 8rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -0.5rem;
	color: ${({ theme, variation }) =>
		variation === 'secondary' ? theme.primary : theme.white} !important;
	background-color: ${({ theme, variation }) =>
		variation === 'secondary' ? theme.white : theme.primary} !important;
	border-radius: 0 0.5rem 0.5rem 0;
	padding: 0.5rem;

	p {
		${({ theme, variation }) =>
			variation === 'secondary' && `color: ${theme.primary} !important;`};
	}
`;
