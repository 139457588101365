import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 1rem;
`;

export const Footer = styled.div`
	display: flex;
	margin: 1rem 0;
	justify-content: flex-end;
`;

export const Spinner = styled.span`
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	animation: spin 1s linear infinite;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	color: ${p => p.theme.primary};
`;

export const StyledInput = styled.div`
	margin-top: 10px;
`;
