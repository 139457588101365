import { Modal } from 'components/Modal';
import { TextM } from 'components/Text';
import { Container } from './styles';
import { useTheme } from 'styled-components';
import { AccountTypeButton } from './PersonTypeButton';
import { CompanyIcon } from 'components/Icons/Company';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PersonIcon } from 'components/Icons/Person';
import { CompanyRoutes } from 'modules/company/constants/routes';

export interface SelectPersonTypeModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export enum PersonType {
	PJ = 'PJ',
	PF = 'PF',
}

const SelectPersonTypeModal = ({
	isOpen,
	onClose,
}: SelectPersonTypeModalProps) => {
	const theme = useTheme();
	const [selectedPersonType, updateSelectedPersonType] =
		useState<PersonType | null>(null);
	const navigate = useNavigate();

	const handleClose = () => {
		updateSelectedPersonType(null);
		onClose();
	};

	const handleRegister = () => {
		const registerRouters = {
			[PersonType.PJ]: CompanyRoutes.NEW,
			[PersonType.PF]: CompanyRoutes.NEW_NATURAL_PERSON,
		};
		navigate(registerRouters[selectedPersonType!]);
	};

	return (
		<Modal
			title="Selecione o Tipo de Cliente"
			isOpen={isOpen}
			onClose={handleClose}
			width={500}
		>
			<Container>
				<TextM color={theme.text}>
					Selecione o Tipo de Cliente que você deseja cadastrar
				</TextM>

				<AccountTypeButton
					icon={<CompanyIcon color={theme.primary} />}
					selected={selectedPersonType === PersonType.PJ}
					buttonText="Empresa (PJ)"
					onClick={() => updateSelectedPersonType(PersonType.PJ)}
				/>

				<AccountTypeButton
					icon={<PersonIcon color={theme.primary} />}
					selected={selectedPersonType === PersonType.PF}
					buttonText="Pessoa Física (PF)"
					onClick={() => updateSelectedPersonType(PersonType.PF)}
				/>

				<div style={{ marginTop: '20px' }}>
					<ButtonPrimary
						onClick={handleRegister}
						style={{ justifyContent: 'center', padding: '20px 0' }}
						disabled={!selectedPersonType}
					>
						<TextM color={theme.white}>Cadastrar</TextM>
					</ButtonPrimary>

					<ButtonPrimary
						variant="secondary"
						onClick={handleClose}
						style={{
							justifyContent: 'center',
							padding: '20px 0',
							outline: 'none',
							border: 'none',
						}}
					>
						<TextM color={theme.primary}>Cancelar</TextM>
					</ButtonPrimary>
				</div>
			</Container>
		</Modal>
	);
};

export default SelectPersonTypeModal;
