import { Outlet, useNavigate } from 'react-router-dom';

import { Wrapper } from './styles';
import { useEffect } from 'react';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';

export function ManagementTemplate() {
	const { type } = useCurrentProfile();
	const navigate = useNavigate();

	useEffect(() => {
		if (type && type !== AccessType.FINANCIAL_INSTITUTION) {
			navigate('/');
		}
	}, [type, navigate]);

	return (
		<Wrapper>
			<Outlet />
		</Wrapper>
	);
}
