export type List<T> = {
	content: T[];
	has_next: boolean;
	total_elements: number;
	total_pages: number;
};

export type PaginationInfo = {
	currentPage: number;
	pageSize: number;
};

export interface ISortingPage {
	order: ESortingPage;
	sort: string;
}

export enum ESortingPage {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface IListFilters {
	page?: number;
	size?: number;
	order?: ESortingPage;
	sort?: string;
}
