import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { StyleConstants } from 'theme/StyleConstants';
import { Button } from 'antd';

export const SideBarHeader = styled.div`
	height: 6.82rem;
	width: ${StyleConstants.SIDE_BAR_WIDTH};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 2rem 2rem;
	transition: padding 0.15s ease-in-out;
`;

export const SideBarMenu = styled.div`
	padding: 0 2rem;
	position: relative;
`;

// ** MENU LIST ** //

export const SideBarMenuGroup = styled.div``;

export const SideBarMenuList = styled.ul`
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin: 0;
	list-style: none;
`;

export const SideBarMenuListItem = styled.li<{
	isActive?: boolean;
	isOpen?: boolean;
	isGroup?: boolean;
}>`
	margin: 0;
	padding: 0;
	margin-left: -10px;
	margin-right: -10px;
	padding: 0px 10px;
	font-size: 1rem;
	position: relative;

	${({ isOpen }) =>
		isOpen
			? css`
					${SideBarMenuListItemAction} {
						&::after {
							transform: rotate(-180deg);
						}
					}
				`
			: ``}

	${({ isActive }) =>
		isActive
			? css`
					${SideBarMenuListItemLink} {
						color: ${p => p.theme.primary} !important;
						margin: 0 -1rem;
						border-radius: 0.5rem;
						background-color: ${p =>
							isActive ? p.theme.primaryOpacity : 'transparent'};
					}

					${SideBarMenuListItemAction} {
						color: ${p => p.theme.primary};
						margin: 0 -1rem;
						border-radius: 0.5rem;
						background-color: ${p =>
							isActive ? p.theme.primaryOpacity : 'transparent'};
						&::after {
							color: ${p => p.theme.primary};
						}
					}

					${SideBarMenuListItemIcon} {
						svg {
							color: ${p => p.theme.primary};
						}
					}
				`
			: ``}
`;

export const SideBarMenuListItemLink = styled(RouterLink)`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	height: 3.375rem;
	font-size: 1.13rem;
	font-weight: 500;
	margin: 0 -1rem;
	white-space: nowrap;
	text-decoration: none;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		padding-left 0.2s ease-in-out;
	color: ${p => p.theme.text};

	&:visited {
		color: ${p => p.theme.text};
	}

	&:hover {
		padding-left: 1.3rem !important;
	}

	&:focus {
		outline: none;
	}

	&:active {
		color: inherit;
	}
`;

export const SideBarMenuListItemIcon = styled.span`
	display: block;
	margin-right: 0.7rem;
	margin-bottom: 1px;
	svg {
		color: ${p => p.theme.text};
	}
`;

// ** SUB MENU LIST ** //

export const SideBarSubMenuList = styled.ul<{ isOpen?: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: ${p => (p.isOpen ? '0.2rem 0.7rem' : '0 0.7rem')};
	margin: 0;
	list-style: none;
	max-height: ${p => (p.isOpen ? '400px' : '0px')};
	overflow: hidden;
	transition:
		max-height 0.2s ease-in-out,
		padding 0.2s ease-in-out;
`;

export const SideBarSubMenuListItem = styled.li<{
	isActive?: boolean;
	isGroup?: boolean;
}>`
	margin: 0;
	padding: 0;
	font-size: 0.875rem;
	position: relative;
	padding-left: 1.3rem;
	border-left: 2px solid ${p => p.theme.border};

	${({ isActive }) =>
		isActive
			? css`
					${SideBarSubMenuListItemLink} {
						color: ${p => p.theme.primary} !important;
					}
				`
			: ``};
`;

export const SideBarSubMenuListItemLink = styled(RouterLink)`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.2rem 1rem;
	margin: 0 -1rem;
	white-space: nowrap;
	text-decoration: none;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		padding-left 0.2s ease-in-out;
	color: ${p => p.theme.text};

	&:visited {
		color: inherit;
	}

	&:hover {
		padding-left: 1.3rem !important;
	}

	&:focus {
		outline: none;
	}

	&:active {
		color: inherit;
	}
`;

export const SideBarMenuListItemAction = styled.a`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	margin: 0 -1rem;
	white-space: nowrap;
	text-decoration: none;
	font-size: 1rem;
	cursor: pointer;
	height: 3.375rem;
	font-size: 1.13rem;
	font-weight: 500;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		padding-left 0.2s ease-in-out;
	color: ${p => p.theme.text};

	&::after {
		position: absolute;
		right: 1.4rem;
		z-index: -1;
		content: '\f2f9';
		font: normal normal normal 1.125rem/1 'Material-Design-Iconic-Font';
		text-rendering: auto;
		margin-left: auto;
		transform: rotate(0deg);
		transition: all 0.2s ease;
		font-size: 2rem;
		color: ${p => p.theme.text};
		opacity: 0.6;
	}

	&:visited {
		color: inherit;
	}
	&:hover {
		padding-left: 1.3rem !important;
	}

	&:focus {
		outline: none;
	}

	&:active {
		color: inherit;
	}
`;

export const SideBarLogoImage = styled.div<{ isCollapsed: boolean }>`
	overflow: hidden;
	transition: width 0.15s ease-in-out;
`;

export const SideBarLogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: ${StyleConstants.SIDE_BAR_WIDTH};
`;

export const SideBarCollapseButton = styled(Button)`
	border: none;
	padding: 0px;
	box-shadow: unset;
	margin-bottom: 0.4rem;
	color: ${p => p.theme.textSecondary};
	svg {
		width: 1.4rem;
		height: 1.4rem;
	}
`;

export const SideBarWrapper = styled.div<{ isCollapsed: boolean }>`
	width: ${p =>
		p.isCollapsed
			? StyleConstants.SIDE_BAR_WIDTH_COLLAPSED
			: StyleConstants.SIDE_BAR_WIDTH};
	overflow: hidden;
	transition: width 0.15s ease-in-out;

	${SideBarLogoImage} {
		width: ${p => (p.isCollapsed ? '50px' : '180px')};
	}

	${SideBarHeader} {
		padding: ${p => (p.isCollapsed ? '2rem 1.4rem' : '2rem 2rem')};
	}

	${p =>
		p.isCollapsed &&
		css`
			${SideBarMenuListItemLink} {
				font-size: 0;
			}

			${SideBarMenuListItemAction} {
				font-size: 0;
				&::after {
					display: none;
				}
			}

			&:hover {
				width: ${StyleConstants.SIDE_BAR_WIDTH};

				${SideBarLogoImage} {
					width: 190px;
				}

				${SideBarHeader} {
					padding: 2rem 2rem;
				}

				${SideBarMenuListItemLink} {
					font-size: 1.13rem;
				}

				${SideBarMenuListItemAction} {
					font-size: 1.13rem;
					&::after {
						display: block;
					}
				}
			}
		`}
`;
