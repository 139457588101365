import { Button } from 'antd';
import styled from 'styled-components';

export const ProfileContainer = styled.div`
	width: 100%;
	max-width: 25rem;
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem 1rem;
	align-items: center;
	box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 0.4rem;
	border: 1px solid ${p => p.theme.border};
	background-color: ${p => p.theme.white};
`;

export const ProfileOption = styled(Button)`
	border: 1px dashed ${({ theme }) => theme.primary};
	background-color: ${({ theme }) => theme.backgroundVariant};
	font-weight: 500;
	width: 100%;
	height: 3rem;
	margin-top: 1rem;

	&:last-of-type {
		margin-bottom: 1rem;
	}
`;
