import styled from 'styled-components';

export const PageTitle = styled.h2`
	color: ${p => p.theme.text};
	font-size: 1.2rem;
	font-weight: bold;
	margin: 1rem 0 0.6rem;

	&:first-of-type {
		margin-top: 0.6rem;
	}
`;
