import { Col, Row } from 'antd/lib';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextS } from 'components/Text';
import Confirmation from 'components/Icons/Confirmation';

interface ISuccessModalProps {
	title: string;
	message: string;
	closeModal: () => void;
}

export default function SuccessModal({
	title,
	message,
	closeModal,
}: ISuccessModalProps) {
	return (
		<ConfirmationModal
			confirmText="Ok, obrigado"
			isOpen
			onClose={closeModal}
			onConfirm={closeModal}
			title={title}
		>
			<Row
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Col span={3}>
					<Confirmation />
				</Col>
				<Col span={21}>
					<TextS>{message}</TextS>
				</Col>
			</Row>
		</ConfirmationModal>
	);
}
