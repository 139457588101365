import { TextS } from 'components/Text';
import { EscrowCreationStepsContainer } from './styles';
import { Divider } from 'antd';
import { useTheme } from 'styled-components';
import { FaCheck } from 'react-icons/fa6';

export type EscrowStepsItem = {
	title: string;
	completed: boolean;
	action?: () => void;
};

export interface EscrowStepsProps {
	steps: EscrowStepsItem[];
	selectedStep: number;
}

export interface CheckmarkProps {
	completed?: boolean;
	checked: boolean;
	title: string;
	action?: () => void;
}

export const Checkmark = ({
	checked,
	title,
	completed,
	action,
}: CheckmarkProps) => {
	const theme = useTheme();
	return (
		<div
			onClick={action}
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.5rem',
				cursor: typeof action === 'function' ? 'pointer' : 'default',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '16px',
					height: '16px',
					borderRadius: '50%',
					backgroundColor: `${completed ? theme.primary : theme.white}`,
					border: `1px solid ${checked ? theme.primary : 'transparent'}`,
				}}
			>
				{completed && (
					<FaCheck style={{ color: theme.white, width: '10px' }} />
				)}
			</div>

			<TextS style={{ color: checked ? theme.primary : theme.textGray }}>
				{title}
			</TextS>
		</div>
	);
};

const EscrowSteps = ({ steps, selectedStep }: EscrowStepsProps) => {
	return (
		<EscrowCreationStepsContainer>
			<div
				style={{
					padding: '0.5rem 0',
				}}
			>
				<TextS style={{ color: '#3F4254' }}>Etapas</TextS>
			</div>
			<Divider style={{ margin: 0 }} />
			<div
				style={{
					marginTop: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
				}}
			>
				{steps.map((step, index) => (
					<Checkmark
						key={index}
						checked={index === selectedStep - 1}
						title={step.title}
						action={step.action}
						completed={step.completed}
					/>
				))}
			</div>
		</EscrowCreationStepsContainer>
	);
};

export default EscrowSteps;
