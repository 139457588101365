import {
	PlaceholderDarkInput,
	DateFormIcon,
	ForwardButton,
	PaymentCreationDateAmount,
	PaymentCreationDateCollapse,
	PaymentCreationDateForm as PaymentCreationDateFormStyled,
	PaymentCreationDateInfo,
} from './styles';
import { Col, Form, Row, notification } from 'antd';
import { DatePicker } from 'components/DatePicker';
import dayjs from 'dayjs';
import {
	betweenRangeValue,
	holidaysRule,
	minTodayDateRule,
	weekendRule,
} from 'helpers/rules';
import { TextM, TextS } from 'components/Text';
import { IoMdArrowForward } from 'react-icons/io';
import { CompanyIcon } from 'components/Icons/Company';
import { useTheme } from 'styled-components';
import { PaymentPersonIcon } from 'components/Icons/PaymentPerson';
import { PaymentPayerIcon } from 'components/Icons/PaymentPayer';
import { BarCodeIcon } from 'components/Icons/BarCode';
import { CalendarIcon } from 'components/Icons/Calendar';
import { formatCurrency, normalizeBoleto } from 'helpers/normalizers';
import { BarCode } from 'types/Posting';
import { CurrencyInput } from 'components/CurrencyInput';
import { useEffect } from 'react';

interface IPaymentCreationDateFormProps {
	paymentNumber: string;
	paymentData: BarCode;
	onNext: (paymentDate: string, paymentAmount: number) => void;
}
const PaymentCreationDateForm = ({
	paymentData,
	paymentNumber,
	onNext,
}: IPaymentCreationDateFormProps) => {
	const [form] = Form.useForm();
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();

	useEffect(() => {
		form.setFieldValue('amount', paymentData.register_data.total_updated);
	}, []);

	return (
		<>
			{contextHolder}
			<PaymentCreationDateFormStyled
				form={form}
				style={{
					width: '100%',
					maxHeight: 'calc(100vh - 20rem)',
					overflow: 'auto',
				}}
				layout="vertical"
				requiredMark={false}
				validateTrigger="onBlur"
				onFinish={values => {
					const paymentDate = values.payment_date ?? dayjs();
					const amount =
						typeof values.amount === 'number'
							? values.amount
							: paymentData.register_data.total_updated;
					const paymentAmount =
						paymentData.register_data &&
						Object.keys(paymentData.register_data)?.length > 0
							? paymentData.register_data.allow_change_value
								? amount
								: paymentData.register_data.total_updated
							: paymentData.value;

					if (
						typeof paymentAmount !== 'number' ||
						paymentAmount === 0
					) {
						api.error({ message: 'Insira um valor válido' });
						return;
					}
					onNext(paymentDate.format('YYYY-MM-DD'), paymentAmount);
				}}
			>
				<Row>
					<Col span={24}>
						<Row>
							<Col
								span={24}
								style={{
									backgroundColor: theme.primaryOpacity,
									borderRadius: '10px',
									padding: '16px 0px',
								}}
							>
								<Row
									align="middle"
									style={{ position: 'relative' }}
								>
									<Col
										span={3}
										style={{ textAlign: 'center' }}
									>
										<DateFormIcon>
											<CompanyIcon
												color={theme.primary}
											/>
										</DateFormIcon>
									</Col>
									<Col span={21}>
										<TextS
											color={theme.textGray}
											weight={400}
										>
											Instituição Financeira
										</TextS>
										<TextM color={theme.text}>
											{paymentData.assignor}
										</TextM>
									</Col>
									<PaymentCreationDateAmount>
										{paymentData.register_data &&
										Object.keys(paymentData.register_data)
											?.length > 0
											? formatCurrency(
													paymentData.register_data
														.total_updated,
												)
											: formatCurrency(paymentData.value)}
									</PaymentCreationDateAmount>
								</Row>
								<Row
									align="middle"
									style={{ marginTop: '1rem' }}
								>
									<Col
										span={3}
										style={{ textAlign: 'center' }}
									>
										<DateFormIcon>
											<PaymentPersonIcon
												color={theme.primary}
											/>
										</DateFormIcon>
									</Col>
									<Col span={21}>
										<TextS
											color={theme.textGray}
											weight={400}
										>
											Nome do Beneficiário
										</TextS>
										<TextM>
											{
												paymentData.register_data
													.recipient
											}
										</TextM>
									</Col>
								</Row>
								<Row
									align="middle"
									style={{ marginTop: '1rem' }}
								>
									<Col
										span={3}
										style={{ textAlign: 'center' }}
									>
										<DateFormIcon>
											<PaymentPayerIcon
												color={theme.primary}
											/>
										</DateFormIcon>
									</Col>
									<Col span={21}>
										<TextS
											color={theme.textGray}
											weight={400}
										>
											Nome do Pagador
										</TextS>
										<TextM>
											{paymentData.register_data.payer}
										</TextM>
									</Col>
								</Row>
								<Row
									align="middle"
									style={{ marginTop: '1rem' }}
								>
									<Col
										span={3}
										style={{ textAlign: 'center' }}
									>
										<DateFormIcon>
											<BarCodeIcon
												color={theme.primary}
											/>
										</DateFormIcon>
									</Col>
									<Col span={21}>
										<TextS
											color={theme.textGray}
											weight={400}
										>
											Código de barras
										</TextS>
										<TextM>
											{normalizeBoleto(paymentNumber)}
										</TextM>
									</Col>
								</Row>
								<Row
									align="middle"
									style={{ marginTop: '1rem' }}
								>
									<Col
										span={3}
										style={{ textAlign: 'center' }}
									>
										<DateFormIcon>
											<CalendarIcon
												color={theme.primary}
											/>
										</DateFormIcon>
									</Col>
									<Col span={21}>
										<TextS
											color={theme.textGray}
											weight={400}
										>
											Vencimento
										</TextS>
										<TextM>
											{dayjs(
												paymentData.due_date ??
													paymentData.register_data
														.due_date_register,
											).format('DD/MM/YYYY')}
										</TextM>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<PaymentCreationDateCollapse
									style={{ marginTop: '1rem' }}
									items={[
										{
											id: '1',
											label: 'Descontos, juros e multas',
											children: (
												<>
													<Row>
														<Col span={24}>
															<TextS
																color={
																	theme.textGray
																}
																weight={400}
															>
																Juros / Multas
															</TextS>
															<TextM>
																{formatCurrency(
																	paymentData
																		.register_data
																		.total_with_additional,
																)}
															</TextM>
														</Col>
													</Row>
													<Row
														style={{
															marginTop: '0.6rem',
														}}
													>
														<Col span={24}>
															<TextS
																color={
																	theme.textGray
																}
																weight={400}
															>
																Desconto /
																Abatimentos
															</TextS>
															<TextM>
																{formatCurrency(
																	paymentData
																		.register_data
																		.total_with_discount,
																)}
															</TextM>
														</Col>
													</Row>
												</>
											),
										},
									]}
								/>
								<Row>
									<Col span={24}>
										<PlaceholderDarkInput
											name="payment_date"
											label="Transferir em"
											rules={[
												minTodayDateRule(
													'payment_date',
												),
												weekendRule,
												holidaysRule,
											]}
										>
											<DatePicker
												placeholder={`Hoje ${dayjs().format('DD/MM/YYYY')}`}
												disablePastDays={true}
												disableHolidays={true}
												format="DD/MM/YYYY"
											/>
										</PlaceholderDarkInput>
									</Col>
								</Row>
								{paymentData.register_data
									?.allow_change_value && (
									<Row>
										<Col span={24}>
											<Form.Item
												name="amount"
												label="Valor"
												validateTrigger="onBlur"
												rules={[
													betweenRangeValue(
														'amount',
														paymentData
															?.register_data
															?.min_value ?? 0,
														paymentData
															?.register_data
															?.max_value ?? 0,
													),
												]}
											>
												<CurrencyInput placeholder="0,00" />
											</Form.Item>
											<PaymentCreationDateInfo>
												<TextS
													color={theme.textGray}
													weight={400}
													style={{
														marginRight: '0.6rem',
													}}
												>
													{`Min: ${formatCurrency(
														paymentData
															.register_data
															.min_value,
													)}`}
												</TextS>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													{`Max: ${formatCurrency(
														paymentData
															.register_data
															.max_value,
													)}`}
												</TextS>
											</PaymentCreationDateInfo>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</PaymentCreationDateFormStyled>
			<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
				<Col span={24}>
					<ForwardButton type="primary" onClick={form.submit}>
						<TextM>Solicitar pagamento</TextM>
						<IoMdArrowForward size={22} />
					</ForwardButton>
				</Col>
			</Row>
		</>
	);
};

export default PaymentCreationDateForm;
