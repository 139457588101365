import { Divider, Flex } from 'antd';
import {
	DescriptionsContainer,
	DescriptionsHeader,
	GridContent,
} from './styles';
import { Heading2 } from 'components/Heading2';
import { useTheme } from 'styled-components';
import { ReactNode } from 'react';
import { TextS } from 'components/Text';

export interface IDescriptionsVerticalProps {
	title: string | ReactNode;
	items?: {
		itemId: string;
		data: {
			key: string;
			label: string;
			content: string | ReactNode;
		}[];
	}[];
	borderless?: boolean;
	columns?: number;
	addComponent?: ReactNode;
	emptyComponent?: ReactNode;
	onEdit?: () => void;
}
const DescriptionsRow: React.FC<IDescriptionsVerticalProps> = ({
	title,
	items,
	borderless,
	addComponent,
	emptyComponent,
}) => {
	const theme = useTheme();
	const itemsSize = items?.length ?? 0;

	return (
		<DescriptionsContainer borderless={borderless}>
			<DescriptionsHeader>
				{typeof title === 'string' ? (
					<Heading2>{title}</Heading2>
				) : (
					title
				)}
				{addComponent && addComponent}
			</DescriptionsHeader>
			{items && items.length > 0
				? items?.map(item => (
						<div key={item.itemId}>
							<div
								style={{
									width: '100%',
									padding: '4px 20px',
									margin: '.5rem 0',
								}}
							>
								<GridContent
									contentLength={item.data.length ?? 3}
								>
									{item.data.map(data => (
										<Flex
											gap={'5rem'}
											style={{ width: '100%' }}
											align="center"
										>
											<TextS
												style={{
													color: theme.textGray,
												}}
											>
												{data.label}
											</TextS>
											{data.content}
										</Flex>
									))}
								</GridContent>
							</div>
							{itemsSize > 1 && (
								<div
									style={{
										width: '100%',
										padding: '4px 8px',
									}}
								>
									<Divider
										style={{
											margin: '0',
											padding: '0',
										}}
									/>
								</div>
							)}
						</div>
					))
				: emptyComponent}
		</DescriptionsContainer>
	);
};

export default DescriptionsRow;
