import { Modal } from 'components/Modal';
import { TextM, TextS } from 'components/Text';
import { ModalTextArea } from './styles';
import { useState } from 'react';
import { notification, Row, Tooltip } from 'antd';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { useTheme } from 'styled-components';
import { WalletCharge } from 'types/Wallet';
import { formatAddZerosMaskInput } from 'helpers/formaters';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export interface CancelChargeModalProps {
	onClose: () => void;
	handleConfirm: (comment: string) => void;
	isOpen: boolean;
	walletCharge: WalletCharge;
}

const CancelChargeModal = ({
	isOpen,
	walletCharge,
	onClose,
	handleConfirm,
}: CancelChargeModalProps) => {
	const [api, contextHolder] = notification.useNotification();
	const [comment, setComment] = useState('');
	const theme = useTheme();

	const onConfirm = (comment: string) => {
		if (comment.length < 5) {
			api.info({
				message:
					'Por favor, informe um motivo para o cancelamento do boleto com no mínimo 5 caracteres.',
			});
			return;
		}
		handleConfirm(comment);
	};

	return (
		<>
			{contextHolder}
			<Modal isOpen={isOpen} title="Cancelar boleto" onClose={onClose}>
				<TextM>{`Tem certeza que deseja cancelar o boleto ${formatAddZerosMaskInput(walletCharge.charge_code?.toString())} ?`}</TextM>

				<Row style={{ margin: '1rem 0', gap: '1rem' }}>
					<TextM>Motivo do cancelamento:</TextM>
					<Tooltip title="O motivo do cancelamento deve possuir no mínimo 5 caracteres">
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							<IoMdInformationCircleOutline size={16} />
						</div>
					</Tooltip>
				</Row>
				<ModalTextArea
					rows={3}
					placeholder="Digite aqui"
					maxLength={220}
					value={comment}
					style={{
						minHeight: '120px',
					}}
					onChange={e => setComment(e.target.value)}
				/>

				<Row style={{ margin: '1rem 0 0', gap: '1rem' }}>
					<ButtonPrimary
						onClick={() => onConfirm(comment)}
						style={{
							justifyContent: 'center',
							padding: '1.4rem',
						}}
						disabled={comment.length < 5}
					>
						<TextM style={{ color: theme.white }}>
							Confirmar cancelamento
						</TextM>
					</ButtonPrimary>

					<ButtonPrimary
						variant="secondary"
						onClick={onClose}
						style={{
							justifyContent: 'center',
							padding: '1.4rem',
							border: 0,
							boxShadow: 'none',
						}}
					>
						<TextM style={{ color: theme.primary }}>Fechar</TextM>
					</ButtonPrimary>
				</Row>
			</Modal>
		</>
	);
};

export default CancelChargeModal;
