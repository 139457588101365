import { ForwardButton } from './styles';
import { Col, Row } from 'antd';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { TextM } from 'components/Text';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

interface IExtendExpirationSuccessProps {
	date: string;
	isMultiples: boolean;
	onClose: () => void;
}
const ExtendExpirationSuccess = ({
	date,
	isMultiples,
	onClose,
}: IExtendExpirationSuccessProps) => {
	const theme = useTheme();
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={3}>
					<CheckmarkIcon />
				</Col>
				<Col span={21}>
					{isMultiples ? (
						<TextM weight="normal">
							Os boletos foi prorrogado com sucesso para o dia{' '}
							<span style={{ color: theme.primary }}>
								{dayjs(date).format('DD/MM/YYYY')}
							</span>
						</TextM>
					) : (
						<TextM weight="normal">
							O boleto foi prorrogado com sucesso para o dia{' '}
							<span style={{ color: theme.primary }}>
								{dayjs(date).format('DD/MM/YYYY')}
							</span>
						</TextM>
					)}
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<ForwardButton onClick={onClose} type="primary">
						<TextM>Ok, obrigado</TextM>
					</ForwardButton>
				</Col>
			</Row>
		</>
	);
};

export default ExtendExpirationSuccess;
