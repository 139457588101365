import { useCallback, useState } from 'react';
import { ApiError } from 'types/ApiError';
import { AccessService } from '../services/access';
import { useMutation } from '@tanstack/react-query';
import { AccessDto } from 'types/Access';

export const useAccess = (options?: {
	onError?: (error: ApiError) => void;
	onSuccess?: () => void;
}) => {
	const [isLoading, toggleIsLoading] = useState(false);

	const { mutate } = useMutation<
		void,
		ApiError,
		{ userId: string; accessId: string }
	>({
		mutationFn: async ({ accessId, userId }) => {
			try {
				await AccessService.deleteAccess(userId, accessId);
			} catch (e) {
				return Promise.reject(e);
			} finally {
				toggleIsLoading(false);
			}
		},
		onSuccess: () => {
			if (typeof options?.onSuccess === 'function') {
				options.onSuccess();
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: mutateMultiples } = useMutation<
		void,
		ApiError,
		{ userId: string; accessId: string[] }
	>({
		mutationFn: async ({ accessId, userId }) => {
			try {
				await AccessService.deleteMultiplesAccess(userId, accessId);
			} catch (e) {
				return Promise.reject(e);
			} finally {
				toggleIsLoading(false);
			}
		},
		onSuccess: () => {
			if (typeof options?.onSuccess === 'function') {
				options.onSuccess();
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: updateAccess } = useMutation<
		void,
		ApiError,
		{ userId: string; accessDto: AccessDto }
	>({
		mutationFn: async ({ userId, accessDto }) => {
			try {
				await AccessService.createAccess(userId, accessDto);
			} catch (e) {
				return Promise.reject(e);
			} finally {
				toggleIsLoading(false);
			}
		},
		onSuccess: () => {
			if (typeof options?.onSuccess === 'function') {
				options.onSuccess();
			}
		},
		onError: e => {
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const deleteAccess = useCallback(
		(userId: string, accessId: string | string[]) => {
			toggleIsLoading(true);
			if (Array.isArray(accessId)) {
				mutateMultiples({ userId, accessId });
				return;
			}
			mutate({ userId, accessId });
		},
		[mutate, mutateMultiples],
	);

	return {
		isLoading,
		deleteAccess,
		updateAccess,
	};
};
