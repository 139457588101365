import { Button, Col, Empty, Flex, Row } from 'antd';
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { TextM, TextS } from 'components/Text';
import EmptyStateFiltersImage from 'components/images/EmptyStateFilters.png';
import { useTheme } from 'styled-components';

export interface EmptyFiltersResultProps {
	title?: string;
	description?: string;
	buttonText?: string;
	onClickButton?: () => void;
	hasBorder?: boolean;
}

const EmptyFiltersResult = ({
	title,
	description,
	buttonText,
	onClickButton,
	hasBorder,
}: EmptyFiltersResultProps) => {
	const theme = useTheme();
	return (
		<Col
			style={{
				width: '100%',
				height: '100%',
				border: hasBorder ? '1px solid #f0f0f0' : 'none',
				borderRadius: '10px',
			}}
		>
			{title && (
				<TextM
					style={{
						margin: '1rem 0',
						paddingTop: '1rem',
						color: theme.textSecondary,
						textAlign: 'center',
					}}
				>
					{title}
				</TextM>
			)}
			<Empty
				style={{
					marginTop: '5%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				description=""
				image={EmptyStateFiltersImage}
				imageStyle={{
					height: 240,
				}}
			>
				{description && (
					<TextS
						style={{
							margin: '0.5rem 0',
							height: 'auto',
							color: theme.textSecondary,
						}}
					>
						{description.split('\n').map((item, key) => {
							return (
								<span key={key}>
									{item}
									<br />
								</span>
							);
						})}
					</TextS>
				)}
			</Empty>

			{buttonText && (
				<Row>
					<Flex
						style={{
							width: '100%',
							justifyContent: 'center',
							padding: '1rem',
						}}
					>
						<Button type="primary" onClick={onClickButton}>
							<Flex
								style={{
									alignItems: 'center',
									gap: '0.5rem',
								}}
							>
								{buttonText}
								<AddCircleIcon color={theme.white} size="16" />
							</Flex>
						</Button>
					</Flex>
				</Row>
			)}
		</Col>
	);
};

export default EmptyFiltersResult;
