import React, { useState } from 'react';
import { ForgotPasswordContainer, ForgotPasswordText } from './styles';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import { Heading2 } from 'components/Heading2';

const ForgotPasswordPage = () => {
	const [wasResetRequested, setWasResetRequested] = useState<string | false>(
		false,
	);
	return (
		<ForgotPasswordContainer>
			<Heading2>Redefinir senha</Heading2>
			{!wasResetRequested ? (
				<>
					<ForgotPasswordText>
						Você receberá um e-mail para redefinir sua senha.
					</ForgotPasswordText>
					<ForgotPasswordForm
						onRequestSuccess={username =>
							setWasResetRequested(username)
						}
					/>
				</>
			) : (
				<ForgotPasswordSuccess username={wasResetRequested} />
			)}
		</ForgotPasswordContainer>
	);
};

export default ForgotPasswordPage;
