import {
	ForwardButton,
	DebtorAddressFormContainer,
	DebtorAddressFormItem,
	CancelButton,
} from './styles';
import { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { TextM } from 'components/Text';
import { FaCheck } from 'react-icons/fa6';
import { DebtorAddressType } from './types';
import { statesList } from 'constants/states';
import { normalizeCep } from 'helpers/normalizers';
import { WalletCharge } from 'types/Wallet';

interface IDebtorAddressFormProps {
	isLoading: boolean;
	charge: WalletCharge | WalletCharge[];
	onClose: () => void;
	onSave: (address: DebtorAddressType) => void;
}
const DebtorAddressForm = ({
	charge,
	onClose,
	onSave,
}: IDebtorAddressFormProps) => {
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);

	useEffect(() => {
		if (!Array.isArray(charge)) {
			form.setFieldValue(
				'postal_code',
				normalizeCep(charge.debtor.postal_code),
			);
			form.setFieldValue('street', charge.debtor.public_area);
			form.setFieldValue('number', charge.debtor.number);
			form.setFieldValue('state', charge.debtor.state);
			form.setFieldValue('city', charge.debtor.city);
			form.setFieldValue('district', charge.debtor.neighborhood);
		}
	}, [charge]);

	return (
		<DebtorAddressFormContainer
			form={form}
			style={{ width: '100%' }}
			layout="vertical"
			initialValues={{
				postal_code: !Array.isArray(charge)
					? charge.debtor.postal_code
					: undefined,
				street: !Array.isArray(charge)
					? charge.debtor.public_area
					: undefined,
				number: !Array.isArray(charge)
					? charge.debtor.number
					: undefined,
				state: !Array.isArray(charge) ? charge.debtor.state : undefined,
				city: !Array.isArray(charge) ? charge.debtor.city : undefined,
				district: !Array.isArray(charge)
					? charge.debtor.neighborhood
					: undefined,
			}}
			requiredMark={false}
			onFieldsChange={(f, allFields) => {
				const errors = allFields.filter(
					field =>
						(!field.touched && Array.isArray(charge)) ||
						(field.errors && field.errors.length > 0),
				);
				setFormValid(errors.length === 0);
			}}
			onFinish={values => {
				onSave(values);
			}}
		>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<DebtorAddressFormItem
						name="postal_code"
						label="CEP"
						normalize={e => normalizeCep(e)}
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="00000-000" />
					</DebtorAddressFormItem>
				</Col>
				<Col span={12}>
					<DebtorAddressFormItem
						name="state"
						label="Estado"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Select
							placeholder="Selecione o estado"
							showSearch={true}
							filterOption={(input, option) =>
								option?.label
									?.toLowerCase()
									?.startsWith(input.toLowerCase()) ||
								String(option?.value)
									?.toLowerCase()
									?.startsWith(input.toLowerCase())
							}
							options={statesList}
						/>
					</DebtorAddressFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={12}>
					<DebtorAddressFormItem
						name="district"
						label="Bairro"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="Digite aqui" />
					</DebtorAddressFormItem>
				</Col>
				<Col span={12}>
					<DebtorAddressFormItem
						name="city"
						label="Cidade"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="Digite aqui" />
					</DebtorAddressFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={16}>
					<DebtorAddressFormItem
						name="street"
						label="Rua/Avenida"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="Digite aqui" />
					</DebtorAddressFormItem>
				</Col>
				<Col span={8}>
					<DebtorAddressFormItem
						name="number"
						label="Número"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="Digite aqui" />
					</DebtorAddressFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<ForwardButton
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						<TextM>Alterar endereço do pagador</TextM>
						<FaCheck size={18} />
					</ForwardButton>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CancelButton type="default" onClick={onClose}>
						<TextM>Cancelar</TextM>
					</CancelButton>
				</Col>
			</Row>
		</DebtorAddressFormContainer>
	);
};

export default DebtorAddressForm;
