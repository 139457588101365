import styled from 'styled-components';

export const ContainerFluid = styled.div`
	max-width: 100%;
`;

export const Footer = styled.div`
	display: flex;
	margin: 1rem 0;
	justify-content: flex-end;
`;

export const ContainerHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const ButtonActions = styled.div`
	display: flex;
	justify-content: center;
	gap: 0rem;
`;

export const CollapseItemsBody = styled.div`
	//last ant-row to border none
	& .ant-row:last-child {
		border-bottom: none !important;
	}
`;
