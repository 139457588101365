import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
import CelcoinLogo from './CelcoinLogo.png';
import {
	AccountStatementMovement,
	StatementStatusResponse,
} from 'types/Account';
import dayjs from 'dayjs';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import { BankListOptions } from 'constants/banksList';
import { useMemo } from 'react';

export interface StatementReceiptPdfProps {
	movement?: AccountStatementMovement;
	movementStatus: StatementStatusResponse;
}

Font.register({
	family: 'Urbanist',
	fonts: [
		{
			src: `/Urbanist.ttf`,
		},
		{
			src: `/Urbanist-SemiBold.ttf`,
			fontWeight: 'semibold',
		},
	],
});

const styles = StyleSheet.create({
	hero: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#806DFB',
		padding: 10,
	},
	title: {
		fontSize: 16,
		textAlign: 'center',
		width: '100%',
		color: '#1E1360',
		marginTop: 20,
		fontWeight: 'bold',
	},
	subtitle: {
		fontSize: 13,
		textAlign: 'center',
		width: '100%',
		color: '#6F6B82',
	},
	amount: {
		marginTop: 3,
		fontSize: 13,
		textAlign: 'center',
		width: '100%',
		color: '#806DFB',
	},
	sectionWrapper: {
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		border: 2,
		borderStyle: 'solid',
		borderColor: '#F7F7F7',
		padding: 20,
		borderRadius: 5,
	},
	sectionTitle: {
		fontSize: 14,
		width: '100%',
		color: '#1E1360',
		fontWeight: 'semibold',
	},
	sectionRow: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		marginTop: 6,
	},
	rowTitle: {
		fontSize: 12,
		fontWeight: 'semibold',
	},
	rowValue: {
		fontSize: 12,
		color: '#6F6B82',
	},
	footer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#F7F7F7',
		padding: 20,
	},
	footerColumn: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
	},
});

const StatementReceiptPdf = (props: StatementReceiptPdfProps) => {
	const creditPartyBank = useMemo(() => {
		return BankListOptions.find(
			bank => bank.value === props.movementStatus.credit_party.bank,
		);
	}, [props]);

	return (
		<Document>
			<Page
				size="A4"
				style={{
					fontFamily: 'Urbanist',
				}}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>
					<View>
						<View style={styles.hero}>
							<View
								style={{
									height: '80px',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Image
									src={CelcoinLogo}
									style={{ width: '182px' }}
								/>
							</View>
						</View>
						<View>
							<Text style={styles.title}>
								Comprovante de Transferência
							</Text>
							<Text style={{ ...styles.subtitle, marginTop: 6 }}>
								{dayjs(props.movement?.create_date).format(
									'DD/MM/YYYY',
								)}{' '}
								às{' '}
								{dayjs(props.movement?.create_date).format(
									'HH:mm',
								)}
							</Text>
						</View>

						<View>
							<Text style={{ ...styles.subtitle, marginTop: 16 }}>
								Valor
							</Text>
							<Text style={styles.amount}>
								{formatCurrency(props.movement?.amount!)}
							</Text>
						</View>

						<View style={styles.sectionWrapper}>
							<View style={styles.section}>
								<Text style={styles.sectionTitle}>
									Conta de Origem
								</Text>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Titular
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												props.movementStatus.debit_party
													.name
											}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											CNPJ/CPF
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{normalizeCpfCnpj(
												props.movementStatus.debit_party
													.tax_id,
											)}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Código do Banco
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											13935893 - CELCOIN
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Agência
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												props.movementStatus.debit_party
													.branch
											}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Conta
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												props.movementStatus.debit_party
													.account
											}
										</Text>
									</View>
								</View>
							</View>

							<View style={styles.section}>
								<Text style={styles.sectionTitle}>
									Conta de Destino
								</Text>

								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Titular
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												props.movementStatus
													.credit_party.name
											}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											CNPJ/CPF
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{normalizeCpfCnpj(
												props.movementStatus
													.credit_party.tax_id,
											)}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Código do Banco
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{creditPartyBank?.value || ''} -{' '}
											{creditPartyBank?.label || ''}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Agência
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												props.movementStatus
													.credit_party.branch
											}
										</Text>
									</View>
								</View>
								<View style={styles.sectionRow}>
									<View
										style={{
											width: '100%',
										}}
									>
										<Text style={styles.rowTitle}>
											Conta
										</Text>
									</View>
									<View
										style={{
											display: 'flex',
											alignItems: 'flex-end',
											width: '100%',
										}}
									>
										<Text style={styles.rowValue}>
											{
												props.movementStatus
													.credit_party.account
											}
										</Text>
									</View>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.footer}>
						<View style={styles.footerColumn}>
							<Text style={{ fontSize: 12, color: '#6F6B82' }}>
								Código de Identificação
							</Text>
							<Text style={{ fontSize: 12, color: '#6F6B82' }}>
								{props.movement?.id || '-'}
							</Text>
						</View>
						<View style={styles.footerColumn}>
							<Text style={{ fontSize: 12, color: '#6F6B82' }}>
								Celcoin Instituição de Pagamento S.A
							</Text>
							<Text style={{ fontSize: 12, color: '#6F6B82' }}>
								13.935.893/0001-09
							</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default StatementReceiptPdf;
