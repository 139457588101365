import { useEffect, useMemo, useState } from 'react';
import ExtendExpirationForm from './ExtendExpirationForm';
import ExtendExpirationSuccess from './ExtendExpirationSuccess';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { Modal } from 'components/Modal';
import { WalletCharge } from 'types/Wallet';
import { WalletsService } from 'modules/debitCollections/services';
import { queryClient } from 'network/query';
import dayjs from 'dayjs';

enum ExtendExpirationSteps {
	FORM = 'FORM',
	SUCCESS = 'SUCCESS',
}

interface IExtendExpirationProps {
	isOpen: boolean;
	walletId: string;
	charge?: WalletCharge | WalletCharge[];
	onClose: () => void;
}
const ExtendExpiration = ({
	isOpen,
	walletId,
	charge: editCharge,
	onClose,
}: IExtendExpirationProps) => {
	const [currentStep, updateCurrentStep] = useState<ExtendExpirationSteps>(
		ExtendExpirationSteps.FORM,
	);
	const [api, contextHolder] = notification.useNotification();
	const [dueDate, updateDueDate] = useState<string>('');
	const isMultiples = Array.isArray(editCharge);

	const { mutate, isPending } = useMutation<
		{ id: string | string[] },
		ApiError,
		{ due_date: string }
	>({
		mutationFn: ({ due_date }) => {
			if (isMultiples) {
				return Promise.all(
					editCharge!.map(charge => {
						return WalletsService.updateCharge(
							walletId,
							charge.id,
							{
								due_date,
								fine: charge.fine,
								interest: charge.interest,
								discount_value: charge.discount_value,
								discount_limit_date: dayjs(
									charge.discount_limit_date,
								).format('YYYY-MM-DD'),
							},
						);
					}),
				).then(() => ({ id: editCharge!.map(charge => charge.id) }));
			}
			return WalletsService.updateCharge(walletId, editCharge!.id, {
				due_date,
				fine: editCharge!.fine,
				interest: editCharge!.interest,
				discount_value: editCharge!.discount_value,
				discount_limit_date: dayjs(
					editCharge!.discount_limit_date,
				).format('YYYY-MM-DD'),
			});
		},
		onSuccess: () => {
			updateCurrentStep(ExtendExpirationSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['chargesList'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao alterar a cobrança.',
			});
		},
	});

	useEffect(() => {
		if (isOpen) {
			updateCurrentStep(ExtendExpirationSteps.FORM);
		}
	}, [isOpen]);

	const title = useMemo(() => {
		if (currentStep === ExtendExpirationSteps.SUCCESS)
			return isMultiples
				? 'Boletos prorrogados com sucesso!'
				: 'Boleto prorrogado com sucesso!';
		return isMultiples ? 'Prorrogar vencimentos' : 'Prorrogar vencimento';
	}, [currentStep, isMultiples]);

	return (
		<Modal title={title} width={480} isOpen={isOpen} onClose={onClose}>
			{contextHolder}
			{currentStep === ExtendExpirationSteps.FORM && isOpen && (
				<ExtendExpirationForm
					charge={editCharge}
					isLoading={isPending}
					onClose={onClose}
					onSave={dueDate => {
						updateDueDate(dueDate);
						mutate({ due_date: dueDate });
					}}
				/>
			)}
			{currentStep === ExtendExpirationSteps.SUCCESS && isOpen && (
				<ExtendExpirationSuccess
					isMultiples={isMultiples}
					date={dueDate}
					onClose={onClose}
				/>
			)}
		</Modal>
	);
};

export default ExtendExpiration;
