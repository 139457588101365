import { Button, Col, Form, Row, Select, notification } from 'antd';
import { Footer } from './styles';
import { BaseOptionType } from 'antd/es/select';
import { Company, BusinessType } from 'types/Company';
import { CompanyTypeDescription } from 'constants/company';
import { AccountPermission } from 'types/Account';
import { useCompaniesList } from 'modules/company/hooks';
import { useEffect, useState } from 'react';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import { AccountPermissionDescription } from 'constants/account';
import { CustomSwitch } from 'components/CustomSwitch';
import { useTheme } from 'styled-components';
import { IoCheckmark } from 'react-icons/io5';

export type RelatedPartType = {
	person?: Company;
	person_id: string;
	relationship: BusinessType;
	permission: AccountPermission[];
	signer?: boolean;
};

interface IRelatedPartsCreationProps {
	isOpen: boolean;
	isLoading?: boolean;
	permissions: BaseOptionType[];
	relatedPart?: RelatedPartType;
	onClose: () => void;
	onCreate: (relatedPart: RelatedPartType) => void;
}
const RelatedPartsCreation = ({
	relatedPart: editRelatedPart,
	isLoading,
	permissions,
	isOpen,
	onClose,
	onCreate,
}: IRelatedPartsCreationProps) => {
	const [formValid, setFormValid] = useState(false);
	const [api, contextHolder] = notification.useNotification();
	const [form] = Form.useForm();
	const [selectedPermission, setSelectedPermission] = useState<
		AccountPermission[]
	>([]);
	const theme = useTheme();

	const { companies, loading, applyFilters } = useCompaniesList({
		skipUntilApplyFilters: true,
	});

	const handlePermissionChange = (permission: AccountPermission) => {
		const permissions = form.getFieldValue('permission') || [];
		if (permissions?.includes(permission)) {
			setSelectedPermission(state =>
				state?.filter(p => p !== permission),
			);
			form.setFieldValue(
				'permission',
				permissions.filter((p: string) => p !== permission),
			);
		} else {
			setSelectedPermission(state => [...(state || []), permission]);
			form.setFieldValue('permission', [...permissions, permission]);
		}
	};

	useEffect(() => {
		if (isOpen && editRelatedPart?.relationship) {
			applyFilters({
				business_type: editRelatedPart?.relationship,
			});
		}
	}, [isOpen]);

	useEffect(() => {
		if (editRelatedPart) {
			form.setFieldsValue({
				permission: editRelatedPart?.permission,
				relationship: editRelatedPart?.relationship,
				person_id: editRelatedPart?.person_id,
			});
		} else {
			form.setFieldValue('permission', undefined);
			form.setFieldValue('relationship', undefined);
			form.setFieldValue('person_id', undefined);
			setSelectedPermission([]);
		}
	}, [isOpen]);

	return (
		<>
			{contextHolder}
			<Modal
				title="Adicionar Parte Relacionada"
				isOpen={isOpen}
				onClose={onClose}
				width={600}
			>
				<Form<RelatedPartType>
					form={form}
					style={{
						width: '100%',
						maxHeight: 'calc(100vh - 20rem)',
						overflowY: 'auto',
						overflowX: 'hidden',
					}}
					initialValues={{
						person_id: editRelatedPart?.person_id,
						relationship: editRelatedPart?.relationship,
						permission: editRelatedPart?.permission,
					}}
					onFieldsChange={(f, allFields) => {
						const errors = allFields
							.filter(
								field =>
									!field.touched ||
									(field.errors && field.errors.length > 0),
							)
							.filter(
								field => !field.name?.includes('permission'),
							);

						setFormValid(errors.length === 0);
					}}
					layout="vertical"
					onFinish={values => {
						if (
							!values.permission ||
							(values.permission &&
								values.permission.length === 0)
						) {
							api.error({
								message: 'Selecione ao menos uma permissão',
							});
							return;
						}

						onCreate({
							...values,
							person: companies.find(
								company => company.id === values.person_id,
							),
						});
					}}
				>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="relationship"
								label="Tipo de relacionamento"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									placeholder="Selecione"
									onChange={value =>
										applyFilters({
											business_type:
												value as BusinessType,
											size: 100,
										})
									}
									options={Object.keys(
										CompanyTypeDescription,
									).map(key => ({
										label: CompanyTypeDescription[
											key as unknown as BusinessType
										],
										value: key,
									}))}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item
								name="person_id"
								label="Empresa"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									loading={loading}
									placeholder="Selecione"
									filterOption={(input, option) =>
										option?.label
											?.toLowerCase()
											?.startsWith(input.toLowerCase()) ||
										String(option?.value)
											?.toLowerCase()
											?.startsWith(input.toLowerCase())
									}
									options={companies.map(company => ({
										label: company.name,
										value: company.id,
									}))}
									showSearch
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item name="permission" label="Permissão">
								<div>
									{Object.values(AccountPermission).map(
										permission => (
											<div
												key={permission}
												style={{
													width: '100%',
													display: 'flex',
													justifyContent:
														'space-between',
													alignItems: 'center',
													padding: '1rem',
													borderBottom: `1px solid ${theme.border}`,
												}}
											>
												<TextS
													style={{
														color: theme.textGray,
													}}
												>
													{
														AccountPermissionDescription[
															permission as AccountPermission
														]
													}
												</TextS>
												<CustomSwitch
													checked={
														selectedPermission
															? selectedPermission?.includes(
																	permission,
																)
															: false
													}
													handleChange={() => {
														handlePermissionChange(
															permission as AccountPermission,
														);
													}}
												/>
											</div>
										),
									)}
								</div>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<Footer>
					<Button
						type="default"
						loading={isLoading}
						onClick={() => {
							form.resetFields();
							setSelectedPermission([]);
							onClose();
						}}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.5rem',
							border: 'none',
							boxShadow: 'none',
							color: theme.primary,
						}}
					>
						Cancelar
					</Button>
					<Button
						onClick={form.submit}
						type="primary"
						loading={isLoading}
						htmlType="submit"
						disabled={!formValid}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.5rem',
						}}
					>
						Adicionar
						<IoCheckmark />
					</Button>
				</Footer>
			</Modal>
		</>
	);
};

export default RelatedPartsCreation;
