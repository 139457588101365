import { Tabs } from 'antd';
import styled from 'styled-components';

export const DropAreaContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 1px;
	border-radius: 10px;
	border-color: ${({ theme }) => theme.primary};
	border-style: dashed;
	background-color: ${({ theme }) => theme.lightGray};
	width: 100%;
`;

export const TabsSelector = styled(Tabs)`
	.ant-tabs-tab {
		padding: 6px 10px !important;
	}
	.ant-tabs-nav {
		padding: 0;
		margin: 0;
	}
`;

export const TableWrapper = styled.div`
	.ant-table {
		& th::before {
			display: none;
		}

		.error-row {
			background-color: ${({ theme }) => theme.dangerOpacity} !important;

			.ant-table-cell {
				background-color: ${({ theme }) =>
					theme.dangerOpacity} !important;
			}
		}
	}
`;

export const BackgroundModalData = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.primaryOpacity};
	padding: 1rem;
	border-radius: 0.5rem;
`;
