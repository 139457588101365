import styled from 'styled-components';

const BackgroundModalData = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.primaryOpacity};
	padding: 1rem;
	border-radius: 0.5rem;
`;

export default BackgroundModalData;
