import { Button, Form } from 'antd';
import styled from 'styled-components';
import { UserSettingsMfaFormType } from './types';
import { TextS } from 'components/Text';

export const UserSettingsMfaContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 450px;
`;

export const UserSettingsMfaConfirmButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;
	margin-top: 2rem;

	p {
		font-size: 18px;
		color: white;
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const UserSettingsMfaText = styled(TextS)`
	margin: 0.6rem 0 0.4rem;
	text-align: center;
	font-weight: 400;
	font-size: 14px;
	font-family: 'Inter', sans-serif;
	color: ${p => p.theme.textSecondary};
`;

export const UserSettingsMfaForm = styled(Form<UserSettingsMfaFormType>)`
	padding: 0 0 1rem;
	width: 100%;
	position: relative;
`;

export const UserSettingsMfaFormContainer = styled.div`
	width: 100%;
	position: relative;

	${UserSettingsMfaText} {
		text-align: left;
		padding: 0 1.2rem;
	}
`;

export const UserSettingsMfaFormSteps = styled.ol`
	list-style: none;
	counter-reset: my-awesome-counter;
`;

export const UserSettingsMfaFormStepItem = styled.li`
	counter-increment: my-awesome-counter;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	${UserSettingsMfaText} {
		text-align: left;
		padding: 0;
		margin: 0;
	}
	&::before {
		content: counter(my-awesome-counter) '.';
		font-size: 1rem;
		font-weight: bold;
		color: ${p => p.theme.textSecondary};
		position: absolute;
		top: 0;
		left: -1.5rem;
	}
`;

export const UserSettingsMfaFormQrCode = styled.div`
	display: flex;
	justify-content: center;
	border: 1px solid ${p => p.theme.border};
	padding: 1rem;
	width: fit-content;
	margin: 1rem 1rem 1rem -2rem;
`;

export const UserSettingsMfaFormError = styled.div`
	margin: 0.4rem 0 0;
	text-align: center;
	color: ${p => p.theme.error};
`;

export const UserSettingsMfaFormItem = styled(Form.Item)`
	width: 2.5rem;
	height: 2.5rem;
	display: inline-block;
	margin: 0 0.4rem 0 0;
	margin-bottom: 0;

	input {
		width: 2.5rem;
		height: 2.5rem;
	}
`;

export const UserSettingsMfaFormInputList = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`;
