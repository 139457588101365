import {
	ForwardButton,
	InterestFineFormContainer,
	InterestFineFormItem,
	CancelButton,
} from './styles';
import { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import { TextM } from 'components/Text';
import { FaCheck } from 'react-icons/fa6';
import { InterestFineType } from './types';
import { minValue } from 'helpers/rules';
import { WalletCharge } from 'types/Wallet';

interface IInterestFineFormProps {
	isLoading: boolean;
	charge: WalletCharge | WalletCharge[];
	onClose: () => void;
	onSave: (values: InterestFineType) => void;
}
const InterestFineForm = ({
	charge,
	onClose,
	onSave,
}: IInterestFineFormProps) => {
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);

	useEffect(() => {
		if (!Array.isArray(charge)) {
			form.setFieldValue('interest', charge.interest);
			form.setFieldValue('fine', charge.fine);
		}
	}, []);

	return (
		<InterestFineFormContainer
			form={form}
			style={{ width: '100%' }}
			layout="vertical"
			requiredMark={false}
			initialValues={{
				interest: !Array.isArray(charge) ? charge.interest : undefined,
				fine: !Array.isArray(charge) ? charge.fine : undefined,
			}}
			onFieldsChange={(f, allFields) => {
				const errors = allFields.filter(
					field =>
						(!field.touched && Array.isArray(charge)) ||
						(field.errors && field.errors.length > 0),
				);
				setFormValid(errors.length === 0);
			}}
			onFinish={values => {
				onSave(values);
			}}
		>
			<Row style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<InterestFineFormItem
						name="interest"
						label="Juros"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
							minValue(
								'interest',
								0.01,
								'Juros deve ser maior que 0.00%',
							),
						]}
					>
						<InputNumber
							min={0}
							max={100}
							changeOnWheel={false}
							style={{ width: '100%' }}
							prefix={'%'}
							decimalSeparator=","
							placeholder="0"
						/>
					</InterestFineFormItem>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<InterestFineFormItem
						name="fine"
						label="Multa"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
							minValue(
								'fine',
								0.01,
								'Multa deve ser maior que 0.00%',
							),
						]}
					>
						<InputNumber
							min={0}
							max={100}
							style={{ width: '100%' }}
							prefix={'%'}
							changeOnWheel={false}
							decimalSeparator=","
							placeholder="0"
						/>
					</InterestFineFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<ForwardButton
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						<TextM>Salvar alterações</TextM>
						<FaCheck size={18} />
					</ForwardButton>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CancelButton type="default" onClick={onClose}>
						<TextM>Cancelar</TextM>
					</CancelButton>
				</Col>
			</Row>
		</InterestFineFormContainer>
	);
};

export default InterestFineForm;
