import { AccessPermission } from 'types/Access';

export const AccessPermissionRestricted = [] as AccessPermission[];

export const AccessPermissionDescription = {
	[AccessPermission.VIEW_ACCOUNT]: 'Visualizar contas',
	[AccessPermission.POSTING_REVIEW]: 'Aprovar/reprovar lançamentos',
	[AccessPermission.POSTING_CREATE]: 'Realizar lançamentos',
	[AccessPermission.MANAGE_USER]: 'Gerenciar acessos',
	[AccessPermission.POSTING_VIEW]: 'Visualizar lançamentos',
	[AccessPermission.VIEW_WALLET]: 'Visualizar carteiras',
	[AccessPermission.CREATE_WALLET]: 'Criar carteiras',
	[AccessPermission.UPDATE_WALLET]: 'Alterar carteiras',
	[AccessPermission.ARCHIVE_WALLET]: 'Arquivar carteiras',
	[AccessPermission.CREATE_CHARGE]: 'Criar cobranças',
	[AccessPermission.CANCEL_CHARGE]: 'Cancelar cobranças',
	[AccessPermission.VIEW_CHARGE]: 'Visualizar cobranças',
	[AccessPermission.UPDATE_CHARGE]: 'Alterar cobranças',
	[AccessPermission.CREATE_BALANCE_RETENTION]: 'Criar bloqueios de saldo',
	[AccessPermission.REMOVE_BALANCE_RETENTION]: 'Remover bloqueios de saldo',
	[AccessPermission.VIEW_BALANCE_RETENTION]: 'Visualizar bloqueios de saldo',
	[AccessPermission.CREATE_ACCOUNT]: 'Criar conta',
	[AccessPermission.CREATE_BENEFICIARY]: 'Criar beneficiário',
};
