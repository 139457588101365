import styled from 'styled-components';
import { StyleConstants } from 'theme/StyleConstants';

export const NavBarWrapper = styled.header`
	border-bottom: 1px solid ${p => p.theme.background};
	height: ${StyleConstants.NAV_BAR_HEIGHT};
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 1rem;
	background-color: ${p => p.theme.white};
	z-index: 2;
`;

export const NavBarUserArea = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`;

export const NavBarNotification = styled.div`
	width: 48px;
	height: 38px;
	gap: 8px;
	border-radius: 6px;
	border: 1px solid ${p => p.theme.border};
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 1.2rem;
		height: 1.2rem;
	}
`;
