import { Button, Col, Input, Row, Select } from 'antd';
import { useCallback, useEffect } from 'react';
import { normalizeCnpj, normalizePhone } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import { Company, BusinessType } from 'types/Company';
import { EditForm, EditFormItem } from './styles';
import { CompanyForm } from 'components/CompanyInformation/types';
import { CompanyTypeDescription } from 'constants/company';
import { DatePicker } from 'components/DatePicker';
import dayjs from 'dayjs';
import { formatPhoneFromObject } from 'helpers/formaters';
import FormItem from 'antd/es/form/FormItem';
import { useCompaniesList } from 'modules/company/hooks';
import { companyRevenueOptions } from 'modules/company/utils/options';
import { parsedCnaeData } from 'modules/company/utils/cnae';

interface ICompanyBasicDataEditProps {
	isOpen: boolean;
	company: Company;
	onClose: () => void;
	onSave: (company: Company) => void;
}
const CompanyBasicDataEdit = ({
	isOpen,
	company,
	onSave,
	onClose,
}: ICompanyBasicDataEditProps) => {
	const [form] = EditForm.useForm();

	const { companies: managerCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.MANAGER,
			size: 100,
		},
	});
	const { companies: custodianCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.CUSTODIAN,
			size: 100,
		},
	});
	const { companies: adminCompanies } = useCompaniesList({
		initialFilters: {
			business_type: BusinessType.ADMINISTRATOR,
			size: 100,
		},
	});

	const onFinish = useCallback(
		(values: CompanyForm) => {
			const isFund = values.business_type === BusinessType.FUND;
			onSave({
				...company,
				business_type: values.business_type,
				taxpayer_id: serializeOnlyNumbers(values.taxpayer_id),
				corporate_name: values.corporate_name,
				name: values.name,
				foundation_date: values.foundation_date.format('YYYY-MM-DD'),
				cnae: values.cnae,
				email_address: values.email_address,
				phone: serializePhoneObject(values.phone),
				average_monthly_revenue: values.average_monthly_revenue,
				fund_manager_id: isFund ? values.fund_manager_id : undefined,
				fund_administrator_id: isFund
					? values.fund_administrator_id
					: undefined,
				fund_securitizer_id: isFund
					? values.fund_securitizer_id
					: undefined,
			} as Company);
		},
		[onSave, company],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id: normalizeCnpj(company.taxpayer_id),
				business_type: company.business_type,
				corporate_name: company.corporate_name,
				name: company.name,
				foundation_date: dayjs(company.foundation_date, 'YYYY-MM-DD'),
				cnae: company.cnae,
				email_address: company.email_address,
				phone: formatPhoneFromObject(company.phone),
				// to update data already saved in db
				average_monthly_revenue: company?.average_monthly_revenue
					? companyRevenueOptions
							.map(option => option.value)
							.includes(company?.average_monthly_revenue)
						? company.average_monthly_revenue
						: companyRevenueOptions?.[0]?.value
					: companyRevenueOptions?.[0]?.value,
				fund_manager_id: company?.fund_manager_id,
				fund_securitizer_id: company?.fund_securitizer_id,
				fund_administrator_id: company?.fund_administrator_id,
			});
		}
	}, [isOpen, form, company]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Dados básicos"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Salvar
						</Button>
					</Col>
				</Row>
			}
		>
			<EditForm layout="vertical" form={form} onFinish={onFinish}>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="taxpayer_id"
							label="CNPJ"
							normalize={e => normalizeCnpj(e)}
						>
							<Input placeholder="Digite aqui" disabled={true} />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="business_type"
							label="Tipo"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={Object.keys(BusinessType).map(key => ({
									label: CompanyTypeDescription[
										key as BusinessType
									],
									value: key,
								}))}
							/>
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="corporate_name"
							label="Razão Social"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Empresa S.A." />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="name"
							label="Nome Fantasia"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Empresa XPTO" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="foundation_date"
							label="Data de Constituição"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="cnae"
							label="CNAE"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione o CNAE"
								showSearch={true}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.includes(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.includes(input.toLowerCase())
								}
								options={parsedCnaeData}
							/>
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="average_monthly_revenue"
							label="Faixa de Faturamento"
						>
							<Select
								placeholder="Selecione a faixa de faturamento"
								options={companyRevenueOptions.map(option => ({
									label: option.label,
									value: option.value,
								}))}
							/>
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FormItem
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.type !== currentValues.type
							}
						>
							{({ getFieldValue }) => (
								<>
									{getFieldValue('type') ===
										BusinessType.FUND && (
										<>
											<Row
												style={{ width: '100%' }}
												align="middle"
											>
												<Col span={24}>
													<EditFormItem
														name="fund_manager_id"
														label="Empresa Gestora"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															filterOption={(
																input,
																option,
															) =>
																option?.label
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	) ||
																String(
																	option?.value,
																)
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	)
															}
															options={managerCompanies.map(
																company => ({
																	label: company.name,
																	value: company.id,
																}),
															)}
															showSearch
														/>
													</EditFormItem>
												</Col>
											</Row>
											<Row
												style={{ width: '100%' }}
												align="middle"
											>
												<Col span={24}>
													<EditFormItem
														name="fund_securitizer_id"
														label="Empresa Custodiante"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															filterOption={(
																input,
																option,
															) =>
																option?.label
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	) ||
																String(
																	option?.value,
																)
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	)
															}
															options={custodianCompanies.map(
																company => ({
																	label: company.name,
																	value: company.id,
																}),
															)}
															showSearch
														/>
													</EditFormItem>
												</Col>
											</Row>
											<Row
												style={{ width: '100%' }}
												align="middle"
											>
												<Col span={24}>
													<EditFormItem
														name="fund_administrator_id"
														label="Empresa Administradora"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															placeholder="Selecione"
															filterOption={(
																input,
																option,
															) =>
																option?.label
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	) ||
																String(
																	option?.value,
																)
																	?.toLowerCase()
																	?.startsWith(
																		input.toLowerCase(),
																	)
															}
															options={adminCompanies.map(
																company => ({
																	label: company.name,
																	value: company.id,
																}),
															)}
															showSearch
														/>
													</EditFormItem>
												</Col>
											</Row>
										</>
									)}
								</>
							)}
						</FormItem>
					</Col>
				</Row>
			</EditForm>
		</CustomDrawer>
	);
};

export default CompanyBasicDataEdit;
