import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import React from 'react';

const NotFoundPage = () => {
	return (
		<PageWrapper>
			<Heading1>404</Heading1>
			<p>Page not found</p>
		</PageWrapper>
	);
};

export default NotFoundPage;
