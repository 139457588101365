import {
	Checkbox,
	Col,
	Flex,
	Form,
	Input,
	notification,
	Radio,
	Row,
	Select,
	TimePicker,
	Tooltip,
} from 'antd';
import { CurrencyInput } from 'components/CurrencyInput';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { CargeSelectWrapper } from './styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CreateTransferRuleModalFormValues } from './CreateTransferRuleModal';
import Footer from './Footer';
import { useState } from 'react';
import { betweenRangeNumberValue } from 'helpers/rules';
import { BsExclamationTriangle } from 'react-icons/bs';
import {
	DailyTransferFrequency,
	SelectType,
	TransferFrequency,
	TransferMethod,
} from 'types/Management';
import { dayOfWeek, requestTime } from 'helpers/dates';

dayjs.extend(customParseFormat);

export interface TransferRuleConfigProps {
	formValues: CreateTransferRuleModalFormValues;
	isEdit?: boolean;
	onNext?: () => void;
	onPrevious?: () => void;
	updateForm?: (values: CreateTransferRuleModalFormValues) => void;
}

const TransferRuleConfig = ({
	formValues,
	isEdit,
	onNext,
	onPrevious,
	updateForm,
}: TransferRuleConfigProps) => {
	const [api, contextHolder] = notification.useNotification();
	const theme = useTheme();
	const [form] = Form.useForm();
	const [selectedWeekdays, updateSelectWeekdays] = useState<string[]>(
		formValues.selected_weekdays ?? [],
	);

	const handleFinish = (values: CreateTransferRuleModalFormValues) => {
		updateForm?.({
			...values,
			selected_weekdays: selectedWeekdays,
			daily_transfer_time: values.daily_transfer_time
				? dayjs(values.daily_transfer_time).format('HH:mm')
				: undefined,
			weekly_transfer_time: values?.weekly_transfer_time
				? dayjs(values.weekly_transfer_time).format('HH:mm')
				: undefined,
			transfer_window_start: values?.transfer_window_start
				? dayjs(values.transfer_window_start).format('HH:mm')
				: undefined,
			transfer_window_end: values?.transfer_window_end
				? dayjs(values.transfer_window_end).format('HH:mm')
				: undefined,
			min_value_in_account_after_transfer:
				values.min_value_in_account_after_transfer || 0,
		});

		if (selectedWeekdays.length === 0) {
			api.error({ message: 'Selecione ao menos um dia da semana.' });
			return;
		}

		onNext?.();
	};

	const initialValues = {
		amount_type: formValues?.amount_type ?? SelectType.FIXED,
		amount: formValues?.amount ?? undefined,
		transfer_method: formValues?.transfer_method ?? undefined,
		min_value_in_account_after_transfer:
			formValues?.min_value_in_account_after_transfer ?? undefined,
		frequency: formValues?.frequency ?? undefined,
		daily_transfer_frequency:
			formValues?.daily_transfer_frequency ?? undefined,
		daily_transfer_time: formValues?.daily_transfer_time
			? dayjs()
					.hour(Number(formValues?.daily_transfer_time.split(':')[0]))
					.minute(
						Number(formValues?.daily_transfer_time.split(':')[1]),
					)
			: dayjs().hour(8).minute(30),
		weekly_transfer_time: formValues?.weekly_transfer_time
			? dayjs()
					.hour(
						Number(formValues?.weekly_transfer_time.split(':')[0]),
					)
					.minute(
						Number(formValues?.weekly_transfer_time.split(':')[1]),
					)
			: dayjs().hour(8).minute(30),
		transfer_window_start: formValues?.transfer_window_start
			? dayjs()
					.hour(
						Number(formValues?.transfer_window_start.split(':')[0]),
					)
					.minute(
						Number(formValues?.transfer_window_start.split(':')[1]),
					)
			: dayjs().hour(8).minute(30),
		transfer_window_end: formValues?.transfer_window_end
			? dayjs()
					.hour(Number(formValues?.transfer_window_end.split(':')[0]))
					.minute(
						Number(formValues?.transfer_window_end.split(':')[1]),
					)
			: dayjs().hour(18).minute(0),
		transfer_interval: formValues?.transfer_interval,
	};

	return (
		<>
			{contextHolder}
			<Form<CreateTransferRuleModalFormValues>
				form={form}
				layout="vertical"
				onFinish={handleFinish}
				initialValues={initialValues}
			>
				<Form.Item shouldUpdate style={{ width: '100%' }}>
					{({ getFieldValue }) => {
						const isTed =
							getFieldValue('transfer_method') ===
							TransferMethod.TED;
						const isDaily =
							getFieldValue('frequency') ===
							TransferFrequency.DAILY;

						return (
							<>
								<Row
									style={{
										flexDirection: 'column',
										width: '100%',
									}}
								>
									<TextS color={theme.primary}>
										Valor da transferência
									</TextS>
									<Row>
										<Row
											style={{
												width: '100%',
												marginTop: '1rem',
												flexDirection: 'column',
												gap: '0.5rem',
											}}
										>
											<TextS>Transferência</TextS>
											<Row>
												<CargeSelectWrapper>
													<Form.Item name="amount_type">
														<Select
															disabled={isEdit}
															style={{
																borderTopRightRadius: 0,
																borderBottomRightRadius: 0,
															}}
															options={[
																{
																	label: 'Monetário',
																	value: SelectType.FIXED,
																},
																{
																	label: 'Percentual',
																	value: SelectType.PERCENT,
																},
															]}
														/>
													</Form.Item>
													<Form.Item
														shouldUpdate
														style={{
															width: '100%',
														}}
													>
														{getFieldValue(
															'amount_type',
														) ===
															SelectType.PERCENT && (
															<Form.Item
																name="amount"
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																	betweenRangeNumberValue(
																		'amount',
																		0,
																		100,
																	),
																]}
																shouldUpdate
															>
																<Input
																	disabled={
																		isEdit
																	}
																	type="number"
																	inputMode="numeric"
																	placeholder="0"
																	prefix="%"
																	min={0}
																	max={100}
																	style={{
																		borderTopLeftRadius: 0,
																		borderBottomLeftRadius: 0,
																		maxWidth:
																			'250px',
																	}}
																/>
															</Form.Item>
														)}

														{getFieldValue(
															'amount_type',
														) ===
															SelectType.FIXED && (
															<Form.Item
																name="amount"
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]}
																shouldUpdate
															>
																<CurrencyInput
																	disabled={
																		isEdit
																	}
																	placeholder="0,00"
																	style={{
																		borderTopLeftRadius: 0,
																		borderBottomLeftRadius: 0,
																		maxWidth:
																			'250px',
																	}}
																/>
															</Form.Item>
														)}
													</Form.Item>
												</CargeSelectWrapper>
											</Row>
										</Row>
									</Row>
								</Row>
								<Row
									style={{
										flexDirection: 'column',
										marginTop: '1rem',
									}}
								>
									<TextS
										style={{
											color: theme.primary,
										}}
									>
										Informações da cobrança
									</TextS>
									<Row
										style={{ marginTop: '1rem' }}
										gutter={[10, 10]}
									>
										<Col span={12}>
											<Form.Item
												label={
													<TextS>
														Tipo de transferência
													</TextS>
												}
												name="transfer_method"
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<Select
													disabled={isEdit}
													placeholder="Selecione"
													options={[
														{
															label: 'Pix',
															value: TransferMethod.PIX,
														},
														{
															label: 'TED',
															value: TransferMethod.TED,
														},
													]}
													onChange={() => {
														updateSelectWeekdays(
															[],
														);
													}}
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name="min_value_in_account_after_transfer"
												label={
													<TextS>
														Saldo mínimo retido em
														conta
													</TextS>
												}
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<CurrencyInput
													disabled={isEdit}
													placeholder="0,00"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Row>
								<Row
									style={{
										flexDirection: 'column',
									}}
								>
									<TextS
										style={{
											color: theme.primary,
										}}
									>
										Frequência de transferência
									</TextS>
									<Row
										style={{ marginTop: '1rem' }}
										gutter={[10, 10]}
									>
										<Col span={12}>
											<Form.Item
												name="frequency"
												label={
													<TextS>
														Frequência de
														transferência
													</TextS>
												}
												rules={[
													{
														required: true,
														message:
															'Campo obrigatório',
													},
												]}
											>
												<Select
													disabled={isEdit}
													placeholder="Selecione"
													options={[
														{
															label: 'Diária',
															value: TransferFrequency.DAILY,
														},
														{
															label: 'Semanal',
															value: TransferFrequency.WEEKLY,
														},
													]}
													onChange={() => {
														updateSelectWeekdays(
															[],
														);
													}}
												/>
											</Form.Item>
										</Col>

										<Col span={12}>
											<Form.Item shouldUpdate>
												{({ getFieldValue }) => {
													if (
														getFieldValue(
															'frequency',
														) ===
														TransferFrequency.DAILY
													) {
														return (
															<Form.Item
																name="daily_transfer_frequency"
																label={
																	<TextS>
																		Transferir
																	</TextS>
																}
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]}
															>
																<Select
																	disabled={
																		isEdit
																	}
																	placeholder="Selecione"
																	options={[
																		{
																			label: 'Uma única vez',
																			value: DailyTransferFrequency.ONCE,
																		},
																		{
																			label: 'Periodicamente',
																			value: DailyTransferFrequency.MULTIPLE,
																		},
																	]}
																/>
															</Form.Item>
														);
													}
													if (
														getFieldValue(
															'frequency',
														) ===
														TransferFrequency.WEEKLY
													) {
														return (
															<Form.Item
																name="weekly_transfer_time"
																label={
																	<TextS>
																		Horário
																		da
																		transferência
																	</TextS>
																}
																rules={[
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]}
															>
																<TimePicker
																	disabled={
																		isEdit
																	}
																	style={{
																		width: '100%',
																	}}
																	minuteStep={
																		30
																	}
																	showSecond={
																		false
																	}
																	inputReadOnly
																	format="HH:mm"
																	needConfirm={
																		false
																	}
																/>
															</Form.Item>
														);
													}
												}}
											</Form.Item>
										</Col>
									</Row>
									{isDaily &&
										getFieldValue(
											'daily_transfer_frequency',
										) === DailyTransferFrequency.ONCE && (
											<Row gutter={[10, 10]}>
												<Col span={12}>
													<Form.Item
														label={
															<TextS>
																Horário da
																transferência
															</TextS>
														}
														name="daily_transfer_time"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<TimePicker
															disabled={isEdit}
															style={{
																width: '100%',
															}}
															minuteStep={30}
															showSecond={false}
															needConfirm={false}
															inputReadOnly
															format="HH:mm"
														/>
													</Form.Item>
												</Col>
											</Row>
										)}

									{isDaily &&
										getFieldValue(
											'daily_transfer_frequency',
										) ===
											DailyTransferFrequency.MULTIPLE && (
											<Row
												gutter={[10, 10]}
												style={{
													marginBottom: '1.5rem',
												}}
											>
												<Col span={8}>
													<Form.Item
														label={
															<TextS>
																Horário de
																Início
															</TextS>
														}
														name="transfer_window_start"
													>
														<TimePicker
															disabled={isEdit}
															style={{
																width: '100%',
															}}
															minuteStep={30}
															showSecond={false}
															format="HH:mm"
															needConfirm={false}
															inputReadOnly
														/>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item
														label={
															<TextS>
																Horário Fim
															</TextS>
														}
														name="transfer_window_end"
													>
														<TimePicker
															disabled={isEdit}
															style={{
																width: '100%',
															}}
															minuteStep={30}
															showSecond={false}
															needConfirm={false}
															format="HH:mm"
															inputReadOnly
															disabledTime={() => {
																return {
																	disabledHours:
																		() => {
																			return Array.from(
																				{
																					length: getFieldValue(
																						'transfer_window_start',
																					).hour(),
																				},
																				(
																					_,
																					index,
																				) =>
																					index,
																			);
																		},
																	disabledMinutes:
																		hour => {
																			if (
																				hour ===
																				getFieldValue(
																					'transfer_window_start',
																				).hour()
																			) {
																				return Array.from(
																					{
																						length: getFieldValue(
																							'transfer_window_start',
																						).minute(),
																					},
																					(
																						_,
																						index,
																					) =>
																						index,
																				);
																			}

																			return [];
																		},
																};
															}}
														/>
													</Form.Item>
												</Col>
												<Col span={8}>
													<Form.Item
														label={
															<TextS>
																A cada
															</TextS>
														}
														name="transfer_interval"
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															disabled={isEdit}
															placeholder="Selecione"
															showSearch
															options={
																requestTime
															}
														/>
													</Form.Item>
												</Col>
											</Row>
										)}

									{getFieldValue('transfer_method') &&
										getFieldValue('frequency') &&
										(getFieldValue(
											'daily_transfer_frequency',
										) ||
											getFieldValue(
												'weekly_transfer_time',
											)) && (
											<Row>
												<TextS>{`${isDaily ? 'Dias' : 'Dia'} da semana:`}</TextS>
												{isTed && (
													<Flex
														gap={10}
														align="center"
														style={{
															marginLeft: '10px',
														}}
													>
														<Tooltip title="Transferências do tipo TED podem não ocorrer em dia não útil">
															<BsExclamationTriangle
																style={{
																	color: theme.attention,
																}}
															/>
														</Tooltip>
													</Flex>
												)}

												<Col style={{ width: '100%' }}>
													<Flex
														justify={
															getFieldValue(
																'transfer_method',
															) !==
															TransferMethod.TED
																? 'space-between'
																: 'flex-start'
														}
													>
														{dayOfWeek
															.map(day => (
																<Form.Item valuePropName="checked">
																	{getFieldValue(
																		'frequency',
																	) ===
																		TransferFrequency.DAILY && (
																		<Flex
																			gap={
																				10
																			}
																			style={{
																				minWidth:
																					isTed
																						? '7rem'
																						: '',
																			}}
																		>
																			<Checkbox
																				disabled={
																					isEdit
																				}
																				checked={selectedWeekdays.includes(
																					day.value,
																				)}
																				onChange={e => {
																					if (
																						!e
																							.target
																							.checked
																					) {
																						updateSelectWeekdays(
																							selectedWeekdays.filter(
																								selectedDay =>
																									selectedDay !==
																									day.value,
																							),
																						);
																						return;
																					}
																					updateSelectWeekdays(
																						[
																							...selectedWeekdays,
																							day.value,
																						],
																					);
																				}}
																			/>

																			<TextS>
																				{
																					day.label
																				}
																			</TextS>
																		</Flex>
																	)}
																	{getFieldValue(
																		'frequency',
																	) ===
																		TransferFrequency.WEEKLY && (
																		<Radio
																			disabled={
																				isEdit
																			}
																			checked={selectedWeekdays.includes(
																				day.value,
																			)}
																			onChange={e => {
																				updateSelectWeekdays(
																					[
																						day.value,
																					],
																				);
																			}}
																			style={{
																				minWidth:
																					isTed
																						? '6.5rem'
																						: '',
																			}}
																		>
																			<TextS>
																				{
																					day.label
																				}
																			</TextS>
																		</Radio>
																	)}
																</Form.Item>
															))
															.filter(
																(_, index) => {
																	if (
																		isTed &&
																		[
																			0,
																			6,
																		].includes(
																			index,
																		)
																	) {
																		return false;
																	}
																	return true;
																},
															)}
													</Flex>
												</Col>
											</Row>
										)}
								</Row>
							</>
						);
					}}
				</Form.Item>
			</Form>
			<Footer
				onDefaultButtonClick={onPrevious}
				onPrimaryButtonClick={form.submit}
			/>
		</>
	);
};

export default TransferRuleConfig;
