import * as React from 'react';

const Confirmation: React.FC<{ color?: string }> = ({ color }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
		<path
			stroke={color ?? '#00CC21'}
			d="M18 35a17 17 0 1 1 17-17 17.02 17.02 0 0 1-17 17Z"
		/>
		<path
			fill={color ?? '#00CC21'}
			fillRule="evenodd"
			d="M23.21 12.528a1.2 1.2 0 0 1 .864-.362c.322.003.63.139.858.378.228.239.358.562.36.9.003.338-.12.663-.345.906l-.004.005-8.676 9.099a1.23 1.23 0 0 1-.399.28 1.18 1.18 0 0 1-1.339-.278l-3.475-3.644a1.323 1.323 0 0 1-.345-.907c.003-.338.132-.661.36-.9.228-.24.536-.375.858-.378a1.2 1.2 0 0 1 .865.362l.005.005 2.6 2.727 7.812-8.193Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default Confirmation;
