import { CustomDrawer } from 'components/CustomDrawer';
import { Col, DatePicker, Form, Row } from 'antd';
import { LuSearch } from 'react-icons/lu';

import dayjs from 'dayjs';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { TextM } from 'components/Text';
import { useTheme } from 'styled-components';
import {
	scheduledPostingsDateFromRule,
	scheduledPostingsDateToRule,
	statementDateFromRule,
	statementDateToRule,
} from 'helpers/rules';
import { localeDatePicker } from 'helpers/dates';

export enum DateFilterType {
	STATEMENT = 'STATEMENT',
	SCHEDULED_POSTINGS = 'SCHEDULED_POSTINGS',
}

export interface DateFilterDrawerProps {
	isOpen: boolean;
	filterType: DateFilterType;
	onClose: () => void;
	handleFilter: (filter: any) => void;
}

const INITIAL_VALUES = {
	[DateFilterType.STATEMENT]: {
		dateFrom: dayjs().subtract(6, 'days'),
		dateTo: dayjs(),
	},
	[DateFilterType.SCHEDULED_POSTINGS]: {
		dateFrom: dayjs(),
		dateTo: dayjs().add(6, 'days'),
	},
};

const DateFilterValidation = {
	[DateFilterType.STATEMENT]: {
		dateFrom: statementDateFromRule,
		dateTo: statementDateToRule,
	},
	[DateFilterType.SCHEDULED_POSTINGS]: {
		dateFrom: scheduledPostingsDateFromRule,
		dateTo: scheduledPostingsDateToRule,
	},
};

const DateFilterDrawer = ({
	isOpen,
	filterType,
	onClose,
	handleFilter,
}: DateFilterDrawerProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();

	return (
		<CustomDrawer onClose={onClose} open={isOpen} title="Filtrar data">
			<Col
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<Form
					form={form}
					layout="vertical"
					initialValues={{
						dateFrom: INITIAL_VALUES[filterType].dateFrom,
						dateTo: INITIAL_VALUES[filterType].dateTo,
					}}
					onFinish={values => {
						handleFilter({
							dateFrom: values.dateFrom,
							dateTo: values.dateTo,
						});

						onClose();
					}}
				>
					<Form.Item
						name="dateFrom"
						label="Data inicial"
						rules={[
							{
								required: true,
								message: 'Campo obrigatório',
							},
							DateFilterValidation[filterType].dateFrom,
						]}
					>
						<DatePicker
							style={{
								width: '100%',
							}}
							format={'DD/MM/YYYY'}
							locale={localeDatePicker}
						/>
					</Form.Item>
					<Form.Item
						name="dateTo"
						label="Data final"
						rules={[
							{
								required: true,
								message: 'Campo obrigatório',
							},
							DateFilterValidation[filterType].dateTo,
						]}
					>
						<DatePicker
							style={{
								width: '100%',
							}}
							format={'DD/MM/YYYY'}
							locale={localeDatePicker}
						/>
					</Form.Item>
				</Form>
				<Row style={{ width: '100%' }}>
					<ButtonPrimary
						onClick={form.submit}
						style={{ padding: '1.4rem' }}
					>
						<TextM style={{ color: theme.white, margin: '0 auto' }}>
							Buscar
						</TextM>
						<LuSearch size="1.3rem" color={theme.white} />
					</ButtonPrimary>
				</Row>
			</Col>
		</CustomDrawer>
	);
};

export default DateFilterDrawer;
