import styled, { css } from 'styled-components';

export const CollapseWrapper = styled.div`
	width: 100%;
	border-bottom: 1px solid ${({ theme }) => theme.border};
`;

export const CollapseHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;

	p {
		font-size: 18px;
		font-weight: 500;
		color: ${({ theme }) => theme.text};
	}
`;

export const CollapseBody = styled.div<{ isOpen: boolean }>`
	overflow: hidden;
	transition: max-height 0.3s ease;
	max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
	padding: ${({ isOpen }) => (isOpen ? '14px 0' : '0')};
`;
