import { PageWrapper } from 'components/PageWrapper';
import styled from 'styled-components';
import { StyleConstants } from 'theme/StyleConstants';

export const DefaultTemplateWrapper = styled.div`
	max-width: 100vw;
	width: 100%;
	min-height: 100vh;
	display: flex;
`;

export const DefaultTemplateContent = styled.div`
	max-height: calc(
		100vh - calc(
				${StyleConstants.NAV_BAR_HEIGHT} +
					${StyleConstants.BREADCRUMB_HEIGHT}
			)
	);

	overflow-x: hidden;
	padding: 0 0 1rem;

	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 30px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: ${p => p.theme.scrollColorHover};
	}
`;

export const DefaultTemplateContainer = styled.div<{ isCollapsed: boolean }>`
	width: calc(
		100vw -
			${p =>
				p.isCollapsed
					? StyleConstants.SIDE_BAR_WIDTH_COLLAPSED
					: StyleConstants.SIDE_BAR_WIDTH}
	);
	border-left: 2px solid ${p => p.theme.borderLight};
	background-color: ${p => p.theme.white};
	box-sizing: border-box;
	overflow: hidden;
	max-height: 100vh;

	${PageWrapper} {
		width: calc(
			100vw -
				${p =>
					p.isCollapsed
						? StyleConstants.SIDE_BAR_WIDTH_COLLAPSED
						: StyleConstants.SIDE_BAR_WIDTH}
		) !important;
	}

	${DefaultTemplateContent} {
		width: calc(
			100vw -
				${p =>
					p.isCollapsed
						? StyleConstants.SIDE_BAR_WIDTH_COLLAPSED
						: StyleConstants.SIDE_BAR_WIDTH}
		);
	}
`;

export const TermsPageWrapper = styled.div`
	padding: 0 calc(100vw - 90%);
	padding-bottom: 5rem;
`;
