import { Card } from 'antd';
import { Table } from 'components/Table';
import styled from 'styled-components';

export const DestinationTable = styled(Table)`
	th::before {
		display: none;
	}
`;

export const WarningCard = styled(Card)`
	background-color: ${({ theme }) => theme.dangerOpacity};
	width: 100%;
	padding: 1rem;

	.ant-card-body {
		padding: 0;
	}
`;
