import styled from 'styled-components';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { StyleConstants } from 'theme/StyleConstants';

export const BreadcrumbContainer = styled.div`
	display: flex;
	align-items: center;
	span {
		display: block;
		margin-left: 0.4rem;
	}
	svg {
		max-width: 1.5rem;
		max-height: 1.5rem;
		fill: ${p => p.theme.textGray};
		color: ${p => p.theme.textGray};
		opacity: 0.6;
	}
`;

export const BreadcrumbWrapper = styled.div`
	border-bottom: 1px solid ${p => p.theme.background};
	height: ${StyleConstants.BREADCRUMB_HEIGHT};
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0.3rem 1rem 0;
	background-color: ${p => p.theme.white};
	z-index: 102;

	.ant-breadcrumb-link {
		height: 27px;
	}

	span {
		font-size: 1rem;
		color: ${p => p.theme.text};
		font-weight: 500;
		line-height: 12px;
	}
`;

export const BreadcrumbComponent = styled(AntdBreadcrumb)`
	align-items: center;
	ol {
		align-items: center;
	}

	.ant-breadcrumb-separator {
		line-height: 12px;
	}

	li:first-child {
		span {
			color: ${p => p.theme.textGray} !important;
		}
	}
`;
