import { Heading1 } from 'components/Heading1';
import { PageHeader } from 'components/PageHeader';
import { PagePanelWrapper } from 'components/PageWrapper';
import {
	Bold,
	ButtonWrapper,
	Email,
	StyledTexts,
	StyledTitle,
	Topic,
} from './styles';
import { Button, Col, notification, Row, Spin } from 'antd';
import { useContext } from 'react';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { UsersService } from 'modules/users/services';

const ReadTermsPage = () => {
	const { userProfile } = useContext(ProfileContext);
	const [api, contextHolder] = notification.useNotification();

	const colGap = '60px';
	const subColGap = '40px';

	// 1.
	const definitions = [
		{
			title: '“Administradora”:',
			text: 'Significa a pessoa física ou jurídica indicada no Contrato para atuar como gestora da Conta Vinculada, para assegurar o controle do fluxo de recebimento e movimentação dos recursos nela depositados.',
		},
		{
			title: '“Conta Beneficiária Terceira”:',
			text: 'Significa a conta cadastrada pelo Usuário Administrador para recebimento de recursos oriundos da Conta Vinculada.',
		},
		{
			title: '“Conta Vinculada”:',
			text: 'Significa a escrow account criada em nome da Contratante e gerida pela Administradora, por meio do Contrato, com o propósito de operacionalizar certos pagamentos e garantir a movimentação de recursos.',
		},
		{
			title: '“Contratante”:',
			text: 'Significa a pessoa física ou jurídica que firmou Contrato com a CELCOIN para a abertura, manutenção, movimentação e administração de uma Conta Vinculada de sua titularidade.',
		},
		{
			title: '“Contrato:”',
			text: 'Significa o Contrato de Abertura de Conta Escrow Vinculada e Outras Avenças firmado entre a CELCOIN, o Contratante e a Administradora para regular os termos aplicáveis à abertura, manutenção, movimentação e administração da Conta Vinculada.',
		},
		{
			title: '“Portal Escrow”:',
			text: 'Significa a plataforma digital disponibilizada pela CELCOIN para a abertura, manutenção, movimentação e administração de uma Conta Vinculada de titularidade do Contratante e gerida pela Administradora.',
		},
		{
			title: '“Usuário”:',
			text: 'Significa toda a pessoa física vinculada ao Contratante ou à Administradora, que obtém, por meio deste Termo, uma licença para acessar e utilizar o Portal Escrow. Para fins de clareza, a definição de Usuário engloba os Usuários Administradores e os Usuários do Contratante.',
		},
		{
			title: '“Usuário Administrador”:',
			text: 'Significa o Usuário vinculado à Administradora que acessa e utiliza o Portal Escrow enquanto gestor da Conta Vinculada do Contratante.',
		},
		{
			title: '“Usuário do Contratante”:',
			text: 'Significa o Usuário que acessa e utiliza o Portal Escrow enquanto representante do Contratante.',
		},
	];

	// 2.
	const portal = [
		{
			title: 'Visualização de Saldo ',
			italic: '(disponível para Usuários Administradores e Usuários do Contratante):',
			text: 'Ao acessar o Portal Escrow, os Usuários poderão verificar, no dashboard da tela inicial, as informações principais da Conta Vinculada, incluindo o seu saldo atualizado.',
		},
		{
			title: 'Cadastro de Contas ',
			italic: '(disponível apenas para Usuários Administradores):',
			text: 'O Usuário Administrador poderá cadastrar todas as Contas Vinculadas às quais estiver atrelado por força dos Contratos que celebrou, bem como as Contas Beneficiárias Terceiras que receberão recursos de cada Conta Vinculada. No dashboard da tela inicial, o Usuário Administrador terá acesso às informações de todas as suas Contas Vinculadas, podendo acessar os seus detalhes e extratos, bem como de solicitar os seus bloqueios e cancelamentos. Além disso, para facilitar a navegação e a localização rápida de uma Conta Vinculada, os Usuários Administradores poderão aplicar filtros de busca de acordo com o CPF/CNPJ ou nome/razão social do Contratante (titular da Conta Vinculada), e o número ou o status da Conta Vinculada.',
		},
		{
			title: 'Aprovação de Transações ',
			italic: '(disponível apenas para Usuários Administradores):',
			text: 'Todas as transações envolvendo uma Conta Vinculada e uma Conta Beneficiária Terceira poderão ser aprovadas e monitoradas pelo Portal Escrow. Além de aprovar ou rejeitar uma transação diretamente no Portal Escrow, o Usuário Administrador poderá filtrar as transações relacionadas a uma Conta Vinculada de acordo com o seu status (“pendentes”, “aprovadas” ou “rejeitadas”).',
		},
		{
			title: 'Extrato ',
			italic: '(disponível apenas para Usuários Administradores):',
			text: 'Na dashboard de extratos do Portal Escrow, os Usuários poderão visualizar todos os lançamentos passados relacionados a uma Conta Vinculada, bem como programar e cancelar lançamentos futuros. Todos os lançamentos passados e futuros de uma Conta Vinculada poderão ser visualizados e filtrados por critérios de entradas e saídas (ou seja, recebimentos ou pagamentos) e por períodos temporais.',
		},
		{
			title: 'Transferências ',
			italic: '(disponível apenas para Usuários Administradores):',
			text: 'Os Usuários Administradores também poderão realizar transferências diretamente no Portal Escrow. Para isso, basta acessar o dashboard de extratos, selecionar o item “nova transferência”, preencher as informações requeridas sobre a Conta Beneficiária Terceira (nome/razão social do beneficiário, conta de destino, data de transferência e valor) e autenticar a sua identidade com um token.',
		},
		{
			title: 'Demais funcionalidades ',
			italic: '(disponível apenas para Usuários Administradores):',
			text: 'O Portal Escrow também oferece aos Usuários Administradores as opções de (i) baixar comprovantes de transferência, (ii) conferir a lista de acessos, na qual estarão indicados todos os Usuários e acessaram o Portal Escrow em relação a uma determinada Conta Vinculada e (iii) criar novos Usuários, indicando os seus dados de identificação e indicando as funcionalidades, dentre as listadas acima, que cada um terá permissão para acessar.',
		},
	];

	// 3.
	const access = [
		{
			number: '3.1',
			text: 'O uso do Portal Escrow dependerá necessariamente de equipamentos e dispositivos conectados a uma rede de internet, que deverão ser independentemente obtidos e contratados pelo Usuário. O acesso e a utilização do Portal Escrow podem ser prejudicados em razão da versão do sistema operacional do dispositivo do Usuário ou das próprias condições técnicas dos dispositivos que o Usuário utiliza. A adequação e atualização de dispositivos, navegadores ou versões de sistemas operacionais para o funcionamento do Portal Escrow são responsabilidade do Usuário.',
		},
		{
			number: '3.2',
			text: 'A CELCOIN se reserva o direito de bloquear a conta do Usuário sem aviso prévio, por motivos de segurança, em caso de suspeita de fraude, ato ilícito, utilização de dados de terceiros, ou qualquer outra violação deste Termo, da Política de Privacidade ou da legislação e regulamentação aplicável.',
		},
		{
			number: '3.3',
			text: 'Para acessar o Portal Escrow, o Usuário deverá utilizar os seus dados de login e senha. Cada Usuário é única e exclusivamente responsável pelo seu login e senha, que são confidenciais, pessoais e intransferíveis. O Usuário está ciente de que o acesso de terceiros à sua conta no Portal Escrow poderá dar a esse terceiro acesso a informações confidenciais, além de lhe permitir operar o Portal Escrow e realizar as transações previstas no item 2.1 deste Termo, razão pela qual o login e a senha não devem ser compartilhados com terceiros em nenhuma circunstância. Todo e qualquer dano, prejuízo ou ônus decorrente do acesso indevido de terceiros à conta do Usuário será suportado exclusivamente pelo Usuário titular da conta em questão, que deverá defender e manter indenes a CELCOIN, a Administradora e o Contratante, conforme aplicável, na referida situação.',
		},
	];
	const accessPartTwo = [
		{
			number: '3.5',
			text: 'A CELCOIN não se responsabiliza pelo uso indevido, por qualquer Usuário, do Portal Escrow ou das informações e ferramentas disponibilizadas nele, para quaisquer que sejam os fins, bem como não se responsabiliza pelos danos e prejuízos de qualquer natureza que possam advir da utilização indevida do Portal Escrow.',
		},
		{
			number: '3.6',
			text: 'Eventuais lesões aos direitos da CELCOIN, de outros Usuários, da Administradora, do Contratante ou de terceiros serão de inteira responsabilidade do Usuário que as causou, devendo ele responder pelos danos e/ou prejuízos de qualquer natureza decorrentes do descumprimento de qualquer uma das obrigações deste Termo ou das leis e regulamentações aplicáveis.',
		},
		{
			number: '3.7',
			text: 'A CELCOIN poderá suspender ou interromper temporariamente o acesso ao Portal Escrow, ao seu exclusivo critério, para realização de manutenções preventivas ou corretivas, inclusive sem aviso prévio, sem que isso enseje qualquer direito de indenização ao Usuário.',
		},
		{
			number: '3.8',
			text: 'A CELCOIN poderá, ao seu exclusivo critério, a qualquer tempo, e sem a necessidade de comunicação prévia, acrescentar, excluir ou modificar as funcionalidades e os conteúdos oferecidos no Portal Escrow, assim como o próprio Portal Escrow. A CELCOIN não se compromete a manter ativa qualquer ferramenta, funcionalidade ou serviço que já tenha sido oferecido no passado por meio do Portal Escrow, independentemente de ter sido oferecido de forma onerosa ou gratuita.',
		},
	];

	// 4.
	const protectionData = [
		{
			number: '4.1',
			text: 'O acesso e utilização do Portal Escrow sujeitam o Usuário ao tratamento de determinadas informações de sua titularidade, incluindo dados pessoais, nos termos da Lei Geral de Proteção de Dados. Os dados pessoais do Usuário serão tratados da maneira indicada na Política de Privacidade da CELCOIN',
		},
		{
			number: '4.2',
			text: 'Ao realizar seu cadastro no Portal Escrow, o Usuário concorda com a Política de Privacidade e com o tratamento de seus dados pessoais pela CELCOIN. O aceite da Política de Privacidade representará a ciência de seus termos e autorizará a CELCOIN a tratar os dados pessoais dos Usuários tal como descrito no documento. A Política de Privacidade será considerada parte integral e indissociável deste Termo.',
		},
	];

	// 5.
	const intelectualPropriety = [
		{
			number: '5.1',
			text: 'Este Termo concede ao Usuário uma licença pessoal, mundial, revogável, não exclusiva e intransferível de uso do Portal Escrow exclusivamente para as finalidades previstas neste instrumento. Dessa forma, este Termo não autoriza que o Usuário copie, modifique, distribua, venda ou alugue o Portal Escrow, tampouco os serviços e os conteúdos da CELCOIN e de outros Usuários disponibilizados e/ou viabilizados pelo Portal Escrow.',
		},
		{
			number: '5.2',
			text: 'O Usuário não adquire, por meio deste Termo, nenhum direito de propriedade intelectual ou outros direitos, incluindo patentes, desenhos, bases de dados, marcas, direitos autorais, conteúdos ou direitos sobre informações confidenciais ou segredos de negócio, sobre ou relacionados ao Portal Escrow, os quais são e continuarão sendo de propriedade exclusiva da CELCOIN.',
		},
		{
			number: '5.3',
			text: 'Todos e quaisquer direitos de propriedade intelectual relacionados ao Portal Escrow ou ao conteúdo da CELCOIN disponibilizado no Portal Escrow, tais como, mas não se limitando a, marcas, textos, imagens, vídeos, sons, ilustrações, gráficos, dados, logos, ícones, fotografias, conteúdo editorial, notificações, softwares, programas de computador, códigos-fonte, ícones, trade-dress e qualquer outro material, incluindo a seleção e a organização desses materiais, pertencem, e continuarão pertencendo, exclusivamente à CELCOIN ou suas licenciantes, e são protegidos pela lei brasileira no que se refere à propriedade intelectual e aos direitos autorais, sendo que a aceitação deste Termo pelo Usuário não atribui a ele nenhum direito sobre tais ativos para além dos direitos expressamente previstos. O uso indevido e a reprodução total ou parcial dos referidos ativos são proibidos, salvo com a autorização prévia e expressa da CELCOIN.',
		},
		{
			number: '5.4',
			text: 'O Usuário está ciente e concorda que todo conteúdo enviado para a CELCOIN ou para o Portal Escrow (incluindo avaliações, feedbacks, informações, dados e interações, depoimentos, sugestões, reclamações, ideias, informações, comentários, contatos e interações por qualquer meio com a CELCOIN ou com outros Usuários por meio do Portal Escrow) passa a pertencer automaticamente à CELCOIN, a qual será a única e exclusiva titular dos direitos de propriedade intelectual sobre referido conteúdo. Caso a cessão dos direitos de propriedade intelectual sobre o conteúdo criado pelo Usuário e compartilhado com a CELCOIN ou com outros Usuários por meio do Portal Escrow seja vedada pelas leis aplicáveis, o Usuário, desde já, concede à CELCOIN uma licença exclusiva, global, gratuita, ilimitada, não revogável e válida por prazo perpétuo (ou pelo período máximo de proteção legal), para uso e exploração do conteúdo, da forma como melhor lhe aprouver, e a seu exclusivo critério.',
		},
	];

	// 6.
	const responsibility = [
		{
			number: '6.2',
			text: 'Nada do que está escrito neste Termo tem como intenção excluir ou limitar qualquer condição, garantia, direito ou responsabilidade que não possa ser legalmente excluída ou limitada. Consequentemente, apenas serão aplicáveis as limitações que forem permitidas por lei na jurisdição do Usuário. Nos casos em que exclusão de responsabilidade não se mostrar possível, mas a limitação de responsabilidade for legalmente aplicável, a responsabilidade total da CELCOIN estará limitada a R$ 1.000,00 (mil reais).',
		},
		{
			number: '6.3',
			text: 'O Usuário concorda em defender, indenizar e manter indene a CELCOIN e suas Afiliadas, diretores, empregados, investidores e agentes, de e contra quaisquer encargos, ações ou demandas, incluindo, mas não limitado a custos judiciais e honorários advocatícios razoáveis, resultantes: (i) da eventual utilização indevida do Portal Escrow e/ou do seu conteúdo; (ii) da violação deste Termo, da Política de Privacidade ou das leis e regulamentações aplicáveis; e/ou (iv) de quaisquer demandas relacionadas a qualquer ação ou omissão ou de qualquer outra forma relacionada à utilização, pelo Usuário, do Portal Escrow.',
		},
		{
			number: '6.4',
			text: 'EM NENHUM CASO A CELCOIN SERÁ RESPONSÁVEL POR DANOS INDIRETOS OU A TERCEIROS DECORRENTES DESTE TERMO, INCLUSIVE POR LUCROS CESSANTES OU PERDA DE RECEITA DE QUALQUER NATUREZA PELO USUÁRIO, PELA ADMINISTRADORA OU PELO CONTRATANTE, INCLUINDO, AINDA, AQUELES OCASIONADOS POR CORRUPÇÃO OU PERDA DE DADOS, FALHA DE TRANSMISSÃO OU RECEPÇÃO DE DADOS, NÃO CONTINUIDADE DO NEGÓCIO OU QUALQUER OUTRO PREJUÍZO OU PERDA COMERCIAL, DECORRENTES OU RELACIONADOS AO USO OU INABILIDADE EM USAR O PORTAL ESCROW, POR QUALQUER OUTRO MOTIVO.',
		},
	];

	// 7.
	const termination = [
		{
			number: '7.1',
			text: 'Este Termo permanecerá válido por prazo indeterminado, enquanto perdurar a relação comercial firmada entre CELCOIN, Administradora e Contratante por meio do Contrato. A rescisão ou término do Contrato, a qualquer momento e independente do motivo, ensejará a imediata revogação deste Termo e da licença de uso do Portal Escrow outorgada ao Usuário.',
		},
		{
			number: '7.2',
			text: 'Em qualquer caso de término deste Termo, o Usuário permanecerá obrigado ao cumprimento de suas condições até o efetivo término da relação comercial, sem prejuízo do cumprimento das obrigações que devam sobreviver ao seu término.',
		},
	];

	// 8.
	const generalInfo = [
		{
			number: '8.1',
			text: 'A CELCOIN poderá, a qualquer momento, alterar qualquer das disposições deste Termo, mediante comunicação ao Usuário, que poderá ser realizada por meio do Portal Escrow ou outros métodos estabelecidos pela CELCOIN.',
		},
		{
			number: '8.2',
			text: 'Este Termo será regido pelas leis vigentes da República Federativa do Brasil, e as Partes deste documento se submetem à escolha da jurisdição estipulada no Contrato com relação a quaisquer disputas ou reivindicações, de qualquer forma, decorrentes deste termo, incluindo disputas relativas à sua existência, validade, término ou as consequências de sua nulidade.',
		},
		{
			number: '8.3',
			text: 'Caso qualquer disposição deste Termo seja considerada nula, inválida ou inexequível, as disposições remanescentes permanecerão válidas e em vigor. A disposição nula, inválida ou inexequível deve ser alterada para garantir a sua validade e eficácia, preservando as intenções das Partes.',
		},
		{
			number: '8.4',
			text: 'Qualquer falha da CELCOIN em impor ou exercer qualquer disposição deste Termo constitui mera liberalidade e não uma renúncia a esse direito ou disposição.',
		},
		{
			number: '8.5',
			text: 'A CELCOIN pode ceder ou transferir este Termo mediante simples comunicação ao Usuário. Ainda, a CELCOIN poderá efetuar qualquer alteração de seu controle societário sem a necessidade de uma autorização do Usuário ou envio de uma comunicação a este. O Usuário, por sua vez, fica proibido de ceder ou de qualquer forma transferir este Termo e as obrigações dele decorrentes.',
		},
	];

	const renderBoldAndText = (texts: { title: string; text: string }[]) => {
		return texts.map(item => (
			<>
				<Bold>{item.title}</Bold>
				<p>{item.text}</p>
			</>
		));
	};

	const renderBoldItalicText = (
		texts: { title: string; italic: string; text: string }[],
	) => {
		return texts.map(item => (
			<>
				<Bold>
					{item.title}
					<i>{item.italic}</i>
				</Bold>
				<p>{item.text}</p>
			</>
		));
	};

	const renderText = (texts: { number: string; text: string }[]) => {
		return texts.map(item => (
			<Row wrap={false}>
				<Col flex={colGap}>
					<StyledTexts>{item.number}</StyledTexts>
				</Col>
				<Col flex="auto">
					<StyledTexts>
						<p style={{ margin: 0 }}>{item.text}</p>
					</StyledTexts>
				</Col>
			</Row>
		));
	};

	const { mutate, isPending } = useMutation<void, ApiError>({
		mutationFn: () => {
			return UsersService.acceptPortalTerms();
		},
		onSuccess: () => {
			window?.location?.reload?.();
		},
		onError: () => {
			api.error({
				message: 'Ocorreu um erro ao aceitar os termos.',
			});
		},
	});

	const handleAcceptTerms = () => {
		mutate();
	};

	return (
		<>
			{contextHolder}
			<PageHeader>
				<Heading1>Termos e Condições</Heading1>
			</PageHeader>
			<PagePanelWrapper>
				<StyledTitle>
					TERMO E CONDIÇÕES DE USO DO PORTAL ESCROW
				</StyledTitle>
				<StyledTexts>
					<p>
						Pelo presente Termo e Condições de Uso do Portal Escrow
						(“Termo”), a{' '}
						<Bold>
							CELCOIN INSTITUIÇÃO DE PAGAMENTO S.A. (“CELCOIN”)
						</Bold>
						, sociedade empresária limitada, inscrita no CNPJ/MF sob
						o nº 13.935.893/0001-09, com sede na Alameda Xingu nº
						350, Conj. 1604, Alphaville Industrial, na cidade de
						Barueri, Estado de São Paulo, CEP: 06455-030, neste ato
						representada na forma de seu contrato social, estabelece
						ao Usuário (conforme definição abaixo) as seguintes
						regras e condições de uso do Portal Escrow.
					</p>
					<p>
						O usuário deverá ler este Termo com atenção, pois o uso
						do Portal Escrow está sujeito à compreensão, à
						concordância e ao aceite deste Termo, bem como com da
						Política de Privacidade da CELCOIN (“Política de
						Privacidade”), disponível em
						https://www.celcoin.com.br/compliance-e-regulatorio/.
						Caso não concorde com este Termo ou com a Política de
						Privacidade, o Usuário deve abster-se de utilizar o
						Portal Escrow.
					</p>
					<p>
						Caso o Usuário tenha qualquer dúvida sobre este Termo
						e/ou a Política de Privacidade, recomendamos que entre
						em contato com a CELCOIN antes de realizar seu aceite
						por meio do e-mail <Email>escrow@celcoin.com.br</Email>.
						Teremos satisfação de esclarecer eventuais dúvidas.
					</p>
				</StyledTexts>

				<Topic style={{ marginTop: '-15px' }}>1. Definições</Topic>
				<Row wrap={false}>
					<Col flex={colGap}>
						<StyledTexts>1.1</StyledTexts>
					</Col>
					<Col flex="auto">
						<StyledTexts>
							<p style={{ marginTop: 0 }}>
								Para o entendimento e interpretação do presente
								Termo, as palavras com letras iniciais
								maiúsculas, seja no plural ou no singular, terão
								os significados a elas atribuídos abaixo:
							</p>
							{renderBoldAndText(definitions)}
						</StyledTexts>
					</Col>
				</Row>

				<Topic style={{ marginTop: '-15px' }}>2. Portal Escrow</Topic>
				<Row wrap={false}>
					<Col flex={colGap}>
						<StyledTexts>2.1</StyledTexts>
					</Col>
					<Col flex="auto">
						<StyledTexts>
							<p style={{ marginTop: 0 }}>
								O Portal Escrow é a plataforma ideal para
								empresas ou pessoas que buscam segurança e
								transparência para transações financeiras e
								proteção para fundos e ativos até que todos os
								termos de um acordo sejam atendidos. Nele, são
								disponibilizadas diversas funcionalidades
								relacionadas à abertura, manutenção,
								movimentação e administração de Contas
								Vinculadas, conforme especificado a seguir:
							</p>
							{renderBoldItalicText(portal)}
						</StyledTexts>
					</Col>
				</Row>

				<Topic style={{ marginTop: '-15px' }}>
					3. Regras de acesso e uso do Portal Escrow
				</Topic>
				{renderText(access)}
				<Row wrap={false}>
					<Col flex={colGap}>
						<StyledTexts>3.4</StyledTexts>
					</Col>
					<Col flex="auto">
						<StyledTexts>
							<p style={{ margin: 0 }}>O Usuário não poderá:</p>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(i)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											fazer uso do Portal Escrow para
											finalidade diversa daquela para qual
											foi disponibilizada pela CELCOIN ou
											de maneira que, de qualquer forma,
											implique violações da legislação
											aplicável, deste Termo ou da
											Política de Privacidade;
										</p>
									</StyledTexts>
								</Col>
							</Row>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(ii)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											copiar, ceder, licenciar,
											sublicenciar, vender, alugar, dar em
											locação ou em garantia, distribuir,
											compartilhar, reproduzir, doar,
											alienar de qualquer forma,
											disponibilizar, dar acesso ao Portal
											Escrow ou transferir total ou
											parcialmente, sob quaisquer
											modalidades, gratuita ou
											onerosamente, provisória ou
											permanentemente, o software do
											Portal Escrow, assim como seus
											módulos, partes, manuais ou
											quaisquer informações relativas a
											ele, incluindo todo e qualquer
											conteúdo da CELCOIN ou de outros
											Usuários; e/ou
										</p>
									</StyledTexts>
								</Col>
							</Row>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(iii)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											decompilar ou promover, e/ou
											facilitar, qualquer engenharia
											reversa, acesso indevido, ou
											tentativa de engenharia reversa ou
											acesso indevido, ao código fonte do
											Portal Escrow, bem como empregar
											softwares, técnicas e/ou artifícios
											com o intuito de utilizar
											indevidamente o Portal Escrow para
											práticas nocivas à CELCOIN, a outros
											Usuários, à Administradora, ao
											Contratante ou a terceiros, tais
											como hacking, scraping, crawling,
											exploits, spamming, flooding,
											spoofing, crashing, root kits, etc.
										</p>
									</StyledTexts>
								</Col>
							</Row>
						</StyledTexts>
					</Col>
				</Row>
				{renderText(accessPartTwo)}

				<Topic>4. Proteção de dados</Topic>
				{renderText(protectionData)}

				<Topic>5. Propriedade Intelectual</Topic>
				{renderText(intelectualPropriety)}

				<Topic>6. Limitações de responsabilidade</Topic>
				<Row wrap={false}>
					<Col flex={colGap}>
						<StyledTexts>6.1</StyledTexts>
					</Col>
					<Col flex="auto">
						<StyledTexts>
							<p style={{ margin: 0 }}>
								DE MANEIRA GERAL E NA MÁXIMA EXTENSÃO PERMITIDA
								PELA LEI, A CELCOIN SE EXIME DE QUAISQUER
								GARANTIAS E/OU RESPONSABILIDADES, EXPRESSAS OU
								IMPLÍCITAS, DECORRENTES OU RELATIVAS À
								UTILIZAÇÃO DO PORTAL ESCROW, INCLUINDO GARANTIAS
								DE POSSÍVEL ADEQUAÇÃO A UMA FINALIDADE
								ESPECÍFICA. O USO QUE O USUÁRIO FAZ DO PORTAL
								ESCROW E DEMAIS SOLUÇÕES DA CELCOIN É INTEIRA
								RESPONSABILIDADE E RISCO DO USUÁRIO, SENDO QUE O
								PORTAL ESCROW É FORNECIDO NO ESTADO EM QUE SE
								ENCONTRA. EM SENDO ASSIM, A CELCOIN NÃO GARANTE
								QUE:
							</p>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(i)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											o acesso ao Portal Escrow, seu
											conteúdo e/ou sua operação serão
											ininterruptos ou livres de erros;
										</p>
									</StyledTexts>
								</Col>
							</Row>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(ii)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											o Portal Escrow e os serviços
											atenderão às necessidades e
											expectativas do Usuário;
										</p>
									</StyledTexts>
								</Col>
							</Row>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(iii)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											o Portal Escrow funcionará de
											maneira pontual e segura;
										</p>
									</StyledTexts>
								</Col>
							</Row>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(iv)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											o conteúdo disponível ou gerado no
											Portal Escrow será preciso ou
											totalmente confiável; e/ou
										</p>
									</StyledTexts>
								</Col>
							</Row>
							<Row wrap={false} style={{ marginTop: '8px' }}>
								<Col flex={subColGap}>(v)</Col>
								<Col flex="auto">
									<StyledTexts>
										<p style={{ margin: 0 }}>
											quaisquer erros no conteúdo ou no
											Portal Escrow serão efetivamente
											corrigidos.
										</p>
									</StyledTexts>
								</Col>
							</Row>
						</StyledTexts>
					</Col>
				</Row>
				{renderText(responsibility)}

				<Topic>7. Vigência e Rescisão</Topic>
				{renderText(termination)}

				<Topic>8. Disposições gerais</Topic>
				{renderText(generalInfo)}

				<Topic>9. Aceite</Topic>
				{renderText([
					{
						number: '9.1',
						text: 'AO ACEITAR ESTE TERMO, O USUÁRIO DECLARA TER CAPACIDADE LEGAL E RECONHECE DE FORMA EXPRESSA E INEQUÍVOCA TER LIDO, COMPREENDIDO E ACEITO INTEGRALMENTE SEUS TERMOS E CONDIÇÕES.',
					},
				])}
			</PagePanelWrapper>

			{!userProfile?.terms_portal_accepted_at && (
				<ButtonWrapper>
					<Button
						type="primary"
						onClick={handleAcceptTerms}
						size="large"
						style={{ width: '10rem' }}
					>
						{isPending ? (
							<Spin
								style={{
									color: 'red',
								}}
							/>
						) : (
							'Aceito'
						)}
					</Button>
				</ButtonWrapper>
			)}
		</>
	);
};

export default ReadTermsPage;
