import { ApiError } from 'types/ApiError';
import { WalletsService } from '../services';
import { useCallback, useState } from 'react';

export const useCharges = (options?: {
	onError?: (error: ApiError) => void;
	onChargeDownload?: (chargePdfUrl: string) => void;
	onCancelChargeSuccess?: () => void;
}) => {
	const [isChargePdfLoading, toggleChargePdfLoading] = useState<
		string | false
	>(false);

	const [isChargeCancelLoading, toggleChargeCancelLoading] = useState<
		string | false
	>(false);

	const getChargePdf = useCallback(
		async (walletId: string, chargeId: string): Promise<string | null> => {
			try {
				toggleChargePdfLoading(chargeId);
				const chargePdf = await WalletsService.getChargePdf(
					walletId,
					chargeId,
				);
				if (typeof options?.onChargeDownload === 'function') {
					options.onChargeDownload(chargePdf.charge_pdf_url);
				}
				return chargePdf.charge_pdf_url;
			} catch (e) {
				if (typeof options?.onError === 'function') {
					options.onError(e as ApiError);
				}
				return null;
			} finally {
				toggleChargePdfLoading(false);
			}
		},
		[options],
	);

	const cancelCharge = useCallback(
		async (
			walletId: string,
			chargeId: string,
			reason: string,
		): Promise<void> => {
			try {
				toggleChargeCancelLoading(chargeId);
				await WalletsService.cancelCharge(walletId, chargeId, reason);
				options?.onCancelChargeSuccess?.();
			} catch (e) {
				if (typeof options?.onError === 'function') {
					options.onError(e as ApiError);
				}
			} finally {
				toggleChargeCancelLoading(false);
			}
		},
		[options],
	);

	return {
		isChargePdfLoading,
		isChargeCancelLoading,
		getChargePdf,
		cancelCharge,
	};
};
