export enum ChargeAction {
	ExtendExpiration = 'ExtendExpiration',
	Discount = 'Discount',
	DebtorAddress = 'DebtorAddress',
	InterestFine = 'InterestFine',
}

export const ChargeActionDescription = {
	[ChargeAction.ExtendExpiration]: 'Prorrogar',
	[ChargeAction.Discount]: 'Desconto',
	[ChargeAction.DebtorAddress]: 'Adicionar / Editar endereço do pagador',
	[ChargeAction.InterestFine]: 'Editar Multa e Juros',
};
