import styled from 'styled-components';

export const Heading1 = styled.h1`
	color: ${p => p.theme.text};
	font-size: 2.25rem;
	font-weight: 400;
	margin: 0;
	font-family: 'Urbanist', sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	line-height: 2.925rem;
`;
