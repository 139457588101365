import { ButtonProps, Flex, Spin } from 'antd';
import { ForwardButtonContainer } from './styles';
import { TextM } from 'components/Text';
import { BiChevronRight } from 'react-icons/bi';

export interface ForwardButtonProps extends ButtonProps {
	children?: React.ReactNode;
	isLoading?: boolean;
	text?: string;
}

const ForwardButton = ({ isLoading, text, ...props }: ForwardButtonProps) => {
	return (
		<ForwardButtonContainer type="primary" {...props}>
			{isLoading ? (
				<Flex justify="center" align="center" style={{ width: '100%' }}>
					<Spin />
				</Flex>
			) : (
				<Flex justify="center" align="center" style={{ width: '100%' }}>
					<TextM>{text}</TextM>
					<BiChevronRight
						size={18}
						style={{
							position: 'absolute',
							right: '1rem',
						}}
					/>
				</Flex>
			)}
		</ForwardButtonContainer>
	);
};

export default ForwardButton;
