import { Dayjs } from 'dayjs';

export type EscrowExtractFiltersFiltersType = {
	search: string;
	dateFrom: Dayjs;
	dateTo: Dayjs;
};

export type EscrowExtractItemType = {
	id: string;
	type: 'in' | 'out' | 'none';
	date: Dayjs;
	description: string;
	amount: number;
};

export enum ExportStatementFileTypeEnum {
	PDF = 'pdf',
	CSV = 'csv',
}
