import { Dayjs } from 'dayjs';
import { WalletDiscountModality } from 'types/Wallet';

export type InfoType = {
	amount?: number;
	due_date?: Dayjs;
	due_date_limit?: Dayjs;
	discount_modality: WalletDiscountModality;
	discount_value: number;
	discount_date?: Dayjs;
	fine: number;
	interest: number;
};

export type PayerType = {
	name: string;
	taxpayer_id: string;
	phone: string;
	email_address: string;
	postal_code: string;
	street: string;
	number: string;
	state: string;
	city: string;
	district: string;
};

export enum EChargesSteps {
	CHARGES_NEW_PAYER = 1,
	CHARGES_NEW_INFO = 2,
	CHARGES_RECEIVERS = 3,
	PREVIEW = 4,
}
