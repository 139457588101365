import { ForwardButton } from './styles';
import { Col, Row } from 'antd';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { TextM } from 'components/Text';

interface IDebtorAddressSuccessProps {
	isMultiples: boolean;
	onClose: () => void;
}
const DebtorAddressSuccess = ({
	isMultiples,
	onClose,
}: IDebtorAddressSuccessProps) => {
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={3}>
					<CheckmarkIcon />
				</Col>
				<Col span={21}>
					<TextM weight="normal">
						{isMultiples
							? 'Endereços alterados com sucesso'
							: 'Endereço do pagador alterado com sucesso'}
					</TextM>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<ForwardButton onClick={onClose} type="primary">
						<TextM>Ok, obrigado</TextM>
					</ForwardButton>
				</Col>
			</Row>
		</>
	);
};

export default DebtorAddressSuccess;
