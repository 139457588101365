import { BankListOptions } from 'constants/banksList';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { Posting } from 'types/Posting';

const postingType: { [key: string]: string } = {
	PIX: 'Transação Pix',
};

const scheduledPostingsDescription = {
	PIX: (posting: Posting) => {
		return `Banco: ${
			BankListOptions.find(
				bank => bank.value === posting.account_destination.bank,
			)?.label
		} | Ag: ${
			posting.account_destination.branch
		} | Conta ${normalizeBankAccount(posting.account_destination.account)} | ${
			posting.account_destination.name
		} |
    CPF/CNPJ: ${normalizeCpfCnpj(posting.account_destination.taxpayer_id)}`;
	},
	TED: () => {
		return '';
	},
	BOLETO: () => {
		return '';
	},
};

export const getPostingType = (posting: Posting) => {
	return postingType[posting.type] || posting.type;
};

export const getScheduledPostingsDescription = (posting: Posting) => {
	return scheduledPostingsDescription[posting.type]?.(posting) || '';
};
