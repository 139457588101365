import React, { ReactNode } from 'react';
import { Header, HeaderContent } from './styles';
import { Divider, Flex, Button } from 'antd';
import { Heading1 } from 'components/Heading1';
import theme from 'theme/theme';
import { AddCircleIcon } from 'components/Icons/AddCircle';

interface IPageHeaderProps {
	children?: ReactNode;
	clean?: boolean;
	isFluid?: boolean;
	isSticky?: boolean;
}

interface IPageHeaderWithButtonProps extends IPageHeaderProps {
	title: string;
	onClickButton?: () => void;
	textButton?: string;
	hasDivider?: boolean;
}

export function PageHeader({
	children,
	clean,
	isFluid,
	isSticky,
}: IPageHeaderProps) {
	return (
		<Header clean={clean} isFluid={isFluid} isSticky={isSticky}>
			<HeaderContent>{children}</HeaderContent>
		</Header>
	);
}

export function PageHeaderWithButton({
	title,
	onClickButton,
	textButton,
	isFluid,
	isSticky,
	children,
	hasDivider,
}: IPageHeaderWithButtonProps) {
	return (
		<>
			{/* HK TODO: Ajustar melhor esses params de clean e IsFluid */}
			<Header clean={isFluid} isFluid={isFluid} isSticky={isSticky}>
				{children ? (
					<HeaderContent>
						<Heading1>{title}</Heading1>
						{children}
					</HeaderContent>
				) : (
					<Flex
						align="center"
						justify="space-between"
						style={{ width: '100%' }}
					>
						<Heading1>{title}</Heading1>
						{!!textButton && (
							<Button
								type="primary"
								onClick={onClickButton}
								style={{
									backgroundColor: theme.primary,
									borderColor: theme.primary,
								}}
							>
								<Flex
									style={{
										alignItems: 'center',
										gap: '0.5rem',
									}}
								>
									{textButton || 'Criar'}
									<AddCircleIcon
										color={theme.white}
										size="16"
									/>
								</Flex>
							</Button>
						)}
					</Flex>
				)}
			</Header>
			{hasDivider && <Divider style={{ margin: 0 }} />}
		</>
	);
}
