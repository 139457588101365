import React, { useContext, useEffect, useRef } from 'react';
import { AuthTemplateLogo, AuthTemplateWrapper } from './styles';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { cancelPreviousPageRequests } from 'network/instances';
import CelcoinLogo from 'components/Icons/CelcoinLogo';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { AppContext, useAuthLogo } from 'modules/core/context/AppContext';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { SplashScreen } from 'components/SplashScreen';

export function AuthTemplate() {
	const { isLogged } = useAuthContext();
	const { isLoading, isAppLoading } = useContext(AppContext);
	const { selectedUserAccess } = useContext(ProfileContext);
	const { isAuthLogoVisible } = useAuthLogo();
	const location = useLocation();

	const previousLocation = useRef<string>();
	const currentLocation = useRef<string>();

	useEffect(() => {
		if (!previousLocation.current && !currentLocation.current) {
			previousLocation.current = location.pathname;
			currentLocation.current = location.pathname;
		} else {
			previousLocation.current = currentLocation.current;
			currentLocation.current = location.pathname;
		}
		if (previousLocation.current !== currentLocation.current) {
			cancelPreviousPageRequests(previousLocation.current!);
		}
	}, [location.pathname]);

	if (isLoading || isAppLoading) {
		return <SplashScreen />;
	}

	if (isLogged && selectedUserAccess) return <Navigate to="/" />;

	return (
		<AuthTemplateWrapper>
			{isAuthLogoVisible && (
				<AuthTemplateLogo>
					<CelcoinLogo />
				</AuthTemplateLogo>
			)}
			<Outlet />
		</AuthTemplateWrapper>
	);
}
