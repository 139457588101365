import { Button, Col, Flex, Row } from 'antd';
import { TextS } from 'components/Text';
import { truncateString } from 'helpers/formaters';
import React, { useContext, useMemo } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { LuFileCheck2 } from 'react-icons/lu';
import { useTheme } from 'styled-components';
import { UploadCnabProviderContext } from './CnabImport';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { BsExclamationTriangle } from 'react-icons/bs';

export interface IFileInfoProps {
	file: File;
	onRemove: () => void;
}

export const FileInfo = ({ file, onRemove }: IFileInfoProps) => {
	const { chargeReviewData } = useContext(UploadCnabProviderContext);
	const theme = useTheme();

	const handleRemoveFile = () => {
		onRemove();
	};

	const groupByLineMap = useMemo(() => {
		const errorsMap: Map<string, { description: string[] }> = new Map();

		chargeReviewData?.file_errors?.forEach(error => {
			const currentErrorLine = error.split(' - ')[0];
			const currentErrorDescription = error.split(' - ')[1];
			if (!errorsMap.get(currentErrorLine)) {
				errorsMap.set(currentErrorLine, {
					description: [currentErrorDescription],
				});
			} else {
				errorsMap.set(currentErrorLine, {
					description: [
						...(errorsMap.get(currentErrorLine)?.description || []),
						currentErrorDescription,
					],
				});
			}
		});

		return errorsMap;
	}, [chargeReviewData]);

	return (
		<Col>
			<Flex
				style={{
					flexDirection: 'column',
				}}
			>
				{(chargeReviewData?.file_errors?.length || 0) > 0 ? (
					<>
						<Flex align="center" gap={10}>
							<BsExclamationTriangle
								style={{ color: theme.danger }}
							/>
							<TextS style={{ color: theme.textGray }}>
								Seu arquivo possui{' '}
								{chargeReviewData?.file_errors?.length || 0}{' '}
								erros
							</TextS>
						</Flex>
						<Flex
							style={{
								marginTop: '1rem',
								flexDirection: 'column',
								maxHeight: '200px',
								overflow: 'auto',
							}}
						>
							{[...groupByLineMap].map(([line, description]) => {
								return (
									<Flex
										align="center"
										gap={8}
										style={{
											backgroundColor:
												theme.dangerOpacity,
											padding: '0.5rem',
											borderRadius: '8px',
											border: `1px solid ${theme.dangerLightBg}`,
											marginBottom: '0.5rem',
											width: '100%',
										}}
									>
										<Col>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													width: '30px',
													height: '30px',
													borderRadius: '50%',
													backgroundColor:
														theme.danger,
												}}
											>
												<AiOutlineExclamationCircle
													color={theme.white}
													size={16}
												/>
											</div>
										</Col>
										<Flex
											style={{
												flexDirection: 'column',
											}}
										>
											<TextS
												weight={600}
												style={{
													color: theme.textSecondary,
													fontSize: '0.8rem',
												}}
											>
												{line}
											</TextS>
											{description.description.map(
												desc => {
													return (
														<Flex
															align="center"
															gap={8}
															style={{
																marginTop:
																	'0.5rem',
															}}
														>
															<AiOutlineExclamationCircle
																style={{
																	fontSize:
																		'1.3rem',
																}}
																color={
																	theme.textSecondary
																}
															/>
															<TextS
																style={{
																	color: theme.textGray,
																}}
															>
																{desc}
															</TextS>
														</Flex>
													);
												},
											)}
										</Flex>
									</Flex>
								);
							})}
						</Flex>
					</>
				) : (
					<Flex
						style={{
							minWidth: '100%',
							backgroundColor: theme.primaryOpacity,
							padding: '0 1rem',
							height: '2rem',
							borderRadius: '5px',
						}}
						align="center"
						justify="space-between"
					>
						<Flex align="center" gap={10}>
							<LuFileCheck2 color={theme.primary} size={18} />
							<TextS
								style={{
									color: theme.textGray,
									margin: '1rem 0',
								}}
							>
								{truncateString(file.name, 35)}
							</TextS>
							<TextS
								weight={300}
								style={{
									color: theme.textGray,
								}}
							>
								({(file.size / 1024).toFixed(2)}) KB
							</TextS>
						</Flex>

						<IoMdCloseCircleOutline
							onClick={handleRemoveFile}
							style={{
								cursor: 'pointer',
							}}
							color={theme.primary}
							size={20}
						/>
					</Flex>
				)}
			</Flex>
			<Row
				style={{
					marginBottom: '1rem',
				}}
			>
				<Flex
					style={{
						width: '100%',
					}}
				>
					<Button
						onClick={handleRemoveFile}
						type="default"
						style={{
							width: '100%',
							border: 0,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<TextS
							weight={600}
							style={{
								color: theme.primary,
							}}
						>
							Enviar outro arquivo
						</TextS>
					</Button>
				</Flex>
			</Row>
		</Col>
	);
};
