import {
	ForwardButton,
	ExtendExpirationFormContainer,
	ExtendExpirationFormItem,
	CancelButton,
} from './styles';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { TextM } from 'components/Text';
import { WalletCharge } from 'types/Wallet';
import { FaCheck } from 'react-icons/fa6';
import { DatePicker } from 'components/DatePicker';
import { minTodayDateRule } from 'helpers/rules';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';

interface IExtendExpirationFormProps {
	charge?: WalletCharge | WalletCharge[];
	isLoading: boolean;
	onClose: () => void;
	onSave: (due_date: string) => void;
}
const ExtendExpirationForm = ({
	charge,
	onClose,
	onSave,
}: IExtendExpirationFormProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);
	const isMultiples = Array.isArray(charge);

	useEffect(() => {
		form.setFieldValue('due_date', undefined);
	}, []);

	return (
		<ExtendExpirationFormContainer
			form={form}
			style={{ width: '100%' }}
			layout="vertical"
			requiredMark={false}
			onFieldsChange={(f, allFields) => {
				const errors = allFields.filter(
					field =>
						!field.touched ||
						(field.errors && field.errors.length > 0),
				);
				setFormValid(errors.length === 0);
			}}
			onFinish={values => {
				onSave(values.due_date.format('YYYY-MM-DD'));
			}}
		>
			{!isMultiples && charge && (
				<Row>
					<Col span={24}>
						<TextM weight="normal" style={{ fontSize: '18px' }}>
							Este boleto vence em{' '}
							<span style={{ color: theme.primary }}>
								{dayjs(charge.due_date).format('DD/MM/YYYY')}
							</span>
						</TextM>
					</Col>
				</Row>
			)}
			<Row style={{ marginTop: '1.5rem' }}>
				<Col span={24}>
					<ExtendExpirationFormItem
						name="due_date"
						label="Nova data de vencimento"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
							minTodayDateRule('due_date'),
						]}
					>
						<DatePicker
							placeholder="dd/mm/aaaa"
							disablePastDays={true}
						/>
					</ExtendExpirationFormItem>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
				<Col span={24}>
					<ForwardButton
						type="primary"
						htmlType="submit"
						disabled={!formValid}
					>
						<TextM>
							{isMultiples
								? 'Prorrogar boletos'
								: 'Prorrogar boleto'}
						</TextM>
						<FaCheck size={18} />
					</ForwardButton>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<CancelButton type="default" onClick={onClose}>
						<TextM>Cancelar</TextM>
					</CancelButton>
				</Col>
			</Row>
		</ExtendExpirationFormContainer>
	);
};

export default ExtendExpirationForm;
