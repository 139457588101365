export const WarningIcon: React.FC<{
	color?: string;
	width?: number;
	height?: number;
}> = ({ width, height, color }) => (
	<svg
		width={width ?? '40'}
		height={height ?? '40'}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="20" cy="20" r="17" stroke="#F97800" />
		<path
			d="M17.8789 12.3124C17.8429 11.7367 18.3001 11.25 18.877 11.25H21.123C21.6999 11.25 22.1571 11.7367 22.1211 12.3124L21.5169 21.979C21.484 22.5061 21.0469 22.9167 20.5189 22.9167H19.4811C18.9531 22.9167 18.516 22.5061 18.4831 21.979L17.8789 12.3124Z"
			fill={color ?? '#F97800'}
		/>
		<circle cx="20" cy="26.5625" r="2.1875" fill="#F97800" />
	</svg>
);
