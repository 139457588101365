import { ButtonProps } from 'antd';
import { CustomButton } from './styles';
import React from 'react';

type ButtonSecondaryProps = ButtonProps;

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
	children,
	...props
}) => {
	return <CustomButton {...props}>{children}</CustomButton>;
};

export default ButtonSecondary;
