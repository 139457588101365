import styled from 'styled-components';

export const ContainerFluid = styled.div`
	max-width: 100%;
`;

export const Footer = styled.div`
	display: flex;
	margin: 1rem 0;
	justify-content: flex-end;
`;

export const DescriptionItem = styled.p`
	padding: 0;
	margin: 0 0 1rem;
`;

export const RepresentativeTitle = styled.div`
	display: flex;
	align-items: center;
	span {
		margin-left: 0.6rem;
	}
`;

export const ContainerHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	margin-top: 1rem;
`;
