import {
	BreadcrumbItemType,
	BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AppService } from '../services/AppService';
import { LoggerService } from '../services/LoggerService';
import { FINANCIAL_INSTITUTION_ID } from '../constants/session';

interface IAppContextProps {
	breadcrumb?: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
	setBreadcrumb: (
		breadcrumb:
			| Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]
			| undefined,
	) => void;

	disabledAuthLogo: boolean;
	toggleAuthLogo: (disableAuthLogo: boolean) => void;

	isLoading: boolean;
	toggleLoading: (isLoading: boolean) => void;

	isAppLoading: boolean;
	financialInstitutionId: string;
}

export const AppContext = createContext<IAppContextProps>({
	breadcrumb: undefined,
	setBreadcrumb: () => {
		throw new Error('Método não implementado');
	},
	disabledAuthLogo: false,
	toggleAuthLogo: () => {
		throw new Error('Método não implementado');
	},
	isLoading: false,
	toggleLoading: () => {
		throw new Error('Método não implementado');
	},
	isAppLoading: false,
	financialInstitutionId: '',
});

interface IAppProviderProps {
	children?: React.ReactNode;
}
export const AppProvider = ({ children }: IAppProviderProps) => {
	const [breadcrumb, setBreadcrumb] = useState<
		Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | undefined
	>();
	const [disabledAuthLogo, toggleAuthLogo] = useState<boolean>(false);
	const [isLoading, toggleLoading] = useState<boolean>(true);
	const [isAppLoading, toggleAppLoading] = useState<boolean>(true);
	const [financialInstitutionId, updateFinancialInstitutionId] =
		useState<string>('');

	useEffect(() => {
		const domain = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
		AppService.getFinancialInstitution(domain)
			.then(({ id }) => {
				sessionStorage.setItem(FINANCIAL_INSTITUTION_ID, id);
				updateFinancialInstitutionId(id);
				toggleAppLoading(false);
			})
			.catch(e => {
				LoggerService.error('AppProvider');
			});
	}, []);

	return (
		<AppContext.Provider
			value={{
				breadcrumb,
				setBreadcrumb,
				disabledAuthLogo,
				toggleAuthLogo,
				isLoading,
				toggleLoading,
				isAppLoading,
				financialInstitutionId,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useBreadcrumb = () => {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error('useAppContext must be used within a AppProvider');
	}

	return {
		breadcrumb: context.breadcrumb,
		setBreadcrumb: context.setBreadcrumb,
	};
};

export const useAuthLogo = () => {
	const context = useContext(AppContext);

	if (context === undefined) {
		throw new Error('useAppContext must be used within a AppProvider');
	}

	return {
		isAuthLogoVisible: !context.disabledAuthLogo,
		showAuthLogo: () => context.toggleAuthLogo(false),
		hideAuthLogo: () => context.toggleAuthLogo(true),
	};
};
