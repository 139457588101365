export const CheckmarkIcon: React.FC<{
	color?: string;
	width?: number;
	height?: number;
}> = ({ width, height, color }) => (
	<svg
		width={width ?? '40'}
		height={height ?? '40'}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.9995 37C16.6374 36.9999 13.3508 36.0029 10.5553 34.135C7.75968 32.267 5.58074 29.612 4.29406 26.5056C3.00737 23.3993 2.67071 19.9811 3.32666 16.6835C3.98261 13.3858 5.6017 10.3567 7.97919 7.97919C10.3567 5.6017 13.3858 3.98261 16.6835 3.32666C19.9811 2.67071 23.3993 3.00737 26.5056 4.29406C29.612 5.58074 32.267 7.75968 34.135 10.5553C36.0029 13.3508 36.9999 16.6374 37 19.9995C36.9951 24.5068 35.2023 28.828 32.0152 32.0152C28.828 35.2023 24.5068 36.9951 19.9995 37Z"
			stroke={color ?? '#00CC21'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.2093 14.5279C25.4412 14.2931 25.7516 14.1631 26.0739 14.1661C26.3962 14.169 26.7044 14.3046 26.9323 14.5436C27.1602 14.7825 27.2895 15.1059 27.2923 15.4438C27.2951 15.7818 27.1712 16.1074 26.9473 16.3505L26.9429 16.3553L18.2673 25.4537C18.1532 25.5736 18.0173 25.6692 17.8682 25.7341C17.7188 25.7992 17.5587 25.8326 17.397 25.8327C17.0717 25.8326 16.7595 25.6973 16.5292 25.4563L13.054 21.8117C12.8301 21.5686 12.7062 21.243 12.709 20.905C12.7118 20.567 12.8411 20.2437 13.069 20.0047C13.2969 19.7657 13.6051 19.6302 13.9274 19.6272C14.2497 19.6243 14.5602 19.7542 14.792 19.989L14.7965 19.9936L17.397 22.7209L25.2093 14.5279Z"
			fill={color ?? '#00CC21'}
		/>
	</svg>
);
