import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { Steps } from '../styles';
import { PageActions } from 'components/PageActions';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { notification } from 'antd';
import { CompanyIcon, CompanyRoutes } from 'modules/company/constants/routes';
import { CompanyDto, PersonType } from 'types/Company';
import { useMutation } from '@tanstack/react-query';
import { PersonsService } from 'modules/company/services';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { ApiError } from 'types/ApiError';
import { NaturalPersonInformation } from 'components/NaturalPersonInformation';
import { PersonRepresentatives } from 'components/PersonRepresentatives';

const NaturalPersonCreationPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const [currentStep, updateCurrentStep] = useState<number>(1);
	const [company, updateCompany] = useState<CompanyDto>({} as CompanyDto);
	const { setBreadcrumb } = useBreadcrumb();
	const navigate = useNavigate();

	const companyMutation = useMutation<{ id: string }, ApiError, CompanyDto>({
		mutationFn: newCompany => {
			return PersonsService.createCompany({
				...newCompany,
				person_type: PersonType.NATURAL,
				documents: {
					proof_of_address: newCompany.documents.proof_of_address,
					document_identification:
						newCompany.documents.document_identification,
				},
				representatives: newCompany.representatives.map(
					representative => ({
						...representative,
						documents: {
							identification:
								representative.documents.identification,
							proof_of_address:
								representative.documents.proof_of_address,
						},
					}),
				),
			});
		},
		onSuccess: () => navigate(CompanyRoutes.BASE),
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a empresa. ',
			});
		},
	});

	const onNext = useCallback(
		(editCompany: CompanyDto) => {
			if (currentStep < 2) {
				updateCurrentStep(currentStep + 1);
				updateCompany({
					...company,
					...editCompany,
				});
			} else {
				companyMutation.mutate({
					...company,
					...editCompany,
				});
			}
		},
		[company, companyMutation, currentStep],
	);

	const onBack = useCallback(
		(editCompany: CompanyDto) => {
			if (currentStep > 1) {
				updateCompany({
					...company,
					...editCompany,
				});
				updateCurrentStep(currentStep - 1);
			}
		},
		[company, currentStep],
	);

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/company',
				title: (
					<BreadcrumbContainer>
						<CompanyIcon />
						<span>Clientes</span>
					</BreadcrumbContainer>
				),
			},
			{
				title: 'Criação de Pessoa Física',
			},
		]);
	}, [setBreadcrumb]);

	return (
		<>
			<PageHeader>
				<Heading1>Criação de Pessoa Física</Heading1>
				<PageActions>
					<Steps>{`Etapa ${currentStep}/2`}</Steps>
				</PageActions>
			</PageHeader>
			<PageWrapper>
				{contextHolder}
				{currentStep === 1 && (
					<NaturalPersonInformation
						person={company}
						onNext={onNext}
						setTenantType={() => {}} // HK refatorar para passar essas infos para um Provider
					/>
				)}
				{currentStep === 2 && (
					<PersonRepresentatives
						isLoading={companyMutation.isPending}
						person={company}
						onBack={onBack}
						onNext={onNext}
					/>
				)}
			</PageWrapper>
		</>
	);
};

export default NaturalPersonCreationPage;
