import { TextL, TextS } from 'components/Text';
import { Account, AccountContent } from 'types/Account';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'components/Table';
import { Table as ANTDTable, Button, Col, Flex, notification, Row } from 'antd';
import {
	AccountDestination,
	SelectType,
	TransferRuleCreated,
	TransferRuleStatus,
} from 'types/Management';
import { useState } from 'react';
import { formatCurrency, normalizeBankAccount } from 'helpers/normalizers';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import { TransferRulesTableWrapper } from './styles';
import { FaRegTrashCan } from 'react-icons/fa6';
import { RiPencilLine } from 'react-icons/ri';
import { BankListDescription } from 'constants/banksList';
import { CreateTransferRuleModal } from '../components/CreateTransferRuleModal';
import { queryClient } from 'network/query';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { ManagementService } from 'modules/management/services/management';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { convertMinutesToHours } from 'helpers/dates';

export interface TransferRuleListProps {}

export interface TransferRuleListProps {
	account: Account | undefined;
	transferRules: TransferRuleCreated[] | undefined;
}

const TransferTypeUiView = {
	PIX: 'Pix',
	TED: 'TED',
};

const frequencyUiView = {
	DAILY: 'Diário - Uma única vez',
	WEEKLY: 'Semanal',
	INTERVAL: 'Diário - Periodicamente',
};

const TransferRuleList = ({
	account,
	transferRules,
}: TransferRuleListProps) => {
	const [api, contextHolder] = notification.useNotification();
	const [deleteTransferRuleConfirm, updateDeleteTransferRuleConfirm] =
		useState<TransferRuleCreated | undefined>();
	const [modalIsOpen, updateModalIsOpen] = useState(false);
	const [transferRuleToEdit, updateTransferRuleToEdit] = useState<
		TransferRuleCreated | undefined
	>();

	const theme = useTheme();

	const { mutate: deleteTransferRuleMutate, isPending: updateIsPending } =
		useMutation<
			void,
			ApiError,
			{
				transferRule: TransferRuleCreated;
			}
		>({
			mutationFn: ({ transferRule }) => {
				return ManagementService.updateAccountTransferRule(
					{
						status: TransferRuleStatus.DELETED,
					},
					transferRule.transfer_rule.id,
				);
			},
			onSuccess: () => {
				api.success({
					message: 'Regra de transferência removida com sucesso',
				});

				queryClient.invalidateQueries({
					queryKey: ['transferRulesList'],
				});
			},

			onError: e => {
				api.error({
					description: e.data.message,
					message:
						'Ocorreu um erro ao tentar remover a regra de transferência',
				});
			},
		});

	const handleDeleteTransferRule = (
		transferRuleToDelete: TransferRuleCreated,
	) => {
		updateDeleteTransferRuleConfirm(transferRuleToDelete);
	};

	const handleConfirmDeleteTransferRule = () => {
		deleteTransferRuleMutate({
			transferRule: deleteTransferRuleConfirm!,
		});

		updateDeleteTransferRuleConfirm(undefined);
	};

	const columns: ColumnsType<AccountContent> = [
		{
			key: '1',
			title: 'Valor',
			render: (rule: TransferRuleCreated) => (
				<div>
					{rule.transfer_rule.amount_type === SelectType.FIXED
						? formatCurrency(rule.transfer_rule?.amount || 0)
						: `${rule.transfer_rule?.amount}%`}
				</div>
			),
			width: '20%',
		},
		{
			key: '2',
			title: 'Saldo mínimo',
			render: (rule: TransferRuleCreated) => (
				<div>
					{formatCurrency(
						rule.transfer_rule?.min_value_in_account_after_transfer,
					)}
				</div>
			),
			width: '20%',
		},
		{
			key: '3',
			title: 'Tipo',
			render: (rule: TransferRuleCreated) => (
				<div>
					{TransferTypeUiView[rule.transfer_rule?.transfer_method]}
				</div>
			),
			width: '20%',
		},
		{
			key: '4',
			title: 'Frequência',
			width: '30%',
			render: (rule: TransferRuleCreated) => {
				const rules = rule.transfer_rule;
				const frequency = frequencyUiView[rules?.frequency];
				const hasInterval: boolean = !rules.fixed_transfer_time;
				const messageInfo = !hasInterval
					? `Às ${rules.fixed_transfer_time}`
					: `Entre ${rules.transfer_window_start} e ${rules.transfer_window_end} a cada ${convertMinutesToHours(rule.transfer_rule.transfer_interval)}`;

				return <div>{`${frequency} | ${messageInfo}`}</div>;
			},
		},
		ANTDTable.EXPAND_COLUMN,
	];

	const expandedRowRender = (record: TransferRuleCreated) => {
		const columnsInternal: ColumnsType<AccountDestination> = [
			{
				key: '1',
				title: 'Conta beneficiária',
				render: (accountDestination: AccountDestination) => {
					const accountDestinationData = account?.destinations.find(
						dest =>
							dest.id ===
							accountDestination.account_destination_id,
					);
					return (
						<div>
							<TextS
								weight="bold"
								style={{
									fontSize: '0.9rem',
									color: theme.textSecondary,
								}}
							>
								{
									BankListDescription[
										accountDestinationData?.bank as keyof typeof BankListDescription
									]
								}
							</TextS>
							<TextS
								weight="normal"
								style={{
									fontSize: '0.9rem',
									color: theme.textGray,
								}}
							>
								Ag.: {accountDestinationData?.branch}
							</TextS>
							<TextS
								weight="normal"
								style={{
									fontSize: '0.9rem',
									color: theme.textGray,
								}}
							>
								Cc.:{' '}
								{normalizeBankAccount(
									accountDestinationData?.account ?? '',
								)}
							</TextS>
						</div>
					);
				},
				width: '20%',
			},
			{
				key: '2',
				title: 'Tipo de transferência',
				render: () => {
					return (
						<TextS
							weight="normal"
							style={{
								fontSize: '1rem',
								color: theme.textGray,
							}}
						>
							Percentual
						</TextS>
					);
				},
				width: '20%',
			},

			{
				key: '3',
				title: 'Valor',
				render: (accountDestination: AccountDestination) => {
					return (
						<TextS
							weight="normal"
							style={{
								fontSize: '1rem',
								color: theme.textGray,
							}}
						>
							{accountDestination.split_percentage}%
						</TextS>
					);
				},
				width: '20%',
			},
		];

		return (
			<>
				<Flex justify="space-between">
					<Col>
						<TextS
							weight="normal"
							style={{
								fontSize: '0.9rem',
							}}
						>
							Frequência de transferência:
						</TextS>
						<TextS style={{ color: '#6D6980', fontSize: '0.9rem' }}>
							{frequencyUiView[record.transfer_rule.frequency]}
						</TextS>
					</Col>

					<Col>
						<Flex gap={10}>
							<Button
								onClick={() => handleDeleteTransferRule(record)}
								type="primary"
								style={{
									backgroundColor: theme.danger,
									borderColor: theme.danger,
								}}
							>
								<TextS style={{ color: theme.white }}>
									Excluir
								</TextS>
								<FaRegTrashCan style={{ color: theme.white }} />
							</Button>

							<Button
								type="primary"
								onClick={() => updateTransferRuleToEdit(record)}
							>
								<TextS style={{ color: theme.white }}>
									Editar
								</TextS>
								<RiPencilLine style={{ color: theme.white }} />
							</Button>
						</Flex>
					</Col>
				</Flex>
				<TransferRulesTableWrapper>
					<Table
						pagination={false}
						columns={columnsInternal}
						rowKey={record => record.id}
						dataSource={record.destinations}
					/>
				</TransferRulesTableWrapper>
			</>
		);
	};

	return (
		<>
			{contextHolder}
			<TransferRulesTableWrapper>
				<Table
					pagination={false}
					columns={columns}
					rowKey={record => record.transfer_rule.id}
					dataSource={transferRules}
					expandable={{
						expandedRowRender: expandedRowRender,
						expandIcon: ({ expanded, onExpand, record }) => {
							return expanded ? (
								<div onClick={e => onExpand(record, e)}>
									<BiChevronUp
										style={{
											color: theme.primary,
											fontSize: '1.3rem',
										}}
									/>
								</div>
							) : (
								<div onClick={e => onExpand(record, e)}>
									<BiChevronDown
										style={{
											color: theme.primary,
											fontSize: '1.3rem',
										}}
									/>
								</div>
							);
						},
					}}
					rowClassName={'custom-row'}
				/>
			</TransferRulesTableWrapper>

			<CreateTransferRuleModal
				isOpen={!!transferRuleToEdit || modalIsOpen}
				onClose={() => {
					updateModalIsOpen(false);
					updateTransferRuleToEdit(undefined);
					queryClient.invalidateQueries({
						queryKey: ['transferRulesList'],
					});
				}}
				transferRule={transferRuleToEdit}
				accountData={account}
			/>

			<ConfirmationModal
				isOpen={!!deleteTransferRuleConfirm}
				danger={true}
				title="Remover regra de transferência"
				confirmText="Remover"
				cancelText="Cancelar"
				onConfirm={handleConfirmDeleteTransferRule}
				onCancel={() => updateDeleteTransferRuleConfirm(undefined)}
				onClose={() => updateDeleteTransferRuleConfirm(undefined)}
			>
				<TextL>Você tem certeza que deseja remover essa regra?</TextL>
			</ConfirmationModal>
		</>
	);
};

export default TransferRuleList;
