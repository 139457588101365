import { Button, Col, Flex, Form, Input, Row, Select } from 'antd';
import { TextL, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { FaCheck } from 'react-icons/fa6';
import { LuTrash2 } from 'react-icons/lu';
import { CreateTransferRuleModalFormValues } from './CreateTransferRuleModal';
import Footer from './Footer';
import { DestinationTable, WarningCard } from './styles';
import { BsExclamationTriangle } from 'react-icons/bs';
import { Account } from 'types/Account';
import { formatToFixed, normalizeBankAccount } from 'helpers/normalizers';
import { useEffect, useState } from 'react';
import { BankListCode } from 'constants/bankListCode';
import { Destination } from 'types/Destination';
import { BankListDescription } from 'constants/banksList';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { betweenRangeNumberValue, validateZeroPercentage } from 'helpers/rules';
import { AccountDestination } from 'types/Management';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { AddCircleIcon } from 'components/Icons/AddCircle';

type ColumnsData = {
	id: string;
	key: string;
	accountDestination: Destination | undefined;
	share: number;
};

export interface TransferRuleDestinationProps {
	accountData: Account | undefined;
	formValues: CreateTransferRuleModalFormValues;
	isLoading?: boolean;
	handleNext?: (values: CreateTransferRuleModalFormValues) => void;
	onPrevious?: () => void;
	updateForm: (values: CreateTransferRuleModalFormValues) => void;
	createTransferRule: () => void;
}

const TransferRuleDestination = ({
	accountData,
	formValues,
	isLoading,
	onPrevious,
	updateForm,
	createTransferRule,
}: TransferRuleDestinationProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const [accounts, updateAccounts] = useState<AccountDestination[]>([]);
	const [isDestinationRemoveOpen, toggleIsDestinationRemoveOpen] =
		useState(false);
	const [destinationToConfirm, updateDestinationToConfirm] = useState<
		Destination | undefined
	>();
	const invalidRule =
		accounts.reduce(
			(acc, account) => acc + Number(account.split_percentage),
			0,
		) !== 100;

	const handleAddBeneficiary = (values: AccountDestination) => {
		const updatedAccounts = [
			...accounts,
			{
				account_destination_id: values.account_destination_id,
				split_percentage: Number(values.split_percentage),
			},
		];
		updateAccounts(updatedAccounts);
		updateForm?.({ ...formValues, destinations: updatedAccounts });
		form.resetFields();
	};

	const handleCreateRule = () => {
		createTransferRule?.();
	};

	const columns: ColumnsType<ColumnsData> = [
		{
			title: 'Conta beneficiária',
			key: 'beneficiaryAccount',
			align: 'left',
			render: (account: ColumnsData) => {
				return (
					<>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textSecondary,
							}}
							weight="bold"
						>
							{account.accountDestination?.name ?? '-'}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Banco:{' '}
							{
								BankListDescription[
									(account.accountDestination
										?.bank as keyof typeof BankListCode) ??
										''
								]
							}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Ag.: {account.accountDestination?.branch}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Cc.:{' '}
							{normalizeBankAccount(
								account.accountDestination?.account ?? '',
							)}
						</TextS>
					</>
				);
			},
		},
		{
			title: 'Tipo de transferência',
			key: 'transferType',
			align: 'left',
			render: () => (
				<TextS style={{ color: theme.textGray }}>Percentual</TextS>
			),
		},
		{
			title: 'Valor',
			key: 'share',
			align: 'left',
			render: (account: ColumnsData) => (
				<TextS style={{ color: theme.textGray }}>
					{formatToFixed(account.share, 2)}%
				</TextS>
			),
		},
		{
			title: '',
			width: '10%',
			key: 'actions',
			render: (account: ColumnsData) => (
				<Button
					type="link"
					onClick={() => {
						updateDestinationToConfirm(account.accountDestination);
						toggleIsDestinationRemoveOpen(true);
					}}
				>
					<LuTrash2 style={{ color: theme.danger }} />
				</Button>
			),
		},
	];

	const dataSource = accounts.map(account => {
		const accountDestination = accountData?.destinations.find(
			destination => destination.id === account.account_destination_id,
		);

		return {
			id: accountDestination?.id,
			key: accountDestination?.id,
			accountDestination: accountDestination,
			share: account.split_percentage,
		};
	});

	useEffect(() => {
		if (formValues?.destinations) {
			updateAccounts(formValues.destinations);
		}
	}, [formValues]);

	return (
		<>
			<Form<AccountDestination>
				form={form}
				layout="vertical"
				onFinish={handleAddBeneficiary}
				initialValues={{
					beneficiaryAccount: undefined,
					splitPercentage: undefined,
				}}
			>
				<Row
					style={{
						flexDirection: 'column',
					}}
				>
					<TextS
						style={{
							color: theme.primary,
						}}
					>
						Conta de destino
					</TextS>
					<Row style={{ marginTop: '1rem' }} gutter={[10, 10]}>
						<Col span={10}>
							<Form.Item
								label={
									<TextS>
										Selecione a conta beneficiária
									</TextS>
								}
								name="account_destination_id"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									placeholder="Selecione"
									options={accountData?.destinations
										.map(account => ({
											label: `${account.name} - ${normalizeBankAccount(
												account.account ?? '',
											)}`,
											value: account.id,
										}))
										.filter(account => {
											return !accounts.some(
												state =>
													state.account_destination_id ===
													account.value,
											);
										})}
								/>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item
								name="split_percentage"
								label={<TextS>Cota percentual</TextS>}
								rules={[
									betweenRangeNumberValue(
										'split_percentage',
										0.01,
										100,
									),
									validateZeroPercentage('split_percentage'),
								]}
							>
								<Input
									placeholder="0"
									inputMode="numeric"
									type="number"
									min={0}
									prefix="%"
									max={100}
								/>
							</Form.Item>
						</Col>
						<Col
							span={4}
							style={{ height: '70px', display: 'flex' }}
						>
							<ButtonPrimary
								type="primary"
								style={{
									alignSelf: 'flex-end',
									minHeight: '40px',
									padding: '10px',
								}}
								onClick={form.submit}
							>
								<Flex align="center" gap={10}>
									<TextS style={{ color: theme.white }}>
										Adicionar
									</TextS>
									<AddCircleIcon
										color={theme.white}
										size="16"
									/>
								</Flex>
							</ButtonPrimary>
						</Col>
					</Row>

					<Row style={{ margin: '2rem 0' }}>
						<Col span={24}>
							<DestinationTable
								rowKey={record => record.id}
								pagination={false}
								columns={columns}
								dataSource={dataSource}
							/>
						</Col>
					</Row>
					{invalidRule && (
						<WarningCard bordered={false}>
							<Row gutter={[10, 10]}>
								<Col>
									<Flex
										style={{ height: '100%' }}
										align="center"
									>
										<BsExclamationTriangle
											style={{ color: theme.danger }}
										/>
									</Flex>
								</Col>
								<Col>
									<TextS
										weight="bold"
										style={{ color: theme.dangerDisabled }}
									>
										Regra inválida.
									</TextS>
									<TextS
										style={{ color: theme.textSecondary }}
									>
										Para que sua regra de transferência seja
										executada, o valor total deve ser igual
										a 100%.
									</TextS>
								</Col>
							</Row>
						</WarningCard>
					)}
				</Row>
			</Form>

			<Row
				style={{
					justifyContent: 'flex-end',
					marginTop: '2rem',
				}}
			>
				<Footer
					isLoading={isLoading}
					primaryButtonText="Adicionar regra"
					primaryButtonIcon={<FaCheck />}
					primaryButtonDisabled={invalidRule}
					onDefaultButtonClick={onPrevious}
					onPrimaryButtonClick={handleCreateRule}
				/>
			</Row>
			<ConfirmationModal
				isOpen={isDestinationRemoveOpen}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				onConfirm={() => {
					if (destinationToConfirm) {
						const updated = accounts.filter(
							state =>
								state.account_destination_id !==
								destinationToConfirm?.id,
						);
						updateAccounts(updated);

						updateDestinationToConfirm(undefined);
						updateForm?.({ ...formValues, destinations: updated });
						toggleIsDestinationRemoveOpen(false);
					}
				}}
				onCancel={() => toggleIsDestinationRemoveOpen(false)}
				onClose={() => toggleIsDestinationRemoveOpen(false)}
			>
				<TextL>
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextL>
			</ConfirmationModal>
		</>
	);
};

export default TransferRuleDestination;
