import { AccountFeeMode, AccountFeeType } from 'types/Account';
import { PaymentType } from 'types/Posting';

export interface IFeeSettingsModalFormValues {
	feeMode: AccountFeeMode;
	defaultPaymentType: PaymentType;

	maintenanceType: AccountFeeType;
	maintenanceAmount?: number;
	maintenanceDay?: number;

	// SAIDAS
	tedDebitType: AccountFeeType;
	tedDebit?: number;
	pixDebitType: AccountFeeType;
	pixDebit?: number;

	// ENTRADAS
	depositType: AccountFeeType;
	deposit?: number;

	// BOLETO
	chargeSettlement?: number;

	// COMMISSION VALUES
	commissionMaintenancePercentAmount?: number;
	commissionChargeSettlementPercentAmount?: number;
	commissionTedTransferPercentAmount?: number;
	commissionWithdrawalPercentAmount?: number;

	// COMMISSION ACCOUNT DESTINATION
	commissionAccountDestinationId: string;
	commissionAccountTaxpayerId: string;
	commissionAccountType: string;
	commissionAccountBank: string;
	commissionAccountBranch: string;
	commissionAccountAccount: string;
	commissionAccountName: string;
}

export enum FeeType {
	MAINTENANCE = 'MAINTENANCE',
	DEPOSIT = 'DEPOSIT',
	WITHDRAWAL = 'WITHDRAWAL',
	TED_TRANSFER = 'TED_TRANSFER',
	TED_DEPOSIT = 'TED_DEPOSIT',
	CHARGE_SETTLEMENT = 'CHARGE_SETTLEMENT',
	CUSTOM = 'CUSTOM',
	NONE = 'NONE',
}

export enum CustomFeeType {
	MAINTENANCE = 'MAINTENANCE',
	CHARGE_SETTLEMENT = 'CHARGE_SETTLEMENT',
	OTHER = 'OTHER',
	CUSTOM = 'CUSTOM',
}

export enum CustomFeeTypeLabel {
	MAINTENANCE = 'Manutenção',
	CHARGE_SETTLEMENT = 'Liquidação de boletos',
	OTHER = 'Outros',
}

export interface CustomFeeDTO {
	id?: string;
	account_id: string;
	type: CustomFeeType;
	amount: number;
	description?: string;
	created_at?: string;
	error?: string;
	executed_at?: string;
	maintenance_date?: string;
	scheduled_date?: string;
	status?: string;
}

export interface ListFeeFilters {
	type: FeeType;
}
