import React, { useEffect, useState } from 'react';
import {
	PasswordCheckIcon,
	PasswordRequirement,
	PasswordRequirements,
	PasswordStrengthContainer,
	PasswordStrengthLevel,
	PasswordStrengthLevels,
	PasswordStrengthLevelText,
} from './styles';
import { FaRegCircleCheck } from 'react-icons/fa6';

interface PasswordStrengthProps {
	password: string;
	onChangePasswordStrength: (strength: number) => void;
}

const PasswordStrength = ({
	password,
	onChangePasswordStrength,
}: PasswordStrengthProps) => {
	const [strength, updateStrength] = useState<number>(-1);
	const [hasMinLength, toggleHasMinLength] = useState<boolean>(false);
	const [hasLowerUppercase, toggleHasLowerUppercase] =
		useState<boolean>(false);
	const [hasSymbols, toggleHasSymbols] = useState<boolean>(false);
	const [hasNumber, toggleHasNumber] = useState<boolean>(false);
	const [strengthText, updateStrengthText] = useState<
		null | 'weak' | 'medium' | 'high' | 'strong'
	>('weak');

	useEffect(() => {
		if (strength >= 3) {
			updateStrengthText('strong');
		} else if (strength >= 2) {
			updateStrengthText('high');
		} else if (strength >= 1) {
			updateStrengthText('medium');
		} else if (strength >= 0) {
			updateStrengthText('weak');
		} else {
			updateStrengthText(null);
		}
		onChangePasswordStrength(strength);
	}, [onChangePasswordStrength, strength]);

	useEffect(() => {
		if (password.length >= 8) {
			toggleHasMinLength(true);
			if (!hasMinLength) updateStrength(state => state + 1);
		} else if (hasMinLength) {
			toggleHasMinLength(false);
			updateStrength(state => state - 1);
		}

		if (/\d/.test(password)) {
			toggleHasNumber(true);
			if (!hasNumber) updateStrength(state => state + 1);
		} else if (hasNumber) {
			toggleHasNumber(false);
			updateStrength(state => state - 1);
		}

		if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
			toggleHasLowerUppercase(true);
			if (!hasLowerUppercase) updateStrength(state => state + 1);
		} else if (hasLowerUppercase) {
			toggleHasLowerUppercase(false);
			updateStrength(state => state - 1);
		}

		if (/[!@#$%^&*_-]/.test(password)) {
			toggleHasSymbols(true);
			if (!hasSymbols) updateStrength(state => state + 1);
		} else if (hasSymbols) {
			toggleHasSymbols(false);
			updateStrength(state => state - 1);
		}
	}, [hasLowerUppercase, hasMinLength, hasNumber, hasSymbols, password]);

	return (
		<>
			<PasswordStrengthContainer>
				<PasswordRequirements>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasMinLength}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>8 ou mais caracteres</span>
					</PasswordRequirement>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasLowerUppercase}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>Letras maiúsculas e minúsculas</span>
					</PasswordRequirement>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasSymbols}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>No mínimo 1 caractere especial</span>
					</PasswordRequirement>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasNumber}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>Pelo menos um número</span>
					</PasswordRequirement>
				</PasswordRequirements>
			</PasswordStrengthContainer>
			<PasswordStrengthContainer>
				<PasswordStrengthLevels>
					<PasswordStrengthLevel
						strength={strength >= 0 ? strengthText : null}
					/>
					<PasswordStrengthLevel
						strength={strength >= 1 ? strengthText : null}
					/>
					<PasswordStrengthLevel
						strength={strength >= 2 ? strengthText : null}
					/>
					<PasswordStrengthLevel
						strength={strength >= 3 ? strengthText : null}
					/>
				</PasswordStrengthLevels>
				<PasswordStrengthLevelText
					strength={strength >= 0 ? strengthText : null}
				>
					<span>Nível de segurança:</span>
					{!strengthText && <>-</>}
					{strengthText === 'weak' && <span>Fraco</span>}
					{strengthText === 'medium' && <span>Médio</span>}
					{strengthText === 'high' && <span>Alto</span>}
					{strengthText === 'strong' && <span>Forte</span>}
				</PasswordStrengthLevelText>
			</PasswordStrengthContainer>
		</>
	);
};

export default PasswordStrength;
