import { Button } from 'antd';
import { StepContainer, StepFooter, StepHeader } from '../styles';
import { Heading2 } from 'components/Heading2';
import { TextS } from 'components/Text';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Representative, RepresentativeDocuments } from 'types/Representative';
import { useTheme } from 'styled-components';
import { Documents } from 'components/Documents';
import { RepresentativeDocumentsList } from 'constants/representatives';

interface IDocumentsStepProps {
	representative?: Representative;
	isLegalRepresentative?: boolean;
	onNext: (representative: Representative) => void;
	onBack: (representative: Representative) => void;
}
const DocumentsStep = ({
	representative,
	isLegalRepresentative,
	onNext,
	onBack,
}: IDocumentsStepProps) => {
	const theme = useTheme();
	const [isDocumentsInvalid, toggleDocumentsInvalid] =
		useState<boolean>(false);
	const [uploadDocuments, updateUploadDocuments] =
		useState<RepresentativeDocuments>({} as RepresentativeDocuments);

	useEffect(() => {
		if (representative?.documents) {
			updateUploadDocuments(representative?.documents);
		}
	}, [representative]);

	const isDocumentsValid = useMemo(() => {
		return (
			(typeof uploadDocuments['identification'] === 'string' &&
				typeof uploadDocuments['proof_of_address'] === 'string') ||
			(typeof uploadDocuments['identification_url'] === 'string' &&
				typeof uploadDocuments['proof_of_address_url'] === 'string')
		);
	}, [uploadDocuments]);

	const onComplete = useCallback(() => {
		if (isDocumentsValid) {
			onNext({
				documents: uploadDocuments,
			} as Representative);
		} else {
			toggleDocumentsInvalid(true);
		}
	}, [isDocumentsValid, onNext, uploadDocuments]);

	return (
		<StepContainer>
			<StepHeader>
				<Heading2>Documentos</Heading2>
				<TextS weight="normal">{`Documentos necessários do ${
					isLegalRepresentative ? 'procurador' : 'representante'
				}`}</TextS>
			</StepHeader>
			<Documents
				hideTitle={true}
				onChangeDocumentsObject={documents => {
					updateUploadDocuments({
						...uploadDocuments,
						...documents,
					});
				}}
				documentsObject={uploadDocuments}
				documentsList={RepresentativeDocumentsList}
			/>
			{isDocumentsInvalid && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* Adicione todos os documentos obrigatórios
				</TextS>
			)}
			<StepFooter>
				<Button
					type="link"
					onClick={() =>
						onBack({
							documents: uploadDocuments,
						} as Representative)
					}
				>
					Voltar
				</Button>
				<Button
					type="primary"
					onClick={onComplete}
					disabled={!isDocumentsValid}
				>
					Concluir
				</Button>
			</StepFooter>
		</StepContainer>
	);
};

export default DocumentsStep;
