import React, {
	createContext,
	useContext,
	useCallback,
	useState,
	useEffect,
} from 'react';
import Cognito, { CognitoService, User } from '../services/CognitoService';
import { useNavigate } from 'react-router-dom';
import {
	ChallengeName,
	CognitoErrorCode,
	CognitoResponse,
	MFAOptions,
} from '../types/Cognito';
import { AppContext } from './AppContext';
import environment from '../constants/environment';

export enum NewPasswordFlow {
	FIRST_ACCESS = 'FIRST_ACCESS',
	RESET_PASSWORD = 'RESET_PASSWORD',
}

interface IAuthContextProps {
	isLogged: boolean;
	isLoading: boolean;
	errorCode?: CognitoErrorCode;
	mfaOptions?: MFAOptions[];
	cognitoUser?: CognitoResponse;
	newPasswordFlow: NewPasswordFlow | null;
	resetError: () => void;
	login: (username: string, password: string) => void;
	confirmNewPassword: (password: string) => void;
	redefinePassword: (
		password: string,
		newPassword: string,
	) => Promise<boolean>;
	startNewPasswordFlow: (
		username: string,
		tmpPassword: string,
		flow: NewPasswordFlow,
	) => void;
	generateSecretMfa: () => Promise<string>;
	confirmSms: (phoneNumber: string) => Promise<CognitoResponse | undefined>;
	confirmCode: (code: string) => void;
	verifyCode: (code: string) => void;
	logout: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContextProps>({
	isLogged: false,
	isLoading: false,
	errorCode: undefined,
	mfaOptions: undefined,
	cognitoUser: undefined,
	newPasswordFlow: null,
	confirmSms: () => {
		throw new Error('Método não implementado');
	},
	resetError: () => {
		throw new Error('Método não implementado');
	},
	login: () => {
		throw new Error('Método não implementado');
	},
	confirmNewPassword: () => {
		throw new Error('Método não implementado');
	},
	startNewPasswordFlow: () => {
		throw new Error('Método não implementado');
	},
	generateSecretMfa: () => {
		throw new Error('Método não implementado');
	},
	confirmCode: () => {
		throw new Error('Método não implementado');
	},
	redefinePassword: () => {
		throw new Error('Método não implementado');
	},
	verifyCode: () => {
		throw new Error('Método não implementado');
	},
	logout: () => {
		throw new Error('Método não implementado');
	},
});

interface IAuthProviderProps {
	children?: React.ReactNode;
}
export const AuthProvider = ({ children }: IAuthProviderProps) => {
	const [isLogged, toggleIsLogged] = useState(false);
	const [isLoading, toggleIsLoading] = useState(false);
	const [username, updateUsername] = useState<string>();
	const [tempPassword, updateTempPassword] = useState<string>();
	const [newPasswordFlow, updateNewPasswordFlow] =
		useState<NewPasswordFlow | null>(null);
	const [mfaOptions, updateMfaOptions] = useState<MFAOptions[]>([]);
	const [cognitoUser, updateCognitoUser] = useState<CognitoResponse>();
	const [errorCode, updateErrorCode] = useState<CognitoErrorCode>();
	const { toggleLoading, financialInstitutionId } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		CognitoService.init(
			environment.AUTH_POOL_ID,
			environment.AUTH_CLIENT_ID,
		);
		Cognito.currentAuthenticatedUser().then(u => {
			toggleIsLogged(typeof u !== 'undefined');
			toggleLoading(false);
		});
	}, [toggleLoading]);

	const processCognito = useCallback(
		(response: CognitoResponse) => {
			updateCognitoUser(response);

			if (response.challengeName === ChallengeName.MFA_SETUP) {
				updateMfaOptions(
					JSON.parse(response.challengeParam.MFAS_CAN_SETUP),
				);
				navigate('/two-factor');
				return;
			}
			if (response.challengeName === ChallengeName.SOFTWARE_TOKEN_MFA) {
				navigate('/two-factor');
				return;
			}
		},
		[navigate],
	);

	const login = useCallback(
		(username: string, password: string) => {
			toggleIsLoading(true);
			Cognito.signIn(`${financialInstitutionId}_${username}`, password)
				.then(res => {
					updateUsername(username);
					if (
						res.challengeName ===
						ChallengeName.NEW_PASSWORD_REQUIRED
					) {
						updateTempPassword(password);
						navigate('/new-password');
						return;
					}

					processCognito(res);
				})
				.catch(() => {
					updateErrorCode(CognitoErrorCode.LOGIN_FAILED);
				})
				.finally(() => toggleIsLoading(false));
		},
		[financialInstitutionId, navigate, processCognito],
	);

	const startNewPasswordFlow = useCallback(
		(username: string, tmpPassword: string, flow: NewPasswordFlow) => {
			updateUsername(username);
			updateTempPassword(tmpPassword);
			updateNewPasswordFlow(flow);
		},
		[],
	);

	const generateSecretMfa = useCallback(async () => {
		const currentUser =
			cognitoUser ?? (await Cognito.currentAuthenticatedUser());
		if (currentUser) {
			const currentUsername =
				username ??
				(currentUser as User).attributes.email?.split('_')?.[1];
			const secret = await Cognito.setupMFA(currentUser as any);
			return `otpauth://totp/Escrow:${currentUsername}?secret=${secret}&issuer=Celcoin`;
		}
		return Promise.resolve('');
	}, [cognitoUser, username]);

	const confirmSms = useCallback(
		async (phoneNumber: string) => {
			if (cognitoUser) {
				const response = await Cognito.setupSMS(
					cognitoUser,
					phoneNumber,
				);
				return response as unknown as CognitoResponse;
			}
		},
		[cognitoUser],
	);

	const confirmCode = useCallback(
		(code: string) => {
			if (cognitoUser) {
				toggleIsLoading(true);
				Cognito.confirmSignIn(cognitoUser, code)
					.then(response => {
						if (response) {
							toggleIsLogged(true);
							navigate('/');
							return;
						}
						updateErrorCode(CognitoErrorCode.MFA_FAILED);
					})
					.finally(() => toggleIsLoading(false));
			}
		},
		[navigate, cognitoUser],
	);

	const verifyCode = useCallback(
		(code: string) => {
			if (cognitoUser) {
				toggleIsLoading(true);
				Cognito.verifyCode(cognitoUser, code)
					.then(response => {
						if (response) {
							toggleIsLogged(true);
							navigate('/');
							return;
						}
						updateErrorCode(CognitoErrorCode.MFA_FAILED);
					})
					.finally(() => toggleIsLoading(false));
			}
		},
		[navigate, cognitoUser],
	);

	const redefinePassword = useCallback(
		async (password: string, newPassword: string) => {
			toggleIsLoading(true);
			try {
				const completeResponse = await Cognito.changePassword(
					password,
					newPassword,
				);
				return completeResponse === 'SUCCESS';
			} catch (e) {
				return false;
			} finally {
				toggleIsLoading(false);
			}
		},
		[],
	);

	const confirmNewPassword = useCallback(
		async (newPassword: string) => {
			if (username && tempPassword) {
				toggleIsLoading(true);
				try {
					if (newPasswordFlow !== null) {
						const signInResponse = await Cognito.signIn(
							`${financialInstitutionId}_${username}`,
							tempPassword,
						);
						if (
							signInResponse.challengeName !==
							ChallengeName.NEW_PASSWORD_REQUIRED
						) {
							updateErrorCode(
								CognitoErrorCode.NEW_PASSWORD_FAILED,
							);
							toggleIsLoading(false);
						}
					}
					const completeResponse = await Cognito.completeNewPassword(
						`${financialInstitutionId}_${username}`,
						tempPassword,
						newPassword,
					);
					if (completeResponse) {
						processCognito(completeResponse);
						return;
					}
					updateErrorCode(CognitoErrorCode.NEW_PASSWORD_FAILED);
				} catch (e) {
					updateErrorCode(CognitoErrorCode.NEW_PASSWORD_FAILED);
				} finally {
					toggleIsLoading(false);
				}
			}
		},
		[
			processCognito,
			financialInstitutionId,
			newPasswordFlow,
			tempPassword,
			username,
		],
	);

	const logout = useCallback(() => {
		return Cognito.signOut().then(() => toggleIsLogged(false));
	}, []);

	const resetError = useCallback(() => {
		if (errorCode) updateErrorCode(undefined);
	}, [errorCode]);

	return (
		<AuthContext.Provider
			value={{
				isLogged,
				isLoading,
				errorCode,
				mfaOptions,
				cognitoUser,
				newPasswordFlow,
				confirmSms,
				login,
				resetError,
				generateSecretMfa,
				confirmCode,
				verifyCode,
				confirmNewPassword,
				startNewPasswordFlow,
				redefinePassword,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuthContext must be used within a AuthProvider');
	}
	return context;
};
