import { ForwardButton } from './styles';
import { Col, Row } from 'antd';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { TextM } from 'components/Text';

interface IPostingCreationSuccessProps {
	onClose: () => void;
}
const PostingCreationSuccess = ({ onClose }: IPostingCreationSuccessProps) => {
	return (
		<>
			<Row gutter={[16, 16]} align="middle">
				<Col span={3}>
					<CheckmarkIcon />
				</Col>
				<Col span={21}>
					<TextM weight="normal">
						A sua carteira de cobrança foi criada com sucesso!
					</TextM>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<ForwardButton onClick={onClose} type="primary">
						<TextM>Ok, obrigado</TextM>
					</ForwardButton>
				</Col>
			</Row>
		</>
	);
};

export default PostingCreationSuccess;
