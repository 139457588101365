import { Form } from 'antd';
import styled from 'styled-components';
import { CompanyListFiltersType } from './types';

export const FiltersTitle = styled.div`
	display: flex;
	p {
		padding-left: 0.3rem;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.border};
	padding: 0 0 1rem;

	svg {
		color: ${({ theme }) => theme.textSecondary};
	}
`;

export const FiltersForm = styled(Form<CompanyListFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;
