import { useState } from 'react';
import {
	Button,
	Col,
	Flex,
	Form,
	Input,
	Row,
	Select,
	Spin,
	Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Heading2 } from 'components/Heading2';
import { TextL, TextS } from 'components/Text';
import { IoMdArrowForward, IoMdInformationCircleOutline } from 'react-icons/io';
import { CancelButton, ForwardButton } from '../styles';
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { ChargeReceiver } from 'types/Wallet';
import { betweenRangeNumberValue, validateZeroPercentage } from 'helpers/rules';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { useTheme } from 'styled-components';
import { BsExclamationTriangle } from 'react-icons/bs';

import { ColumnsType } from 'antd/es/table';
import { Destination } from 'types/Destination';
import { BankListDescription } from 'constants/banksList';
import { BankListCode } from 'constants/bankListCode';
import { formatToFixed, normalizeBankAccount } from 'helpers/normalizers';
import { LuTrash2 } from 'react-icons/lu';
import { DestinationTable, WarningCard } from './styles';
import { Account } from 'types/Account';
import { AccountDestination } from 'types/Management';
import { ConfirmationModal } from 'components/ConfirmationModal';

export type ColumnsData = {
	id: string;
	key: string;
	accountDestination: Destination | undefined;
	share: number;
};

interface IChargesNewInfoProps {
	info?: ChargeReceiver[];
	data?: Account;
	loading?: boolean;
	onBack: (info: ChargeReceiver[]) => void;
	onNext: (info: ChargeReceiver[]) => void;
	updateChargeReceiver: (info: ChargeReceiver[]) => void;
}

const BENEFICIARY_TOOLTIP = `Caso nenhum beneficiário seja adicionado, o valor total da cobrança será creditado na conta principal.`;

const ChargesBeneficiaries = ({
	data,
	info,
	loading,
	onBack,
	onNext,
	updateChargeReceiver,
}: IChargesNewInfoProps) => {
	const theme = useTheme();
	const [form] = useForm();

	const [destinationToConfirm, updateDestinationToConfirm] = useState<
		Destination | undefined
	>();
	const [isDestinationRemoveOpen, toggleIsDestinationRemoveOpen] =
		useState(false);

	const invalidRule =
		info &&
		info.length > 0 &&
		info.reduce(
			(acc, account) => acc + Number(account.split_percentage),
			0,
		) > 100;

	const handleAddBeneficiary = (values: ChargeReceiver) => {
		const updated = [
			...(info as ChargeReceiver[]),
			{
				account_destination_id: values.account_destination_id,
				split_percentage: Number(values.split_percentage),
			},
		];
		updateChargeReceiver(updated);
		form.resetFields();
	};

	const handleNext = () => {
		if (invalidRule) return;
		onNext(info ?? []);
	};

	const dataSource =
		info && info.length > 0
			? info.map(account => {
					const accountDestination = data?.destinations.find(
						destination =>
							destination.id === account.account_destination_id,
					);

					return {
						id: accountDestination?.id,
						key: accountDestination?.id,
						accountDestination: accountDestination,
						share: account.split_percentage,
					};
				})
			: [];

	const columns: ColumnsType<ColumnsData> = [
		{
			title: 'Conta beneficiária',
			key: 'beneficiaryAccount',
			align: 'left',
			render: (account: ColumnsData) => {
				return (
					<>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textSecondary,
							}}
							weight="bold"
						>
							{account.accountDestination?.name ?? '-'}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Banco:{' '}
							{
								BankListDescription[
									(account.accountDestination
										?.bank as keyof typeof BankListCode) ??
										''
								]
							}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Ag.: {account.accountDestination?.branch}
						</TextS>
						<TextS
							style={{
								fontSize: '0.7rem',
								color: theme.textGray,
							}}
						>
							Cc.:{' '}
							{normalizeBankAccount(
								account.accountDestination?.account ?? '',
							)}
						</TextS>
					</>
				);
			},
		},
		{
			title: 'Tipo de transferência',
			key: 'transferType',
			align: 'left',
			render: () => (
				<TextS style={{ color: theme.textGray }}>Percentual</TextS>
			),
		},
		{
			title: 'Valor',
			key: 'share',
			align: 'left',
			render: (account: ColumnsData) => (
				<TextS style={{ color: theme.textGray }}>
					{formatToFixed(account.share, 2)}%
				</TextS>
			),
		},
		{
			title: '',
			width: '10%',
			key: 'actions',
			render: (account: ColumnsData) => (
				<Button
					type="link"
					onClick={() => {
						updateDestinationToConfirm(account.accountDestination);
						toggleIsDestinationRemoveOpen(true);
					}}
				>
					<LuTrash2 style={{ color: theme.danger }} />
				</Button>
			),
		},
	];

	if (loading) {
		return <Spin />;
	}

	return (
		<>
			<Row
				style={{
					flexDirection: 'column',
					overflowY: 'auto',
					overflowX: 'hidden',
					maxWidth: 1000,
				}}
			>
				<Row>
					<Tooltip title={BENEFICIARY_TOOLTIP} placement="right">
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							<Heading2 style={{ margin: '30px 0px' }}>
								Beneficiários
							</Heading2>
							<IoMdInformationCircleOutline size={16} />
						</div>
					</Tooltip>
				</Row>
				<Form<AccountDestination>
					form={form}
					layout="vertical"
					onFinish={handleAddBeneficiary}
					initialValues={{
						beneficiaryAccount: undefined,
						splitPercentage: undefined,
					}}
				>
					<Row
						style={{
							flexDirection: 'column',
						}}
					>
						<Row style={{ marginTop: '1rem' }} gutter={[10, 10]}>
							<Col span={10}>
								<Form.Item
									label={
										<TextS>
											Selecione a conta beneficiária
										</TextS>
									}
									name="account_destination_id"
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
									]}
								>
									<Select
										placeholder="Selecione"
										options={data?.destinations
											.map(account => ({
												label: `${account.name} - ${normalizeBankAccount(
													account.account ?? '',
												)}`,
												value: account.id,
											}))
											.filter(account => {
												return !info?.some?.(
													state =>
														state.account_destination_id ===
														account.value,
												);
											})}
									/>
								</Form.Item>
							</Col>
							<Col span={10}>
								<Form.Item
									name="split_percentage"
									label={<TextS>Cota percentual</TextS>}
									rules={[
										betweenRangeNumberValue(
											'split_percentage',
											0.01,
											100,
										),
										validateZeroPercentage(
											'split_percentage',
										),
									]}
								>
									<Input
										placeholder="0"
										inputMode="numeric"
										type="number"
										min={0}
										prefix="%"
										max={100}
									/>
								</Form.Item>
							</Col>
							<Col
								span={4}
								style={{ height: '70px', display: 'flex' }}
							>
								<ButtonPrimary
									type="primary"
									style={{
										alignSelf: 'flex-end',
										minHeight: '40px',
										padding: '10px',
									}}
									onClick={form.submit}
								>
									<Flex
										align="center"
										justify="space-between"
										style={{
											width: '100%',
										}}
									>
										<Flex
											justify="center"
											style={{
												width: '100%',
											}}
										>
											<TextS
												style={{ color: theme.white }}
											>
												Adicionar
											</TextS>
										</Flex>
										<AddCircleIcon size="18" />
									</Flex>
								</ButtonPrimary>
							</Col>
						</Row>

						<Row style={{ margin: '2rem 0' }}>
							<Col span={24}>
								<DestinationTable
									rowKey={record => record.id}
									pagination={false}
									columns={columns}
									dataSource={dataSource}
								/>
							</Col>
						</Row>
						{invalidRule && (
							<WarningCard bordered={false}>
								<Row gutter={[10, 10]}>
									<Col>
										<Flex
											style={{ height: '100%' }}
											align="center"
										>
											<BsExclamationTriangle
												style={{ color: theme.danger }}
											/>
										</Flex>
									</Col>
									<Col>
										<TextS
											weight="bold"
											style={{
												color: theme.dangerDisabled,
											}}
										>
											Regra inválida.
										</TextS>
										<TextS
											style={{
												color: theme.textSecondary,
											}}
										>
											Para fazer a emissão do boleto é
											necessário que o valor total da cota
											percentual dos beneficiários seja
											menor ou igual a 100%.
										</TextS>
									</Col>
								</Row>
							</WarningCard>
						)}
					</Row>
				</Form>
				<Row
					gutter={[16, 16]}
					style={{ margin: '3rem 0 1rem' }}
					justify="end"
				>
					<Col>
						<CancelButton onClick={() => onBack(info ?? [])}>
							<TextS>Voltar</TextS>
						</CancelButton>
					</Col>
					<Col>
						<ForwardButton type="primary" onClick={handleNext}>
							<TextS>Avançar</TextS>
							<IoMdArrowForward size={16} />
						</ForwardButton>
					</Col>
				</Row>
			</Row>
			<ConfirmationModal
				isOpen={isDestinationRemoveOpen}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				onConfirm={() => {
					if (destinationToConfirm) {
						const updated =
							info?.filter(
								state =>
									state.account_destination_id !==
									destinationToConfirm?.id,
							) || [];

						updateChargeReceiver(updated);

						updateDestinationToConfirm(undefined);
						toggleIsDestinationRemoveOpen(false);
					}
				}}
				onCancel={() => toggleIsDestinationRemoveOpen(false)}
				onClose={() => toggleIsDestinationRemoveOpen(false)}
			>
				<TextL>
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextL>
			</ConfirmationModal>
		</>
	);
};

export default ChargesBeneficiaries;
