import { Col, Form, Input, Row, Select } from 'antd';
import { Heading2 } from 'components/Heading2';
import {
	normalizeCep,
	normalizeCpfCnpj,
	normalizePhone,
} from 'helpers/normalizers';
import { cpfCnpjRule } from 'helpers/rules';
import { TextL, TextS } from 'components/Text';
import { statesList } from 'constants/states';
import { IoMdArrowForward } from 'react-icons/io';
import { ForwardButton } from '../styles';
import { PayerType } from '../types';

interface IChargesNewPayerProps {
	payer?: PayerType;
	onNext: (payer: PayerType) => void;
}
const ChargesNewPayer = ({ payer, onNext }: IChargesNewPayerProps) => {
	return (
		<Row style={{ maxWidth: 1000 }}>
			<Col span={24}>
				<Row>
					<Col span={24}>
						<Heading2 style={{ margin: '30px 0px' }}>
							Dados do Pagador
						</Heading2>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form<PayerType>
							style={{ width: '100%' }}
							initialValues={{
								name: payer?.name,
								taxpayer_id:
									payer?.taxpayer_id &&
									normalizeCpfCnpj(payer.taxpayer_id),
								phone:
									payer?.phone &&
									normalizePhone(payer?.phone),
								email_address: payer?.email_address,
								postal_code: payer?.postal_code,
								street: payer?.street,
								number: payer?.number,
								state: payer?.state,
								city: payer?.city,
								district: payer?.district,
							}}
							layout="vertical"
							onFinish={values => onNext(values)}
						>
							<Row style={{ marginBottom: '16px' }}>
								<Col span={24}>
									<TextL>Dados básicos</TextL>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={17}>
									<Form.Item
										name="name"
										label="Nome completo ou razão social"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="Digite aqui" />
									</Form.Item>
								</Col>
								<Col span={7}>
									<Form.Item
										name="taxpayer_id"
										label="CPF / CNPJ"
										normalize={e => normalizeCpfCnpj(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cpfCnpjRule,
										]}
									>
										<Input placeholder="000.000.000-00" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										name="phone"
										label="Telefone"
										normalize={e => normalizePhone(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="(00) 0 0000-0000" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="email_address"
										label="E-mail"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="Digite aqui" />
									</Form.Item>
								</Col>
							</Row>
							<Row style={{ margin: '30px 0 16px' }}>
								<Col span={24}>
									<TextL>Endereço</TextL>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={6}>
									<Form.Item
										name="postal_code"
										label="CEP"
										normalize={e => normalizeCep(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="00000-000" />
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										name="state"
										label="Estado"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione o estado"
											showSearch={true}
											filterOption={(input, option) =>
												option?.label
													?.toLowerCase()
													?.startsWith(
														input.toLowerCase(),
													) ||
												String(option?.value)
													?.toLowerCase()
													?.startsWith(
														input.toLowerCase(),
													)
											}
											options={statesList}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										name="city"
										label="Cidade"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="Digite aqui" />
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										name="district"
										label="Bairro"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="Digite aqui" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										name="street"
										label="Rua/Avenida"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="Digite aqui" />
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item
										name="number"
										label="Número"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Input placeholder="Digite aqui" />
									</Form.Item>
								</Col>
							</Row>
							<Row
								gutter={[16, 16]}
								style={{ margin: '3rem 0 1rem' }}
								justify="end"
							>
								<Col>
									<ForwardButton
										type="primary"
										htmlType="submit"
									>
										<TextS>Avançar</TextS>
										<IoMdArrowForward size={16} />
									</ForwardButton>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ChargesNewPayer;
