import styled from 'styled-components';
import { Theme } from 'theme/theme';

const getColorByStrength = (
	strength: null | 'weak' | 'medium' | 'high' | 'strong',
	defaultColor: string,
	theme: Theme,
): string => {
	if (strength === 'weak') return theme.error || 'red';
	if (strength === 'medium') return theme.attention || 'yellow';
	if (strength === 'high') return theme.success || 'green';
	if (strength === 'strong') return theme.success || 'green';
	return defaultColor;
};

export const PasswordRequirements = styled.div`
	margin: 1rem 0px;
`;

export const PasswordRequirement = styled.div`
	display: flex;
	align-items: center;
	span {
		margin-left: 10px;
		font-size: 1rem;
	}
`;

export const PasswordStrengthContainer = styled.div`
	margin: 0px 24px;
`;

export const PasswordStrengthLevelText = styled.div<{
	strength: null | 'weak' | 'medium' | 'high' | 'strong';
}>`
	display: flex;
	justify-content: space-between;
	margin-top: 8px;

	span {
		display: block;
	}
	span:nth-of-type(2) {
		font-weight: bold;
		color: ${({ theme, strength }) =>
			getColorByStrength(strength, 'black', theme)};
	}
`;

export const PasswordStrengthLevels = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
`;

export const PasswordStrengthLevel = styled.span<{
	strength: null | 'weak' | 'medium' | 'high' | 'strong';
}>`
	height: 5px;
	width: 24%;
	display: block;
	background-color: ${({ theme, strength }) =>
		getColorByStrength(strength, theme.border, theme)};
`;

export const PasswordCheckIcon = styled.div<{ isChecked: boolean }>`
	width: 2rem;
	svg {
		fill: ${({ theme, isChecked }) =>
			isChecked ? theme.success : theme.error};
		font-size: 1rem;
	}
`;
