import { Col, Row } from 'antd';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { TextL, TextM, TextS } from 'components/Text';
import dayjs from 'dayjs';
import {
	formatCurrency,
	normalizeBoleto,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { useTheme } from 'styled-components';
import { Posting, PostingType } from 'types/Posting';
import {
	BackgroundModalData,
	DateFormIcon,
	ModalTextArea,
	PaymentCreationDateAmount,
	Spinner,
} from './styles';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PostingsService } from 'modules/escrow/services';
import { PiSpinnerBold } from 'react-icons/pi';
import { CompanyIcon } from 'components/Icons/Company';
import { PaymentPersonIcon } from 'components/Icons/PaymentPerson';
import { PaymentPayerIcon } from 'components/Icons/PaymentPayer';
import { BarCodeIcon } from 'components/Icons/BarCode';
import { CalendarIcon } from 'components/Icons/Calendar';
import { PaymentCalendarIcon } from 'components/Icons/PaymentCalendar';

export interface PostingApprovalReviewProps {
	postingData: Posting[];
	onClose: () => void;
	onConfirm: (comment: string) => void;
}

const PostingApprovalReview = ({
	postingData,
	onClose,
	onConfirm,
}: PostingApprovalReviewProps) => {
	const theme = useTheme();
	const [comment, setComment] = useState('');
	const isBarcode = postingData[0].type === PostingType.BARCODE;

	const { data: paymentData, isPending } = useQuery({
		queryKey: ['barCode', postingData[0].id],
		queryFn: () => {
			return PostingsService.getBarCodeDetails(postingData[0].bar_code!);
		},
		enabled:
			isBarcode && postingData.length === 1 && !!postingData[0].bar_code,
	});

	return (
		<>
			<Row style={{ marginBottom: '1.4rem' }}>
				<TextL weight="bold">
					{postingData.length > 1
						? `Você tem certeza que deseja aprovar as ${postingData.length} solicitações?`
						: `Você tem certeza que deseja aprovar esta solicitação${
								isBarcode ? ' de pagamento' : ''
							}?`}
				</TextL>
			</Row>
			{postingData.length === 1 && (
				<>
					{isBarcode ? (
						<>
							{isPending || !paymentData ? (
								<div
									style={{
										position: 'relative',
										width: '30px',
										margin: '0 auto',
									}}
								>
									<Spinner>
										<PiSpinnerBold
											style={{ fontSize: 32 }}
										/>
									</Spinner>
								</div>
							) : (
								<Row style={{ marginTop: '1rem' }}>
									<Col
										span={24}
										style={{
											backgroundColor:
												theme.primaryOpacity,
											borderRadius: '10px',
											padding: '16px 0px',
										}}
									>
										<Row
											align="middle"
											style={{ position: 'relative' }}
										>
											<Col
												span={3}
												style={{ textAlign: 'center' }}
											>
												<DateFormIcon>
													<CompanyIcon
														color={theme.primary}
													/>
												</DateFormIcon>
											</Col>
											<Col span={21}>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													Instituição Financeira
												</TextS>
												<TextM color={theme.text}>
													{paymentData.assignor}
												</TextM>
											</Col>
											<PaymentCreationDateAmount>
												{formatCurrency(
													postingData[0].amount,
												)}
											</PaymentCreationDateAmount>
										</Row>
										<Row
											align="middle"
											style={{ marginTop: '1rem' }}
										>
											<Col
												span={3}
												style={{ textAlign: 'center' }}
											>
												<DateFormIcon>
													<PaymentPersonIcon
														color={theme.primary}
													/>
												</DateFormIcon>
											</Col>
											<Col span={21}>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													Nome do Beneficiário
												</TextS>
												<TextM>
													{
														paymentData
															.register_data
															.recipient
													}
												</TextM>
											</Col>
										</Row>
										<Row
											align="middle"
											style={{ marginTop: '1rem' }}
										>
											<Col
												span={3}
												style={{ textAlign: 'center' }}
											>
												<DateFormIcon>
													<PaymentPayerIcon
														color={theme.primary}
													/>
												</DateFormIcon>
											</Col>
											<Col span={21}>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													Nome do Pagador
												</TextS>
												<TextM>
													{
														paymentData
															.register_data.payer
													}
												</TextM>
											</Col>
										</Row>
										<Row
											align="middle"
											style={{ marginTop: '1rem' }}
										>
											<Col
												span={3}
												style={{ textAlign: 'center' }}
											>
												<DateFormIcon>
													<BarCodeIcon
														color={theme.primary}
													/>
												</DateFormIcon>
											</Col>
											<Col span={21}>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													Código de barras
												</TextS>
												<TextM>
													{normalizeBoleto(
														postingData[0].bar_code,
													)}
												</TextM>
											</Col>
										</Row>
										<Row
											align="middle"
											style={{ marginTop: '1rem' }}
										>
											<Col
												span={3}
												style={{ textAlign: 'center' }}
											>
												<DateFormIcon>
													<CalendarIcon
														color={theme.primary}
													/>
												</DateFormIcon>
											</Col>
											<Col span={21}>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													Vencimento
												</TextS>
												<TextM>
													{dayjs(
														paymentData.due_date,
													).format('DD/MM/YYYY')}
												</TextM>
											</Col>
										</Row>
										<Row
											align="middle"
											style={{ marginTop: '1rem' }}
										>
											<Col
												span={3}
												style={{ textAlign: 'center' }}
											>
												<DateFormIcon>
													<PaymentCalendarIcon
														color={theme.primary}
													/>
												</DateFormIcon>
											</Col>
											<Col span={21}>
												<TextS
													color={theme.textGray}
													weight={400}
												>
													Pagamento
												</TextS>
												<TextM>
													{dayjs(
														postingData[0]
															.scheduled_date,
														'YYYY-MM-DD',
													).format('DD/MM/YYYY')}
												</TextM>
											</Col>
										</Row>
									</Col>
								</Row>
							)}
						</>
					) : (
						<BackgroundModalData>
							<Row
								style={{ margin: '1rem 0.6rem', gap: '1rem' }}
								justify="space-between"
							>
								<Col span={10}>
									<TextM weight="bold">Origem:</TextM>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{postingData?.[0]?.account.person.name}
									</TextS>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{`CNPJ: 
                ${normalizeCpfCnpj(
					postingData?.[0]?.account?.person?.taxpayer_id ?? '',
				)}`}
									</TextS>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{`Conta: ${postingData?.[0]?.account.account}`}
									</TextS>
								</Col>
								<Col span={10}>
									<TextM weight="bold">Destino:</TextM>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{
											postingData?.[0]
												?.account_destination.name
										}
									</TextS>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{`CNPJ: 
                ${normalizeCpfCnpj(
					postingData?.[0]?.account_destination.taxpayer_id ?? '',
				)}`}
									</TextS>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{`Conta: ${postingData?.[0]?.account_destination.account}`}
									</TextS>
								</Col>
							</Row>
							<Row
								style={{ margin: '1rem 0.6rem', gap: '1rem' }}
								justify="space-between"
							>
								<Col span={10}>
									<TextM weight="bold">
										Data da Solicitação:
									</TextM>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{dayjs(
											postingData?.[0]?.created_at,
										).format('DD/MM/YYYY')}{' '}
										-{' '}
										{dayjs(
											postingData?.[0]?.created_at,
										).format('HH:mm')}
									</TextS>
								</Col>
								<Col span={10}>
									<TextM weight="bold">Valor:</TextM>
									<TextS
										color={theme.textSecondary}
										weight={400}
									>
										{formatCurrency(
											postingData?.[0]?.amount,
										)}
									</TextS>
								</Col>
							</Row>
						</BackgroundModalData>
					)}
				</>
			)}
			<Row style={{ margin: '1rem 0', gap: '1rem' }}>
				<TextM>Comentário (Opcional)</TextM>
			</Row>
			<ModalTextArea
				rows={3}
				placeholder="Digite aqui"
				maxLength={220}
				value={comment}
				style={{
					minHeight: postingData.length > 1 ? '200px' : 'auto',
				}}
				onChange={e => setComment(e.target.value)}
			/>

			<Row style={{ margin: '1rem 0 0', gap: '1rem' }}>
				<ButtonPrimary
					onClick={() => onConfirm(comment)}
					style={{ justifyContent: 'center', padding: '1.4rem' }}
				>
					<TextM style={{ color: theme.white }}>
						Aprovar solicitação
					</TextM>
				</ButtonPrimary>

				<ButtonPrimary
					variant="secondary"
					onClick={onClose}
					style={{
						justifyContent: 'center',
						padding: '1.4rem',
						border: 0,
						boxShadow: 'none',
					}}
				>
					<TextM style={{ color: theme.primary }}>Cancelar</TextM>
				</ButtonPrimary>
			</Row>
		</>
	);
};

export default PostingApprovalReview;
