import { CustomDrawer } from 'components/CustomDrawer';
import { EscrowLeadsFiltersType } from '../types';
import { Button, Col, Input, Row, Select } from 'antd';
import { LuSearch } from 'react-icons/lu';
import { BsPerson } from 'react-icons/bs';
import { useEffect } from 'react';
import { FiltersForm, FiltersFormItem } from '../styles';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';

interface IEscrowLeadsFiltersProps {
	filters?: EscrowLeadsFiltersType;
	isOpen: boolean;
	onApply: (filters: EscrowLeadsFiltersType) => void;
	onClose: () => void;
}

export function EscrowLeadsFilters({
	filters,
	isOpen,
	onApply,
	onClose,
}: IEscrowLeadsFiltersProps) {
	const [form] = FiltersForm.useForm();

	const initialValues = {
		account: filters?.account,
		creator_name: filters?.creator_name,
		document_type: filters?.document_type,
	};

	const onFinish = (values: EscrowLeadsFiltersType) => {
		onApply({
			...values,
			account: values.account,
			creator_name: values.creator_name,
			document_type: values.document_type,
		} as EscrowLeadsFiltersType);

		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue(initialValues);
		}
	}, [isOpen, form, filters]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={initialValues}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem label="Conta" name="account">
							<Input
								placeholder="Digite aqui"
								prefix={<MdOutlineAccountBalanceWallet />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="creator_name"
							label="Criador da Conta"
						>
							<Input
								placeholder="Digite aqui"
								prefix={<BsPerson />}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
}
