import { NetworkApi } from 'network';
import {
	AccountDestination,
	TransferRuleCreated,
	TransferRuleDto,
	TransferRuleFilters,
	TransferRuleStatus,
} from 'types/Management';
import {
	DepositManagementItem,
	IDepositManagementListParams,
	IDepositRetentionPayload,
} from '../pages/DepositManagement/types';
import { List } from 'types/List';
import {
	CustomFeeDTO,
	ListFeeFilters,
} from 'components/FeeSettingsModal/types';

export interface UpdateTransferRule {
	destinations?: AccountDestination[];
	status: TransferRuleStatus;
}

export class ManagementService extends NetworkApi {
	public static getTransferRules(params: TransferRuleFilters) {
		return this.get<TransferRuleCreated[], TransferRuleFilters>(
			'/transfer-rules',
			params,
		);
	}

	public static createAccountTransferRule(transferRule: TransferRuleDto) {
		return this.post<void, TransferRuleDto>(
			'/transfer-rules',
			transferRule,
		);
	}

	public static updateAccountTransferRule(
		transferRule: UpdateTransferRule,
		transferRuleId: string,
	) {
		return this.patch<void, UpdateTransferRule>(
			`/transfer-rules/${transferRuleId}`,
			{
				status: transferRule.status,
			},
		);
	}

	public static createDepositRetention(
		accountId: string,
		payload: IDepositRetentionPayload,
	) {
		return this.post<void, IDepositRetentionPayload>(
			`/accounts/${accountId}/deposit-retention`,
			payload,
		);
	}

	public static getDepositRetention(
		accountId: string,
		params: IDepositManagementListParams,
	) {
		return this.get<List<DepositManagementItem>>(
			`/accounts/${accountId}/deposit-retention`,
			params,
		);
	}

	public static deleteDepositRetention(
		accountId: string,
		retentionId: string,
	) {
		return this.delete<void>(
			`/accounts/${accountId}/deposit-retention/${retentionId}`,
		);
	}

	public static editDepositRetention(
		accountId: string,
		retentionId: string,
		payload: IDepositRetentionPayload,
	) {
		return this.patch<void, IDepositRetentionPayload>(
			`/accounts/${accountId}/deposit-retention/${retentionId}`,
			payload,
		);
	}

	public static listFees(accountId: string, ListFeeFilter?: ListFeeFilters) {
		return this.get<List<CustomFeeDTO>>(`/fees`, {
			account_id: accountId,
			type: ListFeeFilter?.type,
		});
	}

	public static createCustomFee(payload: CustomFeeDTO) {
		return this.post<void, CustomFeeDTO>(`/fees`, payload);
	}
}
