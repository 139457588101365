import React from 'react';

export const StarIcon: React.FC<{ color?: string; size?: number }> = ({
	color,
	size,
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={size ?? '24'}
		height={size ?? '24'}
		fill="none"
	>
		<path
			fill={color ?? '#7664FA'}
			d="M11.9998 17L6.12197 20.5902L7.72007 13.8906L2.48926 9.40983L9.35479 8.85942L11.9998 2.5L14.6449 8.85942L21.5104 9.40983L16.2796 13.8906L17.8777 20.5902L11.9998 17Z"
		/>
	</svg>
);
