import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavBar } from 'components/NavBar';
import {
	DefaultTemplateContainer,
	DefaultTemplateContent,
	DefaultTemplateWrapper,
	TermsPageWrapper,
} from './styles';
import { SideBar } from 'components/SideBar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { cancelPreviousPageRequests } from 'network/instances';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { AppContext, useBreadcrumb } from 'modules/core/context/AppContext';
import { Breadcrumb } from 'components/Breadcrumb';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { SplashScreen } from 'components/SplashScreen';
import ReadTermsPage from 'modules/core/pages/ReadTerms/ReadTerms';

export function DefaultTemplate() {
	const [isCollapsed, toggleCollapsed] = useState(false);
	const { isLoading: isContextLoading, isAppLoading } =
		useContext(AppContext);

	const { isLogged } = useAuthContext();
	const { selectedUserAccess, userProfile, isProfileLoading } =
		useContext(ProfileContext);
	const { breadcrumb } = useBreadcrumb();
	const location = useLocation();

	const previousLocation = useRef<string>();
	const currentLocation = useRef<string>();

	useEffect(() => {
		if (!previousLocation.current && !currentLocation.current) {
			previousLocation.current = location.pathname;
			currentLocation.current = location.pathname;
		} else {
			previousLocation.current = currentLocation.current;
			currentLocation.current = location.pathname;
		}
		if (previousLocation.current !== currentLocation.current) {
			cancelPreviousPageRequests(previousLocation.current!);
		}
	}, [location.pathname]);

	const isLoading = isAppLoading || isProfileLoading || isContextLoading;
	if (isLoading) {
		return <SplashScreen />;
	}

	if (!isLogged) return <Navigate to="/login" />;

	if (!selectedUserAccess) {
		return <SplashScreen />;
	}

	if (
		!!userProfile?.id &&
		!userProfile?.terms_portal_accepted_at &&
		!isLoading
	) {
		return (
			<TermsPageWrapper>
				<ReadTermsPage />
			</TermsPageWrapper>
		);
	}

	return (
		<DefaultTemplateWrapper>
			<SideBar
				isCollapsed={isCollapsed}
				toggleCollapsed={toggleCollapsed}
			/>
			<DefaultTemplateContainer isCollapsed={isCollapsed}>
				<NavBar />
				<Breadcrumb breadcrumb={breadcrumb} />
				<DefaultTemplateContent className="default-template-content">
					<Outlet />
				</DefaultTemplateContent>
			</DefaultTemplateContainer>
		</DefaultTemplateWrapper>
	);
}
