import styled from 'styled-components';

export const InfinityScrollContent = styled.div`
	position: absolute;
	bottom: 0px;
	padding: 12px 24px 12px 16px;
	border-radius: 7777px;
	width: 200px;
	height: 64px;
	z-index: 10;
	left: calc(50% - calc(290px / 2));
	background-color: white;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	gap: 1rem;
	align-items: center;
	color: #1b104e;
	border: 1px solid ${({ theme }) => theme.border};
	box-shadow:
		0px 0px 4px -6px rgba(0, 0, 0, 0.08),
		0px 24px 48px -4px rgba(0, 0, 0, 0.08);
`;

export const InfinityScrollLoader = styled.div`
	position: absolute;
	bottom: 20px;
	width: 290px;
	height: 64px;
	content: ' ';
	z-index: 10;
	left: calc(50% - calc(290px / 2));
`;
