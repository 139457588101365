import { Button, Form } from 'antd';
import styled from 'styled-components';
import { TokenForm, PostingForm } from './types';
import { TextM, TextS } from 'components/Text';

export const PostingCreationForm = styled(Form<PostingForm>)`
	padding: 0;
	margin: 0;
	min-height: 413px;
	display: flex;
	flex-direction: column;
`;

export const BackgroundModalData = styled.div`
	width: 100%;
	background-color: #f4f4ff;
	padding: 0.2rem 1rem;
	border-radius: 1rem;

	${TextM} {
		font-family: 'Inter', sans-serif !important;
	}
	${TextS} {
		font-family: 'Inter', sans-serif !important;
		font-size: 14px;
	}
`;

export const PostingTokenForm = styled(Form<TokenForm>)`
	padding: 0;
	margin: 0;
	min-height: 413px;
	display: flex;
	flex-direction: column;
`;

export const ModalTextArea = styled.textarea`
	width: 100%;
	min-height: 4rem;
	padding: 0.5rem;
	font-family: 'Inter', sans-serif;
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.border};
	resize: none;
`;

export const ForwardButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;

	p {
		font-size: 18px;
		color: white;
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const StepsWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
`;

export const CancelButton = styled(Button)`
	display: flex;
	align-items: center;
	height: 45px;
	gap: 0.3rem;
	width: 100%;
	margin-top: 1rem;
	border: none;
	box-shadow: none;
	p {
		font-size: 18px;
		color: ${p => p.theme.primary};
		padding-bottom: 1px;
		margin: 0 auto;
	}

	&:disabled {
		background-color: ${p => p.theme.primaryOpacity};
		border-color: ${p => p.theme.primaryOpacity};
		p {
			color: ${p => p.theme.primary};
		}

		svg {
			color: ${p => p.theme.primary};
		}
	}
`;

export const DescriptionsContainer = styled.div`
	.ant-descriptions-view {
		border: none !important;
		padding: 0px 8px !important;
	}

	.ant-descriptions-item-label {
		background-color: transparent !important;
		border-inline-end: none !important;
		padding: 3px 16px !important;
		font-weight: bold;
		font-size: 12px;
		color: ${p => p.theme.textSecondary} !important;
	}

	.ant-descriptions-item-content {
		padding: 3px 16px !important;
		text-transform: uppercase;
		font-size: 12px;
		color: ${p => p.theme.textGray} !important;
	}

	.ant-descriptions-row {
		border-bottom: none !important;
	}
`;

export const ReceiptFooter = styled.div`
	height: 70px;
	color: ${p => p.theme.textGray};
	background-color: ${p => p.theme.backgroundGray};
	display: flex;
	justify-content: space-between;
	padding: 16px 40px 0px;
`;

export const ReceiptContent = styled.div`
	padding: 18px 24px 18px;
`;

export const PlaceholderDarkInput = styled(Form.Item)`
	input {
		&::placeholder {
			color: ${p => p.theme.textSecondary};
			opacity: 1;
		}
	}
`;

export const DestinationsContainer = styled.div`
	height: 19rem;
	overflow-y: auto;

	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 20px;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0) 0%,
			#fff 100%
		);
		bottom: 0;
	}
`;

export const PostingDestinationCreationForm = styled(Form<PostingForm>)`
	padding: 0;
	margin: 0;
	min-height: 413px;
	display: flex;
	flex-direction: column;
`;

export const ReceiptSectionWrapper = styled.div`
	padding: 20;
	display: flex;
	flex-direction: column;
	gap: 10;
`;

export const ReceiptInfoCard = styled.div`
	display: flex;
	flex-direction: row;
	background-color: #fff7e5;
	padding: 17px;
	gap: 9px;
	border: 1px solid;
	border-radius: 10px;
	border-color: #fdd9b8;
`;

export const InfoCardText = styled(TextM)`
	font-size: 12;
	font-family: 'Urbanist', sans-serif;
	display: flex;
	flex-wrap: wrap;
	line-height: 1.5;
`;
