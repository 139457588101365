import { Button, Dropdown, Select, Tooltip, notification } from 'antd';
import type { MenuProps, TableProps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { LuSearch } from 'react-icons/lu';
import { TextM, TextS } from 'components/Text';
import {
	WalletCharge,
	WalletChargeFilters,
	WalletChargeStatus,
} from 'types/Wallet';
import { useCallback, useState } from 'react';
import { Table } from 'components/Table';
import { FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import { ESortingPage, PaginationInfo } from 'types/List';
import { WalletsService } from 'modules/debitCollections/services';
import { WalletChargeStatusDescription } from 'constants/wallet';
import dayjs from 'dayjs';
import { ExtendExpiration } from 'modules/debitCollections/components/ExtendExpiration';
import { Discount } from 'modules/debitCollections/components/Discount';
import { DebtorAddress } from 'modules/debitCollections/components/DebtorAddress';
import { InterestFine } from 'modules/debitCollections/components/InterestFine';
import { useCharges } from 'modules/debitCollections/hooks/useCharges';
import ChargesActionBar from './ChargesActionBar';
import ChargesFilters from './ChargesFilters';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { formatAddZerosMaskInput } from 'helpers/formaters';
import CancelChargeModal from './CancelChargeModal';
import { sortTableHandler } from 'helpers/tables';

interface ChargesBarcodesProps {
	id: string;
}
const ChargesBarcodes = ({ id }: ChargesBarcodesProps) => {
	const theme = useTheme();
	const [paginationInfo, updatePaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [api, contextHolder] = notification.useNotification();
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });

	const [selectedBarCodes, updateSelectedBarCodes] = useState<WalletCharge[]>(
		[],
	);
	const [isFiltersOpen, toggleFiltersOpen] = useState<boolean>(false);
	const [isExtendExpirationOpen, toggleExtendExpirationOpen] = useState<
		WalletCharge | false
	>(false);
	const [isDiscountOpen, toggleDiscountOpen] = useState<WalletCharge | false>(
		false,
	);
	const [isDebtorAddressOpen, toggleDebtorAddressOpen] = useState<
		WalletCharge | false
	>(false);
	const [isInterestFineOpen, toggleInterestFineOpen] = useState<
		WalletCharge | false
	>(false);

	const [cancelChargeItem, updateCancelChargeItem] = useState<WalletCharge>();
	const [filters, updateFilters] = useState<WalletChargeFilters>(
		{} as WalletChargeFilters,
	);

	const handleClearFilters = useCallback(() => {
		updateFilters({} as WalletChargeFilters);
	}, []);

	const { data, isLoading } = useQuery({
		queryKey: ['chargesList', paginationInfo, filters, sorting],
		queryFn: () =>
			WalletsService.getCharges(id, {
				...filters,
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
			}),
	});

	const { getChargePdf, isChargePdfLoading } = useCharges({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao buscar as cobranças.',
			});
		},
		onChargeDownload: chargePdf => {
			window.open(chargePdf, '_blank');
		},
	});

	const { cancelCharge } = useCharges({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao cancelar boleto.',
			});
		},
		onCancelChargeSuccess: () => {
			api.success({
				message: 'Boleto cancelado com sucesso.',
			});
		},
	});

	const createActionItems = (item: WalletCharge): MenuProps['items'] => {
		const defaultItems: MenuProps['items'] = [];

		if (item.status === WalletChargeStatus.ERROR) {
			defaultItems.push(
				{
					label: 'Prorrogar',
					key: '2',
					onClick: () => {
						toggleExtendExpirationOpen(item);
					},
				},
				{
					label: 'Desconto',
					key: '3',
					onClick: () => {
						toggleDiscountOpen(item);
					},
				},
				{
					label: 'Adicionar / Editar endereço do pagador',
					key: '4',
					onClick: () => {
						toggleDebtorAddressOpen(item);
					},
				},
				{
					label: 'Editar Multa e Juros',
					key: '5',
					onClick: () => {
						toggleInterestFineOpen(item);
					},
				},
			);
		} else if (item.status === WalletChargeStatus.PENDING) {
			defaultItems.push({
				label: 'Baixar boleto',
				key: '1',
				onClick: () => {
					getChargePdf(id, item.id);
				},
			});

			defaultItems.push({
				label: 'Cancelar boleto',
				key: '6',
				onClick: () => {
					updateCancelChargeItem(item);
				},
			});
		}
		return defaultItems;
	};

	const columns: ColumnsType<WalletCharge> = [
		{
			title: 'Cód. do boleto',
			width: '23%',
			render: (charge: WalletCharge) => (
				<TextM weight="normal" style={{ textTransform: 'uppercase' }}>
					{typeof charge.charge_code === 'number'
						? `${formatAddZerosMaskInput(String(charge.charge_code))}`
						: '-'}
				</TextM>
			),
		},
		{
			title: 'Sacado',
			render: (charge: WalletCharge) => (
				<TextM weight="normal">{`${charge.debtor?.name}`}</TextM>
			),
			width: '15%',
		},
		{
			title: 'CPF / CNPJ',
			width: '15%',
			render: (charge: WalletCharge) => (
				<TextM weight="normal">
					{charge.debtor?.document
						? normalizeCpfCnpj(charge.debtor?.document)
						: '-'}
				</TextM>
			),
		},
		{
			title: 'Data de vencimento',
			width: '15%',
			render: (charge: WalletCharge) => (
				<TextM weight="normal">
					{dayjs(charge.due_date?.split('T')?.[0]).format(
						'DD/MM/YYYY',
					)}
				</TextM>
			),
		},
		{
			title: 'Valor',
			width: '10%',
			render: (charge: WalletCharge) => (
				<TextM weight="normal">{formatCurrency(charge.amount)}</TextM>
			),
		},
		{
			title: 'Status',
			width: '10%',
			render: (charge: WalletCharge) => {
				if (
					typeof charge.error === 'string' &&
					[WalletChargeStatus.ERROR].includes(charge.status)
				)
					return (
						<Tooltip title={charge.error}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px',
								}}
							>
								<TextM weight="normal">
									{
										WalletChargeStatusDescription[
											charge.status
										]
									}
								</TextM>
								<IoMdInformationCircleOutline size={16} />
							</div>
						</Tooltip>
					);

				return (
					<TextM weight="normal">
						{WalletChargeStatusDescription[charge.status]}
					</TextM>
				);
			},
		},
		{
			key: 'action',
			width: '20%',
			align: 'end',
			render: (charge: WalletCharge) => {
				const isDisabled = ![
					WalletChargeStatus.ERROR,
					WalletChargeStatus.PENDING,
				].includes(charge.status);
				return (
					<Dropdown
						menu={{ items: createActionItems(charge) }}
						disabled={isDisabled}
						trigger={['click']}
					>
						<Button
							type="link"
							loading={isChargePdfLoading === charge.id}
							disabled={isDisabled}
							style={{
								color: theme.text,
								fontWeight: 'bold',
								fontSize: '1.3rem',
								lineHeight: '1rem',
								letterSpacing: '0.09rem',
								opacity: isDisabled ? '0.4' : '1',
							}}
						>
							...
						</Button>
					</Dropdown>
				);
			},
		},
	];

	return (
		<>
			{contextHolder}
			<FiltersContainer>
				<div>
					<TextM style={{ marginBottom: '8px' }}>Status</TextM>
					<Select
						style={{ width: '200px', height: '32px' }}
						options={[
							{
								label: 'Todos',
								value: '',
							},
						].concat(
							Object.keys(WalletChargeStatusDescription)
								.map(key => ({
									label: WalletChargeStatusDescription[
										key as WalletChargeStatus
									],
									value: key,
								}))
								.sort((a, b) => a.label.localeCompare(b.label)),
						)}
						value={filters.status ?? ''}
						onChange={value => {
							updateFilters({
								...filters,
								status:
									value !== ''
										? (value as WalletChargeStatus)
										: undefined,
							});
						}}
					/>
				</div>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => toggleFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={Object.keys(filters).length === 0}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</FiltersContainer>
			<Table
				columns={columns}
				rowKey={record => record.id}
				dataSource={data?.content}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						updatePaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
			<ExtendExpiration
				isOpen={isExtendExpirationOpen !== false}
				walletId={id}
				charge={isExtendExpirationOpen as WalletCharge}
				onClose={() => toggleExtendExpirationOpen(false)}
			/>
			<Discount
				isOpen={isDiscountOpen !== false}
				walletId={id}
				charge={isDiscountOpen as WalletCharge}
				onClose={() => toggleDiscountOpen(false)}
			/>
			<DebtorAddress
				isOpen={isDebtorAddressOpen !== false}
				walletId={id}
				charge={isDebtorAddressOpen as WalletCharge}
				onClose={() => toggleDebtorAddressOpen(false)}
			/>
			<InterestFine
				isOpen={isInterestFineOpen !== false}
				walletId={id}
				charge={isInterestFineOpen as WalletCharge}
				onClose={() => toggleInterestFineOpen(false)}
			/>
			<ChargesFilters
				isOpen={isFiltersOpen}
				filters={filters}
				onApply={filters => {
					updateFilters(filters);
					toggleFiltersOpen(false);
				}}
				onClose={() => toggleFiltersOpen(false)}
			/>
			{selectedBarCodes.length > 0 && (
				<ChargesActionBar
					selectedBarCodes={selectedBarCodes}
					onTriggerAction={action => {}}
				/>
			)}
			{!!cancelChargeItem && (
				<CancelChargeModal
					isOpen={!!cancelChargeItem}
					onClose={() => updateCancelChargeItem(undefined)}
					walletCharge={cancelChargeItem}
					handleConfirm={comment => {
						cancelCharge(id, cancelChargeItem.id, comment);
						updateCancelChargeItem(undefined);
					}}
				/>
			)}
		</>
	);
};

export default ChargesBarcodes;
