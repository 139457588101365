export const companyRevenueOptions = [
	{ label: 'até R$ 81 mil', value: 81000 },
	{
		label: 'até R$ 360 mil',
		value: 360000,
	},
	{
		label: 'entre R$ 360 mil e R$ 4,8 milhões',
		value: 4800000,
	},
	{
		label: 'mais que R$ 4,8 milhões',
		value: 4800001,
	},
];

export const getCompanyRevenueOption = (value?: number) => {
	if (!value) return companyRevenueOptions[0];

	if (value <= 81000) {
		return companyRevenueOptions[0];
	}
	if (value <= 360000) {
		return companyRevenueOptions[1];
	}
	if (value <= 4800000) {
		return companyRevenueOptions[2];
	}
	return companyRevenueOptions[3];
};
