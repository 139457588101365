import styled from 'styled-components';

export const StyledTitle = styled.div`
	color: ${({ theme }) => theme.primary};
	font-family: 'Inter', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
`;

export const StyledTexts = styled.div`
	color: ${({ theme }) => theme.textGray};
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;

	p {
		margin-top: 0.5rem;
		margin-bottom: 24px;
	}
`;

export const Bold = styled.span`
	color: ${({ theme }) => theme.textSecondary};
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
`;

export const Email = styled.span`
	color: ${({ theme }) => theme.primary};
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	text-decoration-line: underline;
`;

export const Topic = styled.div`
	color: ${({ theme }) => theme.primary};
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	margin-bottom: -15px;
	margin-top: 14px;
`;

export const ButtonWrapper = styled.div`
	margin-bottom: 4rem;
	width: 100%;
	display: flex;
	justify-content: center;

	span {
		color: ${({ theme }) => theme.white};
	}
`;
