import { IdDocumentType } from 'types/IdDocument';
import { RepresentativeRole } from 'types/Representative';

export const RepresentativeRoleDescription = {
	[RepresentativeRole.MANAGING_PARTNER]: 'Sócio Administrador',
	[RepresentativeRole.DIRECTOR]: 'Diretor',
	[RepresentativeRole.MANAGER]: 'Gerente',
	[RepresentativeRole.PARTNER]: 'Sócio',
};

export const IdDocumentDescription = {
	[IdDocumentType.RG]: 'Carteira de Identidade',
	[IdDocumentType.CNH]: 'CNH',
	[IdDocumentType.PASSPORT]: 'Passaporte',
	[IdDocumentType.MILITARY_ID]: 'Identidade Militar',
	[IdDocumentType.RNE]: 'Registro Nacional de Estrangeiros',
	[IdDocumentType.CLASS_ID]: 'Carteira de Classe',
};

export const RepresentativeDocumentsList = [
	{
		label: 'Carteira de identidade',
		upload_key: 'identification',
		download_key: 'identification_url',
	},
	{
		label: 'Comprovante de endereço',
		upload_key: 'proof_of_address',
		download_key: 'proof_of_address_url',
	},
];

export const NaturalPersonRepresentativeDocumentsList = [
	{
		label: 'Carteira de identidade',
		upload_key: 'identification',
		download_key: 'identification_url',
	},
	{
		label: 'Comprovante de endereço',
		upload_key: 'proof_of_address',
		download_key: 'proof_of_address_url',
	},
	// {
	//   label: "Procuração",
	//   upload_key: "power_of_attorney",
	//   download_key: "power_of_attorney_url",
	// },
];
