export type PostingDto = {
	account_id: string;
	account_destination_id?: string;
	amount: number;
	bar_code?: string;
	scheduled_date: string;
	billpayment_beneficiary?: string;
	billpayment_payer?: string;
	billpaymentTxnIdAuth?: string;
	type: PostingType | PaymentType;
	clientFinality?: PostingClientFinality;
	description?: string;
	automaticallyApprove?: boolean;
};

export enum PostingAction {
	APPROVE = 'APPROVE',
	REJECT = 'REJECT',
	CANCELED = 'CANCELED',
}

export enum PostingType {
	PIX = 'PIX',
	TED = 'TED',
	BARCODE = 'BOLETO',
}

export enum PaymentType {
	PIX = 'PIX',
	TED = 'TED',
}

export enum PostingClientFinality {
	TAXES_DUTIES_FEES_PAYMENT = '1',
	DIVIDEND_PAYMENT = '3',
	SALARY_PAYMENT = '4',
	SUPPLIER_PAYMENT = '5',
	RENT_CONDFEES_PAYMENT = '7',
	SCHOOL_FEES_PAYMENT = '9',
	ACCOUNT_CREDIT_PAYMENT = '10',
	JUDICIAL_PAYMENT = '100',
	BETWEEN_ACCOUNTS_TRANSFER = '110',
	OTHERS = '99999',
}

export type PostingReview = {
	reviewed_by: {
		id: string;
		full_name: string;
	};
	reviewed_at: string;
};

export interface Posting {
	id: string;
	status: string;
	error?: string;
	bar_code?: string;
	billpayment_txn_id_auth?: number;
	account: Account;
	account_destination: AccountDestination;
	billpayment_beneficiary?: string;
	billpayment_payer?: string;
	amount: number;
	scheduled_date: string;
	created_at: string;
	review?: PostingReview;
	type: PostingType;
}

export interface Account {
	id: string;
	branch: string;
	account: string;
	person: Company;
}

export interface Company {
	name: string;
	taxpayer_id: string;
}

export interface AccountDestination {
	id: string;
	name: string;
	bank: string;
	branch: string;
	account: string;
	type: string;
	taxpayer_id: string;
}

export enum PostingStatus {
	PENDING_APPROVAL = 'PENDING_APPROVAL',
	REJECTED = 'REJECTED',
	EXPIRED = 'EXPIRED',
	SCHEDULED = 'SCHEDULED',
	EXECUTING = 'EXECUTING',
	CANCELED = 'CANCELED',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
}

export const PostingStatusMap: { [key: string]: string } = {
	[PostingStatus.PENDING_APPROVAL]: 'Pendente de aprovação',
	[PostingStatus.REJECTED]: 'Rejeitado',
	[PostingStatus.EXPIRED]: 'Expirado',
	[PostingStatus.SCHEDULED]: 'Agendado',
	[PostingStatus.EXECUTING]: 'Executando',
	[PostingStatus.CANCELED]: 'Cancelado',
	[PostingStatus.ERROR]: 'Erro',
	[PostingStatus.SUCCESS]: 'Sucesso',
};

export interface ListPostingParams {
	page: number;
	limit: number;
	status: string;
	account?: string;
	taxpayer_id?: string;
	name?: string;
	date_from?: string;
	date_to?: string;
	billpayment_txn_id_auth?: number;
}

export interface RegisterData {
	document_recipient: string;
	document_payer: string;
	pay_due_date: string;
	next_business_day: string;
	due_date_register: string;
	allow_change_value: boolean;
	recipient: string;
	payer: string;
	discount_value: number;
	interest_value_calculated: number;
	max_value: number;
	min_value: number;
	fine_value_calculated: number;
	original_value: number;
	total_updated: number;
	total_with_discount: number;
	total_with_additional: number;
}

export interface BarCode {
	assignor: string;
	register_data: RegisterData | Partial<RegisterData>;
	settle_date: string;
	due_date: string;
	end_hour: string;
	inite_hour: string;
	next_settle: string;
	digitable: string;
	transaction_id: number;
	type: number;
	value: number;
	max_value: number;
	min_value: number;
	error_code: string;
	message: string;
	status: number;
}
