import React, { useState } from 'react';
import { Form, Input } from 'antd';
import {
	UserSettingsMfaFormContainer,
	UserSettingsMfaFormError,
	UserSettingsMfaFormInputList,
	UserSettingsMfaFormItem,
	UserSettingsMfaFormQrCode,
	UserSettingsMfaConfirmButton,
	UserSettingsMfaForm as UserSettingsMfaFormStyled,
	UserSettingsMfaFormStepItem,
	UserSettingsMfaFormSteps,
	UserSettingsMfaText,
} from './styles';
import { UserSettingsMfaFormType } from './types';
import QRCodeCanvas from 'qrcode.react';
import { TextS } from 'components/Text';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import cognito from 'modules/core/services/CognitoService';
import { serializeOnlyNumbers } from 'helpers/serializers';

const VALID_INPUT_REGEX = /^[0-9]*$/;

interface UserSettingsMfaFormProps {
	totpSecret: string;
	onClose: () => void;
}
const UserSettingsMfaForm: React.FC<UserSettingsMfaFormProps> = ({
	totpSecret,
	onClose,
}) => {
	const [formValid, setFormValid] = useState(false);
	const [form] = Form.useForm();
	const [error, setError] = useState<string | null>(null);

	const onKeyDown =
		(index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
			const charCode = e.which || e.keyCode;
			const char = String.fromCharCode(charCode);
			if (e.key === 'Backspace' && index > 0) {
				setTimeout(() =>
					document.getElementById(`code-${index - 1}`)?.focus(),
				);
			} else if (VALID_INPUT_REGEX.test(char) && index < 6) {
				setTimeout(() =>
					document.getElementById(`code-${index + 1}`)?.focus(),
				);
			}
		};

	const { mutate, isPending } = useMutation<boolean, ApiError, string>({
		mutationFn: token => {
			return cognito.validateUser(token);
		},
		onSuccess: () => onClose(),
		onError: () => {
			setError('Código incorreto');
		},
	});

	return (
		<UserSettingsMfaFormContainer>
			<UserSettingsMfaFormSteps>
				<UserSettingsMfaFormStepItem>
					<UserSettingsMfaText>
						<strong>Abra Seu Aplicativo de Autenticação:</strong>{' '}
						<br />
						Procure a opção <i>Adicionar Conta</i> ou{' '}
						<i>Escanear QR Code</i> no aplicativo. Use a câmera do
						seu dispositivo para escanear o QR Code abaixo.
					</UserSettingsMfaText>
					<UserSettingsMfaFormQrCode>
						<QRCodeCanvas value={totpSecret} />
					</UserSettingsMfaFormQrCode>
				</UserSettingsMfaFormStepItem>
				<UserSettingsMfaFormStepItem>
					<UserSettingsMfaText>
						<strong>Digite o Código:</strong> <br />
						Após escanear, o aplicativo gerará um código de 6
						dígitos. Digite este código no campo abaixo para
						concluir a configuração.
					</UserSettingsMfaText>
				</UserSettingsMfaFormStepItem>
			</UserSettingsMfaFormSteps>
			<UserSettingsMfaFormStyled
				style={{ width: '100%' }}
				initialValues={{ code: [{}, {}, {}, {}, {}, {}] }}
				layout="vertical"
				form={form}
				onFieldsChange={(e, allFields) => {
					const errors = allFields.filter(
						field =>
							(!field.touched && field.name.length > 1) ||
							(field.errors && field.errors.length > 0),
					);
					setFormValid(errors.length === 0);
					setError(null);
				}}
				onFinish={(values: UserSettingsMfaFormType) => {
					const code = values.code
						.map((item, i) => item[`code${i}`])
						.join('');
					if (code.length < 6) {
						setError('Campo obrigatório!');
						return;
					}
					mutate(code);
				}}
			>
				<Form.List name="code">
					{fields => (
						<UserSettingsMfaFormInputList>
							{fields.map(
								({ key, name, ...restField }, index) => (
									<UserSettingsMfaFormItem
										key={key}
										name={[name, `code${key}`]}
										rules={[
											{ required: true, message: '' },
										]}
										validateStatus={
											error !== null ? 'error' : undefined
										}
									>
										<Input
											{...restField}
											id={`code-${key}`}
											autoFocus={key === 0 ? true : false}
											onKeyDown={onKeyDown(key)}
											onPaste={e => {
												if (index === 0) {
													e.preventDefault();
													const values =
														e.clipboardData.getData(
															'text',
														);
													const valuesNumbers =
														serializeOnlyNumbers(
															values,
														);
													if (
														valuesNumbers.length ===
														6
													) {
														form.setFields(
															fields.map(
																(field, i) => ({
																	name: [
																		'code',
																		field.name,
																		`code${field.key}`,
																	],
																	value: valuesNumbers.charAt(
																		i,
																	),
																	touched:
																		true,
																}),
															),
														);
														setFormValid(true);
													}
												}
											}}
											maxLength={1}
											style={{
												textAlign: 'center',
												padding: '6.4px 0',
											}}
										/>
									</UserSettingsMfaFormItem>
								),
							)}
						</UserSettingsMfaFormInputList>
					)}
				</Form.List>
				{error && (
					<UserSettingsMfaFormError>{error}</UserSettingsMfaFormError>
				)}
				<UserSettingsMfaConfirmButton
					loading={isPending}
					type="primary"
					disabled={!formValid}
					htmlType="submit"
				>
					<TextS>Confirmar</TextS>
				</UserSettingsMfaConfirmButton>
			</UserSettingsMfaFormStyled>
		</UserSettingsMfaFormContainer>
	);
};

export default UserSettingsMfaForm;
