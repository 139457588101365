import { notification } from 'antd';
import { Company, CompanyDto } from 'types/Company';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { queryClient } from 'network/query';
import { CompanyRepresentatives } from 'components/CompanyRepresentatives';

interface ICompanyDetailsRepresentativesProps {
	company: Company;
}
const CompanyDetailsRepresentatives: React.FC<
	ICompanyDetailsRepresentativesProps
> = ({ company }) => {
	const [api, contextHolder] = notification.useNotification();

	const { mutate } = useMutation<void, ApiError, Partial<CompanyDto>>({
		mutationFn: companyDto => {
			return PersonsService.patchCompany(company.id, companyDto);
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['companyDetails', company.id],
			});
			api.success({
				message: 'Empresa atualizada com sucesso.',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao atualizar a empresa.',
			});
		},
	});

	return (
		<>
			{contextHolder}
			<CompanyRepresentatives
				isCompanyEdition={true}
				company={company}
				onBack={() => {}}
				onNext={newCompany => {
					mutate(newCompany);
				}}
			/>
		</>
	);
};

export default CompanyDetailsRepresentatives;
