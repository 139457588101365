import React from 'react';
import { TableWrapper } from './styles';
import {
	Checkbox,
	Table as ANTDTable,
	type TableColumnsType,
	Flex,
	Col,
	Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Table } from 'components/Table';
import { TextS } from 'components/Text';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { ChargeReviewParsed } from '../types';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import dayjs from 'dayjs';

export interface IChargeReviewTableProps {
	data: ChargeReviewParsed[];
	selectedCharges: ChargeReviewParsed[];
	handleToggleCheckbox: (
		event: CheckboxChangeEvent,
		charge: ChargeReviewParsed | undefined,
		isAll: boolean,
	) => void;
}

const ChargeReviewTable = ({
	data,
	selectedCharges,
	handleToggleCheckbox,
}: IChargeReviewTableProps) => {
	const theme = useTheme();

	const columns: TableColumnsType<ChargeReviewParsed> = [
		{
			title: () => (
				<Checkbox
					checked={
						selectedCharges.length === data?.length &&
						data?.length > 0
					}
					onChange={event =>
						handleToggleCheckbox(event, undefined, true)
					}
				/>
			),
			render: (charge: ChargeReviewParsed) => (
				<Checkbox
					checked={selectedCharges.includes(charge)}
					onChange={event =>
						handleToggleCheckbox(event, charge, false)
					}
				/>
			),
			width: '6%',
		},
		{
			title: 'Sacado',
			render: (charge: ChargeReviewParsed) => {
				return (
					<>
						<TextS
							style={{
								color: theme.textGray,
							}}
						>
							{charge.charge_debtor.name}
						</TextS>
					</>
				);
			},
		},
		{
			title: 'CPF/CNPJ',
			render: (charge: ChargeReviewParsed) => (
				<TextS
					style={{
						color: theme.textGray,
					}}
				>
					{normalizeCpfCnpj(charge.charge_debtor.document)}
				</TextS>
			),
		},
		{
			title: 'Data de venc.',
			render: (charge: ChargeReviewParsed) => (
				<TextS
					style={{
						color: theme.textGray,
					}}
				>
					{dayjs(charge.due_date?.split('T')?.[0]).format(
						'DD/MM/YYYY',
					)}
				</TextS>
			),
		},
		{
			title: 'Valor',
			render: (charge: ChargeReviewParsed) => (
				<TextS
					style={{
						color: theme.textGray,
					}}
				>
					{formatCurrency(Number(charge.amount))}
				</TextS>
			),
		},
		ANTDTable.EXPAND_COLUMN,
	];

	const expandedRowRender = (record: ChargeReviewParsed) => {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col span={1}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '30px',
								height: '30px',
								borderRadius: '50%',
								backgroundColor: theme.danger,
							}}
						>
							<AiOutlineExclamationCircle
								color={theme.white}
								size={16}
							/>
						</div>
					</Col>

					<Col
						span={20}
						style={{
							marginLeft: '10px',
						}}
					>
						<TextS style={{ color: theme.textGray }}>
							Erro ao processar cobrança
						</TextS>
						{record.errors?.map(error => {
							return (
								<Flex align="center" gap={8}>
									<AiOutlineExclamationCircle
										size={14}
										color={theme.textSecondary}
									/>
									<TextS
										style={{ color: theme.textSecondary }}
									>
										{error}
									</TextS>
								</Flex>
							);
						})}
					</Col>
				</Row>
			</>
		);
	};

	return (
		<>
			<TableWrapper>
				<Table
					style={{ width: '100%', height: '100%' }}
					columns={columns}
					dataSource={data}
					pagination={false}
					scroll={{ y: 240 }}
					rowKey={record => record.row_index}
					rowClassName={record => {
						const charge = data.find(
							charge => charge.row_index === record.row_index,
						);
						if (
							charge &&
							charge.errors &&
							charge.errors.length > 0
						) {
							return 'error-row';
						}

						return '';
					}}
					expandable={{
						rowExpandable: () => true,
						expandedRowRender: expandedRowRender,
						defaultExpandedRowKeys: data?.map(record => {
							if (record.errors && record.errors?.length > 0) {
								return record.row_index;
							}

							return '';
						}),
						expandIcon: () => null,
						expandedRowClassName: () => 'error-row',
					}}
				/>
			</TableWrapper>
		</>
	);
};

export default ChargeReviewTable;
