import React from 'react';
import theme from 'theme/theme';

export const SaveIcon: React.FC<{ color?: string; size?: number }> = ({
	color,
	size,
}) => (
	<svg
		width={size ?? '24'}
		height={size ?? '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7 19V13H17V19H19V7.82843L16.1716 5H5V19H7ZM4 3H17L21 7V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM9 15V19H15V15H9Z"
			fill={color ?? theme.primary}
		/>
	</svg>
);
