import { PageWrapper } from 'components/PageWrapper';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { SplashScreen } from 'components/SplashScreen';
import { AccountsService } from 'modules/escrow/services';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import EscrowAccountInfo from './components/EscrowAccountInfo';
import EscrowDetailsInformation from './EscrowDetailsInformation';
import { titleManagementBreadcrumb } from 'modules/management/constants/routes';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { useEffect, useState } from 'react';
import { AccountStatus } from 'types/Account';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { PageActions } from 'components/PageActions';
import { ButtonSecondary } from 'components/ButtonSecondary';
import { CancelIcon } from 'components/Icons/Cancel';
import { UnblockIcon } from 'components/Icons/Unblock';
import { BlockIcon } from 'components/Icons/Block';
import { useAccountActions } from 'modules/escrow/hooks';
import { queryClient } from 'network/query';
import { notification } from 'antd';
import { TextL, TextM } from 'components/Text';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { useTheme } from 'styled-components';
import { useIsEscrowAccountRoute } from 'modules/escrow/utils/adminRoute';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';

const AccountDetailsPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const theme = useTheme();
	const { id } = useParams();
	const { type } = useCurrentProfile();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const navigate = useNavigate();
	const { setBreadcrumb } = useBreadcrumb();

	const [isCancelAccountModalOpen, toggleCancelAccountModalOpen] =
		useState(false);
	const [isBlockAccountModalOpen, toggleBlockAccountModalOpen] =
		useState(false);
	const [isUnblockAccountModalOpen, toggleUnblockAccountModalOpen] =
		useState(false);
	const [isCancelAccountInfoModalOpen, toggleCancelAccountInfoModalOpen] =
		useState(false);
	const [isBlockAccountInfoModalOpen, toggleBlockAccountInfoModalOpen] =
		useState(false);
	const [isUnblockAccountInfoModalOpen, toggleUnblockAccountInfoModalOpen] =
		useState(false);

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;

	const { loading, block, cancel, unblock } = useAccountActions({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBlockSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			toggleBlockAccountInfoModalOpen(true);
		},
		onUnblockSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			toggleUnblockAccountInfoModalOpen(true);
		},
		onCancelSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountDetails', id],
			});
			toggleCancelAccountInfoModalOpen(true);
		},
	});

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/management',
				title: titleManagementBreadcrumb,
			},
			{
				title: `Conta ${data?.account ?? ''}`,
			},
		]);
	}, [setBreadcrumb, data?.account]);

	useEffect(() => {
		if (data && data?.status === AccountStatus.PENDING_ACTIVATION) {
			navigate(redirectAccount);
		}
	}, [data, navigate]);

	if (isLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to={redirectAccount} />;
	}

	return (
		<>
			{contextHolder}
			<PageHeaderWithButton
				title={`Conta ${data?.account ?? ''}`}
				isFluid
			>
				{type === AccessType.FINANCIAL_INSTITUTION && (
					<PageActions>
						<ButtonSecondary
							type="default"
							onClick={() => toggleCancelAccountModalOpen(true)}
							disabled={[
								AccountStatus.CANCELED,
								AccountStatus.CANCELING,
							].includes(data.status)}
						>
							Encerrar conta
							<CancelIcon
								color={
									[
										AccountStatus.CANCELED,
										AccountStatus.CANCELING,
									].includes(data.status)
										? theme.border
										: ''
								}
							/>
						</ButtonSecondary>
						{data.status === AccountStatus.BLOCKED ? (
							<ButtonSecondary
								type="default"
								onClick={() =>
									toggleUnblockAccountModalOpen(true)
								}
							>
								Desbloquear conta
								<UnblockIcon />
							</ButtonSecondary>
						) : (
							<ButtonSecondary
								type="default"
								onClick={() =>
									toggleBlockAccountModalOpen(true)
								}
							>
								Bloquear conta
								<BlockIcon />
							</ButtonSecondary>
						)}
					</PageActions>
				)}
			</PageHeaderWithButton>
			<EscrowAccountInfo account={data} />
			<PageWrapper isFluid={true}>
				<EscrowDetailsInformation account={data} />
			</PageWrapper>

			<ConfirmationModal
				isOpen={isCancelAccountModalOpen}
				danger={true}
				title="Encerrar conta"
				confirmText="Encerrar conta"
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					cancel(data);
					toggleCancelAccountModalOpen(false);
				}}
				onCancel={() => toggleCancelAccountModalOpen(false)}
				onClose={() => toggleCancelAccountModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja encerrar a conta?</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isCancelAccountInfoModalOpen}
				message="A conta foi encerrada com sucesso!"
				title="Conta encerrada"
				icon={<CheckmarkIcon />}
				onClose={() => toggleCancelAccountInfoModalOpen(false)}
			/>
			<ConfirmationModal
				isOpen={isBlockAccountModalOpen}
				danger={true}
				title="Bloquear conta"
				confirmText="Bloquear conta"
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					block(data);
					toggleBlockAccountModalOpen(false);
				}}
				onCancel={() => toggleBlockAccountModalOpen(false)}
				onClose={() => toggleBlockAccountModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja bloquear a conta?</TextL>
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isBlockAccountInfoModalOpen}
				message="A conta foi bloqueada com sucesso!"
				title="Conta bloqueada"
				icon={<CheckmarkIcon />}
				onClose={() => toggleBlockAccountInfoModalOpen(false)}
			/>
			<ConfirmationModal
				isOpen={isUnblockAccountModalOpen}
				title="Desbloquear conta"
				confirmText="Desbloquear conta"
				cancelText="Cancelar"
				onConfirm={() => {
					unblock(data);
					toggleUnblockAccountModalOpen(false);
				}}
				isLoading={loading}
				onCancel={() => toggleUnblockAccountModalOpen(false)}
				onClose={() => toggleUnblockAccountModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja desbloquear a conta?</TextL>
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUnblockAccountInfoModalOpen}
				message="A conta foi desbloqueada com sucesso!"
				title="Conta desbloqueada"
				icon={<CheckmarkIcon />}
				onClose={() => toggleUnblockAccountInfoModalOpen(false)}
			/>
		</>
	);
};

export default AccountDetailsPage;
