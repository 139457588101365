import React, { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from 'styled-components';
import { DropAreaContainer } from './styles';
import { TextS } from 'components/Text';
import { Button, Flex, notification, Spin } from 'antd';
import { MdOutlineUploadFile } from 'react-icons/md';
import UploadFileIcon from 'components/Icons/UploadFile';
import { RiFileDownloadLine } from 'react-icons/ri';
import { FileInfo } from './FileInfo';
import UploadPercentage from './UploadPercentage';
import { UploadCnabProviderContext } from './CnabImport';

export interface IDropAreaProps {
	file?: File | null;
	loading?: boolean;
	loadPercentage?: number;
	onUpload: (file: File) => void;
	removeFile: () => void;
	onNext: () => void;
	downloadTemplate: () => void;
}

const DropArea = ({
	file,
	loading,
	loadPercentage,
	onUpload,
	removeFile,
	onNext,
	downloadTemplate,
}: IDropAreaProps) => {
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const { chargeReviewData, onClose, loadingCnabTemplate } = useContext(
		UploadCnabProviderContext,
	);

	const onDrop = (files: File[]) => {
		onUpload(files[0]);
	};

	const handleRemoveFile = () => {
		removeFile();
	};

	const handleNext = () => {
		if (loading || !file) return;

		if ((chargeReviewData?.file_errors?.length || 0) > 0) {
			api.info({
				message: 'Arquivo inválido',
				description: 'Corrija os erros e tente novamente.',
			});

			return;
		}

		onNext();
	};

	const { getRootProps, getInputProps, isDragReject, isDragActive } =
		useDropzone({
			onDrop,
			accept: {
				'text/plain': ['.txt', '.csv'],
			},
		});

	return (
		<>
			{contextHolder}
			<Flex
				style={{
					flexDirection: 'column',
					maxHeight: '80vh',
					overflow: 'auto',
					width: '100%',
				}}
			>
				{loading ? (
					<DropAreaContainer>
						<div
							style={{
								minHeight: '9rem',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<UploadPercentage
								loadingPercentage={loadPercentage}
							/>
						</div>
					</DropAreaContainer>
				) : file ? (
					<FileInfo file={file} onRemove={handleRemoveFile} />
				) : (
					<DropAreaContainer {...getRootProps()}>
						<input {...getInputProps()} />
						<UploadFileIcon />
						<TextS
							style={{
								textAlign: 'center',
								maxWidth: '300px',
							}}
						>
							{isDragReject
								? 'Arquivo inválido'
								: 'Arraste seu arquivo (.txt, .csv), ou clique no botão abaixo para fazer o upload do seu arquivo.'}
						</TextS>
						<Flex style={{ margin: '1rem 0' }}>
							<Button
								type="primary"
								style={{ padding: '0.8rem' }}
							>
								<TextS
									weight="bold"
									style={{
										color: theme.white,
									}}
								>
									{isDragActive
										? 'Solte aqui'
										: 'Fazer upload'}
								</TextS>
								<MdOutlineUploadFile
									size={18}
									style={{
										color: theme.white,
									}}
								/>
							</Button>
						</Flex>
					</DropAreaContainer>
				)}

				<Flex
					gap={10}
					style={{
						flexDirection: 'column',
						margin: '1rem 0',
						width: '100%',
					}}
				>
					<Button
						style={{
							width: '100%',
						}}
						type="primary"
						onClick={handleNext}
					>
						<TextS
							weight={600}
							style={{
								color: theme.white,
							}}
						>
							Avançar
						</TextS>
					</Button>
					<Button
						onClick={downloadTemplate}
						style={{
							backgroundColor: theme.lightGray,
							border: 0,
						}}
						type="default"
					>
						<Flex align="center" style={{ width: '100%' }}>
							<Flex justify="center" style={{ width: '100%' }}>
								<TextS
									weight={600}
									style={{
										color: theme.primary,
									}}
								>
									Baixar template
								</TextS>
							</Flex>
							{loadingCnabTemplate ? (
								<Spin size="small" />
							) : (
								<RiFileDownloadLine
									size={18}
									style={{
										color: theme.primary,
										justifySelf: 'flex-end',
									}}
								/>
							)}
						</Flex>
					</Button>
					<Button
						onClick={() => {
							if (loading) return;
							handleRemoveFile();
							onClose?.();
						}}
						style={{
							width: '100%',
							outline: 'none',
							border: 'none',
						}}
						type="default"
					>
						<TextS
							weight={600}
							style={{
								color: theme.primary,
							}}
						>
							Cancelar
						</TextS>
					</Button>
				</Flex>
			</Flex>
		</>
	);
};

export default DropArea;
