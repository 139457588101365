import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { Account, AccountContent, IIUpdateAccountStatus } from 'types/Account';
import { AccountsService } from '../services';
import { useCallback, useState } from 'react';

export const useAccountActions = (options?: {
	onError?: (error: ApiError) => void;
	onRetrySuccess?: (account: AccountContent | Account) => void;
	onBlockSuccess?: (account: AccountContent | Account) => void;
	onUnblockSuccess?: (account: AccountContent | Account) => void;
	onCancelSuccess?: (account: AccountContent | Account) => void;
	onUpdateStatusSuccess?: (account: AccountContent | Account) => void;
	onCancelBalanceError?: () => void;
}) => {
	const [processingAccountId, updateProcessingAccountId] = useState<
		string | null
	>(null);
	const { mutate: retryMutate, isPending: isRetrying } = useMutation<
		void,
		ApiError,
		AccountContent | Account
	>({
		mutationFn: ({ id }) => {
			updateProcessingAccountId(id);
			return AccountsService.retryCreateAccount(id);
		},
		onSuccess: (r, account) => {
			updateProcessingAccountId(null);
			if (typeof options?.onRetrySuccess === 'function') {
				options.onRetrySuccess(account);
			}
		},
		onError: e => {
			updateProcessingAccountId(null);
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: blockMutate, isPending: isBlocking } = useMutation<
		void,
		ApiError,
		AccountContent | Account
	>({
		mutationFn: ({ id }) => {
			updateProcessingAccountId(id);
			return AccountsService.blockAccount(id);
		},
		onSuccess: (r, account) => {
			updateProcessingAccountId(null);
			if (typeof options?.onBlockSuccess === 'function') {
				options.onBlockSuccess(account);
			}
		},
		onError: e => {
			updateProcessingAccountId(null);
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: unblockMutate, isPending: isUnblocking } = useMutation<
		void,
		ApiError,
		AccountContent | Account
	>({
		mutationFn: ({ id }) => {
			updateProcessingAccountId(id);
			return AccountsService.unblockAccount(id);
		},
		onSuccess: (r, account) => {
			updateProcessingAccountId(null);
			if (typeof options?.onUnblockSuccess === 'function') {
				options.onUnblockSuccess(account);
			}
		},
		onError: e => {
			updateProcessingAccountId(null);
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const { mutate: cancelMutate, isPending: isCanceling } = useMutation<
		void,
		ApiError,
		AccountContent | Account
	>({
		mutationFn: ({ id }) => {
			updateProcessingAccountId(id);
			return AccountsService.cancelAccount(id);
		},
		onSuccess: (r, account) => {
			updateProcessingAccountId(null);
			if (typeof options?.onCancelSuccess === 'function') {
				options.onCancelSuccess(account);
			}
		},
		onError: e => {
			updateProcessingAccountId(null);
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	const cancelRequest = useCallback(
		async (account: AccountContent | Account) => {
			updateProcessingAccountId(account.id);
			try {
				const balance = await AccountsService.getBalance(account.id);
				if (balance.amount === 0) {
					return cancelMutate(account);
				}
				if (typeof options?.onCancelBalanceError === 'function') {
					options.onCancelBalanceError();
				}
				updateProcessingAccountId(null);
			} catch (e) {
				if (typeof options?.onError === 'function') {
					options.onError(e as ApiError);
				}
				updateProcessingAccountId(null);
			}
		},
		[cancelMutate, options],
	);

	const { mutate: updateStatus } = useMutation<
		void,
		ApiError,
		IIUpdateAccountStatus
	>({
		mutationFn: ({ id, status, reason }) => {
			updateProcessingAccountId(id);
			return AccountsService.updateAccountStatus(id, {
				status: status,
				reason: reason,
			});
		},
		onSuccess: () => {
			updateProcessingAccountId(null);
		},
		onError: e => {
			updateProcessingAccountId(null);
			if (typeof options?.onError === 'function') {
				options.onError(e);
			}
		},
	});

	return {
		processingAccountId,
		loading: isRetrying || isBlocking || isUnblocking || isCanceling,
		retry: retryMutate,
		block: blockMutate,
		unblock: unblockMutate,
		cancel: cancelRequest,
		updateStatus,
	};
};
