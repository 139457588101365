import React from 'react';
import {
	BreadcrumbItemType,
	BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';
import { BreadcrumbComponent, BreadcrumbWrapper } from './styles';
import { BreadcrumbSeparatorIcon } from 'components/Icons/BreadcrumbSeparator';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

interface IBreadcrumbProps {
	breadcrumb?: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
}
export const Breadcrumb: React.FC<IBreadcrumbProps> = ({ breadcrumb }) => {
	const navigate = useNavigate();

	if (!breadcrumb) {
		return null;
	}

	return (
		<BreadcrumbWrapper>
			<BreadcrumbComponent
				separator={<BreadcrumbSeparatorIcon />}
				itemRender={(route, params, routes, paths) => {
					const last = routes.indexOf(route) === routes.length - 1;
					return last ? (
						route.title
					) : (
						<Button
							type="text"
							style={{ padding: '0px', margin: '0px' }}
							onClick={() => navigate(route.href ?? '/')}
						>
							{route.title}
						</Button>
					);
				}}
				items={breadcrumb}
			/>
		</BreadcrumbWrapper>
	);
};
