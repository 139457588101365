import React from 'react';
import theme from 'theme/theme';

export const EyeIcon: React.FC<{ color?: string; size?: number }> = ({
	color,
	size,
}) => (
	<svg
		width={size ?? '21'}
		height={size ?? '21'}
		viewBox="0 0 21 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.0002 3.12695C14.4935 3.12695 18.2319 6.36029 19.016 10.627C18.2327 14.8936 14.4935 18.127 10.0002 18.127C5.50688 18.127 1.76854 14.8936 0.984375 10.627C1.76771 6.36029 5.50688 3.12695 10.0002 3.12695ZM10.0002 16.4603C11.6998 16.4599 13.3489 15.8826 14.6776 14.8229C16.0063 13.7632 16.936 12.2838 17.3144 10.627C16.9346 8.97137 16.0043 7.49362 14.6757 6.43531C13.3471 5.37699 11.6988 4.80073 10.0002 4.80073C8.30162 4.80073 6.65328 5.37699 5.32469 6.43531C3.99609 7.49362 3.06585 8.97137 2.68604 10.627C3.06446 12.2838 3.9941 13.7632 5.32283 14.8229C6.65155 15.8826 8.30065 16.4599 10.0002 16.4603ZM10.0002 14.377C9.00565 14.377 8.05182 13.9819 7.34856 13.2786C6.6453 12.5753 6.25021 11.6215 6.25021 10.627C6.25021 9.63239 6.6453 8.67856 7.34856 7.9753C8.05182 7.27204 9.00565 6.87695 10.0002 6.87695C10.9948 6.87695 11.9486 7.27204 12.6519 7.9753C13.3551 8.67856 13.7502 9.63239 13.7502 10.627C13.7502 11.6215 13.3551 12.5753 12.6519 13.2786C11.9486 13.9819 10.9948 14.377 10.0002 14.377ZM10.0002 12.7103C10.5527 12.7103 11.0826 12.4908 11.4733 12.1001C11.864 11.7094 12.0835 11.1795 12.0835 10.627C12.0835 10.0744 11.864 9.54451 11.4733 9.15381C11.0826 8.76311 10.5527 8.54362 10.0002 8.54362C9.44767 8.54362 8.91777 8.76311 8.52707 9.15381C8.13637 9.54451 7.91688 10.0744 7.91688 10.627C7.91688 11.1795 8.13637 11.7094 8.52707 12.1001C8.91777 12.4908 9.44767 12.7103 10.0002 12.7103Z"
			fill={color ?? theme.primary}
		/>
	</svg>
);
