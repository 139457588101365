import { Col, Row } from 'antd';
import { TextL, TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { Account } from 'types/Account';
import CelcoinLogo from 'components/Icons/CelcoinLogo';
import dayjs from 'dayjs';
import {
	formatCurrency,
	normalizeCnpj,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { BankListDescription } from 'constants/banksList';
import {
	DescriptionsContainer,
	InfoCardText,
	ReceiptContent,
	ReceiptFooter,
	ReceiptInfoCard,
	ReceiptSectionWrapper,
} from './styles';
import { useMemo } from 'react';
import { Modal } from 'components/Modal';
import { PostingDtoForm } from './PostingCreation';
import InfoIcon from 'components/images/InfoIcon.png';

interface IPostingCreationReceiptProps {
	isOpen: boolean;
	account: Account;
	postingDto: PostingDtoForm;
	transactionId: string;
	onClose: () => void;
}
const PostingCreationReceipt = ({
	isOpen,
	account,
	postingDto,
	transactionId,
	onClose,
}: IPostingCreationReceiptProps) => {
	const theme = useTheme();

	const destination = useMemo(() => {
		return account.destinations.find(
			dest => dest.id === postingDto.account_destination_id,
		);
	}, [account, postingDto]);

	return (
		<Modal
			title={
				<div style={{ padding: '0px 16px' }}>
					<CelcoinLogo color={theme.white} />
				</div>
			}
			isOpen={isOpen}
			width={595}
			rounded={false}
			onClose={onClose}
			style={{
				padding: '0px',
			}}
		>
			<ReceiptContent>
				<Row gutter={[16, 16]} style={{ textAlign: 'center' }}>
					<Col span={24}>
						<TextL>Solicitação de Transferência</TextL>
						<TextM weight="normal" color={theme.textGray}>
							{`${dayjs().format('DD/MM/YYYY')} às ${dayjs().format('HH:mm')}`}
						</TextM>
					</Col>
				</Row>
				<Row
					gutter={[16, 16]}
					style={{ textAlign: 'center', paddingTop: '1rem' }}
				>
					<Col span={24}>
						<TextM weight="normal" color={theme.textGray}>
							Valor
						</TextM>
						<TextL color={theme.primary}>
							{formatCurrency(postingDto.amount)}
						</TextL>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ paddingTop: '1.2rem' }}>
					<Col span={24}>
						<DescriptionsContainer>
							<DescriptionsVertical
								hideActions={true}
								title={<TextM>Conta de origem</TextM>}
								items={[
									{
										key: '1',
										label: 'Titular',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.person?.name ?? '-',
									},
									{
										key: '2',
										label: 'CNPJ',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.person?.taxpayer_id
											? normalizeCnpj(
													account.person.taxpayer_id,
												)
											: '-',
									},
									{
										key: '3',
										label: 'Código do banco',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children:
											BankListDescription['13935893'],
									},
									{
										key: '4',
										label: 'Agência',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.branch ?? '-',
									},
									{
										key: '5',
										label: 'Conta',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: account.account ?? '-',
									},
								]}
							/>
						</DescriptionsContainer>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ paddingTop: '1rem' }}>
					<Col span={24}>
						<DescriptionsContainer>
							<DescriptionsVertical
								hideActions={true}
								title={<TextM>Conta de destino</TextM>}
								items={[
									{
										key: '1',
										label: 'Titular',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: destination?.name ?? '-',
									},
									{
										key: '2',
										label: 'CPF/CNPJ',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: destination?.taxpayer_id
											? normalizeCpfCnpj(
													destination.taxpayer_id,
												)
											: '-',
									},
									{
										key: '3',
										label: 'Código do banco',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: destination?.bank
											? BankListDescription[
													destination.bank
												]
											: '-',
									},
									{
										key: '4',
										label: 'Agência',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: destination?.branch ?? '-',
									},
									{
										key: '5',
										label: 'Conta',
										contentStyle: {
											textAlign: 'right',
											color: theme.textGray,
										},
										children: destination?.account ?? '-',
									},
								]}
							/>
						</DescriptionsContainer>
					</Col>
				</Row>
				<Row
					gutter={[8, 8]}
					style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
					align="middle"
				>
					<ReceiptSectionWrapper>
						<ReceiptInfoCard>
							<img
								src={InfoIcon}
								style={{ width: '16px', height: '16px' }}
							/>
							<div>
								<InfoCardText>
									Este documento não deve ser utilizado como
									comprovante.
								</InfoCardText>
								<InfoCardText>
									O seu comprovante será disponibilizado no
									extrato assim que a transação for
									{'\n'}
									aprovada/processada.
								</InfoCardText>
							</div>
						</ReceiptInfoCard>
					</ReceiptSectionWrapper>
				</Row>
			</ReceiptContent>
			<ReceiptFooter>
				<div>
					<TextS color={theme.textGray} style={{ fontSize: '10px' }}>
						Código de identificação
					</TextS>
					<TextS
						color={theme.textGray}
						style={{ fontSize: '10px', textTransform: 'uppercase' }}
					>
						{transactionId}
					</TextS>
				</div>
				<div>
					<TextS color={theme.textGray} style={{ fontSize: '10px' }}>
						Celcoin Instituição de Pagamento S.A
					</TextS>
					<TextS
						color={theme.textGray}
						style={{ fontSize: '10px', textAlign: 'right' }}
					>
						13.935.893/0001-09
					</TextS>
				</div>
			</ReceiptFooter>
		</Modal>
	);
};

export default PostingCreationReceipt;
