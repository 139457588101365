import { useCallback, useState } from 'react';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { Steps, StepsWrapper } from './styles';
import { PageActions } from 'components/PageActions';
import { CompanyDto, ETenantType, PersonType } from 'types/Company';
import { CompanyInformation } from 'components/CompanyInformation';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { notification } from 'antd';
import { AccountRelatedPart } from 'types/Account';
import { AccountsService } from 'modules/escrow/services';
import { useNavigate } from 'react-router-dom';
import { AccountType, EscrowRoutes } from 'modules/escrow/constants/routes';
import { FullAccountRelatedPart, StepsId, StepsStatus } from './types';
import EscrowCreationSigners from './EscrowCreationSigners';
import EscrowCreationRelatedParts from './EscrowCreationRelatedParts';
import EscrowCreationDestination from './EscrowCreationDestination';
import { CompanyRepresentatives } from 'components/CompanyRepresentatives';
import { EscrowSteps } from 'components/EscrowSteps';

const EscrowCreationPage = () => {
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const [currentStep, updateCurrentStep] = useState<number>(1);
	const [accountId, updateAccountId] = useState<string>();
	const [company, updateCompany] = useState<CompanyDto>({} as CompanyDto);
	const [tenantType, setTenantType] = useState<ETenantType>();
	const [isPersonCreating, setIsPersonCreating] = useState<boolean>(false);
	const [accountRelatedParts, updateAccountRelatedParts] = useState<
		FullAccountRelatedPart[]
	>([]);
	const [stepsStatus, updateStepsStatus] = useState<StepsStatus>({
		personal_information: {
			completed: false,
		},
		representatives: {
			completed: false,
		},
		related_parts: {
			completed: false,
		},
		signers: {
			completed: false,
		},
		destination: {
			completed: false,
		},
	});

	const { mutate, isPending } = useMutation<
		{ id: string },
		ApiError,
		{
			company: CompanyDto;
			relatedParts: AccountRelatedPart[];
		}
	>({
		mutationFn: ({ company: newCompany, relatedParts }) => {
			if (newCompany.id) {
				return AccountsService.createAccount({
					person_id: newCompany.id,
					tenant_type: tenantType || ETenantType.DEFAULT,
					account_type: AccountType.ESCROW,
					parties: relatedParts.map(relatedPart => {
						if (relatedPart.person.id === 'company_owner_id') {
							return {
								...relatedPart,
								person: {
									...relatedPart.person,
									id: newCompany.id as string,
								},
							};
						}
						return relatedPart;
					}),
				});
			}
			return PersonsService.createCompany({
				...newCompany,
				person_type: PersonType.LEGAL,
				documents: {
					bylaws: newCompany.documents.bylaws,
					income_statement: newCompany.documents.income_statement,
					incorporation_certificate:
						newCompany.documents.incorporation_certificate,
					last_contract_amendment:
						newCompany.documents.last_contract_amendment,
					proof_of_address: newCompany.documents.proof_of_address,
					power_of_attorney: newCompany.documents.power_of_attorney,
					document_identification:
						newCompany.documents.document_identification,
				},
				representatives: newCompany.representatives.map(
					representative => ({
						...representative,
						documents: {
							identification:
								representative.documents.identification,
							proof_of_address:
								representative.documents.proof_of_address,
						},
						representative: representative.representative && {
							...representative.representative,
							documents: {
								identification:
									representative.representative.documents
										.identification,
								proof_of_address:
									representative.representative.documents
										.proof_of_address,
							},
						},
					}),
				),
			})
				.then(({ id: person_id }) => {
					return AccountsService.createAccount({
						person_id: person_id,
						tenant_type: tenantType || ETenantType.DEFAULT,
						account_type: AccountType.ESCROW,
						parties: relatedParts.map(relatedPart => {
							if (relatedPart.person.id === 'company_owner_id') {
								return {
									...relatedPart,
									person: {
										...relatedPart.person,
										id: person_id,
									},
								};
							}
							return relatedPart;
						}),
					});
				})
				.catch(e => Promise.reject(e));
		},
		onSuccess: ({ id }) => {
			updateAccountId(id);
			setIsPersonCreating(false);
			setTimeout(() => updateCurrentStep(() => 5));
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a conta.',
			});
		},
	});

	const updateRelatedPartsIfNecessary = useCallback(
		(editAccount: CompanyDto) => {
			const updatedRelatedParts = accountRelatedParts.map(relatedPart => {
				if (relatedPart.person.id === 'company_owner_id') {
					return {
						...relatedPart,
						companyDetails: {
							...relatedPart.companyDetails,
							representatives: editAccount.representatives,
						},
					};
				}
				return relatedPart;
			});

			updateAccountRelatedParts(
				updatedRelatedParts as FullAccountRelatedPart[],
			);
		},
		[accountRelatedParts],
	);

	const handleAccountUpdate = useCallback(
		(editAccount: CompanyDto | FullAccountRelatedPart[] | void) => {
			if (Array.isArray(editAccount)) {
				updateAccountRelatedParts(editAccount);
			} else if (editAccount) {
				if (
					accountRelatedParts.length > 0 &&
					!editAccount.representatives?.length
				) {
					updateRelatedPartsIfNecessary(editAccount);
				}
				updateCompany({
					...company,
					...editAccount,
				});
			}
		},
		[company, updateRelatedPartsIfNecessary, accountRelatedParts],
	);

	const onNext = useCallback(
		(editAccount: CompanyDto | FullAccountRelatedPart[] | void) => {
			if (currentStep < 4) {
				handleAccountUpdate(editAccount);
				updateCurrentStep(currentStep + 1);
			} else {
				mutate({
					company: {
						...company,
						person_type: PersonType.LEGAL,
					},
					relatedParts: (
						accountRelatedParts as FullAccountRelatedPart[]
					).map<AccountRelatedPart>(relatedPart => ({
						person: relatedPart.person,
						sign_contract: relatedPart.sign_contract,
						permissions: relatedPart.permissions,
					})),
				});
			}
		},
		[
			mutate,
			accountRelatedParts,
			company,
			currentStep,
			handleAccountUpdate,
		],
	);

	const onBack = useCallback(
		(editAccount: CompanyDto | FullAccountRelatedPart[] | void) => {
			if (currentStep > 1) {
				if (Array.isArray(editAccount)) {
					updateAccountRelatedParts(editAccount);
				} else if (editAccount) {
					updateCompany({
						...company,
						...editAccount,
					});
				}
				updateCurrentStep(currentStep - 1);
			}
		},
		[company, currentStep],
	);

	const onResetRepresentatives = useCallback(() => {
		updateCompany({
			...company,
			id: undefined,
			representatives: [],
			documents: {},
		});
	}, [company]);

	const handleStepStatus = useCallback((stepId: StepsId, status: boolean) => {
		updateStepsStatus(prevState => ({
			...prevState,
			[stepId]: {
				completed: status,
			},
		}));
	}, []);

	const updateRelatedPartsSigners = useCallback(
		(selectedRelatedPart: FullAccountRelatedPart) => {
			if (!selectedRelatedPart.person.id) return;
			const updatedRelatedParts = accountRelatedParts.map(relatedPart => {
				if (relatedPart.person.id === selectedRelatedPart.person.id) {
					return {
						...relatedPart,
						sign_contract: !relatedPart.sign_contract,
					};
				}
				return relatedPart;
			});
			updateAccountRelatedParts(updatedRelatedParts);
		},
		[accountRelatedParts],
	);

	return (
		<>
			<PageHeader>
				<Heading1>{`Criar Conta Escrow PJ`}</Heading1>
				<PageActions>
					<Steps>{`Etapa ${currentStep}/5`}</Steps>
				</PageActions>
			</PageHeader>
			{contextHolder}
			<StepsWrapper>
				<div
					style={{
						minHeight: 'calc(100vh - 200px)',
					}}
				>
					<EscrowSteps
						selectedStep={currentStep}
						steps={[
							{
								title: 'Cadastro da empresa',
								completed:
									stepsStatus[StepsId.PERSONAL_INFORMATION]
										.completed,
							},
							{
								title: 'Representantes',
								completed:
									stepsStatus[StepsId.REPRESENTATIVES]
										.completed,
							},
							{
								title: 'Partes relacionadas',
								completed:
									stepsStatus[StepsId.RELATED_PARTS]
										.completed,
							},
							{
								title: 'Assinantes',
								completed:
									stepsStatus[StepsId.SIGNERS].completed,
							},
							{
								title: 'Contas beneficiárias',
								completed:
									stepsStatus[StepsId.DESTINATION].completed,
							},
						]}
					/>
				</div>
				<PageWrapper>
					{currentStep === 1 && (
						<CompanyInformation
							isAccountCreation={true}
							company={company}
							onResetRepresentatives={onResetRepresentatives}
							onNext={onNext}
							handleStepStatus={handleStepStatus}
							setTenantType={setTenantType}
							tenantType={tenantType}
							isPersonCreating={isPersonCreating}
							setIsPersonCreating={setIsPersonCreating}
						/>
					)}
					{currentStep === 2 && (
						<CompanyRepresentatives
							isAccountCreation={true}
							company={company}
							onBack={onBack}
							onNext={onNext}
							handleStepStatus={handleStepStatus}
							isPersonCreating={isPersonCreating}
						/>
					)}
					{currentStep === 3 && (
						<EscrowCreationRelatedParts
							relatedParts={accountRelatedParts}
							company={company}
							onBack={onBack}
							onNext={onNext}
							handleStepStatus={handleStepStatus}
						/>
					)}
					{currentStep === 4 && (
						<EscrowCreationSigners
							relatedParts={accountRelatedParts}
							isLoading={isPending}
							onBack={onBack}
							onNext={onNext}
							handleStepStatus={handleStepStatus}
							updateRelatedPartsSigners={
								updateRelatedPartsSigners
							}
						/>
					)}
					{currentStep === 5 && accountId && (
						<EscrowCreationDestination
							accountId={accountId}
							onFinish={() => navigate(EscrowRoutes.ESCROW_BASE)}
						/>
					)}
				</PageWrapper>
			</StepsWrapper>
		</>
	);
};

export default EscrowCreationPage;
