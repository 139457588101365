import { NetworkApi } from 'network';

export class AppService extends NetworkApi {
	public static getFinancialInstitution(domain: string) {
		return this.getWithoutContext<{ id: string }, { domain: string }>(
			'/financial-institutions',
			{
				domain,
			},
		);
	}
}
