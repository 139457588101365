import React from 'react';

export const PaymentPersonIcon: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.33301 18.333C3.33301 16.5649 4.03539 14.8692 5.28563 13.619C6.53587 12.3687 8.23156 11.6663 9.99967 11.6663C11.7678 11.6663 13.4635 12.3687 14.7137 13.619C15.964 14.8692 16.6663 16.5649 16.6663 18.333H14.9997C14.9997 17.0069 14.4729 15.7352 13.5352 14.7975C12.5975 13.8598 11.3258 13.333 9.99967 13.333C8.67359 13.333 7.40182 13.8598 6.46414 14.7975C5.52646 15.7352 4.99967 17.0069 4.99967 18.333H3.33301ZM9.99967 10.833C7.23717 10.833 4.99967 8.59551 4.99967 5.83301C4.99967 3.07051 7.23717 0.833008 9.99967 0.833008C12.7622 0.833008 14.9997 3.07051 14.9997 5.83301C14.9997 8.59551 12.7622 10.833 9.99967 10.833ZM9.99967 9.16634C11.8413 9.16634 13.333 7.67467 13.333 5.83301C13.333 3.99134 11.8413 2.49967 9.99967 2.49967C8.15801 2.49967 6.66634 3.99134 6.66634 5.83301C6.66634 7.67467 8.15801 9.16634 9.99967 9.16634Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
