import React from 'react';
import theme from 'theme/theme';

export const FinanceIcon: React.FC<{ color?: string; size?: number }> = ({
	color,
	size,
}) => (
	<svg
		width={size ?? '24'}
		height={size ?? '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.8962 10.2374C12.8962 12.1704 11.3739 13.7374 9.49618 13.7374C7.61839 13.7374 6.09616 12.1704 6.09616 10.2374C6.09616 8.30444 7.61839 6.73743 9.49618 6.73743C11.3739 6.73743 12.8962 8.30444 12.8962 10.2374Z"
			fill={color ?? theme.primary}
		/>
		<path
			d="M1.85 3.23999H17.15C17.6195 3.23999 18 3.63174 18 4.11499V10.34C17.6769 10.2744 17.3425 10.24 17 10.24C16.7624 10.24 16.5287 10.2566 16.3 10.2886V7.04928C15.3394 6.75646 14.5816 5.97787 14.2951 4.98999H4.69722C4.41145 5.97524 3.6569 6.75231 2.7 7.04693V13.428C3.65857 13.7231 4.41407 14.5024 4.6987 15.49H12.0061C12.0367 16.1097 12.18 16.6997 12.416 17.24H1.85C1.38056 17.24 1 16.8483 1 16.365V4.11499C1 3.63174 1.38056 3.23999 1.85 3.23999Z"
			fill={color ?? theme.primary}
		/>
		<path
			clip-rule="evenodd"
			d="M18 21.24C20.7614 21.24 23 19.0014 23 16.24C23 13.4786 20.7614 11.24 18 11.24C15.2386 11.24 13 13.4786 13 16.24C13 19.0014 15.2386 21.24 18 21.24ZM18.5215 17.0703L19.9061 15.7241C19.9838 15.6486 20.1074 15.6486 20.185 15.7241L20.6395 16.166C20.6782 16.2036 20.7 16.2553 20.7 16.3094C20.7 16.3634 20.6782 16.4151 20.6395 16.4528L18.1395 18.8834C18.0618 18.9589 17.9383 18.9589 17.8606 18.8834L15.3606 16.4528C15.3219 16.4151 15.3 16.3634 15.3 16.3094C15.3 16.2553 15.3219 16.2036 15.3606 16.166L15.8151 15.7241C15.8927 15.6486 16.0163 15.6486 16.094 15.7241L17.4786 17.0703V13.74C17.4786 13.6295 17.5682 13.54 17.6786 13.54H18.3215C18.4319 13.54 18.5215 13.6295 18.5215 13.74V17.0703Z"
			fill={color ?? theme.primary}
		/>
	</svg>
);
