import React, { useCallback, useContext, useState } from 'react';
import { TabsSelector } from './styles';
import { Button, Col, Flex, notification, Row, TabsProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import ChargeReviewTable from './ChargeReviewTable';
import { UploadCnabProviderContext } from './CnabImport';
import { ChargeReviewParsed } from '../types';
import ImportConfirmationModal from './ImportConfirmationModal';

const ChargesReview = () => {
	const [api, contextHolder] = notification.useNotification();
	const {
		chargeReviewData,
		wallet,
		setDataReviewData,
		onClose,
		handleConfirmImports: handelConfirmImports,
	} = useContext(UploadCnabProviderContext);
	const theme = useTheme();
	const [activeTab, setActiveTab] = useState('1');
	const [selectedCharges, setSelectedCharges] = useState<
		ChargeReviewParsed[]
	>([]);
	const [finishReviewModalVisible, setFinishReviewModalVisible] =
		useState(false);

	const onChangeTab = useCallback((key: string) => {
		setActiveTab(key);
	}, []);

	const handleToggleCheckbox = useCallback(
		(event: CheckboxChangeEvent, checkedCharge?: any, all?: boolean) => {
			if (event.target.checked && all) {
				setSelectedCharges(chargeReviewData?.charges || []);
				return;
			}

			if (!event.target.checked && all) {
				setSelectedCharges([]);
				return;
			}

			if (!checkedCharge) {
				return;
			}

			if (event.target.checked) {
				setSelectedCharges([...selectedCharges, checkedCharge]);
				return;
			}

			setSelectedCharges(
				selectedCharges.filter(
					selectedCharge => selectedCharge !== checkedCharge,
				),
			);
		},
		[chargeReviewData, setSelectedCharges],
	);

	const handleRemoveSelectedCharges = useCallback(() => {
		if (selectedCharges.length === 0) {
			api.error({
				message: 'Selecione ao menos um boleto/cobrança para remover.',
			});
			return;
		}
		const newCharges = chargeReviewData?.charges.filter(
			charge => !selectedCharges.includes(charge as ChargeReviewParsed),
		);

		setSelectedCharges([]);
		setDataReviewData({
			charges: newCharges! as ChargeReviewParsed[],
			file_errors: chargeReviewData?.file_errors || [],
		});
	}, [chargeReviewData, selectedCharges]);

	const handleFinishReview = useCallback(() => {
		if (
			!!chargeReviewData?.charges.find(
				charge => (charge.errors?.length || 0) > 0,
			)
		) {
			api.error({
				message: 'Existem cobranças com erros, por favor, corrija-os.',
			});

			return;
		}

		if (chargeReviewData?.charges.length === 0) {
			api.error({
				message: 'Não existem cobranças para importar.',
			});

			return;
		}

		setFinishReviewModalVisible(true);
	}, [chargeReviewData]);

	const tabs: TabsProps['items'] = [
		{
			key: '1',
			label: `Todas as cobranças (${chargeReviewData ? chargeReviewData.charges.length : 0})`,
		},
		{
			key: '2',
			label: `Cobranças com erro (${chargeReviewData ? chargeReviewData.charges.filter(charge => (charge?.errors || []).length > 0)?.length : 0})`,
		},
	];

	const tables: { [key: string]: React.ReactElement } = {
		'1': (
			<ChargeReviewTable
				data={chargeReviewData?.charges || []}
				handleToggleCheckbox={handleToggleCheckbox}
				selectedCharges={selectedCharges}
			/>
		),

		'2': (
			<ChargeReviewTable
				data={
					(chargeReviewData?.charges.filter(
						charge => (charge.errors || []).length > 0,
					) || []) as ChargeReviewParsed[]
				}
				handleToggleCheckbox={handleToggleCheckbox}
				selectedCharges={selectedCharges}
			/>
		),
	};

	return (
		<>
			{contextHolder}
			<Flex style={{ width: '100%', flexDirection: 'column' }} gap={20}>
				<TabsSelector
					size="large"
					defaultActiveKey="1"
					activeKey={activeTab}
					items={tabs}
					onChange={onChangeTab}
				/>

				<Col style={{ width: '100%' }}>
					{tables[activeTab]}

					<Row
						style={{
							marginTop: '1rem',
						}}
					>
						<Flex
							gap={12}
							align="center"
							justify="flex-end"
							style={{ width: '100%' }}
						>
							<Button
								onClick={onClose}
								type="default"
								style={{
									border: 0,
								}}
							>
								<TextS
									style={{
										color: theme.primary,
									}}
								>
									Cancelar
								</TextS>
							</Button>
							<Button
								type="default"
								style={{
									backgroundColor: theme.danger,
									border: 0,
								}}
								onClick={handleRemoveSelectedCharges}
							>
								<TextS
									style={{
										color: theme.white,
									}}
								>
									Remover boletos selecionados
								</TextS>
							</Button>
							<Button type="primary" onClick={handleFinishReview}>
								<TextS
									style={{
										color: theme.white,
									}}
								>
									Fazer upload do CNAB
								</TextS>
							</Button>
						</Flex>
					</Row>
				</Col>
			</Flex>
			<ImportConfirmationModal
				isOpen={finishReviewModalVisible}
				onClose={() => setFinishReviewModalVisible(false)}
				totalCharges={chargeReviewData!.charges}
				totalAmount={
					chargeReviewData?.charges?.reduce(
						(acc, charge) => acc + Number(charge.amount),
						0,
					) || 0
				}
				wallet={wallet}
				onConfirm={() => {
					handelConfirmImports();
					setFinishReviewModalVisible(false);
				}}
			/>
		</>
	);
};

export default ChargesReview;
