import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { CompanyFilters } from 'types/Company';
import { PersonsService } from '../services';

export const useCompaniesList = (options?: {
	skipUntilApplyFilters?: boolean;
	initialFilters?: CompanyFilters;
}) => {
	const [filters, updateFilters] = useState<CompanyFilters | undefined>(
		options?.initialFilters,
	);

	const { data, isLoading } = useQuery({
		queryKey: [filters],
		refetchOnWindowFocus: false,
		queryFn: () => PersonsService.getPersons(filters),
		enabled: !options?.skipUntilApplyFilters || !!filters,
	});

	const applyFilters = useCallback((newFilters: CompanyFilters) => {
		updateFilters(newFilters);
	}, []);

	return {
		companies: data?.content || [],
		loading: isLoading,
		applyFilters,
	};
};
