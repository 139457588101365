import { Modal } from 'components/Modal';
import { IDepositManagementForm } from '../types';
import { TextM, TextS } from 'components/Text';
import { CurrencyInput } from 'components/CurrencyInput';
import { useCallback, useEffect, useState } from 'react';
import theme from 'theme/theme';
import { CheckIcon } from 'components/Icons/Check';
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { Table } from 'components/Table';
import { TrashIcon } from 'components/Icons/Trash';
import dayjs from 'dayjs';
import { StyledDatePicker } from '../styles';
import { Destination } from 'types/Destination';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { localeDatePicker } from 'helpers/dates';
import FormItem from 'antd/es/form/FormItem';
import {
	Button,
	Col,
	DatePicker,
	Flex,
	Form,
	Input,
	Radio,
	Row,
	Select,
	notification,
} from 'antd';
import {
	hasInvalidDate,
	validateMinDateBetween,
	validateZeroPercentage,
} from 'helpers/rules';

interface IDepositManagementModalProps {
	closeModal: () => void;
	initialValues?: IDepositManagementForm;
	editRetention: (values: IDepositManagementForm) => void;
	createRetention: (values: IDepositManagementForm) => void;
	isOpen?: boolean;
	duplicating?: boolean;
	setDuplicating: (value: boolean) => void;
	destinations?: Destination[];
}

const DepositManagementModal = ({
	closeModal,
	initialValues,
	editRetention,
	createRetention,
	isOpen,
	duplicating,
	setDuplicating,
	destinations,
}: IDepositManagementModalProps) => {
	const [api, contextHolder] = notification.useNotification();
	const dateFormat = 'YYYY-MM-DD';

	const [inputText, setInputText] = useState<string>();
	const [hasOrigins, setHasOrigins] = useState<boolean>(false);
	const [slc, setSlc] = useState<boolean>(false);
	const [origins, setOrigins] = useState<string[]>([]);
	const [dates, setDates] = useState({
		endDate: '',
		startDate: '',
	});
	const [editedFormValues, setEditedFormValues] =
		useState<IDepositManagementForm>({} as IDepositManagementForm);

	const isDisabled: boolean =
		!editedFormValues.name ||
		!editedFormValues.percent ||
		editedFormValues.percent.toString() === '0' ||
		!dates.endDate ||
		hasInvalidDate(dates.endDate) ||
		hasInvalidDate(dates.startDate) ||
		!dates.startDate ||
		!editedFormValues.beneficiaryAccount;

	const isEditing = !!editedFormValues.id;

	const handleOrigins = useCallback(() => {
		if (!!inputText) {
			if (origins.includes(inputText)) {
				api.error({
					message: 'Documento já consta na listagem.',
				});
				return;
			}

			setOrigins(prev => [...prev, inputText]);
			setInputText('');
			return;
		}

		api.error({ message: 'Digite um documento.' });
	}, [inputText]);

	const validateForm = useCallback(() => {
		if (!hasOrigins) {
			// Se não deseja reter origens, envia vazio.
			editedFormValues.origins = [];
		}

		if (editedFormValues.id) {
			editRetention({
				beneficiaryAccount: editedFormValues.beneficiaryAccount,
				description: editedFormValues.description,
				endDate: dates.endDate,
				name: editedFormValues.name,
				origins: origins,
				originsRetention: !!origins,
				percent: editedFormValues.percent,
				slcRetention: slc,
				startDate: dates.startDate,
				totalAmount:
					editedFormValues?.totalAmount &&
					editedFormValues?.totalAmount > 0
						? editedFormValues.totalAmount
						: null,
				id: editedFormValues.id,
			});

			return;
		}

		createRetention({
			beneficiaryAccount: editedFormValues.beneficiaryAccount,
			description: editedFormValues.description,
			endDate: dates.endDate,
			name: editedFormValues.name,
			origins: origins,
			originsRetention: !!origins,
			percent: editedFormValues.percent,
			slcRetention: slc,
			startDate: dates.startDate,
			totalAmount: editedFormValues.totalAmount,
		});
	}, [editedFormValues, origins, hasOrigins, slc, dates]);

	const removeFromList = (origin: string) => {
		setOrigins(origins?.filter(item => item !== origin));
	};

	const closeModalHandler = () => {
		setHasOrigins(false);
		setInputText('');
		setOrigins([]);
		setEditedFormValues({} as IDepositManagementForm);
		setDates({
			endDate: '',
			startDate: '',
		});

		closeModal();
	};

	const options: IOption[] = !!destinations
		? destinations?.map(destination => ({
				label: `${destination.account} - ${destination.name}`,
				value: destination.id,
			}))
		: [];

	const columns: ColumnsType<string[]> = [
		{
			key: '1',
			title: 'CPF/CNPJ',
			render: (item: string) => <div>{normalizeCpfCnpj(item)}</div>,
			width: '95%',
		},
		{
			key: '2',
			title: '',
			render: (item: string) => (
				<div onClick={() => removeFromList(item)}>
					<TrashIcon color={theme.danger} size={18} />
				</div>
			),
			width: '5%',
		},
	];

	useEffect(() => {
		if (!!initialValues) {
			if (initialValues?.origins && initialValues?.origins[0]) {
				setOrigins(initialValues?.origins || []);
				setHasOrigins(true);
			} else {
				setHasOrigins(false);
				setOrigins([]);
			}

			setEditedFormValues({ ...initialValues, id: initialValues.id });
			setDates({
				endDate: initialValues?.endDate || '',
				startDate: initialValues?.startDate || '',
			});
			setSlc(!!initialValues?.slcRetention);
		}
	}, [initialValues]);

	useEffect(() => {
		if (!hasOrigins) {
			setOrigins([]);
			setInputText('');
			return;
		}

		setOrigins(initialValues?.origins || []);
	}, [hasOrigins]);

	useEffect(() => {
		if (!isOpen) {
			setEditedFormValues({} as IDepositManagementForm);
		}
	}, [isOpen]);

	useEffect(() => {
		if (duplicating) {
			setEditedFormValues({ ...initialValues, id: undefined });
			setDates({
				endDate: initialValues?.endDate || '',
				startDate: initialValues?.startDate || '',
			});
		}
	}, [duplicating]);

	return (
		<>
			{contextHolder}
			<Modal
				isOpen={!!isOpen}
				onClose={closeModalHandler}
				title="Adicionar retenção de recursos"
				width={800}
			>
				<Form<IDepositManagementForm>
					initialValues={initialValues}
					layout="vertical"
					onFinish={validateForm}
					onValuesChange={(changed, values) => {
						setEditedFormValues({
							...values,
							id:
								!duplicating && initialValues?.id
									? initialValues?.id
									: undefined,
						});
					}}
				>
					<FormItem shouldUpdate>
						<Row gutter={16}>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Nome da retenção *
								</TextS>
								<Form.Item
									name="name"
									shouldUpdate
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
									]}
								>
									<Input
										type="string"
										placeholder="Digite aqui"
									/>
								</Form.Item>
							</Col>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Descrição
								</TextS>
								<Form.Item name="description" shouldUpdate>
									<Input
										type="string"
										placeholder="Digite aqui"
									/>
								</Form.Item>
							</Col>
						</Row>
						<br />
						<Row gutter={16}>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Data de início *
								</TextS>
								<StyledDatePicker>
									<Form.Item
										name="start_date"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<DatePicker
											style={{
												width: '100%',
											}}
											placeholder="dd/mm/aaaa"
											format="DD/MM/YYYY"
											minDate={dayjs().startOf('day')}
											locale={localeDatePicker}
											className={
												duplicating ? 'modified' : ''
											}
											onChange={(_, dateString) => {
												setDuplicating(false);
												setDates({
													...dates,
													startDate: dayjs(
														dateString as string,
														'DD/MM/YYYY',
													).format('YYYY/MM/DD'),
												});
											}}
											defaultValue={
												initialValues?.startDate
													? dayjs(
															initialValues?.startDate,
															dateFormat,
														)
													: undefined
											}
										/>
									</Form.Item>
								</StyledDatePicker>
							</Col>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Data de término *
								</TextS>
								<StyledDatePicker>
									<Form.Item
										name="end_date"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											validateMinDateBetween(
												'start_date',
											),
										]}
									>
										<DatePicker
											style={{
												width: '100%',
											}}
											format="DD/MM/YYYY"
											placeholder="dd/mm/aaaa"
											locale={localeDatePicker}
											minDate={dayjs().startOf('day')}
											className={
												duplicating ? 'modified' : ''
											}
											onChange={(_, dateString) => {
												setDuplicating(false);
												setDates({
													...dates,
													endDate: dayjs(
														dateString as string,
														'DD/MM/YYYY',
													).format('YYYY/MM/DD'),
												});
											}}
											defaultValue={
												initialValues?.endDate
													? dayjs(
															initialValues?.endDate,
															dateFormat,
														)
													: undefined
											}
										/>
									</Form.Item>
								</StyledDatePicker>
							</Col>
						</Row>
						<br />
						<Row gutter={16}>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Valor total retido
								</TextS>
								<Form.Item
									name="totalAmount"
									shouldUpdate
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
									]}
								>
									<CurrencyInput placeholder="0,00" />
								</Form.Item>
							</Col>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Percentual de entrada a ser retido *
								</TextS>
								<Form.Item
									name="percent"
									shouldUpdate
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
										validateZeroPercentage('percent'),
									]}
								>
									<Input
										type="number"
										inputMode="numeric"
										placeholder="0"
										prefix="%"
										min={0}
										max={100}
									/>
								</Form.Item>
							</Col>
						</Row>
						<br />
						<Row gutter={16}>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Conta beneficiária *
								</TextS>
								<Form.Item
									name="beneficiaryAccount"
									shouldUpdate
									rules={[
										{
											required: true,
											message: 'Campo obrigatório',
										},
									]}
								>
									<Select
										options={options}
										placeholder="Selecione"
									/>
								</Form.Item>
							</Col>
						</Row>
						<br />
						<Row gutter={16}>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Deseja reter de origens especificas?
								</TextS>
								<Form.Item name="originsRetention" shouldUpdate>
									<Radio.Group
										defaultValue={hasOrigins}
										onChange={e =>
											setHasOrigins(e.target.value)
										}
									>
										<Radio value={true}>
											<TextS weight="normal">Sim</TextS>
										</Radio>
										<Radio value={false}>
											<TextS weight="normal">Não</TextS>
										</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col className="gutter-row" span={12}>
								<TextS
									weight="normal"
									color={theme.textSecondary}
									style={{
										marginBottom: '0.5rem',
									}}
								>
									Reter recursos SLC?
								</TextS>
								<Form.Item name="slcRetention" shouldUpdate>
									<Radio.Group
										onChange={e => setSlc(e.target.value)}
										defaultValue={!!slc}
									>
										<Radio value={true}>
											<TextS weight="normal">Sim</TextS>
										</Radio>
										<Radio value={false}>
											<TextS weight="normal">Não</TextS>
										</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>
						{hasOrigins && (
							<>
								<br />
								<TextM
									color={theme.primary}
									style={{ marginBottom: '18px' }}
								>
									Origem do recurso
								</TextM>
								<Row gutter={16}>
									<Col className="gutter-row" span={20}>
										<TextS
											weight="normal"
											color={theme.textSecondary}
											style={{
												marginBottom: '0.5rem',
											}}
										>
											CPF / CNPJ da origem do recurso:
										</TextS>
										<Input
											style={{
												height: '2rem',
											}}
											placeholder="000.000.000-00"
											allowClear
											type=""
											value={normalizeCpfCnpj(
												inputText || '',
											)}
											onChange={e => {
												setInputText(e.target.value);
											}}
										/>
									</Col>
									<Col className="gutter-row" span={4}>
										<Button
											type="primary"
											onClick={() => {
												handleOrigins();
												setInputText('');
											}}
											style={{
												backgroundColor: theme.primary,
												borderColor: theme.primary,
												marginTop: '1.8rem',
											}}
										>
											<Flex
												style={{
													alignItems: 'center',
													gap: '0.5rem',
												}}
											>
												Adicionar
												<AddCircleIcon
													color={theme.white}
													size="16"
												/>
											</Flex>
										</Button>
									</Col>
								</Row>
								<br />
							</>
						)}
					</FormItem>
				</Form>
				{!!origins && !!origins[0] && (
					<Table
						pagination={false}
						columns={columns}
						rowKey={record => record.id}
						dataSource={origins}
					/>
				)}
				<Col>
					<Flex
						gap={10}
						justify="flex-end"
						style={{ marginTop: '4rem' }}
					>
						<Button onClick={closeModal} type="text">
							<TextS style={{ color: theme.primary }}>
								Cancelar
							</TextS>
						</Button>

						<Button
							type="primary"
							disabled={isDisabled}
							onClick={validateForm}
							style={{ border: 'none', width: '12rem' }}
						>
							<Flex align="center">
								<TextS
									style={{
										marginRight: '10px',
										color: isDisabled
											? theme.primary
											: theme.white,
										opacity: isDisabled ? '0.5' : undefined,
									}}
								>
									{isEditing
										? 'Editar retenção'
										: 'Adicionar retenção'}
								</TextS>
								<CheckIcon
									size={18}
									isDisabled={isDisabled}
									color={
										isDisabled ? theme.primary : theme.white
									}
								/>
							</Flex>
						</Button>
					</Flex>
				</Col>
			</Modal>
		</>
	);
};

export default DepositManagementModal;
