import styled from 'styled-components';

export const Header = styled.div<{ rounded: boolean }>`
	padding: 16px;
	display: flex;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.primary};

	border-top-left-radius: ${({ rounded }) => (rounded ? '15px' : '0px')};
	border-top-right-radius: ${({ rounded }) => (rounded ? '15px' : '0px')};
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	h2 {
		color: ${({ theme }) => theme.white};
	}
`;

export const Content = styled.div`
	padding: 40px 24px 32px;
	border-radius: 15px;
	max-height: 50rem;
	overflow-y: auto;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: transparent;
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: transparent;
	}
`;
