import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextL, TextM } from 'components/Text';
import { TEventConfirmationModal } from '../types';

interface IEventConfirmationModalProps {
	info: TEventConfirmationModal;
	hasInputText?: boolean;
	confirmHandler: (value?: string) => void;
	closeModal: () => void;
	isLoading?: boolean;
}

const EventConfirmationModal = ({
	info,
	hasInputText,
	confirmHandler,
	closeModal,
	isLoading,
}: IEventConfirmationModalProps) => {
	const [reason, setReason] = useState<string>('');

	const isDisable = info.disableIfEmptyReason ? !reason : false;

	return (
		<ConfirmationModal
			cancelText="Cancelar"
			confirmText={info.buttonText}
			danger={info.isDanger}
			disabledButton={isDisable}
			isOpen
			onCancel={closeModal}
			onClose={closeModal}
			onConfirm={() => confirmHandler(reason)}
			title={info.modalTitle}
			isLoading={isLoading}
		>
			<TextL style={{ marginBottom: '2rem' }}>{info.infoPrimary}</TextL>
			<TextM style={{ marginBottom: '2rem' }}>{info.infoSecondary}</TextM>
			{hasInputText && (
				<TextArea
					maxLength={250}
					onChange={e => setReason(e.target.value)}
					placeholder="Digite aqui"
					title={info.inputTextTitle || 'Motivo (opcional)'}
					rows={5}
					style={{ marginTop: '0.5rem' }}
				/>
			)}
		</ConfirmationModal>
	);
};

export default EventConfirmationModal;
