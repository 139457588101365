import { ApiError } from 'types/ApiError';
import { Account, AccountBalance, AccountContent } from 'types/Account';
import { AccountsService } from '../services';
import { useCallback, useState } from 'react';

export const useAccount = (options?: {
	onError?: (error: ApiError) => void;
	onBalanceSuccess?: (
		account: AccountContent | Account,
		amount: number,
	) => void;
}) => {
	const [isBalanceLoading, toggleBalanceLoading] = useState<string | false>(
		false,
	);

	const getAccountBalance = useCallback(
		async (
			account: AccountContent | Account,
		): Promise<AccountBalance | null> => {
			try {
				toggleBalanceLoading(account.id);
				const balance = await AccountsService.getBalance(account.id);
				if (typeof options?.onBalanceSuccess === 'function') {
					options.onBalanceSuccess(account, balance.amount);
				}
				return balance;
			} catch (e) {
				if (typeof options?.onError === 'function') {
					options.onError(e as ApiError);
				}
				return null;
			} finally {
				toggleBalanceLoading(false);
			}
		},
		[options],
	);

	return {
		getAccountBalance,
		isBalanceLoading,
	};
};
