import {
	EscrowAccountIcon,
	EscrowAccountOutlinedIcon,
} from 'components/Icons/EscrowAccount';
import React from 'react';
import { useTheme } from 'styled-components';

export const contaLivre = 'payment-account';

export enum EscrowRoutes {
	ESCROW_BASE = '/escrow',
	ESCROW_NEW = '/escrow/new',
	ESCROW_NEW_NATURAL_PERSON = '/escrow/new-natural-person',
	ESCROW_APPROVALS = '/escrow/approvals',
	ESCROW_ACCOUNTS = '/escrow/accounts',
	ACCOUNT_BASE = `/${contaLivre}`,
	ACCOUNT_NEW = `/${contaLivre}/new-company`,
	ACCOUNT_NEW_NATURAL_PERSON = `/${contaLivre}/new-person`,
	ACCOUNT_APPROVALS = `/${contaLivre}/approvals`,
	ACCOUNT_ACCOUNTS = `/${contaLivre}/accounts`,
}

export const EscrowIcon: React.FC<{ isActive?: boolean }> = ({ isActive }) => {
	const theme = useTheme();
	if (isActive) return <EscrowAccountIcon />;
	return <EscrowAccountOutlinedIcon color={theme.text} />;
};

export enum AccountType {
	ESCROW = 'ESCROW',
	PAYMENT = 'PAYMENT',
}
