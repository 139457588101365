import { useMemo, useState } from 'react';
import { EscrowAccounts } from './pages/EscrowAccounts';
import { Tabs } from 'components/Tabs';
import { PageWrapper } from 'components/PageWrapper';
import { TabElement } from 'components/Tabs/Tabs';
import { SelectPersonTypeModal } from 'modules/escrow/components/SelectEscrowAccountTypeModal';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';

const ManagementPage = () => {
	const [isAccountTypeModalOpen, updateIsAccountTypeModalOpen] =
		useState(false);

	const tabElements = useMemo<TabElement[]>(() => {
		const tabs = [
			{
				title: 'Contas',
				content: <EscrowAccounts />,
				path: 'accounts',
			},
		];

		return tabs;
	}, []);

	return (
		<>
			<PageHeaderWithButton isFluid title="Gestão" />
			<PageWrapper isFluid={true}>
				<Tabs elements={tabElements} />
			</PageWrapper>
			<SelectPersonTypeModal
				isOpen={isAccountTypeModalOpen}
				onClose={() => updateIsAccountTypeModalOpen(false)}
			/>
		</>
	);
};

export default ManagementPage;
