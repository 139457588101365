import {
	Button,
	Col,
	CollapseProps,
	Descriptions,
	DescriptionsProps,
	Empty,
	Row,
} from 'antd';
import { IoAddCircleOutline, IoPeopleOutline } from 'react-icons/io5';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	ContainerFluid,
	ContainerHeader,
	DescriptionItem,
	Footer,
} from './styles';
import { PageActions } from 'components/PageActions';
import { GoLaw } from 'react-icons/go';
import { TextM, TextS } from 'components/Text';
import {
	BaseRepresentative,
	Representative,
	RepresentativeDocuments,
} from 'types/Representative';
import { formatPhoneFromObject } from 'helpers/formaters';
import dayjs from 'dayjs';
import {
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';
import { CompanyDto } from 'types/Company';
import { useTheme } from 'styled-components';
import { normalizeCpf } from 'helpers/normalizers';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { useCompany } from 'modules/company/hooks';
import { Collapse, CollapseHeader } from 'components/Collapse';
import EmptyRepresentativesList from 'components/images/emptyListA.png';
import { NaturalPersonRepresentativesCreation } from 'components/NaturalPersonRepresentativesCreation';
import { StepsId } from 'modules/escrow/pages/EscrowCreation/types';

export interface CustomDescriptionItemProps {
	children: React.ReactNode;
}

export const CustomDescriptionItem = ({
	children,
}: CustomDescriptionItemProps) => {
	return (
		<DescriptionItem style={{ margin: '0', padding: '0.5rem 0' }}>
			{children}
		</DescriptionItem>
	);
};

const createRepresentativeBasicDescriptionItems = (
	fullRepresentative: Representative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'CPF',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{normalizeCpf(fullRepresentative.taxpayer_id)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Nome completo',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.full_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Data de nascimento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{dayjs(fullRepresentative.birth_date, 'YYYY-MM-DD').format(
						'DD/MM/YYYY',
					)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Nome da mãe',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.mother_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'E-mail',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.email_address}
				</CustomDescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Telefone',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{formatPhoneFromObject(fullRepresentative.phone)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '7',
			label: 'Cargo',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{RepresentativeRoleDescription[fullRepresentative.role]}
				</CustomDescriptionItem>
			),
		},
		{
			key: '9',
			label: 'Assina contrato',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.sign_contract ? 'Sim' : 'Não'}
				</CustomDescriptionItem>
			),
		},
	];
};

const createRepresentativeIdentificationDescriptionItems = (
	fullRepresentative: Representative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'Documento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.id_document.type}:{' '}
					{fullRepresentative.id_document.number}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Data de emissão',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{dayjs(
						fullRepresentative.id_document.issue_date,
						'YYYY-MM-DD',
					).format('DD/MM/YYYY')}
				</CustomDescriptionItem>
			),
		},
		{
			key: '3',
			label: 'Órgão emissor',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.id_document.issuer}
				</CustomDescriptionItem>
			),
		},
	];
};

const createRepresentativeAddressDescriptionItems = (
	fullRepresentative: Representative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'CEP',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.postal_code}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Rua / Avenida',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.street_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '3',
			label: 'Número',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.street_number}
				</CustomDescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Estado',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.state_code}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Cidade',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.city}
				</CustomDescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Bairro',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.district}
				</CustomDescriptionItem>
			),
		},
		{
			key: '7',
			label: 'Complemento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.extra_info ?? '-'}
				</CustomDescriptionItem>
			),
		},
	];
};

interface ICompanyRepresentativesProps {
	isLoading?: boolean;
	isAccountCreation?: boolean;
	isCompanyEdition?: boolean;
	person: CompanyDto;
	onNext: (company: CompanyDto) => void;
	onBack: (company: CompanyDto) => void;
	handleStepStatus?: (stepsId: StepsId, status: boolean) => void;
	isPersonCreating?: boolean;
}
const PersonRepresentatives = ({
	isLoading,
	person,
	isAccountCreation,
	isCompanyEdition,
	onNext,
	onBack,
	handleStepStatus,
	isPersonCreating,
}: ICompanyRepresentativesProps) => {
	const theme = useTheme();
	const [representatives, updateRepresentatives] = useState<Representative[]>(
		[],
	);
	const [representativeToEdit, updateRepresentativeToEdit] =
		useState<Representative>();
	const [isRepresentativesCreationOpen, toggleIsRepresentativesCreationOpen] =
		useState(false);
	const [isMissingRepresentatives, toggleIsMissingRepresentatives] =
		useState(false);
	const [isMissingDocuments, toggleIsMissingDocuments] = useState(false);
	const [isMissingSigners, toggleIsMissingSigners] = useState(false);
	const { updateRepresentative, createRepresentative } = useCompany();

	const isNewCompany = useMemo(() => !person.id, [person]);

	useEffect(() => {
		if (Array.isArray(person.representatives)) {
			if (isPersonCreating) {
				person.representatives.forEach(item => {
					item.documents = {
						identification_url: '***',
						proof_of_address_url: '***',
						identification: '***',
						proof_of_address: '***',
					};
				});
			}

			updateRepresentatives(person.representatives);
		}
	}, [person]);

	const onModifyRepresentative = useCallback(
		async (representative: Representative) => {
			if (!representativeToEdit) {
				if (person.id) {
					const response = await createRepresentative(
						person.id,
						representative,
					);
					if (response) {
						updateRepresentatives([
							...representatives,
							{ ...representative, id: response.id },
						]);
					}
				} else {
					updateRepresentatives([...representatives, representative]);
				}
			} else {
				if (person.id) {
					await updateRepresentative(person.id, representative);
				}
				updateRepresentatives(
					representatives.map(r => {
						if (
							r.taxpayer_id === representativeToEdit.taxpayer_id
						) {
							return {
								...r,
								...representative,
							};
						}
						return r;
					}),
				);
				updateRepresentativeToEdit(undefined);
			}
			toggleIsRepresentativesCreationOpen(false);
			toggleIsMissingRepresentatives(false);
			toggleIsMissingDocuments(false);
		},
		[
			representativeToEdit,
			person,
			createRepresentative,
			representatives,
			updateRepresentative,
		],
	);

	const onEditLegalRepresentative = useCallback(
		(
			representative: BaseRepresentative,
			legal_representative: BaseRepresentative,
		) => {
			updateRepresentativeToEdit(representative);
		},
		[],
	);

	const onEditRepresentative = useCallback(
		(representative: BaseRepresentative) => {
			updateRepresentativeToEdit(representative);
			toggleIsRepresentativesCreationOpen(true);
		},
		[],
	);

	const onRemoveRepresentative = useCallback(
		(representative: Representative) => {
			updateRepresentatives(
				representatives.filter(
					r => r.taxpayer_id !== representative.taxpayer_id,
				),
			);
		},
		[representatives],
	);

	const onRemoveLegalRepresentative = useCallback(
		(
			representative: Representative,
			legal_representative: BaseRepresentative,
		) => {
			const newRepresentativesList = representatives.map(r => {
				if (r.taxpayer_id === representative.taxpayer_id) {
					return {
						...r,
						representative: undefined,
					};
				}
				return r;
			});
			updateRepresentatives(newRepresentativesList);
		},
		[representatives],
	);

	const onUpdateDocument = useCallback(
		(
			representative: Representative,
			documents: RepresentativeDocuments,
			legal_representative?: Representative,
		) => {
			updateRepresentatives(
				representatives.map(r => {
					if (r.taxpayer_id === representative.taxpayer_id) {
						if (typeof legal_representative === 'undefined') {
							return {
								...r,
								documents,
							};
						} else {
							return {
								...r,
								representative: {
									...r.representative!,
									documents,
								},
							};
						}
					}
					return r;
				}),
			);
		},
		[representatives],
	);

	const onSave = useCallback(() => {
		if (representatives.length > 0 && !isPersonCreating) {
			const missingDocuments = representatives.some(r => {
				if (
					!(
						(typeof r.documents['identification'] === 'string' &&
							typeof r.documents['proof_of_address'] ===
								'string') ||
						(typeof r.documents['identification_url'] ===
							'string' &&
							typeof r.documents['proof_of_address_url'] ===
								'string')
					)
				) {
					return true;
				}

				if (
					r.representative &&
					!(
						(typeof r.representative.documents['identification'] ===
							'string' &&
							typeof r.representative.documents[
								'proof_of_address'
							] === 'string') ||
						(typeof r.representative.documents[
							'identification_url'
						] === 'string' &&
							typeof r.representative.documents[
								'proof_of_address_url'
							] === 'string')
					)
				) {
					return true;
				}

				return false;
			});

			if (missingDocuments) {
				toggleIsMissingDocuments(true);
				return;
			}

			const missingSigners = representatives.filter(r => {
				if (r.sign_contract) return true;
				if (r.representative && r.representative.sign_contract)
					return true;
				return false;
			});

			if (missingSigners.length === 0) {
				toggleIsMissingSigners(true);
				return;
			}
		}

		onNext({ representatives } as CompanyDto);
		handleStepStatus?.(StepsId.REPRESENTATIVES, true);
	}, [onNext, representatives, handleStepStatus]);

	const onBackWithRepresentatives = useCallback(() => {
		onBack({ representatives } as CompanyDto);
	}, [onBack, representatives]);

	const representativesItems: CollapseProps['items'] = useMemo(
		() =>
			representatives.map((representative, i) => {
				const legalRepresentativesItems:
					| CollapseProps['items']
					| undefined =
					representative.representative &&
					[representative.representative].map(
						(legal_representative, j) => {
							return {
								key: `${j + 1}`,
								label: (
									<CollapseHeader>
										<GoLaw
											color={theme.primary}
											size="24"
										/>{' '}
										<TextM>
											{legal_representative.full_name}
										</TextM>
									</CollapseHeader>
								),
								children: (
									<>
										<Row gutter={[16, 16]}>
											<Col>
												<Descriptions
													layout="vertical"
													column={4}
													items={createRepresentativeBasicDescriptionItems(
														legal_representative,
													)}
												/>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col>
												<TextM>Documentos</TextM>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ paddingBottom: '1rem' }}
										>
											<Col span={24}>
												<Documents
													hideTitle={true}
													isDisabled={!isNewCompany}
													onChangeDocumentsObject={documents => {
														onUpdateDocument(
															representative,
															documents,
															legal_representative,
														);
													}}
													documentsObject={
														legal_representative.documents
													}
													documentsList={
														RepresentativeDocumentsList
													}
												/>
											</Col>
										</Row>
										{(!isAccountCreation ||
											isNewCompany) && (
											<Row
												gutter={[16, 16]}
												justify="end"
											>
												<Col>
													<Button
														type="link"
														onClick={() =>
															onEditLegalRepresentative(
																representative,
																legal_representative,
															)
														}
													>
														Editar
													</Button>
												</Col>
												<Col>
													<Button
														type="link"
														danger={true}
														onClick={() =>
															onRemoveLegalRepresentative(
																representative,
																legal_representative,
															)
														}
													>
														Remover
													</Button>
												</Col>
											</Row>
										)}
									</>
								),
							};
						},
					);

				return {
					key: `${i + 1}`,
					label: (
						<CollapseHeader>
							<IoPeopleOutline color={theme.primary} size="24" />{' '}
							<TextM>{representative.full_name}</TextM>
						</CollapseHeader>
					),
					children: (
						<>
							<Row gutter={[16, 16]}>
								<Col>
									<TextM>Informações básicas</TextM>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeBasicDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>

							<Row
								gutter={[16, 16]}
								style={{ marginTop: '2rem' }}
							>
								<Col>
									<TextM>Identificação</TextM>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeIdentificationDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>

							<Row
								gutter={[16, 16]}
								style={{ marginTop: '2rem' }}
							>
								<Col>
									<TextM>Endereço</TextM>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeAddressDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>
							<Row
								gutter={[16, 16]}
								style={{
									marginBottom: '1rem',
									marginTop: '2rem',
								}}
							>
								<Col>
									<TextM>Documentos</TextM>
								</Col>
							</Row>
							<Row
								gutter={[16, 16]}
								style={{ paddingBottom: '1rem' }}
							>
								<Col span={24}>
									<Documents
										hideTitle={true}
										isDisabled={!isNewCompany}
										onChangeDocumentsObject={documents => {
											onUpdateDocument(
												representative,
												documents,
											);
										}}
										documentsObject={
											representative.documents
										}
										documentsList={
											RepresentativeDocumentsList
										}
									/>
								</Col>
							</Row>
							{legalRepresentativesItems &&
								legalRepresentativesItems.length > 0 && (
									<>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col>
												<TextM>Procuradores</TextM>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col span={24}>
												<Collapse
													expandIconPosition="end"
													items={
														legalRepresentativesItems
													}
												/>
											</Col>
										</Row>
									</>
								)}
							{(!isAccountCreation || isNewCompany) && (
								<Row gutter={[16, 16]} justify="end">
									<Col>
										<Button
											type="link"
											onClick={() =>
												onEditRepresentative(
													representative,
												)
											}
										>
											Editar
										</Button>
									</Col>

									<Col>
										<Button
											type="link"
											danger={true}
											onClick={() =>
												onRemoveRepresentative(
													representative,
												)
											}
										>
											Remover
										</Button>
									</Col>
								</Row>
							)}
						</>
					),
				};
			}),
		[
			isAccountCreation,
			isNewCompany,
			onEditLegalRepresentative,
			onEditRepresentative,
			onRemoveLegalRepresentative,
			onRemoveRepresentative,
			onUpdateDocument,
			representatives,
			theme.primary,
			theme.text,
		],
	);

	return (
		<ContainerFluid>
			<ContainerHeader>
				<Heading2 style={{ fontSize: '1.5rem' }}>Procurador</Heading2>
				{representatives.length > 0 &&
					(!isAccountCreation || isNewCompany) && (
						<PageActions>
							<Button
								type="link"
								onClick={() =>
									toggleIsRepresentativesCreationOpen(true)
								}
							>
								Adicionar
							</Button>
						</PageActions>
					)}
			</ContainerHeader>
			{representatives.length === 0 && (
				<>
					<TextM
						style={{
							textAlign: 'center',
							paddingTop: '1rem',
							color: theme.textSecondary,
						}}
					>
						Você ainda não possui nenhum procurador adicionado!
					</TextM>
					<Empty
						style={{
							minHeight: '50vh',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						image={EmptyRepresentativesList}
						imageStyle={{
							height: 240,
						}}
					>
						<Button
							style={{ marginTop: '0.5rem', height: 'auto' }}
							type="primary"
							onClick={() =>
								toggleIsRepresentativesCreationOpen(true)
							}
						>
							<div
								style={{
									display: 'flex',
									gap: '10px',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '0.2rem',
								}}
							>
								<TextS style={{ color: theme.white }}>
									Adicionar Procurador
								</TextS>
								<IoAddCircleOutline
									size={'1.4rem'}
									style={{ width: '2rem' }}
								/>
							</div>
						</Button>
					</Empty>
				</>
			)}

			{representatives.length > 0 && (
				<Collapse
					expandIconPosition="end"
					items={representativesItems}
				/>
			)}
			{isMissingDocuments && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* Verifique todos os documentos obrigatórios para os
					representantes e procuradores.
				</TextS>
			)}
			{isMissingRepresentatives && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* É necessário adicionar um ou mais representantes.
				</TextS>
			)}
			{isMissingSigners && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* É necessário adicionar um ou mais representantes
					assinantes.
				</TextS>
			)}
			{!isCompanyEdition && (
				<Footer>
					<Button type="link" onClick={onBackWithRepresentatives}>
						Voltar
					</Button>
					<Button type="primary" onClick={onSave} loading={isLoading}>
						{isAccountCreation
							? 'Avançar'
							: isCompanyEdition
								? 'Salvar'
								: 'Concluir'}
					</Button>
				</Footer>
			)}
			<NaturalPersonRepresentativesCreation
				title="Adicionar Procurador"
				isOpen={isRepresentativesCreationOpen}
				representative={representativeToEdit}
				onClose={() => {
					toggleIsRepresentativesCreationOpen(false);
					updateRepresentativeToEdit(undefined);
				}}
				onCreate={onModifyRepresentative}
			/>
		</ContainerFluid>
	);
};

export default PersonRepresentatives;
