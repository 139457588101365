import React from 'react';

const CelcoinLogo: React.FC<{ color?: string }> = ({ color }) => (
	<svg
		width="156"
		height="46"
		viewBox="0 0 156 46"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_10260_1614)">
			<path
				d="M52.8262 22.5058C52.8262 17.3233 56.6671 13.418 61.8112 13.418C64.63 13.418 66.92 14.5904 68.4002 16.4361C68.6125 16.7192 68.6125 16.9688 68.4002 17.1806L66.8138 18.7075C66.5328 18.9907 66.1789 18.9214 65.9686 18.6719C64.9464 17.6421 63.7848 16.9688 61.9861 16.9688C58.7447 16.9688 56.4881 19.3116 56.4881 22.5079C56.4881 25.7043 58.7427 28.1163 62.0548 28.1163C63.816 28.1163 64.9797 27.4409 65.9665 26.4132C66.2122 26.1657 66.5661 26.0944 66.8471 26.3776L68.4335 27.9044C68.6458 28.1184 68.6458 28.3659 68.4335 28.649C66.9533 30.4947 64.6633 31.6671 61.8799 31.6671C56.7025 31.665 52.8262 27.7241 52.8262 22.5058Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M70.1279 22.4702C70.1279 17.3589 73.6524 13.418 78.9027 13.418C84.0823 13.418 87.078 17.3589 87.078 21.69C87.078 23.2168 86.7615 23.9614 85.4563 23.9614H73.8273C74.2853 26.7299 76.3296 28.5756 79.748 28.5756C81.4384 28.5756 82.8144 28.1498 84.1884 27.227C84.4008 27.0844 84.611 27.12 84.7526 27.334L85.7394 28.6826C85.9517 28.9657 85.8809 29.1796 85.5978 29.4628C84.0823 30.954 81.8631 31.6629 79.4315 31.6629C73.7919 31.665 70.1279 27.6528 70.1279 22.4702ZM83.695 21.1908C83.4827 18.351 81.6153 16.6458 78.9027 16.6458C76.1901 16.6458 74.1812 18.3153 73.794 21.1908H83.695Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M89.5078 30.7052V6.38625C89.5078 6.06745 89.6848 5.85352 90.0012 5.85352H92.82C93.1364 5.85352 93.3133 6.06745 93.3133 6.38625V30.7052C93.3133 31.024 93.1364 31.2379 92.82 31.2379H90.0012C89.6848 31.2379 89.5078 31.024 89.5078 30.7052Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M95.7451 22.5058C95.7451 17.3233 99.586 13.418 104.73 13.418C107.549 13.418 109.839 14.5904 111.319 16.4361C111.531 16.7192 111.531 16.9688 111.319 17.1806L109.733 18.7075C109.452 18.9907 109.098 18.9214 108.888 18.6719C107.865 17.6421 106.704 16.9688 104.905 16.9688C101.664 16.9688 99.407 19.3116 99.407 22.5079C99.407 25.7043 101.662 28.1163 104.974 28.1163C106.735 28.1163 107.899 27.4409 108.885 26.4132C109.131 26.1657 109.485 26.0944 109.766 26.3776L111.352 27.9044C111.565 28.1184 111.565 28.3659 111.352 28.649C109.872 30.4947 107.582 31.6671 104.799 31.6671C99.6214 31.665 95.7451 27.7241 95.7451 22.5058Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M112.341 22.5415C112.341 17.3946 116.146 13.418 121.29 13.418C126.399 13.418 130.205 17.3946 130.205 22.5415C130.205 27.6884 126.399 31.665 121.29 31.665C116.146 31.665 112.341 27.6884 112.341 22.5415ZM121.29 28.1142C124.251 28.1142 126.541 25.8784 126.541 22.5415C126.541 19.2046 124.251 16.9688 121.29 16.9688C118.295 16.9688 116.005 19.2046 116.005 22.5415C116.005 25.8784 118.295 28.1142 121.29 28.1142Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M132.355 7.87786C132.355 6.67188 133.307 5.67773 134.575 5.67773C135.807 5.67773 136.758 6.67188 136.758 7.87786C136.758 9.1195 135.807 10.1137 134.575 10.1137C133.307 10.1137 132.355 9.1195 132.355 7.87786ZM132.637 14.3734C132.637 14.0546 132.813 13.8407 133.13 13.8407H135.984C136.3 13.8407 136.477 14.0546 136.477 14.3734V30.7035C136.477 31.0222 136.3 31.2362 135.984 31.2362H133.13C132.813 31.2362 132.637 31.0222 132.637 30.7035V14.3734Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M140.495 30.7065V14.3765C140.495 14.0577 140.672 13.8437 140.989 13.8437H143.666C143.982 13.8437 144.159 13.9864 144.159 14.4121L144.301 16.543C145.287 14.7687 147.013 13.418 149.728 13.418C153.392 13.418 156 15.9747 156 20.5532V30.7065C156 31.0253 155.788 31.2392 155.472 31.2392H152.688C152.372 31.2392 152.195 31.0253 152.195 30.7065V21.2642C152.195 18.5313 150.856 16.9688 148.425 16.9688C145.993 16.9688 144.303 18.5313 144.303 21.2642V30.7065C144.303 31.0253 144.126 31.2392 143.809 31.2392H140.991C140.67 31.2392 140.495 31.0253 140.495 30.7065Z"
				fill={color ?? '#7664FA'}
			/>
			<path
				d="M44.7067 22.62C44.7067 13.486 39.2295 5.38602 30.9106 1.94635C30.6275 0.828458 29.6303 0 28.4333 0C27.0156 0 25.8664 1.15774 25.8664 2.58604C25.8664 4.01435 27.0156 5.17209 28.4333 5.17209C28.833 5.17209 29.2056 5.07142 29.5429 4.90783C36.7293 7.81477 41.4737 14.7717 41.4737 22.62C41.4737 32.6203 33.7502 40.8377 23.9553 41.6557V37.8909C28.885 37.3393 33.2298 34.4156 35.6217 30.0321C36.9125 29.8958 37.9221 28.8094 37.9221 27.4733C37.9221 26.045 36.773 24.8894 35.3574 24.8894C33.9417 24.8894 32.7905 26.0471 32.7905 27.4733C32.7905 27.7271 32.8384 27.9662 32.905 28.1969C30.844 32.1882 26.7803 34.7386 22.2586 34.7386C15.626 34.7386 10.23 29.3022 10.23 22.62C10.23 15.9379 15.626 10.5015 22.2586 10.5015C26.8074 10.5015 30.894 13.0812 32.9425 17.1145C32.8509 17.3808 32.7905 17.6598 32.7905 17.9576C32.7905 19.3859 33.9397 20.5437 35.3574 20.5437C36.7751 20.5437 37.9221 19.3859 37.9221 17.9576C37.9221 16.6614 36.9728 15.5981 35.7363 15.4114C33.3692 10.9126 28.9621 7.90915 23.9553 7.34916V2.03444C23.9553 2.01976 23.947 2.00718 23.947 1.99249C23.9491 1.95055 23.9699 1.91699 23.9699 1.87504C23.9699 0.975274 23.2454 0.247487 22.3544 0.247487C10.0281 0.247487 0 10.2834 0 22.62C0 31.752 5.47723 39.8541 13.7961 43.2937C14.0792 44.4116 15.0764 45.2401 16.2735 45.2401C17.6912 45.2401 18.8403 44.0824 18.8403 42.6541C18.8403 41.2257 17.6912 40.068 16.2735 40.068C15.8738 40.068 15.5011 40.1687 15.1639 40.3323C7.97747 37.4253 3.23304 30.4705 3.23304 22.62C3.23304 12.6303 10.942 4.41705 20.7223 3.58649V7.32399C13.0258 8.10211 6.99694 14.6648 6.99694 22.6221C6.99694 30.5795 13.0258 37.1422 20.7223 37.9203V43.4007C20.7223 44.3005 21.4467 45.0304 22.3378 45.0304C22.4148 45.0304 22.4814 44.9968 22.5563 44.9863C34.7869 44.8731 44.7067 34.8896 44.7067 22.62Z"
				fill={color ?? '#7664FA'}
			/>
		</g>
		<defs>
			<clipPath id="clip0_10260_1614">
				<rect width="156" height="45.2401" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default CelcoinLogo;
