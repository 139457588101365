import { ForwardButton } from './styles';
import { Col, Row } from 'antd';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { TextM } from 'components/Text';
import { formatCurrency } from 'helpers/normalizers';
import { useTheme } from 'styled-components';

interface IDiscountSuccessProps {
	discountValue: number;
	onClose: () => void;
}
const DiscountSuccess = ({ discountValue, onClose }: IDiscountSuccessProps) => {
	const theme = useTheme();
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={3}>
					<CheckmarkIcon />
				</Col>
				<Col span={21}>
					<TextM weight="normal">
						<span style={{ color: theme.primary }}>
							{formatCurrency(discountValue)}
						</span>{' '}
						foram descontados do boleto com sucesso
					</TextM>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
				<Col span={24}>
					<ForwardButton onClick={onClose} type="primary">
						<TextM>Ok, obrigado</TextM>
					</ForwardButton>
				</Col>
			</Row>
		</>
	);
};

export default DiscountSuccess;
