import {
	Col,
	Flex,
	Form,
	Input,
	InputNumber,
	notification,
	Row,
	Select,
} from 'antd';
import { Modal } from 'components/Modal';
import { TextL, TextM, TextS } from 'components/Text';
import { FeetSettingsModalWrapper } from './styles';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { useTheme } from 'styled-components';
import { CurrencyInput } from 'components/CurrencyInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	AccountFee,
	AccountFeeMode,
	AccountFeeType,
	ISelectFeesStateControl,
} from 'types/Account';
import { PaymentType } from 'types/Posting';
import { FeeType, IFeeSettingsModalFormValues } from './types';
import { CollapseHeader } from 'components/Collapse';
import { CollapseProps } from 'antd/lib';
import { CardConfig } from 'components/CardConfig';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { cpfCnpjRule, fieldGreaterThanZeroRule } from 'helpers/rules';
import { BankListOptions } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { DestinationType } from 'types/Destination';
import { CustomCollapse } from './components/CustomCollapse';
import { useDestinations } from 'modules/escrow/hooks';
import { useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';
import { ApiError } from 'types/ApiError';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { serializeOnlyNumbers } from 'helpers/serializers';

export interface FeeSettingsModalProps {
	isOpen: boolean;
	loading?: boolean;
	feesSettings?: AccountFee;
	isEdit?: boolean;
	accountId?: string;
	onClose: () => void;
	handleConfirm: (values: AccountFee) => void;
}

export const FEE_TYPE_OPTIONS = [
	{ label: 'Valor fixo', value: AccountFeeType.FIXED },
	{ label: 'Percentual', value: AccountFeeType.PERCENT },
];

export const FEE_TYPES = [
	{ label: 'Valor fixo', value: AccountFeeType.FIXED },
	{ label: 'Sem cobrança', value: AccountFeeType.NONE },
];

const FeeSettingsModalComission = ({
	isOpen,
	feesSettings,
	isEdit,
	accountId,
	onClose,
	handleConfirm,
}: FeeSettingsModalProps) => {
	const COMMISSION_KEY = 'commission';
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const [form] = Form.useForm();

	const [
		createDestinationConfirmationModalOpen,
		setCreateDestinationConfirmationModalOpen,
	] = useState(false);
	const [activeKeys, setActiveKeys] = useState<string[]>([
		FeeType.MAINTENANCE,
	]);
	const [commissionActiveKeys, setCommissionActiveKeys] = useState<string[]>(
		[],
	);

	const [feesType, setFeesType] = useState<ISelectFeesStateControl>({
		depositType: feesSettings?.deposit?.amount_type || AccountFeeType.NONE,
		pixDebitType:
			feesSettings?.withdrawal?.amount_type || AccountFeeType.NONE,
		tedDebitType:
			feesSettings?.ted_transfer?.amount_type || AccountFeeType.NONE,
	});

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', accountId],
		queryFn: () => AccountsService.getAccount(accountId!),
		refetchOnWindowFocus: false,
		enabled: typeof accountId === 'string',
	});

	const { createOrUpdateDestination, loading } = useDestinations(
		data?.id || '',
		{
			onCreateSuccess: async ({ id }) => {
				form.setFieldValue('commissionAccountDestinationId', id);
				handleCreateFeeSettings();
			},
			onError: (error: ApiError) => {
				api.error({
					message: error.data.message,
				});
				handleClose();
			},
		},
	);

	const handleCreateAccountDestination = useCallback(
		(forceCreation: boolean = false) => {
			if (!activeKeys.includes(COMMISSION_KEY)) {
				handleCreateFeeSettings();
				return;
			}

			const values = form.getFieldsValue();
			const destination = {
				taxpayer_id: serializeOnlyNumbers(
					values.commissionAccountTaxpayerId || '',
				),
				name: values.commissionAccountName,
				bank: values.commissionAccountBank,
				branch: values.commissionAccountBranch,
				account: serializeOnlyNumbers(
					values.commissionAccountAccount || '',
				),
				type: values.commissionAccountType,
			};

			//HK: verificar como será feito quando usuário quiser cadastrar uma conta
			if (
				!forceCreation &&
				feesSettings?.commission_account_destination?.account != null &&
				feesSettings?.commission_account_destination?.account !==
					destination.account
			) {
				setCreateDestinationConfirmationModalOpen(true);
				return;
			}

			createOrUpdateDestination(destination);
		},
		[form, activeKeys, commissionActiveKeys, feesType],
	);

	const handleCreateFeeSettings = useCallback(() => {
		const values = form.getFieldsValue();

		const isMaintenanceActive = activeKeys.includes(FeeType.MAINTENANCE);
		const isCommissionMaintenanceActive = commissionActiveKeys.includes(
			FeeType.MAINTENANCE,
		);
		const isCommissionWithdrawalActive = commissionActiveKeys.includes(
			FeeType.WITHDRAWAL,
		);
		const isCommissionTedActive = commissionActiveKeys.includes(
			FeeType.TED_TRANSFER,
		);
		const isCommissionChargeSettlementActive =
			commissionActiveKeys.includes(FeeType.CHARGE_SETTLEMENT);
		const isTedActive = activeKeys.includes(FeeType.TED_TRANSFER);
		const isChargeSettlementActive = activeKeys.includes(
			FeeType.CHARGE_SETTLEMENT,
		);
		const isDepositActive = activeKeys.includes(FeeType.DEPOSIT);

		const isPixActive = activeKeys.includes(FeeType.WITHDRAWAL);

		const fees: AccountFee = {
			mode: values.feeMode,
			default_payment_type: PaymentType.PIX,
			maintenance: {
				amount: isMaintenanceActive
					? values?.maintenanceAmount || 0
					: 0,
				day: isMaintenanceActive
					? Number(values.maintenanceDay) || 1
					: 1, // 1 === Default date
				...(isCommissionMaintenanceActive && {
					commission_percent_amount:
						values.commissionMaintenanceAmount,
				}),
			},
			// Entrada
			deposit: {
				amount_type: isDepositActive
					? feesType.depositType || AccountFeeType.NONE
					: AccountFeeType.NONE,
				amount: isDepositActive ? values.deposit : 0,
			},
			// PIX
			withdrawal: {
				amount_type: isPixActive
					? feesType.pixDebitType
					: AccountFeeType.NONE,
				amount: isPixActive
					? values.pixDebitType === AccountFeeType.NONE
						? 0
						: values.pixDebit
					: 0,
				...(activeKeys.includes(COMMISSION_KEY) &&
					isCommissionWithdrawalActive && {
						commission_percent_amount:
							values.commissionWithdrawalAmount,
					}),
			},
			// TED
			ted_transfer: {
				amount_type: isTedActive
					? feesType.tedDebitType
					: AccountFeeType.NONE,
				amount: isTedActive ? values.tedDebit : 0,
				...(activeKeys.includes(COMMISSION_KEY) &&
					isCommissionTedActive && {
						commission_percent_amount:
							values.commissionTedTransferAmount,
					}),
			},
			charge_settlement: {
				amount: isChargeSettlementActive ? values.chargeSettlement : 0,
				amount_type: isChargeSettlementActive
					? AccountFeeType.FIXED
					: AccountFeeType.NONE,
				...(activeKeys.includes(COMMISSION_KEY) &&
					isCommissionChargeSettlementActive && {
						commission_percent_amount:
							values.commissionChargeSettlementAmount,
					}),
			},

			//commission account destination
			commission_account_destination_id:
				values.commissionAccountDestinationId,
			...(activeKeys.includes(COMMISSION_KEY) &&
				values.commissionAccountAccount && {
					commission_account_destination: {
						taxpayer_id: values.commissionAccountTaxpayerId,
						name: values.commissionAccountName,
						bank: values.commissionAccountBank,
						branch: values.commissionAccountBranch,
						account: values.commissionAccountAccount,
						type: values.commissionAccountType,
					},
				}),
		};

		handleConfirm(fees);
		handleClose();
	}, [activeKeys, commissionActiveKeys, feesType, form]);

	const handleFeeSettings = useCallback(() => {
		if (form.getFieldValue('commissionAccountDestinationId')) {
			handleCreateFeeSettings();
			return;
		}

		handleCreateAccountDestination();
	}, [feesType, activeKeys, commissionActiveKeys]);

	const handleClose = () => {
		setActiveKeys([]);
		setCommissionActiveKeys([]);
		setCreateDestinationConfirmationModalOpen(false);
		onClose();
	};

	const handleCommissionBankInputBlur = () => {
		const bank = form.getFieldValue('commissionAccountBank');
		const account = form.getFieldValue('commissionAccountAccount');
		const branch = form.getFieldValue('commissionAccountBranch');
		const accountType = form.getFieldValue('commissionAccountType');

		if (bank && account && branch && accountType) {
			const foundDestination = data?.destinations.find(
				destination =>
					destination.bank === bank &&
					serializeOnlyNumbers(destination.account) ===
						serializeOnlyNumbers(account) &&
					destination.branch === branch &&
					destination.type === accountType,
			);

			if (!foundDestination) {
				form.setFieldValue('commissionAccountDestinationId', undefined);
				return;
			}

			form.setFieldsValue({
				commissionAccountDestinationId: foundDestination?.id,
				commissionAccountTaxpayerId: normalizeCpfCnpj(
					foundDestination?.taxpayer_id || '',
				),
				commissionAccountName: foundDestination?.name,
				commissionAccountType: foundDestination?.type,
			});

			api.info({
				message: 'Conta já cadastrada, informações preenchidas',
			});
		}
	};

	const handleToggleCommissionToggle = (key: FeeType) => {
		if (!activeKeys.includes(key)) {
			api.error({
				message:
					'Para ativar o rebate, é necessário ativar as tarifas de cobrança',
			});
			return;
		}

		setCommissionActiveKeys(prevState => {
			if (prevState.includes(key)) {
				return prevState.filter(item => item !== key);
			}
			return [...prevState, key];
		});
	};

	useEffect(() => {
		if (isOpen) {
			if (typeof feesSettings !== 'undefined') {
				setActiveKeys(() => {
					const keys = [];
					if (feesSettings?.maintenance?.amount) {
						keys.push(FeeType.MAINTENANCE);
					}
					if (feesSettings?.deposit?.amount) {
						keys.push(FeeType.DEPOSIT);
					}
					if (feesSettings?.withdrawal?.amount) {
						keys.push(FeeType.WITHDRAWAL);
					}
					if (feesSettings?.ted_transfer?.amount) {
						keys.push(FeeType.TED_TRANSFER);
					}
					if (feesSettings?.charge_settlement?.amount) {
						keys.push(FeeType.CHARGE_SETTLEMENT);
					}

					if (
						feesSettings.withdrawal?.commission_percent_amount ||
						feesSettings.maintenance?.commission_percent_amount ||
						feesSettings.charge_settlement
							?.commission_percent_amount ||
						feesSettings.ted_transfer?.commission_percent_amount
					) {
						keys.push(COMMISSION_KEY);
					}

					return keys;
				});

				setCommissionActiveKeys(() => {
					const keys = [];
					if (feesSettings?.withdrawal?.commission_percent_amount) {
						keys.push(FeeType.WITHDRAWAL);
					}
					if (feesSettings?.ted_transfer?.commission_percent_amount) {
						keys.push(FeeType.TED_TRANSFER);
					}
					if (
						feesSettings?.charge_settlement
							?.commission_percent_amount
					) {
						keys.push(FeeType.CHARGE_SETTLEMENT);
					}
					if (feesSettings?.maintenance?.commission_percent_amount) {
						keys.push(FeeType.MAINTENANCE);
					}

					return keys;
				});

				form.setFieldValue(
					'feeMode',
					feesSettings?.mode ?? AccountFeeMode.DIRECT_DEBIT,
				);
				form.setFieldValue(
					'defaultPaymentType',
					feesSettings?.default_payment_type ?? PaymentType.PIX,
				);
				form.setFieldValue(
					'maintenanceAmount',
					feesSettings?.maintenance?.amount,
				);
				form.setFieldValue(
					'maintenanceDay',
					feesSettings?.maintenance?.day,
				);

				form.setFieldValue(
					'tedDebitType',
					feesSettings.ted_transfer?.amount_type
						? feesSettings.ted_transfer?.amount_type ===
							AccountFeeType.NONE
							? undefined
							: feesSettings.ted_transfer?.amount_type
						: undefined,
				);
				form.setFieldValue(
					'tedDebit',
					feesSettings?.ted_transfer?.amount,
				);
				form.setFieldValue(
					'pixDebitType',
					feesSettings?.withdrawal?.amount_type
						? feesSettings?.withdrawal?.amount_type ===
							AccountFeeType.NONE
							? undefined
							: feesSettings?.withdrawal?.amount_type
						: undefined,
				);
				form.setFieldValue(
					'pixDebit',
					feesSettings?.withdrawal?.amount,
				);
				form.setFieldValue(
					'depositType',
					feesSettings?.deposit?.amount_type
						? feesSettings?.deposit?.amount_type ===
							AccountFeeType.NONE
							? undefined
							: feesSettings?.deposit?.amount_type
						: undefined,
				);
				form.setFieldValue('deposit', feesSettings?.deposit?.amount);
				form.setFieldValue(
					'chargeSettlement',
					feesSettings?.charge_settlement?.amount,
				);

				// Comission
				form.setFieldValue(
					'commissionWithdrawalAmount',
					feesSettings?.withdrawal?.commission_percent_amount,
				);
				form.setFieldValue(
					'commissionTedTransferAmount',
					feesSettings?.ted_transfer?.commission_percent_amount,
				);

				form.setFieldValue(
					'commissionChargeSettlementAmount',
					feesSettings?.charge_settlement?.commission_percent_amount,
				);

				form.setFieldValue(
					'commissionMaintenanceAmount',
					feesSettings?.maintenance?.commission_percent_amount,
				);

				//commission account destination
				form.setFieldValue(
					'commissionAccountDestinationId',
					feesSettings?.commission_account_destination?.id,
				);
				form.setFieldValue(
					'commissionAccountTaxpayerId',
					normalizeCpfCnpj(
						feesSettings?.commission_account_destination
							?.taxpayer_id || '',
					),
				);
				form.setFieldValue(
					'commissionAccountName',
					feesSettings?.commission_account_destination?.name,
				);
				form.setFieldValue(
					'commissionAccountBank',
					feesSettings?.commission_account_destination?.bank,
				);
				form.setFieldValue(
					'commissionAccountBranch',
					feesSettings?.commission_account_destination?.branch,
				);
				form.setFieldValue(
					'commissionAccountAccount',
					normalizeBankAccount(
						feesSettings?.commission_account_destination?.account ||
							'',
					),
				);
				form.setFieldValue(
					'commissionAccountType',
					feesSettings?.commission_account_destination?.type,
				);
			} else {
				form.setFieldValue('feeMode', AccountFeeMode.DIRECT_DEBIT);
				form.setFieldValue('defaultPaymentType', PaymentType.PIX);

				form.setFieldValue('maintenanceAmount', undefined);
				form.setFieldValue('maintenanceDay', undefined);

				form.setFieldValue('tedDebitType', undefined);
				form.setFieldValue('tedDebit', undefined);
				form.setFieldValue('pixDebit', undefined);
				form.setFieldValue('pixDebitType', undefined);
				form.setFieldValue('depositType', undefined);
				form.setFieldValue('deposit', undefined);
				// Comission
				form.setFieldValue('commissionWithdrawalAmount', undefined);
				form.setFieldValue('commissionTedTransferAmount', undefined);
				form.setFieldValue(
					'commissionChargeSettlementAmount',
					undefined,
				);
				form.setFieldValue('commissionMaintenanceAmount', undefined);

				//commission account destination
				form.setFieldValue('commissionAccountTaxpayerId', undefined);
				form.setFieldValue('commissionAccountName', undefined);
				form.setFieldValue('commissionAccountBank', undefined);
				form.setFieldValue('commissionAccountBranch', undefined);
				form.setFieldValue('commissionAccountAccount', undefined);
				form.setFieldValue('commissionAccountType', undefined);
			}
		}
	}, [isOpen, feesSettings]);

	const feeFormItems: CollapseProps['items'] = useMemo(
		() => [
			{
				key: FeeType.MAINTENANCE,
				label: (
					<CollapseHeader>
						<TextM>Taxa de Manutenção</TextM>
					</CollapseHeader>
				),
				children: (
					<>
						<Form.Item
							name="maintenanceAmount"
							label="Valor da taxa de manutenção"
							style={{ width: '100%' }}
							rules={
								activeKeys.includes(FeeType.MAINTENANCE)
									? [
											{
												required: true,
											},
											fieldGreaterThanZeroRule,
										]
									: []
							}
						>
							<CurrencyInput placeholder="0,00" />
						</Form.Item>

						<Form.Item
							name="maintenanceDay"
							label="Dia da manutenção"
							style={{ width: '100%' }}
						>
							<InputNumber
								placeholder="15"
								max={31}
								min={1}
								style={{ width: '100%' }}
								changeOnWheel={false}
								disabled={
									form.getFieldsValue([
										'maintenanceAmount',
									]) === 0
								}
							/>
						</Form.Item>
					</>
				),
			},
			{
				key: FeeType.TED_TRANSFER,
				label: (
					<CollapseHeader>
						<TextM>Tarifas TED</TextM>
					</CollapseHeader>
				),
				children: (
					<>
						<Form.Item
							name="tedDebitType"
							label="Tipo de cobrança"
							style={{ width: '100%' }}
						>
							<Select
								placeholder="Selecione"
								options={FEE_TYPE_OPTIONS.map(option => ({
									...option,
								}))}
								onChange={value => {
									form.setFieldsValue({
										tedDebitType: value,
										tedDebit: 0,
									});
									setFeesType({
										...feesType,
										tedDebitType: value,
									});
								}}
							/>
						</Form.Item>
						<Form.Item
							name="tedDebit"
							label="Valor sobre recurso de saída"
							style={{ width: '100%' }}
							rules={
								activeKeys.includes(FeeType.TED_TRANSFER)
									? [
											{
												required: true,
											},
											fieldGreaterThanZeroRule,
										]
									: []
							}
						>
							{feesType.tedDebitType ===
							AccountFeeType.PERCENT ? (
								<InputNumber
									min={0}
									max={100}
									changeOnWheel={false}
									decimalSeparator=","
									style={{ width: '100%' }}
									prefix={'%'}
									placeholder="0"
								/>
							) : (
								<CurrencyInput
									placeholder="0,00"
									disabled={
										feesType.tedDebitType ===
										AccountFeeType.NONE
									}
								/>
							)}
						</Form.Item>
					</>
				),
			},
			{
				key: FeeType.WITHDRAWAL,
				label: (
					<CollapseHeader>
						<TextM>Tarifas Pix</TextM>
					</CollapseHeader>
				),
				children: (
					<>
						<Form.Item
							name="pixDebitType"
							label="Tipo de cobrança"
							style={{ width: '100%' }}
						>
							<Select
								placeholder="Selecione"
								options={FEE_TYPE_OPTIONS.map(option => ({
									...option,
								}))}
								onChange={value => {
									form.setFieldsValue({
										pixDebitType: value,
										pixDebit: 0,
									});
									setFeesType({
										...feesType,
										pixDebitType: value,
									});
								}}
							/>
						</Form.Item>
						<Form.Item
							name="pixDebit"
							label="Valor sobre recurso de saída"
							style={{ width: '100%' }}
							rules={
								activeKeys.includes(FeeType.WITHDRAWAL)
									? [
											{
												required: true,
											},
											fieldGreaterThanZeroRule,
										]
									: []
							}
						>
							{feesType.pixDebitType ===
							AccountFeeType.PERCENT ? (
								<InputNumber
									min={0}
									max={100}
									changeOnWheel={false}
									decimalSeparator=","
									style={{ width: '100%' }}
									prefix={'%'}
									placeholder="0"
								/>
							) : (
								<CurrencyInput
									placeholder="0,00"
									disabled={
										feesType.pixDebitType ===
										AccountFeeType.NONE
									}
								/>
							)}
						</Form.Item>
					</>
				),
			},
			{
				key: FeeType.DEPOSIT,
				label: (
					<CollapseHeader>
						<TextM>Tarifas de Entrada</TextM>
					</CollapseHeader>
				),
				children: (
					<>
						<Form.Item
							name="depositType"
							label="Tipo de cobrança"
							style={{ width: '100%' }}
						>
							<Select
								placeholder="Selecione"
								options={FEE_TYPE_OPTIONS.map(option => ({
									...option,
								}))}
								onChange={value => {
									form.setFieldsValue({
										depositType: value,
										deposit: 0,
									});
									setFeesType({
										...feesType,
										depositType: value,
									});
								}}
							/>
						</Form.Item>
						<Form.Item
							name="deposit"
							label="Valor sobre recurso de entrada"
							style={{ width: '100%' }}
							rules={
								activeKeys.includes(FeeType.DEPOSIT)
									? [
											{
												required: true,
											},
											fieldGreaterThanZeroRule,
										]
									: []
							}
						>
							{feesType.depositType === AccountFeeType.PERCENT ? (
								<InputNumber
									min={0}
									max={100}
									changeOnWheel={false}
									decimalSeparator=","
									style={{ width: '100%' }}
									prefix={'%'}
									placeholder="0"
								/>
							) : (
								<CurrencyInput
									placeholder="0,00"
									disabled={
										feesType.depositType ===
										AccountFeeType.NONE
									}
								/>
							)}
						</Form.Item>
					</>
				),
			},
			{
				key: FeeType.CHARGE_SETTLEMENT,
				label: (
					<CollapseHeader>
						<TextM>Tarifas de Cobrança</TextM>
					</CollapseHeader>
				),
				children: (
					<>
						<Form.Item
							name="chargeSettlement"
							label="Tarifa de liquidação de boleto"
							style={{ width: '100%' }}
							rules={
								activeKeys.includes(FeeType.CHARGE_SETTLEMENT)
									? [
											{
												required: true,
											},
											fieldGreaterThanZeroRule,
										]
									: []
							}
						>
							<CurrencyInput placeholder="0,00" />
						</Form.Item>
					</>
				),
			},
			{
				key: COMMISSION_KEY,
				label: (
					<CollapseHeader>
						<TextM>Rebate</TextM>
					</CollapseHeader>
				),
				children: (
					<>
						<TextL style={{ margin: '1rem 0' }}>
							Conta beneficiária
						</TextL>
						<Form.Item
							shouldUpdate={() => {
								return true;
							}}
						>
							{({ getFieldValue }) => {
								return (
									<>
										<Row
											gutter={[16, 16]}
											style={{
												display: 'none',
											}}
										>
											<Col span={24}>
												<Form.Item name="commissionAccountDestinationId">
													<Input
														disabled={
															feesSettings !=
																undefined &&
															feesSettings
																.commission_account_destination
																?.id !==
																undefined
														}
														placeholder="CPF/CNPJ"
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="commissionAccountBank"
													label="Banco"
													rules={
														activeKeys.includes(
															COMMISSION_KEY,
														)
															? [
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]
															: []
													}
												>
													<Select
														showSearch={true}
														filterOption={(
															input,
															option,
														) =>
															!!option?.label
																?.toLowerCase()
																?.includes(
																	input.toLowerCase(),
																)
														}
														placeholder="Selecione"
														options={
															BankListOptions
														}
														onBlur={
															handleCommissionBankInputBlur
														}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col span={12}>
												<Form.Item
													name="commissionAccountBranch"
													label="Agência"
													rules={
														activeKeys.includes(
															COMMISSION_KEY,
														)
															? [
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]
															: []
													}
												>
													<Input
														placeholder="0000"
														onBlur={
															handleCommissionBankInputBlur
														}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													name="commissionAccountAccount"
													label="Conta"
													normalize={value =>
														normalizeBankAccount(
															value,
														)
													}
													rules={
														activeKeys.includes(
															COMMISSION_KEY,
														)
															? [
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]
															: []
													}
												>
													<Input
														placeholder="000000-0"
														onBlur={
															handleCommissionBankInputBlur
														}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="commissionAccountType"
													label="Tipo de conta"
													rules={
														activeKeys.includes(
															COMMISSION_KEY,
														)
															? [
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]
															: []
													}
												>
													<Select
														onBlur={
															handleCommissionBankInputBlur
														}
														placeholder="Selecione"
														options={Object.keys(
															DestinationTypeDescription,
														).map(key => ({
															label: DestinationTypeDescription[
																key as DestinationType
															],
															value: key,
														}))}
													/>
												</Form.Item>
											</Col>
										</Row>

										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="commissionAccountTaxpayerId"
													label="Identificação do beneficiário"
													normalize={value =>
														normalizeCpfCnpj(value)
													}
													rules={
														activeKeys.includes(
															COMMISSION_KEY,
														)
															? [
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																	cpfCnpjRule,
																]
															: []
													}
													shouldUpdate={() => {
														return true;
													}}
												>
													<Input
														disabled={
															!getFieldValue(
																'commissionAccountBank',
															) ||
															!getFieldValue(
																'commissionAccountAccount',
															) ||
															!getFieldValue(
																'commissionAccountBranch',
															)
														}
														placeholder="CPF/CNPJ"
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<Form.Item
													name="commissionAccountName"
													label="Nome do beneficiário"
													rules={
														activeKeys.includes(
															COMMISSION_KEY,
														)
															? [
																	{
																		required:
																			true,
																		message:
																			'Campo obrigatório',
																	},
																]
															: []
													}
												>
													<Input
														disabled={
															!getFieldValue(
																'commissionAccountBank',
															) ||
															!getFieldValue(
																'commissionAccountAccount',
															) ||
															!getFieldValue(
																'commissionAccountBranch',
															)
														}
														placeholder="Ex.: João da Silva"
													/>
												</Form.Item>
											</Col>
										</Row>
									</>
								);
							}}
						</Form.Item>

						<TextL
							style={{
								margin: '2.2rem 0rem 1rem',
							}}
						>
							Configuração do rebate
						</TextL>

						<Form.Item
							shouldUpdate={() => {
								return true;
							}}
						>
							{({ getFieldValue }) => (
								<>
									<CustomCollapse
										isOpen={
											commissionActiveKeys.includes(
												FeeType.WITHDRAWAL,
											) &&
											activeKeys.includes(
												FeeType.WITHDRAWAL,
											)
										}
										title={'Rebate Pix'}
										onToggle={() => {
											handleToggleCommissionToggle(
												FeeType.WITHDRAWAL,
											);
										}}
									>
										<>
											<Form.Item
												name="commissionWithdrawalAmount"
												label="Porcentagem de rebate"
												style={{ width: '100%' }}
												rules={
													commissionActiveKeys.includes(
														FeeType.WITHDRAWAL,
													)
														? [
																{
																	required:
																		true,
																},
																fieldGreaterThanZeroRule,
															]
														: []
												}
											>
												<InputNumber
													min={0}
													max={100}
													changeOnWheel={false}
													decimalSeparator=","
													style={{
														width: '100%',
													}}
													prefix={'%'}
													placeholder="0"
												/>
											</Form.Item>
										</>
									</CustomCollapse>
									<CustomCollapse
										isOpen={
											commissionActiveKeys.includes(
												FeeType.TED_TRANSFER,
											) &&
											activeKeys.includes(
												FeeType.TED_TRANSFER,
											)
										}
										title="Rebate Ted"
										onToggle={() => {
											handleToggleCommissionToggle(
												FeeType.TED_TRANSFER,
											);
										}}
									>
										<>
											<Form.Item
												name="commissionTedTransferAmount"
												label="Porcentagem de rebate"
												style={{ width: '100%' }}
												rules={
													commissionActiveKeys.includes(
														FeeType.TED_TRANSFER,
													)
														? [
																{
																	required:
																		true,
																},
																fieldGreaterThanZeroRule,
															]
														: []
												}
											>
												<InputNumber
													min={0}
													max={100}
													changeOnWheel={false}
													decimalSeparator=","
													style={{
														width: '100%',
													}}
													prefix={'%'}
													placeholder="0"
												/>
											</Form.Item>
										</>
									</CustomCollapse>
									<CustomCollapse
										isOpen={
											commissionActiveKeys.includes(
												FeeType.CHARGE_SETTLEMENT,
											) &&
											activeKeys.includes(
												FeeType.CHARGE_SETTLEMENT,
											)
										}
										title="Rebate Boleto"
										onToggle={() => {
											handleToggleCommissionToggle(
												FeeType.CHARGE_SETTLEMENT,
											);
										}}
									>
										<>
											<Form.Item
												name="commissionChargeSettlementAmount"
												label="Porcentagem de rebate"
												style={{ width: '100%' }}
												rules={
													commissionActiveKeys.includes(
														FeeType.CHARGE_SETTLEMENT,
													)
														? [
																{
																	required:
																		true,
																},
																fieldGreaterThanZeroRule,
															]
														: []
												}
											>
												<InputNumber
													min={0}
													max={100}
													changeOnWheel={false}
													decimalSeparator=","
													style={{
														width: '100%',
													}}
													prefix={'%'}
													placeholder="0"
												/>
											</Form.Item>
										</>
									</CustomCollapse>
									<CustomCollapse
										isOpen={
											commissionActiveKeys.includes(
												FeeType.MAINTENANCE,
											) &&
											activeKeys.includes(
												FeeType.MAINTENANCE,
											)
										}
										title="Rebate Mensalidade"
										onToggle={() => {
											handleToggleCommissionToggle(
												FeeType.MAINTENANCE,
											);
										}}
									>
										<>
											<Form.Item
												name="commissionMaintenanceAmount"
												label="Porcentagem de rebate"
												style={{ width: '100%' }}
												rules={
													commissionActiveKeys.includes(
														FeeType.MAINTENANCE,
													)
														? [
																{
																	required:
																		true,
																},
																fieldGreaterThanZeroRule,
															]
														: []
												}
											>
												<InputNumber
													min={0}
													max={100}
													changeOnWheel={false}
													decimalSeparator=","
													style={{
														width: '100%',
													}}
													prefix={'%'}
													placeholder="0"
												/>
											</Form.Item>
										</>
									</CustomCollapse>
								</>
							)}
						</Form.Item>
					</>
				),
			},
		],
		[form, feesSettings, feesType, activeKeys, commissionActiveKeys],
	);

	return (
		<>
			{contextHolder}
			<Modal
				isOpen={isOpen}
				title="Configurações da conta"
				width={600}
				height={400}
				onClose={handleClose}
			>
				<FeetSettingsModalWrapper>
					<Form<IFeeSettingsModalFormValues>
						form={form}
						layout="vertical"
						onFinish={handleFeeSettings}
					>
						<TextL style={{ marginBottom: '1rem' }}>Cobrança</TextL>
						<Form.Item
							name="feeMode"
							label="Modo de cobrança"
							style={{ width: '100%' }}
						>
							<Select
								options={[
									{
										label: 'Débito em conta',
										value: AccountFeeMode.DIRECT_DEBIT,
									},
								]}
								disabled
							/>
						</Form.Item>

						<div style={{ marginTop: '2rem' }}>
							<CardConfig
								items={feeFormItems}
								style={{ marginBottom: '1rem' }}
								handleCollapse={keys => {
									setActiveKeys(keys as string[]);
								}}
								defaultActiveKey={activeKeys}
							/>
						</div>
					</Form>
				</FeetSettingsModalWrapper>
				<Flex>
					<ButtonPrimary
						variant="secondary"
						style={{
							width: '100%',
							marginTop: '0.5rem',
							padding: '1.3rem',
							border: 0,
							justifyContent: 'center',
						}}
						onClick={handleClose}
					>
						<TextS color={theme.primary}>Cancelar</TextS>
					</ButtonPrimary>

					<ButtonPrimary
						loading={isLoading || loading}
						disabled={isLoading || loading}
						onClick={() => {
							if (
								activeKeys.includes(COMMISSION_KEY) &&
								commissionActiveKeys.length === 0
							) {
								api.error({
									message:
										'É necessário preencher ao menos uma configuração de rebate',
								});
								return;
							}

							form.submit();
						}}
						style={{
							width: '100%',
							marginTop: '0.5rem',
							padding: '1.2rem',
							justifyContent: 'center',
						}}
					>
						<TextS color={theme.white}>
							{isEdit
								? 'Salvar alterações'
								: typeof feesSettings !== 'undefined'
									? 'Salvar'
									: 'Aprovar abertura de conta'}
						</TextS>
					</ButtonPrimary>
				</Flex>
			</Modal>

			<ConfirmationModal
				isOpen={createDestinationConfirmationModalOpen}
				title={'Criar conta beneficiária'}
				confirmText={'Confirmar'}
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					handleCreateAccountDestination(true);
					setCreateDestinationConfirmationModalOpen(false);
				}}
				onCancel={() =>
					setCreateDestinationConfirmationModalOpen(false)
				}
				onClose={() => setCreateDestinationConfirmationModalOpen(false)}
			>
				<TextL>
					Você alterou a conta beneficiária tem certeza que deseja
					adicionar essa conta beneficiária?
				</TextL>
			</ConfirmationModal>
		</>
	);
};

export default FeeSettingsModalComission;
