import {
	Checkbox,
	Col,
	Row,
	notification,
	type TableProps,
	Tooltip,
} from 'antd';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import EscrowApprovalsFilters, {
	postingsStatuses,
} from './EscrowApprovalsFilters';
import { TextM, TextS } from 'components/Text';
import { PostingsService } from 'modules/escrow/services';
import { Table } from 'components/Table';
import { FilterButton } from 'components/FilterButton';
import { LuSearch } from 'react-icons/lu';
import { useTheme } from 'styled-components';
import { RiThumbUpLine } from 'react-icons/ri';
import { RiThumbDownLine } from 'react-icons/ri';
import { FilterCol, TableWrapper } from './styles';
import { ButtonPrimary } from 'components/ButtonPrimary';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatCurrency, normalizeCpfCnpj } from 'helpers/normalizers';
import dayjs from 'dayjs';
import {
	Posting,
	PostingStatus,
	PostingStatusMap,
	PostingType,
} from 'types/Posting';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import TablePopover from './TablePopover';
import { PaginationInfo } from 'types/List';
import FiltersDrawer, { SearchFilters } from './FiltersDrawer';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AbilityContext } from 'modules/core/context/AbilityContext';
import { subject } from '@casl/ability';
import { PostingApproval } from 'modules/escrow/components/PostingApproval';
import { PostingTypeDescription } from 'constants/posting';
import { PostingReject } from 'modules/escrow/components/PostingReject';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { AiOutlineStop } from 'react-icons/ai';

const EscrowApprovals = () => {
	const theme = useTheme();
	const ability = useContext(AbilityContext);
	const [api, contextHolder] = notification.useNotification();
	const { person: company } = useCurrentProfile();
	const [paginationInfo, updatePaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [selectedPostingsToAction, setSelectedPostingsToAction] = useState<
		Posting[]
	>([]);
	const [selectedPostings, setSelectedPostings] = useState<Posting[]>([]);
	const [selectedStatus, setSelectedStatus] = useState<PostingStatus>(
		postingsStatuses[0].value,
	);
	const [approvalModalOpen, setApprovalModalOpen] = useState(false);
	const [rejectModalOpen, setRejectModalOpen] = useState(false);
	const [fieltersDrawerOpen, setFiltersDrawerOpen] = useState(false);
	const [searchFilters, setSearchFilters] = useState<SearchFilters>(
		{} as SearchFilters,
	);

	const { data, isLoading, refetch } = useQuery({
		queryKey: ['listPostings'],
		queryFn: () =>
			PostingsService.listPostings({
				status: selectedStatus,
				page: paginationInfo.currentPage,
				limit: paginationInfo.pageSize,
				...(searchFilters.taxpayer_id && {
					taxpayer_id: searchFilters.taxpayer_id,
				}),
				...(searchFilters.name && { name: searchFilters.name }),
				...(searchFilters.account && {
					account: searchFilters.account,
				}),
			}),
		placeholderData: keepPreviousData,
		staleTime: 5000,
	});

	const handleToggleCheckbox = useCallback(
		(event: CheckboxChangeEvent, posting?: Posting, all?: boolean) => {
			if (event.target.checked && all) {
				setSelectedPostings(
					(data?.content || []).filter(posting =>
						ability.can(
							'REVIEW',
							subject('POSTING', {
								accountId: posting.account.id,
							}),
						),
					),
				);
				return;
			}

			if (!event.target.checked && all) {
				setSelectedPostings([]);
				return;
			}

			if (!posting) {
				return;
			}

			if (event.target.checked) {
				setSelectedPostings([...selectedPostings, posting]);
				return;
			}

			setSelectedPostings(selectedPostings.filter(id => id !== posting));
		},
		[ability, data, selectedPostings],
	);

	const handleApprovalPosting = useCallback(() => {
		if (selectedPostings.length > 0) {
			setSelectedPostingsToAction(selectedPostings);
			setApprovalModalOpen(true);
		}
	}, [selectedPostings]);

	const handleRejectPosting = useCallback(() => {
		if (selectedPostings.length > 0) {
			setSelectedPostingsToAction(selectedPostings);
			setRejectModalOpen(true);
		}
	}, [selectedPostings]);

	const clearFilters = () => {
		setSelectedStatus(postingsStatuses[0].value);
		updatePaginationInfo({
			currentPage: 1,
			pageSize: 10,
		});
		setSelectedPostings([]);
		setSelectedPostingsToAction([]);
		setSearchFilters({} as SearchFilters);
	};

	useEffect(() => {
		refetch();
	}, [selectedStatus, paginationInfo, searchFilters, refetch]);

	const columns: ColumnsType<Posting> = [
		...(selectedStatus === postingsStatuses[0].value
			? [
					{
						title: () => (
							<Checkbox
								checked={
									selectedPostings.length ===
										data?.content.length &&
									data?.content.length > 0
								}
								onChange={event =>
									handleToggleCheckbox(event, undefined, true)
								}
							/>
						),
						render: (posting: Posting) => (
							<Checkbox
								checked={selectedPostings.includes(posting)}
								disabled={ability.cannot(
									'REVIEW',
									subject('POSTING', {
										accountId: posting.account.id,
									}),
								)}
								onChange={event =>
									handleToggleCheckbox(event, posting, false)
								}
							/>
						),
						width: '4%',
					},
				]
			: []),
		{
			title: 'Origem',
			render: (posting: Posting) => (
				<div>
					<TextM weight="bold">{`${posting.account.person?.name}`}</TextM>
					<TextS weight="normal" color={theme.textSecondary}>
						CPF/CNPJ:{' '}
						{normalizeCpfCnpj(posting.account.person?.taxpayer_id)}
					</TextS>
					<TextS weight="normal" color={theme.textSecondary}>
						Conta: {posting.account.account}
					</TextS>
				</div>
			),
			width: '15%',
		},
		{
			title: 'Destino',
			render: (posting: Posting) => {
				if (posting.type === PostingType.BARCODE) {
					const beneficiary =
						posting.billpayment_beneficiary?.split(' - ');
					return (
						<div>
							<TextM weight="bold">{`${beneficiary?.[0]}`}</TextM>
							<TextS weight="normal" color={theme.textSecondary}>
								CNPJ: {beneficiary?.[1] || ''}
							</TextS>
						</div>
					);
				}
				return (
					<div>
						{/* FIX POR CONTA DO DELETE DO REGISTRO DE ACCOUNT_DESTINATION */}
						<TextM weight="bold">
							{posting.account_destination
								? `${posting.account_destination?.name}`
								: '-'}
						</TextM>
						<TextS weight="normal" color={theme.textSecondary}>
							CPF/CNPJ:{' '}
							{posting.account_destination
								? normalizeCpfCnpj(
										posting.account_destination
											?.taxpayer_id,
									)
								: '-'}
						</TextS>
						<TextS weight="normal" color={theme.textSecondary}>
							Conta:{' '}
							{posting.account_destination
								? posting.account_destination.account
								: '-'}
						</TextS>
					</div>
				);
			},
			width: '15%',
		},
		{
			title: 'Solicitado em',
			render: (posting: Posting) => (
				<div>
					<TextM weight="bold">
						{dayjs(posting.created_at).format('DD/MM/YYYY')}
					</TextM>
					<TextM color={theme.textSecondary} weight="normal">
						às {dayjs(posting.created_at).format('HH:mm')}
					</TextM>
				</div>
			),
			width: '10%',
		},
		{
			title: 'Data de transferência',
			render: (posting: Posting) => (
				<div>
					<TextM weight="bold">
						{dayjs(posting.scheduled_date).format('DD/MM/YYYY')}
					</TextM>
				</div>
			),
			width: '10%',
		},
		{
			title: 'Valor',
			render: (posting: Posting) => (
				<TextM color={theme.textSecondary}>
					{formatCurrency(posting.amount)}
				</TextM>
			),
			width: '10%',
		},
		{
			title: 'Tipo',
			render: (posting: Posting) => (
				<TextM color={theme.textSecondary}>
					{PostingTypeDescription[posting.type]}
				</TextM>
			),
			width: '8%',
		},
		{
			title: 'Status',
			width: '12%',
			render: (posting: Posting) => {
				if (posting.status === PostingStatus.ERROR) {
					return (
						<Tooltip title={posting.error}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px',
								}}
							>
								<TextM weight="normal">
									{PostingStatusMap[posting.status]}
								</TextM>
								<IoMdInformationCircleOutline size={16} />
							</div>
						</Tooltip>
					);
				}
				return (
					<TextM color={theme.textSecondary}>
						{PostingStatusMap[posting.status]}
					</TextM>
				);
			},
		},
		...(selectedStatus !== PostingStatus.PENDING_APPROVAL
			? [
					{
						title: `${
							selectedStatus === PostingStatus.SCHEDULED
								? 'Aprovada'
								: 'Rejeitada'
						} em`,
						render: (posting: Posting) => (
							<div>
								<TextM weight="bold">
									{posting.review?.reviewed_at
										? dayjs(
												posting.review?.reviewed_at,
											).format('DD/MM/YYYY')
										: '-'}
								</TextM>
								<TextM
									weight="normal"
									color={theme.textSecondary}
								>
									às{' '}
									{posting.review?.reviewed_at
										? dayjs(
												posting.review?.reviewed_at,
											).format('HH:mm')
										: '-'}
								</TextM>
							</div>
						),
						width: '4%',
					},
				]
			: []),
		{
			title: '',
			render: (posting: Posting) => {
				if (
					posting.status !== PostingStatus.PENDING_APPROVAL ||
					ability.cannot(
						'REVIEW',
						subject('POSTING', { accountId: posting.account.id }),
					)
				)
					return null;

				return (
					<TablePopover
						status={selectedStatus}
						posting={posting}
						handleClick={(type, posting) => {
							if (type === 'approve') {
								setSelectedPostingsToAction([posting]);
								setApprovalModalOpen(true);
								return;
							}

							setSelectedPostingsToAction([posting]);
							setRejectModalOpen(true);
						}}
					/>
				);
			},
			width: '5%',
		},
	];

	return (
		<>
			{contextHolder}
			<Row justify="space-between" style={{ marginBottom: '1rem' }}>
				<Col>
					<FilterCol>
						<Row style={{ gap: '1rem' }}>
							<FilterCol>
								<EscrowApprovalsFilters
									value={selectedStatus}
									onSelect={value => setSelectedStatus(value)}
								/>
							</FilterCol>
							<FilterCol>
								<FilterButton
									icon={<LuSearch size={18} />}
									onClick={() => setFiltersDrawerOpen(true)}
								>
									<TextS color={theme.white}>
										Busca avançada
									</TextS>
								</FilterButton>
							</FilterCol>
							<FilterCol>
								<FilterButton
									onClick={clearFilters}
									variation="secondary"
									icon={
										<AiOutlineStop
											size={18}
											color={theme.white}
										/>
									}
									disabled={
										!Object.keys(searchFilters).length
									}
								>
									<TextS>Limpar filtros</TextS>
								</FilterButton>
							</FilterCol>
						</Row>
					</FilterCol>
				</Col>
				{selectedStatus === PostingStatus.PENDING_APPROVAL && (
					<FilterCol>
						<Row style={{ gap: '1rem' }}>
							<Col>
								<ButtonPrimary
									onClick={handleRejectPosting}
									variant="secondary"
									disabled={
										selectedPostings.length === 0 ||
										!company
									}
								>
									<Row
										style={{
											alignItems: 'center',
											gap: '0.4rem',
											padding: '0 1rem',
										}}
									>
										<RiThumbDownLine
											size={'1rem'}
											color={theme.primary}
										/>
										<TextS color={theme.primary}>
											Rejeitar
										</TextS>
									</Row>
								</ButtonPrimary>
							</Col>
							<Col>
								<ButtonPrimary
									onClick={handleApprovalPosting}
									variant="secondary"
									disabled={
										selectedPostings.length === 0 ||
										!company
									}
								>
									<Row
										style={{
											alignItems: 'center',
											gap: '0.4rem',
											padding: '0 1rem',
										}}
									>
										<TextS color={theme.primary}>
											Aprovar
										</TextS>
										<RiThumbUpLine
											size={'1rem'}
											color={theme.primary}
										/>
									</Row>
								</ButtonPrimary>
							</Col>
						</Row>
					</FilterCol>
				)}
			</Row>

			<TableWrapper>
				<Table
					columns={columns}
					rowKey={record => record.id}
					dataSource={data?.content}
					loading={isLoading}
					rowClassName={record => {
						if (
							selectedPostings.find(
								posting => posting.id === record.id,
							)
						) {
							return 'selected-row';
						}
						return '';
					}}
					pagination={{
						total: data?.total_elements || 0,
						showTotal(total) {
							const currentSize =
								paginationInfo.currentPage *
								paginationInfo.pageSize;
							return `${currentSize > total ? total : currentSize} de ${total}`;
						},
						pageSizeOptions: ['10', '30', '60', '90'],
						showSizeChanger: true,
						pageSize: paginationInfo.pageSize,
						current: paginationInfo.currentPage,
						onChange(page, pageSize) {
							updatePaginationInfo({
								currentPage: page,
								pageSize,
							});
						},
					}}
				/>
			</TableWrapper>
			<PostingApproval
				postingData={selectedPostingsToAction}
				isOpen={approvalModalOpen}
				onClose={() => {
					setApprovalModalOpen(false);
					setSelectedPostingsToAction([]);
					setSelectedPostings([]);
				}}
				onConfirm={() => {
					setApprovalModalOpen(false);
					setSelectedPostingsToAction([]);
					setSelectedPostings([]);
				}}
				onError={e => {
					api.error({
						description: e.data.message,
						message: 'Erro ao aprovar movimentações',
					});
					setSelectedPostings([]);
					setSelectedPostingsToAction([]);
				}}
			/>
			<PostingReject
				postingData={selectedPostingsToAction}
				isOpen={rejectModalOpen}
				onClose={() => {
					setRejectModalOpen(false);
					setSelectedPostingsToAction([]);
				}}
				onConfirm={() => {
					setRejectModalOpen(false);
					setSelectedPostingsToAction([]);
				}}
				onError={e =>
					api.error({
						description: e.data.message,
						message: 'Erro ao aprovar movimentações',
					})
				}
			/>
			<FiltersDrawer
				isOpen={fieltersDrawerOpen}
				handleFilter={filters => {
					setSearchFilters(filters);
				}}
				onClose={() => setFiltersDrawerOpen(false)}
				filters={searchFilters}
			/>
		</>
	);
};

export default EscrowApprovals;
