import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import DebitCollectionsPage from './DebitCollections';
import { Charges } from './pages/Charges';
import { ChargesNewPage } from './pages/ChargesNew';

const DebitCollectionsModule = () => {
	return (
		<Routes>
			<Route path=":tab" element={<DebitCollectionsPage />} />
			<Route path="wallet/:id/new" element={<ChargesNewPage />} />
			<Route path="wallet/:id/:tab" element={<Charges />} />
			<Route index element={<Navigate to="collections" replace />} />
		</Routes>
	);
};

export default DebitCollectionsModule;
