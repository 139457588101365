import React, { useContext, useMemo } from 'react';
import { Spin, type MenuProps } from 'antd';
import { UserMenuDropdown } from './styles';
import { useAuthContext } from 'modules/core/context/AuthContext';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { queryClient } from 'network/query';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { PersonSecondaryIcon } from 'components/Icons/Person';
import { LogoutIcon } from 'components/Icons/Logout';
import { FileIcon } from 'components/Icons/File';

export function UserMenu() {
	const { logout } = useAuthContext();
	const theme = useTheme();
	const navigate = useNavigate();
	const { resetSelectedUserAccess, userProfile, isProfileLoading } =
		useContext(ProfileContext);

	const name = userProfile?.full_name ?? '';

	const menu = useMemo<MenuProps['items']>(
		() => [
			{
				label: 'Meu perfil',
				icon: (
					<div style={{ marginRight: '10px' }}>
						<PersonSecondaryIcon
							color={theme.textGray}
							size="1rem"
						/>
					</div>
				),
				key: '0',
				onClick: () => navigate('/my-profile'),
			},
			{
				type: 'divider',
			},
			{
				label: 'Termos e condições',
				icon: (
					<div style={{ marginRight: '10px' }}>
						<FileIcon color={theme.textGray} size="1rem" />
					</div>
				),
				key: '1',
				onClick: () => navigate('/read-terms'),
			},
			{
				type: 'divider',
			},
			{
				label: <span style={{ color: theme.dangerText }}>Sair</span>,
				icon: (
					<div style={{ marginRight: '10px' }}>
						<LogoutIcon color={theme.danger} size="1rem" />
					</div>
				),
				key: '2',
				onClick: () => {
					logout().then(() => {
						queryClient.removeQueries();
						resetSelectedUserAccess();
					});
				},
			},
		],
		[logout, navigate, resetSelectedUserAccess, theme],
	);

	return (
		<>
			{isProfileLoading ? (
				<Spin />
			) : (
				<UserMenuDropdown menu={{ items: menu }} trigger={['click']}>
					<div>{name?.[0] ?? '-'}</div>
				</UserMenuDropdown>
			)}
		</>
	);
}

export default UserMenu;
