import { Account, AccountFeeType } from 'types/Account';
import {
	PlaceholderDarkInput,
	PostingCreationForm,
	StepsWrapper,
} from './styles';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { DatePicker } from 'components/DatePicker';
import dayjs from 'dayjs';
import { CurrencyInput } from 'components/CurrencyInput';
import { holidaysRule, minTodayDateRule, weekendRule } from 'helpers/rules';
import { TextM, TextS } from 'components/Text';
import { PaymentType, PostingClientFinality } from 'types/Posting';
import {
	PaymentTypeDescription,
	PostingClientFinalityDescription,
} from 'constants/posting';
import { useTheme } from 'styled-components';
import { formatCurrency } from 'helpers/normalizers';
import { DestinationCardInfo } from '../DestinationCardInfo';
import { ForwardButton } from './components/ForwardButton';
import { Destination } from 'types/Destination';
import { PostingDtoForm } from './PostingCreation';

interface IPostingCreationDataFormProps {
	destination: Destination;
	account: Account;
	onNext: (posting: PostingDtoForm) => void;
	onBack: () => void;
}
const PostingCreationDataForm = ({
	destination,
	account,
	onNext,
	onBack,
}: IPostingCreationDataFormProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);
	const [postingType, setPostingType] = useState<PaymentType>(
		PaymentType.PIX,
	);

	useEffect(() => {
		form.setFieldValue(
			'type',
			account?.fees?.default_payment_type ?? PaymentType.PIX,
		);
		setPostingType(account?.fees?.default_payment_type ?? PaymentType.PIX);
	}, [account]);

	const scheduledDateRules = useMemo(() => {
		const rules = [minTodayDateRule('scheduled_date')];
		if (postingType === PaymentType.TED) {
			rules.push(holidaysRule, weekendRule);
		}
		return rules;
	}, [postingType]);

	const formattedFee =
		account.fees?.withdrawal?.amount_type === AccountFeeType.PERCENT
			? `${account.fees?.withdrawal?.amount}%`
			: formatCurrency(account.fees?.withdrawal?.amount ?? 0);
	const formattedTed =
		account.fees?.ted_transfer?.amount_type === AccountFeeType.PERCENT
			? `${account.fees?.ted_transfer?.amount}%`
			: formatCurrency(account.fees?.ted_transfer?.amount ?? 0);

	return (
		<StepsWrapper>
			<div>
				<PostingCreationForm
					form={form}
					style={{ width: '100%' }}
					layout="vertical"
					initialValues={{
						type:
							account?.fees?.default_payment_type ??
							PaymentType.PIX,
					}}
					requiredMark={false}
					onValuesChange={changedValues => {
						if (
							Object.keys(changedValues).includes(
								'scheduled_date',
							)
						) {
							form.setFields([
								{
									name: 'amount',
									errors: [],
								},
							]);
						}
					}}
					onFieldsChange={(f, allFields) => {
						const errors = allFields.filter(
							field =>
								(!field.touched &&
									!field.name?.includes('scheduled_date') &&
									!field.name?.includes('type') &&
									!field.name?.includes('destination_id') &&
									!field.name?.includes('clientFinality')) ||
								(field.errors && field.errors.length > 0),
						);
						setFormValid(errors.length === 0);
					}}
					onFinish={values => {
						if (values.amount <= 0) {
							form.setFields([
								{
									name: 'amount',
									errors: ['Valor deve ser maior que 0'],
								},
							]);
							return;
						}
						const scheduledDate = values.scheduled_date ?? dayjs();
						onNext({
							amount: values.amount,
							scheduled_date: scheduledDate.format('YYYY-MM-DD'),
							type: values.type,
							clientFinality: values.clientFinality,
						});
					}}
				>
					<Row style={{ width: '100%' }}>
						<DestinationCardInfo data={destination} type="info" />
					</Row>

					<Row>
						<TextM
							style={{
								fontSize: '1.2rem',
							}}
						>
							Dados da transferência:
						</TextM>
					</Row>

					<FormItem
						shouldUpdate={(prevValues, currentValues) =>
							prevValues.taxpayer_id !== currentValues.taxpayer_id
						}
					>
						{() => {
							return (
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row style={{ marginTop: '1rem' }}>
											<Col span={24}>
												<PlaceholderDarkInput
													name="scheduled_date"
													label={
														<TextS>
															Data da
															transferência
														</TextS>
													}
													rules={scheduledDateRules}
												>
													<DatePicker
														placeholder={`${dayjs().format('DD/MM/YYYY')} (hoje)`}
														disableHolidays={
															postingType ===
															PaymentType.TED
														}
														disablePastDays={
															postingType ===
															PaymentType.TED
														}
														format="DD/MM/YYYY"
													/>
												</PlaceholderDarkInput>
											</Col>
										</Row>
										<Row style={{ marginTop: '1rem' }}>
											<Col span={24}>
												<Form.Item
													name="amount"
													label={<TextS>Valor</TextS>}
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
													]}
												>
													<CurrencyInput placeholder="0,00" />
												</Form.Item>
											</Col>
										</Row>

										<Row style={{ marginTop: '1rem' }}>
											<Col span={24}>
												<Form.Item
													name="type"
													label={
														<TextS>
															Tipo de
															transferência
														</TextS>
													}
													rules={[
														{
															required: true,
															message:
																'Campo obrigatório',
														},
													]}
												>
													<Select
														options={Object.keys(
															PaymentTypeDescription,
														).map(option => ({
															label: PaymentTypeDescription[
																option as PaymentType
															],
															value: option,
														}))}
														onChange={value => {
															form.setFieldsValue(
																{
																	type: value,
																},
															);
															if (
																value ===
																PaymentType.PIX
															) {
																form.resetFields(
																	[
																		'clientFinality',
																	],
																);
															}
															setPostingType(
																value as PaymentType,
															);
															form.setFieldValue(
																'clientFinality',
																PostingClientFinality.ACCOUNT_CREDIT_PAYMENT,
															);
														}}
													/>
												</Form.Item>
												<TextS
													color={theme.textGray}
													style={{ marginTop: -5 }}
													weight="normal"
												>
													{postingType ===
													PaymentType.PIX
														? `Taxa para transferência Pix: ${formattedFee}`
														: `Taxa para transferência TED: ${formattedTed}`}
												</TextS>
											</Col>
										</Row>
										{postingType === PaymentType.TED && (
											<Row style={{ marginTop: '1rem' }}>
												<Col span={24}>
													<Form.Item
														name="clientFinality"
														label={
															<TextS>
																Finalidade
															</TextS>
														}
														rules={[
															{
																required: true,
																message:
																	'Campo obrigatório',
															},
														]}
													>
														<Select
															options={Object.keys(
																PostingClientFinalityDescription,
															).map(option => ({
																label: PostingClientFinalityDescription[
																	option as PostingClientFinality
																],
																value: option,
															}))}
														/>
													</Form.Item>
												</Col>
											</Row>
										)}
									</Col>
								</Row>
							);
						}}
					</FormItem>
				</PostingCreationForm>
			</div>
			<div>
				<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
					<Col span={24}>
						<ForwardButton
							disabled={!formValid}
							text="Solicitar transferência"
							onClick={form.submit}
						/>
					</Col>
				</Row>
				<Row
					style={{
						marginTop: '1rem',
					}}
				>
					<Button
						type="default"
						onClick={() => {
							onBack();
						}}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							border: 'none',
							boxShadow: 'none',
							color: theme.primary,
							width: '100%',
						}}
					>
						<TextS
							style={{
								color: theme.primary,
							}}
						>
							Voltar
						</TextS>
					</Button>
				</Row>
			</div>
		</StepsWrapper>
	);
};

export default PostingCreationDataForm;
