import { Button } from 'antd';
import styled from 'styled-components';

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const DescriptionItem = styled.p<{ color?: string }>`
	padding: 0;
	margin: 0 0;
	font-size: 1.2rem;
	display: inline;
	vertical-align: sub;
	color: ${p => p.color || p.theme.text};
`;

export const DescriptionsWrapper = styled.div`
	.ant-descriptions-item-label {
		color: ${({ theme }) => theme.secondary} !important;
		font-size: 18px;
		font-weight: 500;
	}

	.ant-descriptions-item-container {
		p {
			color: ${({ theme }) => theme.textSecondary} !important;
			font-size: 14px;
		}
	}
`;

export const ButtonSecondary = styled(Button)`
	color: ${({ theme }) => theme.primary};

	svg {
		fill: ${({ theme }) => theme.primary};
	}
`;

export const SignerCollapseHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 32px;
`;

export const FinancialInstitutionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #eaeaea;
	padding: 1rem;
	border-radius: 10px;
`;
