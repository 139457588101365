import { TabsSelector, PageWrapper, HeaderArea } from './styles';
import styled, { useTheme } from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextS } from 'components/Text';
import { TableProps } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { EscrowRoutes } from 'modules/escrow/constants/routes';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import AccountInfo from './AccountInfo';
import AccountInfoHeader from './AccountInfoHeader';
import { GetProp, TabsProps, notification } from 'antd';
import ButtonDrawer from './ButtonDrawer';
import { useParams } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { AccountsService } from 'modules/escrow/services';

import { SplashScreen } from 'components/SplashScreen';
import ScheduledPostingsContent from './ScheduledPostingsContent';
import StatementContent from './StatementContent';
import { ApiError } from 'types/ApiError';
import {
	getAccountRouteTypeIcon,
	getAccountRouteTypeName,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';

export interface TableParams {
	pagination?: TablePaginationConfig;
	sortField?: string;
	sortOrder?: string;
	filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

type TablePaginationConfig = Exclude<
	GetProp<TableProps, 'pagination'>,
	boolean
>;

const tabs: TabsProps['items'] = [
	{
		key: '1',
		label: 'Todos os lançamentos',
	},
	{
		key: '2',
		label: 'Lançamentos futuros',
	},
	// {
	//   key: "3",
	//   label: "Tarifas pendentes",
	// },
];

export const BLOCKED_ACCOUNT_ERROR =
	'Operação não permitida. Conta está bloqueada';

export const movementFilters = {
	CREDIT: {
		label: 'Entradas',
		value: 'credit',
	},
	DEBIT: {
		label: 'Saídas',
		value: 'debit',
	},
	ALL: {
		label: 'Entradas e saídas',
		value: 'all',
	},
};

export const defaultTableConfig = {
	pagination: {
		// showSizeChanger: true,
		current: 1,
		pageSize: 30,
	},
};

export type handleMovementFilter = {
	label: string;
	value: string;
};

export type DateRange = {
	dateFrom: Dayjs;
	dateTo: Dayjs;
};

export const DrawerButton = ({
	onClick,
	children,
	isSelected,
}: {
	onClick: () => void;
	children: React.ReactNode;
	isSelected?: boolean;
}) => {
	const theme = useTheme();
	return (
		<ButtonDrawer onClick={onClick} isSelected={isSelected}>
			<TextS
				style={{
					color: isSelected ? theme.white : theme.primary,
					fontWeight: 'bold',
				}}
			>
				{children}
			</TextS>
		</ButtonDrawer>
	);
};

const StickyHeader = styled.div<{ isVisible: boolean }>`
	position: ${({ isVisible }) => (isVisible ? 'relative' : 'sticky')};
	top: 0;
	z-index: 10;
	background-color: ${({ theme }) => theme.white};
	border-bottom: ${({ isVisible, theme }) =>
		!isVisible ? `1px solid ${theme.border}` : 'none'};
`;

const EscrowStatementPage = () => {
	const [api, contextHolder] = notification.useNotification();
	const isEscrowRoute = useIsEscrowAccountRoute();
	const { id: entityId } = useParams<{ id: string }>();
	const { setBreadcrumb } = useBreadcrumb();

	const [activeTab, setActiveTab] = useState('1');
	const [isVisible, setIsVisible] = useState(true);
	const scrollRef = useRef<HTMLDivElement>(null);

	const redirectAccount = isEscrowRoute
		? EscrowRoutes.ESCROW_BASE
		: EscrowRoutes.ACCOUNT_BASE;
	const title = (
		<BreadcrumbContainer>
			{getAccountRouteTypeIcon()}
			<span>{getAccountRouteTypeName()}</span>
		</BreadcrumbContainer>
	);

	useEffect(() => {
		setBreadcrumb([
			{
				href: redirectAccount,
				title: title,
			},
			{
				title: 'Extrato',
			},
		]);
	}, []);

	const onChangeTab = useCallback((key: string) => {
		setActiveTab(key);
	}, []);

	const {
		data: balanceData,
		isLoading: balanceLoading,
		error: balanceError,
	} = useQuery<{ amount: number; blocked_amount: number }, ApiError>({
		queryKey: ['accountBalance', entityId],
		queryFn: () => AccountsService.getBalance(entityId || ''),
		placeholderData: keepPreviousData,
		staleTime: 5000,
	});

	const {
		data: accountData,
		isLoading: isAccountLoading,
		error: accountError,
	} = useQuery({
		queryKey: ['accountData', entityId],
		queryFn: () => AccountsService.getAccount(entityId!),
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
		staleTime: 5000,
		enabled: typeof entityId === 'string',
	});

	useEffect(() => {
		if (
			balanceError &&
			balanceError?.data?.message !== BLOCKED_ACCOUNT_ERROR
		) {
			api.error({
				message: 'Erro ao buscar saldo',
				description: 'Ocorreu um erro ao buscar o saldo da conta',
			});
		}
	}, [balanceError, api]);

	useEffect(() => {
		if (accountError) {
			api.error({
				message: 'Erro ao buscar dados da conta',
				description: 'Ocorreu um erro ao buscar os dados da conta',
			});
		}
	}, [accountError, api]);

	useEffect(() => {
		if (!scrollRef.current) {
			return;
		}
		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						setIsVisible(true);
					} else {
						setIsVisible(false);
					}
				});
			},
			{ threshold: 0.1 },
		);

		observer.observe(scrollRef.current);

		return () => {
			if (scrollRef.current) {
				observer.unobserve(scrollRef.current);
			}
		};
	}, [scrollRef?.current]);

	if (isAccountLoading) {
		return <SplashScreen />;
	}

	if (!accountData) {
		return null;
	}

	return (
		<div>
			{contextHolder}
			<PageWrapper>
				<StickyHeader isVisible={isVisible}>
					<AccountInfoHeader
						balance={balanceData?.amount}
						isLoading={balanceLoading}
						blockedAmount={balanceData?.blocked_amount}
						accountBlocked={
							balanceError?.data?.message ===
							BLOCKED_ACCOUNT_ERROR
						}
					/>
				</StickyHeader>
				<div
					style={{
						width: '100%',
						height: '10px',
					}}
					ref={scrollRef}
				/>
				<HeaderArea>
					<AccountInfo accountContent={accountData} />
				</HeaderArea>
				<PageWrapper>
					<TabsSelector
						size="large"
						defaultActiveKey="1"
						activeKey={activeTab}
						items={tabs}
						onChange={onChangeTab}
					/>
				</PageWrapper>
				{activeTab === '1' && <StatementContent />}
				{activeTab === '2' && <ScheduledPostingsContent />}
			</PageWrapper>
		</div>
	);
};

export default EscrowStatementPage;
