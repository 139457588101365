import axios, { AxiosInstance } from 'axios';
import { NetworkInstanceType, NetworkInstancesType } from './types';
import environment from 'modules/core/constants/environment';

const NETWORK_INSTANCES: NetworkInstancesType = {};

const createNetworkInstance = (): NetworkInstanceType => {
	const controller = new AbortController();

	return {
		controller,
		instance: axios.create({
			signal: controller.signal,
			baseURL: environment.BASE_URL,
		}),
	};
};

export const getInstance = (pathname: string): AxiosInstance => {
	if (
		NETWORK_INSTANCES[pathname] &&
		!NETWORK_INSTANCES[pathname].controller.signal.aborted
	) {
		return NETWORK_INSTANCES[pathname].instance;
	}

	const networkInstance = createNetworkInstance();
	NETWORK_INSTANCES[pathname] = networkInstance;
	return networkInstance.instance;
};

export const cancelPreviousPageRequests = (previousPath: string) => {
	if (
		NETWORK_INSTANCES[previousPath] &&
		!NETWORK_INSTANCES[previousPath].controller.signal.aborted
	) {
		NETWORK_INSTANCES[previousPath].controller.abort();
	}
};
