import { Button, Form } from 'antd';
import { TextM, TextS } from 'components/Text';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { ForgotPasswordFormType } from './types';

export const ForgotPasswordContainer = styled.div`
	width: 100%;
	max-width: 25rem;
	display: flex;
	flex-direction: column;
	padding: 2rem 1rem 1rem;
	align-items: center;
	box-shadow: 0 0 0.7rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 0.4rem;
	border: 1px solid ${p => p.theme.border};
	background-color: ${p => p.theme.white};
`;

export const ForgotPasswordText = styled(TextS)`
	margin-top: 0.4rem;
	color: ${p => p.theme.textSecondary};
`;

export const ForgotPasswordSuccessContainer = styled.div``;

export const ForgotPasswordSuccessText = styled(TextM)`
	margin: 1.5rem 0;
	text-align: center;
	color: ${p => p.theme.textSecondary};
`;

export const ForgotPasswordFormContainer = styled(Form<ForgotPasswordFormType>)`
	padding: 1rem 0;
	width: 100%;
	position: relative;
`;

export const ForgotPasswordFormSubmit = styled(Button)`
	margin: 0 auto;
	width: 100%;
	margin-top: 1rem;
`;

export const ForgotPasswordBack = styled(RouterLink)`
	margin-top: 0.5rem;
	display: block;
	text-align: center;
	color: ${p => p.theme.primary};
`;
