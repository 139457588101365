import { Button, Empty, notification, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	ContainerFluid,
	ContainerHeader,
	FinancialInstitutionContainer,
	Footer,
} from './styles';
import { PageActions } from 'components/PageActions';
import { RelatedPartType } from 'modules/escrow/components/RelatedPartsCreation/RelatedPartsCreation';
import {
	AccountOwnerPermissions,
	AccountPermissionDescription,
	AccountPermissionFromFundKey,
	AccountPermissionRestricted,
	AccountRelationshipFromFundKey,
} from 'constants/account';
import { Company, CompanyDto, CompanyFund, BusinessType } from 'types/Company';
import { useCompanyFund } from 'modules/company/hooks/useCompanyFund';
import { useCompany } from 'modules/company/hooks';
import { Heading2 } from 'components/Heading2';
import { AccountPermission } from 'types/Account';
import { isSandbox } from 'helpers/environment';
import { FullAccountRelatedPart, StepsId } from '../types';
import { TextM, TextS } from 'components/Text';
import EmptyRepresentativesList from 'components/images/emptyListA.png';
import { useTheme } from 'styled-components';
import { CollapseProps } from 'antd/lib';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { CustomSwitch } from 'components/CustomSwitch';
import { RelatedPartsCreationNaturalPerson } from 'modules/escrow/components/RelatedPartsCreationNaturalPerson';
import { useQuery } from '@tanstack/react-query';
import { PersonsService } from 'modules/company/services';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';

const permissions = Object.keys(AccountPermissionDescription)
	.map(permission => ({
		label: AccountPermissionDescription[
			permission as unknown as keyof typeof AccountPermissionDescription
		],
		value: permission,
	}))
	.filter(
		permission =>
			isSandbox() ||
			!AccountPermissionRestricted.includes(
				permission.value as AccountPermission,
			),
	);

interface IEscrowNaturalPersonCreationRelatedPartsProps {
	relatedParts: FullAccountRelatedPart[];
	person: CompanyDto;
	onNext: (relatedParts: FullAccountRelatedPart[]) => void;
	onBack: (relatedParts: FullAccountRelatedPart[]) => void;
	handleStepStatus: (stepsId: StepsId, status: boolean) => void;
}
const EscrowNaturalPersonCreationRelatedParts = ({
	relatedParts: prevRelatedParts,
	person,
	onNext,
	onBack,
	handleStepStatus,
}: IEscrowNaturalPersonCreationRelatedPartsProps) => {
	const theme = useTheme();
	const { person: personInContext, type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const { getCompanyById, loading: isCompanyLoading } = useCompany();
	const { loading: isCompanyFundLoading, getCompanyFund } = useCompanyFund({
		onError: error =>
			api.error({
				description: error.data.message,
				message:
					'Ocorreu um problema ao buscar as informações do fundo.',
			}),
	});
	const [relatedParts, updateRelatedParts] = useState<RelatedPartType[]>([]);
	const [editRelatedPart] = useState<RelatedPartType>();
	const [isRelatedPartCreationOpen, toggleIsRelatedPartCreationOpen] =
		useState(false);

	useEffect(() => {
		const celcoinRelatedPart: RelatedPartType = {
			person_id: 'celcoin_company_id',
			permission: [AccountPermission.POSTING_REVIEW],
			relationship: BusinessType.OTHERS,
			person: {} as Company,
			signer: false,
		};

		const companyRelatedPart: RelatedPartType = {
			person_id: person.id ?? 'company_owner_id',
			permission: AccountOwnerPermissions,
			relationship: BusinessType.OTHERS,
			person: person as Company,
			signer: true,
		};

		const currentPersonProfile: RelatedPartType = {
			person_id: personInContext?.id ?? '',
			permission: [AccountPermission.VIEW_ACCOUNT],
			relationship: BusinessType.OTHERS,
			signer: true,
		};

		const relatedParts = prevRelatedParts
			.filter(
				relatedPart =>
					relatedPart.person.id !== person.id &&
					relatedPart.person.id !== 'company_owner_id',
			)
			.filter(
				relatedPart => relatedPart.person.id !== personInContext?.id,
			)
			.map(relatedParts => ({
				person_id: relatedParts.person.id,
				person: relatedParts.companyDetails,
				signer: relatedParts.sign_contract,
				permission: relatedParts.permissions,
				relationship: relatedParts.person.businessType,
			}));

		const mustShowInRelatedPartList =
			type === AccessType.FINANCIAL_INSTITUTION
				? [companyRelatedPart]
				: [companyRelatedPart, currentPersonProfile];

		updateRelatedParts(
			[
				celcoinRelatedPart,
				...(person.name && person.taxpayer_id
					? mustShowInRelatedPartList
					: []),
			].concat(relatedParts),
		);
	}, [prevRelatedParts, person]);

	const {
		data: personInTheContextData,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['personData', personInContext],
		queryFn: () => PersonsService.getCompany(personInContext?.id ?? ''),
		enabled:
			!!personInContext?.id && type !== AccessType.FINANCIAL_INSTITUTION,
	});

	useEffect(() => {
		if (error) {
			api.error({
				description: error.message,
				message: 'Ocorreu um erro ao buscar os dados do contexto.',
			});

			onBack([]);
		}
	}, [error]);

	useEffect(() => {
		if (personInTheContextData) {
			updateRelatedParts(relatedParts => {
				const updatedRelatedParts = relatedParts.map(relatedPart => {
					if (relatedPart.person_id === personInTheContextData.id) {
						return {
							...relatedPart,
							person: personInTheContextData,
						};
					}
					return relatedPart;
				});
				return updatedRelatedParts;
			});
		}
	}, [personInTheContextData]);

	const checkEmptyPermissionsAndWarn = useCallback(
		(relatedParts: RelatedPartType[]) => {
			const emptyPermissions = relatedParts.filter(
				relatedPart =>
					!relatedPart.permission ||
					(relatedPart.permission &&
						relatedPart.permission.length === 0),
			);
			if (emptyPermissions.length) {
				api.error({
					description:
						'As seguintes Partes Relacionadas devem ter pelo menos 1 permissão: ' +
						emptyPermissions
							.map(emptyPermission => {
								return emptyPermission.person?.name;
							})
							.join(', '),
					message: 'Permissões não selecionadas',
				});
				return true;
			}
			return false;
		},
		[api],
	);

	const onCreateRelatedPart = useCallback(
		(relatedPart: RelatedPartType) => {
			const relatedPartsCompanyIds = relatedParts.map(rp => rp.person_id);
			if (
				relatedPart.relationship === BusinessType.FUND &&
				relatedParts.length === 2
			) {
				getCompanyFund(relatedPart.person_id).then(companyFund => {
					const companyFundRelatedParts = Object.keys(companyFund)
						.filter(
							companyFundKey =>
								companyFund[
									companyFundKey as keyof CompanyFund
								] !== null && companyFundKey !== 'fund',
						)
						.map<RelatedPartType>(companyFundKey => {
							const companyFundItem = companyFund[
								companyFundKey as keyof CompanyFund
							] as Company;
							return {
								person_id: companyFundItem.id,
								person: companyFundItem,
								permission:
									AccountPermissionFromFundKey[
										companyFundKey as keyof typeof AccountPermissionFromFundKey
									],
								relationship:
									AccountRelationshipFromFundKey[
										companyFundKey as keyof typeof AccountPermissionFromFundKey
									],
							};
						})
						.filter(
							companyFundItem =>
								!relatedPartsCompanyIds.includes(
									companyFundItem.person_id,
								),
						);

					const parsedRelatedParts = [
						...relatedParts,
						{
							...relatedPart,
							person: companyFund.fund ?? undefined,
						},
						...companyFundRelatedParts,
					];

					if (checkEmptyPermissionsAndWarn(parsedRelatedParts))
						return;
					updateRelatedParts(parsedRelatedParts);
					toggleIsRelatedPartCreationOpen(false);
				});
			} else {
				if (relatedPartsCompanyIds.includes(relatedPart.person_id)) {
					toggleIsRelatedPartCreationOpen(false);
					return;
				}
				getCompanyById(relatedPart.person_id).then(company => {
					const parsedRelatedParts = [
						...relatedParts,
						{
							...relatedPart,
							person: company as Company,
						},
					];
					if (checkEmptyPermissionsAndWarn(parsedRelatedParts))
						return;

					updateRelatedParts(parsedRelatedParts);
					toggleIsRelatedPartCreationOpen(false);
				});
			}
		},
		[
			getCompanyById,
			getCompanyFund,
			relatedParts,
			checkEmptyPermissionsAndWarn,
		],
	);

	const onRemoveRelatedPart = useCallback(
		(relatedPart: RelatedPartType, isMainPart: boolean) => {
			if (relatedPart?.person_id === 'company_owner_id' || isMainPart) {
				api.error({
					message: 'Não é possível remover essa parte relacionada',
				});
				return;
			}
			updateRelatedParts(item =>
				item.filter(
					relatedPartEl =>
						relatedPartEl.person_id !== relatedPart?.person_id,
				),
			);
		},
		[api],
	);

	const handleRelatedPartCreation = useCallback(() => {
		handleStepStatus(StepsId.REPRESENTATIVES, true);

		return relatedParts
			.map<FullAccountRelatedPart>(relatedPart => ({
				person: {
					id: relatedPart.person_id,
					businessType: relatedPart.relationship,
				},
				companyDetails: relatedPart.person,
				sign_contract: relatedPart.signer ?? false,
				permissions: relatedPart.permission,
			}))
			.filter(
				relatedPart => relatedPart.person.id !== 'celcoin_company_id',
			);
	}, [relatedParts]);

	const handleTogglePermission = useCallback(
		(relatedPart: RelatedPartType, permission: AccountPermission) => {
			updateRelatedParts(relatedParts =>
				relatedParts.map(rp => {
					if (rp.person_id === relatedPart.person_id) {
						const newPermissions = rp.permission.includes(
							permission,
						)
							? rp.permission.filter(p => p !== permission)
							: [...rp.permission, permission];
						return {
							...rp,
							permission: newPermissions,
						};
					}
					return rp;
				}),
			);
		},
		[],
	);

	const representativesItems: CollapseProps['items'] = useMemo(() => {
		return relatedParts
			.filter(item => item.person_id !== 'celcoin_company_id')
			.map((relatedPart, idx) => ({
				key: `${idx + 1}`,
				label: (
					<CollapseHeader>
						<TextM>{relatedPart.person?.name || '-'}</TextM>
					</CollapseHeader>
				),
				children: (
					<div>
						{Object.values(AccountPermission).map(permission => (
							<div
								key={permission}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '1rem',
									borderBottom: `1px solid ${theme.border}`,
								}}
							>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									{
										AccountPermissionDescription[
											permission as AccountPermission
										]
									}
								</TextS>
								<CustomSwitch
									checked={
										relatedPart.permission?.includes?.(
											permission,
										) || false
									}
									handleChange={() =>
										handleTogglePermission(
											relatedPart,
											permission,
										)
									}
								/>
							</div>
						))}
						<div
							onClick={() =>
								onRemoveRelatedPart(relatedPart, idx === 0)
							}
							style={{
								marginTop: '1rem',
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								cursor: 'pointer',
							}}
						>
							<TextM
								style={{
									color: theme.danger,
								}}
							>
								Remover parte relacionada
							</TextM>
						</div>
					</div>
				),
			}));
	}, [relatedParts, handleTogglePermission, theme, onRemoveRelatedPart]);

	return (
		<ContainerFluid>
			{contextHolder}
			<ContainerHeader>
				<Heading2 style={{ fontSize: '1.5rem' }}>
					Partes Relacionadas
				</Heading2>
				{relatedParts.length > 0 && (
					<PageActions>
						<Button
							type="link"
							onClick={() =>
								toggleIsRelatedPartCreationOpen(true)
							}
						>
							Adicionar
						</Button>
					</PageActions>
				)}
			</ContainerHeader>
			{relatedParts.length === 1 && (
				<>
					<TextM
						style={{
							textAlign: 'center',
							paddingTop: '1rem',
							color: theme.textSecondary,
						}}
					>
						Você ainda não possui parte relacionada cadastrada!
					</TextM>
					<Empty
						style={{
							minHeight: '50vh',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						description={
							<TextS
								style={{
									paddingTop: '1rem',
									color: theme.textGray,
									textAlign: 'center',
								}}
							>
								Para prosseguir, adicione uma parte relacionada.
							</TextS>
						}
						image={EmptyRepresentativesList}
						imageStyle={{
							height: 240,
						}}
					/>
				</>
			)}
			{relatedParts.length > 1 && (
				<>
					<FinancialInstitutionContainer>
						<TextM
							style={{
								fontSize: '1.12rem',
							}}
						>
							Celcoin Instituição de Pagamento S.A
						</TextM>
						<TextM
							style={{
								fontSize: '1.12rem',
							}}
						>
							Instituição Financeira
						</TextM>
					</FinancialInstitutionContainer>

					<Collapse
						style={{ margin: '1rem 0' }}
						expandIconPosition="end"
						items={representativesItems}
					/>
				</>
			)}
			<Footer>
				<Button
					type="link"
					onClick={() => onBack(handleRelatedPartCreation())}
				>
					Voltar
				</Button>
				<Button
					type="primary"
					disabled={relatedParts.length < 2 || isLoading}
					onClick={() => {
						if (checkEmptyPermissionsAndWarn(relatedParts)) return;
						handleStepStatus(StepsId.REPRESENTATIVES, true);
						onNext(handleRelatedPartCreation());
					}}
				>
					{isLoading ? <Spin /> : 'Avançar'}
				</Button>
			</Footer>
			<RelatedPartsCreationNaturalPerson
				isOpen={isRelatedPartCreationOpen}
				isLoading={isCompanyFundLoading ?? isCompanyLoading}
				permissions={permissions}
				relatedPart={editRelatedPart}
				onClose={() => toggleIsRelatedPartCreationOpen(false)}
				onCreate={onCreateRelatedPart}
			/>
		</ContainerFluid>
	);
};

export default EscrowNaturalPersonCreationRelatedParts;
