import styled from 'styled-components';

export interface AccountTypeButtonContainerProps {
	selected: boolean;
}

export const AccountTypeButtonContainer = styled.div<AccountTypeButtonContainerProps>`
	padding: 12px 32px;
	display: flex;
	align-items: center;
	gap: 12px;
	border-radius: 16px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	border: 1px solid #ededed;
	background-color: ${({ selected, theme }) =>
		selected ? theme.primary : 'white'};
`;

export const IconArea = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5;
	border-radius: 50%;
	padding: 8px;
`;
