import {
	ForwardButton,
	PaymentCreationInputForm as PaymentCreationInputFormStyled,
} from './styles';
import { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { TextM } from 'components/Text';
import { IoMdArrowForward } from 'react-icons/io';

interface IPaymentCreationInputFormProps {
	isLoading: boolean;
	onNext: (paymentNumber: string) => void;
}
const PaymentCreationInputForm = ({
	isLoading,
	onNext,
}: IPaymentCreationInputFormProps) => {
	const [form] = Form.useForm();
	const [formValid, setFormValid] = useState(false);

	return (
		<PaymentCreationInputFormStyled
			form={form}
			style={{ width: '100%' }}
			layout="vertical"
			requiredMark={false}
			onFieldsChange={(f, allFields) => {
				const errors = allFields.filter(
					field =>
						!field.touched ||
						(field.errors && field.errors.length > 0),
				);
				setFormValid(errors.length === 0);
			}}
			onFinish={values => {
				onNext(values.number?.trim());
			}}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Form.Item
						name="number"
						label="Linha digitável"
						rules={[
							{ required: true, message: 'Campo obrigatório' },
						]}
					>
						<Input placeholder="Digite ou cole seu código de barras aqui" />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
				<Col span={24}>
					<ForwardButton
						type="primary"
						htmlType="submit"
						disabled={!formValid || isLoading}
						loading={isLoading}
					>
						<TextM>Solicitar pagamento</TextM>
						<IoMdArrowForward size={22} />
					</ForwardButton>
				</Col>
			</Row>
		</PaymentCreationInputFormStyled>
	);
};

export default PaymentCreationInputForm;
