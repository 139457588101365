import { AccountRelatedPart } from 'types/Account';
import { Company } from 'types/Company';

export type FullAccountRelatedPart = AccountRelatedPart & {
	companyDetails?: Company;
};

export enum StepsId {
	PERSONAL_INFORMATION = 'personal_information',
	REPRESENTATIVES = 'representatives',
	RELATED_PARTS = 'related_parts',
	SIGNERS = 'signers',
	DESTINATION = 'destination',
}

export type StepsStatus = {
	[key: string]: {
		completed: boolean;
	};
};
