import {
	Col,
	Form,
	Input,
	InputRef,
	RefSelectProps,
	Row,
	Select,
	notification,
} from 'antd';
import { UserCreationFormConfirmButton, UserCreationFormItem } from './styles';
import { cpfRule } from 'helpers/rules';
import { normalizeCpf } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { User, UserDto } from 'types/User';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { UsersService } from 'modules/users/services';
import { AccessService } from 'modules/users/services/access';
import {
	AccessDto,
	AccessListContent,
	AccessPermission,
	AccessType,
} from 'types/Access';
import { queryClient } from 'network/query';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
	AccessPermissionDescription,
	AccessPermissionRestricted,
} from 'constants/access';
import FormItem from 'antd/es/form/FormItem';
import { validateCpf } from 'helpers/validators';
import { useUser } from 'modules/users/hooks';
import { Modal } from 'components/Modal';
import { TextM } from 'components/Text';
import { FaCheck } from 'react-icons/fa6';
import { useTheme } from 'styled-components';
import { isSandbox } from 'helpers/environment';

type UserCreationDto = UserDto & { permissions?: AccessPermission[] };
export type UserEditionDto = User & { permissions?: AccessPermission[] };

interface IUserCreationProps {
	isOpen: boolean;
	companyId?: string;
	user?: AccessListContent;
	onClose: () => void;
	onSuccess: () => void;
}
const UserCreation = ({
	isOpen,
	companyId,
	user: editUser,
	onClose,
	onSuccess,
}: IUserCreationProps) => {
	const theme = useTheme();
	const [form] = Form.useForm();
	const emailAddressRef = useRef<InputRef>(null);
	const permissionsRef = useRef<RefSelectProps>(null);
	const [isFormDisabled, toggleFormDisabled] = useState<boolean>(true);
	const [api, contextHolder] = notification.useNotification();
	const userProfile = useCurrentProfile();

	const isCreatingCompanyUser =
		userProfile.type !== AccessType.FINANCIAL_INSTITUTION ||
		typeof companyId === 'string';

	useEffect(() => {
		if (isOpen) {
			if (typeof editUser !== 'undefined') {
				form.setFieldValue(
					'email_address',
					editUser.user.email_address,
				);
				form.setFieldValue(
					'taxpayer_id',
					normalizeCpf(editUser.user.taxpayer_id),
				);
				form.setFieldValue('full_name', editUser.user.full_name);
				form.setFieldValue('permissions', editUser.permissions);
				setTimeout(() => permissionsRef.current?.focus(), 100);
			} else {
				form.setFieldValue('email_address', undefined);
				form.setFieldValue('taxpayer_id', undefined);
				form.setFieldValue('full_name', undefined);
				form.setFieldValue('permissions', undefined);
			}
		}
	}, [isOpen]);

	const {
		getUserByTaxpayerId,
		user: loadedUser,
		loading,
	} = useUser({
		onError: e => {
			api.error({
				description: e.data?.message,
				message:
					'Ocorreu um problema ao buscar informações do usuário.',
			});
		},
		onSuccess: (user, hasPrevUser) => {
			if (!user) {
				if (hasPrevUser) {
					form.resetFields([
						'email_address',
						'full_name',
						'permissions',
					]);
				}
				toggleFormDisabled(false);
				setTimeout(() => emailAddressRef.current?.focus(), 100);
				return;
			}

			form.setFieldValue('email_address', user.email_address);
			form.setFieldValue('full_name', user.full_name);
			setTimeout(() => permissionsRef.current?.focus(), 100);
			toggleFormDisabled(true);
		},
	});

	const createAccessDto = useCallback(
		(permissions: AccessPermission[]): AccessDto => {
			if (userProfile.type === AccessType.PERSON) {
				return {
					type: AccessType.PERSON,
					personId: userProfile.person?.id,
					permissions,
				};
			}
			if (typeof companyId === 'string') {
				return {
					type: AccessType.PERSON,
					personId: companyId,
					permissions,
				};
			}
			return {
				type: AccessType.FINANCIAL_INSTITUTION,
				permissions,
			};
		},
		[userProfile, companyId],
	);

	const { mutate, isPending } = useMutation<
		{ id: string },
		ApiError,
		UserCreationDto
	>({
		mutationFn: async userCreationDto => {
			try {
				const { permissions, ...userDto } = userCreationDto;
				if (editUser || loadedUser) {
					const user = editUser?.user ?? loadedUser;
					await AccessService.createAccess(
						user!.id,
						createAccessDto(permissions ?? []),
					);
					return {
						id: user!.id,
					};
				}
				const user = await UsersService.createUser(userDto);
				await AccessService.createAccess(
					user.id,
					createAccessDto(permissions ?? []),
				);
				return {
					id: user!.id,
				};
			} catch (e) {
				return Promise.reject(e);
			}
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['userList', 'accessList'],
			});
			onSuccess();
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a usuário.',
			});
		},
	});

	return (
		<Modal
			title="Criação de Usuário"
			isOpen={isOpen}
			onClose={onClose}
			width={520}
		>
			{contextHolder}
			{isOpen && (
				<Form<UserCreationDto>
					form={form}
					validateTrigger="onBlur"
					style={{ width: '100%' }}
					requiredMark={false}
					disabled={isFormDisabled || typeof editUser !== 'undefined'}
					initialValues={{
						taxpayer_id:
							editUser?.user?.taxpayer_id &&
							normalizeCpf(editUser.user.taxpayer_id),
						full_name: editUser?.user.full_name,
						email_address: editUser?.user.email_address,
						permissions: editUser?.permissions,
					}}
					layout="vertical"
					onFinish={values => {
						mutate({
							...values,
							taxpayer_id: serializeOnlyNumbers(
								values.taxpayer_id,
							),
						});
					}}
				>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<FormItem
								shouldUpdate={(prevValues, currentValues) =>
									prevValues.taxpayer_id !==
									currentValues.taxpayer_id
								}
							>
								{({ getFieldValue }) => {
									return (
										<UserCreationFormItem
											name="taxpayer_id"
											label="Identificação do usuário"
											normalize={value =>
												normalizeCpf(value)
											}
											rules={[
												{
													required: true,
													message:
														'Campo obrigatório',
												},
												cpfRule,
											]}
										>
											<Input
												disabled={
													loading ||
													editUser !== undefined
												}
												placeholder="CPF"
												autoFocus
												onBlur={() => {
													const taxpayerId =
														getFieldValue(
															'taxpayer_id',
														);
													if (
														validateCpf(
															taxpayerId,
														) &&
														!loading
													) {
														getUserByTaxpayerId(
															serializeOnlyNumbers(
																taxpayerId,
															),
														);
													}
												}}
											/>
										</UserCreationFormItem>
									);
								}}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={[16, 16]} style={{ marginTop: '0.6rem' }}>
						<Col span={24}>
							<UserCreationFormItem
								name="email_address"
								label="E-mail do usuário"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
									{
										type: 'email',
										message: 'E-mail inválido',
									},
								]}
							>
								<Input
									ref={emailAddressRef}
									placeholder="Ex.: exemplo@exemplo.com"
								/>
							</UserCreationFormItem>
						</Col>
					</Row>
					<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
						<Col span={24}>
							<UserCreationFormItem
								name="full_name"
								label="Nome do usuário"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: João da Silva" />
							</UserCreationFormItem>
						</Col>
					</Row>
					{isCreatingCompanyUser && (
						<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
							<Col span={24}>
								<UserCreationFormItem
									name="permissions"
									label="Permissões"
								>
									<Select
										mode="multiple"
										disabled={false}
										ref={permissionsRef}
										style={{ minWidth: '100%' }}
										placeholder="Selecione as permissões"
										options={Object.keys(
											AccessPermissionDescription,
										)
											.map(key => ({
												label: AccessPermissionDescription[
													key as AccessPermission
												],
												value: key,
											}))
											.filter(
												permission =>
													isSandbox() ||
													!AccessPermissionRestricted.includes(
														permission.value as AccessPermission,
													),
											)}
									/>
								</UserCreationFormItem>
							</Col>
						</Row>
					)}
					<Row gutter={[16, 16]} style={{ marginTop: 'auto' }}>
						<Col span={24}>
							<UserCreationFormConfirmButton
								type="primary"
								loading={isPending}
								disabled={false}
								htmlType="submit"
							>
								<TextM>Criar acesso</TextM>
								<FaCheck color={theme.white} />
							</UserCreationFormConfirmButton>
						</Col>
					</Row>
				</Form>
			)}
		</Modal>
	);
};

export default UserCreation;
