import React, { useContext, useEffect } from 'react';
import {
	NewPasswordFlow,
	useAuthContext,
} from 'modules/core/context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { SplashScreen } from 'components/SplashScreen';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import { queryClient } from 'network/query';

const FirstAccessPage: React.FC = () => {
	const { startNewPasswordFlow, logout, newPasswordFlow } = useAuthContext();
	const { resetSelectedUserAccess } = useContext(ProfileContext);
	const { search } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const params = new URLSearchParams(search);
		const tokenUrl = params.get('token');
		const emailUrl = params.get('email');

		if (!newPasswordFlow) {
			if (typeof tokenUrl === 'string' && typeof emailUrl === 'string') {
				const token = tokenUrl.replace(/-/g, '+').replace(/_/g, '/');
				const email = emailUrl.replace(/-/g, '+').replace(/_/g, '/');
				const decodedToken = atob(token);
				const decodedEmail = atob(email);
				logout().then(() => {
					queryClient.removeQueries();
					resetSelectedUserAccess();
					startNewPasswordFlow(
						decodedEmail,
						decodedToken,
						NewPasswordFlow.FIRST_ACCESS,
					);
					setTimeout(() => navigate('/terms'));
				});
			} else {
				navigate('/login');
			}
		}
	}, [newPasswordFlow, search, logout]);

	return <SplashScreen />;
};

export default FirstAccessPage;
