import { Col, Flex, Row, Select } from 'antd';
import { TextM, TextS } from 'components/Text';
import { ProfileContext } from 'modules/core/context/ProfileContext';
import React, { useContext } from 'react';
import { AccessType } from 'types/Access';

export const NavBarCompanyList: React.FC = () => {
	const { userAccess, selectedUserAccess, setSelectedUserAccess } =
		useContext(ProfileContext);

	return (
		<Row gutter={[16, 16]} style={{ width: '100%', maxWidth: '450px' }}>
			<Col span={24}>
				{userAccess.length > 1 ? (
					<Select
						style={{ width: '100%' }}
						value={selectedUserAccess?.id}
						onChange={access => setSelectedUserAccess(access, true)}
						options={userAccess.map(access => ({
							label:
								access.type === AccessType.PERSON
									? access.person?.name
									: access.financial_institution.name,
							value: access.id,
						}))}
						placeholder="Selecione uma empresa"
					/>
				) : (
					<Flex vertical>
						{selectedUserAccess?.type === AccessType.PERSON ? (
							<>
								<TextM>
									{selectedUserAccess?.person?.name}
								</TextM>
								<TextS>
									{
										selectedUserAccess
											?.financial_institution.name
									}
								</TextS>
							</>
						) : (
							<TextM>
								{selectedUserAccess?.financial_institution.name}
							</TextM>
						)}
					</Flex>
				)}
			</Col>
		</Row>
	);
};
