import { Form } from 'antd';
import styled from 'styled-components';
import { TBalanceForm } from './types';

export const BalanceForm = styled(Form<TBalanceForm>)`
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
`;
